export const FooterData = [
  {
    id: 1,
    tabName: 'Home',
    pathName: '/',
  },
  {
    id: 2,
    tabName: 'Pricing',
    pathName: '/pricing',
  },
];
