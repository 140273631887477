import createTypes from 'redux-create-action-types';

export default createTypes(
  'GET_SUBSCRIPTIONS',
  'SUCCESS_GET_SUBSCRIPTIONS',
  'RESEND_CODE',
  'SUCCESS_RESEND_CODE',
  'VERIFY_CODE',
  'SUCCESS_VERIFY_CODE',
  'FAILURE_GET_SUBSCRIPTIONS',
  'ADD_SUBSCRIPTIONS',
  'ADD_SUBSCRIPTIONS_SUCCESS',
  'CANCEL_SUBSCRIPTION',
  'SUCCESS_CANCEL_SUBSCRIPTION',
  'SET_TERM_SERVICE',
  'CLEAR_TERM_SERVICE',
);
