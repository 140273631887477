import React from 'react';
import styles from './authLayout.module.scss';
import cogPlayIcon from '../../assets/images/COGPLAYLOGO.svg';
import { useNavigate } from 'react-router-dom';

const AuthLayout = ({ children }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.main}>
      <div className={styles.header} onClick={() => navigate('/')}>
        <div className={styles.icon}>
          <img src={cogPlayIcon} alt={cogPlayIcon} />
        </div>
        <div className={styles.iconText}>Cognitive Playground</div>
      </div>
      {children}
    </div>
  );
};

export default AuthLayout;
