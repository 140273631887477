import React, { useState, useRef } from 'react';
import styles from './CircleCounting.module.scss';
import QuizHeader from '../QuizLayout/QuizHeader/QuizHeader';
import QuizFooterHandleTimer from '../QuizLayout/QuizFooter/QuizFooterHandleTimer';
import { generateDotQuestions } from '../../User_Profile/User_Quiz_data';
import CircleContain from './CircleContain';

const CircleCounting = () => {
  const questionRef = useRef(null);
  const [isDisplayNext, setIsDisplayNext] = useState(false);
  const [quizData] = useState(generateDotQuestions());
  const [questionTimer, setQuestionTimer] = useState('05:00');
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState(0);

  const renderQuiz = () => {
    if (!quizData) return 0;
    return (
      <div className={styles.quizContent}>
        <CircleContain
          quizData={quizData}
          currentQuestion={currentQuestion}
          questionTimer={questionTimer}
          setCurrentQuestion={setCurrentQuestion}
          answers={answers}
          setAnswers={setAnswers}
          isDisplayNext={isDisplayNext}
          questionRef={questionRef}
          setQuestionTimer={setQuestionTimer}
        />
      </div>
    );
  };

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.modalContainer}>
          <QuizHeader isDisplayNext={false} title={'Circle Counting'} />
          <div className={styles.instruction}>Click on start, count the circles end enter the number</div>
          <div className={styles.quizWrapper}>{renderQuiz()}</div>
          <QuizFooterHandleTimer
            isDisplayNext={isDisplayNext}
            setIsDisplayNext={setIsDisplayNext}
            isDisabled={true}
            questionRef={questionRef}
          />
        </div>
      </div>
    </>
  );
};

export default CircleCounting;
