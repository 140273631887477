const colourCodedData = (questionNumber) => {
  const quizData = [
    {
      num1: questionNumber,
      num2: 1,
    },
    {
      num1: 1,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 2,
    },
    {
      num1: 2,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 3,
    },
    {
      num1: 3,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 4,
    },
    {
      num1: 4,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 5,
    },
    {
      num1: 5,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 6,
    },
    {
      num1: 6,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 7,
    },
    {
      num1: 7,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 8,
    },
    {
      num1: 8,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 9,
    },
    {
      num1: 9,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 10,
    },
    {
      num1: 10,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 11,
    },
    {
      num1: 11,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 12,
    },
    {
      num1: 12,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 13,
    },
    {
      num1: 13,

      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 14,
    },
    {
      num1: 14,

      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 15,
    },
    {
      num1: 15,

      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 16,
    },
    {
      num1: 16,

      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 17,
    },
    {
      num1: 17,

      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 18,
    },
    {
      num1: 18,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 19,
    },
    {
      num1: 19,

      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 20,
    },
    {
      num1: 20,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 2,
    },
    {
      num1: 2,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 4,
    },
    {
      num1: 4,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 6,
    },
    {
      num1: 6,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 8,
    },
    {
      num1: 8,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 10,
    },
    {
      num1: 10,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 12,
    },
    {
      num1: 12,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 14,
    },
    {
      num1: 14,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 16,
    },
    {
      num1: 16,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 18,
    },
    {
      num1: 18,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 20,
    },
    {
      num1: 20,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 1,
    },
    {
      num1: 1,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 3,
    },
    {
      num1: 3,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 5,
    },
    {
      num1: 5,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 7,
    },
    {
      num1: 7,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 9,
    },
    {
      num1: 9,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 11,
    },
    {
      num1: 11,

      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 13,
    },
    {
      num1: 13,

      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 15,
    },
    {
      num1: 15,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 17,
    },
    {
      num1: 17,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 19,
    },
    {
      num1: 19,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 3,
    },
    {
      num1: 3,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 6,
    },
    {
      num1: 6,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 9,
    },
    {
      num1: 9,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 12,
    },
    {
      num1: 12,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 15,
    },
    {
      num1: 15,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 18,
    },
    {
      num1: 18,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 4,
    },
    {
      num1: 4,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 8,
    },
    {
      num1: 8,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 12,
    },
    {
      num1: 12,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 16,
    },
    {
      num1: 16,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 20,
    },
    {
      num1: 20,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 5,
    },
    {
      num1: 5,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 10,
    },
    {
      num1: 10,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 15,
    },
    {
      num1: 15,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 20,
    },
    {
      num1: 20,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 6,
    },
    {
      num1: 6,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 12,
    },
    {
      num1: 12,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 18,
    },
    {
      num1: 18,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 7,
    },
    {
      num1: 7,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 14,
    },
    {
      num1: 14,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 8,
    },
    {
      num1: 8,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 16,
    },
    {
      num1: 16,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 9,
    },
    {
      num1: 9,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 18,
    },
    {
      num1: 18,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 10,
    },
    {
      num1: 10,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 20,
    },
    {
      num1: 20,
      num2: questionNumber,
    },
  ];
  return quizData;
};

const divisionColourCodedData = (questionNumber) => {
  const quizData = {
    ['0']: [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 3, 5, 7, 9, 11, 2, 4, 6, 8, 10, 12, 3, 6, 9, 12, 4, 8, 12, 5, 10, 6,
      12, 7, 8, 9, 10, 11, 12,
    ],
    ['1']: [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 3, 5, 7, 9, 11, 2, 4, 6, 8, 10, 12, 3, 6, 9, 12, 4, 8, 12, 5, 10, 6,
      12, 7, 8, 9, 10, 11, 12,
    ],
    ['2']: [
      0, 1, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 4, 8, 12, 16, 20, 24, 6, 12, 18, 24, 8, 16, 24, 22, 10, 20, 12,
      24,
    ],
    ['3']: [
      0, 1, 3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36, 6, 12, 18, 24, 30, 36, 9, 18, 27, 36, 12, 24, 36, 33, 15, 30,
      18, 36,
    ],
    ['4']: [
      0, 1, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 8, 16, 24, 32, 40, 48, 12, 24, 36, 48, 16, 32, 48, 44, 20, 40,
      24, 48,
    ],
    ['5']: [
      0, 1, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 10, 20, 30, 40, 50, 60, 15, 30, 45, 60, 55, 20, 40, 60, 25,
      50, 30, 60,
    ],
    ['6']: [
      0, 1, 6, 12, 18, 24, 30, 36, 42, 48, 54, 60, 66, 72, 12, 24, 36, 48, 60, 72, 18, 36, 54, 72, 66, 24, 48, 72, 30,
      60, 36, 72,
    ],
    ['7']: [
      0, 1, 7, 14, 21, 28, 35, 42, 49, 56, 63, 70, 77, 84, 14, 28, 42, 56, 70, 84, 21, 42, 63, 84, 77, 28, 56, 84, 35,
      70, 42, 84,
    ],
    ['8']: [
      0, 1, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80, 88, 96, 16, 32, 48, 64, 80, 96, 88, 24, 48, 72, 96, 32, 64, 96, 40,
      80, 48, 96,
    ],
    ['9']: [
      0, 1, 9, 18, 27, 36, 45, 54, 63, 72, 81, 90, 99, 108, 18, 36, 54, 72, 90, 108, 99, 27, 54, 81, 108, 36, 72, 108,
      45, 90, 54, 108,
    ],
    ['10']: [
      0, 1, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 20, 40, 60, 80, 100, 120, 110, 30, 60, 90, 120, 40, 80,
      120, 50, 100, 60, 120,
    ],
    ['11']: [
      0, 1, 11, 22, 33, 44, 55, 66, 77, 88, 99, 110, 121, 132, 22, 44, 66, 88, 110, 132, 33, 66, 99, 132, 44, 88, 132,
      55, 110, 66, 132, 121,
    ],
    ['12']: [
      0, 1, 12, 24, 36, 48, 60, 72, 84, 96, 108, 120, 132, 144, 24, 48, 72, 96, 120, 144, 36, 72, 108, 144, 48, 96, 144,
      60, 120, 72, 144, 132,
    ],
  };

  return quizData[questionNumber];
};
const colourCodedDataMultiplication = (questionNumber) => {
  const quizDataMultiplication = [
    {
      num1: questionNumber,
      num2: 1,
    },
    {
      num1: 1,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 2,
    },
    {
      num1: 2,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 3,
    },
    {
      num1: 3,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 4,
    },
    {
      num1: 4,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 5,
    },
    {
      num1: 5,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 6,
    },
    {
      num1: 6,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 7,
    },
    {
      num1: 7,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 8,
    },
    {
      num1: 8,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 9,
    },
    {
      num1: 9,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 10,
    },
    {
      num1: 10,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 11,
    },
    {
      num1: 11,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 12,
    },
    {
      num1: 12,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 2,
    },
    {
      num1: 2,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 4,
    },
    {
      num1: 4,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 6,
    },
    {
      num1: 6,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 8,
    },
    {
      num1: 8,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 10,
    },
    {
      num1: 10,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 12,
    },
    {
      num1: 12,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 1,
    },
    {
      num1: 1,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 3,
    },
    {
      num1: 3,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 5,
    },
    {
      num1: 5,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 7,
    },
    {
      num1: 7,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 9,
    },
    {
      num1: 9,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 11,
    },
    {
      num1: 11,

      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 3,
    },
    {
      num1: 3,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 6,
    },
    {
      num1: 6,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 9,
    },
    {
      num1: 9,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 12,
    },
    {
      num1: 12,
      num2: questionNumber,
    },

    {
      num1: questionNumber,
      num2: 4,
    },
    {
      num1: 4,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 8,
    },
    {
      num1: 8,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 12,
    },
    {
      num1: 12,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 5,
    },
    {
      num1: 5,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 10,
    },
    {
      num1: 10,
      num2: questionNumber,
    },

    {
      num1: questionNumber,
      num2: 6,
    },
    {
      num1: 6,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 12,
    },
    {
      num1: 12,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 7,
    },
    {
      num1: 7,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 8,
    },
    {
      num1: 8,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 9,
    },
    {
      num1: 9,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 10,
    },
    {
      num1: 10,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 11,
    },
    {
      num1: 11,
      num2: questionNumber,
    },
    {
      num1: questionNumber,
      num2: 12,
    },
    {
      num1: 12,
      num2: questionNumber,
    },
  ];
  return quizDataMultiplication;
};

export { colourCodedData, colourCodedDataMultiplication, divisionColourCodedData };
