import BaseAxios from 'axios';
import get from 'lodash/get';
import store from '../store';
import Cookies from 'js-cookie';

class Request {
  axios;

  constructor() {
    this.axios = BaseAxios.create({ timeout: 60000 });
    // we will maintain refresh token call status in refreshTokenInProgress
    this.refreshTokenInProgress = false;
    // this is the queue used to add api calls when refresh token is fetched
  }

  async call(config, recursive = false, download = false) {
    const serverBaseUrl = process.env.REACT_APP_API_URL;
    let headers = {
      'Content-Type': 'application/json',
    };

    const storeData = store.getState();
    const token = get(storeData, 'auth.user.auth_token', null);
    const user = localStorage.getItem('user');
    const userType = user && JSON.parse(user)?.user_type;

    const tokenQuiz =
      userType === 'Student' || userType === 'Children' ? sessionStorage.getItem('token') : Cookies.get('token');

    if (token) headers = { ...headers, Authorization: `Bearer ${token}` };
    if (tokenQuiz) headers = { ...headers, Authorization: `token ${tokenQuiz}` };
    try {
      const res = await this.axios.request({
        baseURL: serverBaseUrl,
        headers,
        ...config,
      });
      return { ...res.data, status: 1 };
    } catch (error) {
      const errorStatus = get(error, 'response.status', null);
      return {
        status: 0,
        message: error?.response?.data,
        errorStatus,
      };
    }
  }
}

export default new Request();
