import Types from '../types/AdminTypes';

import {
  getChildrenSummaryApi,
  getChildActivitiesList,
  getAllSubscriptionsApi,
  getAllSubscriptionsList,
  studentMasterdChart,
  directionChart,
  comparisonChart,
  summaryChart,
} from '../../api/adminApi';
import { notification } from 'antd';

export const getChildrenSummary = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: Types.GET_CHILDREN_SUMMARY,
    });
    const resp = await getChildrenSummaryApi(payload);
    if (resp.status === 1) {
      dispatch({
        type: Types.GET_CHILDREN_SUMMARY_SUCCESS,
        payload: [resp],
      });
    } else {
      notification['error']({
        message: 'Something went wrong, please try again later',
      });
      dispatch({
        type: Types.GET_CHILDREN_SUMMARY_FAILURE,
      });
    }
    return resp.status;
  };
};

export const getChildActivities = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: Types.GET_CHILD_ACTIVITY_LIST,
    });
    const resp = await getChildActivitiesList(payload);
    if (resp.status === 1) {
      dispatch({
        type: Types.GET_CHILD_ACTIVITY_LIST_SUCCESS,
        payload: resp,
      });
    } else {
      notification['error']({
        message: 'Something went wrong, please try again later',
      });
      dispatch({
        type: Types.GET_CHILD_ACTIVITY_LIST_FAILURE,
      });
    }

    return resp.status;
  };
};

export const getAdminSubscriptions = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: Types.GET_CHARTS_DATA,
    });

    const resp = await getAllSubscriptionsApi(payload);
    if (resp.status === 1) {
      dispatch({
        type: Types.GET_SUBSCRIPTION_LIST_SUCCESS,
        payload: resp,
      });
    } else {
      dispatch({
        type: Types.GET_SUBSCRIPTION_LIST_FAILURE,
      });
      notification['error']({
        message: 'Something went wrong, please try again later',
      });
    }

    return resp.status;
  };
};

export const getAllSubscriptions = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: Types.GET_ALL_SUBSCRIPTIONS_LIST,
    });
    const resp = await getAllSubscriptionsList(payload);
    if (resp.status === 1) {
      dispatch({
        type: Types.GET_ALL_SUBSCRIPTIONS_LIST_SUCCESS,
        payload: resp,
      });
    } else {
      notification['error']({
        message: 'Something went wrong, please try again later',
      });
      dispatch({
        type: Types.GET_ALL_SUBSCRIPTIONS_LIST_FAILURE,
      });
    }

    return resp.status;
  };
};

export const getMasteredChart = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: Types.GET_CHARTS_DATA,
    });
    const resp = await studentMasterdChart(payload);
    if (resp.status === 1) {
      dispatch({
        type: Types.GET_STUDENT_MASTERED_CHART_SUCCESS,
        payload: resp?.data[0],
      });
    } else {
      notification['error']({
        message: 'Something went wrong, please try again later',
      });
      dispatch({
        type: Types.GET_CHARTS_DATA_FAILURE,
      });
    }

    return resp.status;
  };
};

export const getDirectionChart = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: Types.GET_CHARTS_DATA,
    });
    const resp = await directionChart(payload);
    if (resp.status === 1) {
      dispatch({
        type: Types.GET_DIRECTION_CHART_SUCCESS,
        payload: resp?.data[0],
      });
    } else {
      notification['error']({
        message: 'Something went wrong, please try again later',
      });
      dispatch({
        type: Types.GET_CHARTS_DATA_FAILURE,
      });
    }

    return resp.status;
  };
};

export const getComparisonChart = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: Types.GET_CHARTS_DATA,
    });
    const resp = await comparisonChart(payload);
    if (resp.status === 1) {
      dispatch({
        type: Types.GET_COMPARISON_CHART_SUCCESS,
        payload: resp?.data[0],
      });
    } else {
      notification['error']({
        message: 'Something went wrong, please try again later',
      });
      dispatch({
        type: Types.GET_CHARTS_DATA_FAILURE,
      });
    }

    return resp.status;
  };
};

export const summaryChartApi = (filterData) => {
  return async (dispatch) => {
    dispatch({
      type: Types.GET_CHARTS_DATA,
    });
    const resp = await summaryChart(filterData);
    if (resp.status === 1) {
      dispatch({
        type: Types.GET_SUMMARY_CHARTS_SUCCESS,
        payload: resp,
      });
    } else {
      notification['error']({
        message: 'Something went wrong, please try again later',
      });
      dispatch({
        type: Types.GET_SUMMARY_CHARTS_FAILURE,
      });
    }

    return resp.status;
  };
};
