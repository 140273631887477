import React, { useEffect, useRef } from 'react';
import styles from '../Figure8.module.scss';
import { netData } from '../constant';
import blueImg from '../../../../../src/assets/images/HomePage/Programs/Figure8/figure8_blue.svg';
import redImg from '../../../../../src/assets/images/HomePage/Programs/Figure8/figure_8.svg';

const Canvas = (props) => {
  const {
    className,
    handleIsWrong,
    isBlue,
    setIsBlue,
    // setApproxStartingPnt,
    setPrompt,
    setInstruction,
    isRed,
    setIsRed,
    setPromptPosition,
    setCountRecord,
  } = props;
  const effectRef = useRef(false);
  const canvasRef = useRef(null);
  let count = 1;
  useEffect(() => {
    if (effectRef.current) return;
    let touchEndCount = 1;
    let touchend;
    let grayscaleImg = [];
    let startingPoint;
    let drawingEnd = false;
    let drawPoint = { x: isBlue ? 200 : 180, y: 189 };
    // let directionX, directionY;
    const canvas = document.getElementById('sketchpad');

    const context = canvas.getContext('2d');
    let canvasOffset = getOffsetSum(canvas);
    // let lastX = 0;
    // let lastY = 0;

    const footprint = {
      width: 28,
      height: 28,
    };

    let isRecognized = false;
    const zoom = 10;

    const clearer = function clearer() {
      context.clearRect(0, 0, footprint.width * zoom, footprint.height * zoom);
      isRecognized = false;
    };

    clearer();

    function getOffsetSum(elem) {
      let top = 0;
      let left = 0;
      while (elem) {
        top = top + parseInt(elem.offsetTop);
        left = left + parseInt(elem.offsetLeft);
        elem = elem.offsetParent;
      }

      return { top, left };
    }

    const drawer = {
      isDrawing: false,
      isOut: false,
      touchstart(coors) {
        handleIsWrong(false);
        startingPoint = {
          x: coors.x - canvasOffset.left,
          y: coors.y - (touchEndCount > 20 ? canvasOffset.top + 15 : canvasOffset.top),
        };
        context.beginPath();
        context.lineWidth = 8;
        context.lineCap = 'round';

        // console.log('@@@', touchEndCount);
        // touchEndCount === 10 ? setInstruction(2) : touchEndCount === 20 ? setInstruction(3) : setInstruction(0);
        context.strokeStyle = touchEndCount > 20 ? 'red' : touchEndCount >= 11 ? 'blue' : 'yellow';
        context.moveTo(
          coors.x - canvasOffset.left,
          coors.y - (touchEndCount > 20 ? canvasOffset.top + 15 : canvasOffset.top),
        );
        this.isDrawing = true;
      },
      touchmove(coors) {
        if (this.isDrawing) {
          try {
            clearTimeout(touchend);
          } catch (e) {}
          if (isRecognized) {
            // clearer();
          }

          context.lineTo(
            coors.x - canvasOffset.left,
            coors.y - (touchEndCount > 20 ? canvasOffset.top + 15 : canvasOffset.top),
          );
          context.stroke();
          this.isOut = false;
          let touchPoints;

          touchPoints =
            touchEndCount === 22 || touchEndCount === 23 || touchEndCount === 27 || touchEndCount === 24
              ? drawPoint.x > startingPoint.x - 50 &&
                drawPoint.x <= startingPoint.x + 50 &&
                drawPoint.y > startingPoint.y - 25 &&
                drawPoint.y <= startingPoint.y + 25
              : touchEndCount === 21
              ? drawPoint.x > startingPoint.x - 70 &&
                drawPoint.x <= startingPoint.x + 70 &&
                drawPoint.y > startingPoint.y - 50 &&
                drawPoint.y <= startingPoint.y + 50
              : touchEndCount === 25 || touchEndCount === 26 || touchEndCount === 29
              ? drawPoint.x > startingPoint.x - 70 &&
                drawPoint.x <= startingPoint.x + 10 &&
                drawPoint.y > startingPoint.y - 30 &&
                drawPoint.y <= startingPoint.y + 30
              : drawPoint.x > startingPoint.x - 30 &&
                drawPoint.x <= startingPoint.x + 30 &&
                drawPoint.y > startingPoint.y - 30 &&
                drawPoint.y <= startingPoint.y + 30;

          if (touchPoints) {
            if (touchEndCount >= 11) {
              setIsBlue(true);
              drawPoint = { x: 200, y: 200 };
              setPromptPosition({ x: 85, y: 190 });
            }
            if (touchEndCount >= 21) {
              setIsRed(true);
              drawPoint = { x: 130, y: 60 };
              // setApproxStartingPnt(drawPoint);
              setPromptPosition({ x: 85, y: 65 });
              setPrompt('Wrong, Start on the top center!');
            }
            if (touchEndCount === 22) {
              drawPoint = { x: 130, y: 85 };
              // setApproxStartingPnt(drawPoint);
              setPromptPosition({ x: 50, y: 85 });
              setPrompt('Wrong, Start on the top left!');
            }
            if (touchEndCount === 23 || touchEndCount === 24) {
              drawPoint = { x: 130, y: 65 };
              // setApproxStartingPnt(drawPoint);
              setPromptPosition({ x: 60, y: 65 });
              setPrompt('Wrong, Start on the top left!');
            }
            if (touchEndCount === 25 || touchEndCount === 26) {
              drawPoint = { x: 190, y: 65 };
              // setApproxStartingPnt(drawPoint);
              setPromptPosition({ x: 90, y: 65 });
              setPrompt('Wrong, Start on the top right!');
            }
            if (touchEndCount === 27) {
              drawPoint = { x: 130, y: 85 };
              // setApproxStartingPnt(drawPoint);
              setPromptPosition({ x: 60, y: 85 });
              setPrompt('Wrong, Start on the top left!');
            }
            if (touchEndCount === 28) {
              drawPoint = { x: 200, y: 200 };
              // setApproxStartingPnt(drawPoint);
              setPromptPosition({ x: 85, y: 190 });
              setPrompt('Wrong, Start on the center');
            }
            if (touchEndCount === 29) {
              drawPoint = { x: 210, y: 65 };
              // setApproxStartingPnt(drawPoint);
              setPromptPosition({ x: 90, y: 65 });
              setPrompt('Wrong, Start on the top right!');
            }
          } else {
            this.isOut = true;
            handleIsWrong(true);
            setTimeout(() => {
              handleIsWrong(false);
            }, 1000);
            this.isDrawing = false;
          }
        }
      },
      touchout() {
        if (this.isDrawing) {
          this.isDrawing = false;
          this.isOut = true;
          // touchEndCount++;
        }
      },

      touchend(coors) {
        let touchPoints;
        touchPoints =
          touchEndCount === 22 || touchEndCount === 23 || touchEndCount === 27 || touchEndCount === 24
            ? drawPoint.x > startingPoint.x - 50 &&
              drawPoint.x <= startingPoint.x + 50 &&
              drawPoint.y > startingPoint.y - 25 &&
              drawPoint.y <= startingPoint.y + 25
            : touchEndCount === 21
            ? drawPoint.x > startingPoint.x - 70 &&
              drawPoint.x <= startingPoint.x + 70 &&
              drawPoint.y > startingPoint.y - 50 &&
              drawPoint.y <= startingPoint.y + 50
            : touchEndCount === 25 || touchEndCount === 26 || touchEndCount === 29
            ? drawPoint.x > startingPoint.x - 70 &&
              drawPoint.x <= startingPoint.x + 10 &&
              drawPoint.y > startingPoint.y - 30 &&
              drawPoint.y <= startingPoint.y + 30
            : drawPoint.x > startingPoint.x - 30 &&
              drawPoint.x <= startingPoint.x + 30 &&
              drawPoint.y > startingPoint.y - 30 &&
              drawPoint.y <= startingPoint.y + 30;

        if (touchPoints) {
          if (this.isDrawing) {
            this.touchmove(coors);
            // this.touchout();
            this.isDrawing = false;
          }
          if (!this.isOut) {
            // console.log('touchEndCount:', touchEndCount);
            // console.log('coors:', coors);

            touchEndCount >= 10 && touchEndCount < 20
              ? setInstruction(2)
              : touchEndCount >= 20
              ? setInstruction(3)
              : setInstruction(1);

            touchEndCount++;
            count = count + 1;
            setCountRecord(count);
            if (touchEndCount >= 11) {
              setIsBlue(true);
              drawPoint = { x: 200, y: 200 };
              setPromptPosition({ x: 85, y: 190 });
            }
            if (touchEndCount === 21) {
              setIsRed(true);
              drawPoint = { x: 130, y: 60 };
              // setApproxStartingPnt(drawPoint);
              setPromptPosition({ x: 85, y: 65 });
              setPrompt('Wrong, Start on the top center!');
            }
            if (touchEndCount === 22) {
              drawPoint = { x: 130, y: 85 };
              // setApproxStartingPnt(drawPoint);
              setPromptPosition({ x: 50, y: 85 });
              setPrompt('Wrong, Start on the top left!');
            }
            if (touchEndCount === 23 || touchEndCount === 24) {
              drawPoint = { x: 130, y: 65 };
              // setApproxStartingPnt(drawPoint);
              setPromptPosition({ x: 60, y: 65 });
              setPrompt('Wrong, Start on the top left!');
            }
            if (touchEndCount === 25 || touchEndCount === 26) {
              drawPoint = { x: 190, y: 65 };
              // setApproxStartingPnt(drawPoint);
              setPromptPosition({ x: 90, y: 65 });
              setPrompt('Wrong, Start on the top right!');
            }
            if (touchEndCount === 27) {
              drawPoint = { x: 130, y: 85 };
              // setApproxStartingPnt(drawPoint);
              setPromptPosition({ x: 60, y: 85 });
              setPrompt('Wrong, Start on the top left!');
            }
            if (touchEndCount === 28) {
              drawPoint = { x: 200, y: 200 };
              // setApproxStartingPnt(drawPoint);
              setPromptPosition({ x: 85, y: 190 });
              setPrompt('Wrong, Start on the center');
            }
            if (touchEndCount === 29) {
              drawPoint = { x: 210, y: 65 };
              setPromptPosition({ x: 90, y: 65 });
              setPrompt('Wrong, Start on the top right!');
            }

            if (touchEndCount === 30) {
              drawPoint = { x: 210, y: 75 };
              // setApproxStartingPnt(drawPoint);
              setPromptPosition({ x: 90, y: 65 });
              // setPrompt('Wrong, Start on the top right!');
              handleIsWrong(false, 9);
              touchEndCount = 1;
              drawingEnd = true;
            }
          }
        } else {
          handleIsWrong(true);
          setTimeout(() => {
            handleIsWrong(false);
          }, 1000);
          this.isDrawing = false;
        }
      },
    };

    function draw(event) {
      let type = null;
      switch (event.type) {
        case 'mousedown':
          event.touches = [];
          event.touches[0] = {
            pageX: event.pageX,
            pageY: event.pageY,
          };
          type = 'touchstart';
          break;
        case 'mousemove':
          event.touches = [];
          event.touches[0] = {
            pageX: event.pageX,
            pageY: event.pageY,
          };
          type = 'touchmove';
          break;
        case 'mouseup':
          event.touches = [];
          event.touches[0] = {
            pageX: event.pageX,
            pageY: event.pageY,
          };
          type = 'touchend';
          break;
        case 'mouseout':
          event.touches = [];
          event.touches[0] = {
            pageX: event.pageX,
            pageY: event.pageY,
          };
          type = 'touchout';
          break;
        default:
          break;
      }

      let coors;
      // let rect = canvas.getBoundingClientRect();
      // const currentX = Math.round(event.clientX - rect.left);
      // const currentY = Math.round(event.clientY - rect.top);
      //
      // directionX = currentX > lastX ? 'right' : 'left';
      // directionY = currentY > lastY ? 'down' : 'up';
      //
      // lastX = currentX;
      // lastY = currentY;

      // console.log('event:', event);
      // console.log('rect:', rect);

      if (event.type === 'touchend') {
        coors = {
          x: event.changedTouches[0].pageX,
          y: event.changedTouches[0].pageY,
        };
      } else {
        coors = {
          x: event.touches[0].pageX,
          y: event.touches[0].pageY,
        };
      }
      type = type || event.type;
      !drawingEnd && drawer[type](coors);
    }

    const touchAvailable = 'createTouch' in document || 'ontouchstart' in window;

    if (touchAvailable) {
      canvas.addEventListener('touchstart', draw, false);
      canvas.addEventListener('touchmove', draw, false);
      canvas.addEventListener('touchend', draw, false);
      canvas.addEventListener('touchout', draw, false);
    } else {
      canvas.addEventListener('mousedown', draw, false);
      canvas.addEventListener('mousemove', draw, false);
      canvas.addEventListener('mouseup', draw, false);
      canvas.addEventListener('mouseout', draw, false);
    }

    window.addEventListener(
      'resize',
      (event) => {
        event.preventDefault();
        canvasOffset = getOffsetSum(canvas);
      },
      false,
    );

    document.body.addEventListener(
      'touchmove',
      (event) => {
        event.preventDefault();
      },
      false,
    );

    function recognizeN() {
      return new Promise((resolve, reject) => {
        if (isRecognized) return;
        let imgData = context.getImageData(0, 0, 280, 280);

        grayscaleImg = imageDataToGrayscale(imgData);
        const boundingRectangle = getBoundingRectangle(grayscaleImg, 0.01);
        // const trans = centerImage(grayscaleImg); // [dX, dY] to center of mass

        const canvasCopy = document.createElement('canvas');

        const copyCtx = canvasCopy.getContext('2d');
        const brW = boundingRectangle.maxX + 1 - boundingRectangle.minX;
        const brH = boundingRectangle.maxY + 1 - boundingRectangle.minY;
        const scaling = 190 / (brW > brH ? brW : brH);

        grayscaleImg = imageDataToGrayscale(imgData);

        const nnInput = new Array(784);
        const nnInput2 = [];
        for (let y = 0; y < 28; y++) {
          for (let x = 0; x < 28; x++) {
            let mean = 0;
            for (let v = 0; v < 10; v++) {
              for (let h = 0; h < 10; h++) {
                mean += grayscaleImg[y * 10 + v][x * 10 + h];
              }
            }
            mean = 1 - mean / 100;
            nnInput[x * 28 + y] = (mean - 0.5) / 0.5;
          }
        }

        context.drawImage(copyCtx.canvas, 0, 0);
        for (let y = 0; y < 28; y++) {
          for (let x = 0; x < 28; x++) {
            const block = context.getImageData(x * 10, y * 10, 10, 10);
            const newVal = 255 * (0.5 - nnInput[x * 28 + y] / 2);
            nnInput2.push(Math.round(((255 - newVal) / 255) * 100) / 100);
            for (let i = 0; i < 4 * 10 * 10; i += 4) {
              block.data[i] = newVal;
              block.data[i + 1] = newVal;
              block.data[i + 2] = newVal;
              block.data[i + 3] = 255;
            }
          }
        }

        const output = nn(nnInput2);
        isRecognized = true;
        resolve(output);
      });
    }

    function net(input) {
      for (var i = 1; i < netData.layers.length; i++) {
        var layer = netData.layers[i];
        var output = {};

        for (var id in layer) {
          var node = layer[id];
          var sum = node.bias;

          for (var iid in node.weights) {
            sum += node.weights[iid] * input[iid];
          }
          output[id] = 1 / (1 + Math.exp(-sum));
        }
        input = output;
      }
      return output;
    }

    function getMax(output) {
      let array = [];
      for (let i in output) {
        array.push(output[i]);
      }
      const max = Math.max(...array);
      return array.indexOf(max);
    }

    function nn(input) {
      var output = net(input);

      return getMax(output);
    }

    function getBoundingRectangle(img, threshold) {
      let rows = img.length;
      let columns = img[0].length;
      let minX = columns;
      let minY = rows;
      let maxX = -1;
      let maxY = -1;
      for (let y = 0; y < rows; y++) {
        for (let x = 0; x < columns; x++) {
          if (img[y][x] < threshold) {
            if (minX > x) minX = x;
            if (maxX < x) maxX = x;
            if (minY > y) minY = y;
            if (maxY < y) maxY = y;
          }
        }
      }
      return { minY: minY, minX: minX, maxY: maxY, maxX: maxX };
    }

    function imageDataToGrayscale(imgData) {
      for (let y = 0; y < imgData.height; y++) {
        grayscaleImg[y] = [];
        for (let x = 0; x < imgData.width; x++) {
          let offset = y * 4 * imgData.width + 4 * x;
          let alpha = imgData.data[offset + 3];
          if (alpha === 0) {
            imgData.data[offset] = 255;
            imgData.data[offset + 1] = 255;
            imgData.data[offset + 2] = 255;
          }
          imgData.data[offset + 3] = 255;
          grayscaleImg[y][x] = imgData.data[y * 4 * imgData.width + x * 4] / 255;
        }
      }
      return grayscaleImg;
    }

    return () => {
      effectRef.current = true;
    };
  }, [isBlue, isRed]);

  useEffect(() => {
    if (!isBlue) return;
    let canvas = document.getElementById('sketchpad');
    // const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const bgImage = new Image();
    bgImage.src = isRed ? redImg : blueImg;
    bgImage.onload = function () {
      ctx.drawImage(bgImage, 115, 50, 140, 300);
    };
  }, [isBlue, isRed]);

  useEffect(() => {
    if (!isRed) return;
    let canvas = document.getElementById('sketchpad');
    // const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Load the background image
    const bgImage = new Image();
    bgImage.src = redImg;
    bgImage.onload = function () {
      ctx.drawImage(bgImage, 115, 50, 140, 300);
    };
  }, [isRed]);

  return (
    <div className={className}>
      <div id="container" className={styles.container}>
        <canvas id="sketchpad" className={styles.sketchpad} ref={canvasRef} width={380} height={380}>
          Sorry, your browser is not supported.
        </canvas>
      </div>
      <div id="result" className={styles.results}></div>
    </div>
  );
};

export default Canvas;
