import React from 'react';
import { ReactComponent as CogPlayLogo } from '../../assets/images/COGPLAYLOGO.svg';
import styles from './CogPlayIcon.module.scss';

const CogPlayIcon = () => {
  return (
    <div className={styles.cogPlayIcon}>
      <CogPlayLogo width="88px" />
      Cognitive Playground
    </div>
  );
};

export default CogPlayIcon;
