import Types from '../types/quizTypes';

const initialState = {
  loading: false,
  quizData: {},
  tabPath: '/',
  tabKey: '0',
  alienMove: [0, 0, 0, 0],
  modelTitle: '',
  recording: false,
  currentQuizKey: [
    {
      tabKey: '0',
      key: 0,
      subKey: 0,
    },
    {
      tabKey: '0',
      key: 0,
      subKey: 0,
    },
    {
      tabKey: '0',
      key: 0,
      subKey: 0,
    },
    {
      tabKey: '0',
      key: 0,
      subKey: 0,
    },
  ],
};

export default function QuizReducer(state = initialState, action) {
  switch (action.type) {
    case Types.ATTEMPT_SET_QUIZ:
      return {
        ...state,
        loading: true,
      };
    case Types.SUCCESS_SET_QUIZ:
      return {
        ...state,
        loading: false,
        quizData: action.payload,
      };
    case Types.FAILURE_SET_QUIZ:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };
    case Types.ATTEMPT_GET_QUIZ:
      return {
        ...state,
        loading: true,
      };
    case Types.SUCCESS_GET_QUIZ:
      return {
        ...state,
        loading: false,
        quizData: action.payload,
      };
    case Types.FAILURE_GET_QUIZ:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };
    case Types.SUCCESS_SET_TAB:
      return {
        ...state,
        loading: false,
        tabKey: action.payload,
      };
    case Types.SUCCESS_SET_RESUME_QUIZ:
      const { currentQuizKey, tabKey } = state;
      const tempResumeQuiz = currentQuizKey;
      tempResumeQuiz[+tabKey] = action.payload;
      return {
        ...state,
        loading: false,
        currentQuizKey: tempResumeQuiz,
      };
    case Types.SUCCESS_SET_TAB_PATH:
      return {
        ...state,
        loading: false,
        tabPath: action.payload,
      };
    case Types.SUCCESS_SET_ALIEN_MOVE:
      const tempTabKey = state.tabKey;
      const tempAlienMove = state.alienMove;
      tempAlienMove[+tempTabKey] = +action.payload;
      return {
        ...state,
        loading: false,
        alienMove: tempAlienMove,
      };

    case Types.SUCCESS_AUDIO_RECORDING:
      return {
        ...state,
        recording: action.payload,
      };

    case Types.CLEAR_SUCCESS:
      return {
        ...state,
        quizData: {},
        tabPath: '/',
        tabKey: '0',
        alienMove: [0, 0, 0, 0],
        modelTitle: '',
        recording: false,
        currentQuizKey: [
          {
            tabKey: '0',
            key: 0,
            subKey: 0,
          },
          {
            tabKey: '0',
            key: 0,
            subKey: 0,
          },
          {
            tabKey: '0',
            key: 0,
            subKey: 0,
          },
          {
            tabKey: '0',
            key: 0,
            subKey: 0,
          },
        ],
      };
    case Types.SET_MODEL_DETAIL:
      return {
        ...state,
        loading: false,
        modelTitle: action.payload,
      };
    default:
      return state;
  }
}
