import React, { useState } from 'react';
import CustomButton from '../../../components/Button';
import styles from '../User_Profile.module.scss';
import { useDispatch } from 'react-redux';

const ConfirmModal = (props) => {
  const { loading, cancelSubscriptionApi, hideModal, user_subscription } = props;

  const dispatch = useDispatch();
  const cancelSubmit = () => {
    const data = {
      subscription_id: user_subscription?.[0]?.id,
    };
    dispatch(cancelSubscriptionApi(data, hideModal));
  };

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.paymentText}>
          <div className={styles.headingText}>
            <span>Cancel Subscription Confirmation</span>
          </div>
          <div className={styles.subHeadingText}>
            <span>Are you sure you want to cancel your current subscription?.</span>
          </div>
        </div>
        <div className={styles.paymentFooter}>
          <CustomButton type="primary" loading={loading} onClick={cancelSubmit}>
            Confirm
          </CustomButton>
        </div>

        <CustomButton type="link" onClick={hideModal}>
          Cancel
        </CustomButton>
      </div>
    </>
  );
};

export default ConfirmModal;
