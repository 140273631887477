import React, { useEffect, useState } from 'react';
import styles from './User_Profile.module.scss';
import CustomButton from '../../components/Button';
import { Tabs, Modal } from 'antd';
import TabInform from '../Quiz/Program_Menu/Tab_Inform';
import { useNavigate } from 'react-router-dom';
import { tabs } from '../quizData/tabs';

import { useDispatch, useSelector } from 'react-redux';
import { useReducerData } from '../../store/hooks';
import { isLoggedIn } from '../../helpers/utility';

//images
import modalImage from '../../assets/images/modalHeaderImage.png';
import { ReactComponent as CloseIcon } from '../../assets/images/closeIconModal.svg';
import CogPlayIcon from '../../components/CogPlayIcon/CogPlayIcon';
import ConfirmModal from './ConfirmModal';

//action
import { attemptGetSubUserProfile, attemptGetUserProfile } from '../../store/actions/authActions';
import { attemptSetTab, attemptSetTabPath } from '../../store/actions/quizActions';
import { cancelSubscriptionApi, checkSubscriptionApi } from '../../store/actions/subscriptionActions';

const { TabPane } = Tabs;

const User_Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tabKey = useReducerData('quiz', 'tabKey' || '0');
  const [open, setOpen] = useState(false);
  const userData = useSelector((state) => state.auth);
  const loading = useSelector((state) => state.subscription.cancelLoading);
  const { user, user_subscription } = userData;

  const showModal = () => {
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (user?.user_type !== 'Admin') {
      dispatch(checkSubscriptionApi());
    }
    dispatch(attemptGetUserProfile());
    const checkUser = user?.user_type === 'Children' || user?.user_type === 'Student';
    if (!checkUser) {
      dispatch(attemptGetSubUserProfile());
    }
  }, [user]);

  const onChange = async (key) => {
    await dispatch(attemptSetTab(key));
  };

  return (
    <>
      <div className={styles.mainContainer}>
        {user?.user_type === 'Children' || user?.user_type === 'Student' ? null : isLoggedIn() ? (
          <div className={styles.message}>
            <div className={styles.messageText}>
              <span>Thanks for becoming a member!</span>
            </div>
            <div className={styles.changeButton}>
              <CustomButton
                type="primary"
                onClick={() => {
                  dispatch(attemptSetTabPath('/pricing'));
                  navigate('/pricing');
                }}
              >
                {user?.user_type === 'All' ? 'Become a member' : 'Change plan'}
              </CustomButton>
              {user?.user_type !== 'All' ? (
                <CustomButton
                  type="primary"
                  danger={true}
                  className="btn-info"
                  disabled={user_subscription && user_subscription[0]?.cancel_at_period_end}
                  onClick={showModal}
                >
                  Cancel subscription
                </CustomButton>
              ) : null}
            </div>
          </div>
        ) : (
          <div className={styles.message}>
            <div className={styles.messageText}>
              <span>Become a member of Cogplay</span>
            </div>
            <div className={styles.changeButton}>
              <CustomButton type="primary" onClick={() => navigate('/pricing')}>
                Become a member
              </CustomButton>
            </div>
          </div>
        )}

        <div className={styles.programContainer}>
          <div className={styles.heading}>
            <span>Math program</span>
          </div>
          <div className={styles.tabContainer}>
            <Tabs defaultActiveKey="0" activeKey={tabKey} onChange={onChange} centered>
              {tabs.map((tabData, i) => (
                <TabPane tab={tabData?.tab?.type} key={i}>
                  <TabInform tabKey={tabKey} />
                </TabPane>
              ))}
            </Tabs>
          </div>
        </div>
      </div>
      <Modal
        title={<img src={modalImage} alt={modalImage} className={styles.imgHeader} />}
        centered
        open={open}
        onCancel={hideModal}
        className={styles.checkoutModalStyle}
        closeIcon={<CloseIcon height="20px" />}
        footer={null}
      >
        <div className={styles.cogplayIcon}>
          <CogPlayIcon />
        </div>
        <ConfirmModal
          hideModal={hideModal}
          user_subscription={user_subscription}
          loading={loading}
          cancelSubscriptionApi={cancelSubscriptionApi}
        />
      </Modal>
    </>
  );
};

export default User_Profile;
