import Types from '../types/AuthTypes';
import Cookies from 'js-cookie';

const initialState = {
  loading: false,
  isError: false,
  message: '',
  status: null,
  isLoggedIn: false,
  isActiveSession: null,
  lastCallRefresh: null,
  user: null,
  currentUser: null,
  profileUserData: [],
  selectedUserType: '',
  isSubscriptionCancel: false,
  login: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: {},
  },
  freeTrial: false,
  documents: [],
  resetPassword: {
    resetEmail: '',
    otp: '',
  },
  user_subscription: [],
};

export default function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case Types.LOGIN_INPROGRESS:
      return {
        ...state,
        login: {
          ...state.login,
          loading: true,
          isError: false,
          message: '',
          status: null,
        },
      };
    case Types.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        status: action.status,
        isActiveSession: true,
        isLoggedIn: true,
        user: action.user,
        user_subscription: action?.subscription,
        freeTrial: action?.freeTrial,
      };
    case Types.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        isError: true,
        message: action.message,
        status: action.status,
      };

    case Types.UPDATE_CURRENT_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case Types.SIGNUP_INPROGRESS:
      return {
        ...state,
        login: {
          ...state.login,
          loading: true,
          isError: false,
          message: '',
          status: null,
        },
      };
    case Types.SIGNUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        isError: false,
        status: action.status,
        isActiveSession: true,
        isLoggedIn: true,
        // user: action.user,
      };
    }
    case Types.SIGNUP_FAILURE:
      return {
        ...state,
        loading: false,
        isError: true,
        message: action.message,
        status: action.status,
      };

    case Types.USER_SUBSCRIPTION:
      return {
        ...state,
        user_subscription: action.payload?.user_subscription,
        freeTrial: action?.payload?.free_trial,
        isSubscriptionCancel: action.payload?.is_cancelled ? action.payload?.is_cancelled : false,
      };
    case Types.RESET_PASSWORD_INPROGRESS:
      return {
        ...state,
        login: {
          ...state.login,
          loading: true,
          isError: false,
          message: '',
          status: null,
        },
      };
    case Types.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        isError: false,
        status: action.status,
        isActiveSession: true,
        resetPassword: {
          ...state.resetPassword,
          resetEmail: action.payload.email,
        },
      };
    }
    case Types.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        isError: true,
        message: action.message,
        status: action.status,
      };

    case Types.VERIFY_PASSWORD_INPROGRESS:
      return {
        ...state,
        login: {
          ...state.login,
          loading: true,
          isError: false,
          message: '',
          status: null,
        },
      };
    case Types.VERIFY_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        isError: false,
        status: action.status,
        isActiveSession: true,
        // isLoggedIn: true,
        user: action.user,
      };
    }
    case Types.VERIFY_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        isError: true,
        message: action.message,
        status: action.status,
      };

    case Types.FORGOT_PASSWORD_OTP:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          otp: action.otp,
        },
      };
    case Types.ATTEMPT_GET_USER_PROFILE:
      return {
        ...state,
        loading: true,
      };
    case Types.ATTEMPT_USER_FEEDBACK:
      return {
        ...state,
        loading: true,
      };
    case Types.SUCCESS_USER_FEEDBACK:
      return {
        ...state,
        loading: false,
      };
    case Types.FAILED_USER_FEEDBACK:
      return {
        ...state,
        loading: false,
      };

    case Types.SUCCESS_GET_USER_PROFILE:
      return {
        ...state,
        loading: false,
        profileUserData: action.payload,
      };
    case Types.FAILURE_GET_USER_PROFILE:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };
    case Types.EDIT_SUB_PROFILE_IN_PROGRESS:
      return {
        ...state,
        login: {
          ...state.login,
          loading: true,
          isError: false,
          message: '',
          status: null,
        },
        loading: true,
      };
    case Types.EDIT_SUB_PROFILE_SUCCESS: {
      return {
        ...state,
        loading: false,
        isError: false,
        status: action.status,
        isActiveSession: true,
        isLoggedIn: true,
        // user: action.user,
      };
    }
    case Types.EDIT_SUB_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        isError: true,
        message: action.message,
        status: action.status,
      };
    case Types.SET_SELECTED_USER_TYPE:
      return {
        ...state,
        loading: false,
        selectedUserType: action.payload,
      };

    case Types.LOGOUT_SUCCESS:
    case Types.DELETE_ACCOUNT_SUCCESS:
      Cookies.remove('token');
      sessionStorage.clear();
      localStorage.clear();
      return {
        ...state,
        initialState,
      };

    case Types.CREATE_SUB_PROFILE_IN_PROGRESS:
      return {
        ...state,
        loading: true,
      };

    case Types.CREATE_SUB_PROFILE_SUCCESS:
    case Types.CREATE_SUB_PROFILE_FAILED:
      return {
        ...state,
        loading: false,
      };
    case Types.GET_CURRENT_USER_PROFILE_ATTEMPT:
      return {
        ...state,
        loading: true,
      };

    case Types.GET_CURRENT_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload,
      };

    case Types.GET_CURRENT_USER_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case Types.EDIT_CURRENT_PROFILE_IN_PROGRESS:
      return {
        ...state,
        loading: true,
      };

    case Types.EDIT_CURRENT_PROFILE_SUCCESS:
      localStorage.setItem('user', JSON.stringify(action.payload));
      return {
        ...state,
        loading: false,
        currentUser: action.payload,
        user: action.payload,
      };

    case Types.EDIT_CURRENT_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case Types.GET_USER_DOCUMENTS:
      return {
        ...state,
        loading: true,
      };
    case Types.GET_USER_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        documents: action.payload,
      };

    default:
      return state;
  }
}
