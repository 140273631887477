import React from 'react';
import { Modal } from 'antd';
import styles from './Inform.module.scss';
import { ReactComponent as CloseIcon } from '../../../assets/images/closeIconModal.svg';
import BrainModal from './BrainModal/BrainModal';
import EyeTrackingModal from './EyeTrackingModal/EyeTrackingModal';
import TeachingModal from './TeachingModal/TeachingModal';
import FullBodyModal from './FullBodyModal/FullBodyModal';
import ConfirmationModal from './ConfirmationModal';

const InformModal = ({ isModalVisible, setIsModalVisible, modalIndex }) => {
  const renderContent = () => {
    switch (modalIndex) {
      case 0:
        return { component: <BrainModal />, styleCls: styles.brainModalStyle };
      case 1:
        return { component: <EyeTrackingModal />, styleCls: styles.eyeTrackingModalStyle };
      case 2:
        return { component: <TeachingModal />, styleCls: styles.teachingModalStyle };
      case 3:
        return { component: <FullBodyModal />, styleCls: styles.fullBodyModalStyle };
      case 'confirmation':
        return { component: <ConfirmationModal  setModal={setIsModalVisible} modal={isModalVisible} />, styleCls: styles.confirmationModalStyle };
      default:
        return { component: <BrainModal />, styleCls: styles.brainModalStyle };
    }
  };

  return (
    <>
      <Modal
        centered={modalIndex === 'confirmation'}
        open={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        className={renderContent().styleCls}
        closeIcon={<CloseIcon height="20px" />}
        footer={null}
        // style={{ marginTop: '30px' }}
      >
        {renderContent().component}
      </Modal>
    </>
  );
};

export default InformModal;
