import React, { useState } from 'react';
import styles from './Profile.module.scss';
import { Form, Switch, Col, Row } from 'antd';
import CustomInput from '../../components/Input';
import CustomPassword from '../../components/InputPassword/InputPassword';
import { validatePassword } from '../../helpers/constants';
import CustomInputNumber from '../../components/InputNumber/inputNumber';
import CustomInputDropDown from '../../components/InputDropDown';
import CustomButton from '../../components/Button';
import { attemptGetSubUserProfile, attemptGetUserProfile, createSubProfile } from '../../store/actions/authActions';
import { editSubProfile } from '../../store/actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
import { useReducerData } from '../../store/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import {
  getCountries,
  getStates,
  getCities,
  getCurrentCountry,
  getCurrentCity,
  getCurrentState,
  capitalizeWords,
} from '../../helpers/utility';

const ParentMenu = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const id = location.pathname.split('/');
  const { loading } = useSelector((state) => state.auth);
  const isUserFound = id[id.length - 1];
  const profileUserData = useReducerData('auth', 'profileUserData');
  const [showInput, setShowInput] = useState(false);
  let existingUser = null;

  if (/\d/.test(isUserFound)) {
    existingUser = profileUserData.find((user) => user.user.id === Number(isUserFound));
  }

  const [parentFormData, setParentFormData] = useState({
    email: existingUser?.user.email || '',
    password: '',
    confirmPassword: '',
    name: existingUser?.user.name || '',
    first_name: existingUser?.first_name || '',
    age: existingUser?.age || '',
    grade: existingUser?.grade || '',
    country: (existingUser?.country && getCurrentCountry(existingUser?.country)) || '',
    state: (getCurrentState(existingUser?.state)?.name !== undefined ? getCurrentState(existingUser?.state) : {name:existingUser?.state}) || '',
    city: (getCurrentCity(existingUser?.city)?.name !== undefined ? getCurrentCity(existingUser?.city) : {name:existingUser?.city}) || '',
    school_district: (getCurrentCity(existingUser?.school_district).name !== undefined ? getCurrentCity(existingUser?.school_district) : {name:existingUser?.school_district}) || '',
    disability: existingUser?.disability || '',
    user_type: 'Children',
  });
  const handleChange = ({ target: { name, value } }) => {
    setParentFormData({
      ...parentFormData,
      [name]: name === 'name' || name === 'first_name' || name === 'disability' ? capitalizeWords(value) : value,
    });
  };

  const handleSelection = (name, value, option) => {
    if (option) {
      const data = {
        name: name === 'country' || name === 'state' ? option?.children : value,
        value,
      };
      setParentFormData((prevData) => {
        if (name === 'country') {
          form.resetFields(['state', 'city', 'school_district']);
          return { ...prevData, [name]: data, state: null, city: null, school_district: null };
        } else if (name === 'state') {
          form.resetFields(['city', 'school_district']);
          return { ...prevData, [name]: data, city: null, school_district: null };
        } else {
          return { ...prevData, [name]: data };
        }
      });
    }
  };
  const handleSubmit = async () => {
    // if (existingUser) {
    //   delete parentFormData['name'];
    //   delete parentFormData['password'];
    //   delete parentFormData['confirmPassword'];
    //   delete parentFormData['email'];
    //   delete parentFormData['user_type'];
    // }
    if (parentFormData?.age <= 100) {
      parentFormData['country'] = parentFormData?.country?.name
        ? parentFormData?.country?.name
        : parentFormData?.country;
      parentFormData['state'] = parentFormData?.state?.name ? parentFormData?.state?.name : parentFormData?.state;
      parentFormData['city'] = parentFormData?.city?.name ? parentFormData?.city?.name : parentFormData?.city;
      parentFormData['school_district'] = parentFormData?.school_district?.name
        ? parentFormData?.school_district?.name
        : parentFormData?.school_district;
      existingUser
        ? await dispatch(editSubProfile(existingUser.id, parentFormData, callBack, 'child'))
        : await dispatch(createSubProfile(parentFormData, callBack, 'child'));
    } else {
      notification['error']({
        message: 'Age should not be grater then 100',
      });
    }
  };
  const callBack = () => {
    navigate('/profile');
    setParentFormData({
      email: '',
      password: '',
      confirmPassword: '',
      name: '',
      first_name: '',
      age: '',
      grade: '',
      country: '',
      state: '',
      city: '',
      school_district: '',
      disability: '',
      user_type: 'Children',
    });
    form.resetFields();
    dispatch(attemptGetSubUserProfile());
    dispatch(attemptGetUserProfile());
  };

  const handleSwitch = () => {
    setShowInput(!showInput);
    setParentFormData((prev) => ({ ...prev, state: '', city: '', school_district: '' }))
    form.resetFields(['state', 'city', 'school_district']);
  }

  return (
    <>
      <div className={styles.formContainer}>
        <div className={styles.formInput}>
          <Form colon={false} onFinish={handleSubmit} autoComplete="off" form={form}>
            {/* {!existingUser && ( */}
            <>
              <CustomInput
                label="Child's Full Name"
                inputType="input"
                name="name"
                value={parentFormData.name}
                defaultValue={parentFormData.name}
                onChange={handleChange}
                placeholder="Enter your child full name"
                maxLength={50}
                rules={
                  parentFormData.name === '' && [
                    {
                      required: true,
                      message: 'Please enter your child name',
                      whitespace: true,
                    },
                  ]
                }
              // allowClear
              />

              <CustomInput
                label="Child's Email"
                inputType="email"
                name="email"
                value={parentFormData.email}
                defaultValue={parentFormData.email}
                onChange={handleChange}
                placeholder="Enter your child's email"
                rules={
                  // parentFormData.email === '' && 
                  [
                    {
                      required: true,
                      message: "Please enter your child's email!",
                    },
                    {
                      type: 'email',
                      message: 'Please enter valid E-mail!',
                    },
                  ]
                }
              // allowClear
              />

              <CustomPassword
                label="Password/Change Password"
                inputType="password"
                name="password"
                value={parentFormData.password}
                onChange={handleChange}
                placeholder="Enter your password"
                rules={
                  !existingUser && [
                    ({ getFieldValue }) => ({
                      validator() {
                        if (!validatePassword(getFieldValue('password'))) {
                          if (!getFieldValue('password')) {
                            return Promise.reject(new Error('Please enter password'));
                          }
                          return Promise.reject(new Error('Please enter strong password'));
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]
                }
              // allowClear
              />
              <CustomPassword
                label="Confirm Password"
                inputType="password"
                name="confirmPassword"
                value={parentFormData.confirmPassword}
                onChange={handleChange}
                placeholder="Confirm password"
                dependencies={['password']}
                rules={
                  parentFormData?.password && [
                    {
                      required: true,
                      message: 'Please enter confirm password',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('confirm password does not match with change password'));
                      },
                    }),
                  ]
                }
              // allowClear
              />
              {/* <hr /> */}
            </>
            {/* )} */}

            <CustomInput
              label="Child's First Name"
              inputType="input"
              name="first_name"
              defaultValue={parentFormData.first_name}
              value={parentFormData.first_name}
              onChange={handleChange}
              placeholder="Enter first name"
              maxLength={50}
              rules={
                parentFormData.first_name === '' && [
                  {
                    required: true,
                    message: 'Please enter first name',
                    whitespace: true,
                  },
                ]
              }
            // allowClear
            />

            <CustomInputNumber
              label="Child's Age"
              name="age"
              defaultValue={parentFormData.age}
              value={parentFormData.age}
              onChange={handleChange}
              placeholder="Enter Age"
              type="number"
              maxLength={true}
              rules={
                parentFormData.age === '' && [
                  {
                    type: 'number',
                    required: true,
                    message: 'Please enter Age',
                    whitespace: true,
                  },
                ]
              }
            />

            <CustomInput
              label="Child's Grade"
              inputType="input"
              name="grade"
              value={parentFormData.grade}
              defaultValue={parentFormData.grade}
              onChange={handleChange}
              placeholder="Enter Grade"
              rules={
                parentFormData.grade === '' && [
                  {
                    required: true,
                    message: 'Please enter grade',
                    whitespace: true,
                  },
                ]
              }
            // allowClear
            />


            <CustomInputDropDown
              label="Child's Country"
              data={getCountries && getCountries}
              placeholder="Enter Country"
              name="country"
              selectedValue={parentFormData?.country?.value}
              onChange={(e, option) => handleSelection('country', e, option)}
              rules={
                parentFormData?.country === '' && [
                  {
                    required: true,
                    message: 'Please enter country',
                    whitespace: true,
                  },
                ]
              }
            />


            {getStates(parentFormData?.country?.value).length > 0 &&
              <CustomInputDropDown
                label="Child's State/Province"
                data={getStates(parentFormData?.country?.value) && getStates(parentFormData?.country?.value)}
                placeholder="Enter State/Province"
                name="state"
                selectedValue={parentFormData?.state ? parentFormData?.state?.name : ''}
                onChange={(e, option) => handleSelection('state', e, option)}
                rules={
                  !!getStates(parentFormData?.country?.value).length &&
                  (parentFormData?.state === '' || parentFormData?.state === null) && [
                    {
                      required: true,
                      message: 'Please enter state',
                      whitespace: true,
                    },
                  ]
                }
              />}

            {getCities(parentFormData?.country?.value, parentFormData?.state?.value)?.length > 0 &&
              <CustomInputDropDown
                label="Child's city"
                data={
                  getCities(parentFormData?.country?.value, parentFormData?.state?.value) &&
                  getCities(parentFormData?.country?.value, parentFormData?.state?.value)
                }
                placeholder="Enter city"
                name="city"
                selectedValue={parentFormData?.city ? parentFormData?.city?.value : null}
                onChange={(e, option) => handleSelection('city', e, option)}
                rules={
                  !!getCities(parentFormData?.country?.value, parentFormData?.state?.value).length &&
                  (parentFormData?.city === '' || parentFormData?.city === null) && [
                    {
                      required: true,
                      message: 'Please enter city',
                      whitespace: true,
                    },
                  ]
                }
              />}
            {(getCities(parentFormData?.country?.value, parentFormData?.state?.value).length > 0) &&
              <CustomInputDropDown
                label="Child's School District"
                data={
                  getCities(parentFormData?.country?.value, parentFormData?.state?.value) &&
                  getCities(parentFormData?.country?.value, parentFormData?.state?.value)
                }
                placeholder="School District"
                name="school_district"
                selectedValue={parentFormData.school_district?.value}
                onChange={(e, option) => handleSelection('school_district', e, option)}
                rules={
                  getCities(parentFormData?.country?.value, parentFormData?.state?.value).length &&
                  (parentFormData?.school_district === '' || parentFormData.school_district === null) && [
                    {
                      required: true,
                      message: 'Please select district',
                      whitespace: true,
                    },
                  ]
                }
              />}

            {(!getStates(parentFormData?.country?.value).length > 0 && parentFormData?.country?.name) &&
              <CustomInput
                label="Child's State/Province"
                inputType="input"
                name="state"
                value={parentFormData?.state?.value}
                defaultValue={parentFormData?.state?.name}
                onChange={handleChange}
                placeholder="Enter State/Province"
              />}
            {(!getCities(parentFormData?.country?.value, parentFormData?.state?.value)?.length > 0 && parentFormData?.state) &&
              <CustomInput
                label="Child's city"
                inputType="input"
                name="city"
                value={parentFormData?.city?.value}
                defaultValue={parentFormData?.city?.name}
                onChange={handleChange}
                placeholder="Enter city" />}
            {(!getCities(parentFormData?.country?.value, parentFormData?.state?.value).length > 0 && parentFormData?.city) &&
              <CustomInput
                label="Child's School District"
                inputType="input"
                name="school_district"
                value={parentFormData?.school_district?.value}
                defaultValue={parentFormData?.school_district?.name}
                onChange={handleChange}
                placeholder="School District"
              />}
            <CustomInput
              label="Specific Diagnosis, Suspected Learning Disability"
              inputType="input"
              name="disability"
              value={parentFormData.disability}
              defaultValue={parentFormData.disability}
              onChange={handleChange}
              placeholder="Enter diagnosis"
              rules={
                parentFormData.disability === '' && [
                  {
                    required: true,
                    message: 'Please select dignosis',
                    whitespace: true,
                  },
                ]
              }
            // allowClear
            />

            <CustomButton loading={loading} type="primary" htmlType="submit">
              {existingUser ? 'Edit Profile' : 'Create Profile'}
            </CustomButton>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ParentMenu;
