export const SettingsData = [
  {
    name: 'Terms and Conditions',
    id: 1,
    path: 'terms',
  },
  {
    name: 'Privacy Policy',
    id: 2,
    path: 'privacy',
  },
  {
    name: 'Support Send Feedback',
    id: 3,
    path: 'feedback',
  },
  {
    name: 'Change password',
    id: 4,
    path: 'change-password',
  },
];
