import createTypes from 'redux-create-action-types';

export default createTypes(
  'GET_CHILDREN_SUMMARY',
  'GET_CHILDREN_SUMMARY_SUCCESS',
  'GET_CHILDREN_SUMMARY_FAILURE',

  'GET_CHILD_ACTIVITY_LIST',
  'GET_CHILD_ACTIVITY_LIST_SUCCESS',
  'GET_CHILD_ACTIVITY_LIST_FAILURE',

  'GET_SUBSCRIPTION_LIST_SUCCESS',
  'GET_SUBSCRIPTION_LIST_FAILURE',

  'GET_ALL_SUBSCRIPTIONS_LIST',
  'GET_ALL_SUBSCRIPTIONS_LIST_SUCCESS',
  'GET_ALL_SUBSCRIPTIONS_LIST_FAILURE',

  'GET_CHARTS_DATA',
  'GET_CHARTS_DATA_FAILURE',

  'GET_STUDENT_MASTERED_CHART_SUCCESS',
  'GET_DIRECTION_CHART_SUCCESS',

  'GET_COMPARISON_CHART_SUCCESS',

  'GET_SUMMARY_CHARTS_SUCCESS',
  'GET_SUMMARY_CHARTS_FAILURE',
);
