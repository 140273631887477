const zerosQuiz = [
  {
    subKey: 0,
    title: 'Zeros Activities',
    questions: 5,
    redirectTo: '/activities/number-quiz',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: true,
    duration: '',
    moveToPoint: 10,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 1,
    title: 'Figure 8 activity',
    redirectTo: '/activities/figure8',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 30,
    isActive: false,
    duration: '',
    moveToPoint: 20,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 2,
    title: 'Speed Number Counting',
    redirectTo: '/activities/speed-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 30,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 3,
    title: 'Direction Charts',
    redirectTo: '/activities/direction-chart',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 45,
    isActive: false,
    duration: '',
    moveToPoint: 40,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 4,
    title: 'Colour coded memory cards',
    questions: 65,
    redirectTo: '/activities/colour-coded',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 50,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 5,
    title: 'Brain Warrior Workout',
    redirectTo: '/activities/brain-warrior-workout',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 60,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 6,
    title: 'Number Families',
    redirectTo: '/activities/number-families',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 84,
    isActive: false,
    duration: '',
    moveToPoint: 70,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 7,
    title: 'Final Quiz',
    redirectTo: '/activities/final-quiz',
    questions: 5,
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 74,
    movetoPointFailed: 72,
    rewardText: 'Congratulations, you did it! Move to Ones!',
    startAgain: 'Great Work! Almost there, Let’s Try Again!',
  },
];
const onesQuiz = [
  {
    subKey: 0,
    title: 'Ones Activities',
    questions: 5,
    redirectTo: '/activities/number-quiz',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: true,
    duration: '',
    moveToPoint: 10,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 1,
    title: 'Figure 8 activity',
    redirectTo: '/activities/figure8',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 30,
    isActive: false,
    duration: '',
    moveToPoint: 20,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 2,
    title: 'Speed Number Counting',
    redirectTo: '/activities/speed-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 30,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 3,
    title: 'Direction Charts',
    redirectTo: '/activities/direction-chart',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 45,
    isActive: false,
    duration: '',
    moveToPoint: 40,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 4,
    title: 'Colour coded memory cards',
    questions: 65,
    redirectTo: '/activities/colour-coded',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 50,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 5,
    title: 'Brain Warrior Workout',
    redirectTo: '/activities/brain-warrior-workout',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 60,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 6,
    title: 'Number Families',
    redirectTo: '/activities/number-families',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 84,
    isActive: false,
    duration: '',
    moveToPoint: 70,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 7,
    title: 'Final Quiz',
    redirectTo: '/activities/final-quiz',
    questions: 5,
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 74,
    movetoPointFailed: 72,
    rewardText: 'Congratulations, you did it! Move to Twos!',
    startAgain: 'Great Work! Almost there, Let’s Try Again!',
  },
];
const TwosQuiz = [
  {
    subKey: 0,
    title: 'Twos Activities',
    questions: 5,
    redirectTo: '/activities/number-quiz',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: true,
    duration: '',
    moveToPoint: 10,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 1,
    title: 'Figure 8 activity',
    redirectTo: '/activities/figure8',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 30,
    isActive: false,
    duration: '',
    moveToPoint: 20,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 2,
    title: 'Speed Number Counting',
    redirectTo: '/activities/speed-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 30,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 3,
    title: 'Direction Charts',
    redirectTo: '/activities/direction-chart',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 45,
    isActive: false,
    duration: '',
    moveToPoint: 40,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 4,
    title: 'Colour coded memory cards',
    questions: 65,
    redirectTo: '/activities/colour-coded',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 50,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 5,
    title: 'Brain Warrior Workout',
    redirectTo: '/activities/brain-warrior-workout',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 60,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 6,
    title: 'Number Families',
    redirectTo: '/activities/number-families',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 84,
    isActive: false,
    duration: '',
    moveToPoint: 70,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 7,
    title: 'Final Quiz',
    redirectTo: '/activities/final-quiz',
    questions: 5,
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 74,
    movetoPointFailed: 72,
    rewardText: 'Congratulations, you did it! Move to Threes!',
    startAgain: 'Great Work! Almost there, Let’s Try Again!',
  },
];
const ThreesQuiz = [
  {
    subKey: 0,
    title: 'Threes Activities',
    questions: 5,
    redirectTo: '/activities/number-quiz',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: true,
    duration: '',
    moveToPoint: 10,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 1,
    title: 'Figure 8 activity',
    redirectTo: '/activities/figure8',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 30,
    isActive: false,
    duration: '',
    moveToPoint: 20,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 2,
    title: 'Speed Number Counting',
    redirectTo: '/activities/speed-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 30,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 3,
    title: 'Direction Charts',
    redirectTo: '/activities/direction-chart',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 45,
    isActive: false,
    duration: '',
    moveToPoint: 40,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 4,
    title: 'Colour coded memory cards',
    questions: 65,
    redirectTo: '/activities/colour-coded',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 50,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 5,
    title: 'Brain Warrior Workout',
    redirectTo: '/activities/brain-warrior-workout',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 60,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 6,
    title: 'Number Families',
    redirectTo: '/activities/number-families',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 84,
    isActive: false,
    duration: '',
    moveToPoint: 70,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 7,
    title: 'Final Quiz',
    redirectTo: '/activities/final-quiz',
    questions: 5,
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 74,
    movetoPointFailed: 72,
    rewardText: 'Congratulations, you did it! Move to Fours!',
    startAgain: 'Great Work! Almost there, Let’s Try Again!',
  },
];
const FoursQuiz = [
  {
    subKey: 0,
    title: 'Fours Activities',
    questions: 5,
    redirectTo: '/activities/number-quiz',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: true,
    duration: '',
    moveToPoint: 10,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 1,
    title: 'Figure 8 activity',
    redirectTo: '/activities/figure8',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 30,
    isActive: false,
    duration: '',
    moveToPoint: 20,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 2,
    title: 'Speed Number Counting',
    redirectTo: '/activities/speed-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 30,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 3,
    title: 'Direction Charts',
    redirectTo: '/activities/direction-chart',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 45,
    isActive: false,
    duration: '',
    moveToPoint: 40,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 4,
    title: 'Colour coded memory cards',
    questions: 65,
    redirectTo: '/activities/colour-coded',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 50,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 5,
    title: 'Brain Warrior Workout',
    redirectTo: '/activities/brain-warrior-workout',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 60,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 6,
    title: 'Number Families',
    redirectTo: '/activities/number-families',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 84,
    isActive: false,
    duration: '',
    moveToPoint: 70,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 7,
    title: 'Final Quiz',
    redirectTo: '/activities/final-quiz',
    questions: 5,
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 74,
    movetoPointFailed: 72,
    rewardText: 'Congratulations, you did it! Move to Fives!',
    startAgain: 'Great Work! Almost there, Let’s Try Again!',
  },
];
const FivesQuiz = [
  {
    subKey: 0,
    title: 'Fives Activities',
    questions: 5,
    redirectTo: '/activities/number-quiz',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: true,
    duration: '',
    moveToPoint: 10,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 1,
    title: 'Figure 8 activity',
    redirectTo: '/activities/figure8',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 30,
    isActive: false,
    duration: '',
    moveToPoint: 20,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 2,
    title: 'Speed Number Counting',
    redirectTo: '/activities/speed-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 30,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 3,
    title: 'Direction Charts',
    redirectTo: '/activities/direction-chart',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 45,
    isActive: false,
    duration: '',
    moveToPoint: 40,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 4,
    title: 'Colour coded memory cards',
    questions: 65,
    redirectTo: '/activities/colour-coded',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 50,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 5,
    title: 'Brain Warrior Workout',
    redirectTo: '/activities/brain-warrior-workout',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 60,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 6,
    title: 'Number Families',
    redirectTo: '/activities/number-families',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 84,
    isActive: false,
    duration: '',
    moveToPoint: 70,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 7,
    title: 'Final Quiz',
    redirectTo: '/activities/final-quiz',
    questions: 5,
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 74,
    movetoPointFailed: 72,
    rewardText: 'Congratulations, you did it! Move to Sixes!',
    startAgain: 'Great Work! Almost there, Let’s Try Again!',
  },
];
const SixesQuiz = [
  {
    subKey: 0,
    title: 'Sixes Activities',
    questions: 5,
    redirectTo: '/activities/number-quiz',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: true,
    duration: '',
    moveToPoint: 10,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 1,
    title: 'Figure 8 activity',
    redirectTo: '/activities/figure8',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 30,
    isActive: false,
    duration: '',
    moveToPoint: 20,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 2,
    title: 'Speed Number Counting',
    redirectTo: '/activities/speed-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 30,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 3,
    title: 'Direction Charts',
    redirectTo: '/activities/direction-chart',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 45,
    isActive: false,
    duration: '',
    moveToPoint: 40,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 4,
    title: 'Colour coded memory cards',
    questions: 65,
    redirectTo: '/activities/colour-coded',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 50,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 5,
    title: 'Brain Warrior Workout',
    redirectTo: '/activities/brain-warrior-workout',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 60,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 6,
    title: 'Number Families',
    redirectTo: '/activities/number-families',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 84,
    isActive: false,
    duration: '',
    moveToPoint: 70,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 7,
    title: 'Final Quiz',
    redirectTo: '/activities/final-quiz',
    questions: 5,
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 74,
    movetoPointFailed: 72,
    rewardText: 'Congratulations, you did it! Move to Sevens!',
    startAgain: 'Great Work! Almost there, Let’s Try Again!',
  },
];
const SevensQuiz = [
  {
    subKey: 0,
    title: 'Sevens Activities',
    questions: 5,
    redirectTo: '/activities/number-quiz',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: true,
    duration: '',
    moveToPoint: 10,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 1,
    title: 'Figure 8 activity',
    redirectTo: '/activities/figure8',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 30,
    isActive: false,
    duration: '',
    moveToPoint: 20,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 2,
    title: 'Speed Number Counting',
    redirectTo: '/activities/speed-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 30,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 3,
    title: 'Direction Charts',
    redirectTo: '/activities/direction-chart',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 45,
    isActive: false,
    duration: '',
    moveToPoint: 40,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 4,
    title: 'Colour coded memory cards',
    questions: 65,
    redirectTo: '/activities/colour-coded',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 50,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 5,
    title: 'Brain Warrior Workout',
    redirectTo: '/activities/brain-warrior-workout',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 60,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 6,
    title: 'Number Families',
    redirectTo: '/activities/number-families',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 84,
    isActive: false,
    duration: '',
    moveToPoint: 70,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 7,
    title: 'Final Quiz',
    redirectTo: '/activities/final-quiz',
    questions: 5,
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 74,
    movetoPointFailed: 72,
    rewardText: 'Congratulations, you did it! Move to Eights!',
    startAgain: 'Great Work! Almost there, Let’s Try Again!',
  },
];
const EightsQuiz = [
  {
    subKey: 0,
    title: 'Eights Activities',
    questions: 5,
    redirectTo: '/activities/number-quiz',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: true,
    duration: '',
    moveToPoint: 10,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 1,
    title: 'Figure 8 activity',
    redirectTo: '/activities/figure8',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 30,
    isActive: false,
    duration: '',
    moveToPoint: 20,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 2,
    title: 'Speed Number Counting',
    redirectTo: '/activities/speed-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 30,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 3,
    title: 'Direction Charts',
    redirectTo: '/activities/direction-chart',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 45,
    isActive: false,
    duration: '',
    moveToPoint: 40,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 4,
    title: 'Colour coded memory cards',
    questions: 65,
    redirectTo: '/activities/colour-coded',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 50,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 5,
    title: 'Brain Warrior Workout',
    redirectTo: '/activities/brain-warrior-workout',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 60,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 6,
    title: 'Number Families',
    redirectTo: '/activities/number-families',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 84,
    isActive: false,
    duration: '',
    moveToPoint: 70,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 7,
    title: 'Final Quiz',
    redirectTo: '/activities/final-quiz',
    questions: 5,
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 74,
    movetoPointFailed: 72,
    rewardText: 'Congratulations, you did it! Move to Nines!',
    startAgain: 'Great Work! Almost there, Let’s Try Again!',
  },
];
const NinesQuiz = [
  {
    subKey: 0,
    title: 'Nines Activities',
    questions: 5,
    redirectTo: '/activities/number-quiz',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: true,
    duration: '',
    moveToPoint: 10,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 1,
    title: 'Figure 8 activity',
    redirectTo: '/activities/figure8',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 30,
    isActive: false,
    duration: '',
    moveToPoint: 20,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 2,
    title: 'Speed Number Counting',
    redirectTo: '/activities/speed-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 30,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 3,
    title: 'Direction Charts',
    redirectTo: '/activities/direction-chart',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 45,
    isActive: false,
    duration: '',
    moveToPoint: 40,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 4,
    title: 'Colour coded memory cards',
    questions: 65,
    redirectTo: '/activities/colour-coded',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 50,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 5,
    title: 'Brain Warrior Workout',
    redirectTo: '/activities/brain-warrior-workout',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 60,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 6,
    title: 'Number Families',
    redirectTo: '/activities/number-families',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 84,
    isActive: false,
    duration: '',
    moveToPoint: 70,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 7,
    title: 'Final Quiz',
    redirectTo: '/activities/final-quiz',
    questions: 5,
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 74,
    movetoPointFailed: 72,
    rewardText: 'Congratulations, you did it! Move to Tens!',
    startAgain: 'Great Work! Almost there, Let’s Try Again!',
  },
];
const TensQuiz = [
  {
    subKey: 0,
    title: 'Tens Activities',
    questions: 5,
    redirectTo: '/activities/number-quiz',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: true,
    duration: '',
    moveToPoint: 10,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 1,
    title: 'Figure 8 activity',
    redirectTo: '/activities/figure8',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 30,
    isActive: false,
    duration: '',
    moveToPoint: 20,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 2,
    title: 'Speed Number Counting',
    redirectTo: '/activities/speed-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 30,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 3,
    title: 'Direction Charts',
    redirectTo: '/activities/direction-chart',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 45,
    isActive: false,
    duration: '',
    moveToPoint: 40,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 4,
    title: 'Colour coded memory cards',
    questions: 65,
    redirectTo: '/activities/colour-coded',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 50,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 5,
    title: 'Brain Warrior Workout',
    redirectTo: '/activities/brain-warrior-workout',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 60,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 6,
    title: 'Number Families',
    redirectTo: '/activities/number-families',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 84,
    isActive: false,
    duration: '',
    moveToPoint: 70,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 7,
    title: 'Final Quiz',
    redirectTo: '/activities/final-quiz',
    questions: 5,
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 74,
    movetoPointFailed: 72,
    rewardText: 'Congratulations, you did it! Move to Elevens!',
    startAgain: 'Great Work! Almost there, Let’s Try Again!',
  },
];

const ElevenQuiz = [
  {
    subKey: 0,
    title: 'Elevens Activities',
    questions: 5,
    redirectTo: '/activities/number-quiz',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: true,
    duration: '',
    moveToPoint: 10,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 1,
    title: 'Figure 8 activity',
    redirectTo: '/activities/figure8',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 30,
    isActive: false,
    duration: '',
    moveToPoint: 20,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 2,
    title: 'Speed Number Counting',
    redirectTo: '/activities/speed-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 30,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 3,
    title: 'Direction Charts',
    redirectTo: '/activities/direction-chart',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 45,
    isActive: false,
    duration: '',
    moveToPoint: 40,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 4,
    title: 'Colour coded memory cards',
    questions: 65,
    redirectTo: '/activities/colour-coded',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 50,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 5,
    title: 'Brain Warrior Workout',
    redirectTo: '/activities/brain-warrior-workout',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 60,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 6,
    title: 'Number Families',
    redirectTo: '/activities/number-families',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 84,
    isActive: false,
    duration: '',
    moveToPoint: 70,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 7,
    title: 'Final Quiz',
    redirectTo: '/activities/final-quiz',
    questions: 5,
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 74,
    movetoPointFailed: 72,
    rewardText: 'Congratulations, you did it! Move to Twelves!',
    startAgain: 'Great Work! Almost there, Let’s Try Again!',
  },
];

const TwelveQuiz = [
  {
    subKey: 0,
    title: 'Twelves Activities',
    questions: 5,
    redirectTo: '/activities/number-quiz',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: true,
    duration: '',
    moveToPoint: 10,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 1,
    title: 'Figure 8 activity',
    redirectTo: '/activities/figure8',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 30,
    isActive: false,
    duration: '',
    moveToPoint: 20,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 2,
    title: 'Speed Number Counting',
    redirectTo: '/activities/speed-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 30,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 3,
    title: 'Direction Charts',
    redirectTo: '/activities/direction-chart',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 45,
    isActive: false,
    duration: '',
    moveToPoint: 40,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 4,
    title: 'Colour coded memory cards',
    questions: 65,
    redirectTo: '/activities/colour-coded',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 50,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 5,
    title: 'Brain Warrior Workout',
    redirectTo: '/activities/brain-warrior-workout',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 60,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 6,
    title: 'Number Families',
    redirectTo: '/activities/number-families',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 84,
    isActive: false,
    duration: '',
    moveToPoint: 70,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 7,
    title: 'Final Quiz',
    redirectTo: '/activities/final-quiz',
    questions: 5,
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 74,
    movetoPointFailed: 72,
    rewardText: 'Congratulations, you did it! Move to Thirteens!',
    startAgain: 'Great Work! Almost there, Let’s Try Again!',
  },
];

const ThirteenQuiz = [
  {
    subKey: 0,
    title: 'Thirteens Activities',
    questions: 5,
    redirectTo: '/activities/number-quiz',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: true,
    duration: '',
    moveToPoint: 10,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 1,
    title: 'Figure 8 activity',
    redirectTo: '/activities/figure8',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 30,
    isActive: false,
    duration: '',
    moveToPoint: 20,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 2,
    title: 'Speed Number Counting',
    redirectTo: '/activities/speed-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 30,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 3,
    title: 'Direction Charts',
    redirectTo: '/activities/direction-chart',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 45,
    isActive: false,
    duration: '',
    moveToPoint: 40,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 4,
    title: 'Colour coded memory cards',
    questions: 65,
    redirectTo: '/activities/colour-coded',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 50,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 5,
    title: 'Brain Warrior Workout',
    redirectTo: '/activities/brain-warrior-workout',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 60,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 6,
    title: 'Number Families',
    redirectTo: '/activities/number-families',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 84,
    isActive: false,
    duration: '',
    moveToPoint: 70,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 7,
    title: 'Final Quiz',
    redirectTo: '/activities/final-quiz',
    questions: 5,
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 74,
    movetoPointFailed: 72,
    rewardText: 'Congratulations, you did it! Move to Fourteens!',
    startAgain: 'Great Work! Almost there, Let’s Try Again!',
  },
];

const FourteenQuiz = [
  {
    subKey: 0,
    title: 'Fourteens Activities',
    questions: 5,
    redirectTo: '/activities/number-quiz',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: true,
    duration: '',
    moveToPoint: 10,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 1,
    title: 'Figure 8 activity',
    redirectTo: '/activities/figure8',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 30,
    isActive: false,
    duration: '',
    moveToPoint: 20,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 2,
    title: 'Speed Number Counting',
    redirectTo: '/activities/speed-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 30,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 3,
    title: 'Direction Charts',
    redirectTo: '/activities/direction-chart',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 45,
    isActive: false,
    duration: '',
    moveToPoint: 40,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 4,
    title: 'Colour coded memory cards',
    questions: 65,
    redirectTo: '/activities/colour-coded',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 50,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 5,
    title: 'Brain Warrior Workout',
    redirectTo: '/activities/brain-warrior-workout',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 60,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 6,
    title: 'Number Families',
    redirectTo: '/activities/number-families',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 84,
    isActive: false,
    duration: '',
    moveToPoint: 70,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 7,
    title: 'Final Quiz',
    redirectTo: '/activities/final-quiz',
    questions: 5,
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 74,
    movetoPointFailed: 72,
    rewardText: 'Congratulations, you did it! Move to Fifteens!',
    startAgain: 'Great Work! Almost there, Let’s Try Again!',
  },
];

const FifteenQuiz = [
  {
    subKey: 0,
    title: 'Fifteens Activities',
    questions: 5,
    redirectTo: '/activities/number-quiz',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: true,
    duration: '',
    moveToPoint: 10,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 1,
    title: 'Figure 8 activity',
    redirectTo: '/activities/figure8',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 30,
    isActive: false,
    duration: '',
    moveToPoint: 20,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 2,
    title: 'Speed Number Counting',
    redirectTo: '/activities/speed-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 30,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 3,
    title: 'Direction Charts',
    redirectTo: '/activities/direction-chart',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 45,
    isActive: false,
    duration: '',
    moveToPoint: 40,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 4,
    title: 'Colour coded memory cards',
    questions: 65,
    redirectTo: '/activities/colour-coded',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 50,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 5,
    title: 'Brain Warrior Workout',
    redirectTo: '/activities/brain-warrior-workout',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 60,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 6,
    title: 'Number Families',
    redirectTo: '/activities/number-families',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 84,
    isActive: false,
    duration: '',
    moveToPoint: 70,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 7,
    title: 'Final Quiz',
    redirectTo: '/activities/final-quiz',
    questions: 5,
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 74,
    movetoPointFailed: 72,
    rewardText: 'Congratulations, you did it! Move to Sixteens!',
    startAgain: 'Great Work! Almost there, Let’s Try Again!',
  },
];

const SixteenQuiz = [
  {
    subKey: 0,
    title: 'Sixteens Activities',
    questions: 5,
    redirectTo: '/activities/number-quiz',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: true,
    duration: '',
    moveToPoint: 10,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 1,
    title: 'Figure 8 activity',
    redirectTo: '/activities/figure8',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 30,
    isActive: false,
    duration: '',
    moveToPoint: 20,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 2,
    title: 'Speed Number Counting',
    redirectTo: '/activities/speed-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 30,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 3,
    title: 'Direction Charts',
    redirectTo: '/activities/direction-chart',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 45,
    isActive: false,
    duration: '',
    moveToPoint: 40,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 4,
    title: 'Colour coded memory cards',
    questions: 65,
    redirectTo: '/activities/colour-coded',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 50,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 5,
    title: 'Brain Warrior Workout',
    redirectTo: '/activities/brain-warrior-workout',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 60,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 6,
    title: 'Number Families',
    redirectTo: '/activities/number-families',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 84,
    isActive: false,
    duration: '',
    moveToPoint: 70,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 7,
    title: 'Final Quiz',
    redirectTo: '/activities/final-quiz',
    questions: 5,
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 74,
    movetoPointFailed: 72,
    rewardText: 'Congratulations, you did it! Move to Seventeens!',
    startAgain: 'Great Work! Almost there, Let’s Try Again!',
  },
];

const SeventeenQuiz = [
  {
    subKey: 0,
    title: 'Seventeens Activities',
    questions: 5,
    redirectTo: '/activities/number-quiz',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: true,
    duration: '',
    moveToPoint: 10,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 1,
    title: 'Figure 8 activity',
    redirectTo: '/activities/figure8',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 30,
    isActive: false,
    duration: '',
    moveToPoint: 20,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 2,
    title: 'Speed Number Counting',
    redirectTo: '/activities/speed-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 30,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 3,
    title: 'Direction Charts',
    redirectTo: '/activities/direction-chart',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 45,
    isActive: false,
    duration: '',
    moveToPoint: 40,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 4,
    title: 'Colour coded memory cards',
    questions: 65,
    redirectTo: '/activities/colour-coded',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 50,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 5,
    title: 'Brain Warrior Workout',
    redirectTo: '/activities/brain-warrior-workout',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 60,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 6,
    title: 'Number Families',
    redirectTo: '/activities/number-families',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 84,
    isActive: false,
    duration: '',
    moveToPoint: 70,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 7,
    title: 'Final Quiz',
    redirectTo: '/activities/final-quiz',
    questions: 5,
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 74,
    movetoPointFailed: 72,
    rewardText: 'Congratulations, you did it! Move to Eighteens!',
    startAgain: 'Great Work! Almost there, Let’s Try Again!',
  },
];

const EighteenQuiz = [
  {
    subKey: 0,
    title: 'Eighteens Activities',
    questions: 5,
    redirectTo: '/activities/number-quiz',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: true,
    duration: '',
    moveToPoint: 10,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 1,
    title: 'Figure 8 activity',
    redirectTo: '/activities/figure8',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 30,
    isActive: false,
    duration: '',
    moveToPoint: 20,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 2,
    title: 'Speed Number Counting',
    redirectTo: '/activities/speed-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 30,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 3,
    title: 'Direction Charts',
    redirectTo: '/activities/direction-chart',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 45,
    isActive: false,
    duration: '',
    moveToPoint: 40,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 4,
    title: 'Colour coded memory cards',
    questions: 65,
    redirectTo: '/activities/colour-coded',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 50,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 5,
    title: 'Brain Warrior Workout',
    redirectTo: '/activities/brain-warrior-workout',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 60,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 6,
    title: 'Number Families',
    redirectTo: '/activities/number-families',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 84,
    isActive: false,
    duration: '',
    moveToPoint: 70,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 7,
    title: 'Final Quiz',
    redirectTo: '/activities/final-quiz',
    questions: 5,
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 74,
    movetoPointFailed: 72,
    rewardText: 'Congratulations, you did it! Move to Nineteens!',
    startAgain: 'Great Work! Almost there, Let’s Try Again!',
  },
];

const NineteenQuiz = [
  {
    subKey: 0,
    title: 'Nineteens Activities',
    questions: 5,
    redirectTo: '/activities/number-quiz',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: true,
    duration: '',
    moveToPoint: 10,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 1,
    title: 'Figure 8 activity',
    redirectTo: '/activities/figure8',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 30,
    isActive: false,
    duration: '',
    moveToPoint: 20,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 2,
    title: 'Speed Number Counting',
    redirectTo: '/activities/speed-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 30,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 3,
    title: 'Direction Charts',
    redirectTo: '/activities/direction-chart',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 45,
    isActive: false,
    duration: '',
    moveToPoint: 40,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 4,
    title: 'Colour coded memory cards',
    questions: 65,
    redirectTo: '/activities/colour-coded',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 50,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 5,
    title: 'Brain Warrior Workout',
    redirectTo: '/activities/brain-warrior-workout',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 60,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 6,
    title: 'Number Families',
    redirectTo: '/activities/number-families',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 84,
    isActive: false,
    duration: '',
    moveToPoint: 70,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 7,
    title: 'Final Quiz',
    redirectTo: '/activities/final-quiz',
    questions: 5,
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 74,
    movetoPointFailed: 72,
    rewardText: 'Congratulations, you did it! Move to Twenties!',
    startAgain: 'Great Work! Almost there, Let’s Try Again!',
  },
];

const TwentieQuiz = [
  {
    subKey: 0,
    title: 'Twenties Activities',
    questions: 5,
    redirectTo: '/activities/number-quiz',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: true,
    duration: '',
    moveToPoint: 10,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 1,
    title: 'Figure 8 activity',
    redirectTo: '/activities/figure8',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 30,
    isActive: false,
    duration: '',
    moveToPoint: 20,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 2,
    title: 'Speed Number Counting',
    redirectTo: '/activities/speed-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 30,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 3,
    title: 'Direction Charts',
    redirectTo: '/activities/direction-chart',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 45,
    isActive: false,
    duration: '',
    moveToPoint: 40,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 4,
    title: 'Colour coded memory cards',
    questions: 65,
    redirectTo: '/activities/colour-coded',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 50,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 5,
    title: 'Brain Warrior Workout',
    redirectTo: '/activities/brain-warrior-workout',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 60,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 6,
    title: 'Number Families',
    redirectTo: '/activities/number-families',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 84,
    isActive: false,
    duration: '',
    moveToPoint: 70,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 7,
    title: 'Final Quiz',
    redirectTo: '/activities/final-quiz',
    questions: 5,
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 74,
    movetoPointFailed: 72,
    rewardText: 'Congratulations, you did it! Move to Multiplication',
    startAgain: 'Great Work! Almost there, Let’s Try Again!',
  },
];
export const subtractionQuiz = [
  {
    key: 0,
    data: zerosQuiz,
    isDisable: false,
  },
  {
    key: 1,
    data: onesQuiz,
    isDisable: true,
  },
  {
    key: 2,
    data: TwosQuiz,
    isDisable: true,
  },
  {
    key: 3,
    data: ThreesQuiz,
    isDisable: true,
  },
  {
    key: 4,
    data: FoursQuiz,
    isDisable: true,
  },
  {
    key: 5,
    data: FivesQuiz,
    isDisable: true,
  },
  {
    key: 6,
    data: SixesQuiz,
    isDisable: true,
  },
  {
    key: 7,
    data: SevensQuiz,
    isDisable: true,
  },
  {
    key: 8,
    data: EightsQuiz,
    isDisable: true,
  },
  {
    key: 9,
    data: NinesQuiz,
    isDisable: true,
  },
  {
    key: 10,
    data: TensQuiz,
    isDisable: true,
  },
  {
    key: 11,
    data: ElevenQuiz,
    isDisable: true,
  },
  {
    key: 12,
    data: TwelveQuiz,
    isDisable: true,
  },
  {
    key: 13,
    data: ThirteenQuiz,
    isDisable: true,
  },
  {
    key: 14,
    data: FourteenQuiz,
    isDisable: true,
  },
  {
    key: 15,
    data: FifteenQuiz,
    isDisable: true,
  },
  {
    key: 16,
    data: SixteenQuiz,
    isDisable: true,
  },
  {
    key: 17,
    data: SeventeenQuiz,
    isDisable: true,
  },
  {
    key: 18,
    data: EighteenQuiz,
    isDisable: true,
  },
  {
    key: 19,
    data: NineteenQuiz,
    isDisable: true,
  },
  {
    key: 20,
    data: TwentieQuiz,
    isDisable: true,
  },
];
