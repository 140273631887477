import * as React from 'react';
import { Button } from 'antd';

const CustomButton = ({ children, className, loading, disabled, ...rest }) => {
  return (
    <Button loading={loading} className={className} disabled={disabled || loading} {...rest}>
      {children}
    </Button>
  );
};

export default CustomButton;
