import React, { useEffect, memo } from 'react';
import styles from './Pdf.module.scss';
import { Table, Skeleton } from 'antd';
import { FileOutlined, FilePdfOutlined, FilePptOutlined, DownloadOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

//Actions
import { getUserDocuments } from '../../../store/actions/authActions';

const Pdf = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const documents = useSelector((state) => state.auth.documents);

  const loading = useSelector((state) => state.auth.loading);
  useEffect(() => {
    getDocuments();
  }, []);

  const getDocuments = async () => {
    await dispatch(getUserDocuments());
  };

  const fileFormate = (file) => {
    const { name } = file;
    const fileType = name.split('.').pop();
    return fileType;
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'id',
      render: (name, id) => {
        return (
          <div>
            {fileFormate(id) === 'pdf' ? (
              <FilePdfOutlined style={{ color: '#1890ff', padding: '5px' }} />
            ) : (
              <FilePptOutlined style={{ color: '#1890ff', padding: '5px' }} />
            )}

            {name}
          </div>
        );
      },
    },
    {
      title: 'Download',
      dataIndex: 'document',
      key: 'id',
      render: (data) => {
        return (
          <div className={styles.downloadIcon}>
            <a href={`${data}`} target="blank">
              <DownloadOutlined style={{ fontSize: '20px' }} />
            </a>
          </div>
        );
      },
    },
  ];

  return (
    <div className={styles.mainContainer}>
      <div className={styles.headingWrapper}>
        <ArrowLeftOutlined className={styles.backIcon} onClick={() => navigate('/')} />
        <div className={styles.heading}>
          <FileOutlined /> My Files
        </div>
      </div>

      <Table
        columns={
          loading
            ? columns.map((column) => {
                return {
                  render: function renderPlaceholder() {
                    return <Skeleton active key={column.dataIndex} title={true} paragraph={false} />;
                  },
                };
              })
            : columns
        }
        className={styles.tableWrapper}
        dataSource={documents && documents}
        pagination={false}
        rowKey={(element) => element.id}
      />
    </div>
  );
};

export default memo(Pdf);
