import React, { useEffect, useState } from 'react';
import styles from './QuizFooter.module.scss';
import CustomButton from '../../../../components/Button';
import { QuestionStartClick } from '../../../../helpers/QuestionTimer';
import { getMicrophonePermission } from '../../../../helpers/utility';
import { useNavigate } from 'react-router-dom';

const QuizFooterHandleTimer = ({
  isDisplayNext,
  setIsDisplayNext,
  isDisabled,
  questionRef,
  totalTime,
  isContiune,
  setCheckTimeOut,
  onTimeOut,
  recordingVoice,
  setTimeRecord,
  startRecording,
  status,
}) => {
  const navigate = useNavigate();
  const [questionTimer, setQuestionTimer] = useState(totalTime ? `${totalTime}:00` : '05:00');

  useEffect(() => {
    if (questionTimer === '00:00') {
      if (isContiune) {
        setCheckTimeOut(true);
        // notification['warning']({
        //   message: 'Quiz timeout, Please try again',
        // });
      } else {
        onTimeOut && onTimeOut();
        // navigate(`/activities`);
        // notification['warning']({
        //   message: 'Quiz timeout, Please try again',
        // });
      }
    }
    questionRef.current = questionTimer;
    if (setTimeRecord) {
      setTimeRecord(questionTimer);
    }
  }, [questionTimer]);

  const audioPermission = () => {
    getMicrophonePermission().then((granted) => {
      if (granted) {
        setIsDisplayNext(true);
        QuestionStartClick(questionTimer, setQuestionTimer, questionRef, totalTime);
      } else {
        return false;
      }
    });
  };

  useEffect(() => {
    if (status && status === 'recording') {
      setIsDisplayNext(true);
      QuestionStartClick(questionTimer, setQuestionTimer, questionRef, totalTime);
    }
  }, [status]);

  return (
    <>
      {!isDisplayNext ? (
        <div className={styles.footerContainer} style={{ paddingTop: '0px' }}>
          {isDisabled && (
            <CustomButton
              type="primary"
              onClick={() => {
                if (recordingVoice) {
                  recordingVoice();
                  audioPermission();
                } else {
                  if (startRecording) {
                    startRecording();
                  } else {
                    setIsDisplayNext(true);
                    QuestionStartClick(questionTimer, setQuestionTimer, questionRef, totalTime);
                  }
                }
              }}
              disabled={!isDisabled}
            >
              Start
            </CustomButton>
          )}
        </div>
      ) : (
        <div className={styles.activeFooterContainer} style={{ paddingTop: '0px' }}>
          {isDisabled && <CustomButton type="primary">{questionTimer}</CustomButton>}
        </div>
      )}
    </>
  );
};

export default QuizFooterHandleTimer;
