import Types from '../types/AdminTypes';

const initialState = {
  loading: false,
  childrenSummary: [],
  childActivity: [],
  loadingActivity: false,
  subscriptions: [],
  subscriptionsGraph: [],
  loadingSubscriptions: false,
  masteredChart: false,
  directionChart: false,
  comparisonChart: false,
  loadingChart: false,
  summaryChart: false,
};

export default function AdminReducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_CHILDREN_SUMMARY:
      return {
        ...state,
        loading: true,
      };
    case Types.GET_CHILDREN_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        childrenSummary: action.payload,
      };

    case Types.GET_CHILDREN_SUMMARY_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case Types.GET_CHILD_ACTIVITY_LIST:
      return {
        ...state,
        loadingActivity: true,
      };
    case Types.GET_CHARTS_DATA:
      return {
        ...state,
        loadingChart: true,
      };
    case Types.GET_CHARTS_DATA_FAILURE:
      return {
        ...state,
        loadingChart: false,
      };
    case Types.GET_CHILD_ACTIVITY_LIST_SUCCESS:
      return {
        ...state,
        loadingActivity: false,
        childActivity: action.payload,
      };

    case Types.GET_SUBSCRIPTION_LIST_SUCCESS:
      return {
        ...state,
        subscriptionsGraph: action.payload,
        loadingChart: false,
      };

    case Types.GET_CHILD_ACTIVITY_LIST_FAILURE:
      return {
        ...state,
        loadingActivity: false,
        loadingChart: false,
      };

    case Types.GET_COMPARISON_CHART_SUCCESS:
      return {
        ...state,
        comparisonChart: action.payload,
        loadingChart: false,
      };

    case Types.GET_ALL_SUBSCRIPTIONS_LIST:
      return {
        ...state,
        loadingSubscriptions: true,
      };
    case Types.GET_ALL_SUBSCRIPTIONS_LIST_SUCCESS:
      return {
        ...state,
        loadingSubscriptions: false,
        subscriptions: action.payload,
      };

    case Types.GET_DIRECTION_CHART_SUCCESS:
      return {
        ...state,
        loadingChart: false,
        directionChart: action.payload,
      };

    case Types.GET_STUDENT_MASTERED_CHART_SUCCESS:
      return {
        ...state,
        loadingChart: false,
        masteredChart: action.payload,
      };

    case Types.GET_SUBSCRIPTION_LIST_FAILURE:
      return {
        ...state,
        loadingChart: false,
      };

    case Types.GET_ALL_SUBSCRIPTIONS_LIST_FAILURE:
      return {
        ...state,
        loadingSubscriptions: false,
        loadingChart: false,
      };
    case Types.GET_SUMMARY_CHARTS_SUCCESS:
      return {
        ...state,
        summaryChart: action.payload,
        loadingChart: false,
      };

    default:
      return state;
  }
}
