import React, { useState } from 'react';
import styles from './QuizScreen.module.scss';
import { Form } from 'antd';
import CustomInput from '../../../components/Input';
import ResultModal from '../ResultModal/ResultModal';

const CircleContain = ({
  quizData,
  currentQuestion,
  setCurrentQuestion,
  setQuestionTimer,
  answers,
  setAnswers,
  isDisplayNext,
  questionRef,
}) => {
  const [currentQuiz, setCurrentQuiz] = useState(quizData[currentQuestion]);
  const [form] = Form.useForm();
  const [resultModalVisible, setResultModalVisible] = useState(false);

  const setDuration = () => {
    const [minutes, seconds] = questionRef.current.split(':');
    const durationMinutes = '0' + (4 - minutes);
    const durationSeconds = 60 - seconds >= 10 ? 60 - seconds : '0' + (60 - seconds);
    return durationMinutes + ':' + durationSeconds;
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (currentQuestion !== quizData.length) {
        if (currentQuiz.answer === +e.target.value) {
          setAnswers(answers + 1);
        }
        if (currentQuestion !== quizData.length - 1) {
          setCurrentQuestion(currentQuestion + 1);
          setCurrentQuiz(quizData[currentQuestion + 1]);
        } else {
          setQuestionTimer(setDuration());
          setResultModalVisible(true);
        }
      } else {
      }
      form.resetFields();
    }
  };

  return (
    <>
      <div className={styles.questionContainer}>
        <div className={styles.detailContainer}>
          <img src={currentQuiz?.single.image} alt={currentQuiz?.single.image} className={styles.number1} />
          {currentQuiz?.question ? (
            <div className={styles.number2}>
              <span className={styles.signContainer}>+</span>
              <img src={currentQuiz?.multi.image} alt={currentQuiz?.multi.image} className={styles.number11} />
            </div>
          ) : null}
          <div className={styles.answer}>
            <hr />
            <Form form={form} colon={false} autoComplete="off">
              <CustomInput
                disabled={!isDisplayNext}
                inputType="answer"
                name="answer"
                onKeyDown={handleKeyDown}
                autoFocus
              />
            </Form>
          </div>
        </div>
      </div>
      {resultModalVisible && (
        <ResultModal resultModalVisible={resultModalVisible} setResultModalVisible={setResultModalVisible} />
      )}
    </>
  );
};

export default CircleContain;
