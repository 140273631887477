import React, { Component } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import '../../assets/scss/light-bootstrap-dashboard-react.scss';
import { ReactComponent as CogPlayLogo } from '../../assets/images/COGPLAYLOGO.svg';

import { Nav } from 'react-bootstrap';

function Sidebar({ color, image, routes, handleLogout }) {
  const location = useLocation();
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  };

  return (
    <div className="sidebar" data-image={image} data-color={color}>
      <div
        className="sidebar-background"
        // style={{
        //   backgroundImage: "url(" + image + ")"
        // }}
      />
      <div className="sidebar-wrapper">
        <div className="logo d-flex align-items-center justify-content-start">
          <NavLink to="/" className="simple-text logo-mini mx-1">
            <div className="logo-img">
              <CogPlayLogo width="88px" height="30px" />
            </div>
          </NavLink>
          <NavLink className="simple-text" to="/">
            CogPlay
          </NavLink>
        </div>
        <Nav>
          {routes.map((prop, key) => {
            if (!prop.redirect)
              return (
                <li className={prop.upgrade ? 'active active-pro' : activeRoute(prop.path)} key={key}>
                  <NavLink to={prop.path} className="nav-link" activeClassName="active">
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </NavLink>
                </li>
              );
            return null;
          })}
          <li className={'active active-pro'}>
            <NavLink to={'/'} className="nav-link" activeClassName="active" onClick={handleLogout}>
              <i className={'fas fa-sign-out-alt opacity-75'} />
              <p>{'LOgout'}</p>
            </NavLink>
          </li>
        </Nav>
      </div>
    </div>
  );
}

export default Sidebar;
