import React, { useState } from 'react';
import CustomInput from '../../../../components/Input';
import CustomButton from '../../../../components/Button';
import { QuestionCircleFilled } from '@ant-design/icons';
import { useElements, useStripe, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import { Button, Popover } from 'antd';
import SecureModal from './SecureModal';
import styles from '../Checkout.module.scss';
import { useDispatch } from 'react-redux';
import { createSubscriptions } from '../../../../store/actions/subscriptionActions';

const PaymentModal = ({
  setModalVisible,
  heading,
  price,
  paymentPlan,
  setIsConfirmModal,
  loading,
  setLoading,
  setStages,
  planId,
}) => {
  const convertedPrice = price.split('/', 2);
  const dispatch = useDispatch();
  const [paymentFormData, setPaymentFormData] = useState({
    // cardNumber:'',
    // expiryDate:'',
    // code:'',
    name: '',
  });

  const handleChange = ({ target: { name, value } }) => {
    setPaymentFormData({
      ...paymentFormData,
      [name]: value,
    });
  };
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState({
    number: '',
    date: '',
    code: '',
  });

  const clearCardElement = async () => {
    const cardNumberElement = await elements?.getElement(CardNumberElement);
    const cardExpiryElement = await elements?.getElement(CardExpiryElement);
    const cardCvcElement = await elements?.getElement(CardCvcElement);

    cardNumberElement.clear();
    cardExpiryElement.clear();
    cardCvcElement.clear();
  };

  const handle_Change = (event) => {
    switch (event.elementType) {
      case 'cardNumber':
        return setError({ ...error, number: event.error?.message });
      case 'cardExpiry':
        return setError({ ...error, date: event.error?.message });
      case 'cardCvc':
        return setError({ ...error, code: event.error?.message });
      default:
        return error;
    }
  };

  const formValid = () => {
    if (error.number === '') {
      setError({ ...error, number: 'Please Enter Card Number' });
      setIsConfirmModal(false);
    } else if (error.date === '') {
      setError({ ...error, date: 'Please Enter Expiry Date' });
      setIsConfirmModal(false);
    } else if (error.code === '') {
      setError({ ...error, code: 'Please Enter Secure Code' });
      setIsConfirmModal(false);
    }
  };

  const errorCondition =
    error.number || error.date || error.code || error.number === '' || error.date === '' || error.code === '';

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (errorCondition) {
      formValid();
      setIsConfirmModal(false);
    } else {
      setLoading(true);
      if (!stripe || !elements) {
        return;
      }
      const cardNumberElement = await elements?.getElement(CardNumberElement);
      if (cardNumberElement) {
        const { token } = await stripe?.createToken(cardNumberElement, {
          account_holder_type: 'individual',
          name: paymentFormData.name,
        });
        if (token) {
          const data = {
            card_token: token?.id,
            plan_id: planId,
          };
          dispatch(createSubscriptions(data, callBack, setLoading));
        } else {
          setLoading(false);
        }
      }
    }
  };

  const callBack = () => {
    setStages(2);
    setModalVisible(false);
    setIsConfirmModal(true);
    setLoading(false);
    setError({
      number: '',
      date: '',
      code: '',
    });
    setPaymentFormData({
      // cardNumber:'',
      // expiryDate:'',
      // code:'',
      name: '',
    });
    clearCardElement();
  };

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.paymentText}>
          <span>Payment Info</span>
        </div>
        <form onSubmit={handleSubmit}>
          <div className={styles.number}>
            <label>Card Number</label>
            <div id="card-number-element"></div>
            <CardNumberElement
              className={styles.numberInput}
              options={{ placeholder: '' }}
              onChange={(event) => {
                handle_Change(event);
              }}
            />
            <span style={{ color: 'red' }}>{error.number}</span>
          </div>
          <div className={styles.container}>
            <div className={styles.expiryDateWrapper}>
              <label>Expiry Date</label>
              <CardExpiryElement
                className={styles.DateInput}
                options={{ placeholder: '' }}
                onChange={(event) => {
                  handle_Change(event);
                }}
              />
              <span style={{ color: 'red' }}>{error.date}</span>
            </div>

            <div className={styles.codeWrapper}>
              <label>
                Secure Code
                <Popover content={<SecureModal />} trigger="hover">
                  <Button type="link" className={styles.secureButton}>
                    <QuestionCircleFilled width="10px" />
                  </Button>
                </Popover>
              </label>
              <CardCvcElement
                className={styles.codeInput}
                options={{ placeholder: '' }}
                onChange={(event) => {
                  handle_Change(event);
                }}
              />
              <span style={{ color: 'red' }}>{error.code}</span>
            </div>
          </div>

          <CustomInput
            label="Cardholder Name"
            inputType="input"
            name="name"
            value={paymentFormData.name}
            onChange={handleChange}
            // placeholder="Enter your full name"
            rules={[
              {
                required: true,
                message: 'Please enter Cardholder name',
                whitespace: true,
              },
            ]}
            required
            allowClear
          />
          <hr />

          <div className={styles.paymentFooter}>
            <div className={styles.orderText}>
              <span>Order Summary</span>
            </div>
            <div className={styles.detailContainer}>
              <div className={styles.plan}>
                <div className={styles.planHeading}>Plan</div>
                <div className={styles.planDetail}>{paymentPlan}</div>
              </div>

              <div className={styles.plan}>
                <div className={styles.planHeading}>Program</div>
                <div className={styles.planDetail}>{heading}</div>
              </div>

              <div className={styles.plan}>
                <div className={styles.planHeading}>Time</div>
                <div className={styles.planDetail}>{convertedPrice[1]}</div>
              </div>

              <div className={styles.total}>
                <div className={styles.totalHeading}>Total</div>
                <div className={styles.totalDetail}>{'$' + convertedPrice[0] / 100}</div>
              </div>
            </div>
            <CustomButton type="primary" loading={loading} htmlType="submit">
              Pay{' '}
              <span style={{ fontFamily: 'Poppins-Dark', marginLeft: '5px' }}> {'$' + convertedPrice[0] / 100}</span>
            </CustomButton>
          </div>
        </form>
      </div>
    </>
  );
};

export default PaymentModal;
