import Request from './request';

export const getChildrenSummaryApi = async (payload) => {
  return Request.call({
    url: `users/get-child-activity/?search=${payload}`,
    method: 'GET',
  });
};

export const getChildActivitiesList = async (payload) => {
  return Request.call({
    url: `users/get-child-activity-details/?search=${payload?.search}&filter=${payload?.filter}&page=${payload?.page}`,
    method: 'GET',
  });
};

export const getAllSubscriptionsApi = async () => {
  return Request.call({
    url: `api/v1/subscribed-user-count/`,
    method: 'GET',
  });
};

export const getAllSubscriptionsList = async (payload) => {
  return Request.call({
    url: `api/v1/subscribed-user/?country=${payload?.country}&state=${payload?.state}&city=${payload?.city}&district=${payload?.district}&page=${payload?.page}`,
    method: 'GET',
  });
};

export const studentMasterdChart = async (payload) => {
  return Request.call({
    url: `api/v1/mastered-time-chart/${payload}/`,
    method: 'GET',
  });
};

export const directionChart = async (payload) => {
  return Request.call({
    url: `api/v1/direction-time-chart/${payload}/`,
    method: 'GET',
  });
};

export const comparisonChart = async (payload) => {
  return Request.call({
    url: `api/v1/student-chart/${payload}/`,
    method: 'GET',
  });
};

export const summaryChart = async (filterData) => {
  return Request.call({
    url: `api/v1/student-summary-chart/?student_name=${filterData}`,
    method: 'GET',
  });
};
