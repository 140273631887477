import React, { useState, useEffect } from 'react';
import styles from './FingerSkipCounting.module.scss';
import QuizHeader from '../QuizLayout/QuizHeader/QuizHeader';
import ReactPlayer from 'react-player';
import { ReactComponent as PlayBtn } from './../../../assets/images/playBtn.svg';
import { useDispatch } from 'react-redux';
import { attemptSetQuiz } from '../../../store/actions/quizActions';
import { useReducerData } from '../../../store/hooks';
import Thumbnail from './../../../assets/images/fingerCount.png';
import { Spin } from 'antd';
import ResultModal from '../ResultModal/ResultModal';

import { updateQuizPayload, getCurrentRoute } from '../../../helpers/utility';

const FingerSkipCounting = () => {
  const dispatch = useDispatch();
  const quizData = useReducerData('quiz', 'quizData');
  const tabKey = useReducerData('quiz', 'tabKey');
  const currentQuizKey = useReducerData('quiz', 'currentQuizKey');
  const { key, subKey } = currentQuizKey[+tabKey];
  const video = `${process.env.REACT_APP_VIDEO_URL + 'FingerSkipCounting' + key + 's.mp4'}`;
  const [playing, setPlaying] = useState(false);
  const [resultModalVisible, setResultModalVisible] = useState(false);
  const url = window.location.href;
  const currentData = quizData?.data?.[+tabKey]?.quiz[key]?.data;
  const [ready, setReady] = useState(false);
  const [isShow, setIsShow] = useState(true);
  const videoCompleted = async () => {
    const tempQuizData = updateQuizPayload(quizData?.data, +tabKey, key, getCurrentRoute(url, currentData));

    await dispatch(
      attemptSetQuiz({
        data: tempQuizData?.tempQuizData,
        activity: tempQuizData?.activity,
      }),
    );
    setResultModalVisible(true);
  };
  useEffect(() => {
    setTimeout(() => {
      setIsShow(false);
    }, 500);
  }, []);

  const renderQuiz = () => {
    return (
      <ReactPlayer
        url={video}
        controls={true}
        width="100%"
        height="100%"
        playIcon={<span></span>}
        light={
          <div style={{ height: '100%' }}>
            <img src={Thumbnail} alt="Thumbnail" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            {!isShow && !playing && (
              <PlayBtn
                className={styles.playBtn}
                onClick={() => {
                  handlePlay();
                  setReady(true);
                }}
              />
            )}
          </div>
        }
        onReady={() => setReady(false)}
        playing={resultModalVisible ? !resultModalVisible : playing}
        onPlay={handlePlay}
        onStart={handlePlay}
        onPause={handlePause}
        onEnded={() => videoCompleted()}
        style={{ height: '100%', display: ready ? 'none' : 'flex' }}
      />
    );
  };

  const handlePlay = () => {
    setPlaying(true);
  };

  const handlePause = () => {
    setPlaying(false);
  };

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.modalContainer}>
          <QuizHeader isDisplayNext={false} title={'Finger Skip Counting'} />
          <div className={styles.quizWrapper}>
            {ready ? (
              <Spin tip="Loading" size="large">
                <div style={{ height: '100%' }}>
                  <img src={Thumbnail} alt="Thumbnail" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </div>
              </Spin>
            ) : null}
            {renderQuiz()}
          </div>
        </div>
      </div>
      {resultModalVisible && (
        <ResultModal resultModalVisible={resultModalVisible} setResultModalVisible={setResultModalVisible} />
      )}
    </>
  );
};

export default FingerSkipCounting;
