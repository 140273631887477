const NumberFamiliesSubTractionData = [
  {
    num1: 0,
  },
  {
    num1: 1,
  },
  {
    num1: 2,
  },
  {
    num1: 3,
  },
  {
    num1: 4,
  },
  {
    num1: 5,
  },
  {
    num1: 6,
  },
  {
    num1: 7,
  },
  {
    num1: 8,
  },
  {
    num1: 9,
  },
  {
    num1: 10,
  },
  {
    num1:11,
  },
  {
    num1: 12,
  },
  {
    num1: 13,
  },
  {
    num1: 14,
  },
  {
    num1: 15,
  },
  {
    num1: 16,
  },
  {
    num1: 17,
  },
  {
    num1: 18,
  },
  {
    num1: 19,
  },
  {
    num1: 20,
  },
  
  
];
const NumberFamiliesDivisionData = [
  {
    num1: 0,
  },
  {
    num1: 1,
  },
  {
    num1: 2,
  },
  {
    num1: 3,
  },
  {
    num1: 4,
  },
  {
    num1: 5,
  },
  {
    num1: 6,
  },
  {
    num1: 7,
  },
  {
    num1: 8,
  },
  {
    num1: 9,
  },
  {
    num1: 10,
  },
  {
    num1:11,
  },
  {
    num1: 12,
  },
];

export { NumberFamiliesSubTractionData,NumberFamiliesDivisionData};
