import React, { Component } from 'react';
import { useLocation } from 'react-router-dom';
import { Navbar, Container, Nav, Dropdown, Button } from 'react-bootstrap';

// Routes
import routes from '../../routes.js';

function AdminNavbar({ user }) {
  const location = useLocation();
  const mobileSidebarToggle = (e) => {
    e.preventDefault();
    document.documentElement.classList.toggle('nav-open');
    var node = document.createElement('div');
    node.id = 'bodyClick';
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle('nav-open');
    };
    document.body.appendChild(node);
  };
  const adminRoutes = routes.filter((item) => {
    if (item.layout === 'admin' && user?.user_type === 'Admin') {
      return item;
    } else {
      if (user?.user_type !== 'Admin' && item?.isTeacherAndParent) {
        return item?.isTeacherAndParent;
      }
    }
  });
  const getBrandText = () => {
    for (let i = 0; i < adminRoutes.length; i++) {
      if (location.pathname.indexOf(adminRoutes[i].path) !== -1) {
        return adminRoutes[i].name;
      }
    }
    return 'Brand';
  };
  return (
    <Navbar bg="light" expand="lg">
      <Container fluid>
        <div className="d-flex justify-content-center align-items-center ml-2 ml-lg-0" style={{ paddingLeft: '12px' }}>
          <Button
            variant="dark"
            className="d-lg-none d-flex justify-content-center align-items-center p-2"
            onClick={mobileSidebarToggle}
          >
            <i className="fa fa-bars"></i>
          </Button>
          <Navbar.Brand href="#home" onClick={(e) => e.preventDefault()} style={{ color: '#000', margin: '2px 0' }}>
            {getBrandText()}
          </Navbar.Brand>
        </div>
        {/* <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-2">
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
        </Navbar.Toggle> */}
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="nav mr-auto" navbar>
            <Nav.Item>
              <Nav.Link data-toggle="dropdown" href="/" onClick={(e) => e.preventDefault()} className="m-0">
                <span className="d-lg-none ml-1">Dashboard</span>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          {/* <Nav className="ml-auto " navbar>
            <Nav.Item>
              <Nav.Link className="m-0" href="/" onClick={handleLogout}>
                <span className="no-icon">Log out</span>
              </Nav.Link>
            </Nav.Item>
          </Nav> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default AdminNavbar;
