import { Form } from 'antd';
import React from 'react';
import CustomButton from '../../../components/Button/Button';
import CustomPassword from '../../../components/InputPassword/InputPassword';
import { validatePassword } from '../../../helpers/constants';
import styles from '../forgotPassword/forgotPassword.module.scss';

const NewPassword = ({ handleChangePassword, loading, forgotPasswordData, resetPasswordhandleChange, form }) => {
  return (
    <div className={styles.formInput}>
      <Form colon={false} onFinish={handleChangePassword} autoComplete="off" form={form}>
        <CustomPassword
          label="New Password"
          name="password"
          value={forgotPasswordData.password}
          onChange={resetPasswordhandleChange}
          placeholder="Enter your password"
          rules={[
            ({ getFieldValue }) => ({
              validator() {
                if (!validatePassword(getFieldValue('password'))) {
                  if (!getFieldValue('password')) {
                    return Promise.reject(new Error('Please enter password'));
                  }
                  return Promise.reject(new Error('Please enter strong password'));
                }
                return Promise.resolve();
              },
            }),
          ]}
          allowClear
        />
        <CustomPassword
          label="Confirm Password"
          name="confirmPassword"
          value={forgotPasswordData.confirmPassword}
          onChange={resetPasswordhandleChange}
          placeholder="Confirm password"
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: 'Please enter confirm password',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('confirm password does not match with password'));
              },
            }),
          ]}
          allowClear
        />
        <CustomButton type="primary" htmlType="submit" loading={loading}>
          Change password
        </CustomButton>
      </Form>
    </div>
  );
};
export default NewPassword;
