import { Form, Input } from 'antd';
import React from 'react';
import styles from './InputPassword.module.scss';
import cs from 'classnames';

const CustomPassword = ({
  children,
  message,
  className,
  rules,
  name,
  inputType,
  label,
  dependencies,
  isVertical = true,
  onChange,
  placeholder,
  ...rest
}) => {
  return (
    <div>
      <Form.Item
        className={cs(styles.inputWrapper, { [styles.inputVerticalWrapper]: isVertical })}
        label={label}
        name={name}
        required={false}
        dependencies={dependencies}
        rules={rules}
        onChange={onChange}
        labelAlign={isVertical ? 'left' : 'right'}
      >
        <Input.Password
          className={cs(styles.inputClass, { [className]: className })}
          name={name}
          placeholder={placeholder}
          {...rest}
        />
      </Form.Item>
    </div>
  );
};

export default CustomPassword;
