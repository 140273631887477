import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { debounce, indexOf } from 'lodash';
import { Container, Row, Card, Col } from 'react-bootstrap';
import { Modal, Tabs, Skeleton, Tooltip } from 'antd';

// Components
import CustomInput from '../../../components/Input';
import CustomTable from '../../../components/CustomTable';
import LineChart from '../../../components/ChildCharts/LineChart';

// Helper Functions
import { convertSeconds, generateChartOption, compareData, getOtherUserValue } from '../../../helpers/utility';

// Actions
import {
  getChildrenSummary,
  getMasteredChart,
  getDirectionChart,
  getComparisonChart,
} from '../../../store/actions/adminActions';

// Hooks
import { useReducerData } from '../../../store/hooks';

// Styles
import styles from './StudenSummaryt.module.scss';
import styled from 'styled-components';

function StudentSummary() {
  const dispatch = useDispatch();
  const loading = useReducerData('admin', 'loading');
  const data = useReducerData('admin', 'childrenSummary');
  const user = useReducerData('auth', 'user');

  const loadingChart = useReducerData('admin', 'loadingChart');
  const masteredChart = useReducerData('admin', 'masteredChart');
  const directionChartData = useReducerData('admin', 'directionChart');
  const comparisonChartData = useReducerData('admin', 'comparisonChart');

  const [onloadState, setOnloadState] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchStudent, setSearchStudent] = useState('');

  const [userId, setUserId] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [activeTab, setActiveTab] = useState('Numbers Mastered');

  const checkUserType = user.user_type === 'Parent' ? 'Child' : 'Student';
  const allUserType = user.user_type === 'Parent' ? 'Children' : 'Students';

  useEffect(() => {
    dispatch(getChildrenSummary(''));
    setOnloadState(true);
  }, []);

  const getMasteredGraph = (id) => {
    setUserId(id.id);
    dispatch(getMasteredChart(id.id));
  };
  const tabs = ['Numbers Mastered', 'Direction Chart', `All ${allUserType} Comparison`];

  const onTabChange = (e) => {
    setActiveTab(e);
    if (e === tabs[1]) {
      dispatch(getDirectionChart(userId));
    } else if (e === tabs[2]) {
      dispatch(getComparisonChart(userId));
    } else {
      dispatch(getMasteredChart(userId));
    }
  };

  const transformedObjectData = () => {
    const transformedObject = {};

    if (directionChartData && directionChartData?.direction_chart_time) {
      directionChartData?.direction_chart_time.forEach((item) => {
        const [unitPart, timePart] = item.split('-').map((part) => part.trim());

        if (timePart && unitPart) {
          const times = parseFloat(timePart.split(':')[0]) + parseFloat(`0.${timePart.split(':')[1]}`);
          const key = times.toFixed(2);
          if (unitPart in transformedObject) {
            if (!Array.isArray(transformedObject[unitPart])) {
              transformedObject[unitPart] = [transformedObject[key]];
            }
            transformedObject[unitPart].push(key);
          } else {
            transformedObject[unitPart] = key;
          }
        }
      });
      const entries = Object.entries(transformedObject);
      // entries && entries?.sort((a, b) => a[1] - b[1]);
      const sortedData = Object.fromEntries(entries);

      return sortedData;
    }
  };

  const changeText = () => {
    const data =
      directionChartData &&
      directionChartData?.direction_chart_time &&
      Object.keys(transformedObjectData()).map((item, index) => `Attempt ${index + 1}`);
    return data;
  };

  const optionDirectionChart = {
    title: {
      text: 'Direction Chart Graph',
    },
    xAxis: {
      type: 'category',
      data: directionChartData && directionChartData?.direction_chart_time && changeText(),
      name: 'Total Attempts on Direction Chart',
      nameLocation: 'middle',
      nameGap: 40,
    },
    yAxis: {
      type: 'value',
      name: 'Direction Chart Time',
      nameLocation: 'middle',
      nameGap: 70,
      min: 0,
      max: 5,
      axisLabel: {
        formatter: '{value} mins',
      },
    },
    series: [
      {
        name: 'Direction Chart Time',
        type: 'line',
        data: directionChartData && directionChartData?.direction_chart_time && Object.values(transformedObjectData()),
      },
    ],
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        const time = params?.value;
        const name = params?.name;
        // const masterCount = Array.isArray(transformedObjectData()?.[time])
        //   ? transformedObjectData()?.[time].join(', ')
        //   : transformedObjectData()?.[time];
        const customText = `<b>${name}</b> <br><b>Direction Chart Time:</b> ${time} mins`;
        return customText;
      },
    },
  };

  const columns = [
    {
      title: `${checkUserType}`,
      dataIndex: 'name',
      key: 'name',
      width: 150,
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      title: 'Age ',
      dataIndex: 'age',
      key: 'age',
      width: 100,
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      title: 'Grade ',
      dataIndex: 'grade',
      key: 'grade',
      width: 100,
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      title: 'Disability ',
      dataIndex: 'disability',
      key: 'disability',
      width: 150,
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },

    {
      title: 'Days Used',
      dataIndex: 'days',
      key: 'days',
      width: 150,
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      title: 'First date of Use',
      dataIndex: 'first_date',
      key: 'first_date',
      width: 150,
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      title: 'Last date of Use',
      dataIndex: 'last_date',
      key: 'last_date',
      width: 150,
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      title: 'Total time for addition unit',
      dataIndex: 'total_time_addition',
      key: 'total_time_addition',
      width: 150,
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      title: 'Total time for subtraction unit',
      dataIndex: 'total_time_subtraction',
      key: 'total_time_subtraction',
      width: 150,
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      title: 'Total time for multiplication unit',
      dataIndex: 'total_time_multiplication',
      key: 'total_time_multiplication',
      width: 150,
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      title: 'Total time for division unit',
      dataIndex: 'total_time_division',
      key: 'total_time_division',
      width: 150,
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      title: 'Total time on app',
      dataIndex: 'total_time',
      key: 'total_time',
      width: 150,
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      title: 'Addition unit and completed number',
      dataIndex: 'addition_category_number',
      key: 'addition_category_number',
      width: 200,
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      title: 'Subtraction unit and completed number',
      dataIndex: 'subtraction_category_number',
      key: 'subtraction_category_number',
      width: 200,
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      title: 'Multiplication unit and completed number',
      dataIndex: 'multiplication_category_number',
      key: 'multiplication_category_number',
      width: 200,
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      title: 'Division unit and completed number',
      dataIndex: 'division_category_number',
      key: 'division_category_number',
      width: 200,
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    // {
    //   title: 'Completed Unit and number',
    //   dataIndex: 'activity',
    //   key: 'activity',
    //   width: 200,
    //   render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    // },
    {
      title: 'Comparison Charts',
      dataIndex: '',
      key: 'id',
      width: 200,
      render: (item, id) => (
        <Tooltip title="Detail">
          <a
            onClick={() => {
              getMasteredGraph(id);
              setModalVisible(true);
            }}
          >
            <i style={{ color: '#474862', fontSize: 25 }} className="fas fa-file-alt "></i>
          </a>
        </Tooltip>
      ),
    },
  ];
  const tableData = () => {
    const newData =
      data &&
      data?.[0]?.results?.map((item) => ({
        id: item?.user.id,
        name: item.first_name,
        age: item.age,
        grade: item.grade,
        disability: item.disability,
        days: item.user.days,
        first_date: moment(item?.user?.date_joined).format('MMM Do, YYYY'),
        last_date: item?.user?.last_login ? moment(item?.user?.last_login).format('MMM Do, YYYY') : '',
        total_time: convertSeconds(item?.user?.total_duration?.total_time_on_app),
        // activity: item.user?.category_number ? item?.user?.category_number : '-',
        addition_category_number: item.user?.addition_category_number ? item?.user?.addition_category_number : '-',
        subtraction_category_number: item.user?.subtraction_category_number
          ? item?.user?.subtraction_category_number
          : '-',
        multiplication_category_number: item.user?.multiplication_category_number
          ? item?.user?.multiplication_category_number
          : '-',
        division_category_number: item.user?.division_category_number ? item?.user?.division_category_number : '-',
        total_time_addition: convertSeconds(item?.user?.total_duration?.total_addition_time) || '-',
        total_time_subtraction: convertSeconds(item?.user?.total_duration?.total_subtraction_time) || '-',
        total_time_multiplication: convertSeconds(item?.user?.total_duration?.total_multiplication_time) || '-',
        total_time_division: convertSeconds(item?.user?.total_duration?.total_division_time) || '-',
      }));
    return newData;
  };

  const nextPagination = (e) => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setPageNumber(e);
    const data = {
      page: e,
    };
    dispatch(getChildrenSummary(data));
  };

  const onChangeHandle = debounce((e) => {
    setSearchStudent(e.target.value);
    dispatch(getChildrenSummary(e.target.value));
  }, 1000);

  const notFound = () => {
    return (
      <Col md="12">
        <Card>
          <Card.Body>
            <div className="text-center">
              <span>No data found </span>
            </div>
          </Card.Body>
        </Card>
      </Col>
    );
  };

  const numbersMastered = () => {
    return (
      <Row>
          <Col md="12">
            <Card>
              <Card.Body>
                <LineChart
                option={{}}
                  />
              </Card.Body>
              <StyleText>Coming Soon</StyleText>
            </Card>
          </Col>
        {/* {!!masteredChart?.mastered_number_for_addition?.[0]?.length && (
          <Col md="6">
            <Card>
              <Card.Body>
                <LineChart option={generateChartOption('Addition', masteredChart?.mastered_number_for_addition, 70)} />
              </Card.Body>
            </Card>
          </Col>
        )}

        {!!masteredChart?.mastered_number_for_subtraction?.[0]?.length && (
          <Col md="6">
            <Card>
              <Card.Body>
                <LineChart
                  option={generateChartOption('Subtraction', masteredChart?.mastered_number_for_subtraction, 80)}
                />
              </Card.Body>
            </Card>
          </Col>
        )}

        {!!masteredChart?.mastered_number_for_multiplication?.[0]?.length && (
          <Col md="6">
            <Card>
              <Card.Body>
                <LineChart
                  option={generateChartOption('Multiplication', masteredChart?.mastered_number_for_multiplication, 72)}
                />
              </Card.Body>
            </Card>
          </Col>
        )}
        {!!masteredChart?.mastered_number_for_division?.[0]?.length && (
          <Col md="6">
            <Card>
              <Card.Body>
                <LineChart option={generateChartOption('Division', masteredChart?.mastered_number_for_division, 80)} />
              </Card.Body>
            </Card>
          </Col>
        )}
        {masteredChart?.mastered_number_for_addition?.[0]?.length < 1 &&
          masteredChart?.mastered_number_for_subtraction?.[0]?.length < 1 &&
          masteredChart?.mastered_number_for_multiplication?.[0]?.length < 1 &&
          masteredChart?.mastered_number_for_division?.[0]?.length < 1 &&
          notFound()} */}
      </Row>
    );
  };

  const comparisonStudentChart = (allStudents, singleStudent) => {
    if(singleStudent[0]?.length > 0){
      const unavailableTitles = allStudents[0]?.filter(title => !singleStudent[0]?.includes(title));
      unavailableTitles.forEach(title => {
        const index = allStudents[0]?.indexOf(title);
        singleStudent[0]?.splice(index, 0, title);
        singleStudent[1]?.splice(index, 0, 0);
      });
    }

    return {
      legend: {
        data: [
          `The average of all ${checkUserType === 'Child' ? 'Children\'s' : checkUserType} results`,
          `${
            comparisonChartData?.first_name.charAt(0).toUpperCase() + comparisonChartData?.first_name.slice(1)
          }'s Results`,
        ],
      },
      grid: {
        left: '6%',
        right: '4%',
        bottom: '10%',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        data: singleStudent && compareData(allStudents[0], allStudents[1])?.sortedUnitNames,
        name: 'Mastered Quiz',
        nameLocation: 'middle',
        nameGap: 60,
        axisLabel: {
          formatter: function (value, index) {
            return `${value}`;
          },
          interval: 0,
          rotate: -25,
        },
      },
      yAxis: {
        type: 'value',
        name: 'Time on Mastered Number',
        nameLocation: 'middle',
        nameGap: 95,
        axisLabel: {
          formatter: '{value} mins',
        },
      },
      series: [
        {
          name: `${
            comparisonChartData?.first_name.charAt(0).toUpperCase() + comparisonChartData?.first_name.slice(1)
            }'s Results`,
          type: 'line',
          color: 'red',
          data: singleStudent && compareData(singleStudent[0], singleStudent[1])?.sortedNumericValues,
        },
        {
          name: `The average of all ${checkUserType === 'Child' ? 'Children\'s' : checkUserType} results`,
          type: 'line',
          color: 'green',
          data: singleStudent && compareData(allStudents[0], allStudents[1])?.sortedNumericValues,
        },
      ],

      tooltip: {
        trigger: 'item',
        formatter: function (params) {
          const timeData = params?.data;
          const min = params?.data > 1 ? 'mins' : 'min'
          if (singleStudent) {
            if(params.color == 'green'){
              return `<b>${params.name} Time:</b> ${timeData} ${min}`;
            }else{
              return `<b>${params.name}</b> <br><b>Activity Time:</b> ${timeData} ${min}`;
            }
          }
        },
      },
    };
  };

  const directionChart = () => {
    return (
      <Row>
           <Col md="12">
            <Card>
              <Card.Body>
                <LineChart
                option={{}}
                  />
              </Card.Body>
              <StyleText>Coming Soon</StyleText>
            </Card>
          </Col>
        {/* {!!directionChartData?.direction_chart_time?.length && (
          <Col md="12">
            <Card>
              <Card.Body>
                <LineChart option={optionDirectionChart}  />
              </Card.Body>
            </Card>
          </Col>
        )}

        {directionChartData?.direction_chart_time?.length < 1 && notFound()} */}
      </Row>
    );
  };

  const comparisonChart = () => {
    return (
      <Row>
           <Col md="12">
            <Card>
              <Card.Body>
                <LineChart
                option={{}}
                  />
              </Card.Body>
              <StyleText>Coming Soon</StyleText>
            </Card>
          </Col>
        {/* {!!comparisonChartData?.mastered_number_for_student?.length && (
          <Col md="12">
            <Card>
              <Card.Body>
                <LineChart
                  option={comparisonStudentChart(
                    comparisonChartData?.mastered_number_for_all_student,
                    comparisonChartData?.mastered_number_for_student,
                  )}
                />
              </Card.Body>
            </Card>
          </Col>
        )}
        {comparisonChartData?.mastered_number_for_student?.length < 1 && notFound()} */}
      </Row>
    );
  };

  const loadingCharts = () => {
    return <Skeleton active paragraph={{ rows: 4 }} />;
  };

  return (
    <>
      <Container fluid>
        {user.user_type !== 'Parent' && (
          <CustomInput
            label={''}
            inputType="text"
            name="search"
            className={styles.search}
            value={searchStudent}
            onChange={onChangeHandle}
            placeholder={`Search by ${checkUserType?.toLowerCase()} name`}
            searchField
          // allowClear
          />
        )}

        <Row>
          <div className={styles.table}>
            <CustomTable
              columns={columns}
              data={tableData()}
              loading={loading}
              onloadState={onloadState}
              nextPagination={nextPagination}
              pageNumber={pageNumber}
              totalPages={data && data?.total_pages}
            />
          </div>
        </Row>
        <Modal
          // title="Modal 1000px width"
          centered
          open={modalVisible}
          onOk={() => {
            setModalVisible(false);
            setActiveTab('Numbers Mastered');
          }}
          onCancel={() => {
            setModalVisible(false);
            setActiveTab('Numbers Mastered');
          }}
          width={1500}
          footer={null}
          style={{ maxWidth: '90%', maxHeight: 'calc(100vh - 110px)' }}
        >
          <Container fluid>
            <Tabs
              type="card"
              activeKey={activeTab}
              onChange={onTabChange}
              items={tabs.map((item, i) => {
                return {
                  label: item,
                  key: item,
                  children: loadingChart
                    ? loadingCharts()
                    : i === 0
                      ? numbersMastered()
                      : i === 1
                        ? directionChart()
                        : i === 2
                          ? comparisonChart()
                          : notFound(),
                };
              })}
            />
          </Container>
        </Modal>
      </Container>
    </>
  );
}

export default StudentSummary;


export const StyleText = styled.p`
  position: absolute;
  top: 40%;
  width: 100%;
  font-size: 30px;
  text-align: center;
`