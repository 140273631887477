const getTimeRemaining = (e) => {
  const total = Date.parse(e) - Date.parse(new Date());
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  return {
    total,
    minutes,
    seconds,
  };
};

const startTimer = (questionTimer, setQuestionTimer, Ref, e) => {
  let { total, minutes, seconds } = getTimeRemaining(e);
  const questionTimerData = {
    minutes,
    seconds,
  };
  if (total >= 0) {
    setQuestionTimer((minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds < 10 ? '0' + seconds : seconds));
  }
};

var timeIntervelId;

const clearTimer = (questionTimer, setQuestionTimer, Ref, e, totalTime) => {
  setQuestionTimer(totalTime ? `${totalTime}:00` : '05:00');
  if (Ref.current) clearInterval(Ref.current);
  timeIntervelId = setInterval(() => {
    startTimer(questionTimer, setQuestionTimer, Ref, e);
  }, 1000);

  Ref.current = timeIntervelId;
};

const getDeadTime = (totalTime) => {
  let deadline = new Date();

  let min = totalTime ? totalTime - 1 : 4;
  deadline.setMinutes(deadline.getMinutes() + min);
  deadline.setSeconds(deadline.getSeconds() + 60);

  return deadline;
};

const QuestionStartClick = (questionTimer, setQuestionTimer, Ref, totalTime) => {
  clearTimer(questionTimer, setQuestionTimer, Ref, getDeadTime(totalTime), totalTime);
};

const stopQuestionTime = () => {
  clearInterval(timeIntervelId);
};

export { QuestionStartClick, stopQuestionTime };
