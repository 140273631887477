import Types from '../types/subscriptionTypes';
import AuthTypes from '../types/AuthTypes';
import Cookies from 'js-cookie';

import {
  getSubscriptions,
  resendCode,
  verifyCode,
  addSubscriptions,
  cancelSubscription,
  checkSubscription,
} from '../../api/subscriptionApi';
import { notification } from 'antd';
import { doLogout, attemptUpdateUserProfile } from './authActions';

export const getSubscriptionsApi = () => {
  return async (dispatch) => {
    dispatch({
      type: Types.GET_SUBSCRIPTIONS,
    });

    const resp = await getSubscriptions();

    if (resp.status === 1) {
      delete resp.status;
      dispatch({
        type: Types.SUCCESS_GET_SUBSCRIPTIONS,
        payload: [resp],
      });
    } else {
      notification['error']({
        message: resp?.message?.message,
      });
      dispatch({
        type: Types.FAILURE_GET_SUBSCRIPTIONS,
        status: resp.status,
        message: resp.message,
      });
    }
    return resp.status;
  };
};

export const createSubscriptions = (payload, callBack, setLoading) => {
  return async (dispatch) => {
    dispatch({
      type: Types.ADD_SUBSCRIPTIONS,
    });
    const resp = await addSubscriptions(payload);
    if (resp.status === 1) {
      dispatch({
        type: Types.ADD_SUBSCRIPTIONS_SUCCESS,
        data: resp,
      });
      callBack();
    } else {
      setLoading(false);
      notification['error']({
        message: resp?.message?.message,
      });
      dispatch({
        type: Types.ADD_SUBSCRIPTIONS_SUCCESS,
        data: resp,
      });
    }

    return resp.status;
  };
};

export const resendCodeApi = () => {
  return async (dispatch) => {
    dispatch({
      type: Types.RESEND_CODE,
    });

    const resp = await resendCode();
    if (resp.status === 1) {
      dispatch({
        type: Types.SUCCESS_RESEND_CODE,
        payload: resp || { data: {} },
      });
      notification['success']({
        message: `${resp?.message}`,
      });
    } else {
      dispatch({
        type: Types.SUCCESS_RESEND_CODE,
        payload: resp || { data: {} },
      });
    }
    return resp.status;
  };
};

export const verifyCodeApi = (payload, callBack) => {
  return async (dispatch) => {
    dispatch({
      type: Types.VERIFY_CODE,
    });

    const resp = await verifyCode(payload);
    if (resp.status === 1) {
      callBack();
      dispatch({
        type: Types.SUCCESS_VERIFY_CODE,
        payload: resp || { data: {} },
      });
      dispatch({
        type: AuthTypes.USER_SUBSCRIPTION,
        payload: resp,
      });
      const user = JSON.parse(localStorage.getItem('user'));
      const updateUser = { user_type: payload?.product_for === 'Teachers' ? 'Teacher' : 'Parent', id: user.id };
      dispatch(attemptUpdateUserProfile(updateUser));
    } else {
      notification['error']({
        message: resp?.message?.message,
      });
      dispatch({
        type: Types.SUCCESS_VERIFY_CODE,
        payload: resp || { data: {} },
      });
    }
    return resp.status;
  };
};

export const cancelSubscriptionApi = (payload, hideModal) => {
  return async (dispatch) => {
    dispatch({
      type: Types.CANCEL_SUBSCRIPTION,
    });

    const resp = await cancelSubscription(payload);
    if (resp.status === 1) {
      hideModal();
      notification['success']({
        message: 'Subscription cancel successfully',
      });
      dispatch({
        type: Types.SUCCESS_CANCEL_SUBSCRIPTION,
        payload: resp || { data: {} },
      });
      dispatch({
        type: AuthTypes.USER_SUBSCRIPTION,
        payload: resp,
      });
      dispatch(checkSubscriptionApi());
    } else {
      notification['error']({
        message: resp?.message?.message,
      });
      dispatch({
        type: Types.SUCCESS_CANCEL_SUBSCRIPTION,
        payload: resp || { data: {} },
      });
    }
    return resp.status;
  };
};

export const checkSubscriptionApi = () => {
  return async (dispatch) => {
    const resp = await checkSubscription();
    if (resp.status === 1) {
      dispatch({
        type: AuthTypes.USER_SUBSCRIPTION,
        payload: resp,
      });
    } else {
      const user = JSON.parse(localStorage.getItem('user'));
      const tokenQuiz =
        user?.user_type === 'Student' || user?.user_type === 'Children'
          ? sessionStorage.getItem('token')
          : Cookies.get('token');
      if (user && tokenQuiz && resp?.message?.detail === 'Invalid token.') {
        dispatch(doLogout());
      }
    }
    return resp.status;
  };
};

export const setTermService = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: Types.SET_TERM_SERVICE,
      payload,
    });
  };
};

export const clearTermService = () => {
  return async (dispatch) => {
    dispatch({
      type: Types.CLEAR_TERM_SERVICE,
    });
  };
};
