import React, { useState } from 'react';
import styles from './Profile.module.scss';
import ParentMenu from './ParentMenu';
import TeacherMenu from './TeacherMenu';
import EditUser from './EditUser';
import { ReactComponent as DownArrow } from '../../assets/images/DownDropDownArrow.svg';
import { ReactComponent as UpperArrow } from '../../assets/images/UpperDropDownArrow.svg';
import { useReducerData } from '../../store/hooks';

const Profile = () => {
  const [displaySection, setDisplaySection] = useState(true);
  const [displayForm, setDisplayForm] = useState(true);
  const mainClass = !displaySection ? styles.hideForm : styles.mainCls;
  const Icon = !displaySection ? DownArrow : UpperArrow;
  const selectedUserType = useReducerData('auth', 'selectedUserType');

  return (
    <>
      <div className={`${styles.mainContainer} ${mainClass}`}>
        {selectedUserType === 'child' && (
          <div className={styles.parentDropDown} style={{ height: !displaySection ? '35px' : 'auto' }}>
            <div
              className={displaySection ? `${styles.parentHeader} ${styles.headerTextCls}` : `${styles.parentHeader}`}
              onClick={() => {
                setDisplaySection((prev) => !prev);
                setDisplayForm(true);
              }}
            >
              <div className={styles.headerText}>I am a Parent</div>
              <div className={styles.UpperArrow}>
                <Icon height="15px" />
              </div>
            </div>
            <div className={styles.displayFormContainer}>{displaySection && <ParentMenu />}</div>
          </div>
        )}
        {selectedUserType === 'student' && (
          <div className={styles.parentDropDown} style={{ height: !displaySection ? '35px' : 'auto' }}>
            <div
              className={!displayForm ? `${styles.parentHeader} ${styles.headerTextCls}` : `${styles.parentHeader}`}
              onClick={() => {
                setDisplaySection((prev) => !prev);
                setDisplayForm(true);
              }}
            >
              <div className={styles.headerText}>I am a Teacher</div>
              <div className={styles.UpperArrow}>
                <Icon height="15px" />
              </div>
            </div>
            <div className={styles.displayFormContainer}>{displaySection && <TeacherMenu />}</div>
          </div>
        )}
        {selectedUserType === '' && (
          <>
            <div className={styles.parentDropDown} style={{ height: !displaySection ? '35px' : 'auto' }}>
              <div
                className={displayForm ? `${styles.parentHeader} ${styles.headerTextCls}` : `${styles.parentHeader}`}
                onClick={() => {
                  setDisplaySection((prev) => !prev);
                  setDisplayForm(true);
                }}
              >
                <div className={styles.headerText}>Edit User</div>
                <div className={styles.UpperArrow}>
                  <Icon height="15px" />
                </div>
              </div>
              <div className={styles.displayFormContainer}>{displaySection && <EditUser />}</div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Profile;
