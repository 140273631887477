import { additionQuiz } from './additionUnitData';
import { subtractionQuiz } from './subtractionUnitData';
import { multiplicationQuiz } from './multiplicationUnitData';
import { divisionQuiz } from './divisionUnitData';
import NumberQuiz from '../../assets/images/HomePage/Programs/MathPrograms/programImage1.svg';
import Direction from '../../assets/images/HomePage/Programs/MathPrograms/programImage2.png';
import Figure8 from '../../assets/images/HomePage/Programs/MathPrograms/programImage3.png';
import FingerSkip from '../../assets/images/HomePage/Programs/MathPrograms/programImage5.png';
import BrainWarrior from '../../assets/images/HomePage/Programs/MathPrograms/programImage6.png';
import NumberFamilies from '../../assets/images/HomePage/Programs/MathPrograms/programImage4.png';
// import Count7 from '../../assets/images/HomePage/Programs/MathPrograms/programImage7.png';
import SpeedCounting from '../../assets/images/HomePage/Programs/MathPrograms/programImage10.svg';
// import SpeedCountingSubtraction from '../../assets/images/HomePage/Programs/MathPrograms/programImage12.png';
import RewardTracking from '../../assets/images/HomePage/Programs/MathPrograms/programImage9.png';
import SubtractionNumberQuiz from '../../assets/images/HomePage/Programs/MathPrograms/programImage13.svg';
import MultiplicationNumberQuiz from '../../assets/images/HomePage/Programs/MathPrograms/programImage14.svg';
import DivisionNumberQuiz from '../../assets/images/HomePage/Programs/MathPrograms/programImage15.svg';
import SpeedCountingSubtraction from '../../assets/images/HomePage/Programs/MathPrograms/SpeedCountingSubtraction.png';
import ColourCodedAddition from '../../assets/images/HomePage/Programs/MathPrograms/programImage16.png';
import ColourCodedSubtraction from '../../assets/images/HomePage/Programs/MathPrograms/programImage17.png';
import ColourCodedMultiplication from '../../assets/images/HomePage/Programs/MathPrograms/programImage18.png';
import ColourCodedDivision from '../../assets/images/HomePage/Programs/MathPrograms/programImage19.png';
import MultiplicationSpeedCounting from '../../assets/images/HomePage/Programs/MathPrograms/programImage20.png';
import DivisionSpeedCounting from '../../assets/images/HomePage/Programs/MathPrograms/programImage21.png';
import SubtractionNumberFamilies from '../../assets/images/HomePage/Programs/MathPrograms/programImage22.png';

export const tabs = [
  {
    tab: {
      type: 'Addition Unit',
      key: 0,
      quizList: [
        {
          key: 0,
          title: 'Number Quiz',
          image: NumberQuiz,
        },
        {
          key: 1,
          title: 'Figure 8 activity',
          image: Figure8,
        },
        // {
        //   key: 2,
        //   title: 'Count by Seven',
        //   image: Count7,
        // },
        {
          key: 2,
          title: 'Speed Number Counting',
          image: SpeedCounting,
        },
        {
          key: 3,
          title: 'Direction Charts',
          image: Direction,
        },
        {
          key: 4,
          title: 'Colour coded memory cards',
          image: ColourCodedAddition,
        },
        {
          key: 5,
          title: 'Brain Warrior Workout',
          image: BrainWarrior,
          neededBackground: true,
        },
        {
          key: 6,
          title: 'Final Quiz',
          image: NumberQuiz,
        },
        {
          key: 7,
          title: 'Reward Chart',
          image: RewardTracking,
          neededBackground: true,
        },
      ],
    },
  },
  {
    tab: {
      type: 'Subtraction Unit',
      key: 1,
      quizList: [
        {
          key: 0,
          title: 'Number Quiz',
          image: SubtractionNumberQuiz,
        },
        {
          key: 1,
          title: 'Figure 8 activity',
          image: Figure8,
        },
        {
          key: 2,
          title: 'Speed Number Counting',
          image: SpeedCountingSubtraction,
        },
        {
          key: 3,
          title: 'Direction Charts',
          image: Direction,
        },
        {
          key: 4,
          title: 'Colour coded memory cards',
          image: ColourCodedSubtraction,
        },
        {
          key: 5,
          title: 'Brain Warrior Workout',
          image: BrainWarrior,
          neededBackground: true,
        },
        {
          key: 6,
          title: 'Number Families',
          image: SubtractionNumberFamilies,
        },
        {
          key: 7,
          title: 'Final Quiz',
          image: SubtractionNumberQuiz,
        },
        {
          key: 8,
          title: 'Reward Chart',
          image: RewardTracking,
          neededBackground: true,
        },
      ],
    },
  },
  {
    tab: {
      type: 'Multiplication Unit',
      key: 2,
      quizList: [
        {
          key: 0,
          title: 'Number Quiz',
          image: MultiplicationNumberQuiz,
        },
        {
          key: 1,
          title: 'Figure 8 activity',
          image: Figure8,
        },
        {
          key: 2,
          title: 'Speed Number Counting',
          image: MultiplicationSpeedCounting,
        },
        {
          key: 3,
          title: 'Direction Charts',
          image: Direction,
        },
        {
          key: 4,
          title: 'Finger Skip Counting',
          image: FingerSkip,
          neededBackground: true,
        },
        {
          key: 5,
          title: 'Colour coded memory cards',
          image: ColourCodedMultiplication,
        },
        {
          key: 6,
          title: 'Brain Warrior Workout',
          image: BrainWarrior,
          neededBackground: true,
        },
        {
          key: 7,
          title: 'Final Quiz',
          image: MultiplicationNumberQuiz,
        },
        {
          key: 8,
          title: 'Reward Chart',
          image: RewardTracking,
          neededBackground: true,
        },
      ],
    },
  },
  {
    tab: {
      type: 'Division Unit',
      key: 3,
      quizList: [
        {
          key: 0,
          title: 'Number Quiz',
          image: DivisionNumberQuiz,
        },
        {
          key: 1,
          title: 'Figure 8 activity',
          image: Figure8,
        },
        {
          key: 2,
          title: 'Speed Number Counting',
          image: DivisionSpeedCounting,
        },
        {
          key: 3,
          title: 'Direction Charts',
          image: Direction,
        },
        {
          key: 4,
          title: 'Colour coded memory cards',
          image: ColourCodedDivision,
        },
        {
          key: 5,
          title: 'Brain Warrior Workout',
          image: BrainWarrior,
          neededBackground: true,
        },
        {
          key: 6,
          title: 'Number Families',
          image: NumberFamilies,
        },
        {
          key: 7,
          title: 'Final Quiz',
          image: DivisionNumberQuiz,
        },
        {
          key: 8,
          title: 'Reward Chart',
          image: RewardTracking,
          neededBackground: true,
        },
      ],
    },
  },
];

export const newTabs = [
  {
    unitName: 'Addition Unit',
    quiz: additionQuiz,
  },
  {
    unitName: 'Subtraction Unit',
    quiz: subtractionQuiz,
  },
  {
    unitName: 'Multiplication Unit',
    quiz: multiplicationQuiz,
  },
  {
    unitName: 'Division Unit',
    quiz: divisionQuiz,
  },
];
