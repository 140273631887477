import React, { useEffect, useState } from 'react';
import styles from './Activities.module.scss';
import { useNavigate } from 'react-router-dom';
import QuizHeader from '../QuizLayout/QuizHeader/QuizHeader';
import { getTitle, signContent } from '../../../helpers/utility';
import cs from 'classnames';
import { Form } from 'antd';
import CustomInput from '../../../components/Input';
import { useReducerData } from '../../../store/hooks';
import { attemptSetResumeQuiz } from '../../../store/actions/quizActions';
import { useDispatch } from 'react-redux';
import SubtractionActivities from './SubtractionActivities';
import { checkSubscriptionApi } from '../../../store/actions/subscriptionActions';

const Activities = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useReducerData('quiz', 'quizData');
  const tabKey = useReducerData('quiz', 'tabKey');
  const currentQuizKey = useReducerData('quiz', 'currentQuizKey');
  const elements = data?.data?.[+tabKey === 0 || +tabKey ? +tabKey : 0]?.quiz;

  const { key, subKey } = currentQuizKey[+tabKey];

  const quizData = elements;
  const [isDisabled, setIsDisabled] = useState(false);

  const questionHandleClick = (index) => {
    const updateQuizData = currentQuizKey[+tabKey];
    updateQuizData.tabKey = tabKey;
    updateQuizData.key = key === index ? key : index;
    updateQuizData.subKey = key === index ? subKey : 0;

    dispatch(attemptSetResumeQuiz(updateQuizData));
    setIsDisabled(true);
    const findData = elements[index]?.data[key === index ? subKey : 0];
    navigate(`${findData?.redirectTo}`);
    // }
  };

  useEffect(() => {
    dispatch(checkSubscriptionApi());
  }, []);

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.modalContainer}>
          <QuizHeader isDisplayNext={false} title={'Quiz'} isActivity={true} />
          <div className={styles.questionContainer}>
            {+tabKey !== 1 ? (
              quizData?.map((elements, index) => {
                return (
                  <div
                    className={cs(
                      { [styles.disabledView]: elements.isDisable },
                      { [styles.activeContainer]: !elements.isDisable },
                      { [styles.detailContainer]: elements.isDisable },
                    )}
                    key={index}
                    onClick={() => {
                      questionHandleClick(index);
                    }}
                  >
                    <div className={styles.numberContainer}>
                      <div className={styles.number1}>{index}</div>
                      <div className={styles.number2}>
                        <span>{signContent(String(tabKey))}</span>
                        <span>1</span>
                      </div>
                      <div className={styles.answer}>
                        <hr />
                        <Form colon={false} autoComplete="off" form={form}>
                          <CustomInput value={''} inputType="answer" name="answer" readOnly />
                        </Form>
                      </div>
                    </div>
                    <div className={styles.textContainer}>{getTitle(+tabKey, index)}</div>
                  </div>
                );
              })
            ) : (
              <SubtractionActivities questionHandleClick={questionHandleClick} form={form} />
            )}
          </div>
          <div className={!isDisabled ? styles.footerContainer : styles.activeFooterContainer}></div>
        </div>
      </div>
    </>
  );
};

export default Activities;
