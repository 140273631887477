import React, { useState } from 'react';
import styles from './forgotPassword.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { doResetPassword, doVerifyPassword } from '../../../store/actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
import Types from '../../../store/types/AuthTypes';
import OtpModal from './OtpModal';
import backIcon from '../../../assets/images/Union.svg';
import SendEmail from './SendEmail';
import NewPassword from './NewPassword';
import SuccessMessage from './SuccessMessage';
import { Form } from 'antd';

const ForgotPassword = () => {
  const [isDisplaynext, setIsDisplayNext] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDisplaySuccess, setIsDisplaySuccess] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [emailSent, setEmailSent] = useState(false)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams()
  const {otp} = params
  const [form] = Form.useForm();
  const [forgotPasswordData, setForgotPasswordData] = useState({
    email: '',
    otp: '',
    password: '',
    confirmPassword: '',
  });

  const resetPasswordhandleChange = ({ target: { name, value } }) => {
    setForgotPasswordData({
      ...forgotPasswordData,
      [name]: value,
    });
  };

  const handleSend = async () => {
    const payload = {
      email: forgotPasswordData.email,
    };
    setLoading(true);
    const response = await dispatch(doResetPassword(payload));
    if (response.message === 'Successfully Token sent.') {
      setLoading(false);
      setEmailSent(true);
      form.resetFields();
    } else {
      setLoading(false);
      setEmailSent(false);
    }
  };

  const selector = useSelector((state) => state.auth);

  const handleChangePassword = async () => {
    const payload = {
      email: selector.resetPassword.resetEmail,
      token: otp,
      password: forgotPasswordData.password,
    };
    setLoading(true);
    const response = await dispatch(doVerifyPassword(payload));
    if (response.message !== 'Wrong Token.') {
      setLoading(false);
      setIsDisplaySuccess(true);
      setForgotPasswordData({
        email: '',
        otp: '',
        password: '',
        confirmPassword: '',
      });
    } else {
      setLoading(false);
      navigate('/login');
      setForgotPasswordData({
        password: '',
        confirmPassword: '',
      });
    }
  };

  const handleOk = async () => {
    setIsModalVisible(false);
    setIsDisplayNext(true);
    dispatch({
      type: Types.FORGOT_PASSWORD_OTP,
      otp: forgotPasswordData.otp,
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleBack = () => {
    navigate('/login');
  };
  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.formContainer}>
          {!isDisplaySuccess ? (
            <div className={styles.ForgotPassText}>
              {!isDisplaynext && (
                <div className={styles.backContainer}>
                  <img src={backIcon} onClick={handleBack} alt="error" />
                </div>
              )}
              Forgot your Password?
            </div>
          ) : (
            <div className={styles.ForgotPassText}>Successful password reset!</div>
          )}
          {!otp ? (
            <SendEmail
              handleSend={handleSend}
              loading={loading}
              resetPasswordhandleChange={resetPasswordhandleChange}
              forgotPasswordData={forgotPasswordData}
              form={form}
              emailSent={emailSent}
            />
          ) : !isDisplaySuccess ? (
            <NewPassword
              handleChangePassword={handleChangePassword}
              loading={loading}
              forgotPasswordData={forgotPasswordData}
              resetPasswordhandleChange={resetPasswordhandleChange}
              form={form}
            />
          ) : (
            <SuccessMessage />
          )}
        </div>
      </div>
      <OtpModal
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        forgotPasswordData={forgotPasswordData}
        resetPasswordhandleChange={resetPasswordhandleChange}
        handleSend={handleSend}
      />
    </>
  );
};
export default ForgotPassword;
