import React, { useEffect, useState } from 'react';
import styles from './Pricing.module.scss';
import CustomButton from '../../components/Button/Button';
import { NavLink } from 'react-router-dom';
import PriceContainer from './PriceContainer';
import { isLoggedIn } from '../../helpers/utility';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { loginUserData } from '../../store/hooks';
import { Checkbox, notification } from 'antd';

//actions
import { checkSubscriptionApi, getSubscriptionsApi } from '../../store/actions/subscriptionActions';
import ConfirmModal from './PaymentModals/ConfirmModal';

const Pricing = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth);
  const { user, user_subscription, freeTrial } = userData;

  const PriceData = useSelector((state) => state.subscription.subscriptions);
  const [isLogin, setIsLogin] = useState(isLoggedIn());
  const [selectPlan, setSelectPlan] = useState(false);
  const [open, setOpen] = useState(false);

  const is_terms_agreed = user?.is_terms_agreed;

  const showModal = () => {
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
  };

  const checkSubscription = () => {
    if (user_subscription?.[0]?.status === 'active') {
      if (
        user_subscription?.[0]?.plan?.interval === selectPlan?.item?.interval &&
        user_subscription?.[0]?.plan?.nickname === selectPlan?.item?.nickname &&
        user_subscription?.[0]?.plan?.metadata?.product_type === selectPlan?.subscribeType
      ) {
        return true;
      } else return false;
    } else return false;
  };
  useEffect(() => {
    setIsLogin(isLoggedIn());
    dispatch(getSubscriptionsApi());
    if (isLogin && loginUserData() && user?.user_type !== 'Admin') {
      dispatch(checkSubscriptionApi());
    }
    if (user_subscription?.length) {
      if (freeTrial && user_subscription?.[0]?.plan.interval !== 'week') {
        setSelectPlan(user_subscription[0]);
      }
    }
  }, [user]);


  useEffect(() => {
    if (is_terms_agreed && selectPlan && location?.state) {
      checkPlan()
    }
  }, [selectPlan])

  const handleClick = () => {
    navigate('/signup');
  };

  const checkPlan = () => {
    if (selectPlan?.item) {
      if (user_subscription?.length) {
        if (checkSubscription()) {
          notification['info']({
            message: 'You have already subscribed to this plan.',
          });
        } else {
          if (user_subscription[0]?.plan?.nickname === selectPlan?.item?.nickname) {
            confirmChange();
          } else {
            showModal();
          }
        }
      } else {
        confirmChange();
      }
    } else {
      notification['error']({
        message: 'Please select your plan first.',
      });
    }
  }
  const changePlane = () => {
    if (!is_terms_agreed) {
      return navigate('/settings/terms',{ state: selectPlan?.item });
    }
    checkPlan()
  };
  const confirmChange = () => {
    user_subscription?.length && hideModal();
    selectPlan && selectPlan?.callBack(selectPlan?.item);
  };

  const handleTerms = () => {
    if (!is_terms_agreed) {
      navigate("/settings/terms", { state: selectPlan?.item })
    }
  }
  return (
    <div style={{ height: '100%' }}>
      {PriceData &&
        PriceData?.map((element, index) => {
          return (
            <div className={styles.homeContainer} key={index}>
              <div className={styles.parents}>
                <div className={styles.parentHeading}>
                  <span>Parent</span>
                  <div className={styles.child}>
                    <span>Parent with 1 child</span>
                  </div>
                </div>
                <div className={styles.parentContainer}>
                  {element?.Parents?.map((item) => (
                    <PriceContainer
                      heading={item.description}
                      priceData={item?.plans}
                      selectPlan={selectPlan}
                      setSelectPlan={setSelectPlan}
                      subscribeType={element?.Parents[0]?.description}
                      state={location?.state}
                    />
                  ))}
                </div>

                {isLogin && loginUserData() ? (
                  <>
                    <div className={styles.parentFooter}>
                      <Checkbox checked={is_terms_agreed} onClick={handleTerms}>By clicking you agree with our<NavLink to="/settings/terms"> Terms of Service</NavLink> and{' '}
                        <NavLink to="/settings/privacy"> Privacy Policy.</NavLink>
                      </Checkbox>
                    </div>
                    <div className={styles.becomeButton}>
                      <CustomButton onClick={changePlane} disabled={selectPlan?.item?.nickname !== 'Parents'}>
                        {' '}
                        {user_subscription?.length ? 'Change plan' : 'Become a member'}{' '}
                      </CustomButton>
                    </div>
                  </>
                ) : (
                  <div className={styles.becomeButton}>
                    <CustomButton onClick={handleClick}>Become a member</CustomButton>
                  </div>
                )}

                <div className={styles.parentFooter}>
                  Thank you for trusting our service
                </div>
              </div>

              <div className={styles.parents}>
                <div className={styles.parentHeading}>
                  <span>Teacher</span>
                  <div className={styles.child}>
                    <span>Teacher with 10 students</span>
                  </div>
                </div>

                <div className={styles.parentContainer}>
                  {element?.Teachers?.map((item) => (
                    <PriceContainer
                      heading={item.description}
                      priceData={item?.plans}
                      selectPlan={selectPlan}
                      setSelectPlan={setSelectPlan}
                      subscribeType={element?.Teachers[0]?.description}
                      state={location?.state}
                    />
                  ))}
                </div>

                {isLogin && loginUserData() ? (
                  <>
                    <div className={styles.parentFooter}>
                      <Checkbox checked={is_terms_agreed} onClick={handleTerms}>By clicking you agree with our<NavLink to="/settings/terms"> Terms of Service</NavLink> and{' '}
                        <NavLink to="/settings/privacy"> Privacy Policy.</NavLink>
                      </Checkbox>
                    </div>
                    <div className={styles.becomeButton}>
                      <CustomButton onClick={changePlane} disabled={selectPlan?.item?.nickname !== 'Teachers' }>
                        {user_subscription?.length ? 'Change plan' : 'Become a member'}{' '}
                      </CustomButton>
                    </div>
                  </>
                ) : ( 
                  <div className={styles.becomeButton}>
                    <CustomButton onClick={handleClick}>Become a member</CustomButton>
                  </div>
                )}

                <div className={`mb-2 ${styles.parentFooter}`}>
                  Thank you for trusting our service
                </div>
              </div>
            </div>
          );
        })}
      <ConfirmModal hideModal={hideModal} confirmChange={confirmChange} open={open} />
    </div>
  );
};

export default Pricing;
