import * as React from 'react';
import { Form, Select } from 'antd';
import styles from './InputDropDown.module.scss';
import cs from 'classnames';

const { Option } = Select;
const CustomInputDropDown = ({
  className,
  rules,
  name,
  label,
  isVertical = true,
  onChange,
  placeholder,
  data,
  selectedValue,
}) => {
  return (
    <>
      <Form.Item
        required={false}
        className={cs(styles.inputWrapper, { [styles.inputVerticalWrapper]: isVertical })}
        label={label}
        onChange={onChange}
        name={name}
        rules={rules}
        labelAlign={isVertical ? 'left' : 'right'}
      >
        <Select
          showSearch
          placeholder={placeholder}
          optionFilterProp="children"
          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          filterSort={(optionA, optionB) =>
            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
          }
          className={cs(styles.inputClass, { [className]: className })}
          defaultValue={selectedValue ? selectedValue : null}
          value={selectedValue ? selectedValue : null}
          onChange={onChange}
        >
          {data &&
            data.map((element, index) => (
              <Option value={element.key} key={index}>
                {element.label}
              </Option>
            ))}
        </Select>
      </Form.Item>
    </>
  );
};
export default CustomInputDropDown;
