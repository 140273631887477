import rootReducer from './reducers';
import { applyMiddleware, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { logger } from 'redux-logger/src';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
const emptyMiddleWare = () => (next) => (action) => {
  return next(action);
};

let loggerMiddleWare = emptyMiddleWare;

if (process.env.NODE_ENV === 'development') {
  loggerMiddleWare = createLogger({
    collapsed: true,
  });
}

const persistConfig = {
  key: 'root',
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, compose(applyMiddleware(thunk, logger, emptyMiddleWare)));
const persistor = persistStore(store);
export default store;
export { persistor };
