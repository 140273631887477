import React, { useState } from 'react';
import { Form } from 'antd';
import CustomInput from '../../../../components/Input';
import CustomButton from '../../../../components/Button';
import styles from '../Checkout.module.scss';
import { useDispatch, useSelector } from 'react-redux';

//actions
import { verifyCodeApi, resendCodeApi } from '../../../../store/actions/subscriptionActions';

const PaymentConfirmModal = ({ setIsConfirmModal, setIsPaymentSuccess, setStages, selectPlan, setSelectPlan }) => {
  const loading = useSelector((state) => state?.subscription?.verifyTokenLoading);
  const resendLoading = useSelector((state) => state?.subscription?.resendCodeLoading);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [paymentFormData, setPaymentFormData] = useState({
    confirmCode: '',
  });

  const handleChange = ({ target: { name, value } }) => {
    setPaymentFormData({
      ...paymentFormData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    const data = {
      code: paymentFormData?.confirmCode,
      category: selectPlan?.subscribeType,
      product_for: selectPlan?.item?.nickname,
      interval: selectPlan?.item?.interval,
    };

    dispatch(verifyCodeApi(data, resetData));
  };
  const resendCode = () => {
    dispatch(resendCodeApi());
  };
  const resetData = () => {
    setSelectPlan(false);
    setStages(3);
    setIsPaymentSuccess(true);
    setIsConfirmModal(false);
    setPaymentFormData({
      confirmCode: '',
    });
    form.resetFields();
  };

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.paymentText}>
          <div className={styles.headingText}>
            <span>Purchase Authentication</span>
          </div>
          <div className={styles.subHeadingText}>
            <span>We'll sent you a message to your registered email address.</span>
          </div>
        </div>
        <Form colon={false} onFinish={handleSubmit} autoComplete="off" form={form}>
          <CustomInput
            label="Confirmation Code"
            inputType="input"
            name="confirmCode"
            value={paymentFormData.confirmCode}
            onChange={handleChange}
            rules={[
              {
                required: true,
                message: 'Please enter Code',
                whitespace: true,
              },
            ]}
            allowClear
          />

          <div className={styles.paymentFooter}>
            <CustomButton type="primary" loading={loading} htmlType="submit">
              Confirm Payment
            </CustomButton>
          </div>
        </Form>
        <CustomButton type="link" loading={resendLoading} onClick={resendCode}>
          Resend Code
        </CustomButton>
      </div>
    </>
  );
};

export default PaymentConfirmModal;
