import styled from 'styled-components';
import { Select } from 'antd';
import 'antd/dist/antd.min.css';

const variants = {
  redborder: `
  border-color: #EB0101 !important;
  `,
  dark: `
    border-color: '#C5C5C5' !important;
  `,
};

function AntdDataSelect({
  variant,
  style,
  filterSort,
  filterOption,
  optionFilterProp,
  options,
  label,
  value,
  error,
  disabled,
  onChangeHandeler,
  ...props
}) {
  return (
    <>
      {label && <label className="d-block fw-bold mt-2">{label}</label>}
      <AntdSelect
        disabled={disabled}
        placeholder={label}
        showSearch
        variant={variant}
        style={style}
        value={value}
        onChange={onChangeHandeler}
        filterSort={filterSort}
        filterOption={filterOption}
        optionFilterProp={optionFilterProp}
        error={error}
      >
        {options}
      </AntdSelect>
      {error && <small style={{ color: `#EB0101` }}>*{error}</small>}
    </>
  );
}
export default AntdDataSelect;
const AntdSelect = styled(Select)`
  width: 100%;
  border-radius: 6px !important;
  font-size: 14px;
  div {
    &.ant-select-selector {
      padding: 3px 0px 3px 10px !important;
      height: auto !important;
      border-radius: 5px !important;
      width: 100% !important;
      ${(p) => variants[p.variant]}
    }
  }
`;
