import React from 'react';
import cs from 'classnames';
import styles from './Activities.module.scss';
import { getTitle, signContent } from '../../../helpers/utility';
import { Form } from 'antd';
import CustomInput from '../../../components/Input';
import { useReducerData } from '../../../store/hooks';

const SubtractionActivities = ({ questionHandleClick, form }) => {
  const data = useReducerData('quiz', 'quizData');
  const tabKey = useReducerData('quiz', 'tabKey');
  const currentQuizKey = useReducerData('quiz', 'currentQuizKey');
  const elements = data?.data[+tabKey]?.quiz;
  const { key } = currentQuizKey[+tabKey];
  const quizData = elements;
  const tens = true;
  return (
    <>
      {quizData?.map((elements, index) => {
        return (
          <div
            className={cs(
              { [styles.disabledView]: elements.isDisable },
              { [styles.activeContainer]: !elements.isDisable },
              { [styles.detailContainer]: elements.isDisable },
            )}
            key={index}
            onClick={() => {
              questionHandleClick(index);
            }}
          >
            <div className={styles.numberContainer}>
              <div className={styles.number1}>{index === 0 ? '1' : index}</div>
              <div className={styles.number2}>
                <span>{signContent(String(tabKey))}</span>
                <span>{index === 0 ? '0' : '1'}</span>
              </div>
              <div className={styles.answer}>
                <hr />
                <Form colon={false} autoComplete="off" form={form}>
                  <CustomInput value={''} inputType="answer" name="answer" readOnly />
                </Form>
              </div>
            </div>
            <div className={styles.textContainer}>{getTitle(+tabKey, index)}</div>
          </div>
        );
      })}

      {/* <div
        className={cs(
          { [styles.disabledView]: key !== 11 && key !== 12 },
          { [styles.activeContainer]: key === key },
          { [styles.detailContainer]: key !== 11 && key !== 12 },
        )}
        key={key === 12 ? 12 : 11}
        onClick={() => {
          questionHandleClick(key);
        }}
      >
        <div className={styles.numberContainer}>
          <div className={styles.number1}>{key === 12 ? 12 : 11}</div>
          <div className={styles.number2}>
            <span>{signContent(String(tabKey))}</span>
            <span>1</span>
          </div>
          <div className={styles.answer}>
            <hr />
            <Form colon={false} autoComplete="off" form={form}>
              <CustomInput value={''} inputType="answer" name="answer" readOnly />
            </Form>
          </div>
        </div>
        <div className={styles.textContainer}>{getTitle(+tabKey, key === 12 ? 12 : 11, tens)}</div>
      </div>

      <div
        className={cs(
          { [styles.disabledView]: key !== 13 && key !== 14 },
          { [styles.activeContainer]: key === key },
          { [styles.detailContainer]: key !== 13 && key !== 14 },
        )}
        key={key === 14 ? 14 : 13}
        onClick={() => {
          questionHandleClick(key);
        }}
      >
        <div className={styles.numberContainer}>
          <div className={styles.number1}>{key === 14 ? 14 : 13}</div>
          <div className={styles.number2}>
            <span>{signContent(String(tabKey))}</span>
            <span>1</span>
          </div>
          <div className={styles.answer}>
            <hr />
            <Form colon={false} autoComplete="off" form={form}>
              <CustomInput value={''} inputType="answer" name="answer" readOnly />
            </Form>
          </div>
        </div>
        <div className={styles.textContainer}>{getTitle(+tabKey, key === 14 ? 14 : 13, tens)}</div>
      </div>

      <div
        className={cs(
          {
            [styles.disabledView]: key !== 15 && key !== 16 && key !== 17 && key !== 18 && key !== 19 && key !== 20,
          },
          { [styles.activeContainer]: key === key },
          {
            [styles.detailContainer]: key !== 15 && key !== 16 && key !== 17 && key !== 18 && key !== 19 && key !== 20,
          },
        )}
        key={key === 20 ? 20 : key === 19 ? 19 : key === 18 ? 18 : key === 17 ? 17 : key === 16 ? 16 : 15}
        onClick={() => {
          questionHandleClick(key);
        }}
      >
        <div className={styles.numberContainer}>
          <div className={styles.number1}>
            {key === 20 ? 20 : key === 19 ? 19 : key === 18 ? 18 : key === 17 ? 17 : key === 16 ? 16 : 15}
          </div>
          <div className={styles.number2}>
            <span>{signContent(String(tabKey))}</span>
            <span>1</span>
          </div>
          <div className={styles.answer}>
            <hr />
            <Form colon={false} autoComplete="off" form={form}>
              <CustomInput value={''} inputType="answer" name="answer" readOnly />
            </Form>
          </div>
        </div>
        <div className={styles.textContainer}>
          {getTitle(
            +tabKey,
            key === 20 ? 20 : key === 19 ? 19 : key === 18 ? 18 : key === 17 ? 17 : key === 16 ? 16 : 15,
            tens,
          )}
        </div>
      </div> */}
    </>
  );
};

export default SubtractionActivities;
