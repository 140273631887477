import React, { useEffect, useState } from 'react';
import styles from './Pricing.module.scss';
import CustomButton from '../../components/Button/Button';
import CheckoutModal from './PaymentModals/CheckoutModal';
import { isLoggedIn } from '../../helpers/utility';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { loginUserData } from '../../store/hooks';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIP_KEY);

const PriceContainer = ({ priceData, heading, selectPlan, setSelectPlan, subscribeType ,state}) => {
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(isLoggedIn());
  const { user, freeTrial } = useSelector((state) => state.auth);
  useEffect(() => {
    setIsLogin(isLoggedIn());
  }, [user]);
  const [stages, setStages] = useState(1);
  const [modalVisible, setModalVisible] = useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [loading, setLoading] = useState();
  const [priceValue, setPriceValue] = useState(false);
  const [planId, setPlanId] = useState('');

  const renderModal = (stage) => {
    switch (stage) {
      case 1:
        return modalVisible;
      case 2:
        return isConfirmModal;
      case 3:
        return isPaymentSuccess;
      default:
        break;
    }
  };

  const [activeButton1, setActiveButton1] = useState(false);

  const callBack = (item) => {
    isLogin && loginUserData() ? setModalVisible(true) : navigate('/login');
    setPriceValue(item.amount + '/' + item.interval);
    setStages(1);
    setActiveButton1(item.id);
    setPlanId(item.id);
  };
  useEffect(()=>{
    if(state){
    setSelectPlan(() => ({ item: state, callBack, subscribeType }));
  }
  },[])


  return (
    <>
      <div className={styles.priceContainer}>
        <div className={styles.priceHeading}>{heading}</div>
        {priceData.map((item) => (
          <div
            className={`${styles.buttons} ${
              (item?.id === selectPlan?.item?.id || item?.id === selectPlan?.plan?.id) && styles.activeButton1
            }`}
          >
            <CustomButton
              disabled={freeTrial && item?.interval === 'week'}
              onClick={() => {
                setSelectPlan({ item: item, callBack: callBack, subscribeType: subscribeType });
              }}
              htmlType="submit"
            >
              <span>{item?.interval === 'week' ? '1 week free trial' : '$ ' + item.amount / 100}</span>
            {item.interval !== 'week' &&  <span style={{ fontFamily: 'Poppins-Extra-Light', fontWeight: '100px' }}>/{item.interval}</span>}
            </CustomButton>
          </div>
        ))}
      </div>
      {renderModal(stages) && (
        <Elements stripe={stripePromise}>
          <CheckoutModal
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            isConfirmModal={isConfirmModal}
            setIsConfirmModal={setIsConfirmModal}
            isPaymentSuccess={isPaymentSuccess}
            setIsPaymentSuccess={setIsPaymentSuccess}
            heading={heading}
            price={priceValue}
            loading={loading}
            setLoading={setLoading}
            stages={stages}
            setStages={setStages}
            setActiveButton1={setActiveButton1}
            planId={planId}
            selectPlan={selectPlan}
            setSelectPlan={setSelectPlan}
          />
        </Elements>
      )}
    </>
  );
};

export default PriceContainer;
