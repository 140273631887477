import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Styles
import styles from './HeaderLayout.module.scss';
import homeStyles from './HomeHeaderLayout.module.scss';

// Components
import CustomButton from '../../../components/Button/Button';
import Loader from '../../../components/Loader/Loader';

// Actions
import { attemptSetSelectedUserType } from '../../../store/actions/authActions';

// Hooks
import { loginUserData, useReducerData } from '../../../store/hooks';

// Helper Functions
import { isLoggedIn } from '../../../helpers/utility';
import { attemptSetTabPath } from '../../../store/actions/quizActions';


const HeadingText = ({ MainText, SubText, buttontxt, secondbtntxt, homePath, settingPath, profilePath }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const useStyles = location.pathname === '/' ? homeStyles : styles;
  const profileUserData = useReducerData('auth', 'profileUserData');
  const loading = useReducerData('auth', 'loading');
  const currentUser = useReducerData('auth', 'currentUser');
  const handleProfile = (userType) => {
    dispatch(attemptSetSelectedUserType(userType));
    navigate(`/profile/edit/new-profile`);
  };
  const userData = useSelector((state) => state.auth);
  const { user_subscription, user } = userData;
  const [isLogin, setIsLogin] = useState(isLoggedIn());
  useEffect(() => {
    setIsLogin(isLoggedIn());
  }, [user]);
  const handleClick = () => {
    // navigate('/signup');
    if (isLogin && loginUserData()) {
      dispatch(attemptSetTabPath('/pricing'));
      navigate('/pricing');
    } else {
      navigate('/signup');
    }
  };

  return (
    <div>
      <div className={useStyles.headingText}>
        {MainText}
        <div className={useStyles.headingSmallText}>{SubText}</div>
        {homePath && (
          <>
            <div className={useStyles.buttonClass}>
              <CustomButton
                onClick={() => {
                  if (isLogin && loginUserData()) {
                    navigate('/pdf');
                  } else {
                    navigate('/signup');
                  }
                }}
              >
                {buttontxt}
              </CustomButton>
            </div>
            {user?.user_type === 'Children' || user?.user_type === 'Student' ? null : (
              <div className={useStyles.becomeButton}>
                <CustomButton onClick={handleClick}>
                  {' '}
                  {isLogin && loginUserData() && user_subscription?.length ? 'Change plan' : 'Become a member'}{' '}
                </CustomButton>
              </div>
            )}
          </>
        )}
        {settingPath && (
          <>
            <div className={styles.buttonClass}>
              <CustomButton
                onClick={() => {
                  navigate(`/profile/edit/${loginUserData()?.id}`);
                  dispatch(attemptSetSelectedUserType(''));
                }}
              >
                {buttontxt}
              </CustomButton>
            </div>
            {/* <div className={settingPath ? styles.activeButtonClass : styles.buttonClass}>
              <CustomButton type="primary" onClick={() => navigate('/settings')}>
                {secondbtntxt}
              </CustomButton>
            </div> */}
          </>
        )}
        {profilePath && user?.user_type !== 'Children' && user?.user_type !== 'Student' && (
          <>
            <div className={styles.buttonClass}>
              <CustomButton
                onClick={() => {
                  navigate(`/profile/edit/${loginUserData()?.id}`);
                  dispatch(attemptSetSelectedUserType(''));
                }}
              >
                {buttontxt}
              </CustomButton>
            </div>
            {user?.user_type !== 'Admin' && (
              <div className={styles.profileButtonClass}>
                {loading ? (
                  <Loader />
                ) : (
                  <>
                    {profileUserData.map((user) => {
                      return (
                        <div className={styles.addButtonClass}>
                          <CustomButton
                            type="primary"
                            onClick={() => {
                              navigate(`/profile/edit/${user.user.id}`);
                              dispatch(
                                attemptSetSelectedUserType(user?.user?.user_type === 'Student' ? 'student' : 'child'),
                              );
                            }}
                          >
                            {user?.first_name.charAt(0).toUpperCase()}
                          </CustomButton>
                          {user?.first_name}
                        </div>
                      );
                    })}

                    {!['Student', 'Children'].includes(currentUser?.user_type) && (
                      <>
                        {user_subscription?.[0]?.plan?.nickname === 'Parents' && profileUserData?.length < 1 ? (
                          <div className={styles.addButtonClass}>
                            <CustomButton
                              onClick={() => {
                                handleProfile('child');
                              }}
                            >
                              +
                            </CustomButton>
                            Add Child
                          </div>
                        ) : (
                          user_subscription?.[0]?.plan?.nickname === 'Teachers' &&
                          profileUserData?.length < 10 && (
                            <div className={styles.addButtonClass}>
                              <CustomButton
                                onClick={() => {
                                  handleProfile('student');
                                }}
                              >
                                +
                              </CustomButton>
                              Add Student
                            </div>
                          )
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default HeadingText;
