import React from 'react';
import styles from '../Checkout.module.scss';
import { ReactComponent as VisaCard } from '../../../../assets/images/visa card.svg';
import { ReactComponent as AmericanExpressCard } from '../../../../assets/images/american express card.svg';

const SecureModal = () => {
  return (
    <div className={styles.secureModal}>
      <div className={styles.textFirst}>
        <div className={styles.visaText}>
          For <span>Visa, MasterCard, JCB, Discover</span> the 3 digits on the back of your card
        </div>
        <div className={styles.cardImg}>
          <VisaCard />
        </div>
      </div>

      <div className={styles.textFirst}>
        <div className={styles.visaText}>
          For <span>American Express</span> the 4 digits <br /> on the back of your card
        </div>
        <div className={styles.cardImg}>
          <AmericanExpressCard />
        </div>
      </div>
    </div>
  );
};

export default SecureModal;
