import React from 'react';
import styles from '../forgotPassword/forgotPassword.module.scss';
import { useNavigate } from 'react-router';
import CustomButton from '../../../components/Button/Button';

const SuccessMessage = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.formInput}>
      <div className={styles.passwordchange}>
        <p className={styles.messclass}>You can now use your new password to login to your account</p>
        <CustomButton
          type="primary"
          onClick={() => {
            navigate('/login');
          }}
        >
          Log in
        </CustomButton>
      </div>
    </div>
  );
};

export default SuccessMessage;
