import Alien1 from '../../assets/images/HomePage/Programs/DirectionChart/alien1.svg';
import Alien2 from '../../assets/images/HomePage/Programs/DirectionChart/alien2.svg';
import Alien3 from '../../assets/images/HomePage/Programs/DirectionChart/alien3.svg';
import Alien4 from '../../assets/images/HomePage/Programs/DirectionChart/alien4.svg';
import { ReactComponent as Blue } from '../../assets/images/HomePage/Programs/DirectionChart/Blue.svg';
import { ReactComponent as Green } from '../../assets/images/HomePage/Programs/DirectionChart/Green.svg';
import { ReactComponent as Red } from '../../assets/images/HomePage/Programs/DirectionChart/Red.svg';
import { ReactComponent as Yellow } from '../../assets/images/HomePage/Programs/DirectionChart/Yellow.svg';
import { ReactComponent as ArrowDown } from '../../assets/images/HomePage/Programs/DirectionChart/ArrowDown.svg';
import { ReactComponent as ArrowLeft } from '../../assets/images/HomePage/Programs/DirectionChart/ArrowLeft.svg';
import { ReactComponent as ArrowTop } from '../../assets/images/HomePage/Programs/DirectionChart/ArrowTop.svg';
import { ReactComponent as ArrowRight } from '../../assets/images/HomePage/Programs/DirectionChart/ArrowRight.svg';
import DotImage0 from '../../assets/images/HomePage/Programs/DotNumber/dot0.svg';
import DotImage1 from '../../assets/images/HomePage/Programs/DotNumber/dot1-svg.svg';
import DotImage2 from '../../assets/images/HomePage/Programs/DotNumber/dot2-svg.svg';
import DotImage3 from '../../assets/images/HomePage/Programs/DotNumber/dot3-svg.svg';
import DotImage4 from '../../assets/images/HomePage/Programs/DotNumber/dot4-svg.svg';
import DotImage5 from '../../assets/images/HomePage/Programs/DotNumber/dot5-svg.svg';
import DotImage6 from '../../assets/images/HomePage/Programs/DotNumber/dot6-svg.svg';
import DotImage7 from '../../assets/images/HomePage/Programs/DotNumber/dot7-svg.svg';
import DotImage8 from '../../assets/images/HomePage/Programs/DotNumber/dot8-svg.svg';
import DotImage9 from '../../assets/images/HomePage/Programs/DotNumber/dot9-svg.svg';
import { getRandomArbitrary } from '../../helpers/utility';

const dotImages = [
  { count: 0, image: DotImage0 },
  { count: 1, image: DotImage1 },
  { count: 2, image: DotImage2 },
  { count: 3, image: DotImage3 },
  { count: 4, image: DotImage4 },
  { count: 5, image: DotImage5 },
  { count: 6, image: DotImage6 },
  { count: 7, image: DotImage7 },
  { count: 8, image: DotImage8 },
  { count: 9, image: DotImage9 },
];

export const generateDotQuestions = () => {
  return Array(6)
    .fill(1)
    .map((value, index, array) => {
      const type = index <= 2 ? 0 : 1;
      const s = getRandomArbitrary(0, 9);
      const m = getRandomArbitrary(0, 9);
      const answer = !type ? dotImages[s]?.count : dotImages[s]?.count + dotImages[m]?.count;
      return {
        question: type,
        single: dotImages[s],
        multi: dotImages[m],
        answer,
      };
    });
};

export const directionChart = [
  {
    key: 1,
    image: Alien1, //blue
  },
  {
    key: 2,
    image: Alien2, //red
  },
  {
    key: 3,
    image: Alien3, //yellow
  },
  {
    key: 4,
    image: Alien4, //green
  },
];

const alienImage = [
  [
    { key: 1, image: Alien1 },
    { key: 2, image: Alien2 },
    { key: 3, image: Alien3 },
    { key: 4, image: Alien4 },
    { key: 1, image: Alien1 },
    { key: 2, image: Alien2 },
    { key: 3, image: Alien3 },
    { key: 4, image: Alien4 },
    { key: 1, image: Alien1 },
  ],
  [
    { key: 3, image: Alien3 },
    { key: 1, image: Alien1 },
    { key: 2, image: Alien2 },
    { key: 1, image: Alien1 },
    { key: 4, image: Alien4 },
    { key: 3, image: Alien3 },
    { key: 2, image: Alien2 },
    { key: 1, image: Alien1 },
    { key: 2, image: Alien2 },
  ],
  [
    { key: 2, image: Alien2 },
    { key: 1, image: Alien1 },
    { key: 4, image: Alien4 },
    { key: 3, image: Alien3 },
    { key: 2, image: Alien2 },
    { key: 1, image: Alien1 },
    { key: 4, image: Alien4 },
    { key: 2, image: Alien2 },
    { key: 3, image: Alien3 },
  ],
  [
    { key: 4, image: Alien4 },
    { key: 3, image: Alien3 },
    { key: 2, image: Alien2 },
    { key: 4, image: Alien4 },
    { key: 3, image: Alien3 },
    { key: 2, image: Alien2 },
    { key: 1, image: Alien1 },
    { key: 3, image: Alien3 },
    { key: 4, image: Alien4 },
  ],
  [
    { key: 3, image: Alien3 },
    { key: 4, image: Alien4 },
    { key: 3, image: Alien3 },
    { key: 2, image: Alien2 },
    { key: 1, image: Alien1 },
    { key: 4, image: Alien4 },
    { key: 3, image: Alien3 },
    { key: 2, image: Alien2 },
    { key: 1, image: Alien1 },
  ],
];

// const renderAlienRows = () => {
//   let previousAlien = null;
//   return Array(5)
//     .fill(1)
//     .map((value, index, array) => ({
//       images: Array(9)
//         .fill(1)
//         .map((value, index, array) => {
//           let newAlien = getRandomArbitrary(0, 3);
//           while (newAlien === previousAlien) {
//             newAlien = getRandomArbitrary(0, 3);
//           }
//           previousAlien = newAlien;
//           return directionChart[newAlien];
//         }),
//     }));
// };

const renderAlienRows = () => {
  let previousAlienRow = null;
  return Array(5)
    .fill(1)
    .map((value, index, array) => {
      let newAlienRow = alienImage[getRandomArbitrary(0, 3)];
      while (newAlienRow === previousAlienRow) {
        newAlienRow = alienImage[getRandomArbitrary(0, 3)];
      }
      previousAlienRow = newAlienRow;
      return { images: previousAlienRow };
    });
};

export const directionChartAlienImage = renderAlienRows();

export const directionChartColorBtn = [
  [
    {
      key: 3,
      image: Yellow,
    },
    {
      key: 2,
      image: Red,
    },
  ],
  [
    {
      key: 4,
      image: Green,
    },
    {
      key: 1,
      image: Blue,
    },
  ],
];

export const directionChartDirectionBtn = [
  [
    {
      key: 3,
      image: ArrowTop,
    },
    {
      key: 2,
      image: ArrowRight,
    },
  ],
  [
    {
      key: 1,
      image: ArrowLeft,
    },
    {
      key: 4,
      image: ArrowDown,
    },
  ],
];

export const source = 'https://cogplay-6e31a.web.app/media/videobrain2.da13d776.mp4';
export const brainWorkout = 'https://eva-mazzei-cogplay-33731.s3.us-east-2.amazonaws.com/eva_video.mp4';
