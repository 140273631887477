import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Select, Tooltip, Table } from 'antd';
import { Modal } from 'antd';

// react-bootstrap components
import { Container, Row } from 'react-bootstrap';

// Components
import AntdDataSelect from '../../../components/AntdSelect';
import CustomButton from '../../../components/Button/Button';
import FilterButton from '../../../components/FilterButton';
import CustomTable from '../../../components/CustomTable';

// Helper Functions
import { getCountries, getCities, getStates } from '../../../helpers/utility';

// Actions
import { getAllSubscriptions } from '../../../store/actions/adminActions';

// Hooks
import { useReducerData } from '../../../store/hooks';

// Styles
import styles from './Subscriptions.module.scss';
import styled from 'styled-components';

function Subscriptions() {
  const dispatch = useDispatch();
  const loading = useReducerData('admin', 'loadingSubscriptions');
  const data = useReducerData('admin', 'subscriptions');
  const { Option } = Select;

  const [onloadState, setOnloadState] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [isdropdownOption, setdropdownOption] = useState(false);
  const [filterFormData, setFilterFormData] = useState({
    country: '',
    state: '',
    city: '',
    school_district: '',
  });
  const [isModalOpen, setIsModalOpen] = useState({ open: false, data: [] });

  useEffect(() => {
    const data = {
      country: '',
      state: '',
      city: '',
      district: '',
      page: 1,
    };
    dispatch(getAllSubscriptions(data));
    setOnloadState(true);
  }, []);

  const columns = [
    {
      title: 'Username ',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      title: 'Email ',
      dataIndex: 'email',
      key: 'email',
      width: 150,
      render: name => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      width: 150,
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      title: 'State/Province',
      dataIndex: 'state',
      key: 'state',
      width: 150,
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      width: 150,
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      title: 'School District',
      dataIndex: 'school_district',
      key: 'school_district',
      width: 150,
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      title: 'Plan Type',
      dataIndex: 'plan',
      key: 'plan',
      width: 150,
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      title: 'Plan Category',
      dataIndex: 'plan_category',
      key: 'plan_category',
      width: 150,
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      title: 'Plan For',
      dataIndex: 'plan_for',
      key: 'plan_for',
      width: 150,
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      title: 'Plan Date',
      dataIndex: 'plan_date',
      key: 'plan_date',
      width: 150,
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      title: 'Plan End Date',
      dataIndex: 'subscription_end_date',
      key: 'subscription_end_date',
      width: 150,
      render: name => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      title: 'User Feedback',
      width: 150,
      render: (_, data) => <div className={styles.feedback} onClick={() => setIsModalOpen({ open: true, data: data })}>View</div>,
    },
  ];

  const handleCancel = () => {
    setIsModalOpen({ open: false, data: [] });
  };


  const tableData = () => {
    const newData =
      data &&
      data?.results?.map((item, i) => ({
        key: i.toString(),
        name: item?.user?.name || '-',
        country: item?.user?.country || '-',
        state: item?.user?.state || '-',
        city: item?.user?.city || '-',
        school_district: item?.user?.school_district || '-',
        plan: item?.interval || '-',
        plan_category: item?.plan_category || '-',
        plan_for: item?.product_for || '-',
        plan_date: moment(item?.start_date).format('YYYY-MM-DD'),
        subscription_end_date: item?.subscription_end_date,
        childData: item?.child_data && {
          planFor: item?.product_for,
          data: item?.child_data?.[0].sub_account,
        },
        email: item?.user?.email,
        user_feedback: item?.user_feedback
      }));
    return newData;
  };

  const expandedRowRender = (record) => {
    const columns = [
      {
        title: record?.childData?.planFor === 'Teachers' ? 'Students Name' : 'Child Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Country',
        dataIndex: 'country',
        key: 'country',
      },
      {
        title: 'State/Province',
        dataIndex: 'state',
        key: 'state',
      },
      {
        title: 'City',
        dataIndex: 'city',
        key: 'city',
      },
      {
        title: 'School District',
        dataIndex: 'school_district',
        key: 'school_district',
      },
      {
        title: 'Disability',
        dataIndex: 'disability',
        key: 'disability',
      },
    ];
  
    const newData = record?.childData?.data?.map((item, index) => ({
      key: index?.toString(),
      name: item?.first_name || '-',
      country: item?.country || '-',
      state: item?.state || '-',
      city: item?.city || '-',
      school_district: item?.school_district || '-',
      disability: item?.disability || '-',
    }));

    return (
      <Table
        columns={columns}
        dataSource={newData}
        pagination={false}
        bordered={true}
        showHeader
        tableLayout="fixed"
        scroll={{
          x: 1000,
        }}
      />
    );
  };

  const handleSelection = (name, value, option) => {
    if (option) {
      const data = {
        name: name === 'country' || name === 'state' ? option?.children : value,
        value,
      };
      setFilterFormData((prevData) => {
        if (name === 'country') {
          return { ...prevData, [name]: data, state: null, city: null, school_district: null };
        } else if (name === 'state') {
          return { ...prevData, [name]: data, city: null, school_district: null };
        } else {
          return { ...prevData, [name]: data };
        }
      });
    }
  };

  const nextPagination = (e) => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setPageNumber(e);
    const data = {
      country: filterFormData.country ? filterFormData.country?.name : '',
      state: filterFormData.state ? filterFormData.state?.name : '',
      city: filterFormData.city ? filterFormData.city?.name : '',
      district: filterFormData.school_district ? filterFormData.school_district?.name : '',
      page: e,
    };
    dispatch(getAllSubscriptions(data));
  };

  const onFilterApply = () => {
    setPageNumber(1);
    setdropdownOption(false);
    const data = {
      country: filterFormData.country ? filterFormData.country?.name : '',
      state: filterFormData.state ? filterFormData.state?.name : '',
      city: filterFormData.city ? filterFormData.city?.name : '',
      district: filterFormData.school_district ? filterFormData.school_district?.name : '',
      page: 1,
    };
    dispatch(getAllSubscriptions(data));
  };

  const onClearFilter = () => {
    setPageNumber(1);
    setdropdownOption(false);
    setFilterFormData({
      country: '',
      state: '',
      city: '',
      school_district: '',
    });
    const data = {
      country: '',
      state: '',
      city: '',
      district: '',
      page: 1,
    };
    dispatch(getAllSubscriptions(data));
  };

  const countryOptionList = (list) =>
    list &&
    list?.map((items, index) => {
      return (
        <Option key={index} value={items.key}>
          {items.label}
        </Option>
      );
    });

  const checkFilter =
    filterFormData.country !== '' ||
    filterFormData.city !== '' ||
    filterFormData.state !== '' ||
    filterFormData.school_district !== '';

  const dropdownList = [
    <AntdDataSelect
      label="Filter By Country"
      optionFilterProp={'children'}
      options={countryOptionList(getCountries)}
      value={filterFormData.country ? filterFormData.country : null}
      onChangeHandeler={(e, option) => handleSelection('country', e, option)}
      variant={'dark'}
    />,
    <AntdDataSelect
      label="Filter By State/Province"
      optionFilterProp={'children'}
      options={countryOptionList(getStates(filterFormData?.country?.value))}
      value={filterFormData.state ? filterFormData.state : null}
      onChangeHandeler={(e, option) => handleSelection('state', e, option)}
      variant={'dark'}
    />,
    <AntdDataSelect
      label="Filter By City"
      optionFilterProp={'children'}
      options={countryOptionList(getCities(filterFormData?.country?.value, filterFormData?.state?.value))}
      value={filterFormData.city ? filterFormData.city : null}
      onChangeHandeler={(e, option) => handleSelection('city', e, option)}
      variant={'dark'}
    />,
    <AntdDataSelect
      label="Filter By District"
      optionFilterProp={'children'}
      options={countryOptionList(getCities(filterFormData?.country?.value, filterFormData?.state?.value))}
      value={filterFormData.school_district ? filterFormData.school_district : null}
      onChangeHandeler={(e, option) => handleSelection('school_district', e, option)}
      variant={'dark'}
    />,

    <div className="pt-2  mb-1">
      <CustomButton className={styles.btnStyle} disabled={!checkFilter} type="primary" onClick={() => onFilterApply()}>
        Apply
      </CustomButton>
    </div>,
    <div className="pb-2 mb-1">
      <CustomButton type="primary" disabled={!checkFilter} className={styles.btnStyle} onClick={() => onClearFilter()}>
        Clear Filter
      </CustomButton>
    </div>,
  ];

  return (
    <>
      <Container fluid>
        <Row>
          <div className={styles.main}>
            <FilterButton
              itemList={dropdownList}
              dropdownmenuClass=""
              class="fa fa-filter"
              textData="Filter"
              dropdownStyle={undefined}
              onClick={() => setdropdownOption(!isdropdownOption)}
              style={undefined}
              dropDownValue={isdropdownOption}
            />
          </div>
          <div onClick={() => setdropdownOption(false)}>
            <CustomTable
              columns={columns}
              data={tableData() || []}
              loading={loading}
              onloadState={onloadState}
              pageNumber={pageNumber}
              nextPagination={nextPagination}
              totalPages={data && data?.total_pages}
              expandedRowRender={expandedRowRender || null}
            />
          </div>
        </Row>
      </Container>
      <StyleContainer title='User Feedback' footer={false} open={isModalOpen.open} onOk={handleCancel} onCancel={handleCancel}>
        {isModalOpen?.data.user_feedback ? isModalOpen?.data.user_feedback?.map(feedbackItem => (
          <div key={feedbackItem?.id}>
            <p className={styles.questionStyle}>{feedbackItem?.title}</p>
            <p>{feedbackItem?.text}</p>
          </div>
        )): 'No feedback '}
      </StyleContainer>
    </>
  );
}

export default Subscriptions;


const StyleContainer = styled(Modal)`
.ant-modal-title{
  font-size: 22px;
  font-weight: 700;
}
`