import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Modal, Tabs, Skeleton, Tooltip, Table, Pagination } from 'antd';

// react-bootstrap components
import { Container, Row, Card, Col } from 'react-bootstrap';

// Components
import CustomInput from '../../../components/Input';

// Helper Functions
import { convertMinutesSeconds } from '../../../helpers/utility';
import { getChildActivities } from '../../../store/actions/adminActions';

// Hooks
import { useReducerData } from '../../../store/hooks';

// Styles
import styles from './Student.module.scss';
import styled from 'styled-components';


function StudentDailyUse() {
  const dispatch = useDispatch();
  const loading = useReducerData('admin', 'loadingActivity');
  const user = useReducerData('auth', 'user');

  const data = useReducerData('admin', 'childActivity');
  const [selectedItem, setSelectedItem] = useState('Select Activity');
  const [onloadState, setOnloadState] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [studentName, setStudentName] = useState('');

  useEffect(() => {
    const data = {
      search: '',
      filter: '',
      page: 1,
    };
    dispatch(getChildActivities(data));
    setOnloadState(true);
  }, []);

  const handleMenuClick = (item) => {
    setPageNumber(1);
    setSelectedItem(item.key === 'Reset' ? 'Select Activity' : item.key);
    const data = {
      search: studentName ? studentName : '',
      filter: item.key === 'Reset' ? '' : item.key,
      page: 1,
    };
    dispatch(getChildActivities(data));
  };

  const checkUserType = user.user_type === 'Parent' ? 'Child' : 'Student';

  //handle audio
  const [audio, setAudio] = useState(new Audio());
  const [currentAudioSrc, setCurrentAudioSrc] = useState('');
  const [playing, setPlaying] = useState(false);

  const handlePlay = (audioSrc) => {
    if (playing && currentAudioSrc === audioSrc) {
      if (!audio.paused) {
        audio.pause();
        setPlaying(false);
      }
    } else {
      audio.src = audioSrc;
      audio.play();
      setPlaying(true);
      setCurrentAudioSrc(audioSrc);
    }
  };

  const handleAudioEnd = () => {
    setPlaying(false);
  };

  useEffect(() => {
    audio.addEventListener('ended', handleAudioEnd);
    return () => {
      audio.removeEventListener('ended', handleAudioEnd);
      audio.pause();
      audio.src = '';
    };
  }, [audio]);

  const splitData = (value) => {
    const splitValue = value?.split('&');
    const data = {
      audioUrl: splitValue?.[0],
      percentage: splitValue?.[1],
    };
    return data;
  };

  const splitVideoData = (value) => {
    const splitValue = value?.split('$');

    const data = {
      file: splitValue.length === 2 ? splitValue?.[0] : false,
      percentage: splitValue.length === 2 ? splitValue?.[1] : splitValue?.[0],
    };
    return data;
  }; 

  const columns = [
    {
      title: checkUserType,
      dataIndex: 'name',
      key: 'name',
      width: 150,
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      title: 'Mastered ',
      dataIndex: 'mastered',
      width: 200,
      key: 'mastered',
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      title: 'Current #',
      dataIndex: 'current_number',
      key: 'current_number',
      width: 200,
      render: name => <Tooltip title={name}>{name}</Tooltip>
    },
    {
      title: '1st Quiz time and %',
      dataIndex: 'first_quiz',
      key: 'first_quiz',
      width: 150,
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      title: 'Speed Number Counting and %',
      dataIndex: 'speed_counting',
      key: 'speed_counting',
      width: 150,
      render: (name) =>
        name && (
          <Tooltip title={splitVideoData(name)?.file ? 'audio' : name}>
            {name !== '-' ? (
              <div>
                {splitVideoData(name) && splitVideoData(name)?.file ? (
                  <>
                    <Button onClick={() => handlePlay(splitVideoData(name)?.file)}>
                      {playing && currentAudioSrc === splitVideoData(name)?.file ? 'Stop' : 'Play'}
                    </Button>
                    <br />
                  </>
                ) : null}

                {` ${splitVideoData(name)?.percentage}`}
              </div>
            ) : (
              name
            )}
          </Tooltip>
        ),
    },
    {
      title: 'Fig 8 time and %',
      dataIndex: 'figure',
      key: 'figure',
      width: 200,
      render: (name) =>
        name && (
          <Tooltip title={splitVideoData(name)?.file ? 'Video' : name}>
            {name !== '-' ? (
              <div>
                {splitVideoData(name) && splitVideoData(name)?.file ? (
                  <>
                    {' '}
                    <Button onClick={() => window.open(`${splitVideoData(name)?.file}`, '_blank')}>
                      {'View'}
                    </Button>{' '}
                    <br />
                  </>
                ) : null}

                {` ${splitVideoData(name)?.percentage}`}
              </div>
            ) : (
              name
            )}
          </Tooltip>
        ),
    },
    {
      title: 'Direction Chart time and %',
      dataIndex: 'direction_chart',
      key: 'direction_chart',
      width: 150,
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      title: 'Color coded memory card time and %',
      dataIndex: 'color_coded',
      key: 'color_coded',
      width: 150,
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      title: 'Number family time and %',
      dataIndex: 'number_family',
      key: 'number_family',
      width: 150,
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      title: 'Final Quiz time and %',
      dataIndex: 'final_quiz',
      key: 'final_quiz',
      width: 200,
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
  ];

  const tableData = () => {
    const newData =
      data &&
      data?.results?.map((item) => ({
        name: item.child_name,
        mastered: item?.mastered_number ? item?.mastered_number : 'None',
        current_number: item?.current ? item?.current : 'None',
        first_quiz: item?.Activities?.time
          ? convertMinutesSeconds(item?.Activities?.time) +
            `${
              item?.Activities?.percentage && item?.Activities?.percentage ? ' & ' + item?.Activities?.percentage : ''
            }`
          : '-',
        speed_counting:
          !item?.Speed_Number_Counting && !convertMinutesSeconds(item?.Speed_Number_Counting_Percentage?.time)
            ? '-'
            : (item?.Speed_Number_Counting ? item?.Speed_Number_Counting + ' $ ' : '') +
              convertMinutesSeconds(item?.Speed_Number_Counting_Percentage?.time) +
              `${
                item?.Speed_Number_Counting_Percentage?.percentage
                  ? ' & ' + item?.Speed_Number_Counting_Percentage?.percentage
                  : ''
              }`,
        figure:
          !item?.Figure_8_activity_Video && !convertMinutesSeconds(item?.Figure_8_activity_Percentage?.time)
            ? '-'
            : (item?.Figure_8_activity_Video ? item?.Figure_8_activity_Video + ' $ ' : '') +
              convertMinutesSeconds(item?.Figure_8_activity_Percentage?.time) +
              `${
                item?.Figure_8_activity_Percentage?.percentage
                  ? ' & ' + item?.Figure_8_activity_Percentage?.percentage
                  : ''
              }`,
        direction_chart: convertMinutesSeconds(item?.Direction_Charts_Percentage?.time)
          ? convertMinutesSeconds(item?.Direction_Charts_Percentage?.time) +
            `${
              item?.Direction_Charts_Percentage?.percentage ? ' & ' + item?.Direction_Charts_Percentage?.percentage : ''
            }`
          : '-',
        color_coded: convertMinutesSeconds(item?.Colour_coded_memory_cards_Percentage?.time)
          ? convertMinutesSeconds(item?.Colour_coded_memory_cards_Percentage?.time) +
            `${
              item?.Colour_coded_memory_cards_Percentage?.percentage
                ? ' & ' + item?.Colour_coded_memory_cards_Percentage?.percentage
                : ''
            }`
          : '-',
        number_family: convertMinutesSeconds(item?.Number_Families_Percentage?.time)
          ? convertMinutesSeconds(item?.Number_Families_Percentage?.time) +
            `${
              item?.Number_Families_Percentage?.percentage ? ' & ' + item?.Number_Families_Percentage?.percentage : ''
            }`
          : '-',
        final_quiz: item?.Final_Quiz
          ? convertMinutesSeconds(item?.Final_Quiz?.time && item?.Final_Quiz?.time) +
            `${item?.Final_Quiz?.percentage ? ' & ' + item?.Final_Quiz?.percentage : ''}`
          : '-',
      }));
    return newData;
  };

  const nextPagination = (e) => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setPageNumber(e);
    const data = {
      search: studentName,
      filter: selectedItem === 'Select Activity' ? '' : selectedItem,
      page: e,
    };
    dispatch(getChildActivities(data));
  };

  const onChangeHandle = debounce((e) => {
    setStudentName(e.target.value);
    const data = {
      search: e.target.value,
      filter: selectedItem === 'Select Activity' ? '' : selectedItem,
      page: 1,
    };
    dispatch(getChildActivities(data));
  }, 1000);

  const menu = (
    <Menu onClick={handleMenuClick} selectedKeys={[selectedItem]}>
      <Menu.Item key="Addition">Addition</Menu.Item>
      <Menu.Item key="Subtraction">Subtraction</Menu.Item>
      <Menu.Item key="Multiplication">Multiplication</Menu.Item>
      <Menu.Item key="Division">Division</Menu.Item>
      <Menu.Item key="Reset" disabled={selectedItem === 'Select Activity' ? true : false}>
        Reset
      </Menu.Item>
    </Menu>
  );

  const CustomTable = ({ totalPages }) => (
    <>
      <TableStyled
        dataSource={tableData()}
        rowKey={(element) => element.id}
        bordered={true}
        showHeader
        tableLayout="fixed"
        scroll={{
          x: 1000,
        }}
        pagination={false}
        loading={loading}
        columns={columns}
      />
      <div className="d-flex justify-content-end mb-3 mr-2 mt-1">
        {onloadState && (
          <Pagination
            defaultCurrent={pageNumber}
            current={pageNumber}
            showSizeChanger={false}
            total={totalPages ? totalPages * 10 : 1}
            onChange={nextPagination}
          />
        )}
      </div>
    </>
  );

  return (
    <>
      <Container fluid>
        <Row>
          <div className={styles.main}>
            <div className={styles.searchMain}>
              <CustomInput
                label={''}
                inputType="text"
                name="search"
                className={styles.search}
                value={studentName}
                onChange={onChangeHandle}
                placeholder={`Search by ${checkUserType?.toLowerCase()} name`}
                searchField
                // allowClear
              />
            </div>

            <div className={styles.sub_main}>
              <div className={styles.filterStyle}>
                <span>Filter by: </span>
              </div>
              <div className="d-flex align-self-end">
                <Dropdown overlay={menu} className={styles.dropdownStyle} arrow>
                  <Button className={styles.filterButton}>
                    {selectedItem} <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
            </div>
          </div>

          <CustomTable data={tableData()} totalPages={data && data?.total_pages} />
        </Row>
      </Container>
    </>
  );
}

export default StudentDailyUse;

const TableStyled = styled(Table)`
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 15px;
  .ant-table-content {
    scrollbar-color: darkgray lightgray !important;
    scrollbar-width: thin;
  }
  .ant-table-content::-webkit-scrollbar {
    height: 5px !important;
    width: 5px !important;
  }

  /* Track */
  .ant-table-content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  .ant-table-content::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 10px;
  }

  &.ant-table-wrapper {
    margin-bottom: 15px;
  }
  .ant-table-header {
    background-color: #4c4c4c !important;
  }
  table {
    border: collapse;
    min-width: 100% !important;
    border: 1px solid #4c4c4c14;
    td {
      font-size: 16px;
      color: #474862;
      @media (max-width: 1024px) {
        font-size: 14px !important;
      }
    }
    thead {
      outline: none !important;
      th {
        font-size: 16px;
        background-color: #000;
        text-align: center;
        @media (max-width: 1024px) {
          font-size: 14px !important;
        }
      }
    }
    thead > tr:first-child th:first-child {
      background-color: #000 !important;
    }
    .ant-table-cell-scrollbar {
      display: none;
    }
    td.ant-table-cell:last-child {
      white-space: break-spaces;
      display: table-cell;
    }
    thead > tr:first-child {
      color: white !important;
      background-color: #4c4c4c;
      text-align: left;
      border: none;
      th:first-child {
        background-color: #4c4c4c;
        color: white;
        border: none;
      }
    }
    th {
      background-color: transparent;
      color: white;
      border: none;
    }
  }
  .ant-table-filter-column {
    display: flex;
    justify-content: space-between;
  }
  ul {
    display: flex;
  }
  li {
    list-style: none;
  }
  .ant-pagination-item-link {
    margin-top: 0px;
  }
  .antd-table th,
  td {
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    justify-content: center;
    display: table-cell;
  }
  .ant-table-placeholder {
    display: inherit;
  }
`;
