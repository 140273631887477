import createTypes from 'redux-create-action-types';

export default createTypes(
  'LOGIN_INPROGRESS',
  'LOGIN_SUCCESS',
  'LOGIN_FAILURE',
  'LOGOUT_SUCCESS',

  'DELETE_ACCOUNT_SUCCESS',

  'SIGNUP_INPROGRESS',
  'SIGNUP_SUCCESS',
  'SIGNUP_FAILURE',
  'USER_SUBSCRIPTION',

  'RESET_PASSWORD_INPROGRESS',
  'RESET_PASSWORD_SUCCESS',
  'RESET_PASSWORD_FAILURE',

  'VERIFY_PASSWORD_INPROGRESS',
  'VERIFY_PASSWORD_SUCCESS',
  'VERIFY_PASSWORD_FAILURE',

  'ATTEMPT_CHANGE_PASSWORD',
  'SUCCESS_CHANGE_PASSWORD',
  'FAILED_CHANGE_PASSWORD',

  'ATTEMPT_SEND_FEEDBACK',
  'SUCCESS_SEND_FEEDBACK',
  'FAILED_SEND_FEEDBACK',

  'ATTEMPT_USER_FEEDBACK',
  'SUCCESS_USER_FEEDBACK',
  'FAILED_USER_FEEDBACK',

  'ATTEMPT_GET_USER_PROFILE',
  'SUCCESS_GET_USER_PROFILE',
  'FAILURE_GET_USER_PROFILE',

  'GET_CURRENT_USER_PROFILE_ATTEMPT',
  'GET_CURRENT_USER_PROFILE_SUCCESS',
  'GET_CURRENT_USER_PROFILE_FAILURE',

  'EDIT_CURRENT_PROFILE_IN_PROGRESS',
  'EDIT_CURRENT_PROFILE_SUCCESS',
  'EDIT_CURRENT_PROFILE_FAILURE',

  'CREATE_SUB_PROFILE_IN_PROGRESS',
  'CREATE_SUB_PROFILE_SUCCESS',
  'CREATE_SUB_PROFILE_FAILED',

  'EDIT_SUB_PROFILE_IN_PROGRESS',
  'EDIT_SUB_PROFILE_SUCCESS',
  'EDIT_SUB_PROFILE_FAILURE',

  'SET_SELECTED_USER_TYPE',
  'FORGOT_PASSWORD_OTP',
  'GET_USER_DOCUMENTS',
  'GET_USER_DOCUMENTS_SUCCESS',

  'UPDATE_CURRENT_USER_PROFILE_ATTEMPT',
  'UPDATE_CURRENT_USER_PROFILE_SUCCESS',
);
