import React, { useRef, useState } from 'react';
import styles from './ColourCoded.module.scss';
import QuizHeader from '../QuizLayout/QuizHeader/QuizHeader';
import QuizFooterHandleTimer from '../QuizLayout/QuizFooter/QuizFooterHandleTimer';
import RedCartoon from '../../../../src/assets/images/HomePage/Programs/MemoryCard/redCartoon.png';
import RedArrowImage from '../../../assets/images/HomePage/Programs/MemoryCard/redArrowImage.png';
import BlueArrowImage from '../../../assets/images/HomePage/Programs/MemoryCard/blueArrowImage.png';
import BlueCartoon from '../../../../src/assets/images/HomePage/Programs/MemoryCard/blueCartoon.png';
import {
  colourCodedAnswer,
  setDuration,
  signContent,
  titleContent,
  updateQuizPayload,
  getCurrentRoute,
  generateNumbersArray,
} from '../../../helpers/utility';
import { Form } from 'antd';
import { colourCodedData, colourCodedDataMultiplication, divisionColourCodedData } from './ColourCodedData';
import ResultModal from '../ResultModal/ResultModal';
import { attemptSetQuiz } from '../../../store/actions/quizActions';
import { useReducerData } from '../../../store/hooks';
import { useDispatch } from 'react-redux';
import CustomInputNumber from '../../../components/InputNumberBox';
import { useNavigate } from 'react-router-dom';

const ColourCoded = () => {
  const navigate = useNavigate();
  const quizData = useReducerData('quiz', 'quizData');
  const tabKey = useReducerData('quiz', 'tabKey');
  const currentQuizKey = useReducerData('quiz', 'currentQuizKey');
  const { key, subKey } = currentQuizKey[+tabKey];
  const [form] = Form.useForm();
  const questionRef = useRef(null);
  const dispatch = useDispatch();
  const [resultModalVisible, setResultModalVisible] = useState(false);
  const [isDisplayNext, setIsDisplayNext] = useState(false);
  const [isDoneWork, setIsDoneWork] = useState(false);

  const [correctAnswer, setCorrectAnswer] = useState(0);
  const [wrongAnswer, setWrongAnswer] = useState(0);
  const [wrongNumber, setWrongNumber] = useState(false);
  const [quizQuestionKey, setQuizQuestionKey] = useState(0);
  const totalTime = 15;

  const dataColourCoded =
    +tabKey === 2 ? colourCodedDataMultiplication : +tabKey === 3 ? generateNumbersArray : colourCodedData;

  const number1 = dataColourCoded(key)?.[quizQuestionKey]?.num1;
  const number2 = dataColourCoded(key)?.[quizQuestionKey]?.num2;

  const questionLimit =
    +tabKey === 2
      ? colourCodedDataMultiplication().length - 1
      : +tabKey === 3
      ? generateNumbersArray(key)?.length - 1
      : colourCodedData().length - 1;
  const [questionCounter, setQuestionCounter] = useState(0);
  const [answerData, setAnswerData] = useState({
    answer: '',
    sawAnswer: '',
  });

  const [isFinishQuestions, setIsFinishQuestions] = useState(false);
  const [isBlueCard, setIsBlueCard] = useState(false);
  const [isRedCard, setIsRedCard] = useState(false);
  const [wrongeData, setWrongeData] = useState(false);
  const checkNo1 = number2 < number1 ? number1 : number2;
  const checkNo2 = number1 > number2 ? number2 : number1;
  const [tempKey, setTempKey] = useState(0);
  const url = window.location.href;
  const currentData = quizData?.data?.[+tabKey]?.quiz[key]?.data;
  const handleChange = (e, type) => {
    const value = typeof e === 'number' ? e : e?.target?.value;
    if (value) {
      setAnswerData({
        ...answerData,
        [type]: value,
      });
      checkCorrectValue(value, type);
    }
  };
  const sign = ({ props }) => {
    const { children } = props;
    const signMatch = children.match(/[+\-x÷]/);
    return signMatch ? signMatch[0] : '';
  };

  const countNumbers = (number1, number2, operator) => {
    switch (operator) {
      case '+':
        return number1 + number2;
      case '-':
        return Math.abs(number1 - number2);
      case 'x':
        return number1 * number2;
      case '÷':
        const data = number2 === 0 ? 0 : number1 / number2;
        return data;
      default:
        return NaN;
    }
  };

  const checkCorrectValue = (value, type) => {
    const sin = sign(signContent(String(tabKey)));
    const numb1 = !isBlueCard
      ? type !== 'answer'
        ? number2
        : number1
      : +tabKey === 1 || +tabKey === 3
      ? number2
      : number1;
    const numb2 = !isBlueCard
      ? type !== 'answer'
        ? number1
        : number2
      : +tabKey === 1 || +tabKey === 3
      ? number1
      : number2;

    const count = countNumbers(numb1, numb2, sin);
    if (sin === '÷' ? Number(value) === Number(count) : parseInt(value) === count) {
      setWrongNumber(false);
    } else {
      setWrongNumber(true);
    }
  };

  const checkData = () => {
    if (+answerData.answer !== colourCodedAnswer(number1, number2, String(tabKey))) {
      if (tempKey === quizQuestionKey) {
        setQuestionCounter(0);
      } else {
        setQuestionCounter(1);
      }
    }
    if (tempKey === quizQuestionKey) {
      if (+answerData.answer !== colourCodedAnswer(number1, number2, String(tabKey))) {
        setQuestionCounter(questionCounter + 1);
      }
    }
  };

  const handleSawKeyDown = (e) => {
    const value = e?.target?.value;
    if (e?.key === 'Enter' && !wrongNumber && (answerData?.sawAnswer || value)) {
      setWrongNumber(false);
      setQuizQuestionKey(quizQuestionKey + 1);
      setAnswerData({
        sawAnswer: '',
        answer: '',
      });
    }
  };

  const handleRedKeyDown = (e) => {
    const value = e?.target?.value;
    if (e?.key === 'Enter' && !wrongNumber && (answerData?.sawAnswer || value)) {
      setWrongNumber(false);
      setIsRedCard(false);
      setQuizQuestionKey(quizQuestionKey);
      if (quizQuestionKey === 0) {
        setCorrectAnswer(0);
        setWrongeData(false);
      } else {
        setCorrectAnswer(correctAnswer + (correctAnswer && 1));
      }

      setAnswerData({
        sawAnswer: '',
        answer: '',
      });
    }
  };

  const countCorrectAnswer = (quizQuestionKey, wrongeData) => {
    if (quizQuestionKey === 0 || quizQuestionKey === 2 || quizQuestionKey === 4) {
      setCorrectAnswer(quizQuestionKey === 4 ? 1 : quizQuestionKey === 2 ? 0 : 0);
    } else {
      setCorrectAnswer(
        correctAnswer -
          (wrongeData === 5 || wrongeData === 3
            ? 1
            : wrongeData === 4
            ? 2
            : wrongeData === 2
            ? 3
            : wrongeData === 1
            ? 2
            : 0),
      );
    }
  };
  const handleWronge = async () => {
    const isCheck = +tabKey !== 1 || +tabKey !== 3;
    let checkInfinity =
      colourCodedAnswer(isCheck ? number2 : number1, isCheck ? number1 : number2, String(tabKey)) === Infinity
        ? 0
        : colourCodedAnswer(isCheck ? number2 : number1, isCheck ? number1 : number2, String(tabKey));
    if (+answerData.answer === checkInfinity) {
      if (tempKey === quizQuestionKey) {
        setTempKey(0);
        setQuestionCounter(0);
      }
    } else {
      setWrongAnswer(wrongAnswer + 1);
      setIsRedCard(true);
      quizQuestionKey >= 2 && setWrongeData(5);
      checkData();
    }

    if (quizQuestionKey !== questionLimit) {
      const checkAnswerCondition =
        +tabKey !== 1 && +tabKey !== 3
          ? colourCodedAnswer(number1, number2, String(tabKey))
          : colourCodedAnswer(checkNo1, checkNo2, String(tabKey)) === Infinity
          ? 0
          : colourCodedAnswer(checkNo1, checkNo2, String(tabKey));

      if (+answerData.answer === checkAnswerCondition) {
        if (tempKey === 0 || tempKey === quizQuestionKey) {
          setQuizQuestionKey(
            wrongeData === 5 ? quizQuestionKey - 2 : wrongeData === 3 ? quizQuestionKey - 4 : quizQuestionKey + 2,
          );
          if ((quizQuestionKey === 2 && wrongeData === 4) || (quizQuestionKey === 2 && wrongeData === 2)) {
            setWrongeData(wrongeData - 1);
          } else if (quizQuestionKey === 2) {
            setWrongeData(0);
          } else {
            setWrongeData(wrongeData - 1);
          }
        }
      } else {
        if (quizQuestionKey !== 1) {
          switch (questionCounter) {
            case 0:
              setTempKey(quizQuestionKey);
              setQuizQuestionKey(quizQuestionKey);
              countCorrectAnswer(quizQuestionKey, wrongeData);

              break;
            case 1:
              setTempKey(quizQuestionKey);
              setQuizQuestionKey(quizQuestionKey);
              countCorrectAnswer(quizQuestionKey, wrongeData);

              break;
            case 2:
              setTempKey(quizQuestionKey);
              setQuizQuestionKey(quizQuestionKey);
              countCorrectAnswer(quizQuestionKey, wrongeData);

              break;
            default:
              setTempKey(quizQuestionKey);
              setQuizQuestionKey(quizQuestionKey);
              countCorrectAnswer(quizQuestionKey, wrongeData);
              break;
          }
        } else {
          setQuizQuestionKey(quizQuestionKey);
        }
      }
    } else {
      if (wrongNumber) {
        setTempKey(quizQuestionKey);
        setQuizQuestionKey(quizQuestionKey);
        setCorrectAnswer(correctAnswer - 1);
      }

      if (quizQuestionKey === questionLimit && wrongeData === 1) {
        setIsFinishQuestions(true);
        const tempQuizData = updateQuizPayload(
          quizData.data,
          +tabKey,
          key,
          getCurrentRoute(url, currentData),
          setDuration(questionRef.current, totalTime),
          wrongAnswer,
          correctAnswer,
        );
        await dispatch(
          attemptSetQuiz({
            data: tempQuizData?.tempQuizData,
            activity: tempQuizData?.activity,
          }),
        );
        setResultModalVisible(true);
      } else {
        setIsBlueCard(true);
        setQuizQuestionKey(0);
        setTempKey(0);
        setWrongAnswer(0);
        setCorrectAnswer(0);
        setQuestionCounter(0);
      }
    }
    setAnswerData({
      sawAnswer: '',
      answer: '',
    });
    form.resetFields();
  };

  const handleKeyDown = async (e) => {
    if (e.key === 'Enter' && answerData?.answer && !isFinishQuestions) {
      // setWrongNumber(false);
      if (answerData.answer === '') {
        return;
      }

      let checkInfinity =
        colourCodedAnswer(number1, number2, String(tabKey)) === Infinity
          ? 0
          : colourCodedAnswer(number1, number2, String(tabKey));
      if (+answerData.answer === +checkInfinity) {
        setCorrectAnswer(correctAnswer + 1);
        if (tempKey === quizQuestionKey) {
          setTempKey(0);
          setQuestionCounter(0);
        }
      } else {
        setWrongAnswer(wrongAnswer + 1);
        checkData();
      }

      if (quizQuestionKey !== questionLimit) {
        const checkAnswerCondition =
          +tabKey !== 1 && +tabKey !== 3
            ? colourCodedAnswer(number1, number2, String(tabKey))
            : colourCodedAnswer(checkNo1, checkNo2, String(tabKey)) === Infinity
            ? 0
            : colourCodedAnswer(checkNo1, checkNo2, String(tabKey));

        if (+answerData.answer === +checkAnswerCondition) {
          if (tempKey === 0 || tempKey === quizQuestionKey) {
            setQuizQuestionKey(quizQuestionKey + 1);
          } else {
            setQuizQuestionKey(quizQuestionKey + 2);
          }
        } else {
          if (quizQuestionKey !== 1) {
            switch (questionCounter) {
              case 0:
                setTempKey(quizQuestionKey);
                setQuizQuestionKey(quizQuestionKey - 1);
                break;
              case 1:
                // if (quizQuestionKey !== 3) {
                setTempKey(quizQuestionKey);
                setQuizQuestionKey(quizQuestionKey - 1);
                // } else {
                //   setQuizQuestionKey(1);
                // }
                break;
              case 2:
                // if (quizQuestionKey !== 3 && quizQuestionKey !== 5) {
                setTempKey(quizQuestionKey);
                setQuizQuestionKey(quizQuestionKey - 1);
                // } else {
                //   setQuizQuestionKey(1);
                // }
                break;
              default:
                setTempKey(quizQuestionKey);
                setQuizQuestionKey(quizQuestionKey - 1);
                break;
            }
          } else {
            setQuizQuestionKey(quizQuestionKey - 1);
          }
        }
      } else {
        if (wrongNumber) {
          setTempKey(quizQuestionKey);
          setQuizQuestionKey(quizQuestionKey - 1);
        } else {
          setIsBlueCard(true);
          setQuizQuestionKey(0);
          setTempKey(0);
          setWrongAnswer(0);
          setCorrectAnswer(0);
          setQuestionCounter(0);
        }
      }
      setAnswerData({
        sawAnswer: '',
        answer: '',
      });
      form.resetFields();
    }
  };

  const handleBlueCard = async (e) => {
    if (e?.key === 'Enter' && answerData?.answer && !isFinishQuestions) {
      if (answerData.answer === '') {
        return;
      }

      const isCheck = +tabKey !== 1 || +tabKey !== 3;
      if (wrongeData) {
        handleWronge(true);
      } else {
        let checkInfinity =
          colourCodedAnswer(isCheck ? number2 : number1, isCheck ? number1 : number2, String(tabKey)) === Infinity
            ? 0
            : colourCodedAnswer(isCheck ? number2 : number1, isCheck ? number1 : number2, String(tabKey));

        if (+answerData.answer === checkInfinity) {
          setCorrectAnswer(correctAnswer + 1);
          if (tempKey === quizQuestionKey) {
            setTempKey(0);
            setQuestionCounter(0);
          }
        } else {
          setWrongAnswer(wrongAnswer + 1);
          setIsRedCard(true);
          checkData();
          quizQuestionKey >= 2 && setWrongeData(5);
        }

        if (quizQuestionKey !== questionLimit - 1) {
          const checkAnswerCondition =
            +tabKey !== 1 && +tabKey !== 3
              ? colourCodedAnswer(number1, number2, String(tabKey))
              : colourCodedAnswer(checkNo1, checkNo2, String(tabKey)) === Infinity
              ? 0
              : colourCodedAnswer(checkNo1, checkNo2, String(tabKey));

          if (+answerData.answer === checkAnswerCondition) {
            if (tempKey === 0 || tempKey === quizQuestionKey) {
              setQuizQuestionKey(quizQuestionKey + 2);
            } else {
              setQuizQuestionKey(quizQuestionKey + 2);
            }
          } else {
            if (quizQuestionKey !== 0) {
              switch (questionCounter) {
                case 0:
                  setTempKey(quizQuestionKey);
                  setCorrectAnswer(correctAnswer - 1);
                  break;
                case 1:
                  setTempKey(quizQuestionKey);
                  setCorrectAnswer(correctAnswer - 1);

                  break;
                case 2:
                  setTempKey(quizQuestionKey);
                  setCorrectAnswer(correctAnswer - 1);

                  break;
                default:
                  setTempKey(quizQuestionKey);
                  setQuizQuestionKey(2);
                  break;
              }
            } else {
              setQuizQuestionKey(0);
            }
          }
        } else {
          if (wrongNumber) {
            setCorrectAnswer(correctAnswer - 1);
          } else {
            setIsFinishQuestions(true);
            setIsDoneWork(true);
            const tempQuizData = updateQuizPayload(
              quizData.data,
              +tabKey,
              key,
              getCurrentRoute(url, currentData),
              setDuration(questionRef.current, totalTime),
              wrongAnswer,
              correctAnswer,
            );
            await dispatch(
              attemptSetQuiz({
                data: tempQuizData?.tempQuizData,
                activity: tempQuizData?.activity,
              }),
            );
            setResultModalVisible(true);
          }
        }
        setAnswerData({
          sawAnswer: '',
          answer: '',
        });
        form.resetFields();
      }
    }
  };

  const onTimeOut = async () => {
    setIsDoneWork(true);
    setIsFinishQuestions(true);
    const tempQuizData = updateQuizPayload(
      quizData.data,
      +tabKey,
      key,
      getCurrentRoute(url, currentData),
      setDuration(questionRef.current, totalTime),
      wrongAnswer,
      correctAnswer,
    );
    await dispatch(
      attemptSetQuiz({
        data: tempQuizData?.tempQuizData,
        activity: tempQuizData?.activity,
      }),
    );

    setResultModalVisible(true);
    setAnswerData({
      sawAnswer: '',
      answer: '',
    });
    form.resetFields();
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.modalContainer}>
        <QuizHeader isDisplayNext={false} title={titleContent(String(tabKey))} />

        {isBlueCard ? (
          !isRedCard ? (
            <>
              <div className={styles.instruction}>Enter the Correct answer</div>
              <div className={styles.blueQuizWrapper}>
                <div className={styles.quizBorder}>
                  <div className={styles.upperArrow}>
                    <img src={BlueArrowImage} alt="something went wrong" />
                  </div>
                  <div className={styles.quizContainer}>
                    <div className={styles.questionWrapper}>
                      {+tabKey !== 1 && +tabKey !== 3 ? (
                        <>
                          <div className={styles.number1}>{number1}</div>
                          <div className={styles.number2}>
                            <span className={styles.signContainer}>{signContent(String(tabKey))}</span>
                            <span>{number2}</span>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={styles.number1}>
                            {+tabKey === 3 ? number2 : number2 < number1 ? number1 : number2}
                          </div>
                          <div className={styles.number2}>
                            <span className={styles.signContainer}>{signContent(String(tabKey))}</span>
                            <span>{+tabKey === 3 ? number1 : number1 > number2 ? number2 : number1}</span>
                          </div>
                        </>
                      )}
                      <div className={styles.answer}>
                        <hr />
                        <Form form={form} colon={false} autoComplete="off">
                          <CustomInputNumber
                            inputType="answer"
                            name="answer"
                            onKeyUp={handleBlueCard}
                            isDisables={!isDisplayNext || isDoneWork}
                            value={answerData.answer}
                            onChange={(val) => handleChange(val, 'answer')}
                          />
                        </Form>
                      </div>
                    </div>
                    <div className={styles.imageWrapper}>
                      <img src={BlueCartoon} alt="something went wrong" />
                    </div>
                  </div>
                  <div className={styles.downArrow}>
                    <img src={BlueArrowImage} alt="something went wrong" />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={styles.instruction}>Enter the answer you see </div>
              <div className={styles.redQuizWrapper}>
                <div className={styles.quizBorder}>
                  <div className={styles.upperArrow}>
                    <img src={RedArrowImage} alt="something went wrong" />
                  </div>
                  <div className={styles.quizContainer}>
                    <div className={styles.imageWrapper}>
                      <img src={RedCartoon} alt="something went wrong" />
                    </div>
                    <div className={styles.questionWrapper}>
                      {+tabKey !== 1 && +tabKey !== 3 ? (
                        <>
                          <div className={styles.number1}>{number1}</div>
                          <div className={styles.number2}>
                            <span className={styles.signContainer}>{signContent(String(tabKey))}</span>
                            <span>{number2}</span>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={styles.number1}>
                            {+tabKey === 3 ? number2 : number2 < number1 ? number1 : number2}
                          </div>
                          <div className={styles.number2}>
                            <span className={styles.signContainer}>{signContent(String(tabKey))}</span>
                            <span>{+tabKey === 3 ? number1 : number1 > number2 ? number2 : number1}</span>
                          </div>
                        </>
                      )}

                      <div className={wrongNumber ? styles.answer : styles.correctAnswer}>
                        <div className={styles.answer}>
                          <hr />
                          <Form form={form} colon={false} autoComplete="off">
                            <CustomInputNumber
                              inputType="sawAnswer"
                              name="sawAnswer"
                              onKeyUp={handleRedKeyDown}
                              isDisables={!isDisplayNext || isDoneWork}
                              value={answerData.sawAnswer}
                              colorTextPlaceholder={'red'}
                              placeholder={
                                +tabKey !== 1 && +tabKey !== 3
                                  ? colourCodedAnswer(number1, number2, String(tabKey))
                                  : colourCodedAnswer(checkNo1, checkNo2, String(tabKey)) === Infinity
                                  ? 0
                                  : colourCodedAnswer(checkNo1, checkNo2, String(tabKey))
                              }
                              onChange={(val) => handleChange(val, 'sawAnswer')}
                            />
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.downArrow}>
                    <img src={RedArrowImage} alt="something went wrong" />
                  </div>
                </div>
              </div>
            </>
          )
        ) : quizQuestionKey % 2 === 0 ? (
          <>
            <div className={styles.instruction}>Enter the answer you see </div>
            <div className={styles.redQuizWrapper}>
              <div className={styles.quizBorder}>
                <div className={styles.upperArrow}>
                  <img src={RedArrowImage} alt="something went wrong" />
                </div>
                <div className={styles.quizContainer}>
                  <div className={styles.imageWrapper}>
                    <img src={RedCartoon} alt="something went wrong" />
                  </div>
                  <div className={styles.questionWrapper}>
                    {+tabKey !== 1 && +tabKey !== 3 ? (
                      <>
                        <div className={styles.number1}>{number1}</div>
                        <div className={styles.number2}>
                          <span className={styles.signContainer}>{signContent(String(tabKey))}</span>
                          <span>{number2}</span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={styles.number1}>
                          {+tabKey === 3 ? number2 : number2 < number1 ? number1 : number2}
                        </div>
                        <div className={styles.number2}>
                          <span className={styles.signContainer}>{signContent(String(tabKey))}</span>
                          <span>{+tabKey === 3 ? number1 : number1 > number2 ? number2 : number1}</span>
                        </div>
                      </>
                    )}

                    <div className={wrongNumber ? styles.answer : styles.correctAnswer}>
                      <div className={styles.answer}>
                        <hr />
                        <Form form={form} colon={false} autoComplete="off">
                          <CustomInputNumber
                            inputType="sawAnswer"
                            name="sawAnswer"
                            onKeyUp={handleSawKeyDown}
                            isDisables={!isDisplayNext || isDoneWork}
                            value={answerData.sawAnswer}
                            colorTextPlaceholder={'red'}
                            placeholder={
                              +tabKey !== 1 && +tabKey !== 3
                                ? colourCodedAnswer(number1, number2, String(tabKey))
                                : colourCodedAnswer(checkNo1, checkNo2, String(tabKey)) === Infinity
                                ? 0
                                : colourCodedAnswer(checkNo1, checkNo2, String(tabKey))
                            }
                            // defaultValue={
                            //   +tabKey !== 1 && +tabKey !== 3
                            //     ? colourCodedAnswer(number1, number2, String(tabKey))
                            //     : colourCodedAnswer(checkNo1, checkNo2, String(tabKey)) === Infinity
                            //     ? 0
                            //     : colourCodedAnswer(checkNo1, checkNo2, String(tabKey))
                            // }
                            onChange={(val) => handleChange(val, 'sawAnswer')}
                          />
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.downArrow}>
                  <img src={RedArrowImage} alt="something went wrong" />
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.instruction}>Enter the Correct answer</div>
            <div className={styles.blueQuizWrapper}>
              <div className={styles.quizBorder}>
                <div className={styles.upperArrow}>
                  <img src={BlueArrowImage} alt="something went wrong" />
                </div>
                <div className={styles.quizContainer}>
                  <div className={styles.questionWrapper}>
                    {+tabKey !== 1 && +tabKey !== 3 ? (
                      <>
                        <div className={styles.number1}>{number1}</div>
                        <div className={styles.number2}>
                          <span className={styles.signContainer}>{signContent(String(tabKey))}</span>
                          <span>{number2}</span>
                        </div>
                      </>
                    ) : (
                      <>
                        {+tabKey === 3 ? (
                          <>
                            <div className={styles.number1}>{+tabKey === 3 && key === 0 ? number2 : number1}</div>
                            <div className={styles.number2}>
                              <span className={styles.signContainer}>{signContent(String(tabKey))}</span>
                              <span>{+tabKey === 3 && key === 0 ? number1 : number2}</span>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className={styles.number1}>{number2 < number1 ? number1 : number2}</div>
                            <div className={styles.number2}>
                              <span className={styles.signContainer}>{signContent(String(tabKey))}</span>
                              <span>{number1 > number2 ? number2 : number1}</span>
                            </div>
                          </>
                        )}
                      </>
                    )}
                    <div className={styles.answer}>
                      <hr />
                      <Form form={form} colon={false} autoComplete="off">
                        <CustomInputNumber
                          inputType="answer"
                          name="answer"
                          onKeyUp={handleKeyDown}
                          isDisables={!isDisplayNext || isDoneWork}
                          value={answerData.answer}
                          onChange={(val) => handleChange(val, 'answer')}
                        />
                      </Form>
                    </div>
                  </div>
                  <div className={styles.imageWrapper}>
                    <img src={BlueCartoon} alt="something went wrong" />
                  </div>
                </div>
                <div className={styles.downArrow}>
                  <img src={BlueArrowImage} alt="something went wrong" />
                </div>
              </div>
            </div>
          </>
        )}

        <QuizFooterHandleTimer
          isDisplayNext={isDisplayNext}
          setIsDisplayNext={setIsDisplayNext}
          isDisabled={!isDoneWork}
          questionRef={questionRef}
          totalTime={15}
          onTimeOut={onTimeOut}
        />
      </div>

      {resultModalVisible && (
        <ResultModal resultModalVisible={resultModalVisible} setResultModalVisible={setResultModalVisible} />
      )}
    </div>
  );
};

export default ColourCoded;
