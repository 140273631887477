import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { notification } from 'antd';

// Styles
import styles from './ConfirmationModal.module.scss';

// Actions
import { deleteAccount, doLogout } from '../../../../store/actions/authActions';
import CustomButton from '../../../../components/Button';

const ConfirmationModal = ({ setModal, modal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);

  const handleLogout = async () => {
    setLoading(true);
    const response = await dispatch(doLogout());
    if (response) {
      setModal(false);
      navigate('/');
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handleDeleteAccount = async () => {
    const payload = {
      user_id: user?.id,
    };
    setLoading(true);
    const response = await dispatch(deleteAccount(payload));
    if (response) {
      setModal(false);
      setLoading(false);
      notification['success']({
        message: 'Account Deleted Successfully',
      });
      navigate('/');
    } else {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className={styles.mainContainer}>
        <div className={styles.headingContainer}>Are you sure you want to {modal === 'logout' ? 'logout?' : 'delete this account?'}</div>
        <div className={styles.buttonWrapper}>
          <div className={styles.cancelButton}>
            <CustomButton onClick={() => setModal(false)}>Cancel</CustomButton>
          </div>
          <CustomButton
            onClick={() => {
              modal === 'logout' ? handleLogout() : handleDeleteAccount();
            }}
            loading={loading}
          >
            Yes
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
