import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cs from 'classnames';

// Styles
import styles from './NavigationLink.module.scss';
import homeStyles from './HomeNavigationLink.module.scss';

// Components
import { TabData, TabLoginArray, ChildTabData, CheckUrlTabData, AdminTabData } from '../Tab/TabData';

// Actions
import { attemptSetTabPath } from '../../../../store/actions/quizActions';

// Hooks
import { loginUserData, useReducerData } from '../../../../store/hooks';

// Helper Functions
import { isLoggedIn } from '../../../../helpers/utility';

const NavigationLink = ({ manuList, onClose }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const useStyles = location.pathname === '/' ? homeStyles : styles;
  const navClass = manuList ? useStyles.NavLinkClass : '';
  const tabPath = useReducerData('quiz', 'tabPath');
  const [isLogin, setIsLogin] = useState(isLoggedIn());
  const { user } = useSelector((state) => state.auth);
  const currentUrl = CheckUrlTabData.includes(window.location.pathname);
  const pathName = window.location.pathname;
  const pattern = /\/profile\/edit\/\d+/;
  const checkPath = pattern?.test(pathName) || pathName === '/settings' || pathName === '/profile/edit/new-profile';

  useEffect(() => {
    setIsLogin(isLoggedIn());
    dispatch(attemptSetTabPath(currentUrl ? window.location.pathname : checkPath ? '/profile' : '/'));
  }, [user, pathName, currentUrl]);

  const DataArray =
    isLogin && loginUserData()
      ? user?.user_type === 'Children' || user?.user_type === 'Student'
        ? ChildTabData
        : user?.user_type === 'Admin'
        ? AdminTabData
        : TabData
      : TabLoginArray;

    const signupClass = location.pathname !== '/' ? styles.headerSignup : styles.signupClass

  return (
    <>
      {DataArray.map((tabs, index) => {
        return (
          <NavLink
            to={tabs.pathName}
            className={tabPath === tabs.pathName ? useStyles.activeTab : useStyles.homeText}
            key={index}
            onClick={() => {
              dispatch(attemptSetTabPath(tabs.pathName));
            }}
          >
            <p
              className={cs(navClass, {
                [signupClass]: tabs.id === 5,
              })}
              onClick={onClose}
            >
              {tabs.TabName}
            </p>
          </NavLink>
        );
      })}
    </>
  );
};

export default NavigationLink;
