const ZerosQuiz = [
  {
    subKey: 0,
    title: 'Zeros Activities',
    questions: 5,
    redirectTo: '/activities/number-quiz',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: true,
    duration: '',
    moveToPoint: 10,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 1,
    title: 'Figure 8 activity',
    redirectTo: '/activities/figure8',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 30,
    isActive: false,
    duration: '',
    moveToPoint: 20,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 2,
    title: 'Direction Charts',
    redirectTo: '/activities/direction-chart',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 45,
    isActive: false,
    duration: '',
    moveToPoint: 30,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 3,
    title: 'Colour coded memory cards',
    questions: 40,
    redirectTo: '/activities/colour-coded',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 40,
    rewardText: 'Congratulations, you did it! ',
  },
  // {
  //   subKey: 4,
  //   title: 'Finger Skip Counting',
  //   redirectTo: '/activities/finger-skip-counting',
  //   correctAnswer: 0,
  //   wrongAnswer: 0,
  //   isActive: false,
  //   duration: '',
  //   moveToPoint: 45,
  //   rewardText: 'Congratulations, you did it!',
  // },
  {
    subKey: 5,
    title: 'Brain Warrior Workout',
    redirectTo: '/activities/brain-warrior-workout',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 50,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 6,
    title: 'Final Quiz',
    redirectTo: '/activities/final-quiz',
    questions: 5,
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 74,
    movetoPointFailed: 72,
    rewardText: 'Congratulations, you did it! Move to Ones!',
    startAgain: 'Great Work! Almost there, Let’s Try Again!',
  },
];
const OnesQuiz = [
  {
    subKey: 0,
    title: 'Ones Activities',
    questions: 5,
    redirectTo: '/activities/number-quiz',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: true,
    duration: '',
    moveToPoint: 10,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 1,
    title: 'Figure 8 activity',
    redirectTo: '/activities/figure8',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 30,
    isActive: false,
    duration: '',
    moveToPoint: 20,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 2,
    title: 'Speed Number Counting',
    redirectTo: '/activities/speed-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 30,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 3,
    title: 'Direction Charts',
    redirectTo: '/activities/direction-chart',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 45,
    isActive: false,
    duration: '',
    moveToPoint: 40,
    rewardText: 'Congratulations, you did it! ',
  },
  // {
  //   subKey: 4,
  //   title: 'Finger Skip Counting',
  //   redirectTo: '/activities/finger-skip-counting',
  //   correctAnswer: 0,
  //   wrongAnswer: 0,
  //   isActive: false,
  //   duration: '',
  //   moveToPoint: 45,
  //   rewardText: 'Congratulations, you did it!',
  // },
  {
    subKey: 4,
    title: 'Colour coded memory cards',
    questions: 40,
    redirectTo: '/activities/colour-coded',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 50,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 5,
    title: 'Brain Warrior Workout',
    redirectTo: '/activities/brain-warrior-workout',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 60,
    rewardText: 'Congratulations, you did it! ',
  },
  {
    subKey: 6,
    title: 'Final Quiz',
    redirectTo: '/activities/final-quiz',
    questions: 5,
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 74,
    movetoPointFailed: 72,
    rewardText: 'Congratulations, you did it! Move to Twos!',
    startAgain: 'Great Work! Almost there, Let’s Try Again!',
  },
];
const TwosQuiz = [
  {
    subKey: 0,
    title: 'Twos Activities',
    questions: 5,
    redirectTo: '/activities/number-quiz',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: true,
    duration: '',
    moveToPoint: 9,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 1,
    title: 'Figure 8 activity',
    redirectTo: '/activities/figure8',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 30,
    isActive: false,
    duration: '',
    moveToPoint: 18,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 2,
    title: 'Speed Number Counting',
    redirectTo: '/activities/speed-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 27,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 3,
    title: 'Direction Charts',
    redirectTo: '/activities/direction-chart',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 45,
    isActive: false,
    duration: '',
    moveToPoint: 36,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 4,
    title: 'Finger Skip Counting',
    redirectTo: '/activities/finger-skip-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 45,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 5,
    title: 'Colour coded memory cards',
    questions: 40,
    redirectTo: '/activities/colour-coded',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 54,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 6,
    title: 'Brain Warrior Workout',
    redirectTo: '/activities/brain-warrior-workout',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 63,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 7,
    title: 'Final Quiz',
    redirectTo: '/activities/final-quiz',
    questions: 5,
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 74,
    movetoPointFailed: 72,
    rewardText: 'Congratulations, you did it! Move to Threes!',
    startAgain: 'Great Work! Almost there, Let’s Try Again!',
  },
];
const ThreesQuiz = [
  {
    subKey: 0,
    title: 'Threes Activities',
    questions: 5,
    redirectTo: '/activities/number-quiz',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: true,
    duration: '',
    moveToPoint: 9,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 1,
    title: 'Figure 8 activity',
    redirectTo: '/activities/figure8',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 30,
    isActive: false,
    duration: '',
    moveToPoint: 18,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 2,
    title: 'Speed Number Counting',
    redirectTo: '/activities/speed-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 27,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 3,
    title: 'Direction Charts',
    redirectTo: '/activities/direction-chart',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 45,
    isActive: false,
    duration: '',
    moveToPoint: 36,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 4,
    title: 'Finger Skip Counting',
    redirectTo: '/activities/finger-skip-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 45,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 5,
    title: 'Colour coded memory cards',
    questions: 40,
    redirectTo: '/activities/colour-coded',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 54,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 6,
    title: 'Brain Warrior Workout',
    redirectTo: '/activities/brain-warrior-workout',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 63,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 7,
    title: 'Final Quiz',
    redirectTo: '/activities/final-quiz',
    questions: 5,
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 74,
    movetoPointFailed: 72,
    rewardText: 'Congratulations, you did it! Move to Fours!',
    startAgain: 'Great Work! Almost there, Let’s Try Again!',
  },
];
const FoursQuiz = [
  {
    subKey: 0,
    title: 'Fours Activities',
    questions: 5,
    redirectTo: '/activities/number-quiz',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: true,
    duration: '',
    moveToPoint: 9,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 1,
    title: 'Figure 8 activity',
    redirectTo: '/activities/figure8',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 30,
    isActive: false,
    duration: '',
    moveToPoint: 18,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 2,
    title: 'Speed Number Counting',
    redirectTo: '/activities/speed-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 27,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 3,
    title: 'Direction Charts',
    redirectTo: '/activities/direction-chart',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 45,
    isActive: false,
    duration: '',
    moveToPoint: 36,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 4,
    title: 'Finger Skip Counting',
    redirectTo: '/activities/finger-skip-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 45,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 5,
    title: 'Colour coded memory cards',
    questions: 40,
    redirectTo: '/activities/colour-coded',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 54,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 6,
    title: 'Brain Warrior Workout',
    redirectTo: '/activities/brain-warrior-workout',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 63,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 7,
    title: 'Final Quiz',
    redirectTo: '/activities/final-quiz',
    questions: 5,
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 74,
    movetoPointFailed: 72,
    rewardText: 'Congratulations, you did it! Move to Fives!',
    startAgain: 'Great Work! Almost there, Let’s Try Again!',
  },
];
const FivesQuiz = [
  {
    subKey: 0,
    title: 'Fives Activities',
    questions: 5,
    redirectTo: '/activities/number-quiz',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: true,
    duration: '',
    moveToPoint: 9,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 1,
    title: 'Figure 8 activity',
    redirectTo: '/activities/figure8',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 30,
    isActive: false,
    duration: '',
    moveToPoint: 18,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 2,
    title: 'Speed Number Counting',
    redirectTo: '/activities/speed-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 27,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 3,
    title: 'Direction Charts',
    redirectTo: '/activities/direction-chart',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 45,
    isActive: false,
    duration: '',
    moveToPoint: 36,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 4,
    title: 'Finger Skip Counting',
    redirectTo: '/activities/finger-skip-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 45,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 5,
    title: 'Colour coded memory cards',
    questions: 40,
    redirectTo: '/activities/colour-coded',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 54,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 6,
    title: 'Brain Warrior Workout',
    redirectTo: '/activities/brain-warrior-workout',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 63,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 7,
    title: 'Final Quiz',
    redirectTo: '/activities/final-quiz',
    questions: 5,
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 74,
    movetoPointFailed: 72,
    rewardText: 'Congratulations, you did it! Move to Sixes!',
    startAgain: 'Great Work! Almost there, Let’s Try Again!',
  },
];
const SixesQuiz = [
  {
    subKey: 0,
    title: 'Sixes Activities',
    questions: 5,
    redirectTo: '/activities/number-quiz',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: true,
    duration: '',
    moveToPoint: 9,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 1,
    title: 'Figure 8 activity',
    redirectTo: '/activities/figure8',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 30,
    isActive: false,
    duration: '',
    moveToPoint: 18,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 2,
    title: 'Speed Number Counting',
    redirectTo: '/activities/speed-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 27,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 3,
    title: 'Direction Charts',
    redirectTo: '/activities/direction-chart',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 45,
    isActive: false,
    duration: '',
    moveToPoint: 36,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 4,
    title: 'Finger Skip Counting',
    redirectTo: '/activities/finger-skip-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 45,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 5,
    title: 'Colour coded memory cards',
    questions: 40,
    redirectTo: '/activities/colour-coded',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 54,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 6,
    title: 'Brain Warrior Workout',
    redirectTo: '/activities/brain-warrior-workout',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 63,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 7,
    title: 'Final Quiz',
    redirectTo: '/activities/final-quiz',
    questions: 5,
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 74,
    movetoPointFailed: 72,
    rewardText: 'Congratulations, you did it! Move to Sevens!',
    startAgain: 'Great Work! Almost there, Let’s Try Again!',
  },
];
const SevensQuiz = [
  {
    subKey: 0,
    title: 'Sevens Activities',
    questions: 5,
    redirectTo: '/activities/number-quiz',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: true,
    duration: '',
    moveToPoint: 9,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 1,
    title: 'Figure 8 activity',
    redirectTo: '/activities/figure8',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 30,
    isActive: false,
    duration: '',
    moveToPoint: 18,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 2,
    title: 'Speed Number Counting',
    redirectTo: '/activities/speed-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 27,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 3,
    title: 'Direction Charts',
    redirectTo: '/activities/direction-chart',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 45,
    isActive: false,
    duration: '',
    moveToPoint: 36,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 4,
    title: 'Finger Skip Counting',
    redirectTo: '/activities/finger-skip-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 45,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 5,
    title: 'Colour coded memory cards',
    questions: 40,
    redirectTo: '/activities/colour-coded',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 54,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 6,
    title: 'Brain Warrior Workout',
    redirectTo: '/activities/brain-warrior-workout',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 63,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 7,
    title: 'Final Quiz',
    redirectTo: '/activities/final-quiz',
    questions: 5,
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 74,
    movetoPointFailed: 72,
    rewardText: 'Congratulations, you did it! Move to Eights!',
    startAgain: 'Great Work! Almost there, Let’s Try Again!',
  },
];
const EightsQuiz = [
  {
    subKey: 0,
    title: 'Eights Activities',
    questions: 5,
    redirectTo: '/activities/number-quiz',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: true,
    duration: '',
    moveToPoint: 9,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 1,
    title: 'Figure 8 activity',
    redirectTo: '/activities/figure8  ',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 30,
    isActive: false,
    duration: '',
    moveToPoint: 18,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 2,
    title: 'Speed Number Counting',
    redirectTo: '/activities/speed-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 27,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 3,
    title: 'Direction Charts',
    redirectTo: '/activities/direction-chart',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 45,
    isActive: false,
    duration: '',
    moveToPoint: 36,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 4,
    title: 'Finger Skip Counting',
    redirectTo: '/activities/finger-skip-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 45,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 5,
    title: 'Colour coded memory cards',
    questions: 40,
    redirectTo: '/activities/colour-coded',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 54,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 6,
    title: 'Brain Warrior Workout',
    redirectTo: '/activities/brain-warrior-workout',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 63,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 7,
    title: 'Final Quiz',
    redirectTo: '/activities/final-quiz',
    questions: 5,
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 74,
    movetoPointFailed: 72,
    rewardText: 'Congratulations, you did it! Move to Nines!',
    startAgain: 'Great Work! Almost there, Let’s Try Again!',
  },
];
const NinesQuiz = [
  {
    subKey: 0,
    title: 'Nines Activities',
    questions: 5,
    redirectTo: '/activities/number-quiz',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: true,
    duration: '',
    moveToPoint: 9,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 1,
    title: 'Figure 8 activity',
    redirectTo: '/activities/figure8',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 30,
    isActive: false,
    duration: '',
    moveToPoint: 18,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 2,
    title: 'Speed Number Counting',
    redirectTo: '/activities/speed-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 27,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 3,
    title: 'Direction Charts',
    redirectTo: '/activities/direction-chart',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 45,
    isActive: false,
    duration: '',
    moveToPoint: 36,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 4,
    title: 'Finger Skip Counting',
    redirectTo: '/activities/finger-skip-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 45,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 5,
    title: 'Colour coded memory cards',
    questions: 40,
    redirectTo: '/activities/colour-coded',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 54,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 6,
    title: 'Brain Warrior Workout',
    redirectTo: '/activities/brain-warrior-workout',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 63,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 7,
    title: 'Final Quiz',
    redirectTo: '/activities/final-quiz',
    questions: 5,
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 74,
    movetoPointFailed: 72,
    rewardText: 'Congratulations, you did it! Move to Tens!',
    startAgain: 'Great Work! Almost there, Let’s Try Again!',
  },
];
const TensQuiz = [
  {
    subKey: 0,
    title: 'Tens Activities',
    questions: 5,
    redirectTo: '/activities/number-quiz',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: true,
    duration: '',
    moveToPoint: 9,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 1,
    title: 'Figure 8 activity',
    redirectTo: '/activities/figure8',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 30,
    isActive: false,
    duration: '',
    moveToPoint: 18,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 2,
    title: 'Speed Number Counting',
    redirectTo: '/activities/speed-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 27,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 3,
    title: 'Direction Charts',
    redirectTo: '/activities/direction-chart',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 45,
    isActive: false,
    duration: '',
    moveToPoint: 36,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 4,
    title: 'Finger Skip Counting',
    redirectTo: '/activities/finger-skip-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 45,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 5,
    title: 'Colour coded memory cards',
    questions: 40,
    redirectTo: '/activities/colour-coded',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 54,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 6,
    title: 'Brain Warrior Workout',
    redirectTo: '/activities/brain-warrior-workout',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 63,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 7,
    title: 'Final Quiz',
    redirectTo: '/activities/final-quiz',
    questions: 5,
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 74,
    movetoPointFailed: 72,
    rewardText: 'Congratulations, you did it! Move to Elevens!',
    startAgain: 'Great Work! Almost there, Let’s Try Again!',
  },
];

const ElevensQuiz = [
  {
    subKey: 0,
    title: 'Elevens Activities',
    questions: 5,
    redirectTo: '/activities/number-quiz',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: true,
    duration: '',
    moveToPoint: 9,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 1,
    title: 'Figure 8 activity',
    redirectTo: '/activities/figure8',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 30,
    isActive: false,
    duration: '',
    moveToPoint: 18,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 2,
    title: 'Speed Number Counting',
    redirectTo: '/activities/speed-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 27,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 3,
    title: 'Direction Charts',
    redirectTo: '/activities/direction-chart',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 45,
    isActive: false,
    duration: '',
    moveToPoint: 36,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 4,
    title: 'Finger Skip Counting',
    redirectTo: '/activities/finger-skip-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 45,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 5,
    title: 'Colour coded memory cards',
    questions: 40,
    redirectTo: '/activities/colour-coded',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 54,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 6,
    title: 'Brain Warrior Workout',
    redirectTo: '/activities/brain-warrior-workout',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 63,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 7,
    title: 'Final Quiz',
    redirectTo: '/activities/final-quiz',
    questions: 5,
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 74,
    movetoPointFailed: 72,
    rewardText: 'Congratulations, you did it! Move to Twelves!',
    startAgain: 'Great Work! Almost there, Let’s Try Again!',
  },
];

const TwelvesQuiz = [
  {
    subKey: 0,
    title: 'Twelves Activities',
    questions: 5,
    redirectTo: '/activities/number-quiz',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: true,
    duration: '',
    moveToPoint: 9,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 1,
    title: 'Figure 8 activity',
    redirectTo: '/activities/figure8',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 30,
    isActive: false,
    duration: '',
    moveToPoint: 18,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 2,
    title: 'Speed Number Counting',
    redirectTo: '/activities/speed-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 27,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 3,
    title: 'Direction Charts',
    redirectTo: '/activities/direction-chart',
    correctAnswer: 0,
    wrongAnswer: 0,
    questions: 45,
    isActive: false,
    duration: '',
    moveToPoint: 36,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 4,
    title: 'Finger Skip Counting',
    redirectTo: '/activities/finger-skip-counting',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 45,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 5,
    title: 'Colour coded memory cards',
    questions: 40,
    redirectTo: '/activities/colour-coded',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 54,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 6,
    title: 'Brain Warrior Workout',
    redirectTo: '/activities/brain-warrior-workout',
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 63,
    rewardText: 'Congratulations, you did it!',
  },
  {
    subKey: 7,
    title: 'Final Quiz',
    redirectTo: '/activities/final-quiz',
    questions: 5,
    correctAnswer: 0,
    wrongAnswer: 0,
    isActive: false,
    duration: '',
    moveToPoint: 74,
    movetoPointFailed: 72,
    rewardText: 'Congratulations, you did it! Move to Division',
    startAgain: 'Great Work! Almost there, Let’s Try Again!',
  },
];

export const multiplicationQuiz = [
  {
    key: 0,
    data: ZerosQuiz,
    isDisable: false,
  },
  {
    key: 1,
    data: OnesQuiz,
    isDisable: true,
  },
  {
    key: 2,
    data: TwosQuiz,
    isDisable: true,
  },
  {
    key: 3,
    data: ThreesQuiz,
    isDisable: true,
  },
  {
    key: 4,
    data: FoursQuiz,
    isDisable: true,
  },
  {
    key: 5,
    data: FivesQuiz,
    isDisable: true,
  },
  {
    key: 6,
    data: SixesQuiz,
    isDisable: true,
  },
  {
    key: 7,
    data: SevensQuiz,
    isDisable: true,
  },
  {
    key: 8,
    data: EightsQuiz,
    isDisable: true,
  },
  {
    key: 9,
    data: NinesQuiz,
    isDisable: true,
  },
  {
    key: 10,
    data: TensQuiz,
    isDisable: true,
  },
  {
    key: 11,
    data: ElevensQuiz,
    isDisable: true,
  },
  {
    key: 12,
    data: TwelvesQuiz,
    isDisable: true,
  },
];
