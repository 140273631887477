import React, { useEffect } from 'react';
import styles from '../Checkout.module.scss';
import { useNavigate } from 'react-router-dom';

const PaymentSuccessModal = () => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate('/profile');
      window.location.reload();
    }, 500);
  }, []);
  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.paymentText}>
          <div className={styles.successText}>
            <span>
              You have successfully purchased <br /> our program.Thank you!
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentSuccessModal;
