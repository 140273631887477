import React, { useState } from 'react';
import styles from './Profile.module.scss';
import { Form } from 'antd';
import CustomInput from '../../components/Input';
import CustomButton from '../../components/Button';
import { attemptGetSubUserProfile, attemptGetUserProfile, editCurrentProfile } from '../../store/actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
import { useReducerData } from '../../store/hooks';
import { useNavigate } from 'react-router-dom';

const EditUser = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useReducerData('auth', 'currentUser');
  const { loading } = useSelector((state) => state.auth);
  const [userData, setUserData] = useState({
    email: currentUser?.email,
    id: currentUser?.id,
    name: currentUser?.name,
    user_type: currentUser?.user_type,
  });

  const handleChange = ({ target: { name, value } }) => {
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    await dispatch(editCurrentProfile(currentUser?.id, userData, callBack));
  };

  const callBack = () => {
    navigate('/profile');
    setUserData({
      email: '',
      id: '',
      name: '',
      user_type: '',
    });
    form.resetFields();
    dispatch(attemptGetSubUserProfile());
    dispatch(attemptGetUserProfile());
  };
  return (
    <>
      <div className={styles.formContainer}>
        <div className={styles.formInput}>
          <Form colon={false} onFinish={handleSubmit} autoComplete="off" form={form}>
            <CustomInput
              label="Email"
              inputType="email"
              name="email"
              value={userData.email}
              defaultValue={userData.email}
              onChange={handleChange}
              placeholder="Enter your email"
              disabled={true}
              rules={
                userData.email === ''
                  ? [
                      {
                        required: true,
                        message: 'Please enter your email!',
                        whitespace: true,
                      },
                    ]
                  : [
                      {
                        type: 'email',
                        message: 'Please enter valid E-mail!',
                      },
                    ]
              }
              // allowClear
            />

            <CustomInput
              label="Name"
              inputType="input"
              name="name"
              value={userData.name}
              defaultValue={userData.name}
              onChange={handleChange}
              placeholder="Enter your name"
              rules={
                userData.name === '' && [
                  {
                    required: true,
                    message: 'Please enter first name',
                    whitespace: true,
                  },
                ]
              }
              // allowClear
            />

            <CustomButton loading={loading} type="primary" htmlType="submit">
              Edit Profile
            </CustomButton>
          </Form>
        </div>
      </div>
    </>
  );
};

export default EditUser;
