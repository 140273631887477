import React, { useState } from 'react';
import styles from './changepassword.module.scss';
import { Form } from 'antd';
import { validatePassword } from '../../../../helpers/constants';
import CustomPassword from '../../../../components/InputPassword/InputPassword';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { changePassword } from '../../../../store/actions/authActions';

const ChangePassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [changePassForm, setChangePassword] = useState({
    old_password: '',
    new_password1: '',
    new_password2: '',
  });

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setChangePassword({
      ...changePassForm,
      [name]: value,
    });
  };

  const onFinish = async () => {
    const response = await dispatch(changePassword(changePassForm));
    if (response) navigate(-1);
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.passwordContainer}>
        <Header title={`Change Password`} />
        <div className={styles.formInput}>
          <Form autoComplete="off" onFinish={onFinish}>
            <CustomPassword
              label="Enter current password"
              inputType="password"
              name="old_password"
              placeholder="Enter your current password"
              onChange={handleChange}
              rules={[
                {
                  required: true,
                  message: 'Please enter current password',
                },
              ]}
              allowClear
            />

            <CustomPassword
              label="New Password"
              inputType="password"
              name="new_password1"
              placeholder="Enter your password"
              onChange={handleChange}
              rules={[
                ({ getFieldValue }) => ({
                  validator() {
                    if (!validatePassword(getFieldValue('new_password1'))) {
                      if (!getFieldValue('new_password1')) {
                        return Promise.reject(new Error('Please enter password'));
                      }
                      return Promise.reject(new Error('Please enter strong password'));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              allowClear
            />
            <CustomPassword
              label="Confirm Password"
              inputType="password"
              name="new_password2"
              placeholder="Confirm password"
              dependencies={['new_password1']}
              onChange={handleChange}
              rules={[
                {
                  required: true,
                  message: 'Please enter confirm password',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('new_password1') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('confirm password does not match with password'));
                  },
                }),
              ]}
              allowClear
            />
            <div className={styles.btnWrapper}>
              <Footer />
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
