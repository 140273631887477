import React from 'react';
import { useSelector } from 'react-redux';
import ReactECharts from 'echarts-for-react';

const PieChart = ({ title }) => {
  const data = useSelector((state) => state.admin.summaryChart);
  const result = () => {
    const result =
      data &&
      data?.filtered_data?.student_list?.map((name, index) => {
        return { name, value: (data?.filtered_data?.time_list[index]) };
      });

    return result;
  };

  const option = {
    title: {
      text: title,
    },
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        const time = params?.value;
        const name = params?.name;
        const customText = `<b>Name: </b> ${name}<br><b>Total Time:</b> ${time} mins`;
        return customText;
      },
    },
    legend: {
      top: '5%',
      left: 'center',
    },
    series: [
      {
        name: 'Time mins',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center',
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 40,
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        data: result(),
      },
    ],
  };

  return (
    <>
      <ReactECharts option={option} style={{ height: 500 }} />
    </>
  );
};
export default PieChart;
