import React, { useState } from 'react';
import styles from './login.module.scss';
import { Form } from 'antd';
import CustomButton from '../../../components/Button/Button';
import CustomInput from '../../../components/Input/Input';
import Header from '../authComponent/Header/Header';
import Footer from '../authComponent/Footer/Footer';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { doLogin } from '../../../store/actions/authActions';
import CustomPassword from '../../../components/InputPassword/InputPassword';
import { attemptSetModelDetail, attemptSetTab, attemptSetTabPath } from '../../../store/actions/quizActions';

const Login = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [authData, setAuthData] = useState({
    email: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);

  const handleChange = ({ target: { name, value } }) => {
    setAuthData({
      ...authData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    const payload = {
      username: authData.email,
      password: authData.password,
    };
    setLoading(true);
    const response = await dispatch(doLogin(payload));
    if (response === 1) {
      setLoading(false);
      setAuthData({
        email: '',
        password: '',
      });
      form.resetFields();
      await dispatch(attemptSetTabPath('/'));
      await dispatch(attemptSetTab('0'));
      await dispatch(attemptSetModelDetail(''));
    } else {
      setLoading(false);
    }
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.formContainer}>
        <Header />
        <div className={styles.formInput}>
          <Form colon={false} onFinish={handleSubmit} autoComplete="off" form={form}>
            <CustomInput
              label="Email"
              name="email"
              inputType="email"
              placeholder="Enter your email"
              onChange={handleChange}
              value={authData.email}
              rules={[
                {
                  required: true,
                  message: 'Please enter your email!',
                },
                {
                  type: 'email',
                  message: 'Please enter valid E-mail!',
                },
              ]}
              allowClear
            />
            <CustomPassword
              label="Password"
              name="password"
              placeholder="Enter your password"
              onChange={handleChange}
              value={authData.password}
              rules={[
                {
                  required: true,
                  message: 'Please enter password',
                },
              ]}
              allowClear
            />
            <NavLink to="/forgotPassword" className={styles.forgotPass}>
              Forgot your password?
            </NavLink>
            <CustomButton type="primary" loading={loading} htmlType="submit">
              Log in
            </CustomButton>
          </Form>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default Login;
