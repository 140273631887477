import React from 'react';
import { Form, Modal } from 'antd';
import CustomInput from '../../../components/Input';
import styles from '../forgotPassword/forgotPassword.module.scss';
import SendEmail from './SendEmail';

const OtpModal = ({
  isModalVisible,
  handleOk,
  handleCancel,
  forgotPasswordData,
  resetPasswordhandleChange,
  handleSend,
}) => {
  return (
    <>
      <Modal
        title="Confirm One Time Password"
        open={isModalVisible}
        okButtonProps={{ form: 'category-editor-form', key: 'submit', htmlType: 'submit' }}
        onCancel={handleCancel}
      >
        <div className={styles.formInput}>
          <Form
            colon={false}
            id="category-editor-form"
            onFinish={handleOk}
            autoComplete="off"
            style={{ padding: '20px', flexDirection: 'column' }}
          >
            <CustomInput
              label="Enter One Time Password"
              inputType="input"
              name="otp"
              value={forgotPasswordData.token}
              onChange={resetPasswordhandleChange}
              placeholder="Please Enter One time password"
              rules={[
                {
                  required: true,
                  message: 'Please enter one time password',
                  whitespace: true,
                },
              ]}
              allowClear
            />
            <div onClick={handleSend} className={styles.resendOtpWrapper}>
              <div className={styles.headingWrapper}>Didn't receive the email? </div>
              <span>Resend</span>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default OtpModal;
