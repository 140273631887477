import styled from 'styled-components';

function FilterButton({ dropdownStyle, onClick, style, textData, itemList, dropDownValue, ...props }) {
  return (
    <>
      <StyledFilterButton>
        <div className="dropdown" style={dropdownStyle}>
          <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" onClick={onClick} style={style}>
            {textData && <span>{textData}</span>}
            {props.class && (
              <span>
                <i className={props.class}></i>
              </span>
            )}
          </button>
          <StyledPopUp className="filter-dropdown" style={{ display: dropDownValue ? 'block' : 'none' }}>
            {itemList &&
              itemList?.map((item, index) => (
                <li className="dropdown-item" key={index}>
                  <span>{item}</span>
                </li>
              ))}
          </StyledPopUp>
        </div>
      </StyledFilterButton>
    </>
  );
}
export default FilterButton;
const StyledPopUp = styled.div`
  width: 100%;
  background: #ffffff;
  padding: 10px;
  z-index: 5;
  border-radius: 4px;
  position: absolute;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  top: 50px;
  left: 0px;
  padding-top: 0.5rem;
  .dropdown-item:focus,
  .dropdown-item:hover {
    text-decoration: none;
    background-color: white;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const StyledFilterButton = styled.div`
  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
  }
  .dropdown {
    display: flex;
    justify-content: flex-end !important;
    @media (max-width: 768px) {
      justify-content: start !important;
      padding: 0;
    }
    button {
      display: flex;
      background-color: #ffffff;
      border: 1px solid #c5c5c5;
      width: 100% !important;
      border-radius: 5px;
      height: 45px;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      margin-top: 0 !important;
      &:focus {
        box-shadow: none;
      }
      span {
        font-size: 16px !important;
      }
    }
    .dropdown-item{
      label{
        text-align: left;
        margin-bottom: 3px;
        font-size: 16px;
        font-weight: 500 !important;
      }
      .ant-select-selection-placeholder, .ant-select-selection-item{
        color: #232323;
        text-align: left;
      }
    }
  }
  .dropdown-toggle {
    &::after {
      display: none;
    }
  }
  .fas {
    color: #c5c5c5;
    font-weight: lighter !important;
    font-size: 12px !important;
  }
  .dropdown-menu {
    margin-left: -37px;
  }
  .ant-select-arrow {
    top: 57% !important;
  }
  .filter-dropdown {
    // height: 267px !important;
    overflow: auto;
  }
  .filter-dropdown::-webkit-scrollbar {
    height: 8px !important;
    width: 8px !important;
  }

  /* Track */
  .filter-dropdown::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  .filter-dropdown::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 10px;
  }
`;
