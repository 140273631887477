import { Form, Input } from 'antd';
import React from 'react';
import styles from './InputTextarea.module.scss';
import cs from 'classnames';

const CustomTextarea = ({
  children,
  placeholder,
  message,
  className,
  rules,
  name,
  inputType,
  label,
  isVertical = true,
  onChange,
  ...rest
}) => {
  return (
    <div>
      <Form.Item
        required={false}
        className={cs(styles.inputWrapper, { [styles.inputVerticalWrapper]: isVertical })}
        label={label}
        name={name}
        rules={rules}
        onChange={onChange}
        labelAlign={isVertical ? 'left' : 'right'}
      >
        <Input.TextArea
          className={cs(styles.inputClass, { [className]: className })}
          name={name}
          rows={4}
          placeholder={placeholder}
          {...rest}
        />
      </Form.Item>
    </div>
  );
};

export default CustomTextarea;
