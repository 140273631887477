import React from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';

// Styles
import styles from './Footer.module.scss';

// Data
import CogPlayIcon from '../../../components/CogPlayIcon/CogPlayIcon';

// Assets
import { ReactComponent as FooterIconV } from '../../../assets/images/footer_V_Icon.svg';
import { ReactComponent as FooterIconYoutube } from '../../../assets/images/footer_youtube_Icon.svg';
import { ReactComponent as FooterIconFaceBook } from '../../../assets/images/footer_facebook_Icon.svg';
import { FooterData } from './FooterData.js';

// Constants
import { fixedFooterRoutes } from '../../../helpers/constants';

// Actions
import { attemptSetTabPath } from '../../../store/actions/quizActions';

const Footer = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  return (
    <div
      className={styles.mainFooter}
    >
      <div className={styles.footerTab}>
        <div className={styles.cogPlayIcon}>
          <CogPlayIcon />
        </div>
        <div className={styles.footerText}>
          {FooterData.map((tabs, index) => {
            return (
              <NavLink
                to={tabs.pathName}
                className={styles.TabText}
                key={index}
                onClick={() => {
                  dispatch(attemptSetTabPath(tabs.pathName));
                }}
              >
                {tabs.tabName}
              </NavLink>
            );
          })}
        </div>
        <div className={styles.socialMedia}>
          <div>
            <FooterIconV height="15px" alt="icon_v" />
          </div>
          <div>
            <FooterIconYoutube height="13px" alt="icon_youtube" />
          </div>
          <div>
            <FooterIconFaceBook height="15px" alt="icon_fb" />
          </div>
        </div>
      </div>

      <div className={styles.copyRight}>
        <p>
          Copyright © 2020 Cogplay. Made by Lumysoft. All right
          <br />
          reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
