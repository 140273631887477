import React, { useState } from 'react';
import CustomInput from '../Input';
import CustomButton from '../Button';
import { ReactComponent as CloseIcon } from '../../assets/images/closeIconModal.svg';
import modalImage from '../../../src/assets/images/modalHeaderImage.png';
import { Modal, Form } from 'antd';
import styles from './Checkout.module.scss';
import { useDispatch, useSelector } from 'react-redux';

//Action
import { userFeedback } from '../../store/actions/authActions';

const CustomModal = ({ setFeedBackModal, feedBackModal }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { loading } = useSelector((state) => state.auth);

  const [answers, setAnswers] = useState({
    answer1: '',
    answer2: '',
    answer3: '',
    answer4: '',
    answer5: '',
  });

  const handleChange = ({ target: { name, value } }) => {
    setAnswers({
      ...answers,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    const data = [
      { title: 'What did you like best about the Cogplay application?', text: answers.answer1 },
      {
        title: 'Did your student/students complete all 4 Units? (Which unit did they complete and upto which number?)',
        text: answers.answer2,
      },
      {
        title: 'How would you say Cogplay facilitated your student learning their basic numeracy?',
        text: answers.answer3,
      },
      { title: 'Would you suggest Cogplay to a friend or colleague?', text: answers.answer4 },
      { title: 'What changes would you suggest for the Cogplay application?', text: answers.answer5 },
    ];

    const newData = {
      questions: data,
      cancelled: false,
    };

    dispatch(userFeedback(newData, callBack));
  };


  const callBack = () => {
    setFeedBackModal(false);
    setAnswers({
      answer1: '',
      answer2: '',
      answer3: '',
      answer4: '',
      answer5: '',
    });
    form.resetFields();
  };

  const handleCancel = (e) => {
    if (e.target.closest('.ant-modal-close')) {
      setFeedBackModal(false);
    }  }

  return (
    <>
      <Modal
        title={<img src={modalImage} alt={modalImage} className={styles.imgHeader} />}
        centered={false}
        open={feedBackModal}
        onCancel={handleCancel}
        className={styles.checkoutModalStyle}
        closeIcon={<CloseIcon height="20px" />}
        footer={null}
      >
        <div className={styles.mainContainer}>
          <div className={styles.paymentText}>
            <span>Feedback</span>
          </div>
          <div className={styles.formInput}>
            <Form onFinish={handleSubmit} autoComplete="off" colon={false} form={form}>
              <CustomInput
                label="What did you like best about the Cogplay application?"
                inputType="input"
                name="answer1"
                value={answers.answer1}
                onChange={handleChange}
                placeholder="Enter your answer"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your answer',
                    whitespace: true,
                  },
                ]}
              />
              <CustomInput
                label="Did your student/students complete all 4 Units? (Which unit did they complete and upto which number?)"
                inputType="input"
                name="answer2"
                value={answers.answer2}
                onChange={handleChange}
                placeholder="Enter your answer"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your answer',
                    whitespace: true,
                  },
                ]}
              />
              <CustomInput
                label="How would you say Cogplay facilitated your student learning their basic numeracy?"
                inputType="input"
                name="answer3"
                value={answers.answer3}
                onChange={handleChange}
                placeholder="Enter your answer"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your answer',
                    whitespace: true,
                  },
                ]}
              />
              <CustomInput
                label="Would you suggest Cogplay to a friend or colleague?"
                inputType="input"
                name="answer4"
                value={answers.answer4}
                onChange={handleChange}
                placeholder="Enter your answer"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your answer',
                    whitespace: true,
                  },
                ]}
              />
              <CustomInput
                label="What changes would you suggest for the Cogplay application?"
                inputType="input"
                name="answer5"
                value={answers.answer5}
                onChange={handleChange}
                placeholder="Enter your answer"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your answer',
                    whitespace: true,
                  },
                ]}
              />
              <div className={styles.paymentFooter}>
                <CustomButton type="primary" loading={loading} htmlType="submit">
                  Submit Feedback{' '}
                </CustomButton>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CustomModal;
