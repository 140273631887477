import React, { useState } from 'react';
import styles from './Profile.module.scss';
import { Form, Switch, Col, Row } from 'antd';
import CustomInput from '../../components/Input';
import CustomPassword from '../../components/InputPassword/InputPassword';
import { validatePassword } from '../../helpers/constants';
import CustomInputNumber from '../../components/InputNumber/inputNumber';
import CustomInputDropDown from '../../components/InputDropDown';
import CustomButton from '../../components/Button';
import { attemptGetSubUserProfile, attemptGetUserProfile, createSubProfile } from '../../store/actions/authActions';
import { editSubProfile } from '../../store/actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useReducerData } from '../../store/hooks';
import { notification } from 'antd';
import {
  getCountries,
  getStates,
  getCities,
  getCurrentCountry,
  getCurrentState,
  getCurrentCity,
  capitalizeWords,
} from '../../helpers/utility';
import { Country, State } from 'country-state-city';

const TeacherMenu = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const id = location.pathname.split('/');
  const { loading } = useSelector((state) => state.auth);
  const isUserFound = id[id.length - 1];
  const profileUserData = useReducerData('auth', 'profileUserData');
  let existingUser = null;

  if (/\d/.test(isUserFound)) {
    existingUser = profileUserData.find((user) => user.user.id === Number(isUserFound));
  }

  const [teacherFormData, setTeacherFormData] = useState({
    email: existingUser?.user.email || '',
    password: '',
    confirmPassword: '',
    name: existingUser?.user.name || '',
    first_name: existingUser?.first_name || '',
    age: existingUser?.age || '',
    grade: existingUser?.grade || '',
    country: (existingUser?.country && getCurrentCountry(existingUser?.country)) || '',
    state: (getCurrentState(existingUser?.state)?.name !== undefined ? getCurrentState(existingUser?.state) : {name:existingUser?.state}) || '',
    city: (getCurrentCity(existingUser?.city)?.name !== undefined ? getCurrentCity(existingUser?.city) : {name:existingUser?.city}) || '',
    school_district: (getCurrentCity(existingUser?.school_district).name !== undefined ? getCurrentCity(existingUser?.school_district) : {name:existingUser?.school_district}) || '',
    disability: existingUser?.disability || '',
    user_type: 'Student',
  });

  const handleChange = ({ target: { name, value } }) => {
    setTeacherFormData({
      ...teacherFormData,
      [name]: name === 'name' || name === 'first_name' || name === 'disability' ? capitalizeWords(value) : value,
    });
  };

  const handleSelection = (name, value, option) => {
    if (option) {
      const data = {
        name: name === 'country' || name === 'state' ? option?.children : value,
        value,
      };
      setTeacherFormData((prevData) => {
        if (name === 'country') {
          form.resetFields(['state', 'city', 'school_district']);
          return { ...prevData, [name]: data, state: null, city: null, school_district: null };
        } else if (name === 'state') {
          form.resetFields(['city', 'school_district']);
          return { ...prevData, [name]: data, city: null, school_district: null };
        } else {
          return { ...prevData, [name]: data };
        }
      });
    }
  };

  const handleSubmit = async () => {
    // if (existingUser) {
    //   delete teacherFormData['name'];
    //   delete teacherFormData['password'];
    //   delete teacherFormData['confirmPassword'];
    //   delete teacherFormData['email'];
    //   delete teacherFormData['user_type'];
    // }
    if (teacherFormData.age <= 100) {
      teacherFormData['country'] = teacherFormData?.country?.name
        ? teacherFormData?.country?.name
        : teacherFormData?.country;
      teacherFormData['state'] = teacherFormData?.state?.name ? teacherFormData?.state?.name : teacherFormData?.state;
      teacherFormData['city'] = teacherFormData?.city?.name ? teacherFormData?.city?.name : teacherFormData?.city;
      teacherFormData['school_district'] = teacherFormData?.school_district?.name
        ? teacherFormData?.school_district?.name
        : teacherFormData?.school_district;

      existingUser
        ? await dispatch(editSubProfile(existingUser.id, teacherFormData, callBack, 'student'))
        : await dispatch(createSubProfile(teacherFormData, callBack, 'student'));
    } else {
      notification['error']({
        message: 'Age should not be grater then 100',
      });
    }
  };
  const callBack = () => {
    navigate('/profile');
    setTeacherFormData({
      email: '',
      password: '',
      confirmPassword: '',
      name: '',
      first_name: '',
      age: '',
      grade: '',
      country: '',
      state: '',
      city: '',
      school_district: '',
      disability: '',
      user_type: 'Student',
    });
    form.resetFields();
    dispatch(attemptGetSubUserProfile());
    dispatch(attemptGetUserProfile());
  };


  return (
    <>
      <div className={styles.formContainer}>
        <div className={styles.formInput}>
          <Form colon={false} onFinish={handleSubmit} autoComplete="off" form={form}>
            {/* {!existingUser && ( */}
            <>
              <CustomInput
                label="Full Student's Name"
                inputType="input"
                name="name"
                value={teacherFormData.name}
                defaultValue={teacherFormData.name}
                onChange={handleChange}
                placeholder="Enter your full student's name"
                maxLength={50}
                rules={
                  teacherFormData.name === '' && [
                    {
                      required: true,
                      message: "Please enter your student's name",
                      whitespace: true,
                    },
                  ]
                }
              // allowClear
              />

              <CustomInput
                label="Student's Email"
                inputType="email"
                name="email"
                value={teacherFormData.email}
                defaultValue={teacherFormData.email}
                onChange={handleChange}
                placeholder="Enter your student's email"
                rules={
                  teacherFormData.email === '' && [
                    {
                      required: true,
                      message: "Please enter your student's email!",
                    },
                    {
                      type: 'email',
                      message: 'Please enter valid E-mail!',
                    },
                  ]
                }
              // allowClear
              />
              <CustomPassword
                label={existingUser ? 'Change Password' : 'Password'}
                inputType="password"
                name="password"
                value={teacherFormData.password}
                onChange={handleChange}
                placeholder="Enter your password"
                rules={
                  !existingUser && [
                    ({ getFieldValue }) => ({
                      validator() {
                        if (!validatePassword(getFieldValue('password'))) {
                          if (!getFieldValue('password')) {
                            return Promise.reject(new Error('Please enter password'));
                          }
                          return Promise.reject(new Error('Please enter strong password'));
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]
                }
              // allowClear
              />
              <CustomPassword
                label={existingUser ? 'Change Confirm Password' : 'Confirm Password'}
                inputType="password"
                name="confirmPassword"
                value={teacherFormData.confirmPassword}
                onChange={handleChange}
                placeholder="Confirm password"
                dependencies={['password']}
                rules={
                  teacherFormData?.password && [
                    {
                      required: true,
                      message: 'Please enter confirm password',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('confirm password does not match with password'));
                      },
                    }),
                  ]
                }
              // allowClear
              />
              {/* <hr /> */}
            </>
            {/* )} */}

            <CustomInput
              label="Student's First Name"
              inputType="input"
              name="first_name"
              value={teacherFormData.first_name}
              defaultValue={teacherFormData.first_name}
              onChange={handleChange}
              placeholder="Enter first name"
              rules={
                teacherFormData.first_name === '' && [
                  {
                    required: true,
                    message: 'Please enter first name',
                    whitespace: true,
                  },
                ]
              }
            // allowClear={false}
            />

            <CustomInputNumber
              label="Student's Age"
              name="age"
              value={teacherFormData.age}
              defaultValue={teacherFormData.age}
              onChange={handleChange}
              placeholder="Enter Age"
              type="number"
              maxLength={true}
              rules={
                teacherFormData.age === '' && [
                  {
                    type: 'number',
                    required: true,
                    message: 'Please enter Age',
                    whitespace: true,
                  },
                ]
              }
            />

            <CustomInput
              label="Student's Grade"
              inputType="input"
              name="grade"
              value={teacherFormData.grade}
              defaultValue={teacherFormData.grade}
              onChange={handleChange}
              placeholder="Enter Grade"
              rules={
                teacherFormData.grade === '' && [
                  {
                    required: true,
                    message: 'Please enter grade',
                    whitespace: true,
                  },
                ]
              }
            // allowClear
            />
            <CustomInputDropDown
              label="Student's Country"
              data={getCountries && getCountries}
              placeholder="Enter Country"
              name="country"
              selectedValue={teacherFormData?.country?.value}
              onChange={(e, option) => handleSelection('country', e, option)}
              rules={
                teacherFormData.country === '' && [
                  {
                    required: true,
                    message: 'Please enter country',
                    whitespace: true,
                  },
                ]
              }
            />

            {getStates(teacherFormData?.country?.value).length > 0 &&
              <CustomInputDropDown
                label="Student's State/Province"
                data={getStates(teacherFormData?.country?.value) && getStates(teacherFormData?.country?.value)}
                placeholder="Enter State/Province"
                name="state"
                selectedValue={teacherFormData?.state?.value}
                onChange={(e, option) => handleSelection('state', e, option)}
                rules={
                  !!getStates(teacherFormData?.country?.value)?.length &&
                  (teacherFormData?.state === '' || teacherFormData?.state === null) && [
                    {
                      required: true,
                      message: 'Please enter state',
                      whitespace: true,
                    },
                  ]
                }
              />}
            {getCities(teacherFormData?.country?.value, teacherFormData?.state?.value)?.length > 0 &&
              <CustomInputDropDown
                label="Student's city"
                data={
                  getCities(teacherFormData?.country?.value, teacherFormData?.state?.value) &&
                  getCities(teacherFormData?.country?.value, teacherFormData?.state?.value)
                }
                placeholder="Enter city"
                name="city"
                selectedValue={teacherFormData?.city?.value}
                onChange={(e, option) => handleSelection('city', e, option)}
                rules={
                  !!getCities(teacherFormData?.country?.value, teacherFormData?.state?.value)?.length &&
                  (teacherFormData.city === '' || teacherFormData.city === null) && [
                    {
                      required: true,
                      message: 'Please enter city',
                      whitespace: true,
                    },
                  ]
                }
              />}
            {(getCities(teacherFormData?.country?.value, teacherFormData?.state?.value).length > 0) &&
              <CustomInputDropDown
                label="Student's School District"
                data={
                  getCities(teacherFormData?.country?.value, teacherFormData?.state?.value) &&
                  getCities(teacherFormData?.country?.value, teacherFormData?.state?.value)
                }
                placeholder="School District"
                name="school_district"
                selectedValue={teacherFormData.school_district?.value}
                onChange={(e, option) => handleSelection('school_district', e, option)}
                rules={
                  !!getCities(teacherFormData?.country?.value, teacherFormData?.state?.value)?.length &&
                  (teacherFormData.school_district === '' || teacherFormData.school_district === null) && [
                    {
                      required: true,
                      message: 'Please select district',
                      whitespace: true,
                    },
                  ]
                }
              />}


            {(!getStates(teacherFormData?.country?.value)?.length > 0 && teacherFormData?.country?.name) && <CustomInput
              label="Student's State/Province"
              inputType="input"
              name="state"
              value={teacherFormData?.state?.name}
              defaultValue={teacherFormData.state?.name}
              onChange={handleChange}
              placeholder="Enter State/Province"
            />}
            {(!getCities(teacherFormData?.country?.value, teacherFormData?.state?.value)?.length > 0 && teacherFormData?.state) &&
              <CustomInput
                label="Student's city"
                inputType="input"
                name="city"
                value={teacherFormData?.city?.value}
                defaultValue={teacherFormData.city?.name}
                onChange={handleChange}
                placeholder="Enter city" />
            }
            {(!getCities(teacherFormData?.country?.value, teacherFormData?.state?.value)?.length > 0 && teacherFormData?.city) && <CustomInput
              label="Student's School District"
              inputType="input"
              name="school_district"
              value={teacherFormData?.school_district?.value}
              defaultValue={teacherFormData.school_district?.name}
              onChange={handleChange} 
              placeholder="School District" />}
            <CustomInput
              label="Specific Diagnosis, Suspected Learning Disability"
              inputType="input"
              name="disability"
              value={teacherFormData.disability}
              defaultValue={teacherFormData.disability}
              onChange={handleChange}
              placeholder="Enter diagnosis"
              rules={
                teacherFormData.disability === '' && [
                  {
                    required: true,
                    message: 'Please select dignosis',
                    whitespace: true,
                  },
                ]
              }
            // allowClear
            />

            {/* <CustomButton type="link" onClick={() => alert('Add student')}>
              <AddIcon height="15px" style={{ marginTop: '4px', paddingRight: '15px' }} /> Add Student
            </CustomButton>*/}

            <CustomButton loading={loading} type="primary" htmlType="submit">
              {existingUser ? 'Edit Profile' : 'Create Profile'}
            </CustomButton>
          </Form>
        </div>
      </div>
    </>
  );
};

export default TeacherMenu;
