export const TabLoginArray = [
  {
    TabName: 'Home',
    id: 1,
    pathName: '/',
  },
  {
    TabName: 'Pricing',
    id: 2,
    pathName: '/pricing',
  },
  {
    TabName: 'Login/',
    id: 3,
    pathName: '/login',
  },
  {
    TabName: 'Signup',
    id: 5,
    pathName: '/signup',
  },
];
export const TabData = [
  {
    TabName: 'Home',
    id: 1,
    pathName: '/',
  },
  {
    TabName: 'Dashboard',
    id: 1.1,
    pathName: '/summary',
  },
  {
    TabName: 'Pricing',
    id: 2,
    pathName: '/pricing',
  },
  {
    TabName: 'Settings',
    id: 3,
    pathName: '/settings',
  },
  {
    TabName: 'Profile',
    id: 4,
    pathName: '/profile',
  },
];

export const AdminTabData = [
  {
    TabName: 'Home',
    id: 1,
    pathName: '/',
  },
  {
    TabName: 'Dashboard',
    id: 1.1,
    pathName: '/dashboard',
  },
  {
    TabName: 'Pricing',
    id: 2,
    pathName: '/pricing',
  },
  {
    TabName: 'Profile',
    id: 3,
    pathName: '/profile',
  },
];

export const ChildTabData = [
  {
    TabName: 'Home',
    id: 1,
    pathName: '/',
  },
  {
    TabName: 'Profile',
    id: 3,
    pathName: '/profile',
  },
];

export const CheckUrlTabData = ['/pricing', '/profile'];
