import Cookies from 'js-cookie';
import { attemptSetResumeQuiz } from '../store/actions/quizActions';
import { notification } from 'antd';
import moment from 'moment';
import { Country, State, City } from 'country-state-city';
import { divisionColourCodedData } from '../pages/Quiz/ColourCoded/ColourCodedData';

export const isLoggedIn = () => {
  const token = sessionStorage.getItem('token') || Cookies.get('token');
  return !!token;
};

export const randomNumber = () => {
  return Math.floor(Math.random() * 10);
};

export const getRandomArbitrary = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
export const getRandomValues = (key, min, max) => {
  if (key === 0) {
    return Math.floor(Math.random() * 10);
  } else if (key === 1) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * 21);
  } else {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * 13);
  }
};

const titles = [
  'Zeros',
  'Ones',
  'Twos',
  'Threes',
  'Fours',
  'Fives',
  'Sixes',
  'Sevens',
  'Eights',
  'Nines',
  'Tens',
  'Elevens',
  'Twelves',
  'Thirteens',
  'Fourteens',
  'Fifteens',
  'Sixteens',
  'Seventeens',
  'Eighteens',
  'Nineteens',
  'Twenties',
];
export const quizTitle = (index, tens) => {
  if (tens) {
    if (index === 11 || index === 12) {
      return getMergeTitle[0];
    }
    if (index === 13 || index === 14) {
      return getMergeTitle[1];
    }
    if (index >= 15 && index <= 20) {
      return getMergeTitle[2];
    }
  } else {
    return titles[index];
  }
};

const getMergeTitle = ['Eleven-Twelve', 'Thirteens-Fourteens', 'Fifteens-Twenties'];

export const getTitle = (params, index, tens) => {
  switch (params) {
    case 0:
      return `${quizTitle(index)} Addition Quiz`;
    case 1:
      return `${quizTitle(index)} Subtraction Quiz`;
    case 2:
      return `${quizTitle(index)} Multiplication Quiz`;
    case 3:
      return `${quizTitle(index)} Division Quiz`;
    default:
      return `Addition Quiz`;
  }
};

export const signContent = (tabKey) => {
  switch (tabKey) {
    case '0':
      return <span>+</span>;
    case '1':
      return <span>-</span>;
    case '2':
      return <span>x</span>;
    case '3':
      return <span>÷</span>;
    default:
      return <span>+</span>;
  }
};

export const checkAnswers = (tabKey, questionKey, randomNumberText, answer) => {
  switch (tabKey) {
    case '0':
      return questionKey + randomNumberText === answer;
    case '1':
      return questionKey - randomNumberText === answer;
    case '2':
      return questionKey * randomNumberText === answer;
    case '3':
      const numberValue = {
        NaN: 0,
      };
      const zerorValue = parseInt(questionKey / randomNumberText)?.toFixed(0);
      return numberValue[zerorValue] === undefined
        ? parseInt(zerorValue) === answer
        : numberValue[zerorValue] === answer;
    default:
      return questionKey + randomNumberText === answer;
  }
};

export const unitName = (tabKey) => {
  switch (tabKey) {
    case '0':
      return 'addition';
    case '1':
      return 'subtraction';
    case '2':
      return 'multiplication';
    case '3':
      return 'division';
    default:
      return 'addition';
  }
};

const countMints = (durationSeconds, durationMinutes) => {
  return durationSeconds === 60
    ? Number(durationMinutes) + 1 < 10
      ? '0' + String(Number(durationMinutes) + 1)
      : Number(durationMinutes) + 1
    : durationMinutes;
};

const secondTime = (durationSeconds) => {
  return durationSeconds === 60 ? '00' : durationSeconds;
};

export const setDuration = (ref, totalTime) => {
  const [minutes, seconds] = ref === undefined || ref === null ? ['00', '00'] : ref?.split(':');
  let durationMinutes =
    totalTime > 10 && minutes < 5 ? 14 - minutes : (!totalTime ? 4 - minutes : totalTime - 1 - minutes);
  const durationSeconds = 60 - seconds >= 10 ? 60 - seconds : '0' + (60 - seconds);
  durationMinutes = durationMinutes <= 9 ? '0' + durationMinutes : durationMinutes
  return countMints(durationSeconds, durationMinutes) + ':' + secondTime(durationSeconds);
};

export const titleContent = (tabKey) => {
  switch (tabKey) {
    case '0':
      return 'Colour Coded Addition Memory Cards';
    case '1':
      return 'Colour Coded Subtraction Memory Cards';
    case '2':
      return 'Colour Coded Multiplication Memory Cards';
    case '3':
      return 'Colour Coded Division Memory Cards';
    default:
      return 'Colour Coded Addition Memory Cards';
  }
};

export const colourCodedAnswer = (number1, number2, sign) => {
  switch (sign) {
    case '0':
      return number1 + number2;
    case '1':
      const sub = number1 > number2 ? number1 - number2 : number2 - number1;
      return sub;
    case '2':
      return number1 * number2;
    case '3':
      const data = number2 === 0 ? 0 : number1 / number2;
      return data;

    default:
      return number1 + number2;
  }
};

export const updateQuizPayload = (
  orignalData,
  tabKey,
  questionKey,
  subKey,
  duration,
  wrongAnswer,
  correctAnswer,
  isFinal,
  totalQuestions,
  updateQuizData,
) => {
  let tempQuizData = orignalData;
  let tabIndex = 0;
  let parentKey = 0;
  let childKey = 0;

  const user = JSON.parse(localStorage.getItem('user'));
  if (Object.keys(orignalData).length > 0) {
    tabIndex = orignalData.findIndex((tab, index) => index === +tabKey);
    parentKey = orignalData[tabIndex].quiz.findIndex((quizData, index) => index === questionKey);
    childKey = orignalData[tabIndex].quiz[parentKey].data.findIndex((data, index) => index === subKey);

    tempQuizData = orignalData;
  }

  const updateTabValue = tempQuizData[tabIndex].quiz[parentKey];

  updateTabValue['subKey'] = isFinal ? 0 : childKey + 1;
  updateTabValue['tabKey'] = tabIndex;
  const updateQuiz = updateTabValue.data[childKey];

  correctAnswer !== undefined ? (updateQuiz.correctAnswer = correctAnswer) : (updateQuiz.correctAnswer = 1);
  wrongAnswer !== undefined ? (updateQuiz.wrongAnswer = wrongAnswer) : (updateQuiz.wrongAnswer = 0);
  duration ? (updateQuiz.duration = duration) : (updateQuiz.duration = '');
  if (totalQuestions) {
    updateQuiz.questions = totalQuestions;
  }

  if (isFinal) {
    updateTabValue['isDisable'] = false;
    const percentage = (updateQuiz?.correctAnswer / totalQuestions) * 100;
    if (percentage >= 90) {
      const updateNextQuiz = tempQuizData[tabIndex].quiz[parentKey + 1];
      if (typeof updateNextQuiz !== 'undefined') {
        updateNextQuiz['isDisable'] = false;
    }    
      updateQuizData.subKey = 0;
    }
  }
  const activeActivity = {
    category: tabIndex,
    sub_category: parentKey,
    data: updateQuiz,
  };

  return {
    tempQuizData,
    activity: user?.user_type === 'Children' || user?.user_type === 'Student' ? activeActivity : false,
  };
};

export const updateResumeQuiz = (subKey, elements, key, tabKey, currentQuizKey, percentage) => {
  if (subKey === elements[key].data.length - 1) {
    if (percentage) {
      if (percentage < 90) {
        const updateQuizData = currentQuizKey[+tabKey];
        updateQuizData.subKey = 0;
        return updateQuizData;
      } else {
        const updateQuizData = currentQuizKey[+tabKey];
        updateQuizData.key = updateQuizData.key + 1;
        updateQuizData.subKey = 0;
        return updateQuizData;
      }
    } else {
      const updateQuizData = currentQuizKey[+tabKey];
      updateQuizData.key = updateQuizData.key + 1;
      updateQuizData.subKey = 0;
      return updateQuizData;
    }
  } else {
    const updateQuizData = currentQuizKey[+tabKey];
    updateQuizData.subKey = subKey + 1;
    return updateQuizData;
  }
};
export const getCurrentRoute = (url, data) => {
  const lastSegment = url?.split('/').pop();
  let indexValue = 0;
  data &&
    data?.map((item, index) => {
      const urlName = item?.redirectTo?.split('/')?.pop();
      const removeSpaces = urlName && urlName?.replace(/\s/g, '');
      if (removeSpaces === (lastSegment === 'brain-warrior-workout#true' ? 'brain-warrior-workout' : lastSegment)) {
        indexValue = index;
      }
    });
  return indexValue;
};

export const shuffleRecords = (records) => {
  const shuffledRecords = [...records];
  let currentIndex = shuffledRecords.length;
  let temporaryValue, randomIndex;
  // While there remain elements to shuffle
  while (currentIndex !== 0) {
    // Pick a remaining element
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    // And swap it with the current element
    temporaryValue = shuffledRecords[currentIndex];
    shuffledRecords[currentIndex] = shuffledRecords[randomIndex];
    shuffledRecords[randomIndex] = temporaryValue;
  }
  return shuffledRecords;
};

export function generateAddNumbers(count, key) {
  let numbers = [];
  let pair = [];

  for (let k = key; k > -1; --k) {
    for (let i = 0; i <= count; i++) {
      if (count === 41) {
        if (i <= 20) {
          numbers.push({ [k]: i });
        } else {
          numbers.push({ [count - i]: k });
        }
      } else {
        if (i <= 12) {
          numbers.push({ [k]: i });
        } else {
          numbers.push({ [i - 13]: k });
        }
      }
    }
    pair[k] = shuffleRecords(numbers);
    numbers = [];
  }
  const newData = [].concat(...pair);
  const newArray = shuffleRecords(newData);
  return newArray;
}

export function generateSubNumbers(key) {
  let response = [];
  let pairs = [];

  for (let k = key; k > -1; --k) {
    const count = 21;
    for (let i = 0; i < count; i++) {
      if (i <= k) {
        response.push({ [k]: i });
      } else {
        response.push({ [i]: k });
      }
    }
    pairs[k] = shuffleRecords(response);
    response = [];
  }
  const newData = [].concat(...pairs);
  const newArray = shuffleRecords(newData);
  return newArray;
}

export const generateNumbersDivisible = (key) => {
  let numbers = [];
  let keyPairs = [];

  for (let index = 0; index <= key; index++) {
    if (index === 0) {
      let tempRecord = [];
      for (let i = 0; i < 12; i++) {
        tempRecord.push({ [index]: i + 1 });
      }
      numbers = shuffleRecords(tempRecord);
    } else {
      for (let i = 0; keyPairs.length <= 12; i += index) {
        keyPairs.push({ [i]: index });
      }
      index > 1 && keyPairs.push({ [index]: 1 });
    }
    numbers = [...numbers, ...shuffleRecords(keyPairs)];

    keyPairs = [];
  }
  const newArray = shuffleRecords(numbers);
  return newArray;
};

export const convertSeconds = (totalSeconds) => {
  isNaN(totalSeconds) && parseInt(totalSeconds)
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  let result = '';
  if (minutes > 0) {
    result += minutes;
  }
  if (seconds > 0) {
    result += minutes > 0 ? '.' + (seconds < 10 ? '0' + seconds : seconds) : seconds;
  }
  if(minutes === 0 && seconds > 0){
    result +=  ' sec'
  }else if(minutes >= 2){
    result += ' mins'
  }else if(minutes < 2 && minutes > 0){
    result += ' min'
  }else if(minutes === 0 && seconds === 0){
    result = ''
  }
  return result.trim();
};

export const convertMinutesSeconds = (durationString) => {
  if (durationString) {
    const duration = moment.duration('00:' + durationString);
    const minutesValue = duration.minutes();
    const secondsValue = duration.seconds();
    const formate =
      minutesValue === 0
        ? `${secondsValue} sec`
        : secondsValue === 0
        ? `${minutesValue} mins`
        : `${minutesValue} mins ` + `${secondsValue} sec`;
    return formate;
  }
};

export const getCountries = Country.getAllCountries().map((item) => ({
  key: item.isoCode,
  label: item.name,
}));

export const getCurrentCountry = (name) => {
  const data = Country.getAllCountries().find((item) => item?.name === name);
  return { name: data?.name, value: data?.isoCode };
};

export const getCurrentState = (name) => {
  const data = State.getAllStates().find((item) => item?.name === name);
  return { name: data?.name, value: data?.isoCode };
};

export const getCurrentCity = (name) => {
  const data = City.getAllCities().find((item) => item?.name === name);
  return { name: data?.name, value: data?.name };
};

export const getStates = (code) => {
  return State.getStatesOfCountry(code).map((item) => ({
    key: item.isoCode,
    label: item.name,
  }));
};
export const getCities = (countryCode, stateCode) => {
  return City.getCitiesOfState(countryCode, stateCode).map((item) => ({
    key: item.name,
    label: item.name,
  }));
};

export const graphXAxisData = (data) => {
  const sortArray = Object.values(data)?.slice()?.sort((a, b) => a?.subscriber - b?.subscriber);
  return sortArray?.map(item => item?.region).filter(item => item !== undefined);
};

export const graphYAxisData = (data) => {
  const sortArray = Object.values(data)?.slice()?.sort((a, b) => a?.subscriber - b?.subscriber);
  return sortArray?.map(item => item?.subscriber).filter(item => item !== undefined);
};

const getChartValues = (data, chartData) => {
  if (chartData) {
    const indexNumber = chartData[1].findIndex((value) => value === data);
    const findData = chartData[0].find((value, index) => index === indexNumber);
    return findData;
  }
};
export const convertData = (dataType) => {
  return dataType && dataType?.map((item) => item && parseFloat(item).toFixed(1) + ' mins');
};

export const splitStrings = (inputArray) => {
  const unitNames = [];
  const numericValues = [];
  if (inputArray) {
    inputArray.forEach((item) => {
      const match = item.match(/([a-zA-Z\s]+)\s*(\d+)/);

      if (match) {
        const unitName = match[1].trim();
        const numericValue = match[2];

        unitNames.push(unitName);
        numericValues.push(numericValue);
      }
    });
    return { unitNames, numericValues };
  }
};

export const convertToMinutesAndSeconds = (decimalMinutes) => {
  let minutes = Math.floor(decimalMinutes);
  let seconds = Math.round((decimalMinutes % 1) * 60);
  return parseFloat(`${minutes}.${seconds > 9 ? seconds : '0' + seconds}`);
};

export const sortData = (unitNames, numericValues) => {
  if (unitNames && numericValues) {
    const combinedData = unitNames.map((unitName, index) => ({
      unitName,
      numericValue: numericValues[index],
    }));
    const sortedUnitNames = combinedData.map((item) => item.unitName);
    const sortedNumericValues = combinedData.map((item) => item.numericValue);

    return { sortedUnitNames, sortedNumericValues };
  }
};

export const compareData = (unitNames, numericValues) => {
  if (unitNames && numericValues) {
    const combinedData = unitNames.map((unitName, index) => ({
      unitName,
      numericValue: numericValues[index],
    }));
    const sortedUnitNames = combinedData.map((item) => item.unitName);
    const sortedNumericValues = combinedData.map((item) => item.numericValue);

    return { sortedUnitNames, sortedNumericValues };
  }
};

export const generateChartOption = (title, chartData, totalTime) => {
  return {
    title: {
      text: title,
    },
    grid: {
      left: '8%',
      right: '4%',
      bottom: '10%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: chartData && chartData?.units,
      name: 'Mastered Quizzes',
      nameLocation: 'middle',
      nameGap: 29,
      axisLabel: {
        formatter: function (value, index) {
          return `Number ${index}`;
        },
      },
    },
    yAxis: {
      type: 'value',
      name: `Time on Mastered Number`,
      nameLocation: 'middle',
      nameGap: 70,
      axisLabel: {
        formatter: '{value} mins',
      },
    },
    series: [
      {
        name: 'Mastered',
        type: 'line',
        data: chartData && sortData(chartData[0], chartData[1])?.sortedNumericValues,
      },
    ],
    tooltip: {
      trigger: 'item',
      formatter: function (params) {   
        const min = params.data > 1 ? 'mins' : 'min'      
        if (chartData) {
          const name = params?.dataIndex;
          const customText = `<b>Number ${name}</b> <br><b>Activity Time:</b> 
          ${params.data} ${min}`;
          return customText;
        }
      },
    },
  };
};

export const getMicrophonePermission = async () => {
  if ('MediaRecorder' in window) {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
      return true;
      // You can now use the stream to record audio or perform other actions.
    } catch (err) {
      if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
        // User denied microphone access

        alert('Microphone access denied by the user.');
        return false;
      } else if (err.name === 'NotFoundError' || err.name === 'DevicesNotFoundError') {
        // No microphone found
        alert('No microphone found.');
        return false;
      } else if (err.name === 'NotReadableError' || err.name === 'TrackStartError') {
        // Microphone is in use by another application
        alert('Microphone is already in use by another application.');
        return false;
      } else {
        // Other errors
        alert(`Error accessing the microphone: ${err.message}`);
        return false;
      }
    }
  } else {
    alert('The MediaRecorder API is not supported in your browser.');
    return false;
  }
};

// Function to generate the desired array
export const generateNumbersArray = (questionNumber) => {
  const inputList = divisionColourCodedData(questionNumber);
  let newArray = [];
  for (let i = 0; i < inputList.length; i++) {
    for (let j = 0; j < 2; j++) {
      if (questionNumber === 0) {
        newArray.push({
          num1: inputList[i],
          num2: questionNumber,
        });
      } else {
        newArray.push({
          num1: j === 1 ? (i === 1 ? questionNumber : inputList[i]) : i === 1 ? inputList[i] : questionNumber,
          num2: j === 1 ? (i === 1 ? inputList[i] : questionNumber) : i === 1 ? questionNumber : inputList[i],
        });
      }
    }
  }

  return newArray;
};

export const getOtherUserValue = (keys, values, clientArray) => {
  const keyValueArray = {};

  keys.forEach((key, index) => {
    const value = parseFloat(values[index]);

    if (clientArray.includes(key)) {
      if (keyValueArray[key] === undefined) {
        keyValueArray[key] = [value];
      } else {
        keyValueArray[key].push(value);
      }
    }
  });

  const clientArrayAverages = clientArray.map((key) => {
    const values = keyValueArray[key] || [];
    const average = values.length > 0 ? values.reduce((sum, value) => sum + value, 0) / values.length : 0;
    return { [key]: average.toFixed(6) };
  });

  const newData = clientArrayAverages.map((obj) => convertToMinutesAndSeconds(Object.values(obj)[0]));
  return newData;
};

export const capitalizeWords = (str) => {
  return str.replace(/\b\w/g, function (char) {
    return char.toUpperCase();
  });
};




export const minsToTime = (data)=>{
  const totalMinutes = data?.value;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = (totalMinutes % 60).toFixed(2);
  const time = hours > 0 ? `${hours} hr ${minutes} mins`: `${minutes} mins`;
  const name = data?.name;
  const customText = `<b>Name: </b> ${name}<br><b>Total Time:</b> ${time}`;
  return customText;
}

export const getTimeWithSeconds = (data)=>{
  const min = data.value > 1 ? 'mins' : 'min'      
  const customText = `<b>${data?.name}</b> <br><b>Activity Time:</b> 
  ${data.data} ${min}`;
  return customText;  
}