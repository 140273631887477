import React from 'react';
import { useSelector } from 'react-redux';
import ReactECharts from 'echarts-for-react';
import { getTimeWithSeconds } from '../../helpers/utility';

const BarChart = ({ title }) => {
  const data = useSelector((state) => state.admin.summaryChart);

  const convertData = (dataType) => {
    return dataType && dataType?.map((item) => item);
  };

  const option = {
    title: {
      text: title,
    },
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        return getTimeWithSeconds(params)
      },
    },
    grid: {
      left: '6%',
      right: '4%',
      bottom: '7%',
      containLabel: true,
    },

    xAxis: [
      {
        type: 'category',
        data: data?.filtered_data?.student_list,
        axisTick: {
          alignWithLabel: true,
        },
        name: 'Student Name',
        nameLocation: 'middle',
        nameGap: 30,
      },
    ],
    yAxis: {
      type: 'value',
      name: 'Total Time on App',
      nameLocation: 'middle',
      nameGap: 65,
      axisLabel: {
        formatter: '{value} mins',
      },
    },

    series: [
      {
        type: 'bar',
        barWidth: '60%',
        data: convertData(data?.filtered_data?.time_list),
      },
    ],
  };

  return (
    <>
      <ReactECharts option={option} />
    </>
  );
};

export default BarChart;
