import Request from './request';

export const getSubscriptions = async () => {
  return Request.call({
    url: 'api/v1/payment/get_products/',
    method: 'GET',
  });
};

export const addSubscriptions = async (payload) => {
  return Request.call({
    url: 'api/v1/payment/create_subscription/',
    method: 'POST',
    data: payload,
  });
};

export const resendCode = async () => {
  return Request.call({
    url: 'api/v1/payment/resend_code/',
    method: 'GET',
  });
};

export const verifyCode = async (payload) => {
  return Request.call({
    url: 'api/v1/payment/confirm_subscription/',
    method: 'POST',
    data: payload,
  });
};

export const cancelSubscription = async (payload) => {
  return Request.call({
    url: 'api/v1/payment/cancel_subscription/',
    method: 'POST',
    data: payload,
  });
};

export const checkSubscription = async () => {
  return Request.call({
    url: 'api/v1/payment/get_subscription/',
    method: 'GET',
  });
};
