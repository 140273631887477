import React from 'react';
import { Table, Pagination } from 'antd';
import styled from 'styled-components';

const CustomTable = ({
  columns,
  data,
  loading,
  nextPagination,
  onloadState,
  pageNumber,
  totalPages,
  expandedRowRender,
}) => {
  return (
    <>
      <TableStyled
        dataSource={data}
        rowKey={(element) => element}
        bordered={true}
        showHeader
        tableLayout="fixed"
        scroll={{
          x: 1000,
        }}
        pagination={false}
        loading={loading}
        columns={columns}
        expandable={{
          expandedRowRender,
        }}
      />
      <div className="d-flex justify-content-end mb-3 mr-2 mt-1">
        {onloadState && (
          <Pagination
            defaultCurrent={pageNumber}
            current={pageNumber}
            showSizeChanger={false}
            total={totalPages ? totalPages * 10 : 1}
            onChange={nextPagination}
          />
        )}
      </div>
    </>
  );
};

export default CustomTable;

const TableStyled = styled(Table)`
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 15px;
  .ant-table-content {
    scrollbar-color: darkgray lightgray !important;
    scrollbar-width: thin;
  }
  .ant-table-content::-webkit-scrollbar {
    height: 5px !important;
    width: 5px !important;
  }

  /* Track */
  .ant-table-content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  .ant-table-content::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 10px;
  }

  &.ant-table-wrapper {
    margin-bottom: 15px;
  }
  .ant-table-header {
    background-color: #4c4c4c !important;
  }
  table {
    border: collapse;
    min-width: 100% !important;
    border: 1px solid #4c4c4c14;
    td {
      font-size: 16px;
      color: #474862;
      @media (max-width: 1024px) {
        font-size: 14px !important;
      }
    }
    thead {
      outline: none !important;
      th {
        font-size: 16px;
        background-color: #000;
        text-align: center;
        @media (max-width: 1024px) {
          font-size: 14px !important;
        }
      }
    }
    thead > tr:first-child th:first-child {
      background-color: #000 !important;
    }
    .ant-table-cell-scrollbar {
      display: none;
    }
    td.ant-table-cell:last-child {
      // display: flex;
      white-space: break-spaces;
    }
    thead > tr:first-child {
      color: white !important;
      background-color: #4c4c4c;
      text-align: left;
      border: none;
      th:first-child {
        background-color: #4c4c4c;
        color: white;
        border: none;
      }
    }
    th {
      background-color: transparent;
      color: white;
      border: none;
    }
  }
  .ant-table-filter-column {
    display: flex;
    justify-content: space-between;
  }
  ul {
    display: flex;
  }
  li {
    list-style: none;
  }
  .ant-pagination-item-link {
    margin-top: 0px;
  }
  .antd-table th,
  td {
    white-space: break-spaces;
    // overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    justify-content: center;
  }
  .ant-table-placeholder {
    display: inherit;
  }
`;
