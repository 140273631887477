import React, { useEffect, useState } from 'react';
import styles from './Settings.module.scss';
import { SettingsData } from './SettingsData';
import { useNavigate, useParams } from 'react-router';
import TermsCondition from './Component/TermsCondition/TermsCondition';
import PrivacyPolicy from './Component/PrivacyPolicy/PrivacyPolicy';
import ChangePassword from './Component/ChangePassword/ChangePassword';
import Feedback from './Component/Feedback/Feedback';
import CustomButton from '../../components/Button';
import InformModal from '../Home/Modals/InformModal';
import { attemptGetUserProfile } from '../../store/actions/authActions';
import { useDispatch } from 'react-redux';

const Settings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const params = useParams();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalIndex, setModalIndex] = useState(0)

  useEffect(()=> {
    dispatch(attemptGetUserProfile());
  },[])

  const handleProfile = (path) => {
    navigate(`/settings/${path}`);
  };

  switch (params.card) {
    case 'terms':
      return <TermsCondition />;
    case 'privacy':
      return <PrivacyPolicy />;
    case 'change-password':
      return <ChangePassword />;
    case 'feedback':
      return <Feedback />;
    default:
      break;
  }

  return (
    <div className={styles.profileContainer}>
      <div className={styles.subContainer}>
        <div className={styles.settings}>Settings</div>
        <div className={styles.container}>
          {SettingsData.map((tab, index) => {
            return (
              <div className={styles.subComponentClass} key={index} onClick={() => handleProfile(tab.path)}>
                {tab.name}
              </div>
            );
          })}
        </div>
        <div className={styles.buttonWrapper}>
          <div className={styles.logoutButton}>
            <CustomButton
            onClick={()=> {
              setModalIndex('confirmation')
              setIsModalVisible('delete')
            }}
            >
              Delete Account
            </CustomButton>
          </div>
          <div>
            <CustomButton
            onClick={()=> {
              setModalIndex('confirmation')
              setIsModalVisible('logout')
            }}
            >
              Logout
            </CustomButton>
          </div>
        </div>
      </div>
      <InformModal isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} modalIndex={modalIndex} />
    </div>
  );
};

export default Settings;
