import Request from './request';

export const postLogin = async (payload) => {
  return Request.call({
    url: 'api/v1/login/',
    method: 'POST',
    data: payload,
  });
};
export const userLogout = async () => {
  return Request.call({
    url: 'api/v1/logout/',
    method: 'POST',
  });
};

export const postSignup = async (payload) => {
  return Request.call({
    url: 'api/v1/signup/',
    method: 'POST',
    data: payload,
  });
};
export const postResetPassword = async (payload) => {
  return Request.call({
    url: 'users/reset-password/send/',
    method: 'POST',
    data: payload,
  });
};
export const postVerifyPassword = async (payload) => {
  return Request.call({
    url: 'users/reset-password/verify/',
    method: 'POST',
    data: payload,
  });
};
export const changePasswordApi = async (payload) => {
  return Request.call({
    url: 'users/password/change/',
    method: 'POST',
    data: payload,
  });
};
export const sendFeedbackApi = async (payload) => {
  return Request.call({
    url: 'api/v1/feedback/',
    method: 'POST',
    data: payload,
  });
};

export const sendUserFeedbackApi = async (payload) => {
  return Request.call({
    url: 'api/v1/user-feedback/',
    method: 'POST',
    data: payload,
  });
};

export const getSubUserProfile = async () => {
  return Request.call({
    url: 'users/get-sub-accounts/',
    method: 'GET',
  });
};

export const postCreateSubProfile = async (payload) => {
  return Request.call({
    url: 'users/create/sub-profile/',
    method: 'POST',
    data: payload,
  });
};

export const postEditCurrentProfile = async (userId, payload) => {
  return Request.call({
    url: `users/profile/${userId}/`,
    method: 'PUT',
    data: payload,
  });
};

export const postEditSubProfile = async (userId, payload) => {
  return Request.call({
    url: `users/sub-accounts/${userId}/`,
    method: 'PATCH',
    data: payload,
  });
};

export const getCurrentUser = async () => {
  return Request.call({
    url: 'users/profile/',
    method: 'GET',
  });
};
export const updateCurrentUser = async (payload) => {
  return Request.call({
    url: `users/profile/${payload?.id}/`,
    method: 'PATCH',
    data: payload,
  });
};

export const getDocuments = async () => {
  return Request.call({
    url: 'users/upload-document/',
    method: 'GET',
  });
};

export const postDeleteAccount = async (payload) => {
  return Request.call({
    url: 'api/v1/delete_account/',
    method: 'POST',
    data: payload,
  });
};