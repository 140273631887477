import React from 'react';
import styles from './Header.module.scss';
import { ReactComponent as BackIcon } from '../../../assets/images/Union.svg';
import { useNavigate } from 'react-router-dom';

const Header = ({ title }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className={styles.titleContainer}>
      <div className={styles.backContainer}>
        <BackIcon onClick={handleBack} style={{ marginTop: '25px' }} />
      </div>
      {title}
    </div>
  );
};

export default Header;
