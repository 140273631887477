import React from 'react';
import styles from './header.module.scss';
import 'antd/dist/antd.css';
import { useNavigate, useLocation } from 'react-router';

const Header = () => {
  const navigate = useNavigate();
  const param = useLocation();
  const handleSignUp = () => {
    navigate('/signup');
  };

  const handleLogin = () => {
    navigate('/login');
  };
  const signUpClass = param.pathname === '/signup' ? styles.activeSignup : styles.signup;
  const loginClass = param.pathname === '/login' ? styles.activeLogin : styles.logIn;

  return (
    <>
      <div className={styles.WelcomeText}>Welcome to Cogplay!</div>
      <div className={styles.selectForm}>
        <div className={signUpClass} onClick={handleSignUp}>
          Sign Up
        </div>
        <div className={styles.or}>
          <span>|</span>
        </div>
        <div className={loginClass} onClick={handleLogin}>
          Log in
        </div>
      </div>
    </>
  );
};

export default Header;
