import React from 'react';
import styles from './TeachingModal.module.scss';
import NumberFamilies from '../../../../assets/images/HomePage/InformationModal/TeachingStrategiesModal/noFamilies.png';
import DotImage from '../../../../assets/images/HomePage/InformationModal/TeachingStrategiesModal/3dots.png';
import SpeedCounting from '../../../../assets/images/HomePage/InformationModal/TeachingStrategiesModal/speedCounting.png';
import GirlImage from '../../../../assets/images/HomePage/InformationModal/TeachingStrategiesModal/girl.png';
import FooterIcon from '../../../../../src/assets/images/HomePage/InformationModal/BrainModal/icon.png';

const TeachingModal = () => {
  return (
    <div>
      <div className={styles.headerContainer}>Teaching Strategies</div>
      <div className={styles.mainContainer}>
        <div className={styles.container1}>
          <div className={styles.imageWrapper}>
            <img src={NumberFamilies} alt={NumberFamilies} />
          </div>
          <div className={styles.textWrapper}>
            <p>
              "Chunking helps you overcome the natural limitations of your memory,and is therefore a very powerful trick
              for helping you to learn information and get it into memory."
            </p>
            <div className={styles.linkWrapper}>
              <a href="https://examstudyexpert.com/chunking-and-memory" target="blank">
                https://examstudyexpert.com/chunking-and-memory
              </a>
            </div>
          </div>
        </div>

        <div className={styles.container2}>
          <div className={styles.textWrapper}>
            <p>"Colour is believed to be the most important visual experience to human beings."</p>

            <span>
              Adams FM,Osgood CE. A cross-cultural study of the affective meaning of color.J Cross Cult
              Psychol.1973;4(2):135-156.
            </span>
          </div>
          <div className={styles.imageWrapper}>
            <img src={SpeedCounting} alt={SpeedCounting} />
          </div>
        </div>

        <div className={styles.container1}>
          <div className={styles.imageWrapper}>
            <img src={GirlImage} alt={GirlImage} />
          </div>
          <div className={styles.textWrapper1}>
            <p>
              "Spaced repetition leverages a memory phenomenon called the spacing effect,which describes how our brains
              learn more effectively when we space out our learning over time."
            </p>
            <div className={styles.linkWrapper}>
              <a href="https://collegeinfogeek.com/spaced-repetition-memory-technique" target="blank">
                https://collegeinfogeek.com/spaced-repetition-memory-technique
              </a>
            </div>
          </div>
        </div>

        <div className={styles.footerContainer}>
          <img src={FooterIcon} alt={FooterIcon} />
        </div>
      </div>
    </div>
  );
};

export default TeachingModal;
