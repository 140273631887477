import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer } from 'antd';

// Styles
import styles from '../HeaderLayout.module.scss';
import homeStyles from '../HomeHeaderLayout.module.scss';

// Components
import NavigationLink from '../NavigationLink/NavigationLink';

// Assets
import { ReactComponent as ProfileIcon } from '../../../../assets/images/profileIcon.svg';
import { ReactComponent as ManuIcon } from '../../../../assets/images/ManuIcon.svg';
import { ReactComponent as CogPlayLogo } from '../../../../assets/images/COGPLAYLOGO.svg';
import { ReactComponent as BackIcon } from '../../../../assets/images/Union.svg';

//Actions
import { doLogout } from '../../../../store/actions/authActions';

import { isLoggedIn } from '../../../../helpers/utility';
import LogoutOutlined from '@ant-design/icons/lib/icons/LogoutOutlined';
import Tooltip from 'antd/es/tooltip';
import { loginUserData } from '../../../../store/hooks';
import InformModal from '../../../../pages/Home/Modals/InformModal';

const Tab = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);
  const [isLogin, setIsLogin] = useState(isLoggedIn());
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalIndex, setModalIndex] = useState(0);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    setIsLogin(isLoggedIn());
  }, [user]);
  const showDrawer = () => {
    setVisible(true);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const onClose = () => {
    setVisible(false);
  };

  const useStyle = location.pathname === '/' ? homeStyles : styles;

  return (
    <div className={useStyle.tabContainer}>
      <div className={useStyle.tabText}>
        <NavigationLink manuList={false} onClose={onClose} />
        {isLogin && loginUserData() && (
          <>
            <div className={useStyle.profileIcon} onClick={() => {}}>
              <ProfileIcon width="37px" />
            </div>

            <div
              className={useStyle.logoutIcon}
              onClick={() => {
                setIsModalVisible('logout');
                setModalIndex('confirmation');
              }}
            >
              <Tooltip placement="bottom" title="Logout">
                <LogoutOutlined />
              </Tooltip>
            </div>
          </>
        )}
      </div>
      <div className={useStyle.manuContainer}>
        {location.pathname !== '/' && <BackIcon onClick={handleBack} style={{ height: '17px' }} />}
        <ManuIcon alt="something went wrong" onClick={showDrawer} />
      </div>
      <div>
        <Drawer placement="right" onClose={onClose} visible={visible} className={useStyle.drawerClass}>
          <div className={useStyle.hederContainer}>
            <CogPlayLogo width="88px" />
          </div>
          <NavigationLink manuList={true} onClose={onClose} />
          {isLogin && loginUserData() && (
            <div
              className={styles.drawerLogout}
              onClick={() => {
                setIsModalVisible('logout');
                setModalIndex('confirmation');
              }}
            >
              Logout
            </div>
          )}
        </Drawer>
      </div>

      <InformModal isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} modalIndex={modalIndex} />
    </div>
  );
};
export default Tab;
