import React from "react"
import './svgStyles.scss'

export const ResultSnake=({style})=>{
return (
	<svg style={{"enableBackground":"new 0 0 800 600", ...style}} version="1.1" viewBox="0 0 800 600" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" xmlSpace="preserve">
	<g id="Layer_1">
	<rect class="st0" width="800" height="600"/>
	<g>
		<defs>
			<filter id="Adobe_OpacityMaskFilter" filterUnits="userSpaceOnUse" x="652.7" y="121.6" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="652.7" y="121.6" width="7" height="6.5" id="SVGID_1_">
			<g class="st1">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_1_" filterUnits="userSpaceOnUse" x="652.7" y="121.6" width="7" height="6.5">
						<feFlood  style={{floodColor:'white', floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="652.7" y="121.6" width="7" height="6.5" id="SVGID_1_">
					<g class="st2">
					</g>
				</mask>
				<radialGradient id="SVGID_2_" cx="656.2344" cy="124.8613" r="3.3892" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}} />
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<ellipse class="st3" cx="656.2" cy="124.9" rx="3.5" ry="3.3"/>
			</g>
		</mask>
		<radialGradient id="SVGID_3_" cx="656.2344" cy="124.8613" r="3.3892" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<ellipse class="st4" cx="656.2" cy="124.9" rx="3.5" ry="3.3"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_2_" filterUnits="userSpaceOnUse" x="594.4" y="64.8" width="25.4" height="23.6">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="594.4" y="64.8" width="25.4" height="23.6" id="SVGID_4_">
			<g class="st5">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_3_" filterUnits="userSpaceOnUse" x="594.4" y="64.8" width="25.4" height="23.6">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="594.4" y="64.8" width="25.4" height="23.6" id="SVGID_4_">
					<g class="st6">
					</g>
				</mask>
				<radialGradient id="SVGID_5_" cx="607.1338" cy="76.6309" r="12.2749" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st7" d="M619.9,76.6c0,6.5-5.7,11.8-12.7,11.8c-7,0-12.7-5.3-12.7-11.8c0-6.5,5.7-11.8,12.7-11.8
					C614.2,64.8,619.9,70.1,619.9,76.6z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_6_" cx="607.1338" cy="76.6309" r="12.2749" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st8" d="M619.9,76.6c0,6.5-5.7,11.8-12.7,11.8c-7,0-12.7-5.3-12.7-11.8c0-6.5,5.7-11.8,12.7-11.8
			C614.2,64.8,619.9,70.1,619.9,76.6z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_4_" filterUnits="userSpaceOnUse" x="128" y="175.5" width="25.4" height="23.6">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="128" y="175.5" width="25.4" height="23.6" id="SVGID_7_">
			<g class="st9">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_5_" filterUnits="userSpaceOnUse" x="128" y="175.5" width="25.4" height="23.6">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="128" y="175.5" width="25.4" height="23.6" id="SVGID_7_">
					<g class="st10">
					</g>
				</mask>
				<radialGradient id="SVGID_8_" cx="140.7076" cy="187.3398" r="12.2754" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st11" d="M153.4,187.3c0,6.5-5.7,11.8-12.7,11.8c-7,0-12.7-5.3-12.7-11.8c0-6.5,5.7-11.8,12.7-11.8
					C147.7,175.5,153.4,180.8,153.4,187.3z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_9_" cx="140.7076" cy="187.3398" r="12.2754" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st12" d="M153.4,187.3c0,6.5-5.7,11.8-12.7,11.8c-7,0-12.7-5.3-12.7-11.8c0-6.5,5.7-11.8,12.7-11.8
			C147.7,175.5,153.4,180.8,153.4,187.3z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_6_" filterUnits="userSpaceOnUse" x="541.5" y="65.5" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="541.5" y="65.5" width="7" height="6.5" id="SVGID_10_">
			<g class="st13">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_7_" filterUnits="userSpaceOnUse" x="541.5" y="65.5" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="541.5" y="65.5" width="7" height="6.5" id="SVGID_10_">
					<g class="st14">
					</g>
				</mask>
				<radialGradient id="SVGID_11_" cx="544.9727" cy="68.7822" r="3.3893" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st15" d="M548.5,68.8c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
					C546.9,65.5,548.5,67,548.5,68.8z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_12_" cx="544.9727" cy="68.7822" r="3.3893" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st16" d="M548.5,68.8c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
			C546.9,65.5,548.5,67,548.5,68.8z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_8_" filterUnits="userSpaceOnUse" x="657" y="45.5" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="657" y="45.5" width="7" height="6.5" id="SVGID_13_">
			<g class="st17">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_9_" filterUnits="userSpaceOnUse" x="657" y="45.5" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="657" y="45.5" width="7" height="6.5" id="SVGID_13_">
					<g class="st18">
					</g>
				</mask>
				<radialGradient id="SVGID_14_" cx="660.5273" cy="48.7368" r="3.3893" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st19" d="M664,48.7c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
					C662.5,45.5,664,46.9,664,48.7z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_15_" cx="660.5273" cy="48.7368" r="3.3893" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st20" d="M664,48.7c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
			C662.5,45.5,664,46.9,664,48.7z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_10_" filterUnits="userSpaceOnUse" x="695.9" y="169.3" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="695.9" y="169.3" width="7" height="6.5" id="SVGID_16_">
			<g class="st21">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_11_" filterUnits="userSpaceOnUse" x="695.9" y="169.3" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="695.9" y="169.3" width="7" height="6.5" id="SVGID_16_">
					<g class="st22">
					</g>
				</mask>
				<radialGradient id="SVGID_17_" cx="699.4395" cy="172.5461" r="3.3899" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st23" d="M703,172.5c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
					C701.4,169.3,703,170.7,703,172.5z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_18_" cx="699.4395" cy="172.5461" r="3.3899" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st24" d="M703,172.5c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
			C701.4,169.3,703,170.7,703,172.5z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_12_" filterUnits="userSpaceOnUse" x="592.2" y="137.4" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="592.2" y="137.4" width="7" height="6.5" id="SVGID_19_">
			<g class="st25">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_13_" filterUnits="userSpaceOnUse" x="592.2" y="137.4" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="592.2" y="137.4" width="7" height="6.5" id="SVGID_19_">
					<g class="st26">
					</g>
				</mask>
				<radialGradient id="SVGID_20_" cx="595.6758" cy="140.7095" r="3.3897" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<ellipse class="st27" cx="595.7" cy="140.7" rx="3.5" ry="3.3"/>
			</g>
		</mask>
		<radialGradient id="SVGID_21_" cx="595.6758" cy="140.7095" r="3.3897" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<ellipse class="st28" cx="595.7" cy="140.7" rx="3.5" ry="3.3"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_14_" filterUnits="userSpaceOnUse" x="139.6" y="114.5" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="139.6" y="114.5" width="7" height="6.5" id="SVGID_22_">
			<g class="st29">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_15_" filterUnits="userSpaceOnUse" x="139.6" y="114.5" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="139.6" y="114.5" width="7" height="6.5" id="SVGID_22_">
					<g class="st30">
					</g>
				</mask>
				<radialGradient id="SVGID_23_" cx="143.0659" cy="117.7705" r="3.3895" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st31" d="M146.6,117.8c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
					C145,114.5,146.6,116,146.6,117.8z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_24_" cx="143.0659" cy="117.7705" r="3.3895" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st32" d="M146.6,117.8c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
			C145,114.5,146.6,116,146.6,117.8z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_16_" filterUnits="userSpaceOnUse" x="115.9" y="248.9" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="115.9" y="248.9" width="7" height="6.5" id="SVGID_25_">
			<g class="st33">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_17_" filterUnits="userSpaceOnUse" x="115.9" y="248.9" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="115.9" y="248.9" width="7" height="6.5" id="SVGID_25_">
					<g class="st34">
					</g>
				</mask>
				<radialGradient id="SVGID_26_" cx="119.4575" cy="252.1917" r="3.3901" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st35" d="M123,252.2c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
					C121.4,248.9,123,250.4,123,252.2z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_27_" cx="119.4575" cy="252.1917" r="3.3901" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st36" d="M123,252.2c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
			C121.4,248.9,123,250.4,123,252.2z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_18_" filterUnits="userSpaceOnUse" x="171.4" y="164" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="171.4" y="164" width="7" height="6.5" id="SVGID_28_">
			<g class="st37">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_19_" filterUnits="userSpaceOnUse" x="171.4" y="164" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="171.4" y="164" width="7" height="6.5" id="SVGID_28_">
					<g class="st38">
					</g>
				</mask>
				<radialGradient id="SVGID_29_" cx="174.877" cy="167.2944" r="3.3895" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st39" d="M178.4,167.3c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
					C176.8,164,178.4,165.5,178.4,167.3z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_30_" cx="174.877" cy="167.2944" r="3.3895" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st40" d="M178.4,167.3c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
			C176.8,164,178.4,165.5,178.4,167.3z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_20_" filterUnits="userSpaceOnUse" x="80.6" y="391.6" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="80.6" y="391.6" width="7" height="6.5" id="SVGID_31_">
			<g class="st41">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_21_" filterUnits="userSpaceOnUse" x="80.6" y="391.6" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="80.6" y="391.6" width="7" height="6.5" id="SVGID_31_">
					<g class="st42">
					</g>
				</mask>
				<radialGradient id="SVGID_32_" cx="84.0835" cy="394.8672" r="3.3902" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st43" d="M87.6,394.9c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
					C86,391.6,87.6,393.1,87.6,394.9z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_33_" cx="84.0835" cy="394.8672" r="3.3902" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st44" d="M87.6,394.9c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
			C86,391.6,87.6,393.1,87.6,394.9z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_22_" filterUnits="userSpaceOnUse" x="183.2" y="345.3" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="183.2" y="345.3" width="7" height="6.5" id="SVGID_34_">
			<g class="st45">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_23_" filterUnits="userSpaceOnUse" x="183.2" y="345.3" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="183.2" y="345.3" width="7" height="6.5" id="SVGID_34_">
					<g class="st46">
					</g>
				</mask>
				<radialGradient id="SVGID_35_" cx="186.6933" cy="348.607" r="3.3897" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st47" d="M190.2,348.6c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
					C188.6,345.3,190.2,346.8,190.2,348.6z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_36_" cx="186.6933" cy="348.607" r="3.3897" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st48" d="M190.2,348.6c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
			C188.6,345.3,190.2,346.8,190.2,348.6z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_24_" filterUnits="userSpaceOnUse" x="157.2" y="438.8" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="157.2" y="438.8" width="7" height="6.5" id="SVGID_37_">
			<g class="st49">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_25_" filterUnits="userSpaceOnUse" x="157.2" y="438.8" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="157.2" y="438.8" width="7" height="6.5" id="SVGID_37_">
					<g class="st50">
					</g>
				</mask>
				<radialGradient id="SVGID_38_" cx="160.7524" cy="442.0327" r="3.39" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st51" d="M164.3,442c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
					C162.7,438.8,164.3,440.2,164.3,442z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_39_" cx="160.7524" cy="442.0327" r="3.39" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st52" d="M164.3,442c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
			C162.7,438.8,164.3,440.2,164.3,442z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_26_" filterUnits="userSpaceOnUse" x="528.1" y="241.7" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="528.1" y="241.7" width="7" height="6.5" id="SVGID_40_">
			<g class="st53">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_27_" filterUnits="userSpaceOnUse" x="528.1" y="241.7" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="528.1" y="241.7" width="7" height="6.5" id="SVGID_40_">
					<g class="st54">
					</g>
				</mask>
				<radialGradient id="SVGID_41_" cx="531.6436" cy="244.9489" r="3.3904" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st55" d="M535.2,244.9c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
					C533.6,241.7,535.2,243.1,535.2,244.9z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_42_" cx="531.6436" cy="244.9489" r="3.3904" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st56" d="M535.2,244.9c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
			C533.6,241.7,535.2,243.1,535.2,244.9z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_28_" filterUnits="userSpaceOnUse" x="630.7" y="195.4" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="630.7" y="195.4" width="7" height="6.5" id="SVGID_43_">
			<g class="st57">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_29_" filterUnits="userSpaceOnUse" x="630.7" y="195.4" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="630.7" y="195.4" width="7" height="6.5" id="SVGID_43_">
					<g class="st58">
					</g>
				</mask>
				<radialGradient id="SVGID_44_" cx="634.2539" cy="198.6887" r="3.3898" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st59" d="M637.8,198.7c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
					C636.2,195.4,637.8,196.9,637.8,198.7z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_45_" cx="634.2539" cy="198.6887" r="3.3898" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st60" d="M637.8,198.7c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
			C636.2,195.4,637.8,196.9,637.8,198.7z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_30_" filterUnits="userSpaceOnUse" x="604.8" y="288.9" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="604.8" y="288.9" width="7" height="6.5" id="SVGID_46_">
			<g class="st61">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_31_" filterUnits="userSpaceOnUse" x="604.8" y="288.9" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="604.8" y="288.9" width="7" height="6.5" id="SVGID_46_">
					<g class="st62">
					</g>
				</mask>
				<radialGradient id="SVGID_47_" cx="608.3125" cy="292.1143" r="3.3895" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st63" d="M611.8,292.1c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
					C610.3,288.9,611.8,290.3,611.8,292.1z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_48_" cx="608.3125" cy="292.1143" r="3.3895" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st64" d="M611.8,292.1c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
			C610.3,288.9,611.8,290.3,611.8,292.1z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_32_" filterUnits="userSpaceOnUse" x="68.8" y="489.5" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="68.8" y="489.5" width="7" height="6.5" id="SVGID_49_">
			<g class="st65">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_33_" filterUnits="userSpaceOnUse" x="68.8" y="489.5" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="68.8" y="489.5" width="7" height="6.5" id="SVGID_49_">
					<g class="st66">
					</g>
				</mask>
				<radialGradient id="SVGID_50_" cx="72.2925" cy="492.7354" r="3.3902" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st67" d="M75.8,492.7c0,1.8-1.6,3.3-3.5,3.3s-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3S75.8,490.9,75.8,492.7z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_51_" cx="72.2925" cy="492.7354" r="3.3902" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st68" d="M75.8,492.7c0,1.8-1.6,3.3-3.5,3.3s-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3S75.8,490.9,75.8,492.7z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_34_" filterUnits="userSpaceOnUse" x="269.2" y="414" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="269.2" y="414" width="7" height="6.5" id="SVGID_52_">
			<g class="st69">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_35_" filterUnits="userSpaceOnUse" x="269.2" y="414" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="269.2" y="414" width="7" height="6.5" id="SVGID_52_">
					<g class="st70">
					</g>
				</mask>
				<radialGradient id="SVGID_53_" cx="272.7451" cy="417.271" r="3.3902" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st71" d="M276.3,417.3c0,1.8-1.6,3.3-3.5,3.3s-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3S276.3,415.5,276.3,417.3z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_54_" cx="272.7451" cy="417.271" r="3.3902" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st72" d="M276.3,417.3c0,1.8-1.6,3.3-3.5,3.3s-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3S276.3,415.5,276.3,417.3z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_36_" filterUnits="userSpaceOnUse" x="273.9" y="105.1" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="273.9" y="105.1" width="7" height="6.5" id="SVGID_55_">
			<g class="st73">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_37_" filterUnits="userSpaceOnUse" x="273.9" y="105.1" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="273.9" y="105.1" width="7" height="6.5" id="SVGID_55_">
					<g class="st74">
					</g>
				</mask>
				<radialGradient id="SVGID_56_" cx="277.4614" cy="108.3372" r="3.3901" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st75" d="M281,108.3c0,1.8-1.6,3.3-3.5,3.3s-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3S281,106.5,281,108.3z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_57_" cx="277.4614" cy="108.3372" r="3.3901" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st76" d="M281,108.3c0,1.8-1.6,3.3-3.5,3.3s-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3S281,106.5,281,108.3z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_38_" filterUnits="userSpaceOnUse" x="366" y="63.8" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="366" y="63.8" width="7" height="6.5" id="SVGID_58_">
			<g class="st77">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_39_" filterUnits="userSpaceOnUse" x="366" y="63.8" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="366" y="63.8" width="7" height="6.5" id="SVGID_58_">
					<g class="st78">
					</g>
				</mask>
				<radialGradient id="SVGID_59_" cx="369.4849" cy="67.0676" r="3.3901" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st79" d="M373,67.1c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
					C371.4,63.8,373,65.3,373,67.1z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_60_" cx="369.4849" cy="67.0676" r="3.3901" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st80" d="M373,67.1c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
			C371.4,63.8,373,65.3,373,67.1z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_40_" filterUnits="userSpaceOnUse" x="499" y="65.5" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="499" y="65.5" width="7" height="6.5" id="SVGID_61_">
			<g class="st81">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_41_" filterUnits="userSpaceOnUse" x="499" y="65.5" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="499" y="65.5" width="7" height="6.5" id="SVGID_61_">
					<g class="st82">
					</g>
				</mask>
				<radialGradient id="SVGID_62_" cx="502.5488" cy="68.7822" r="3.3897" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<ellipse class="st83" cx="502.5" cy="68.8" rx="3.5" ry="3.3"/>
			</g>
		</mask>
		<radialGradient id="SVGID_63_" cx="502.5488" cy="68.7822" r="3.3897" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<ellipse class="st84" cx="502.5" cy="68.8" rx="3.5" ry="3.3"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_42_" filterUnits="userSpaceOnUse" x="664.1" y="230.6" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="664.1" y="230.6" width="7" height="6.5" id="SVGID_64_">
			<g class="st85">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_43_" filterUnits="userSpaceOnUse" x="664.1" y="230.6" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="664.1" y="230.6" width="7" height="6.5" id="SVGID_64_">
					<g class="st86">
					</g>
				</mask>
				<radialGradient id="SVGID_65_" cx="667.6279" cy="233.8611" r="3.3903" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st87" d="M671.1,233.9c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
					C669.6,230.6,671.1,232.1,671.1,233.9z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_66_" cx="667.6279" cy="233.8611" r="3.3903" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st88" d="M671.1,233.9c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
			C669.6,230.6,671.1,232.1,671.1,233.9z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_44_" filterUnits="userSpaceOnUse" x="708.9" y="273" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="708.9" y="273" width="7" height="6.5" id="SVGID_67_">
			<g class="st89">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_45_" filterUnits="userSpaceOnUse" x="708.9" y="273" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="708.9" y="273" width="7" height="6.5" id="SVGID_67_">
					<g class="st90">
					</g>
				</mask>
				<radialGradient id="SVGID_68_" cx="712.4102" cy="276.3101" r="3.39" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st91" d="M715.9,276.3c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
					C714.3,273,715.9,274.5,715.9,276.3z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_69_" cx="712.4102" cy="276.3101" r="3.39" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st92" d="M715.9,276.3c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
			C714.3,273,715.9,274.5,715.9,276.3z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_46_" filterUnits="userSpaceOnUse" x="83" y="181.7" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="83" y="181.7" width="7" height="6.5" id="SVGID_70_">
			<g class="st93">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_47_" filterUnits="userSpaceOnUse" x="83" y="181.7" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="83" y="181.7" width="7" height="6.5" id="SVGID_70_">
					<g class="st94">
					</g>
				</mask>
				<radialGradient id="SVGID_71_" cx="86.4668" cy="184.981" r="3.3897" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st95" d="M90,185c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3C88.4,181.7,90,183.2,90,185z
					"/>
			</g>
		</mask>
		<radialGradient id="SVGID_72_" cx="86.4668" cy="184.981" r="3.3897" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st96" d="M90,185c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3C88.4,181.7,90,183.2,90,185z"
			/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_48_" filterUnits="userSpaceOnUse" x="204.4" y="73.2" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="204.4" y="73.2" width="7" height="6.5" id="SVGID_73_">
			<g class="st97">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_49_" filterUnits="userSpaceOnUse" x="204.4" y="73.2" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="204.4" y="73.2" width="7" height="6.5" id="SVGID_73_">
					<g class="st98">
					</g>
				</mask>
				<radialGradient id="SVGID_74_" cx="207.918" cy="76.5007" r="3.3898" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st99" d="M211.4,76.5c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
					C209.9,73.2,211.4,74.7,211.4,76.5z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_75_" cx="207.918" cy="76.5007" r="3.3898" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st100" d="M211.4,76.5c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
			C209.9,73.2,211.4,74.7,211.4,76.5z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_50_" filterUnits="userSpaceOnUse" x="253.9" y="125.1" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="253.9" y="125.1" width="7" height="6.5" id="SVGID_76_">
			<g class="st101">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_51_" filterUnits="userSpaceOnUse" x="253.9" y="125.1" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="253.9" y="125.1" width="7" height="6.5" id="SVGID_76_">
					<g class="st102">
					</g>
				</mask>
				<radialGradient id="SVGID_77_" cx="257.4414" cy="128.3826" r="3.3898" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st103" d="M261,128.4c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
					C259.4,125.1,261,126.6,261,128.4z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_78_" cx="257.4414" cy="128.3826" r="3.3898" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st104" d="M261,128.4c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
			C259.4,125.1,261,126.6,261,128.4z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_52_" filterUnits="userSpaceOnUse" x="242.3" y="552.4" width="25.4" height="23.6">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="242.3" y="552.4" width="25.4" height="23.6" id="SVGID_79_">
			<g class="st105">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_53_" filterUnits="userSpaceOnUse" x="242.3" y="552.4" width="25.4" height="23.6">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="242.3" y="552.4" width="25.4" height="23.6" id="SVGID_79_">
					<g class="st106">
					</g>
				</mask>
				<radialGradient id="SVGID_80_" cx="255.0029" cy="564.2339" r="12.2751" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st107" d="M267.7,564.2c0,6.5-5.7,11.8-12.7,11.8c-7,0-12.7-5.3-12.7-11.8c0-6.5,5.7-11.8,12.7-11.8
					C262,552.4,267.7,557.7,267.7,564.2z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_81_" cx="255.0029" cy="564.2339" r="12.2751" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st108" d="M267.7,564.2c0,6.5-5.7,11.8-12.7,11.8c-7,0-12.7-5.3-12.7-11.8c0-6.5,5.7-11.8,12.7-11.8
			C262,552.4,267.7,557.7,267.7,564.2z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_54_" filterUnits="userSpaceOnUse" x="658.1" y="337.8" width="17.2" height="16">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="658.1" y="337.8" width="17.2" height="16" id="SVGID_82_">
			<g class="st109">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_55_" filterUnits="userSpaceOnUse" x="658.1" y="337.8" width="17.2" height="16">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="658.1" y="337.8" width="17.2" height="16" id="SVGID_82_">
					<g class="st110">
					</g>
				</mask>
				<radialGradient id="SVGID_83_" cx="666.6797" cy="345.747" r="8.2922" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st111" d="M675.3,345.7c0,4.4-3.8,8-8.6,8s-8.6-3.6-8.6-8c0-4.4,3.8-8,8.6-8S675.3,341.3,675.3,345.7z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_84_" cx="666.6797" cy="345.747" r="8.2922" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st112" d="M675.3,345.7c0,4.4-3.8,8-8.6,8s-8.6-3.6-8.6-8c0-4.4,3.8-8,8.6-8S675.3,341.3,675.3,345.7z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_56_" filterUnits="userSpaceOnUse" x="252.6" y="361.4" width="17.8" height="16.6">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="252.6" y="361.4" width="17.8" height="16.6" id="SVGID_85_">
			<g class="st113">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_57_" filterUnits="userSpaceOnUse" x="252.6" y="361.4" width="17.8" height="16.6">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="252.6" y="361.4" width="17.8" height="16.6" id="SVGID_85_">
					<g class="st114">
					</g>
				</mask>
				<radialGradient id="SVGID_86_" cx="261.5556" cy="369.6763" r="8.6008" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st115" d="M270.5,369.7c0,4.6-4,8.3-8.9,8.3c-4.9,0-8.9-3.7-8.9-8.3c0-4.6,4-8.3,8.9-8.3
					C266.5,361.4,270.5,365.1,270.5,369.7z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_87_" cx="261.5556" cy="369.6763" r="8.6008" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st116" d="M270.5,369.7c0,4.6-4,8.3-8.9,8.3c-4.9,0-8.9-3.7-8.9-8.3c0-4.6,4-8.3,8.9-8.3
			C266.5,361.4,270.5,365.1,270.5,369.7z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_58_" filterUnits="userSpaceOnUse" x="125" y="516.2" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="125" y="516.2" width="7" height="6.5" id="SVGID_88_">
			<g class="st117">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_59_" filterUnits="userSpaceOnUse" x="125" y="516.2" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="125" y="516.2" width="7" height="6.5" id="SVGID_88_">
					<g class="st118">
					</g>
				</mask>
				<radialGradient id="SVGID_89_" cx="128.5581" cy="519.4263" r="3.39" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st119" d="M132.1,519.4c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
					C130.5,516.2,132.1,517.6,132.1,519.4z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_90_" cx="128.5581" cy="519.4263" r="3.39" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st120" d="M132.1,519.4c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
			C130.5,516.2,132.1,517.6,132.1,519.4z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_60_" filterUnits="userSpaceOnUse" x="142.8" y="398.6" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="142.8" y="398.6" width="7" height="6.5" id="SVGID_91_">
			<g class="st121">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_61_" filterUnits="userSpaceOnUse" x="142.8" y="398.6" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="142.8" y="398.6" width="7" height="6.5" id="SVGID_91_">
					<g class="st122">
					</g>
				</mask>
				<radialGradient id="SVGID_92_" cx="146.27" cy="401.9062" r="3.3902" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st123" d="M149.8,401.9c0,1.8-1.6,3.3-3.5,3.3s-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3S149.8,400.1,149.8,401.9z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_93_" cx="146.27" cy="401.9062" r="3.3902" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st124" d="M149.8,401.9c0,1.8-1.6,3.3-3.5,3.3s-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3S149.8,400.1,149.8,401.9z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_62_" filterUnits="userSpaceOnUse" x="448.3" y="235.3" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="448.3" y="235.3" width="7" height="6.5" id="SVGID_94_">
			<g class="st125">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_63_" filterUnits="userSpaceOnUse" x="448.3" y="235.3" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="448.3" y="235.3" width="7" height="6.5" id="SVGID_94_">
					<g class="st126">
					</g>
				</mask>
				<radialGradient id="SVGID_95_" cx="451.7949" cy="238.5779" r="3.3898" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st127" d="M455.3,238.6c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
					C453.7,235.3,455.3,236.8,455.3,238.6z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_96_" cx="451.7949" cy="238.5779" r="3.3898" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st128" d="M455.3,238.6c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
			C453.7,235.3,455.3,236.8,455.3,238.6z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_64_" filterUnits="userSpaceOnUse" x="550.9" y="189.1" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="550.9" y="189.1" width="7" height="6.5" id="SVGID_97_">
			<g class="st129">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_65_" filterUnits="userSpaceOnUse" x="550.9" y="189.1" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="550.9" y="189.1" width="7" height="6.5" id="SVGID_97_">
					<g class="st130">
					</g>
				</mask>
				<radialGradient id="SVGID_98_" cx="554.4053" cy="192.3174" r="3.3902" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st131" d="M557.9,192.3c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
					C556.3,189.1,557.9,190.5,557.9,192.3z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_99_" cx="554.4053" cy="192.3174" r="3.3902" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st132" d="M557.9,192.3c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
			C556.3,189.1,557.9,190.5,557.9,192.3z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_66_" filterUnits="userSpaceOnUse" x="467.7" y="441.7" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="467.7" y="441.7" width="7" height="6.5" id="SVGID_100_">
			<g class="st133">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_67_" filterUnits="userSpaceOnUse" x="467.7" y="441.7" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="467.7" y="441.7" width="7" height="6.5" id="SVGID_100_">
					<g class="st134">
					</g>
				</mask>
				<radialGradient id="SVGID_101_" cx="471.2344" cy="444.939" r="3.3897" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st135" d="M474.7,444.9c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
					C473.2,441.7,474.7,443.1,474.7,444.9z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_102_" cx="471.2344" cy="444.939" r="3.3897" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st136" d="M474.7,444.9c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
			C473.2,441.7,474.7,443.1,474.7,444.9z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_68_" filterUnits="userSpaceOnUse" x="487.6" y="364.4" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="487.6" y="364.4" width="7" height="6.5" id="SVGID_103_">
			<g class="st137">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_69_" filterUnits="userSpaceOnUse" x="487.6" y="364.4" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="487.6" y="364.4" width="7" height="6.5" id="SVGID_103_">
					<g class="st138">
					</g>
				</mask>
				<radialGradient id="SVGID_104_" cx="491.1006" cy="367.6514" r="3.39" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st139" d="M494.6,367.7c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
					C493,364.4,494.6,365.8,494.6,367.7z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_105_" cx="491.1006" cy="367.6514" r="3.39" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st140" d="M494.6,367.7c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
			C493,364.4,494.6,365.8,494.6,367.7z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_70_" filterUnits="userSpaceOnUse" x="299.9" y="514.1" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="299.9" y="514.1" width="7" height="6.5" id="SVGID_106_">
			<g class="st141">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_71_" filterUnits="userSpaceOnUse" x="299.9" y="514.1" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="299.9" y="514.1" width="7" height="6.5" id="SVGID_106_">
					<g class="st142">
					</g>
				</mask>
				<radialGradient id="SVGID_107_" cx="303.4385" cy="517.3418" r="3.3905" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<ellipse class="st143" cx="303.4" cy="517.3" rx="3.5" ry="3.3"/>
			</g>
		</mask>
		<radialGradient id="SVGID_108_" cx="303.4385" cy="517.3418" r="3.3905" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<ellipse class="st144" cx="303.4" cy="517.3" rx="3.5" ry="3.3"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_72_" filterUnits="userSpaceOnUse" x="402.5" y="467.8" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="402.5" y="467.8" width="7" height="6.5" id="SVGID_109_">
			<g class="st145">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_73_" filterUnits="userSpaceOnUse" x="402.5" y="467.8" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="402.5" y="467.8" width="7" height="6.5" id="SVGID_109_">
					<g class="st146">
					</g>
				</mask>
				<radialGradient id="SVGID_110_" cx="406.0488" cy="471.0815" r="3.3897" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st147" d="M409.6,471.1c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
					C408,467.8,409.6,469.3,409.6,471.1z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_111_" cx="406.0488" cy="471.0815" r="3.3897" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st148" d="M409.6,471.1c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
			C408,467.8,409.6,469.3,409.6,471.1z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_74_" filterUnits="userSpaceOnUse" x="435.9" y="503" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="435.9" y="503" width="7" height="6.5" id="SVGID_112_">
			<g class="st149">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_75_" filterUnits="userSpaceOnUse" x="435.9" y="503" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="435.9" y="503" width="7" height="6.5" id="SVGID_112_">
					<g class="st150">
					</g>
				</mask>
				<radialGradient id="SVGID_113_" cx="439.4219" cy="506.2539" r="3.39" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<ellipse class="st151" cx="439.4" cy="506.3" rx="3.5" ry="3.3"/>
			</g>
		</mask>
		<radialGradient id="SVGID_114_" cx="439.4219" cy="506.2539" r="3.39" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<ellipse class="st152" cx="439.4" cy="506.3" rx="3.5" ry="3.3"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_76_" filterUnits="userSpaceOnUse" x="322.7" y="461.4" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="322.7" y="461.4" width="7" height="6.5" id="SVGID_115_">
			<g class="st153">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_77_" filterUnits="userSpaceOnUse" x="322.7" y="461.4" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="322.7" y="461.4" width="7" height="6.5" id="SVGID_115_">
					<g class="st154">
					</g>
				</mask>
				<radialGradient id="SVGID_116_" cx="326.2002" cy="464.7104" r="3.3902" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st155" d="M329.7,464.7c0,1.8-1.6,3.3-3.5,3.3s-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3S329.7,462.9,329.7,464.7z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_117_" cx="326.2002" cy="464.7104" r="3.3902" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st156" d="M329.7,464.7c0,1.8-1.6,3.3-3.5,3.3s-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3S329.7,462.9,329.7,464.7z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_78_" filterUnits="userSpaceOnUse" x="525" y="282.5" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="525" y="282.5" width="7" height="6.5" id="SVGID_118_">
			<g class="st157">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_79_" filterUnits="userSpaceOnUse" x="525" y="282.5" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="525" y="282.5" width="7" height="6.5" id="SVGID_118_">
					<g class="st158">
					</g>
				</mask>
				<radialGradient id="SVGID_119_" cx="528.4648" cy="285.743" r="3.3896" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st159" d="M532,285.7c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
					C530.4,282.5,532,283.9,532,285.7z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_120_" cx="528.4648" cy="285.743" r="3.3896" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st160" d="M532,285.7c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
			C530.4,282.5,532,283.9,532,285.7z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_80_" filterUnits="userSpaceOnUse" x="556.1" y="264.4" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="556.1" y="264.4" width="7" height="6.5" id="SVGID_121_">
			<g class="st161">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_81_" filterUnits="userSpaceOnUse" x="556.1" y="264.4" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="556.1" y="264.4" width="7" height="6.5" id="SVGID_121_">
					<g class="st162">
					</g>
				</mask>
				<radialGradient id="SVGID_122_" cx="559.6016" cy="267.6265" r="3.39" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st163" d="M563.1,267.6c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
					C561.5,264.4,563.1,265.8,563.1,267.6z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_123_" cx="559.6016" cy="267.6265" r="3.39" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st164" d="M563.1,267.6c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
			C561.5,264.4,563.1,265.8,563.1,267.6z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_82_" filterUnits="userSpaceOnUse" x="201.3" y="290.9" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="201.3" y="290.9" width="7" height="6.5" id="SVGID_124_">
			<g class="st165">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_83_" filterUnits="userSpaceOnUse" x="201.3" y="290.9" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="201.3" y="290.9" width="7" height="6.5" id="SVGID_124_">
					<g class="st166">
					</g>
				</mask>
				<radialGradient id="SVGID_125_" cx="204.8594" cy="294.2109" r="3.3899" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st167" d="M208.4,294.2c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
					C206.8,290.9,208.4,292.4,208.4,294.2z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_126_" cx="204.8594" cy="294.2109" r="3.3899" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st168" d="M208.4,294.2c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
			C206.8,290.9,208.4,292.4,208.4,294.2z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_84_" filterUnits="userSpaceOnUse" x="243.3" y="338.3" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="243.3" y="338.3" width="7" height="6.5" id="SVGID_127_">
			<g class="st169">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_85_" filterUnits="userSpaceOnUse" x="243.3" y="338.3" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="243.3" y="338.3" width="7" height="6.5" id="SVGID_127_">
					<g class="st170">
					</g>
				</mask>
				<radialGradient id="SVGID_128_" cx="246.8296" cy="341.5317" r="3.39" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st171" d="M250.3,341.5c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
					C248.8,338.3,250.3,339.7,250.3,341.5z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_129_" cx="246.8296" cy="341.5317" r="3.39" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st172" d="M250.3,341.5c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
			C248.8,338.3,250.3,339.7,250.3,341.5z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_86_" filterUnits="userSpaceOnUse" x="267.4" y="305.1" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="267.4" y="305.1" width="7" height="6.5" id="SVGID_130_">
			<g class="st173">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_87_" filterUnits="userSpaceOnUse" x="267.4" y="305.1" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="267.4" y="305.1" width="7" height="6.5" id="SVGID_130_">
					<g class="st174">
					</g>
				</mask>
				<radialGradient id="SVGID_131_" cx="270.8911" cy="308.3608" r="3.39" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st175" d="M274.4,308.4c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
					C272.8,305.1,274.4,306.6,274.4,308.4z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_132_" cx="270.8911" cy="308.3608" r="3.39" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st176" d="M274.4,308.4c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
			C272.8,305.1,274.4,306.6,274.4,308.4z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_88_" filterUnits="userSpaceOnUse" x="207.6" y="477.3" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="207.6" y="477.3" width="7" height="6.5" id="SVGID_133_">
			<g class="st177">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_89_" filterUnits="userSpaceOnUse" x="207.6" y="477.3" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="207.6" y="477.3" width="7" height="6.5" id="SVGID_133_">
					<g class="st178">
					</g>
				</mask>
				<radialGradient id="SVGID_134_" cx="211.123" cy="480.5146" r="3.39" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st179" d="M214.6,480.5c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
					C213.1,477.3,214.6,478.7,214.6,480.5z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_135_" cx="211.123" cy="480.5146" r="3.39" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st180" d="M214.6,480.5c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
			C213.1,477.3,214.6,478.7,214.6,480.5z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_90_" filterUnits="userSpaceOnUse" x="504.7" y="478.7" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="504.7" y="478.7" width="7" height="6.5" id="SVGID_136_">
			<g class="st181">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_91_" filterUnits="userSpaceOnUse" x="504.7" y="478.7" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="504.7" y="478.7" width="7" height="6.5" id="SVGID_136_">
					<g class="st182">
					</g>
				</mask>
				<radialGradient id="SVGID_137_" cx="508.2393" cy="481.9677" r="3.3905" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st183" d="M511.8,482c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
					C510.2,478.7,511.8,480.2,511.8,482z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_138_" cx="508.2393" cy="481.9677" r="3.3905" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st184" d="M511.8,482c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
			C510.2,478.7,511.8,480.2,511.8,482z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_92_" filterUnits="userSpaceOnUse" x="360.8" y="472.8" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="360.8" y="472.8" width="7" height="6.5" id="SVGID_139_">
			<g class="st185">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_93_" filterUnits="userSpaceOnUse" x="360.8" y="472.8" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="360.8" y="472.8" width="7" height="6.5" id="SVGID_139_">
					<g class="st186">
					</g>
				</mask>
				<radialGradient id="SVGID_140_" cx="364.3594" cy="476.0718" r="3.3897" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st187" d="M367.9,476.1c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
					C366.3,472.8,367.9,474.3,367.9,476.1z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_141_" cx="364.3594" cy="476.0718" r="3.3897" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st188" d="M367.9,476.1c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
			C366.3,472.8,367.9,474.3,367.9,476.1z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_94_" filterUnits="userSpaceOnUse" x="452.9" y="431.5" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="452.9" y="431.5" width="7" height="6.5" id="SVGID_142_">
			<g class="st189">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_95_" filterUnits="userSpaceOnUse" x="452.9" y="431.5" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="452.9" y="431.5" width="7" height="6.5" id="SVGID_142_">
					<g class="st190">
					</g>
				</mask>
				<radialGradient id="SVGID_143_" cx="456.3828" cy="434.8022" r="3.3897" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st191" d="M459.9,434.8c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
					C458.3,431.5,459.9,433,459.9,434.8z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_144_" cx="456.3828" cy="434.8022" r="3.3897" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st192" d="M459.9,434.8c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
			C458.3,431.5,459.9,433,459.9,434.8z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_96_" filterUnits="userSpaceOnUse" x="462.3" y="478.7" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="462.3" y="478.7" width="7" height="6.5" id="SVGID_145_">
			<g class="st193">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_97_" filterUnits="userSpaceOnUse" x="462.3" y="478.7" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="462.3" y="478.7" width="7" height="6.5" id="SVGID_145_">
					<g class="st194">
					</g>
				</mask>
				<radialGradient id="SVGID_146_" cx="465.8164" cy="481.9677" r="3.3899" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st195" d="M469.3,482c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
					C467.8,478.7,469.3,480.2,469.3,482z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_147_" cx="465.8164" cy="481.9677" r="3.3899" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st196" d="M469.3,482c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
			C467.8,478.7,469.3,480.2,469.3,482z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_98_" filterUnits="userSpaceOnUse" x="340.8" y="492.9" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="340.8" y="492.9" width="7" height="6.5" id="SVGID_148_">
			<g class="st197">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_99_" filterUnits="userSpaceOnUse" x="340.8" y="492.9" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="340.8" y="492.9" width="7" height="6.5" id="SVGID_148_">
					<g class="st198">
					</g>
				</mask>
				<radialGradient id="SVGID_149_" cx="344.3398" cy="496.1172" r="3.39" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st199" d="M347.9,496.1c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
					C346.3,492.9,347.9,494.3,347.9,496.1z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_150_" cx="344.3398" cy="496.1172" r="3.39" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st200" d="M347.9,496.1c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
			C346.3,492.9,347.9,494.3,347.9,496.1z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_100_" filterUnits="userSpaceOnUse" x="47.6" y="115.3" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="47.6" y="115.3" width="7" height="6.5" id="SVGID_151_">
			<g class="st201">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_101_" filterUnits="userSpaceOnUse" x="47.6" y="115.3" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="47.6" y="115.3" width="7" height="6.5" id="SVGID_151_">
					<g class="st202">
					</g>
				</mask>
				<radialGradient id="SVGID_152_" cx="51.1289" cy="118.5198" r="3.3898" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st203" d="M54.6,118.5c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
					C53.1,115.3,54.6,116.7,54.6,118.5z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_153_" cx="51.1289" cy="118.5198" r="3.3898" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st204" d="M54.6,118.5c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
			C53.1,115.3,54.6,116.7,54.6,118.5z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_102_" filterUnits="userSpaceOnUse" x="70.4" y="62.6" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="70.4" y="62.6" width="7" height="6.5" id="SVGID_154_">
			<g class="st205">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_103_" filterUnits="userSpaceOnUse" x="70.4" y="62.6" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="70.4" y="62.6" width="7" height="6.5" id="SVGID_154_">
					<g class="st206">
					</g>
				</mask>
				<radialGradient id="SVGID_155_" cx="73.8906" cy="65.8885" r="3.3898" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st207" d="M77.4,65.9c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
					C75.8,62.6,77.4,64.1,77.4,65.9z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_156_" cx="73.8906" cy="65.8885" r="3.3898" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st208" d="M77.4,65.9c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
			C75.8,62.6,77.4,64.1,77.4,65.9z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_104_" filterUnits="userSpaceOnUse" x="108.5" y="74" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="108.5" y="74" width="7" height="6.5" id="SVGID_157_">
			<g class="st209">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_105_" filterUnits="userSpaceOnUse" x="108.5" y="74" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="108.5" y="74" width="7" height="6.5" id="SVGID_157_">
					<g class="st210">
					</g>
				</mask>
				<radialGradient id="SVGID_158_" cx="112.0503" cy="77.2503" r="3.3901" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st211" d="M115.6,77.2c0,1.8-1.6,3.3-3.5,3.3s-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3S115.6,75.4,115.6,77.2z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_159_" cx="112.0503" cy="77.2503" r="3.3901" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st212" d="M115.6,77.2c0,1.8-1.6,3.3-3.5,3.3s-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3S115.6,75.4,115.6,77.2z"/>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_106_" filterUnits="userSpaceOnUse" x="88.5" y="94" width="7" height="6.5">
				<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="88.5" y="94" width="7" height="6.5" id="SVGID_160_">
			<g class="st213">
				<defs>
					<filter id="Adobe_OpacityMaskFilter_107_" filterUnits="userSpaceOnUse" x="88.5" y="94" width="7" height="6.5">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="88.5" y="94" width="7" height="6.5" id="SVGID_160_">
					<g class="st214">
					</g>
				</mask>
				<radialGradient id="SVGID_161_" cx="92.0298" cy="97.2956" r="3.3901" gradientUnits="userSpaceOnUse">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.4724" style={{stopColor:"#808080"}}/>
					<stop  offset="0.8368" style={{stopColor:"#242424"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</radialGradient>
				<path class="st215" d="M95.5,97.3c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3
					C94,94,95.5,95.5,95.5,97.3z"/>
			</g>
		</mask>
		<radialGradient id="SVGID_162_" cx="92.0298" cy="97.2956" r="3.3901" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style={{stopColor:"#FCC300"}}/>
			<stop  offset="0.4356" style={{stopColor:"#DDCB6D"}}/>
			<stop  offset="0.8254" style={{stopColor:"#C2D2C9"}}/>
			<stop  offset="1" style={{stopColor:"#B8D5ED"}}/>
		</radialGradient>
		<path class="st216" d="M95.5,97.3c0,1.8-1.6,3.3-3.5,3.3c-1.9,0-3.5-1.5-3.5-3.3c0-1.8,1.6-3.3,3.5-3.3C94,94,95.5,95.5,95.5,97.3
			z"/>
	</g>
	<g class="st217">
		<path class="st218" d="M492.1,147.8c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C492.7,146.4,492.1,147,492.1,147.8z"/>
		<path class="st218" d="M459.9,122.6c0,0.7,0.6,1.3,1.4,1.3c0.7,0,1.4-0.6,1.4-1.3c0-0.7-0.6-1.4-1.4-1.4
			C460.5,121.3,459.9,121.9,459.9,122.6z"/>
		<path class="st218" d="M424.9,134.6c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C425.5,133.2,424.9,133.9,424.9,134.6z"/>
		<path class="st218" d="M447.8,143c0,0.7,0.6,1.4,1.3,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.3-1.4-1.3
			C448.4,141.6,447.8,142.2,447.8,143z"/>
		<path class="st218" d="M482.5,125c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C483.1,123.7,482.5,124.3,482.5,125z"/>
		<path class="st218" d="M530.4,139.4c0,0.7,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4c0-0.7-0.6-1.3-1.4-1.3S530.4,138.6,530.4,139.4z"/>
		<path class="st218" d="M546,194.5c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C546.6,193.1,546,193.7,546,194.5z"/>
		<path class="st218" d="M569.9,182.3c0,0.7,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4S569.9,181.6,569.9,182.3z"/>
		<path class="st218" d="M565.1,98.8c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C565.7,97.5,565.1,98.1,565.1,98.8z"/>
		<path class="st218" d="M565.1,149c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C565.7,147.6,565.1,148.2,565.1,149z"/>
		<path class="st218" d="M532.8,159.7c0,0.7,0.6,1.4,1.3,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C533.4,158.4,532.8,159,532.8,159.7z"/>
		<path class="st218" d="M513.6,119c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.4-0.6,1.4-1.3s-0.6-1.4-1.4-1.4
			C514.3,117.7,513.6,118.3,513.6,119z"/>
		<path class="st218" d="M550.8,105.9c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C551.4,104.5,550.8,105.1,550.8,105.9z"/>
		<path class="st218" d="M493.3,102.3c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C493.9,100.9,493.3,101.5,493.3,102.3z"/>
		<path class="st218" d="M410.9,105.8c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C412.1,102.9,410.9,104.2,410.9,105.8z"/>
		<path class="st218" d="M428.4,123.3c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C429.7,120.5,428.4,121.8,428.4,123.3z"/>
		<path class="st218" d="M402.3,150.3c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C403.6,147.5,402.3,148.7,402.3,150.3z"/>
		<path class="st218" d="M502.6,192.9c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C503.9,190.1,502.6,191.4,502.6,192.9z"/>
		<ellipse class="st218" cx="454.7" cy="189.8" rx="2.8" ry="2.8"/>
		<path class="st218" d="M416.7,245.6c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C418,242.8,416.7,244.1,416.7,245.6z"/>
		<path class="st218" d="M355.9,232.5c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C357.1,229.6,355.9,230.9,355.9,232.5z"/>
		<path class="st218" d="M221,242.5c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C222.3,239.7,221,240.9,221,242.5z"/>
		<path class="st218" d="M177.1,251.3c0,1.6,1.3,2.8,2.8,2.8s2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8S177.1,249.7,177.1,251.3z"/>
		<path class="st218" d="M168.4,177.3c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C169.6,174.4,168.4,175.7,168.4,177.3z"/>
		<path class="st218" d="M130.7,193.6c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C132,190.8,130.7,192,130.7,193.6z"/>
		<path class="st218" d="M521,159.3c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C522.2,156.5,521,157.8,521,159.3z"/>
		<path class="st218" d="M544.9,125.4c0,1.6,1.3,2.8,2.8,2.8s2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8S544.9,123.9,544.9,125.4z"/>
		<path class="st218" d="M501.8,101.9c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C503.1,99.1,501.8,100.3,501.8,101.9z"/>
		<path class="st218" d="M459.5,157.3c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C460.8,154.5,459.5,155.8,459.5,157.3z"/>
		<path class="st218" d="M298.5,119c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C299.7,116.2,298.5,117.5,298.5,119z"/>
		<path class="st218" d="M361.3,96.3c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C362.6,93.5,361.3,94.7,361.3,96.3z"/>
		<path class="st218" d="M223,151.4c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C224.3,148.5,223,149.8,223,151.4z"/>
		<path class="st218" d="M183.5,105.3c0,1.6,1.3,2.8,2.8,2.8s2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8S183.5,103.7,183.5,105.3z"/>
		<path class="st218" d="M175.1,132.8c0,1.6,1.3,2.8,2.8,2.8s2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8S175.1,131.2,175.1,132.8z"/>
		<path class="st218" d="M120.1,123.2c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C121.3,120.4,120.1,121.7,120.1,123.2z"/>
		<path class="st218" d="M45.8,126.8c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C47.1,124,45.8,125.3,45.8,126.8z"/>
		<path class="st218" d="M102.7,234.6c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C104,231.8,102.7,233,102.7,234.6z"/>
		<path class="st218" d="M132.2,301.8c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C133.5,299,132.2,300.3,132.2,301.8z"/>
		<path class="st218" d="M399.7,306.9c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8s-1.3-2.8-2.8-2.8
			C401,304.1,399.7,305.3,399.7,306.9z"/>
		<path class="st218" d="M439.8,319.6c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C441.1,316.8,439.8,318.1,439.8,319.6z"/>
		<path class="st218" d="M417.6,259.9c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C418.8,257.1,417.6,258.4,417.6,259.9z"/>
		<path class="st218" d="M479.5,228c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C480.7,225.2,479.5,226.4,479.5,228z"/>
		<path class="st218" d="M561.7,249.5c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C562.9,246.7,561.7,248,561.7,249.5z"/>
		<path class="st218" d="M556.1,319.8c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C557.3,317,556.1,318.2,556.1,319.8z"/>
		<path class="st218" d="M501.8,336.5c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C503.1,333.7,501.8,335,501.8,336.5z"/>
		<path class="st218" d="M62.8,310.6c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8s-1.3-2.8-2.8-2.8C64,307.8,62.8,309.1,62.8,310.6
			z"/>
		<path class="st218" d="M118.9,232.2c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C120.1,229.4,118.9,230.6,118.9,232.2z"/>
		<path class="st218" d="M193.1,224.4c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C194.4,221.6,193.1,222.8,193.1,224.4z"/>
		<path class="st218" d="M108.1,314.8c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C109.3,312,108.1,313.2,108.1,314.8z"/>
		<path class="st218" d="M142.8,341.7c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C144.1,338.9,142.8,340.2,142.8,341.7z"/>
		<path class="st218" d="M5.7,328.6c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8C7,325.7,5.7,327,5.7,328.6z
			"/>
		<path class="st218" d="M51.8,251.9c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C53.1,249.1,51.8,250.4,51.8,251.9z"/>
		<path class="st218" d="M47,198.7c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C48.3,195.8,47,197.1,47,198.7z"/>
		<path class="st218" d="M90.1,166.9c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8s-1.3-2.8-2.8-2.8
			C91.4,164.1,90.1,165.4,90.1,166.9z"/>
		<path class="st218" d="M11.1,225c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C12.4,222.2,11.1,223.4,11.1,225z"/>
		<path class="st218" d="M577.4,213c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8s-1.3-2.8-2.8-2.8
			C578.7,210.2,577.4,211.5,577.4,213z"/>
		<path class="st218" d="M514.6,241.2c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C515.8,238.3,514.6,239.6,514.6,241.2z"/>
		<path class="st218" d="M452.3,228c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C453.6,225.2,452.3,226.4,452.3,228z"/>
		<path class="st218" d="M375.7,189.1c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C376.9,186.3,375.7,187.5,375.7,189.1z"/>
		<path class="st218" d="M318.8,212.4c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C320.1,209.6,318.8,210.9,318.8,212.4z"/>
		<path class="st218" d="M273.3,187.9c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C274.6,185.1,273.3,186.3,273.3,187.9z"/>
		<path class="st218" d="M250.6,207c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C251.8,204.2,250.6,205.5,250.6,207z"/>
		<path class="st218" d="M403.6,129.8c0,0.7,0.6,1.4,1.3,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C404.2,128.5,403.6,129.1,403.6,129.8z"/>
		<path class="st218" d="M444.4,104.7c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C445,103.3,444.4,103.9,444.4,104.7z"/>
		<path class="st218" d="M378.2,125c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C378.8,123.7,378.2,124.3,378.2,125z"/>
		<path class="st218" d="M343.5,103.5c0,0.7,0.6,1.4,1.3,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C344.1,102.1,343.5,102.7,343.5,103.5z"/>
		<path class="st218" d="M239.3,102.3c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C239.9,100.9,239.3,101.5,239.3,102.3z"/>
		<path class="st218" d="M261,133.4c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C261.6,132,261,132.7,261,133.4z"/>
		<path class="st218" d="M287.3,102.4c0,0.7,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4S287.3,101.7,287.3,102.4z"/>
		<path class="st218" d="M283.8,126.4c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C284.4,125,283.8,125.6,283.8,126.4z"/>
		<path class="st218" d="M351.7,146.4c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C352.3,145.1,351.7,145.7,351.7,146.4z"/>
		<path class="st218" d="M324.1,130.8c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C324.7,129.4,324.1,130,324.1,130.8z"/>
		<path class="st218" d="M254.5,158.4c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C255.1,157,254.5,157.6,254.5,158.4z"/>
		<path class="st218" d="M261.4,233c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C262,231.6,261.4,232.2,261.4,233z"/>
		<path class="st218" d="M222.5,217.3c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C223.1,216,222.5,216.6,222.5,217.3z"/>
		<path class="st218" d="M176.7,237.4c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C177.3,236,176.7,236.6,176.7,237.4z"/>
		<path class="st218" d="M163.6,257.4c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C164.2,256.1,163.6,256.7,163.6,257.4z"/>
		<path class="st218" d="M341,222.3c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C341.6,221,341,221.6,341,222.3z"/>
		<path class="st218" d="M347.9,243.6c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C348.5,242.3,347.9,242.9,347.9,243.6z"/>
		<path class="st218" d="M378.7,231.1c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C379.3,229.8,378.7,230.4,378.7,231.1z"/>
		<path class="st218" d="M410,235.5c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C410.6,234.1,410,234.7,410,235.5z"/>
		<path class="st218" d="M435.7,256.2c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C436.3,254.8,435.7,255.4,435.7,256.2z"/>
		<path class="st218" d="M496.6,270.6c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C497.2,269.3,496.6,269.9,496.6,270.6z"/>
		<path class="st218" d="M171.2,306.9c0,0.7,0.6,1.3,1.4,1.3c0.7,0,1.4-0.6,1.4-1.3c0-0.7-0.6-1.4-1.4-1.4
			C171.8,305.5,171.2,306.1,171.2,306.9z"/>
		<path class="st218" d="M542.6,339.7c0,0.7,0.6,1.3,1.4,1.3c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.4-1.3-1.4
			C543.2,338.4,542.6,339,542.6,339.7z"/>
		<path class="st218" d="M478,274.3c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C478.6,272.9,478,273.5,478,274.3z"/>
		<path class="st218" d="M417.1,234.1c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C417.7,232.8,417.1,233.4,417.1,234.1z"/>
		<path class="st218" d="M458.6,251.1c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C459.2,249.8,458.6,250.4,458.6,251.1z"/>
		<path class="st218" d="M579.9,281.2c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C580.5,279.9,579.9,280.5,579.9,281.2z"/>
		<path class="st218" d="M528.8,262.9c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C529.4,261.5,528.8,262.1,528.8,262.9z"/>
		<path class="st218" d="M408.3,340.3c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4s-0.6-1.3-1.3-1.3
			C408.9,338.9,408.3,339.5,408.3,340.3z"/>
		<path class="st218" d="M500.6,222.5c0,0.7,0.6,1.4,1.3,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C501.2,221.1,500.6,221.7,500.6,222.5z"/>
		<path class="st218" d="M163,129.8c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C163.6,128.5,163,129.1,163,129.8z"/>
		<path class="st218" d="M182.1,155c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C182.7,153.6,182.1,154.2,182.1,155z"/>
		<path class="st218" d="M215.7,131c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C216.3,129.7,215.7,130.3,215.7,131z"/>
		<path class="st218" d="M170.2,105.9c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C170.8,104.5,170.2,105.1,170.2,105.9z"/>
		<path class="st218" d="M85.2,105.9c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C85.8,104.5,85.2,105.1,85.2,105.9z"/>
		<path class="st218" d="M86.3,135.8c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C87,134.4,86.3,135,86.3,135.8z"/>
		<path class="st218" d="M122.1,106.9c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C122.7,105.5,122.1,106.2,122.1,106.9z"/>
		<path class="st218" d="M125.7,148.8c0,0.7,0.6,1.3,1.4,1.3c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.4-1.3-1.4
			C126.3,147.5,125.7,148.1,125.7,148.8z"/>
		<path class="st218" d="M75.4,185.9c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C76,184.6,75.4,185.2,75.4,185.9z"/>
		<path class="st218" d="M116.1,244.6c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C116.7,243.2,116.1,243.9,116.1,244.6z"/>
		<path class="st218" d="M161.8,205.2c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C162.4,203.9,161.8,204.5,161.8,205.2z"/>
		<path class="st218" d="M113.9,183.7c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C114.5,182.3,113.9,182.9,113.9,183.7z"/>
		<path class="st218" d="M55.2,214.8c0,0.7,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4S55.2,214.1,55.2,214.8z"/>
		<path class="st218" d="M38.5,133.4c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C39.1,132,38.5,132.7,38.5,133.4z"/>
		<path class="st218" d="M304.3,205.2c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C304.9,203.9,304.3,204.5,304.3,205.2z"/>
		<path class="st218" d="M367.6,210c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C368.2,208.7,367.6,209.3,367.6,210z"/>
		<path class="st218" d="M460.8,230.4c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C461.4,229,460.8,229.6,460.8,230.4z"/>
		<path class="st218" d="M442,212.4c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C442.6,211.1,442,211.7,442,212.4z"/>
		<path class="st218" d="M426.2,204c0,0.7,0.6,1.4,1.3,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C426.8,202.7,426.2,203.3,426.2,204z"/>
		<path class="st218" d="M362.6,206.4c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C363.2,205.1,362.6,205.7,362.6,206.4z"/>
		<path class="st218" d="M396.1,189.7c0,0.7,0.6,1.4,1.3,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C396.8,188.3,396.1,188.9,396.1,189.7z"/>
		<path class="st218" d="M389,217.2c0,0.7,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4S389,216.5,389,217.2z"/>
		<path class="st218" d="M565.1,228.1c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C565.7,226.8,565.1,227.4,565.1,228.1z"/>
		<path class="st218" d="M107.7,327.5c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C108.4,326.2,107.7,326.8,107.7,327.5z"/>
		<path class="st218" d="M134.1,337.1c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C134.7,335.7,134.1,336.4,134.1,337.1z"/>
		<path class="st218" d="M93.4,289.2c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4s-0.6-1.4-1.4-1.4C94,287.9,93.4,288.5,93.4,289.2
			z"/>
		<path class="st218" d="M88.4,320.9c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C89,319.5,88.4,320.1,88.4,320.9z"/>
		<path class="st218" d="M119.1,347.8c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C119.7,346.5,119.1,347.1,119.1,347.8z"/>
		<path class="st218" d="M126.9,305.7c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C127.5,304.3,126.9,304.9,126.9,305.7z"/>
		<path class="st218" d="M97,259c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4C97.6,257.6,97,258.2,97,259z"
			/>
		<path class="st218" d="M65.8,286.5c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C66.4,285.2,65.8,285.8,65.8,286.5z"/>
		<path class="st218" d="M21.5,250.6c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C22.1,249.2,21.5,249.8,21.5,250.6z"/>
		<path class="st218" d="M16.7,200.5c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C17.4,199.1,16.7,199.7,16.7,200.5z"/>
		<path class="st218" d="M16.7,152.7c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C17.4,151.4,16.7,152,16.7,152.7z"/>
		<path class="st218" d="M55.1,101.2c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C55.7,99.9,55.1,100.5,55.1,101.2z"/>
		<path class="st218" d="M69.4,332.3c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C70,331,69.4,331.6,69.4,332.3z"/>
		<path class="st218" d="M47.9,289.2c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4s-0.6-1.4-1.4-1.4
			C48.5,287.9,47.9,288.5,47.9,289.2z"/>
		<path class="st218" d="M12,285.6c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C12.6,284.3,12,284.9,12,285.6z"/>
		<path class="st218" d="M40.5,322.6c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C41.1,321.2,40.5,321.8,40.5,322.6z"/>
		<path class="st218" d="M129.1,427.9c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.3-1.3-1.3
			C129.7,426.6,129.1,427.2,129.1,427.9z"/>
		<path class="st218" d="M107.7,420.9c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.3-1.4-1.3
			C108.4,419.6,107.7,420.2,107.7,420.9z"/>
		<path class="st218" d="M17.9,110.8c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C18.6,109.5,17.9,110.1,17.9,110.8z"/>
		<path class="st218" d="M64.6,157.5c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C65.2,156.1,64.6,156.8,64.6,157.5z"/>
		<path class="st218" d="M159.1,155c0,0.7,0.6,1.4,1.3,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C159.7,153.6,159.1,154.2,159.1,155z"/>
		<path class="st218" d="M207,98.7c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4s-0.6-1.4-1.4-1.4C207.6,97.3,207,97.9,207,98.7z"/>
		<path class="st218" d="M318.3,111.8c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C318.9,110.5,318.3,111.1,318.3,111.8z"/>
		<path class="st218" d="M542,231.6c0,0.7,0.7,1.3,1.4,1.3c0.7,0,1.3-0.7,1.3-1.4c0-0.7-0.7-1.3-1.4-1.3
			C542.5,230.3,541.9,230.9,542,231.6z"/>
		<path class="st218" d="M535,216c0,0.7,0.7,1.3,1.4,1.3c0.7,0,1.3-0.7,1.3-1.4c0-0.7-0.7-1.3-1.4-1.3
			C535.5,214.6,534.9,215.3,535,216z"/>
		<path class="st218" d="M324.8,354.4c0,0.7,0.6,1.4,1.3,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.3-1.4-1.3
			C325.5,353.1,324.8,353.7,324.8,354.4z"/>
		<path class="st218" d="M326.1,308.9c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C326.7,307.5,326.1,308.1,326.1,308.9z"/>
		<path class="st218" d="M327.6,262.6c0,0.7,0.7,1.3,1.4,1.3c0.7,0,1.3-0.7,1.3-1.4c0-0.7-0.7-1.3-1.4-1.3
			C328.2,261.2,327.6,261.9,327.6,262.6z"/>
		<path class="st218" d="M373.1,282.6c0,0.7,0.7,1.3,1.4,1.3c0.7,0,1.3-0.7,1.3-1.4c0-0.7-0.7-1.3-1.4-1.3
			C373.6,281.2,373.1,281.8,373.1,282.6z"/>
		<path class="st218" d="M215.1,290.1c1.5-0.4,2.5-1.9,2.1-3.4c-0.4-1.5-1.9-2.5-3.4-2.1c-1.5,0.4-2.5,1.9-2.1,3.4
			C212.1,289.5,213.6,290.4,215.1,290.1z"/>
		<path class="st218" d="M306.3,298.3c1.5-0.4,2.5-1.9,2.1-3.4c-0.3-1.5-1.9-2.5-3.4-2.1c-1.5,0.4-2.5,1.9-2.1,3.4
			C303.3,297.8,304.8,298.7,306.3,298.3z"/>
		<path class="st218" d="M196.4,341.1c1.5-0.4,2.5-1.9,2.1-3.4c-0.4-1.5-1.9-2.5-3.4-2.1c-1.5,0.4-2.5,1.9-2.1,3.4
			C193.4,340.5,194.9,341.4,196.4,341.1z"/>
		<path class="st218" d="M199.8,315.7c0.7-0.2,1.2-0.9,1-1.6c-0.2-0.7-0.9-1.2-1.6-1c-0.7,0.2-1.2,0.9-1,1.6
			C198.4,315.4,199.1,315.9,199.8,315.7z"/>
		<path class="st218" d="M292.3,262c0.7-0.2,1.2-0.9,1-1.6c-0.2-0.7-0.9-1.2-1.6-1c-0.7,0.2-1.2,0.9-1,1.6
			C290.8,261.7,291.5,262.2,292.3,262z"/>
		<path class="st218" d="M269.7,274.9c0.7-0.2,1.2-0.9,1-1.6c-0.2-0.7-0.9-1.2-1.6-1c-0.7,0.2-1.2,0.9-1,1.6
			C268.3,274.6,269,275,269.7,274.9z"/>
		<path class="st218" d="M263.6,329.8c0.7-0.2,1.2-0.9,1-1.6c-0.2-0.7-0.9-1.2-1.6-1c-0.7,0.2-1.2,0.9-1,1.6
			C262.2,329.6,262.9,330,263.6,329.8z"/>
		<path class="st218" d="M209.9,332.9c0.7-0.2,1.2-0.9,1-1.6c-0.2-0.7-0.9-1.2-1.6-1c-0.7,0.2-1.2,0.9-1,1.6
			C208.4,332.6,209.2,333.1,209.9,332.9z"/>
		<path class="st218" d="M249.1,348.4c0.7-0.2,1.2-0.9,1-1.6c-0.2-0.7-0.9-1.2-1.6-1c-0.7,0.2-1.2,0.9-1,1.6
			C247.7,348.1,248.4,348.6,249.1,348.4z"/>
		<path class="st218" d="M255.8,303.7c0.7-0.2,1.2-0.9,1-1.6c-0.2-0.7-0.9-1.2-1.6-1c-0.7,0.2-1.2,0.9-1,1.6
			C254.3,303.4,255.1,303.8,255.8,303.7z"/>
		<path class="st218" d="M183.7,288.5c0.7-0.2,1.2-0.9,1-1.6c-0.2-0.7-0.9-1.2-1.6-1c-0.7,0.2-1.2,0.9-1,1.6
			C182.3,288.2,183,288.6,183.7,288.5z"/>
		<path class="st218" d="M208,297.6c0.7-0.2,1.2-0.9,1-1.6c-0.2-0.7-0.9-1.2-1.6-1c-0.7,0.2-1.2,0.9-1,1.6
			C206.5,297.3,207.2,297.7,208,297.6z"/>
		<path class="st218" d="M238.9,271.9c0.7-0.2,1.2-0.9,1-1.6c-0.2-0.7-0.9-1.2-1.6-1c-0.7,0.2-1.2,0.9-1,1.6
			C237.4,271.7,238.2,272.1,238.9,271.9z"/>
		<path class="st218" d="M274.3,308c0.7-0.2,1.2-0.9,1-1.6c-0.2-0.7-0.9-1.2-1.6-1c-0.7,0.2-1.2,0.9-1,1.6
			C272.9,307.7,273.6,308.1,274.3,308z"/>
		<path class="st218" d="M311.7,324.9c0.7-0.2,1.2-0.9,1-1.6c-0.2-0.7-0.9-1.2-1.6-1c-0.7,0.2-1.2,0.9-1,1.6
			C310.2,324.6,311,325.1,311.7,324.9z"/>
		<path class="st218" d="M295.6,255.2c0.7-0.2,1.1-0.9,0.9-1.7c-0.2-0.7-0.9-1.1-1.7-0.9c-0.7,0.2-1.1,0.9-0.9,1.7
			C294.1,255,294.9,255.4,295.6,255.2z"/>
		<path class="st218" d="M539.1,380.9c0.7,0.3,1.5,0,1.8-0.7c0.3-0.7,0-1.5-0.7-1.8c-0.7-0.3-1.5,0-1.8,0.7
			C538.1,379.9,538.4,380.7,539.1,380.9z"/>
		<path class="st218" d="M580.5,400.4c0.7,0.3,1.5,0,1.8-0.7c0.3-0.7,0-1.5-0.7-1.8c-0.7-0.3-1.5,0-1.8,0.7
			C579.4,399.3,579.8,400.1,580.5,400.4z"/>
		<path class="st218" d="M535.8,316.6c0.7,0.3,1.5,0,1.8-0.7c0.3-0.7,0-1.5-0.7-1.8c-0.7-0.3-1.5,0-1.8,0.7
			C534.7,315.5,535.1,316.3,535.8,316.6z"/>
		<path class="st218" d="M479.4,308.3c0.7,0.3,1.5,0,1.8-0.7c0.3-0.7,0-1.5-0.7-1.8c-0.7-0.3-1.5,0-1.8,0.7
			C478.3,307.2,478.7,308,479.4,308.3z"/>
		<path class="st218" d="M424.3,336.2c0.7,0.3,1.5,0,1.8-0.7c0.3-0.7,0-1.5-0.7-1.8c-0.7-0.3-1.5,0-1.8,0.7
			C423.3,335.1,423.6,335.9,424.3,336.2z"/>
		<path class="st218" d="M471.2,341.4c0.7,0.3,1.5,0,1.8-0.7c0.3-0.7,0-1.5-0.7-1.8c-0.7-0.3-1.5,0-1.8,0.7
			C470.2,340.3,470.5,341.1,471.2,341.4z"/>
		<path class="st218" d="M532.4,285.4c0.7,0.3,1.5,0,1.8-0.7c0.3-0.7,0-1.5-0.7-1.8c-0.7-0.3-1.5,0-1.8,0.7
			C531.4,284.3,531.7,285.1,532.4,285.4z"/>
		<path class="st218" d="M515.2,356.9c0.7,0.3,1.5-0.1,1.7-0.8c0.3-0.7-0.1-1.5-0.8-1.7c-0.7-0.3-1.5,0.1-1.7,0.8
			C514.2,355.9,514.5,356.6,515.2,356.9z"/>
		<path class="st218" d="M438.4,295c0.7,0.3,1.5-0.1,1.7-0.8c0.3-0.7-0.1-1.5-0.8-1.7c-0.7-0.3-1.5,0.1-1.7,0.8
			C437.3,294,437.7,294.8,438.4,295z"/>
		<path class="st218" d="M350.6,374.1c-0.6,0.5-0.7,1.3-0.2,1.9c0.5,0.6,1.3,0.7,1.9,0.2c0.6-0.5,0.7-1.3,0.2-1.9
			C352,373.7,351.1,373.6,350.6,374.1z"/>
		<path class="st218" d="M376.5,304.9c-0.6,0.5-0.7,1.3-0.2,1.9c0.5,0.6,1.3,0.7,1.9,0.2c0.6-0.5,0.7-1.3,0.2-1.9
			C377.9,304.5,377,304.4,376.5,304.9z"/>
		<path class="st218" d="M387.4,343.5c-0.6,0.5-0.7,1.3-0.2,1.9c0.5,0.6,1.3,0.7,1.9,0.2c0.6-0.5,0.7-1.3,0.2-1.9
			C388.8,343.1,388,343,387.4,343.5z"/>
		<path class="st218" d="M416.3,429.2c-0.6,0.5-0.7,1.3-0.2,1.9c0.5,0.6,1.3,0.7,1.9,0.2c0.6-0.5,0.7-1.3,0.2-1.9
			C417.7,428.8,416.8,428.7,416.3,429.2z"/>
		<path class="st218" d="M305.3,155c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C305.9,153.6,305.3,154.2,305.3,155z"/>
		<path class="st218" d="M266.8,205.2c0,0.7,0.6,1.4,1.3,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C267.4,203.9,266.8,204.5,266.8,205.2z"/>
		<path class="st218" d="M469.2,218.4c0,0.7,0.6,1.4,1.3,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C469.8,217.1,469.2,217.7,469.2,218.4z"/>
		<path class="st218" d="M378.2,254.3c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C378.8,253,378.2,253.6,378.2,254.3z"/>
		<path class="st218" d="M282.4,202.8c0,0.7,0.6,1.3,1.4,1.3c0.7,0,1.4-0.6,1.4-1.3c0-0.7-0.6-1.4-1.4-1.4
			C283,201.5,282.4,202.1,282.4,202.8z"/>
		<path class="st218" d="M246.5,236.4c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.3-1.4-1.3
			C247.1,235,246.5,235.6,246.5,236.4z"/>
		<path class="st218" d="M191.4,214.8c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C192,213.5,191.4,214.1,191.4,214.8z"/>
		<path class="st218" d="M132.7,269.9c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C133.3,268.5,132.7,269.2,132.7,269.9z"/>
		<path class="st218" d="M70.6,253c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C71.2,251.6,70.6,252.2,70.6,253z"/>
		<path class="st218" d="M89.9,210c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C90.5,208.7,89.9,209.3,89.9,210z"/>
		<path class="st218" d="M32.5,169.3c0,0.7,0.6,1.3,1.4,1.3c0.7,0,1.4-0.6,1.4-1.3c0-0.7-0.6-1.4-1.4-1.4
			C33.1,168,32.5,168.6,32.5,169.3z"/>
		<path class="st218" d="M32.6,232.2c0.3,0.7,1.1,0.9,1.8,0.6c0.7-0.3,0.9-1.1,0.6-1.8c-0.3-0.7-1.1-0.9-1.8-0.6
			C32.6,230.7,32.3,231.5,32.6,232.2z"/>
		<polygon class="st218" points="521.5,144 521,152.6 512.6,150.4 520.6,153.6 516,160.9 521.5,154.3 527,160.9 522.4,153.6
			530.4,150.4 522.1,152.6 		"/>
		<polygon class="st218" points="348.3,189.5 347.8,198.1 339.4,195.9 347.4,199.1 342.8,206.4 348.3,199.8 353.8,206.4
			349.2,199.1 357.2,195.9 348.9,198.1 		"/>
		<polygon class="st218" points="184.7,135.2 184.1,143.8 175.8,141.6 183.8,144.8 179.2,152.1 184.7,145.5 190.2,152.1
			185.6,144.8 193.6,141.6 185.2,143.8 		"/>
		<polygon class="st218" points="97.7,204.6 97.1,213.2 88.8,211.1 96.8,214.3 92.2,221.6 97.7,214.9 103.2,221.6 98.6,214.3
			106.6,211.1 98.2,213.2 		"/>
		<polygon class="st218" points="126.9,261.3 126.3,269.9 118,267.8 126,271 121.4,278.2 126.9,271.6 132.4,278.2 127.8,271
			135.8,267.8 127.5,269.9 		"/>
		<polygon class="st218" points="57.1,327.3 56.6,335.9 48.2,333.8 56.2,336.9 51.6,344.2 57.1,337.6 62.6,344.2 58,336.9 66,333.8
			57.7,335.9 		"/>
		<polygon class="st218" points="439.1,229.5 438.5,238.1 430.2,236 438.2,239.2 433.6,246.4 439.1,239.8 444.6,246.4 440,239.2
			448,236 439.6,238.1 		"/>
		<polygon class="st218" points="515.7,299.8 515.1,308.4 506.8,306.2 514.8,309.4 510.2,316.7 515.7,310.1 521.2,316.7
			516.6,309.4 524.6,306.2 516.2,308.4 		"/>
		<path class="st218" d="M409.7,282.8c1.5,0.3,3-0.7,3.3-2.3c0.3-1.5-0.7-3-2.3-3.3c-1.5-0.3-3,0.7-3.3,2.3
			C407.2,281.1,408.2,282.6,409.7,282.8z"/>
		<path class="st218" d="M337.1,276.1c1.5,0.3,3-0.7,3.3-2.3c0.3-1.5-0.7-3-2.3-3.3c-1.5-0.3-3,0.7-3.3,2.3
			C334.6,274.3,335.6,275.8,337.1,276.1z"/>
		<path class="st218" d="M322.7,292.9c0.7,0.1,1.4-0.4,1.6-1.1c0.1-0.7-0.4-1.4-1.1-1.6c-0.7-0.1-1.4,0.4-1.6,1.1
			C321.5,292,322,292.7,322.7,292.9z"/>
		<path class="st218" d="M348.8,310.4c0.7,0.1,1.4-0.4,1.6-1.1c0.1-0.7-0.4-1.4-1.1-1.6c-0.7-0.1-1.4,0.4-1.6,1.1
			C347.6,309.6,348.1,310.3,348.8,310.4z"/>
		<path class="st218" d="M323,313.2c0.7,0.1,1.4-0.4,1.6-1.1c0.1-0.7-0.4-1.4-1.1-1.6c-0.7-0.1-1.4,0.4-1.6,1.1
			C321.8,312.4,322.3,313.1,323,313.2z"/>
		<path class="st218" d="M385,285.1c0.7,0.1,1.4-0.4,1.6-1.1c0.1-0.7-0.4-1.4-1.1-1.6c-0.7-0.1-1.4,0.4-1.6,1.1
			C383.8,284.2,384.2,285,385,285.1z"/>
		<path class="st218" d="M354.6,305.5c0.7,0.1,1.4-0.4,1.5-1.2c0.1-0.7-0.4-1.4-1.2-1.5c-0.7-0.1-1.4,0.4-1.5,1.2
			C353.3,304.7,353.8,305.4,354.6,305.5z"/>
		<path class="st218" d="M477.3,315.7c0.4-0.6,0.2-1.5-0.4-1.9c-0.6-0.4-1.5-0.2-1.9,0.4c-0.4,0.6-0.2,1.5,0.4,1.9
			C476,316.5,476.9,316.3,477.3,315.7z"/>
		<path class="st218" d="M143.1,312.4c0.4-0.6,0.2-1.5-0.4-1.9c-0.6-0.4-1.5-0.2-1.9,0.4c-0.4,0.6-0.2,1.5,0.4,1.9
			C141.9,313.2,142.7,313,143.1,312.4z"/>
		<path class="st218" d="M175.1,73.7c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C176.3,70.9,175.1,72.2,175.1,73.7z"/>
		<path class="st218" d="M105.6,82.5c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C106.9,79.7,105.6,81,105.6,82.5z"/>
		<path class="st218" d="M151,86.7c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C152.2,83.9,151,85.1,151,86.7z"/>
		<path class="st218" d="M48.6,100.5c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C49.9,97.7,48.6,98.9,48.6,100.5z"/>
		<path class="st218" d="M94.7,23.8c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C95.9,21,94.7,22.3,94.7,23.8z"/>
		<path class="st218" d="M150.6,99.4c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C151.2,98.1,150.6,98.7,150.6,99.4z"/>
		<path class="st218" d="M177,109c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C177.6,107.7,177,108.3,177,109z"/>
		<path class="st218" d="M136.3,61.1c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C136.9,59.8,136.3,60.4,136.3,61.1z"/>
		<path class="st218" d="M131.3,92.8c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C131.9,91.4,131.3,92,131.3,92.8z"/>
		<path class="st218" d="M169.8,77.6c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C170.4,76.2,169.8,76.8,169.8,77.6z"/>
		<path class="st218" d="M139.9,30.9c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C140.5,29.5,139.9,30.1,139.9,30.9z"/>
		<path class="st218" d="M108.7,58.4c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C109.3,57.1,108.7,57.7,108.7,58.4z"/>
		<path class="st218" d="M64.4,22.5c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C65,21.1,64.4,21.7,64.4,22.5z"/>
		<path class="st218" d="M112.3,104.2c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C112.9,102.9,112.3,103.5,112.3,104.2z"/>
		<path class="st218" d="M90.8,61.1c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C91.4,59.8,90.8,60.4,90.8,61.1z"/>
		<path class="st218" d="M54.8,57.5c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C55.4,56.2,54.8,56.8,54.8,57.5z"/>
		<path class="st218" d="M83.4,94.5c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C84,93.1,83.4,93.7,83.4,94.5z"/>
		<path class="st218" d="M175.6,41.8c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C176.2,40.5,175.6,41.1,175.6,41.8z"/>
		<path class="st218" d="M113.5,24.9c0,0.7,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4S113.5,24.1,113.5,24.9z"/>
		<polygon class="st218" points="169.8,33.2 169.2,41.8 160.9,39.7 168.9,42.9 164.3,50.1 169.8,43.5 175.3,50.1 170.7,42.9
			178.7,39.7 170.3,41.8 		"/>
		<polygon class="st218" points="100,99.2 99.4,107.8 91.1,105.7 99.1,108.9 94.5,116.1 100,109.5 105.5,116.1 100.9,108.9
			108.9,105.7 100.6,107.8 		"/>
		<path class="st218" d="M186,84.3c0.4-0.6,0.2-1.5-0.4-1.9c-0.6-0.4-1.5-0.2-1.9,0.4c-0.4,0.6-0.2,1.5,0.4,1.9
			C184.8,85.1,185.6,84.9,186,84.3z"/>
		<path class="st218" d="M477.2,268.2c0.4-0.6,0.2-1.5-0.5-1.8c-0.6-0.4-1.5-0.2-1.8,0.5c-0.4,0.6-0.2,1.5,0.5,1.8
			C476,269.1,476.8,268.9,477.2,268.2z"/>
		<path class="st218" d="M511.2,286.9c0.4-0.6,0.2-1.5-0.5-1.8c-0.6-0.4-1.5-0.2-1.9,0.5c-0.4,0.6-0.2,1.5,0.5,1.8
			C510,287.7,510.9,287.5,511.2,286.9z"/>
		<path class="st218" d="M437.3,272.1c0.4-0.6,0.2-1.5-0.5-1.8c-0.6-0.4-1.5-0.2-1.9,0.5c-0.4,0.6-0.2,1.5,0.5,1.8
			C436.1,272.9,437,272.7,437.3,272.1z"/>
		<path class="st218" d="M347.2,39.5c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C347.8,38.2,347.2,38.8,347.2,39.5z"/>
		<path class="st218" d="M371.1,27.4c0,0.7,0.6,1.3,1.4,1.3c0.7,0,1.4-0.6,1.4-1.3c0-0.7-0.6-1.4-1.4-1.4
			C371.7,26,371.1,26.6,371.1,27.4z"/>
		<path class="st218" d="M303.8,38c0,1.6,1.3,2.8,2.8,2.8s2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8S303.8,36.4,303.8,38z"/>
		<path class="st218" d="M253,34.9c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8C254.3,32,253,33.3,253,34.9z
			"/>
		<path class="st218" d="M217.9,90.7c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C219.2,87.9,217.9,89.1,217.9,90.7z"/>
		<path class="st218" d="M218.8,105c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C220,102.2,218.8,103.4,218.8,105z"/>
		<path class="st218" d="M280.7,73c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C281.9,70.2,280.7,71.5,280.7,73z"/>
		<path class="st218" d="M362.9,94.6c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C364.1,91.8,362.9,93,362.9,94.6z"/>
		<path class="st218" d="M378.6,58.1c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C379.9,55.3,378.6,56.5,378.6,58.1z"/>
		<path class="st218" d="M315.8,86.2c0,1.6,1.3,2.8,2.8,2.8s2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8S315.8,84.7,315.8,86.2z"/>
		<path class="st218" d="M253.5,73c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C254.8,70.2,253.5,71.5,253.5,73z"/>
		<path class="st218" d="M236.9,101.2c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C237.6,99.9,236.9,100.5,236.9,101.2z"/>
		<path class="st218" d="M297.8,115.7c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4s-0.6-1.4-1.3-1.4
			C298.4,114.3,297.8,114.9,297.8,115.7z"/>
		<path class="st218" d="M279.2,119.3c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C279.8,118,279.2,118.6,279.2,119.3z"/>
		<path class="st218" d="M218.3,79.2c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C218.9,77.8,218.3,78.4,218.3,79.2z"/>
		<path class="st218" d="M259.8,96.2c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C260.4,94.8,259.8,95.4,259.8,96.2z"/>
		<path class="st218" d="M330,107.9c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C330.6,106.6,330,107.2,330,107.9z"/>
		<path class="st218" d="M301.8,67.5c0,0.7,0.6,1.4,1.3,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C302.4,66.2,301.8,66.8,301.8,67.5z"/>
		<path class="st218" d="M262,75.4c0,0.7,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4S262,74.7,262,75.4z"/>
		<path class="st218" d="M243.2,57.5c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C243.8,56.1,243.2,56.7,243.2,57.5z"/>
		<path class="st218" d="M227.4,49.1c0,0.7,0.6,1.4,1.3,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C228.1,47.7,227.4,48.3,227.4,49.1z"/>
		<path class="st218" d="M394.9,120.9c0,0.7,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4S394.9,120.2,394.9,120.9z"/>
		<path class="st218" d="M366.3,73.2c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C366.9,71.8,366.3,72.4,366.3,73.2z"/>
		<path class="st218" d="M343.2,76.7c0,0.7,0.7,1.3,1.4,1.3c0.7,0,1.3-0.7,1.3-1.4c0-0.7-0.7-1.3-1.4-1.3
			C343.7,75.3,343.2,75.9,343.2,76.7z"/>
		<path class="st218" d="M336.2,61.1c0,0.7,0.7,1.3,1.4,1.3c0.7,0,1.3-0.7,1.3-1.4c0-0.7-0.7-1.3-1.4-1.3
			C336.7,59.7,336.1,60.3,336.2,61.1z"/>
		<path class="st218" d="M270.4,63.5c0,0.7,0.6,1.4,1.3,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C271,62.1,270.4,62.7,270.4,63.5z"/>
		<polygon class="st218" points="240.3,74.6 239.7,83.2 231.4,81 239.4,84.2 234.8,91.5 240.3,84.9 245.8,91.5 241.2,84.2 249.2,81
			240.8,83.2 		"/>
		<path class="st218" d="M278.4,113.3c0.4-0.6,0.2-1.5-0.5-1.8c-0.6-0.4-1.5-0.2-1.8,0.5c-0.4,0.6-0.2,1.5,0.5,1.8
			C277.2,114.1,278,113.9,278.4,113.3z"/>
		<path class="st218" d="M238.5,117.1c0.4-0.6,0.2-1.5-0.5-1.8c-0.6-0.4-1.5-0.2-1.9,0.5c-0.4,0.6-0.2,1.5,0.5,1.8
			C237.3,118,238.2,117.8,238.5,117.1z"/>
		<path class="st218" d="M394.7,276.9c0.4-0.6,0.2-1.5-0.5-1.9c-0.6-0.4-1.5-0.2-1.9,0.5c-0.4,0.6-0.2,1.5,0.5,1.8
			C393.5,277.8,394.4,277.6,394.7,276.9z"/>
		<path class="st218" d="M446.6,291.3c0.4-0.6,0.2-1.5-0.5-1.9c-0.6-0.4-1.5-0.2-1.9,0.5c-0.4,0.6-0.2,1.5,0.5,1.9
			C445.4,292.2,446.2,291.9,446.6,291.3z"/>
		<path class="st218" d="M34.8,549.9c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8s-1.3-2.8-2.8-2.8
			C36.1,547.1,34.8,548.4,34.8,549.9z"/>
		<path class="st218" d="M156.7,479.8c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C157.9,477,156.7,478.3,156.7,479.8z"/>
		<path class="st218" d="M151.1,550.1c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C152.4,547.3,151.1,548.5,151.1,550.1z"/>
		<path class="st218" d="M96.8,566.8c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C98.1,564,96.8,565.3,96.8,566.8z"/>
		<path class="st218" d="M30.7,486.5c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C31.4,485.1,30.7,485.7,30.7,486.5z"/>
		<path class="st218" d="M91.6,500.9c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C92.2,499.6,91.6,500.2,91.6,500.9z"/>
		<path class="st218" d="M73,504.6c0,0.7,0.6,1.3,1.4,1.3c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.4-1.3-1.4
			C73.6,503.2,73,503.8,73,504.6z"/>
		<path class="st218" d="M53.6,481.4c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4s-0.6-1.4-1.4-1.4
			C54.2,480.1,53.6,480.7,53.6,481.4z"/>
		<path class="st218" d="M174.9,511.5c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C175.5,510.2,174.9,510.8,174.9,511.5z"/>
		<path class="st218" d="M123.8,493.2c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C124.4,491.8,123.8,492.4,123.8,493.2z"/>
		<path class="st218" d="M188.7,506.2c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.3-1.4-1.3
			C189.3,504.8,188.7,505.4,188.7,506.2z"/>
		<path class="st218" d="M130.8,546.9c0.7,0.3,1.5,0,1.8-0.7c0.3-0.7,0-1.5-0.7-1.8c-0.7-0.3-1.5,0-1.8,0.7
			C129.7,545.8,130.1,546.6,130.8,546.9z"/>
		<path class="st218" d="M74.4,538.6c0.7,0.3,1.5,0,1.8-0.7c0.3-0.7,0-1.5-0.7-1.8c-0.7-0.3-1.5,0-1.8,0.7
			C73.3,537.5,73.7,538.3,74.4,538.6z"/>
		<path class="st218" d="M19.3,566.5c0.7,0.3,1.5,0,1.8-0.7c0.3-0.7,0-1.5-0.7-1.8c-0.7-0.3-1.5,0-1.8,0.7
			C18.3,565.4,18.6,566.2,19.3,566.5z"/>
		<path class="st218" d="M127.4,515.7c0.7,0.3,1.5,0,1.8-0.7c0.3-0.7,0-1.5-0.7-1.8c-0.7-0.3-1.5,0-1.8,0.7
			C126.4,514.6,126.7,515.4,127.4,515.7z"/>
		<path class="st218" d="M33.4,525.3c0.7,0.3,1.5-0.1,1.7-0.8c0.3-0.7-0.1-1.5-0.8-1.7c-0.7-0.3-1.5,0.1-1.7,0.8
			C32.3,524.3,32.7,525.1,33.4,525.3z"/>
		<polygon class="st218" points="34.1,459.8 33.5,468.4 25.2,466.3 33.2,469.5 28.6,476.7 34.1,470.1 39.6,476.7 35,469.5 43,466.3
			34.6,468.4 		"/>
		<polygon class="st218" points="110.7,530.1 110.1,538.6 101.8,536.5 109.8,539.7 105.2,547 110.7,540.4 116.2,547 111.6,539.7
			119.6,536.5 111.3,538.6 		"/>
		<path class="st218" d="M72.3,546c0.4-0.6,0.2-1.5-0.4-1.9c-0.6-0.4-1.5-0.2-1.9,0.4c-0.4,0.6-0.2,1.5,0.4,1.9
			C71,546.8,71.9,546.6,72.3,546z"/>
		<path class="st218" d="M72.2,498.5c0.4-0.6,0.2-1.5-0.5-1.8c-0.6-0.4-1.5-0.2-1.8,0.5c-0.4,0.6-0.2,1.5,0.5,1.8
			C71,499.4,71.8,499.2,72.2,498.5z"/>
		<path class="st218" d="M106.3,517.2c0.4-0.6,0.2-1.5-0.5-1.8c-0.6-0.4-1.5-0.2-1.9,0.5c-0.4,0.6-0.2,1.5,0.5,1.8
			C105,518,105.9,517.8,106.3,517.2z"/>
		<path class="st218" d="M32.3,502.4c0.4-0.6,0.2-1.5-0.5-1.8c-0.6-0.4-1.5-0.2-1.9,0.5c-0.4,0.6-0.2,1.5,0.5,1.8
			C31.1,503.2,32,503,32.3,502.4z"/>
		<path class="st218" d="M41.6,521.6c0.4-0.6,0.2-1.5-0.5-1.9c-0.6-0.4-1.5-0.2-1.9,0.5c-0.4,0.6-0.2,1.5,0.5,1.9
			C40.4,522.4,41.2,522.2,41.6,521.6z"/>
		<polygon class="st218" points="56.3,190.8 55.8,199.4 47.4,197.3 55.4,200.5 50.8,207.7 56.3,201.1 61.8,207.7 57.2,200.5
			65.2,197.3 56.9,199.4 		"/>
		<path class="st218" d="M492.1,400.4c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.3-1.4-1.3
			C492.7,399.1,492.1,399.7,492.1,400.4z"/>
		<path class="st218" d="M459.9,375.3c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C460.5,373.9,459.9,374.5,459.9,375.3z"/>
		<path class="st218" d="M424.9,387.2c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4s-0.6-1.4-1.3-1.4
			C425.5,385.9,424.9,386.5,424.9,387.2z"/>
		<path class="st218" d="M447.8,395.6c0,0.7,0.6,1.4,1.3,1.4c0.7,0,1.4-0.6,1.4-1.4s-0.6-1.4-1.4-1.4
			C448.4,394.3,447.8,394.9,447.8,395.6z"/>
		<path class="st218" d="M482.5,377.7c0,0.7,0.6,1.3,1.4,1.3c0.7,0,1.4-0.6,1.4-1.3c0-0.7-0.6-1.4-1.4-1.4
			C483.1,376.3,482.5,376.9,482.5,377.7z"/>
		<path class="st218" d="M530.4,392c0,0.7,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4s-0.6-1.3-1.4-1.3S530.4,391.3,530.4,392z"/>
		<path class="st218" d="M546,447.1c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4s-0.6-1.4-1.4-1.4C546.6,445.8,546,446.4,546,447.1
			z"/>
		<path class="st218" d="M569.9,435c0,0.7,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4S569.9,434.2,569.9,435z"/>
		<path class="st218" d="M565.1,351.5c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C565.7,350.1,565.1,350.7,565.1,351.5z"/>
		<path class="st218" d="M565.1,401.6c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4s-0.6-1.3-1.3-1.3
			C565.7,400.3,565.1,400.9,565.1,401.6z"/>
		<path class="st218" d="M532.8,412.4c0,0.7,0.6,1.4,1.3,1.4c0.7,0,1.4-0.6,1.4-1.4s-0.6-1.4-1.4-1.4
			C533.4,411,532.8,411.6,532.8,412.4z"/>
		<path class="st218" d="M513.6,371.7c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.4-0.6,1.4-1.3s-0.6-1.4-1.4-1.4
			C514.3,370.3,513.6,370.9,513.6,371.7z"/>
		<path class="st218" d="M550.8,358.5c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.3-1.4-1.3
			C551.4,357.1,550.8,357.8,550.8,358.5z"/>
		<path class="st218" d="M493.3,354.9c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.3-1.3-1.3
			C493.9,353.6,493.3,354.2,493.3,354.9z"/>
		<path class="st218" d="M410.9,358.4c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C412.1,355.6,410.9,356.8,410.9,358.4z"/>
		<path class="st218" d="M428.4,375.9c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C429.7,373.1,428.4,374.4,428.4,375.9z"/>
		<path class="st218" d="M402.3,402.9c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C403.6,400.1,402.3,401.4,402.3,402.9z"/>
		<path class="st218" d="M502.6,445.6c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C503.9,442.8,502.6,444,502.6,445.6z"/>
		<path class="st218" d="M451.8,442.4c0,1.6,1.3,2.8,2.8,2.8s2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8S451.8,440.9,451.8,442.4z"/>
		<path class="st218" d="M168.4,429.9c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C169.6,427.1,168.4,428.3,168.4,429.9z"/>
		<path class="st218" d="M130.7,446.2c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C132,443.4,130.7,444.7,130.7,446.2z"/>
		<path class="st218" d="M521,412c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C522.2,409.2,521,410.4,521,412z"/>
		<path class="st218" d="M544.9,378.1c0,1.6,1.3,2.8,2.8,2.8s2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8S544.9,376.5,544.9,378.1z"/>
		<path class="st218" d="M501.8,354.5c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C503.1,351.7,501.8,353,501.8,354.5z"/>
		<path class="st218" d="M459.5,410c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C460.8,407.2,459.5,408.4,459.5,410z"/>
		<path class="st218" d="M298.5,371.7c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C299.7,368.9,298.5,370.1,298.5,371.7z"/>
		<path class="st218" d="M361.3,348.9c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C362.6,346.1,361.3,347.4,361.3,348.9z"/>
		<path class="st218" d="M324.7,433.9c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C325.9,431.1,324.7,432.3,324.7,433.9z"/>
		<path class="st218" d="M223,404c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C224.3,401.2,223,402.4,223,404z"/>
		<path class="st218" d="M183.5,357.9c0,1.6,1.3,2.8,2.8,2.8s2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8S183.5,356.3,183.5,357.9z"/>
		<path class="st218" d="M175.1,385.4c0,1.6,1.3,2.8,2.8,2.8s2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8S175.1,383.9,175.1,385.4z"/>
		<path class="st218" d="M120.1,375.9c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C121.3,373,120.1,374.3,120.1,375.9z"/>
		<path class="st218" d="M45.8,379.5c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C47.1,376.6,45.8,377.9,45.8,379.5z"/>
		<path class="st218" d="M193.1,477c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C194.4,474.2,193.1,475.5,193.1,477z"/>
		<path class="st218" d="M47,451.3c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C48.3,448.5,47,449.7,47,451.3z"/>
		<path class="st218" d="M90.1,419.6c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8s-1.3-2.8-2.8-2.8C91.4,416.7,90.1,418,90.1,419.6
			z"/>
		<path class="st218" d="M11.1,477.6c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C12.4,474.8,11.1,476.1,11.1,477.6z"/>
		<path class="st218" d="M577.4,465.7c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C578.7,462.8,577.4,464.1,577.4,465.7z"/>
		<path class="st218" d="M375.7,441.7c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C376.9,438.9,375.7,440.2,375.7,441.7z"/>
		<path class="st218" d="M318.8,465.1c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C320.1,462.2,318.8,463.5,318.8,465.1z"/>
		<path class="st218" d="M273.3,440.5c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C274.6,437.7,273.3,439,273.3,440.5z"/>
		<path class="st218" d="M250.6,459.7c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C251.8,456.9,250.6,458.1,250.6,459.7z"/>
		<path class="st218" d="M403.6,382.4c0,0.7,0.6,1.4,1.3,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C404.2,381.1,403.6,381.7,403.6,382.4z"/>
		<path class="st218" d="M444.4,357.3c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C445,355.9,444.4,356.6,444.4,357.3z"/>
		<path class="st218" d="M378.2,377.7c0,0.7,0.6,1.3,1.4,1.3c0.7,0,1.4-0.6,1.4-1.3c0-0.7-0.6-1.4-1.4-1.4
			C378.8,376.3,378.2,376.9,378.2,377.7z"/>
		<path class="st218" d="M343.5,356.1c0,0.7,0.6,1.4,1.3,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C344.1,354.8,343.5,355.4,343.5,356.1z"/>
		<path class="st218" d="M239.3,354.9c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.3-1.4-1.3
			C239.9,353.6,239.3,354.2,239.3,354.9z"/>
		<path class="st218" d="M261,386c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C261.6,384.7,261,385.3,261,386z"/>
		<path class="st218" d="M287.3,355.1c0,0.7,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4s-0.6-1.4-1.4-1.4S287.3,354.3,287.3,355.1z"/>
		<path class="st218" d="M283.8,379c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.3-1.4-1.3
			C284.4,377.7,283.8,378.3,283.8,379z"/>
		<path class="st218" d="M351.7,399.1c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4s-0.6-1.3-1.4-1.3
			C352.3,397.7,351.7,398.3,351.7,399.1z"/>
		<path class="st218" d="M324.1,383.4c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C324.7,382,324.1,382.7,324.1,383.4z"/>
		<path class="st218" d="M254.5,411c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C255.1,409.6,254.5,410.2,254.5,411z"/>
		<path class="st218" d="M222.5,469.9c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C223.1,468.6,222.5,469.2,222.5,469.9z"/>
		<path class="st218" d="M341,475c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C341.6,473.6,341,474.2,341,475z"/>
		<path class="st218" d="M500.6,475.1c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.4-0.6,1.4-1.3s-0.6-1.4-1.4-1.4
			C501.2,473.8,500.6,474.4,500.6,475.1z"/>
		<path class="st218" d="M163,382.4c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C163.6,381.1,163,381.7,163,382.4z"/>
		<path class="st218" d="M182.1,407.6c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C182.7,406.2,182.1,406.8,182.1,407.6z"/>
		<path class="st218" d="M215.7,383.6c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4s-0.6-1.4-1.4-1.4
			C216.3,382.3,215.7,382.9,215.7,383.6z"/>
		<path class="st218" d="M170.2,358.5c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.3-1.4-1.3
			C170.8,357.1,170.2,357.8,170.2,358.5z"/>
		<path class="st218" d="M85.2,358.5c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.3-1.4-1.3
			C85.8,357.1,85.2,357.8,85.2,358.5z"/>
		<path class="st218" d="M86.3,388.4c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C87,387.1,86.3,387.7,86.3,388.4z"/>
		<path class="st218" d="M122.1,359.5c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C122.7,358.2,122.1,358.8,122.1,359.5z"/>
		<path class="st218" d="M125.7,401.4c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C126.3,400.1,125.7,400.7,125.7,401.4z"/>
		<path class="st218" d="M75.4,438.6c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C76,437.2,75.4,437.8,75.4,438.6z"/>
		<path class="st218" d="M161.8,457.9c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C162.4,456.5,161.8,457.1,161.8,457.9z"/>
		<path class="st218" d="M113.9,436.3c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C114.5,435,113.9,435.6,113.9,436.3z"/>
		<path class="st218" d="M55.2,467.5c0,0.7,0.6,1.3,1.4,1.3s1.4-0.6,1.4-1.3s-0.6-1.4-1.4-1.4S55.2,466.7,55.2,467.5z"/>
		<path class="st218" d="M38.5,386c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C39.1,384.7,38.5,385.3,38.5,386z"/>
		<path class="st218" d="M304.3,457.9c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C304.9,456.5,304.3,457.1,304.3,457.9z"/>
		<path class="st218" d="M367.6,462.7c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.3-1.4-1.3
			C368.2,461.3,367.6,461.9,367.6,462.7z"/>
		<path class="st218" d="M442,465.1c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C442.6,463.7,442,464.3,442,465.1z"/>
		<path class="st218" d="M426.2,456.7c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.4-0.6,1.4-1.3c0-0.7-0.6-1.4-1.4-1.4
			C426.8,455.3,426.2,455.9,426.2,456.7z"/>
		<path class="st218" d="M362.6,459.1c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C363.2,457.7,362.6,458.3,362.6,459.1z"/>
		<path class="st218" d="M396.1,442.3c0,0.7,0.6,1.4,1.3,1.4c0.7,0,1.4-0.6,1.4-1.4s-0.6-1.4-1.4-1.4
			C396.8,441,396.1,441.6,396.1,442.3z"/>
		<path class="st218" d="M389,469.9c0,0.7,0.6,1.3,1.4,1.3s1.4-0.6,1.4-1.3c0-0.7-0.6-1.4-1.4-1.4S389,469.1,389,469.9z"/>
		<path class="st218" d="M16.7,453.1c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C17.4,451.7,16.7,452.3,16.7,453.1z"/>
		<path class="st218" d="M16.7,405.3c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C17.4,404,16.7,404.6,16.7,405.3z"/>
		<path class="st218" d="M55.1,353.9c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C55.7,352.5,55.1,353.1,55.1,353.9z"/>
		<path class="st218" d="M17.9,363.4c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4s-0.6-1.4-1.3-1.4
			C18.6,362.1,17.9,362.7,17.9,363.4z"/>
		<ellipse class="st218" cx="512.4" cy="512.6" rx="2.8" ry="2.8"/>
		<path class="st218" d="M440.2,521.3c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8s-1.3-2.8-2.8-2.8
			C441.4,518.5,440.2,519.8,440.2,521.3z"/>
		<path class="st218" d="M485.5,525.5c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C486.8,522.7,485.5,524,485.5,525.5z"/>
		<path class="st218" d="M520.2,552.5c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C521.5,549.7,520.2,550.9,520.2,552.5z"/>
		<path class="st218" d="M383.1,539.3c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C384.4,536.5,383.1,537.7,383.1,539.3z"/>
		<path class="st218" d="M429.2,462.7c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C430.5,459.9,429.2,461.1,429.2,462.7z"/>
		<path class="st218" d="M541,468.2c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C541.6,466.8,541,467.4,541,468.2z"/>
		<path class="st218" d="M548.6,517.6c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C549.2,516.2,548.6,516.8,548.6,517.6z"/>
		<path class="st218" d="M485.2,538.3c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C485.8,536.9,485.2,537.5,485.2,538.3z"/>
		<path class="st218" d="M511.5,547.8c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C512.1,546.5,511.5,547.1,511.5,547.8z"/>
		<path class="st218" d="M470.8,499.9c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C471.4,498.6,470.8,499.2,470.8,499.9z"/>
		<path class="st218" d="M465.8,531.6c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C466.4,530.3,465.8,530.9,465.8,531.6z"/>
		<path class="st218" d="M496.6,558.6c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C497.2,557.2,496.6,557.8,496.6,558.6z"/>
		<path class="st218" d="M504.3,516.4c0,0.7,0.6,1.4,1.3,1.4s1.4-0.6,1.4-1.4s-0.6-1.4-1.4-1.4S504.3,515.6,504.3,516.4z"/>
		<path class="st218" d="M474.4,469.7c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C475,468.3,474.4,469,474.4,469.7z"/>
		<path class="st218" d="M443.3,497.2c0,0.7,0.6,1.3,1.4,1.3c0.7,0,1.3-0.6,1.3-1.3c0-0.8-0.6-1.4-1.3-1.4
			C443.9,495.9,443.3,496.5,443.3,497.2z"/>
		<path class="st218" d="M446.9,543c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C447.5,541.7,446.9,542.3,446.9,543z"/>
		<path class="st218" d="M425.3,499.9c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C425.9,498.6,425.3,499.2,425.3,499.9z"/>
		<path class="st218" d="M389.4,496.3c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C390,495,389.4,495.6,389.4,496.3z"/>
		<path class="st218" d="M418,533.3c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C418.6,532,418,532.6,418,533.3z"/>
		<path class="st218" d="M510.2,480.6c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C510.8,479.3,510.2,479.9,510.2,480.6z"/>
		<path class="st218" d="M448.1,463.7c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4s-0.6-1.4-1.4-1.4
			C448.7,462.4,448.1,463,448.1,463.7z"/>
		<polygon class="st218" points="504.3,472 503.8,480.6 495.4,478.5 503.4,481.7 498.8,489 504.3,482.3 509.8,489 505.2,481.7
			513.2,478.5 504.9,480.6 		"/>
		<polygon class="st218" points="434.5,538 434,546.6 425.6,544.5 433.6,547.7 429,555 434.5,548.3 440,555 435.4,547.7
			443.4,544.5 435.1,546.6 		"/>
		<path class="st218" d="M520.5,523.1c0.4-0.6,0.2-1.5-0.4-1.9c-0.6-0.4-1.5-0.2-1.9,0.4c-0.4,0.6-0.2,1.5,0.4,1.9
			C519.3,523.9,520.1,523.7,520.5,523.1z"/>
		<path class="st218" d="M547.6,569.2c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.3-1.4-1.3
			C548.2,567.9,547.6,568.5,547.6,569.2z"/>
		<path class="st218" d="M462.6,569.2c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.3-1.3-1.3
			C463.2,567.9,462.6,568.5,462.6,569.2z"/>
		<path class="st218" d="M499.5,570.3c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4s-0.6-1.4-1.4-1.4
			C500.1,568.9,499.5,569.5,499.5,570.3z"/>
		<path class="st218" d="M432.5,564.6c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C433.1,563.2,432.5,563.8,432.5,564.6z"/>
		<path class="st218" d="M395.4,574.2c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C396,572.8,395.4,573.4,395.4,574.2z"/>
		<path class="st218" d="M519.5,66.6c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C520.7,63.8,519.5,65,519.5,66.6z"/>
		<path class="st218" d="M450,75.4c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8s-1.3-2.8-2.8-2.8C451.3,72.6,450,73.8,450,75.4z"/>
		<path class="st218" d="M495.4,79.6c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C496.6,76.8,495.4,78,495.4,79.6z"/>
		<path class="st218" d="M530.1,106.5c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C531.3,103.7,530.1,105,530.1,106.5z"/>
		<path class="st218" d="M393,93.3c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C394.2,90.5,393,91.8,393,93.3z"/>
		<path class="st218" d="M439.1,16.7c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C440.3,13.9,439.1,15.2,439.1,16.7z"/>
		<path class="st218" d="M550.8,22.2c0,0.7,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4S550.8,21.5,550.8,22.2z"/>
		<path class="st218" d="M558.5,71.6c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C559.1,70.3,558.5,70.9,558.5,71.6z"/>
		<path class="st218" d="M495,92.3c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C495.6,90.9,495,91.5,495,92.3z"/>
		<path class="st218" d="M521.4,101.9c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C522,100.5,521.4,101.1,521.4,101.9z"/>
		<path class="st218" d="M480.7,54c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C481.3,52.6,480.7,53.2,480.7,54z"/>
		<path class="st218" d="M475.7,85.7c0,0.7,0.6,1.4,1.3,1.4c0.8,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C476.3,84.3,475.7,84.9,475.7,85.7z"/>
		<path class="st218" d="M506.4,112.6c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C507,111.3,506.4,111.9,506.4,112.6z"/>
		<path class="st218" d="M514.2,70.4c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C514.8,69.1,514.2,69.7,514.2,70.4z"/>
		<path class="st218" d="M484.3,23.7c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C484.9,22.4,484.3,23,484.3,23.7z"/>
		<path class="st218" d="M453.1,51.3c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C453.7,49.9,453.1,50.5,453.1,51.3z"/>
		<path class="st218" d="M456.7,97.1c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C457.3,95.7,456.7,96.3,456.7,97.1z"/>
		<path class="st218" d="M435.2,54c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C435.8,52.6,435.2,53.2,435.2,54z"/>
		<path class="st218" d="M399.2,50.4c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C399.8,49,399.2,49.6,399.2,50.4z"/>
		<path class="st218" d="M427.8,87.3c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C428.4,86,427.8,86.6,427.8,87.3z"/>
		<path class="st218" d="M520,34.7c0,0.7,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4S520,33.9,520,34.7z"/>
		<path class="st218" d="M457.9,17.8c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C458.5,16.4,457.9,17,457.9,17.8z"/>
		<polygon class="st218" points="514.2,26.1 513.6,34.7 505.3,32.5 513.3,35.7 508.7,43 514.2,36.4 519.7,43 515.1,35.7 523.1,32.5
			514.7,34.7 		"/>
		<polygon class="st218" points="444.4,92.1 443.8,100.7 435.5,98.5 443.5,101.7 438.9,109 444.4,102.4 449.9,109 445.3,101.7
			453.3,98.5 445,100.7 		"/>
		<path class="st218" d="M530.4,77.1c0.4-0.6,0.2-1.5-0.4-1.9c-0.6-0.4-1.5-0.2-1.9,0.4c-0.4,0.6-0.2,1.5,0.4,1.9
			C529.2,77.9,530,77.8,530.4,77.1z"/>
		<path class="st218" d="M557.4,123.3c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.3-1.4-1.3
			C558.1,121.9,557.4,122.5,557.4,123.3z"/>
		<path class="st218" d="M472.4,123.3c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.3-1.3-1.3
			C473,121.9,472.4,122.5,472.4,123.3z"/>
		<path class="st218" d="M509.4,124.3c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C510,123,509.4,123.6,509.4,124.3z"/>
		<path class="st218" d="M442.3,118.6c0,0.7,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4S442.3,117.9,442.3,118.6z"/>
		<path class="st218" d="M405.2,128.2c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C405.8,126.9,405.2,127.5,405.2,128.2z"/>
		<path class="st218" d="M303.1,498.5c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8
			C304.4,495.7,303.1,497,303.1,498.5z"/>
		<path class="st218" d="M200.7,512.3c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8s-1.3-2.8-2.8-2.8
			C202,509.5,200.7,510.8,200.7,512.3z"/>
		<path class="st218" d="M302.8,511.3c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C303.4,509.9,302.8,510.5,302.8,511.3z"/>
		<path class="st218" d="M283.4,504.6c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C284,503.3,283.4,503.9,283.4,504.6z"/>
		<path class="st218" d="M264.5,516.1c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C265.1,514.7,264.5,515.3,264.5,516.1z"/>
		<path class="st218" d="M235.6,506.3c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C236.2,505,235.6,505.6,235.6,506.3z"/>
		<polygon class="st218" points="252.1,511 251.6,519.6 243.3,517.5 251.3,520.7 246.6,528 252.1,521.3 257.6,528 253,520.7
			261,517.5 252.7,519.6 		"/>
		<path class="st218" d="M240.8,563.2c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8s-1.3-2.8-2.8-2.8
			C242.1,560.4,240.8,561.6,240.8,563.2z"/>
		<path class="st218" d="M280.2,542.2c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4s-0.6-1.4-1.4-1.4
			C280.8,540.9,280.2,541.5,280.2,542.2z"/>
		<path class="st218" d="M281.4,572.2c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4s-0.6-1.4-1.4-1.4
			C282,570.8,281.4,571.4,281.4,572.2z"/>
		<path class="st218" d="M233.5,569.8c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C234.1,568.4,233.5,569,233.5,569.8z"/>
		<path class="st218" d="M250.1,537.6c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4s-0.6-1.4-1.4-1.4
			C250.7,536.3,250.1,536.9,250.1,537.6z"/>
		<path class="st218" d="M213,547.2c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C213.6,545.8,213,546.4,213,547.2z"/>
		<path class="st218" d="M64.6,410.1c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4s-0.6-1.4-1.4-1.4
			C65.2,408.8,64.6,409.4,64.6,410.1z"/>
		<path class="st218" d="M159.1,407.6c0,0.7,0.6,1.4,1.3,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C159.7,406.2,159.1,406.8,159.1,407.6z"/>
		<path class="st218" d="M207,351.3c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C207.6,350,207,350.6,207,351.3z"/>
		<path class="st218" d="M318.3,364.5c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4c0-0.7-0.6-1.4-1.3-1.4
			C318.9,363.1,318.3,363.7,318.3,364.5z"/>
		<path class="st218" d="M535,468.7c0,0.7,0.7,1.3,1.4,1.3c0.7,0,1.3-0.7,1.3-1.4c0-0.7-0.7-1.3-1.4-1.3
			C535.5,467.3,534.9,467.9,535,468.7z"/>
		<path class="st218" d="M305.3,407.6c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C305.9,406.2,305.3,406.8,305.3,407.6z"/>
		<path class="st218" d="M266.8,457.9c0,0.7,0.6,1.4,1.3,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
			C267.4,456.5,266.8,457.1,266.8,457.9z"/>
		<path class="st218" d="M469.2,471c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.4-0.6,1.4-1.3s-0.6-1.4-1.4-1.4
			C469.8,469.7,469.2,470.3,469.2,471z"/>
		<path class="st218" d="M282.4,455.5c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4s-0.6-1.4-1.4-1.4
			C283,454.1,282.4,454.7,282.4,455.5z"/>
		<path class="st218" d="M191.4,467.5c0,0.7,0.6,1.3,1.4,1.3c0.7,0,1.4-0.6,1.4-1.3s-0.6-1.4-1.4-1.4
			C192,466.1,191.4,466.7,191.4,467.5z"/>
		<path class="st218" d="M89.9,462.7c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.3-1.4-1.3
			C90.5,461.3,89.9,461.9,89.9,462.7z"/>
		<path class="st218" d="M32.5,422c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4s-0.6-1.4-1.4-1.4C33.1,420.6,32.5,421.2,32.5,422z"
			/>
		<polygon class="st218" points="521.5,396.6 521,405.2 512.6,403.1 520.6,406.3 516,413.5 521.5,406.9 527,413.5 522.4,406.3
			530.4,403.1 522.1,405.2 		"/>
		<polygon class="st218" points="348.3,442.1 347.8,450.7 339.4,448.6 347.4,451.7 342.8,459 348.3,452.4 353.8,459 349.2,451.7
			357.2,448.6 348.9,450.7 		"/>
		<polygon class="st218" points="184.7,387.8 184.1,396.4 175.8,394.3 183.8,397.5 179.2,404.7 184.7,398.1 190.2,404.7
			185.6,397.5 193.6,394.3 185.2,396.4 		"/>
		<polygon class="st218" points="97.7,457.3 97.1,465.9 88.8,463.7 96.8,466.9 92.2,474.2 97.7,467.6 103.2,474.2 98.6,466.9
			106.6,463.7 98.2,465.9 		"/>
		<polygon class="st218" points="56.3,443.4 55.8,452 47.4,449.9 55.4,453.1 50.8,460.4 56.3,453.7 61.8,460.4 57.2,453.1
			65.2,449.9 56.9,452 		"/>
		<path class="st218" d="M182.9,248.7c-0.7,2.4-1,5-1,7.6c0,15.8,12.8,28.5,28.5,28.5c2.1,0,4.2-0.2,6.1-0.7
			c-4.4,4.4-10.5,7.1-17.2,7.1c-13.5,0-24.5-10.9-24.5-24.5C174.9,259.7,178,253.2,182.9,248.7z"/>

			<ellipse transform="matrix(1.281382e-02 -0.9999 0.9999 1.281382e-02 -15.6499 312.5457)" class="st218" cx="150.5" cy="164.2" rx="8.4" ry="8.4"/>
		<path class="st219" d="M343.2,529.4c0,2-1.6,3.6-3.6,3.6c-2,0-3.6-1.6-3.6-3.6s1.6-3.6,3.6-3.6
			C341.6,525.8,343.2,527.4,343.2,529.4z"/>
		<path class="st220" d="M492.2,346.2c0,3.5-2.8,6.3-6.3,6.3c-3.5,0-6.3-2.8-6.3-6.3c0-3.5,2.8-6.3,6.3-6.3
			C489.4,339.9,492.2,342.7,492.2,346.2z"/>
		<path class="st218" d="M589.5,147.8c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C588.9,146.4,589.5,147,589.5,147.8z"/>
		<path class="st218" d="M621.7,122.6c0,0.7-0.6,1.3-1.4,1.3c-0.7,0-1.4-0.6-1.4-1.3c0-0.7,0.6-1.4,1.4-1.4
			C621.1,121.3,621.7,121.9,621.7,122.6z"/>
		<path class="st218" d="M656.8,134.6c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.3-0.6-1.3-1.4c0-0.7,0.6-1.4,1.3-1.4
			C656.2,133.2,656.8,133.9,656.8,134.6z"/>
		<path class="st218" d="M633.9,143c0,0.7-0.6,1.4-1.3,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.3,1.4-1.3
			C633.2,141.6,633.9,142.2,633.9,143z"/>
		<path class="st218" d="M599.1,125c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C598.5,123.7,599.1,124.3,599.1,125z"/>
		<path class="st218" d="M588.4,102.3c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.3-0.6-1.3-1.4c0-0.7,0.6-1.4,1.3-1.4
			C587.7,100.9,588.4,101.5,588.4,102.3z"/>
		<path class="st218" d="M670.8,105.8c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8
			C669.5,102.9,670.8,104.2,670.8,105.8z"/>
		<path class="st218" d="M653.2,123.3c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8
			C652,120.5,653.2,121.8,653.2,123.3z"/>
		<path class="st218" d="M679.3,150.3c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8
			C678.1,147.5,679.3,148.7,679.3,150.3z"/>
		<ellipse class="st218" cx="627" cy="189.8" rx="2.8" ry="2.8"/>
		<path class="st218" d="M664.9,245.6c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8
			C663.7,242.8,664.9,244.1,664.9,245.6z"/>
		<path class="st218" d="M725.8,232.5c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8
			C724.5,229.6,725.8,230.9,725.8,232.5z"/>
		<path class="st218" d="M622.1,157.3c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8
			C620.9,154.5,622.1,155.8,622.1,157.3z"/>
		<path class="st218" d="M783.2,119c0,1.6-1.3,2.8-2.8,2.8s-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8S783.2,117.5,783.2,119z"/>
		<path class="st218" d="M720.3,96.3c0,1.6-1.3,2.8-2.8,2.8s-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8S720.3,94.7,720.3,96.3z"/>
		<path class="st218" d="M681.9,306.9c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8s1.3-2.8,2.8-2.8
			C680.7,304.1,681.9,305.3,681.9,306.9z"/>
		<path class="st218" d="M641.8,319.6c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8
			C640.6,316.8,641.8,318.1,641.8,319.6z"/>
		<path class="st218" d="M664.1,259.9c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8
			C662.8,257.1,664.1,258.4,664.1,259.9z"/>
		<path class="st218" d="M602.2,228c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8
			C600.9,225.2,602.2,226.4,602.2,228z"/>
		<path class="st218" d="M629.3,228c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8
			C628.1,225.2,629.3,226.4,629.3,228z"/>
		<path class="st218" d="M706,189.1c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8
			C704.7,186.3,706,187.5,706,189.1z"/>
		<path class="st218" d="M762.8,212.4c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8
			C761.6,209.6,762.8,210.9,762.8,212.4z"/>
		<path class="st218" d="M678,129.8c0,0.7-0.6,1.4-1.3,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C677.4,128.5,678,129.1,678,129.8z"/>
		<path class="st218" d="M637.3,104.7c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C636.7,103.3,637.3,103.9,637.3,104.7z"/>
		<path class="st218" d="M703.5,125c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C702.8,123.7,703.5,124.3,703.5,125z"/>
		<path class="st218" d="M738.2,103.5c0,0.7-0.6,1.4-1.3,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C737.6,102.1,738.2,102.7,738.2,103.5z"/>
		<path class="st218" d="M783.5,98.4c0,0.7-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4S783.5,97.7,783.5,98.4z"/>
		<path class="st218" d="M729.9,146.4c0,0.7-0.6,1.4-1.3,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C729.3,145.1,729.9,145.7,729.9,146.4z"/>
		<path class="st218" d="M757.5,130.8c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.3-0.6-1.3-1.4c0-0.7,0.6-1.4,1.3-1.4
			C756.9,129.4,757.5,130,757.5,130.8z"/>
		<path class="st218" d="M740.6,222.3c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C740,221,740.6,221.6,740.6,222.3z"/>
		<path class="st218" d="M733.7,243.6c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C733.1,242.3,733.7,242.9,733.7,243.6z"/>
		<path class="st218" d="M703,231.1c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.3-0.6-1.3-1.4c0-0.7,0.6-1.4,1.3-1.4
			C702.4,229.8,703,230.4,703,231.1z"/>
		<path class="st218" d="M671.6,235.5c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.3-0.6-1.3-1.4c0-0.7,0.6-1.4,1.3-1.4
			C671,234.1,671.6,234.7,671.6,235.5z"/>
		<path class="st218" d="M645.9,256.2c0,0.7-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4S645.9,255.4,645.9,256.2z"/>
		<path class="st218" d="M585.1,270.6c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.3-0.6-1.3-1.4c0-0.7,0.6-1.4,1.3-1.4
			C584.5,269.3,585.1,269.9,585.1,270.6z"/>
		<path class="st218" d="M603.7,274.3c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.3-0.6-1.3-1.4c0-0.7,0.6-1.4,1.3-1.4
			C603.1,272.9,603.7,273.5,603.7,274.3z"/>
		<path class="st218" d="M664.6,234.1c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C664,232.8,664.6,233.4,664.6,234.1z"/>
		<path class="st218" d="M623.1,251.1c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C622.5,249.8,623.1,250.4,623.1,251.1z"/>
		<path class="st218" d="M673.4,340.3c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.3-0.6-1.3-1.4s0.6-1.3,1.3-1.3
			C672.8,338.9,673.4,339.5,673.4,340.3z"/>
		<path class="st218" d="M777.4,205.2c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.3-0.6-1.3-1.4c0-0.7,0.6-1.4,1.3-1.4
			C776.8,203.9,777.4,204.5,777.4,205.2z"/>
		<path class="st218" d="M714.1,210c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C713.5,208.7,714.1,209.3,714.1,210z"/>
		<path class="st218" d="M620.8,230.4c0,0.7-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4S620.8,229.6,620.8,230.4z"/>
		<path class="st218" d="M639.7,212.4c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C639.1,211.1,639.7,211.7,639.7,212.4z"/>
		<path class="st218" d="M655.4,204c0,0.7-0.6,1.4-1.3,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C654.8,202.7,655.4,203.3,655.4,204z"/>
		<path class="st218" d="M719,206.4c0,0.7-0.6,1.4-1.3,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C718.4,205.1,719,205.7,719,206.4z"/>
		<path class="st218" d="M685.5,189.7c0,0.7-0.6,1.4-1.3,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C684.9,188.3,685.5,188.9,685.5,189.7z"/>
		<path class="st218" d="M692.7,217.2c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C692.1,215.9,692.7,216.5,692.7,217.2z"/>
		<path class="st218" d="M752.7,122.7c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.3-0.6-1.3-1.4c0-0.7,0.6-1.4,1.3-1.4
			C752.1,121.4,752.7,122,752.7,122.7z"/>
		<path class="st218" d="M756.8,354.4c0,0.7-0.6,1.4-1.3,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.3,1.4-1.3
			C756.2,353.1,756.8,353.7,756.8,354.4z"/>
		<path class="st218" d="M755.6,308.9c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C755,307.5,755.6,308.1,755.6,308.9z"/>
		<path class="st218" d="M754,262.6c0,0.7-0.7,1.3-1.4,1.3c-0.8,0-1.3-0.7-1.3-1.4c0-0.7,0.7-1.3,1.4-1.3
			C753.5,261.2,754.1,261.9,754,262.6z"/>
		<path class="st218" d="M708.5,282.6c0,0.7-0.7,1.3-1.4,1.3c-0.7,0-1.3-0.7-1.3-1.4c0-0.7,0.7-1.3,1.4-1.3
			C708,281.2,708.6,281.8,708.5,282.6z"/>
		<path class="st218" d="M775.3,298.3c-1.5-0.4-2.5-1.9-2.1-3.4c0.3-1.5,1.9-2.5,3.4-2.1c1.5,0.4,2.5,1.9,2.1,3.4
			C778.3,297.8,776.8,298.7,775.3,298.3z"/>
		<path class="st218" d="M789.4,262c-0.7-0.2-1.2-0.9-1-1.6c0.2-0.7,0.9-1.2,1.6-1c0.7,0.2,1.2,0.9,1,1.6
			C790.8,261.7,790.1,262.2,789.4,262z"/>
		<path class="st218" d="M769.9,324.9c-0.7-0.2-1.2-0.9-1-1.6c0.2-0.7,0.9-1.2,1.6-1c0.7,0.2,1.2,0.9,1,1.6
			C771.4,324.6,770.7,325.1,769.9,324.9z"/>
		<path class="st218" d="M786,255.2c-0.7-0.2-1.1-0.9-0.9-1.7c0.2-0.7,0.9-1.1,1.7-0.9c0.7,0.2,1.1,0.9,0.9,1.7
			C787.5,255,786.8,255.4,786,255.2z"/>
		<path class="st218" d="M602.3,308.3c-0.7,0.3-1.5,0-1.8-0.7c-0.3-0.7,0-1.5,0.7-1.8c0.7-0.3,1.5,0,1.8,0.7
			C603.3,307.2,603,308,602.3,308.3z"/>
		<path class="st218" d="M657.4,336.2c-0.7,0.3-1.5,0-1.8-0.7c-0.3-0.7,0-1.5,0.7-1.8c0.7-0.3,1.5,0,1.8,0.7
			C658.4,335.1,658.1,335.9,657.4,336.2z"/>
		<path class="st218" d="M610.4,341.4c-0.7,0.3-1.5,0-1.8-0.7c-0.3-0.7,0-1.5,0.7-1.8c0.7-0.3,1.5,0,1.8,0.7
			C611.4,340.3,611.1,341.1,610.4,341.4z"/>
		<path class="st218" d="M643.3,295c-0.7,0.3-1.5-0.1-1.7-0.8c-0.3-0.7,0.1-1.5,0.8-1.7c0.7-0.3,1.5,0.1,1.7,0.8
			C644.3,294,644,294.8,643.3,295z"/>
		<path class="st218" d="M731.1,374.1c0.6,0.5,0.7,1.3,0.2,1.9c-0.5,0.6-1.3,0.7-1.9,0.2c-0.6-0.5-0.7-1.3-0.2-1.9
			C729.6,373.7,730.5,373.6,731.1,374.1z"/>
		<path class="st218" d="M705.2,304.9c0.6,0.5,0.7,1.3,0.2,1.9c-0.5,0.6-1.3,0.7-1.9,0.2c-0.6-0.5-0.7-1.3-0.2-1.9
			C703.8,304.5,704.6,304.4,705.2,304.9z"/>
		<path class="st218" d="M694.2,343.5c0.6,0.5,0.7,1.3,0.2,1.9c-0.5,0.6-1.3,0.7-1.9,0.2c-0.6-0.5-0.7-1.3-0.2-1.9
			C692.8,343.1,693.7,343,694.2,343.5z"/>
		<path class="st218" d="M665.4,429.2c0.6,0.5,0.7,1.3,0.2,1.9c-0.5,0.6-1.3,0.7-1.9,0.2c-0.6-0.5-0.7-1.3-0.2-1.9
			C664,428.8,664.8,428.7,665.4,429.2z"/>
		<path class="st218" d="M776.3,155c0,0.7-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4S776.3,154.2,776.3,155z"/>
		<path class="st218" d="M612.5,218.4c0,0.7-0.6,1.4-1.3,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C611.8,217.1,612.5,217.7,612.5,218.4z"/>
		<path class="st218" d="M703.5,254.3c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C702.8,253,703.5,253.6,703.5,254.3z"/>
		<polygon class="st218" points="733.3,189.5 733.9,198.1 742.2,195.9 734.2,199.1 738.8,206.4 733.3,199.8 727.8,206.4
			732.4,199.1 724.4,195.9 732.8,198.1 		"/>
		<polygon class="st218" points="642.6,229.5 643.1,238.1 651.5,236 643.5,239.2 648.1,246.4 642.6,239.8 637.1,246.4 641.7,239.2
			633.7,236 642,238.1 		"/>
		<path class="st218" d="M671.9,282.8c-1.5,0.3-3-0.7-3.3-2.3c-0.3-1.5,0.7-3,2.3-3.3c1.5-0.3,3,0.7,3.3,2.3
			C674.5,281.1,673.4,282.6,671.9,282.8z"/>
		<path class="st218" d="M744.5,276.1c-1.5,0.3-3-0.7-3.3-2.3c-0.3-1.5,0.7-3,2.3-3.3c1.5-0.3,3,0.7,3.3,2.3
			C747.1,274.3,746,275.8,744.5,276.1z"/>
		<path class="st218" d="M758.9,292.9c-0.7,0.1-1.4-0.4-1.6-1.1c-0.1-0.7,0.4-1.4,1.1-1.6c0.7-0.1,1.4,0.4,1.6,1.1
			C760.2,292,759.7,292.7,758.9,292.9z"/>
		<path class="st218" d="M732.8,310.4c-0.7,0.1-1.4-0.4-1.6-1.1c-0.1-0.7,0.4-1.4,1.1-1.6c0.7-0.1,1.4,0.4,1.6,1.1
			C734,309.6,733.5,310.3,732.8,310.4z"/>
		<path class="st218" d="M758.6,313.2c-0.7,0.1-1.4-0.4-1.6-1.1c-0.1-0.7,0.4-1.4,1.1-1.6c0.7-0.1,1.4,0.4,1.6,1.1
			C759.8,312.4,759.3,313.1,758.6,313.2z"/>
		<path class="st218" d="M696.7,285.1c-0.7,0.1-1.4-0.4-1.6-1.1c-0.1-0.7,0.4-1.4,1.1-1.6c0.7-0.1,1.4,0.4,1.6,1.1
			C697.9,284.2,697.4,285,696.7,285.1z"/>
		<path class="st218" d="M727.1,305.5c-0.7,0.1-1.4-0.4-1.5-1.2c-0.1-0.7,0.4-1.4,1.2-1.5c0.7-0.1,1.4,0.4,1.5,1.2
			C728.3,304.7,727.8,305.4,727.1,305.5z"/>
		<path class="st218" d="M604.4,315.7c-0.4-0.6-0.2-1.5,0.4-1.9c0.6-0.4,1.5-0.2,1.9,0.4c0.4,0.6,0.2,1.5-0.4,1.9
			C605.6,316.5,604.8,316.3,604.4,315.7z"/>
		<path class="st218" d="M604.4,268.2c-0.4-0.6-0.2-1.5,0.5-1.8c0.6-0.4,1.5-0.2,1.8,0.5c0.4,0.6,0.2,1.5-0.5,1.8
			C605.6,269.1,604.8,268.9,604.4,268.2z"/>
		<path class="st218" d="M644.3,272.1c-0.4-0.6-0.2-1.5,0.5-1.8c0.6-0.4,1.5-0.2,1.9,0.5c0.4,0.6,0.2,1.5-0.5,1.8
			C645.5,272.9,644.7,272.7,644.3,272.1z"/>
		<path class="st218" d="M734.5,39.5c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C733.9,38.2,734.5,38.8,734.5,39.5z"/>
		<path class="st218" d="M710.5,27.4c0,0.7-0.6,1.3-1.4,1.3s-1.4-0.6-1.4-1.3c0-0.7,0.6-1.4,1.4-1.4S710.5,26.6,710.5,27.4z"/>
		<path class="st218" d="M777.8,38c0,1.6-1.3,2.8-2.8,2.8s-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8S777.8,36.4,777.8,38z"/>
		<path class="st218" d="M718.8,94.6c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8
			C717.5,91.8,718.8,93,718.8,94.6z"/>
		<path class="st218" d="M703,58.1c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8
			C701.7,55.3,703,56.5,703,58.1z"/>
		<path class="st218" d="M765.9,86.2c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8
			C764.6,83.4,765.9,84.7,765.9,86.2z"/>
		<path class="st218" d="M777.2,110.2c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.3-0.6-1.3-1.4c0-0.7,0.6-1.4,1.3-1.4
			C776.6,108.8,777.2,109.4,777.2,110.2z"/>
		<path class="st218" d="M751.6,107.9c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C751,106.6,751.6,107.2,751.6,107.9z"/>
		<path class="st218" d="M779.8,67.5c0,0.7-0.6,1.4-1.3,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C779.2,66.2,779.8,66.8,779.8,67.5z"/>
		<path class="st218" d="M686.7,120.9c0,0.7-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4S686.7,120.2,686.7,120.9z"/>
		<path class="st218" d="M715.3,73.2c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.3-0.6-1.3-1.4c0-0.7,0.6-1.4,1.3-1.4
			C714.7,71.8,715.3,72.4,715.3,73.2z"/>
		<path class="st218" d="M738.5,76.7c0,0.7-0.7,1.3-1.4,1.3c-0.7,0-1.3-0.7-1.3-1.4c0-0.7,0.7-1.3,1.4-1.3
			C737.9,75.3,738.5,75.9,738.5,76.7z"/>
		<path class="st218" d="M745.5,61.1c0,0.7-0.7,1.3-1.4,1.3c-0.7,0-1.3-0.7-1.3-1.4c0-0.7,0.7-1.3,1.4-1.3
			C744.9,59.7,745.5,60.3,745.5,61.1z"/>
		<path class="st218" d="M686.9,276.9c-0.4-0.6-0.2-1.5,0.5-1.9c0.6-0.4,1.5-0.2,1.9,0.5c0.4,0.6,0.2,1.5-0.5,1.8
			C688.1,277.8,687.3,277.6,686.9,276.9z"/>
		<path class="st218" d="M635.1,291.3c-0.4-0.6-0.2-1.5,0.5-1.9c0.6-0.4,1.5-0.2,1.9,0.5c0.4,0.6,0.2,1.5-0.5,1.9
			C636.3,292.2,635.5,291.9,635.1,291.3z"/>
		<path class="st218" d="M589.5,400.4c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.3,1.4-1.3
			C588.9,399.1,589.5,399.7,589.5,400.4z"/>
		<path class="st218" d="M621.7,375.3c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C621.1,373.9,621.7,374.5,621.7,375.3z"/>
		<path class="st218" d="M656.8,387.2c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.3-0.6-1.3-1.4s0.6-1.4,1.3-1.4
			C656.2,385.9,656.8,386.5,656.8,387.2z"/>
		<path class="st218" d="M633.9,395.6c0,0.7-0.6,1.4-1.3,1.4c-0.7,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4
			C633.2,394.3,633.9,394.9,633.9,395.6z"/>
		<path class="st218" d="M599.1,377.7c0,0.7-0.6,1.3-1.4,1.3c-0.7,0-1.4-0.6-1.4-1.3c0-0.7,0.6-1.4,1.4-1.4
			C598.5,376.3,599.1,376.9,599.1,377.7z"/>
		<path class="st218" d="M588.4,354.9c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.3-0.6-1.3-1.4c0-0.7,0.6-1.3,1.3-1.3
			C587.7,353.6,588.4,354.2,588.4,354.9z"/>
		<path class="st218" d="M670.8,358.4c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8
			C669.5,355.6,670.8,356.8,670.8,358.4z"/>
		<path class="st218" d="M653.2,375.9c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8
			C652,373.1,653.2,374.4,653.2,375.9z"/>
		<path class="st218" d="M679.3,402.9c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8
			C678.1,400.1,679.3,401.4,679.3,402.9z"/>
		<path class="st218" d="M629.8,442.4c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8
			C628.5,439.6,629.8,440.9,629.8,442.4z"/>
		<path class="st218" d="M622.1,410c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8
			C620.9,407.2,622.1,408.4,622.1,410z"/>
		<path class="st218" d="M783.2,371.7c0,1.6-1.3,2.8-2.8,2.8s-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8S783.2,370.1,783.2,371.7z"/>
		<path class="st218" d="M720.3,348.9c0,1.6-1.3,2.8-2.8,2.8s-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8S720.3,347.4,720.3,348.9z"/>
		<path class="st218" d="M757,433.9c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8
			C755.7,431.1,757,432.3,757,433.9z"/>
		<path class="st218" d="M706,441.7c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8
			C704.7,438.9,706,440.2,706,441.7z"/>
		<path class="st218" d="M762.8,465.1c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8
			C761.6,462.2,762.8,463.5,762.8,465.1z"/>
		<path class="st218" d="M678,382.4c0,0.7-0.6,1.4-1.3,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C677.4,381.1,678,381.7,678,382.4z"/>
		<path class="st218" d="M637.3,357.3c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C636.7,355.9,637.3,356.6,637.3,357.3z"/>
		<path class="st218" d="M703.5,377.7c0,0.7-0.6,1.3-1.4,1.3c-0.7,0-1.4-0.6-1.4-1.3c0-0.7,0.6-1.4,1.4-1.4
			C702.8,376.3,703.5,376.9,703.5,377.7z"/>
		<path class="st218" d="M738.2,356.1c0,0.7-0.6,1.4-1.3,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C737.6,354.8,738.2,355.4,738.2,356.1z"/>
		<path class="st218" d="M794.3,355.1c0,0.7-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4S794.3,354.3,794.3,355.1z"/>
		<path class="st218" d="M729.9,399.1c0,0.7-0.6,1.4-1.3,1.4c-0.7,0-1.4-0.6-1.4-1.4s0.6-1.3,1.4-1.3
			C729.3,397.7,729.9,398.3,729.9,399.1z"/>
		<path class="st218" d="M757.5,383.4c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.3-0.6-1.3-1.4c0-0.7,0.6-1.4,1.3-1.4
			C756.9,382,757.5,382.7,757.5,383.4z"/>
		<path class="st218" d="M740.6,475c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C740,473.6,740.6,474.2,740.6,475z"/>
		<path class="st218" d="M777.4,457.9c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.3-0.6-1.3-1.4c0-0.7,0.6-1.4,1.3-1.4
			C776.8,456.5,777.4,457.1,777.4,457.9z"/>
		<path class="st218" d="M714.1,462.7c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.3,1.4-1.3
			C713.5,461.3,714.1,461.9,714.1,462.7z"/>
		<path class="st218" d="M639.7,465.1c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C639.1,463.7,639.7,464.3,639.7,465.1z"/>
		<path class="st218" d="M655.4,456.7c0,0.7-0.6,1.3-1.3,1.3c-0.7,0-1.4-0.6-1.4-1.3c0-0.7,0.6-1.4,1.4-1.4
			C654.8,455.3,655.4,455.9,655.4,456.7z"/>
		<path class="st218" d="M719,459.1c0,0.7-0.6,1.4-1.3,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C718.4,457.7,719,458.3,719,459.1z"/>
		<path class="st218" d="M685.5,442.3c0,0.7-0.6,1.4-1.3,1.4c-0.7,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4
			C684.9,441,685.5,441.6,685.5,442.3z"/>
		<path class="st218" d="M692.7,469.9c0,0.7-0.6,1.3-1.4,1.3c-0.7,0-1.4-0.6-1.4-1.3c0-0.7,0.6-1.4,1.4-1.4
			C692.1,468.5,692.7,469.1,692.7,469.9z"/>
		<path class="st218" d="M641.5,521.3c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8s1.3-2.8,2.8-2.8
			C640.2,518.5,641.5,519.8,641.5,521.3z"/>
		<path class="st218" d="M596.1,525.5c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8
			C594.9,522.7,596.1,524,596.1,525.5z"/>
		<path class="st218" d="M698.5,539.3c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8
			C697.2,536.5,698.5,537.7,698.5,539.3z"/>
		<path class="st218" d="M652.4,462.7c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8
			C651.2,459.9,652.4,461.1,652.4,462.7z"/>
		<path class="st218" d="M596.5,538.3c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C595.9,536.9,596.5,537.5,596.5,538.3z"/>
		<path class="st218" d="M610.8,499.9c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C610.2,498.6,610.8,499.2,610.8,499.9z"/>
		<path class="st218" d="M615.8,531.6c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C615.2,530.3,615.8,530.9,615.8,531.6z"/>
		<path class="st218" d="M585.1,558.6c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.3-0.6-1.3-1.4c0-0.7,0.6-1.4,1.3-1.4
			C584.5,557.2,585.1,557.8,585.1,558.6z"/>
		<path class="st218" d="M607.2,469.7c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C606.6,468.3,607.2,469,607.2,469.7z"/>
		<path class="st218" d="M638.4,497.2c0,0.7-0.6,1.3-1.4,1.3c-0.7,0-1.3-0.6-1.3-1.3c0-0.8,0.6-1.4,1.3-1.4
			C637.8,495.9,638.4,496.5,638.4,497.2z"/>
		<path class="st218" d="M634.8,543c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C634.2,541.7,634.8,542.3,634.8,543z"/>
		<path class="st218" d="M656.3,499.9c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C655.7,498.6,656.3,499.2,656.3,499.9z"/>
		<path class="st218" d="M692.3,496.3c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C691.7,495,692.3,495.6,692.3,496.3z"/>
		<path class="st218" d="M663.7,533.3c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.3-0.6-1.3-1.4c0-0.7,0.6-1.4,1.3-1.4
			C663.1,532,663.7,532.6,663.7,533.3z"/>
		<path class="st218" d="M633.6,463.7c0,0.7-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4S633.6,463,633.6,463.7z"/>
		<polygon class="st218" points="577.3,472 577.9,480.6 586.2,478.5 578.2,481.7 582.8,489 577.3,482.3 571.8,489 576.4,481.7
			568.4,478.5 576.8,480.6 		"/>
		<polygon class="st218" points="647.1,538 647.7,546.6 656,544.5 648,547.7 652.6,555 647.1,548.3 641.6,555 646.2,547.7
			638.2,544.5 646.5,546.6 		"/>
		<path class="st218" d="M619.1,569.2c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.3-0.6-1.3-1.4c0-0.7,0.6-1.3,1.3-1.3
			C618.5,567.9,619.1,568.5,619.1,569.2z"/>
		<path class="st218" d="M649.2,564.6c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C648.5,563.2,649.2,563.8,649.2,564.6z"/>
		<path class="st218" d="M686.3,574.2c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.3-0.6-1.3-1.4c0-0.7,0.6-1.4,1.3-1.4
			C685.7,572.8,686.3,573.4,686.3,574.2z"/>
		<path class="st218" d="M631.6,75.4c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8s1.3-2.8,2.8-2.8
			C630.3,72.6,631.6,73.8,631.6,75.4z"/>
		<path class="st218" d="M586.3,79.6c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8
			C585,76.8,586.3,78,586.3,79.6z"/>
		<path class="st218" d="M688.7,93.3c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8
			C687.4,90.5,688.7,91.8,688.7,93.3z"/>
		<path class="st218" d="M642.6,16.7c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8
			C641.3,13.9,642.6,15.2,642.6,16.7z"/>
		<path class="st218" d="M586.6,92.3c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C586,90.9,586.6,91.5,586.6,92.3z"/>
		<path class="st218" d="M601,54c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.3-0.6-1.3-1.4c0-0.7,0.6-1.4,1.3-1.4C600.4,52.6,601,53.2,601,54z"
			/>
		<path class="st218" d="M606,85.7c0,0.7-0.6,1.4-1.3,1.4c-0.8,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C605.3,84.3,606,84.9,606,85.7z"/>
		<path class="st218" d="M597.4,23.7c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C596.8,22.4,597.4,23,597.4,23.7z"/>
		<path class="st218" d="M628.5,51.3c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C627.9,49.9,628.5,50.5,628.5,51.3z"/>
		<path class="st218" d="M624.9,97.1c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C624.3,95.7,624.9,96.3,624.9,97.1z"/>
		<path class="st218" d="M646.5,54c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C645.9,52.6,646.5,53.2,646.5,54z"/>
		<path class="st218" d="M682.4,50.4c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C681.8,49,682.4,49.6,682.4,50.4z"/>
		<path class="st218" d="M653.8,87.3c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.3-0.6-1.3-1.4c0-0.7,0.6-1.4,1.3-1.4
			C653.2,86,653.8,86.6,653.8,87.3z"/>
		<path class="st218" d="M623.7,17.8c0,0.7-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4S623.7,17,623.7,17.8z"/>
		<polygon class="st218" points="637.2,92.1 637.8,100.7 646.1,98.5 638.1,101.7 642.7,109 637.2,102.4 631.7,109 636.3,101.7
			628.3,98.5 636.7,100.7 		"/>
		<path class="st218" d="M609.2,123.3c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.3-0.6-1.3-1.4c0-0.7,0.6-1.3,1.3-1.3
			C608.6,121.9,609.2,122.5,609.2,123.3z"/>
		<path class="st218" d="M639.3,118.6c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C638.7,117.3,639.3,117.9,639.3,118.6z"/>
		<path class="st218" d="M676.4,128.2c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C675.8,126.9,676.4,127.5,676.4,128.2z"/>
		<path class="st218" d="M778.5,498.5c0,1.6-1.3,2.8-2.8,2.8s-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8S778.5,497,778.5,498.5z"/>
		<path class="st218" d="M778.9,511.3c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
			C778.3,509.9,778.9,510.5,778.9,511.3z"/>
		<path class="st218" d="M763.3,364.5c0,0.7-0.6,1.4-1.4,1.4c-0.7,0-1.3-0.6-1.3-1.4c0-0.7,0.6-1.4,1.3-1.4
			C762.7,363.1,763.3,363.7,763.3,364.5z"/>
		<path class="st218" d="M776.3,407.6c0,0.7-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4S776.3,406.8,776.3,407.6z"/>
		<path class="st218" d="M612.5,471c0,0.7-0.6,1.3-1.3,1.3c-0.7,0-1.4-0.6-1.4-1.3s0.6-1.4,1.4-1.4
			C611.8,469.7,612.5,470.3,612.5,471z"/>
		<polygon class="st218" points="733.3,442.1 733.9,450.7 742.2,448.6 734.2,451.7 738.8,459 733.3,452.4 727.8,459 732.4,451.7
			724.4,448.6 732.8,450.7 		"/>
		<path class="st219" d="M738.5,529.4c0,2,1.6,3.6,3.6,3.6c2,0,3.6-1.6,3.6-3.6s-1.6-3.6-3.6-3.6
			C740.1,525.8,738.5,527.4,738.5,529.4z"/>
		<path class="st220" d="M590.3,173c0,3.5,2.8,6.3,6.3,6.3c3.5,0,6.3-2.8,6.3-6.3c0-3.5-2.8-6.3-6.3-6.3
			C593.2,166.7,590.3,169.6,590.3,173z"/>
	</g>
	<path class="st221" d="M363,77.7C242.2,108.5,121.6,172.6,0,197.8v43.5C86,229,201,181.7,224.3,185c9.4,1.3,15.9,14.3,7.8,18.5
		c-16.7,8.7-34.1,16-51.7,22.7c-19.9,7.6-39.9,14.8-60,22c-36.2,13-72.6,25.6-109.5,36.7c-3.7,1.1-7.4,2.2-11.1,3.3V308
		c15.7-3.3,31.3-7.6,46.5-11.4c34.1-8.5,67.9-17.8,101.6-27.5c24.5-7,49.1-14,73-22.9c29-10.7,60.5-23.8,82.5-46.4
		c3.7-3.8,7.3-8.2,10.4-13.5c1.7-2.9,5.2-7.8,8-10.3c10.4-9.1,23.5-4.7,37-7.1c146.8-25.9,306,59.9,441,36.8V82.2
		C652.6,23.4,507.7,41.1,363,77.7z M687,87.1c15.7,12.2,17.6,21.8,14.5,27.1c-3.8,6.4-13.6,10.2-29.1,7c-20.2-4.1-29.8-2.7-56.3-1.4
		c-29.9,1.4-50.7,2.6-67.1,2.1c-16-0.1-21.6-8.5-22.5-11.8c-1.4-4.1,0-10.4,17.1-17.6c17.5-6.6,40-9.2,68.2-15.1
		C640.4,71.4,672,74.4,687,87.1z"/>
	<path class="st221" d="M800,480.8c-120.9,21.6-276.3-2.4-281.7-15.1c-6.5-15.4,7.7-21.2,23.1-27.6c19.1-8,36.7-19,55.7-27.3
		c49.5-21.8,101.7-38.2,154.7-49.1c16-3.2,32.1-6,48.3-8.2v-17.6c-17.3,0-34.6,0.8-51.7,2.3c-54.1,4.6-107.9,15.3-159.6,31.8
		c-18.4,5.9-35.8,13.9-53.8,20.8c-24.4,9.4-50.5,14.9-76,20.2c-26.2,5.5-52.7,9.9-79.2,13.1c-5.8,0.7-38.8-4.7-44.7-5
		c-122.6-8-212.9-56.2-335-36v144.5c138.6-49,276-45.1,413.7-26.6c128,17.1,256.3,46.7,386.3,56.3V480.8z M190.1,460.6
		c-36.9,0-61.5,4.2-61.5-4.1c0-8.4,24.6-26.1,61.5-26.1c36.9,0,72,17.8,72,26.1C262.1,464.8,227,460.6,190.1,460.6z"/>
	<g>
		<path class="st222" d="M674.6,268.6c2.3,4.5-6.6,11.3-15.1,16.1c-8.7,5.1-17.1,8.1-18,11.3c-3.3,6.3,28.1,20.8,23.7,27.1
			c-6.1,6.4-42.2-4.8-47.4-2.5c-3,1.4-0.6,7.4,3.5,14.8c3.8,7.2,9.3,15.7,5.3,17.7c-3.8,1.9-16.3-4.7-26.9-11.8
			c-10.9-7.1-20-14.7-25-15.5c-5.2-0.9-8.8,5-13,12.2c-4.1,6.9-8.8,14.9-15.2,11.9c-6.6-3-11-15.1-13.2-25.5
			c-2.4-10.6-2.5-19.5-5.3-22.7c-4.7-6.9-29,7.5-32.4,3c-2.5-5.2,21.7-28.7,23.1-33.8c0.8-2.8-3.8-2.8-9.6-1.8
			c-5.5,0.8-12.3,2.6-10.2-1.6c2.1-4.1,12.5-11.8,21.7-17.3c9.5-5.8,17.8-9.4,20.9-12.3c6.9-5.7-2.7-16.4,4.5-22.6
			c7.5-6.1,23.5,1.7,27.9-0.8c2.3-1.5,2.8-6.6,1.6-13.2c-1-6.4-3.8-14.1-2.8-16.1c3-3.7,19,21.3,21.8,21.8c2.1,0.5,3-5,3.3-11.5
			c0.4-6.3,0.2-13.6,3.1-11.3c2.9,2.4,6.9,12.8,9.3,22c2.6,9.4,3.6,17.4,5.4,20.3c3.5,6.3,13.5-6.7,18.8-1.6
			c6.2,5.9-2.6,28.1-0.1,34.1c1.7,3.2,8.5,4.5,17.7,4.9C661.1,264.3,672.4,264.1,674.6,268.6z"/>
		<path class="st222" d="M618.3,268.5c0.8,1.7-2.4,4.2-5.6,6c-3.2,1.9-6.3,3-6.7,4.2c-1.2,2.3,10.4,7.7,8.8,10
			c-2.2,2.4-15.6-1.8-17.6-0.9c-1.1,0.5-0.2,2.8,1.3,5.5c1.4,2.7,3.4,5.8,1.9,6.6c-1.4,0.7-6-1.7-10-4.4c-4.1-2.6-7.4-5.5-9.3-5.7
			c-1.9-0.3-3.3,1.9-4.8,4.5c-1.5,2.5-3.2,5.5-5.6,4.4c-2.5-1.1-4.1-5.6-4.9-9.4c-0.9-3.9-0.9-7.2-2-8.4c-1.8-2.6-10.7,2.8-12,1.1
			c-0.9-1.9,8-10.7,8.6-12.5c0.3-1-1.4-1-3.6-0.7c-2,0.3-4.5,1-3.8-0.6c0.8-1.5,4.6-4.4,8.1-6.4c3.5-2.1,6.6-3.5,7.8-4.6
			c2.6-2.1-1-6.1,1.7-8.4c2.8-2.3,8.7,0.6,10.4-0.3c0.9-0.5,1-2.5,0.6-4.9c-0.4-2.4-1.4-5.2-1-6c1.1-1.4,7,7.9,8.1,8.1
			c0.8,0.2,1.1-1.9,1.2-4.3c0.2-2.3,0.1-5,1.2-4.2c1.1,0.9,2.6,4.8,3.4,8.1c1,3.5,1.3,6.4,2,7.5c1.3,2.3,5-2.5,7-0.6
			c2.3,2.2-1,10.4,0,12.6c0.6,1.2,3.2,1.7,6.6,1.8C613.3,266.9,617.5,266.9,618.3,268.5z"/>
	</g>
	<path class="st222" d="M158.8,112.2c-0.2,2.9-6.7,3.6-12.4,3.5c-5.9-0.1-11-1.1-12.4,0.2c-3.6,2.2,7.8,18.9,3.7,20.7
		c-5,1.4-19.6-15.2-22.9-15.6c-1.9-0.2-2.6,3.5-2.7,8.4c-0.3,4.7-0.1,10.6-2.7,10.4c-2.5-0.2-6.7-7.3-9.9-14
		c-3.3-6.9-5.5-13.4-7.8-15.3c-2.3-2-5.9-0.1-10.2,2.2c-4.1,2.2-8.9,4.8-11.2,1.3c-2.4-3.5-0.9-10.9,1.1-16.7c2-6,4.6-10.5,4.2-12.9
		c-0.3-4.9-16.7-5-17.1-8.3c0.3-3.3,19.5-7.8,21.8-9.9c1.2-1.2-1.1-2.5-4.3-3.8c-3-1.3-6.9-2.4-4.6-3.9c2.3-1.4,9.8-2.1,16.1-2.1
		c6.5,0,11.7,0.7,14.2,0.2c5.2-0.7,3.6-9,9.1-9.9c5.6-0.8,11.2,7.9,14.2,8c1.6,0,3.4-2.5,4.8-6.1c1.4-3.5,2.3-8.2,3.5-8.9
		c2.6-0.9,3,16.4,4.3,17.5c0.9,0.9,3-1.6,5.1-4.8c2.1-3,4.2-6.7,5-4.7c0.7,2.1-0.4,8.5-2,13.8c-1.5,5.5-3.5,9.8-3.4,11.7
		c-0.2,4.2,8.8,0.7,9.9,4.9c1.3,4.8-9.8,13.2-10.3,17c-0.1,2.1,2.9,4.8,7.4,7.8C153.4,106,159.1,109.3,158.8,112.2z"/>
	<g>
		<defs>
			<rect id="SVGID_163_" x="38.4" y="336.5" width="747.1" height="263.5"/>
		</defs>
		<clipPath id="SVGID_164_">
			<use style={{overflow:"visible"}}/>
		</clipPath>
		<g class="st223">
			<g>
				<g>
					<path class="st224" d="M697.2,711.5c0,160-129.7,289.6-289.6,289.6c-160,0-289.6-129.7-289.6-289.6s129.7-289.6,289.6-289.6
						C567.5,421.9,697.2,551.6,697.2,711.5z"/>
					<g>
						<path class="st225" d="M464.9,533.2c3,0.5,3.3-1.7,6.6-0.9c-3.1,3.5,3.1,3.8,2.8,7.6c-2.3,2.3-4.1-0.7-7.5,0
							c2.7,5.3,0.3,5.9-2.8,9.4c3.2,0.6,8.8-1.2,10.4,0.9c-1.1,5.2-11.9,0.6-11.3,7.6c6.9-2.8,15.6-3.9,24.5-4.7
							c-2.1-6,2.4-3.4,1.9-9.4c-10.7-1.6-10.4-14.1-17.9-18.9c1.1-2.3,5.8-1.1,4.7-5.7c-2.2-0.6-6.8,1.2-7.5-0.9
							c1.2-1.9,3.1-3.2,5.7-3.8c-10-8.4-15,7.6-12.3,15.1C463.9,526.2,466,530.5,464.9,533.2z"/>
						<path class="st225" d="M336.7,769.9c-12.7-4.2-19.2-5.7-27.4-7.5c-5.7-1.3-11.3,10.3-16,3.8c-3.5-4.8,6.1-5.4,5.7-9.4
							c-0.4-3.9-2.2-5.7-3.8-12.3c-1.6-6.5-12.1-4.1-21.7-6.6c-5.3-1.4-6.3-9.3-11.3-11.3c-2.9-1.2-5.3,2.7-6.6,0.9
							c-2.8-3.8,0.4-5.6-2.8-8.5c-8-7-19.1,1-27.4-2.8c-6.6-0.9-3,8.3-8.5,8.5c-2.1-3.5,2.4-7.3,3.8-10.4c-4.8-5.1-6.4,5.9-14.1,3.8
							c-3.5,1.8-5.3,5.4-6.6,9.4c-3.6-0.5-5.1-3.1-8.5-3.8c-13.7,6.7-22.1-6.5-15.1-18.9c-3.2-5-11.9-4.4-19.8-4.7
							c4.3-6.5,7.8-12.3,6.6-19.8c-1.5-9.3-18.5-0.2-18.9,7.5c-23,9.9-20.8-20.6-18.9-35.8c7.7-7.4,16.8-6.1,29.2-3.8
							c0.6-2.5-2-1.8-2.8-2.8c7.7-1.6,15.4-2.1,21.7,1.9c6.1,3.9,4.3,19.2,11.3,17.9c12-2.2-8.5-22.6,1.9-32.1
							c5.3-1.7,10.1-3.7,14.1-6.6c2.3-5.5,1-11.8-2.8-17.9c4.1-0.3,3.9,3.6,5.7,5.7c5-2.4-2.3-7.4,0.9-10.4c0.3-4.5,2.8,2.1,4.7,1.9
							c4.1-0.5-1.2-5.1,1.9-6.6c4.2-2,12.1,0.7,14.2-2.8c1.5-2.7-4.1-2-3.8-4.7c1-8.4,11.9-9.9,18.9-12.3c4.7-1.6-6.6,7.1-0.9,7.5
							c6.5,0.5,17-0.3,18.9-7.5c1.3-5.2-13.8-0.3-14.1-8.5c-0.2-4.2,4.2-4.5,2.8-7.5c-5.4-12-20.9,14.8-27.4,8.5
							c-16.4-16.1,39.3-33.2,54.7-21.7c5.6,4.2-12,10.4-10.4,17c1.2,5.1,11.8,2.4,17,4.7c3.1,1.3,5.3-3.7,6.6-6.6
							c1.3-2.9-2.4-3.6-4.7-6.6c-2.1-2.7-6.1,2.9-6.6,0c-2.2-13.4,0.6-26.5-14.1-26.4c-1.7-2.7-2.5-6.3-6.6-6.6
							c1.1-12.8-7.2-16.1-11.3-23.6c-3,4.2-3.2,11.3-8.5,13.2c-2.5-0.9-3.1-3.8-4.7-5.7c-2.8-0.8-4.6,4-5.7,1.9
							c5.6-9.8-6.1-11.6-8.5-18.9c-7.5,7-15.7,13.3-21.7,21.7c4.3,2.1,6.4,7.8,4.7,13.2c-8.7,1-11.7,10.2-7.5,18.9
							c-1.5,2.9-3.7,5.1-6.6,6.6c-1.6-7.2-7.6-10-6.6-19.8c-7.5,8.9-14.4,18.3-19.8,29.2c2.7,0.8,2.4-1.3,4.7-0.9
							c0.3,6.6,8.1,5.7,7.5,13.2c5,2.8,15.6,0.1,17,6.6c-2.3,2.3-2.5-2.3-6.6-0.9c-0.5,3.3,1.4,8.9-1.9,9.4c-2-0.2-0.3-4.1-1.9-4.7
							c-3.5-1-1.5,3.5-4.7,2.8c-0.6-3.8,2.5-3.8,1.9-7.5c-2.1-1.1-3.8-2.4-7.5-1.9c-6.1,2.4,1.8,18.8-8.5,17
							c-1.4-3.8-1.4-11.3,0-15.1c1.7-3,8.3-1.1,8.5-5.7c-3-3-9.8-2.1-13.2-4.7c-20.1,34.6-33,76.4-36.8,127.3c0,0.6,0,1.3,0,1.9
							c7.1,0.6,11.8,3.1,17.9,0c6.7,5.4,14.7,12.9,25.5,11.3c4.5,7.5,7.8,16.1,15.1,20.7c5.1-3.1,6.6,1.9,10.4,2.8
							c0.4-3.3,1.6-6,4.7-6.6c3.5,4.7,7.3,9.1,6.6,17.9c-4.7,8.8-16,11-16,24.5c2.9,0.4,4.8-2.5,5.7,0.9c-1.2,3.2-5.2,3.6-5.7,7.5
							c8.2,11.3,15.7,23.3,19.8,38.7c10.5,3.4,22.4,5.2,21.7,19.8c0.4,7.3-0.3,13.6-1.9,18.9c-0.6,3.5,1.2,9.4-0.9,11.3
							c-4.3,11.4-1.9,29.5-10.4,36.8c-0.1,7.7,2.1,13.2-1.9,17.9c8.1,7.6,16.5,15,25.5,21.7c2.2,0.3,1-2.7,2.8-2.8
							c1.5-4.2,5.1-6.2,6.6-10.4c0-3.6-4.6-6-1.9-9.4c5.5,0.6,7.6,2.9,12.3,0c1.4-3.4-1.3-6.8,0.9-10.4c9.5,1.1,20.7-0.8,18.9-12.3
							c-0.5-3.1-9.6-4.3-5.7-6.6c5.3-3,10.1,3.2,15.1,1c10.8-4.8,26.4-17,21.7-28.3c-0.8-1.9-0.7-5.6,0-6.6
							c5.5-7.6,14.6-7.1,21.7-11.3c7.9-4.6,13-12.2,13.2-21.7c0.2-7-3.7-16.7,1.9-20.8c1.3-0.9,3.4,0.8,4.7,0
							c4.6-2.9,8.6-7.9,9.4-12.3C357.9,778.9,345.8,773,336.7,769.9z M267.8,536c2.3,2-3.5,4.2-5.7,4.7
							C257.7,541.8,266.1,537.2,267.8,536z"/>
						<path class="st225" d="M379.1,441.7c1.9-6.1,4.7,0.1,8.5,0c2.4,0.2,3-1.4,3.8-2.8c1-3.5-3.5-1.5-2.8-4.7
							c6.3,0.9,7.1,13.2,15.1,6.6c0-1.9,0-3.8,0-5.7c-2.9-3.3-8.1-7.5-13.2-5.7c2.3-3.5,1.4-5.4-1.9-7.6
							c-36.8,1.6-66.4,10.3-93.4,21.7c-0.2,0.8,0.4,0.9,1,0.9c3,3.3-2.1,4.2-1,8.5c-0.7,1.9-5.3-0.2-5.7,1.9
							c2.8,1.9,8.6,0.9,8.5,5.7c-4.3,1.1-5.4-2.4-11.3-1.9c-1,0.2-0.6,1.9-1.9,1.9c1,4.4,4.1,4.1,0.9,8.5c5.4,0,6.4-4.3,11.3-4.7
							c-0.2,4.5-7.2,2.2-7.5,6.6c0,4.1,1.9,6.3,2.8,9.4c3.1,0.2,5-4.8,6.6-1.9c2,3.5-3.1,3.2-4.7,4.7c3.3,7.5,4,15.1,12.3,17.9
							c8.3,2.9,8.7,3.9,14.1,4.7c6.9,1.1,2.3-11.9,6.6-17c3.4-3.9,5.8-5.1,4.7-7.6c-0.6-1.3,2.5-0.6,3.8-1.9
							c1.5-1.5-2.2-0.9-2.8-2.8c-1.7-5.6,9.1-2.7,12.3-8.5c2.3,0.1,0.3,3.2,0,3.8c8.8-3.8,18.8-6.4,20.7-17
							c12.9,4.7,26.3-5.5,35.8-10.4C394.2,441.6,385.6,444.8,379.1,441.7z"/>
						<path class="st225" d="M502.7,513.4c1,10,16.3,0.7,19.7-4.7c6.1,4.6,3,20.2,10.4,21.7c7.4,1.5,14.8-8.7,13.2-17
							c0.7-4,6.6-2.8,5.7-8.5c-9.6-8.8,0-22.3,10.4-22.6c-3-8.9,7.4-8.6,5.7-13.2c-8.1-3.9-16.4-10.1-22.6-13.2
							c-7.4,1.1-7.8,9.2-15.1,10.4c0.2,9-5,12.6-10.4,16c-0.9,3.4,4.1,1,4.7,2.8c-7.7,0.8-18.2,3.6-22.6,10.4
							c-0.4,6.1,2,9.3,4.7,12.3C507.2,511,502.5,511.5,502.7,513.4z"/>
						<path class="st225" d="M641.3,719.9c-0.4-3.4-2.4-4.6-1-7.5c-1.3-1.5-2.7-3-3.8-4.7c-4.4-1.9-6.9-5.7-11.3-7.5
							c1.6-9.8-4.9-11.5-8.5-16c3.7-9.3-4.8-10.5-5.7-17.9c-0.3,0-0.6,0-0.9,0c-1.5-4-5.9-9.5-4.7-13.2c3.6-0.4,5.9,0.4,6.6,2.8
							c0.2,1.4,1.6,1.6,1.9,2.8c2.7,5.1,2.8,13.7,8.2,16.1c-0.4,16.5,16.9,14.4,16.3,31.1c1.7,1.4,2.2,4.1,3.8,5.7
							c18.2-3,36.6-13.6,52.8-21.7c0.1-7.3-1.3-13.1-1.9-19.8c-4.6-1.1-0.6-10.7-6.6-10.4c2.3,0.1,2.9-1.5,4.7-1.9
							c-3.1-14.8-6.2-29.7-11.3-42.5c-7.8,4.1-16-1.2-19.8-6.6c1.8-2.3,4.2-4,3.8-8.5c-3.7-5.5-7.6-10.6-8.5-18.9
							c1-2.1,2.9-3.4,5.7-3.8c0.5-1.1,1-2.1,1.9-2.8c-6.5-9.3-12.1-19.3-17.9-29.2c-8.9-9.6-15.4-21.7-25.5-30.2
							c-1.4-2.1-3-3.9-4.7-5.7c0-1-0.8-1.1-0.9-1.9c-2.6-1.8-4.8-4-6.6-6.6c-0.8-0.2-0.9-0.9-1.9-0.9c-7.6-7.8-16.8-14-24.5-21.7
							c-0.3,0-0.6,0-0.9,0c-9.2-8.2-11.3,9.1-20.8,9.4c1.9,5.3,3.1,7.1,1.9,14.1c4.6,4,12.8,2.6,18.9,0.9c3.1,0.4,8.2-1.2,8.5,1.9
							c-1.5-0.3-1.6,0.9-2.8,0.9c-2.5,2.5-10.8-0.7-13.2,1.9c-3-0.4-2.5,2.5-4.7,2.8c1.8,2.9,5.7,5.6,2.8,10.4
							c-4.1,1-3.1-3.2-6.6-2.8c-6.4,1-3.9,7.2-3.8,13.2c-3.3-0.5-5.6,1.5-8.5,3.8c0.8-3-2.5-2-2.8-3.8c-7.5,5.4-24.7,10.5-29.2-0.9
							c1.4,0.5,2.4,1.4,3.8,1.9c0.3-2.5-0.3-4.1-2.8-3.8c0.8-2.1,3.3-2.4,4.7-3.8c-2.8-2.6-4.6-2.4-1.9-6.6
							c-5.8-0.8-6.6,3.5-11.3,3.8c2.5,7.3,0.5,13.3,6.6,17c-5.4,2.1-8.7-1-14.1,0.9c-2.3,0.8-2.9,5.2-6.6,8.5
							c-5.2,4.6-10.2,6.6-15.1,12.3c-1.2-1-3.5-0.9-4.7-1.9c-3.2,0.9-0.5,1.8-0.9,4.7c-3.6,0.3-9.7-3.2-11.3,0.9
							c-0.8,6.2,6.8,3.9,10.4,5.7c0.8,4.8,4.4,7,2.8,14.1c-0.9,0.7-1.4,1.7-1.9,2.8c-2.5,0-5,0-7.5,0c-8-0.1-16.4-0.7-20.7,2.8
							c6.7,6.5-2.8,19.8,2.8,26.4c5.2-0.5,9.3,0.2,10.4,3.8c7-4.4,25.2-2,20.8-17c4.7-1.9,5.9-7.3,12.3-7.5c0-2.5,0-5,0-7.6
							c6.1-0.9,9.4,3.3,14.1,0.9c0.3,0,0.6,0,0.9,0c2.4-2.3,4.5-4.9,8.5-5.7c2.9,14.1,23.6,10.4,22.6,28.3c3.4,0.3,2.6-3.7,5.7-3.8
							c-0.9-2.3-3.8-2.5-2.8-6.6c2.9-0.7,3.4,1,5.7,0.9c-3.1-6.6-23.6-12-18.9-23.6c3,0.8,4.1,2.8,7.5,0.9c-0.4,2.3,1.7,2.1,0.9,4.7
							c14.3,5.2,15.3,23.7,25.5,33c6.2,0-0.3-7,6.6-5.7c-0.4-4.6-7.3-6.8-5.7-10.4c0.9,0,1.9,0,2.8,0c1.8,2.3,2.5-4.4,7.6-1.9
							c0.3,0,0.6,0,0.9,0c1.8,1.8,0.1,2.4,0,4.7c0.6,1.9,4.8,0.3,3.8,3.8c0.4,2-1.3,1.8-2.8,1.9c1.6,4.4,7.1,5.5,5.7,10.4
							c2.5,0.6,1.8-2,2.8-2.8c2.2,1,3,3.2,6.6,2.8c1.3-0.3,0.8-2.4,0.9-3.8c6.2,4.5,12.9,2,19.8,0c-2.3,8.2-1.6,20.6-10.4,23.6
							c-5.6,1.9-12.2-3-18.9,0.9c-8.3-3.6-16.4-7.5-28.3-7.5c-1.8,2.3,0,8.1-2.8,9.4c-7.5-1.3-13.4-4.2-17-9.4
							c-7.7,1.1-10.9-2.3-15.1-4.7c3.6-3.1,2-8.2,2.8-12.3c-15.5-2.2-37.4-3.3-48.1,7.5c-4.1-0.9-8.3-1.7-12.3-2.8
							c-5.2,8.9-20.5,11.9-17,26.4c-12.2,6-21.5,16.3-26.4,28.3c-2.8,6.9,3.9,11.2,3.8,16c-0.3,14.8,1.3,23.6,10.4,32.1
							c7.4,6.8,10.9,14.9,18.9,19.8c8.3-2.2,15.2-0.1,23.6-0.9c7.4-0.7,13.1-6.2,18.9-5.7c4.7,0.4,7.1,5.2,11.3,6.6
							c5.2,1.7,10.8-3.1,12.3,2.8c1.6,6.2-4.5,10.3-3.8,15.1c1.5,9.2,10.4,15.7,15.1,24.5c7.6,14.4-3.3,22.4-4.7,32.1
							c-1.6,11.2,8.1,16.9,10.4,30.2c0.6,3.7-0.7,8.6,0,12.3c1.5,7.2,8.8,11.9,11.3,19.8c1.8,5.6-0.7,10.3,3.8,15.1
							c8.4-4.4,17.8-3,25.5-5.7c12.9-4.5,22.4-15.6,27.4-26.4c1.3-2.8-2.8-3.6-1.9-7.5c1-4,7.5-2.8,8.5-6.6c1.8-7.1-3.5-10.8-1.9-17
							c2.1-8.1,13.6-9,19.8-14.1c3-15.6-10.5-28.3-7.5-40.6c2.4-10,11.7-17,20.7-24.5c5-4.2,12.4-5.2,16-10.4
							c1.9-2.6,1.2-6.5,2.8-9.4c2.9-5.4,12.3-10,8.5-18.9C662.8,715.4,651.2,719,641.3,719.9z M660.2,644.5c0.6,0,1.3,0,1.9,0
							c7.5,5.7,9.7,16.7,23.6,16c-2.6,4.5-6.1,12.5-14.2,8.5c-1.5-1.4-0.8-4.8-0.9-7.5c-2-2.1-2.8,1.2-5.7,0.9
							C666.1,653.9,655.6,653.4,660.2,644.5z M590.4,596.4c0,0.9-0.8,1.1-0.9,1.9c0,2.9-8,4.4-4.7,0c-1.8-8.5,4.7-20.6,12.3-25.5
							c0.6,2.8,11.1-1.6,11.4,1.5c0.7,2.4-6,5.1-6,5.1s2,2.2,3.1,3.4c3.4,0.6,5.9-1.3,7.9-2.1c0.7-2.9-3.3-3.9-3.3-6.1
							c3.9-1.8,7-4.4,13.2-3.8c-0.2,4.9-6,5.6-6.3,10.4c2.9,0.2,5.8,2,8.2,2.8c0,0.9,0.8,1.1,0.9,1.9c2.4,2.9,6.4,4.3,8.5,7.5
							c-1.1,3.1-6.5,5.5-9.4,3.8c-0.6,0-1.3,0-1.9,0C612.2,594.1,600.4,597.3,590.4,596.4z"/>
						<path class="st225" d="M230.1,689.8c-3.1-1.9-7.2-2.9-9.4-5.7c-2,3.4-4.5-0.6-7.5,0c-3.4,0.7,3.4,5.2,0.9,5.7
							c-1.8,0.4-4.6-1.6-5.7,0C203.6,696.8,223.5,690.1,230.1,689.8z"/>
						<path class="st225" d="M660.2,803.9c-2,9-7.6,14.5-17.9,15.1c-0.1,15.1-4.8,26.9,0.9,36.8c3.6,0.2,7,0,9.4-0.9
							c5.2-12.4,6.7-28.6,13.2-39.6C664.3,811.1,665,804.7,660.2,803.9z"/>
						<path class="st225" d="M248,476.6c4.1-3.8,4-7.3,9.4-8.5c0.3-2.2-0.2-3.6-0.9-4.7c-5.3,3.2-10.8,6.2-15.1,10.4
							C244.8,473.5,244.9,476.6,248,476.6z"/>
						<path class="st225" d="M402.7,430.4c1.8-2.4-0.8-3.7,0.9-6.6c-4.3-0.2-4.6-1-8.5,0c0,1.3,0,2.5,0,3.8
							C397.8,428.4,399,430.6,402.7,430.4z"/>
						<path class="st225" d="M433.8,474.7c-2.3-2.5-5.9-3.5-5.7-8.5c-5-0.6-6.4,2.4-8.5,4.7c-3.2-1.5-4.8-2.6-6.6,0.9
							c-2.2-0.3-1.8-3.3-4.7-2.8c-1.3,1.2-0.4,4.6-2.8,4.7c-2.1-1.1-2.1-4.2-2.8-6.6c-4.4-1.2-4.2,2.1-5.7,3.8
							c-0.2-1.5-2.1-0.7-2.8,0c0,3.4,1.9,5,3.8,6.6c3.7,1.3,0.2,4.2,1.9,6.6c3.8,0.6,3.8-2.5,7.5-1.9
							C415.4,492.3,428.1,481,433.8,474.7z"/>
						<path class="st225" d="M238.6,495.5c1.7-4.2-4.6-7.7-8.5-9.4c3.9-1.7,9,3.5,13.2,4.7c1.3-1.8-0.3-3.4,2.8-4.7
							c-2.1-3.9-6-5.9-7.5-10.4c-7,4.4-13.4,9.2-18.9,15.1C225.3,489.2,232.1,492.8,238.6,495.5z"/>
						<path class="st225" d="M446.1,548.3c4.2,2.3,11-2.5,16.1-2.5c1-5.3-1.8-7.2-0.1-12.6c-3.4-3.1-6.1-0.9-11.3-1.9
							c-0.8,2.7,1.3,2.4,0.9,4.7c-3.8-0.3-6.9,0-7.5,2.8C448.4,541.2,447.9,543.7,446.1,548.3z"/>
						<path class="st225" d="M210.3,684.1c-3.4-10.8-41.4-24.9-40.6-8.5c0.3,5.1,7.5-2,12.3,0.9c2.5,1.5,6.8,1.2,9.4,1.9
							c3.7,1.1,3.2,3.6,5.7,7.5C200,690.6,211.8,689,210.3,684.1z"/>
						<path class="st225" d="M191.4,689.8c-2.7,2.8,14.7,4.9,10.4,0C198.9,686.6,194.4,686.6,191.4,689.8z"/>
					</g>
				</g>
				<path class="st224" d="M657.5,565.3c0,0.3,0,0.7,0,1c0,186-150.8,336.8-336.8,336.8c-58.1,0-112.8-14.7-160.5-40.6
					c50.9,83.2,142.6,138.7,247.2,138.7c160,0,289.6-129.7,289.6-289.6C697.2,658.2,682.7,608.2,657.5,565.3z"/>
			</g>
		</g>
	</g>
</g>
<g id="Layer_2">
	<g>
		<g>
			<g>
				<path class="st226" d="M40.7,166c1.3,2.1,2.7,4.1,4.1,5.2c1,0,2.6-2.5,5-4.4c2.3-2,5.3-3.5,6.7-3.9c2.7-0.9,4.2-2.4,4.5-4.6
					c0.3-2-0.6-4.7-0.5-4.7c0,0.1-2-2-4-2.6c-2.1-0.7-4.1,0-6.1,2c-1,1.1-3.7,3.2-6.5,4.3c-2.8,1.3-5.7,1.7-6.1,2.6
					C38.1,161.6,39.4,163.9,40.7,166z"/>
				<path class="st227" d="M60.5,160c-0.9,0.8-2.2,1.4-3.8,1.9c-1.4,0.4-4.1,1.6-6.5,3.5c-2.5,1.8-4.5,4.2-5.8,5.5
					c0.1,0.1,0.2,0.2,0.4,0.3c1,0,2.6-2.5,5-4.4c2.3-2,5.3-3.5,6.7-3.9C58.5,162.3,59.9,161.3,60.5,160z"/>
				<path class="st228" d="M60.4,153.7C60.4,153.7,60.4,153.7,60.4,153.7c-1.8,2.9-6.2,5.1-10.6,8.1c-3.3,2.1-6.2,4.3-8.6,5.1
					c1.2,1.8,2.4,3.4,3.5,4.4c1,0,2.6-2.5,5-4.4c2.3-2,5.3-3.5,6.7-3.9c2.7-0.9,4.2-2.4,4.5-4.6C61.3,156.4,60.4,153.7,60.4,153.7z"
					/>
				<defs>
					<filter id="Adobe_OpacityMaskFilter_108_" filterUnits="userSpaceOnUse" x="37.8" y="158.7" width="9.5" height="12.6">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="37.8" y="158.7" width="9.5" height="12.6" id="SVGID_165_">
					<g class="st229">
						<defs>
							<filter id="Adobe_OpacityMaskFilter_109_" filterUnits="userSpaceOnUse" x="37.8" y="158.7" width="9.5" height="12.6">
								<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
								<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
							</filter>
						</defs>
						<mask maskUnits="userSpaceOnUse" x="37.8" y="158.7" width="9.5" height="12.6" id="SVGID_165_">
							<g class="st230">
							</g>
						</mask>

							<linearGradient id="SVGID_166_" gradientUnits="userSpaceOnUse" x1="1023.8426" y1="1855.4443" x2="1023.8426" y2="1857.9656" gradientTransform="matrix(0.529 0.8486 -0.8486 0.529 1076.0553 -1685.8756)">
							<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
							<stop  offset="6.171513e-02" style={{stopColor:"#FAFAFA"}}/>
							<stop  offset="0.4084" style={{stopColor:"#E2E2E2"}}/>
							<stop  offset="0.73" style={{stopColor:"#D4D4D4"}}/>
							<stop  offset="1" style={{stopColor:"#CFCFCF"}}/>
						</linearGradient>
						<path class="st231" d="M43.7,164.3c-1.2-1.9-2.2-3.9-2.9-5.6c-1.5,0.5-2.7,0.9-3.1,1.4c0.4,1.5,1.6,3.8,2.9,5.9
							c1.3,2.1,2.7,4.1,4.1,5.2c0.6-0.1,1.4-0.9,2.6-2.1C46.1,167.8,44.9,166.1,43.7,164.3z"/>
					</g>
				</mask>

					<linearGradient id="SVGID_167_" gradientUnits="userSpaceOnUse" x1="1023.8426" y1="1855.4443" x2="1023.8426" y2="1857.9656" gradientTransform="matrix(0.529 0.8486 -0.8486 0.529 1076.0553 -1685.8756)">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="7.153982e-02" style={{stopColor:"#EFEFEF"}}/>
					<stop  offset="0.2798" style={{stopColor:"#C4C4C5"}}/>
					<stop  offset="0.482" style={{stopColor:"#A2A3A4"}}/>
					<stop  offset="0.6739" style={{stopColor:"#8A8B8D"}}/>
					<stop  offset="0.8512" style={{stopColor:"#7C7D7F"}}/>
					<stop  offset="1" style={{stopColor:"#77787A"}}/>
				</linearGradient>
				<path class="st232" d="M43.7,164.3c-1.2-1.9-2.2-3.9-2.9-5.6c-1.5,0.5-2.7,0.9-3.1,1.4c0.4,1.5,1.6,3.8,2.9,5.9
					c1.3,2.1,2.7,4.1,4.1,5.2c0.6-0.1,1.4-0.9,2.6-2.1C46.1,167.8,44.9,166.1,43.7,164.3z"/>
				<path class="st233" d="M50.9,153.7c1.7-1.5,3.2-2.4,4.9-2.2c1.7,0.1,3.4,1.3,4.6,2.2c-0.3-0.2-2.3-2.1-4.2-2.6
					c-2-0.6-3.9,0.1-5.9,2.1c-1,1.1-3.7,3.2-6.5,4.3c-2.8,1.3-5.7,1.7-6.1,2.6c0.1,0.3,0.2,0.6,0.3,1c0.6-0.9,3.5-1.5,6.3-2.9
					C47.2,156.8,49.9,154.8,50.9,153.7z"/>
			</g>
			<g>
				<path class="st234" d="M45.5,163c1.3,2.1,2.8,4,4.4,5.5c2.9,0.6,11.3-3.1,13.6-4.6c4.4-2.7,1.7-13.3,1.7-13.3
					c0,0-8.3-7-12.7-4.3c-2.3,1.4-9.4,7.3-10.1,10.2C43,158.5,44.2,160.9,45.5,163z"/>
				<path class="st227" d="M66,158.7c-0.6,1.4-1.4,2.7-2.7,3.5c-2.3,1.4-9.4,4.9-13.8,6c0.1,0.1,0.3,0.3,0.4,0.4
					c2.9,0.6,11.3-3.1,13.6-4.6C65.1,163,65.8,160.9,66,158.7z"/>
				<path class="st228" d="M65.3,150.7C65.3,150.7,65.3,150.7,65.3,150.7c-2.4,3.3-5.8,6.5-9.9,9c-3.1,1.9-6.2,3.3-9.3,4.2
					c1.2,1.8,2.4,3.4,3.8,4.7c2.9,0.6,11.3-3.1,13.6-4.6C67.9,161.3,65.2,150.7,65.3,150.7z"/>
				<defs>
					<filter id="Adobe_OpacityMaskFilter_110_" filterUnits="userSpaceOnUse" x="42.5" y="153.3" width="11.3" height="15.3">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="42.5" y="153.3" width="11.3" height="15.3" id="SVGID_168_">
					<g class="st235">
						<defs>
							<filter id="Adobe_OpacityMaskFilter_111_" filterUnits="userSpaceOnUse" x="42.5" y="153.3" width="11.3" height="15.3">
								<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
								<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
							</filter>
						</defs>
						<mask maskUnits="userSpaceOnUse" x="42.5" y="153.3" width="11.3" height="15.3" id="SVGID_168_">
							<g class="st236">
							</g>
						</mask>

							<linearGradient id="SVGID_169_" gradientUnits="userSpaceOnUse" x1="1023.8346" y1="1849.4694" x2="1023.8346" y2="1852.2383" gradientTransform="matrix(0.529 0.8486 -0.8486 0.529 1076.0553 -1685.8756)">
							<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
							<stop  offset="6.171513e-02" style={{stopColor:"#FAFAFA"}}/>
							<stop  offset="0.4084" style={{stopColor:"#E2E2E2"}}/>
							<stop  offset="0.73" style={{stopColor:"#D4D4D4"}}/>
							<stop  offset="1" style={{stopColor:"#CFCFCF"}}/>
						</linearGradient>
						<path class="st237" d="M48.9,161.2c-1.6-2.6-3.1-5.3-4.3-7.9c-1.1,1.3-1.9,2.4-2.2,3.3c0.5,1.9,1.7,4.3,3,6.4
							c1.3,2.1,2.8,4,4.4,5.5c0.9,0.2,2.3,0,3.9-0.5C52.1,166,50.5,163.6,48.9,161.2z"/>
					</g>
				</mask>

					<linearGradient id="SVGID_170_" gradientUnits="userSpaceOnUse" x1="1023.8346" y1="1849.4694" x2="1023.8346" y2="1852.2383" gradientTransform="matrix(0.529 0.8486 -0.8486 0.529 1076.0553 -1685.8756)">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="7.153982e-02" style={{stopColor:"#EFEFEF"}}/>
					<stop  offset="0.2798" style={{stopColor:"#C4C4C5"}}/>
					<stop  offset="0.482" style={{stopColor:"#A2A3A4"}}/>
					<stop  offset="0.6739" style={{stopColor:"#8A8B8D"}}/>
					<stop  offset="0.8512" style={{stopColor:"#7C7D7F"}}/>
					<stop  offset="1" style={{stopColor:"#77787A"}}/>
				</linearGradient>
				<path class="st238" d="M48.9,161.2c-1.6-2.6-3.1-5.3-4.3-7.9c-1.1,1.3-1.9,2.4-2.2,3.3c0.5,1.9,1.7,4.3,3,6.4
					c1.3,2.1,2.8,4,4.4,5.5c0.9,0.2,2.3,0,3.9-0.5C52.1,166,50.5,163.6,48.9,161.2z"/>
				<path class="st233" d="M53.4,147.5c3.5-2.2,9.3,1.4,11.8,3.2c-0.6-0.5-8.4-6.9-12.7-4.3c-2.3,1.4-9.4,7.3-10.1,10.2
					c0.1,0.4,0.2,0.8,0.4,1.2C44,154.8,51,148.9,53.4,147.5z"/>
			</g>
			<g>
				<g>
					<path class="st239" d="M80,154.5l-20.4,12.7c3.8,5.6,2.4,14.6-7.9,21c1.6,2.6,2.1,3.4,3.7,6C72.8,183.4,86.2,164.1,80,154.5z"
						/>
					<path class="st239" d="M51.7,188.2c1.6,2.6,2.1,3.4,3.7,6c9.6-6,17.9-14.5,22.5-22.5c-2.3,2.4-5.2,5.5-7.7,8.1
						c-5.2,5.6-14.5,9.9-14.5,9.9s5.8-7.9,8.9-15.7c1.6-4,1.6-7.9,1.2-10.7l-6.2,3.9C63.4,172.8,62,181.8,51.7,188.2z"/>
					<path class="st239" d="M80.1,164.6c1-3.8,1-7.5-0.6-8.2c-0.6-0.3-2.4,0.7-2.5,2.1c-0.1,2.1,0.1,4.3,0,6.4
						C78.1,164.8,79.1,164.7,80.1,164.6z"/>
					<path class="st239" d="M77.9,170.4c0.7-1.2,1.3-2.6,1.7-4.1c-0.9,0.1-1.8,0.1-2.7,0.2c-0.1,0.9-0.4,1.8-0.8,2.7
						c-1.2,2.4-2,3.8-3.5,5.7c-0.6,0.7-3,3.5-2.9,3.6C70.1,178.7,75.6,174.4,77.9,170.4z"/>
				</g>
				<g>
					<path class="st239" d="M67.6,134.6l-20.4,12.7c-3.4-5.9-12.1-8.6-22.4-2.2c-1.6-2.6-2.1-3.4-3.7-6
						C38.5,128.3,61.7,124.8,67.6,134.6z"/>
					<path class="st239" d="M24.9,145.2c-1.6-2.6-2.1-3.4-3.7-6c9.6-6,20.9-9.7,30.1-10.2c-3.2,1-7.2,2.3-10.7,3.3
						c-7.3,2.2-15.3,8.6-15.3,8.6s9.7-1.8,18.1-1.1c4.3,0.4,7.7,2.1,10.1,3.6l-6.2,3.9C43.9,141.4,35.2,138.7,24.9,145.2z"/>
					<path class="st239" d="M58.6,130.1c3.8,0.8,7.2,2.4,7.1,4.2c0,0.7-1.7,1.9-3,1.3c-1.9-0.8-3.8-2-5.7-2.8
						C57.6,131.8,58.1,131,58.6,130.1z"/>
					<path class="st239" d="M52.4,129.5c1.4-0.1,2.9,0,4.4,0.2c-0.4,0.8-0.9,1.6-1.4,2.4c-0.9-0.3-1.8-0.5-2.8-0.4
						c-2.7,0-4.3,0.1-6.7,0.6c-0.9,0.2-4.5,1.1-4.5,1.1C41.5,132.9,47.9,129.8,52.4,129.5z"/>
				</g>
			</g>
			<path class="st240" d="M51.7,158.7c2.2,3.5,5,6.6,8.7,8.6c8.3-1.1,36.7-16.2,44.9-21.3c15.5-9.6,16.2-30.8,16.2-30.8
				c0,0-19.4-8.7-34.8,1c-8.2,5.1-34.3,23.9-38.9,30.9C47.8,150.9,49.5,155.1,51.7,158.7z"/>
			<path class="st227" d="M117.1,132.2c-2.9,3.6-6.6,7.1-11.2,10c-8.2,5.1-32.4,18.5-46.5,24.5c0.3,0.2,0.6,0.4,1,0.6
				c8.3-1.1,36.7-16.2,44.9-21.3C111.1,142.4,114.8,137.3,117.1,132.2z"/>
			<path class="st239" d="M104.3,126c2.8,4.5,6,8.6,9.4,12.1c7.4-10.2,7.9-23,7.9-23c0,0-11.7-5.2-24.1-3.1
				C99.1,116.8,101.4,121.5,104.3,126z"/>
			<path class="st241" d="M121.4,115.1c0,0-3.1,8.8-12.1,18.1c1.4,1.7,2.8,3.4,4.3,4.9C120.8,128.4,121.5,116.2,121.4,115.1
				C121.5,115.2,121.4,115.1,121.4,115.1L121.4,115.1z"/>

				<linearGradient id="SVGID_171_" gradientUnits="userSpaceOnUse" x1="1028.6571" y1="1803.9407" x2="1039.3635" y2="1803.9407" gradientTransform="matrix(0.529 0.8486 -0.8486 0.529 1076.0553 -1685.8756)">
				<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
				<stop  offset="7.153982e-02" style={{stopColor:"#EFEFEF"}}/>
				<stop  offset="0.2798" style={{stopColor:"#C4C4C5"}}/>
				<stop  offset="0.482" style={{stopColor:"#A2A3A4"}}/>
				<stop  offset="0.6739" style={{stopColor:"#8A8B8D"}}/>
				<stop  offset="0.8512" style={{stopColor:"#7C7D7F"}}/>
				<stop  offset="1" style={{stopColor:"#77787A"}}/>
			</linearGradient>
			<path class="st242" d="M121.5,115.2C121.5,115.2,121.5,115.2,121.5,115.2c-10,9.3-22.7,19-37.2,28.1
				c-10.9,6.8-21.6,12.5-31.7,16.9c2.1,2.9,4.6,5.5,7.8,7.2c8.3-1.1,36.7-16.2,44.9-21.3C120.8,136.4,121.5,115.2,121.5,115.2z"/>
			<defs>
				<filter id="Adobe_OpacityMaskFilter_112_" filterUnits="userSpaceOnUse" x="56.6" y="116" width="43.9" height="33.5">
					<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
					<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
				</filter>
			</defs>
			<mask maskUnits="userSpaceOnUse" x="56.6" y="116" width="43.9" height="33.5" id="SVGID_172_">
				<g class="st243">
					<defs>
						<filter id="Adobe_OpacityMaskFilter_113_" filterUnits="userSpaceOnUse" x="56.6" y="116" width="43.9" height="33.5">
							<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
							<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
						</filter>
					</defs>
					<mask maskUnits="userSpaceOnUse" x="56.6" y="116" width="43.9" height="33.5" id="SVGID_172_">
						<g class="st244">
						</g>
					</mask>

						<linearGradient id="SVGID_173_" gradientUnits="userSpaceOnUse" x1="1014.816" y1="1799.9084" x2="1016.5583" y2="1832.1437" gradientTransform="matrix(0.529 0.8486 -0.8486 0.529 1076.0553 -1685.8756)">
						<stop  offset="0" style={{stopColor:"#B5B5B5"}}/>
						<stop  offset="0.1038" style={{stopColor:"#929292"}}/>
						<stop  offset="0.253" style={{stopColor:"#656565"}}/>
						<stop  offset="0.4038" style={{stopColor:"#414141"}}/>
						<stop  offset="0.5541" style={{stopColor:"#242424"}}/>
						<stop  offset="0.7037" style={{stopColor:"#101010"}}/>
						<stop  offset="0.8527" style={{stopColor:"#040404"}}/>
						<stop  offset="1" style={{stopColor:"#000000"}}/>
					</linearGradient>
					<path class="st245" d="M88.3,128.8c3.8-2.4,8.4-4.8,12.1-7c-0.6-1.1-0.9-1.5-1.5-2.7c-0.6-1.2-0.7-1.6-1.4-3
						c-10.8,7-35.5,22-40.6,27.2c-0.7,2.4-0.3,4.5,0.5,6.3C66.6,142.9,77.1,135.8,88.3,128.8z"/>
				</g>
			</mask>

				<linearGradient id="SVGID_174_" gradientUnits="userSpaceOnUse" x1="1014.816" y1="1799.9084" x2="1016.5583" y2="1832.1437" gradientTransform="matrix(0.529 0.8486 -0.8486 0.529 1076.0553 -1685.8756)">
				<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
				<stop  offset="0.14" style={{stopColor:"#DCDCDD"}}/>
				<stop  offset="0.3121" style={{stopColor:"#B8B8B9"}}/>
				<stop  offset="0.4855" style={{stopColor:"#9B9C9D"}}/>
				<stop  offset="0.6582" style={{stopColor:"#878889"}}/>
				<stop  offset="0.83" style={{stopColor:"#7B7C7E"}}/>
				<stop  offset="1" style={{stopColor:"#77787A"}}/>
			</linearGradient>
			<path class="st246" d="M88.3,128.8c3.8-2.4,8.4-4.8,12.1-7c-0.6-1.1-0.9-1.5-1.5-2.7c-0.6-1.2-0.7-1.6-1.4-3
				c-10.8,7-35.5,22-40.6,27.2c-0.7,2.4-0.3,4.5,0.5,6.3C66.6,142.9,77.1,135.8,88.3,128.8z"/>
			<defs>
				<filter id="Adobe_OpacityMaskFilter_114_" filterUnits="userSpaceOnUse" x="47.8" y="137.9" width="24.8" height="29.4">
					<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
					<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
				</filter>
			</defs>
			<mask maskUnits="userSpaceOnUse" x="47.8" y="137.9" width="24.8" height="29.4" id="SVGID_175_">
				<g class="st247">
					<defs>
						<filter id="Adobe_OpacityMaskFilter_115_" filterUnits="userSpaceOnUse" x="47.8" y="137.9" width="24.8" height="29.4">
							<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
							<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
						</filter>
					</defs>
					<mask maskUnits="userSpaceOnUse" x="47.8" y="137.9" width="24.8" height="29.4" id="SVGID_175_">
						<g class="st248">
						</g>
					</mask>

						<linearGradient id="SVGID_176_" gradientUnits="userSpaceOnUse" x1="1023.4431" y1="1833.8562" x2="1023.4431" y2="1843.6489" gradientTransform="matrix(0.529 0.8486 -0.8486 0.529 1076.0553 -1685.8756)">
						<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
						<stop  offset="6.171513e-02" style={{stopColor:"#FAFAFA"}}/>
						<stop  offset="0.4084" style={{stopColor:"#E2E2E2"}}/>
						<stop  offset="0.73" style={{stopColor:"#D4D4D4"}}/>
						<stop  offset="1" style={{stopColor:"#CFCFCF"}}/>
					</linearGradient>
					<path class="st249" d="M63.6,151.8c-2.8-4.5-4.9-9.1-6.5-13.9c-4.4,3.7-7.9,7-9.3,9.2c0,3.8,1.7,8.1,3.9,11.6
						c2.2,3.5,5,6.6,8.7,8.6c2.5-0.3,7-2,12.1-4.3C69.3,159.8,66.2,156,63.6,151.8z"/>
				</g>
			</mask>

				<linearGradient id="SVGID_177_" gradientUnits="userSpaceOnUse" x1="1023.4431" y1="1833.8562" x2="1023.4431" y2="1843.6489" gradientTransform="matrix(0.529 0.8486 -0.8486 0.529 1076.0553 -1685.8756)">
				<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
				<stop  offset="7.153982e-02" style={{stopColor:"#EFEFEF"}}/>
				<stop  offset="0.2798" style={{stopColor:"#C4C4C5"}}/>
				<stop  offset="0.482" style={{stopColor:"#A2A3A4"}}/>
				<stop  offset="0.6739" style={{stopColor:"#8A8B8D"}}/>
				<stop  offset="0.8512" style={{stopColor:"#7C7D7F"}}/>
				<stop  offset="1" style={{stopColor:"#77787A"}}/>
			</linearGradient>
			<path class="st250" d="M63.6,151.8c-2.8-4.5-4.9-9.1-6.5-13.9c-4.4,3.7-7.9,7-9.3,9.2c0,3.8,1.7,8.1,3.9,11.6
				c2.2,3.5,5,6.6,8.7,8.6c2.5-0.3,7-2,12.1-4.3C69.3,159.8,66.2,156,63.6,151.8z"/>
			<path class="st233" d="M88.3,117.8c12.5-7.8,27-4.5,33.1-2.7c-1.4-0.6-19.8-8.3-34.7,1c-8.2,5.1-34.3,23.9-38.9,30.9
				c0,0.7,0.1,1.5,0.2,2.2C53.9,141.7,80,123,88.3,117.8z"/>
			<path class="st251" d="M101.7,121.5c4.3-2.3,8.5-4.3,12.4-6.1l0,0c0,0-6.8-1.6-15.2,0.3C99.7,117.6,100.6,119.6,101.7,121.5z"/>
			<path class="st252" d="M101,141.1c-10.7,6.7-35.7,19.6-36.1,19c-0.4-0.6,24-13.7,34.7-20.3c10.7-6.7,9.3-7.3,9.7-6.7
				C109.6,133.7,111.7,134.4,101,141.1z"/>
			<g>

					<radialGradient id="SVGID_178_" cx="1023.2029" cy="1799.0345" r="11.6106" gradientTransform="matrix(0.529 0.8486 -0.8486 0.529 1076.0553 -1685.8756)" gradientUnits="userSpaceOnUse">
					<stop  offset="0.6416" style={{stopColor:"#77787A"}}/>
					<stop  offset="0.6508" style={{stopColor:"#7B7C7E"}}/>
					<stop  offset="0.7695" style={{stopColor:"#B4B4B5"}}/>
					<stop  offset="0.8714" style={{stopColor:"#DDDDDD"}}/>
					<stop  offset="0.9515" style={{stopColor:"#F6F6F6"}}/>
					<stop  offset="1" style={{stopColor:"#FFFFFF"}}/>
				</radialGradient>
				<path class="st253" d="M96.8,144c-5.4,3.4-12.6,1.7-16-3.7c-3.4-5.4-1.7-12.6,3.7-16c5.4-3.4,12.6-1.7,16,3.7
					C103.9,133.4,102.3,140.6,96.8,144z"/>
				<path class="st254" d="M94.6,140.5c-3.5,2.2-8.2,1.1-10.3-2.4c-2.2-3.5-1.1-8.2,2.4-10.3c3.5-2.2,8.2-1.1,10.3,2.4
					C99.2,133.7,98.2,138.3,94.6,140.5z"/>
				<path class="st255" d="M98.1,135.3c-0.3,0.3-0.7,0.6-1.2,0.9c-3.5,2.2-8.2,1.1-10.3-2.4c-1-1.6-1.3-3.5-1-5.2
					c-2.6,2.4-3.2,6.4-1.2,9.5c2.2,3.5,6.8,4.6,10.3,2.4C96.5,139.3,97.7,137.4,98.1,135.3z"/>
				<path class="st255" d="M96.8,138.5c-0.3,0.2-0.5,0.4-0.8,0.6c-3.8,2.4-8.8,1.2-11.1-2.6c-1-1.5-1.3-3.3-1.2-5
					c-0.8,2.1-0.6,4.5,0.7,6.6c2.2,3.5,6.8,4.6,10.3,2.4C95.5,139.9,96.2,139.3,96.8,138.5z"/>
				<path class="st228" d="M98.8,129c-2.8-4.5-8.7-5.9-13.2-3.1c-4.5,2.8-5.9,8.7-3.1,13.2c2.8,4.5,8.7,5.9,13.2,3.1
					C100.2,139.4,101.6,133.5,98.8,129z M84.8,137.7c-2-3.2-1-7.5,2.2-9.5c3.2-2,7.5-1,9.5,2.2c2,3.2,1,7.5-2.2,9.5
					C91.1,141.9,86.8,141,84.8,137.7z"/>
				<g>
					<path class="st256" d="M84.6,138.1c2,3.2,6.2,4.2,9.5,2.2c1-0.6,1.8-1.5,2.4-2.5c-0.5,0.8-1.2,1.5-2.1,2.1
						c-3.2,2-7.5,1-9.5-2.2c-1.4-2.2-1.3-4.9-0.2-7C83.3,132.9,83.1,135.8,84.6,138.1z"/>
					<path class="st256" d="M85.4,126.4c4.5-2.8,10.4-1.4,13.2,3.1c1.9,3.1,1.9,6.9,0.2,9.9c2-3,2.1-7,0.1-10.3
						c-2.8-4.5-8.7-5.9-13.2-3.1c-1.4,0.9-2.5,2-3.2,3.3C83.1,128.2,84.1,127.2,85.4,126.4z"/>
				</g>
				<g>
					<path class="st257" d="M87,128.3c3.2-2,7.5-1,9.5,2.2c1.1,1.7,1.3,3.7,0.8,5.5c0.7-1.9,0.6-4.2-0.6-6c-2-3.2-6.2-4.2-9.5-2.2
						c-1.5,0.9-2.5,2.4-3,4C84.7,130.3,85.7,129.1,87,128.3z"/>
					<path class="st257" d="M99.8,136.8c-0.7,1.9-2,3.7-3.9,4.9c-4.5,2.8-10.4,1.4-13.2-3.1c-1.5-2.4-1.8-5.3-1-7.8
						c-1,2.7-0.7,5.7,0.9,8.3c2.8,4.5,8.7,5.9,13.2,3.1C97.8,140.9,99.2,139,99.8,136.8z"/>
				</g>
				<path class="st258" d="M99.9,131.6c-1.1-0.4-2.2-0.7-3.4-1.1c0,0,0,0,0,0c0.7,1.1,1,2.4,1,3.6c0.9,0.2,1.8,0.5,2.7,0.7
					C100.3,133.7,100.2,132.6,99.9,131.6z M87.8,127.9c-1.2-0.3-2.4-0.6-3.5-0.9c-0.8,0.7-1.4,1.5-1.9,2.3c0.8,0.3,1.7,0.6,2.6,0.9
					c0.5-0.8,1.2-1.4,2-2C87.3,128.1,87.5,128,87.8,127.9z"/>
				<path class="st254" d="M90.1,128.7c0,0,0-0.1,0-0.1c-1.5,0.3-2.6,1.1-2.7,1.4c-0.1,0.3,1.1,0,2.6-0.1
					C90.1,129.5,90.1,129.1,90.1,128.7z"/>
				<path class="st254" d="M96,131.7c0.1-0.4-1.5-2.7-3.8-3.1c-0.2,0-0.5-0.1-0.7-0.1c0,0.1,0.1,0.2,0,0.3c0,0.4,0,0.8,0,1.3
					c0.1,0,0.3,0,0.4,0.1C94.3,130.6,96,132.1,96,131.7z"/>
				<g>
					<g>
						<path class="st259" d="M98,130.2c-0.2,0.1-0.5,0.1-0.7-0.2c-0.1-0.2-0.1-0.5,0.2-0.7c0.2-0.1,0.5-0.1,0.7,0.2
							C98.3,129.8,98.2,130.1,98,130.2z"/>
						<path class="st260" d="M98.2,129.9C98.2,129.9,98.2,129.9,98.2,129.9c-0.3,0.2-0.6,0.1-0.8-0.1c-0.1-0.1-0.1-0.2-0.1-0.3
							c-0.2,0.2-0.2,0.4-0.1,0.6c0.1,0.2,0.5,0.3,0.7,0.2C98.1,130.1,98.2,130,98.2,129.9z"/>
						<path class="st261" d="M98.1,130.1C98.1,130.1,98.1,130.1,98.1,130.1c-0.3,0.2-0.6,0.1-0.8-0.1c-0.1-0.1-0.1-0.2-0.1-0.3
							c-0.1,0.1,0,0.3,0,0.4c0.1,0.2,0.5,0.3,0.7,0.2C98,130.2,98.1,130.1,98.1,130.1z"/>
						<path class="st262" d="M97.7,129.4C97.7,129.4,97.7,129.4,97.7,129.4c-0.1,0-0.2,0.1-0.2,0.1
							C97.5,129.6,97.6,129.5,97.7,129.4C97.7,129.5,97.7,129.5,97.7,129.4z"/>
						<path class="st262" d="M98.1,129.6c0,0-0.1-0.2-0.3-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0
							C98,129.6,98.1,129.7,98.1,129.6z"/>
					</g>
					<g>
						<path class="st259" d="M84,138.9c-0.2,0.1-0.5,0.1-0.7-0.2c-0.1-0.2-0.1-0.5,0.2-0.7c0.2-0.1,0.5-0.1,0.7,0.2
							C84.3,138.5,84.2,138.8,84,138.9z"/>
						<path class="st260" d="M84.2,138.6C84.2,138.6,84.2,138.6,84.2,138.6c-0.3,0.2-0.6,0.1-0.8-0.1c-0.1-0.1-0.1-0.2-0.1-0.3
							c-0.2,0.2-0.2,0.4-0.1,0.6c0.1,0.2,0.5,0.3,0.7,0.2C84.1,138.9,84.2,138.7,84.2,138.6z"/>
						<path class="st261" d="M84.1,138.8C84.1,138.8,84.1,138.8,84.1,138.8c-0.3,0.2-0.6,0.1-0.8-0.1c-0.1-0.1-0.1-0.2-0.1-0.3
							c-0.1,0.1,0,0.3,0,0.4c0.1,0.2,0.5,0.3,0.7,0.2C84,138.9,84.1,138.9,84.1,138.8z"/>
						<path class="st262" d="M83.7,138.2C83.7,138.2,83.7,138.2,83.7,138.2c-0.1,0-0.2,0.1-0.2,0.1
							C83.5,138.3,83.6,138.3,83.7,138.2C83.7,138.2,83.7,138.2,83.7,138.2z"/>
						<path class="st262" d="M84.1,138.4c0,0-0.1-0.2-0.3-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0
							C84,138.3,84.1,138.4,84.1,138.4z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st259" d="M98.8,136.5c-0.3-0.1-0.4-0.3-0.4-0.6c0.1-0.3,0.3-0.4,0.6-0.4c0.3,0.1,0.4,0.3,0.4,0.6
							C99.3,136.4,99,136.5,98.8,136.5z"/>
						<path class="st260" d="M99.2,136.4C99.1,136.4,99.1,136.4,99.2,136.4c-0.4-0.1-0.5-0.3-0.5-0.6c0-0.1,0.1-0.2,0.2-0.3
							c-0.2,0-0.4,0.2-0.5,0.4c-0.1,0.3,0.1,0.5,0.4,0.6C98.9,136.5,99,136.5,99.2,136.4z"/>
						<path class="st261" d="M99,136.5C98.9,136.5,98.9,136.5,99,136.5c-0.4-0.1-0.5-0.4-0.5-0.6c0-0.1,0.1-0.2,0.2-0.3
							c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.3,0.1,0.5,0.4,0.6C98.8,136.5,98.9,136.5,99,136.5z"/>
						<path class="st262" d="M99.1,135.7C99.1,135.7,99.1,135.7,99.1,135.7c-0.1-0.1-0.2-0.1-0.2-0.1
							C98.9,135.7,98.9,135.7,99.1,135.7C99,135.8,99.1,135.7,99.1,135.7z"/>
						<path class="st262" d="M99.2,136.1c0,0,0.1-0.2,0-0.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0.1c0,0,0,0,0,0
							C99.2,136,99.2,136.1,99.2,136.1z"/>
					</g>
					<g>
						<path class="st259" d="M82.6,133.2c-0.3-0.1-0.4-0.3-0.4-0.6c0.1-0.3,0.3-0.4,0.6-0.4c0.3,0.1,0.4,0.3,0.4,0.6
							C83.1,133.1,82.9,133.2,82.6,133.2z"/>
						<path class="st260" d="M83,133.1C83,133.1,82.9,133.1,83,133.1c-0.4-0.1-0.5-0.3-0.5-0.6c0-0.1,0.1-0.2,0.2-0.3
							c-0.2,0-0.4,0.2-0.5,0.4c-0.1,0.3,0.1,0.5,0.4,0.6C82.7,133.2,82.9,133.2,83,133.1z"/>
						<path class="st261" d="M82.8,133.2C82.8,133.2,82.8,133.2,82.8,133.2c-0.4-0.1-0.5-0.4-0.5-0.6c0-0.1,0.1-0.2,0.2-0.3
							c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.3,0.1,0.5,0.4,0.6C82.7,133.2,82.7,133.2,82.8,133.2z"/>
						<path class="st262" d="M82.9,132.4C82.9,132.4,82.9,132.4,82.9,132.4c-0.1-0.1-0.2-0.1-0.2-0.1
							C82.7,132.4,82.8,132.4,82.9,132.4C82.9,132.5,82.9,132.4,82.9,132.4z"/>
						<path class="st262" d="M83.1,132.8c0,0,0.1-0.2,0-0.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0.1c0,0,0,0,0,0
							C83,132.7,83,132.8,83.1,132.8z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st259" d="M91.8,126c-0.1,0.3,0.1,0.5,0.4,0.6c0.3,0.1,0.5-0.1,0.6-0.4c0-0.3-0.1-0.5-0.4-0.6
							C92.1,125.6,91.8,125.7,91.8,126z"/>
						<path class="st260" d="M92,125.7C92,125.7,92,125.7,92,125.7c-0.1,0.4,0.1,0.6,0.4,0.7c0.1,0,0.2,0,0.3-0.1
							c-0.1,0.2-0.3,0.3-0.6,0.3c-0.3-0.1-0.4-0.3-0.4-0.6C91.8,125.9,91.9,125.7,92,125.7z"/>
						<path class="st261" d="M91.8,125.8C91.8,125.8,91.8,125.9,91.8,125.8c-0.1,0.4,0.1,0.6,0.4,0.7c0.1,0,0.2,0,0.3,0
							c-0.1,0.1-0.3,0.1-0.4,0.1c-0.3-0.1-0.4-0.3-0.4-0.6C91.8,125.9,91.8,125.9,91.8,125.8z"/>
						<path class="st262" d="M92.6,126C92.6,126,92.6,126,92.6,126c0,0.1,0,0.2,0,0.2C92.6,126.2,92.6,126.1,92.6,126
							C92.5,126,92.6,126,92.6,126z"/>
						<path class="st262" d="M92.3,125.7c0,0,0.2,0,0.3,0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0
							C92.4,125.8,92.2,125.7,92.3,125.7z"/>
					</g>
					<g>
						<path class="st259" d="M88.7,142.2c-0.1,0.3,0.1,0.5,0.4,0.6c0.3,0.1,0.5-0.1,0.6-0.4c0.1-0.3-0.1-0.5-0.4-0.6
							C89,141.8,88.7,141.9,88.7,142.2z"/>
						<path class="st260" d="M88.9,141.9C88.9,141.9,88.9,141.9,88.9,141.9c-0.1,0.4,0.1,0.6,0.4,0.7c0.1,0,0.2,0,0.3-0.1
							c-0.1,0.2-0.3,0.3-0.6,0.3c-0.3,0-0.4-0.3-0.4-0.6C88.7,142.1,88.8,141.9,88.9,141.9z"/>
						<path class="st261" d="M88.8,142C88.8,142,88.8,142.1,88.8,142c-0.1,0.4,0.1,0.6,0.4,0.7c0.1,0,0.2,0,0.3,0
							c-0.1,0.1-0.3,0.1-0.4,0.1c-0.3,0-0.4-0.3-0.4-0.6C88.7,142.1,88.7,142.1,88.8,142z"/>
						<path class="st262" d="M89.5,142.2C89.5,142.2,89.5,142.2,89.5,142.2c0,0.1,0,0.2,0,0.2C89.5,142.4,89.5,142.3,89.5,142.2
							C89.5,142.2,89.5,142.2,89.5,142.2z"/>
						<path class="st262" d="M89.2,141.9c0,0,0.2,0,0.3,0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0
							C89.3,142,89.2,141.9,89.2,141.9z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st259" d="M94.7,141.4c-0.1-0.2-0.1-0.5,0.2-0.7c0.2-0.1,0.5-0.1,0.7,0.2c0.1,0.2,0.1,0.5-0.2,0.7
							C95.1,141.7,94.8,141.7,94.7,141.4z"/>
						<path class="st260" d="M95,141.6C95,141.6,95,141.6,95,141.6c-0.2-0.3-0.1-0.6,0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1
							c-0.2-0.2-0.4-0.2-0.6-0.1c-0.2,0.1-0.3,0.5-0.2,0.7C94.7,141.5,94.9,141.6,95,141.6z"/>
						<path class="st261" d="M94.8,141.6C94.8,141.5,94.8,141.5,94.8,141.6c-0.2-0.3-0.1-0.6,0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1
							c-0.1-0.1-0.3,0-0.4,0c-0.2,0.1-0.3,0.5-0.2,0.7C94.7,141.5,94.7,141.5,94.8,141.6z"/>
						<path class="st262" d="M95.4,141.1C95.4,141.1,95.4,141.1,95.4,141.1c0-0.1-0.1-0.2-0.1-0.2C95.3,140.9,95.4,141,95.4,141.1
							C95.4,141.1,95.4,141.1,95.4,141.1z"/>
						<path class="st262" d="M95.2,141.5c0,0,0.2-0.1,0.2-0.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0
							C95.3,141.4,95.2,141.5,95.2,141.5z"/>
					</g>
					<g>
						<path class="st259" d="M85.9,127.4c-0.1-0.2-0.1-0.5,0.2-0.7c0.2-0.1,0.5-0.1,0.7,0.2c0.1,0.2,0.1,0.5-0.2,0.7
							C86.4,127.7,86.1,127.7,85.9,127.4z"/>
						<path class="st260" d="M86.3,127.7C86.3,127.6,86.2,127.6,86.3,127.7c-0.2-0.3-0.1-0.6,0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1
							c-0.2-0.2-0.4-0.2-0.6-0.1c-0.2,0.1-0.3,0.5-0.2,0.7C86,127.6,86.1,127.6,86.3,127.7z"/>
						<path class="st261" d="M86.1,127.6C86.1,127.6,86,127.5,86.1,127.6c-0.2-0.3-0.1-0.6,0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1
							c-0.1-0.1-0.3,0-0.4,0c-0.2,0.1-0.3,0.5-0.2,0.7C86,127.5,86,127.5,86.1,127.6z"/>
						<path class="st262" d="M86.7,127.1C86.7,127.1,86.7,127.1,86.7,127.1c0-0.1-0.1-0.2-0.1-0.2C86.6,126.9,86.6,127,86.7,127.1
							C86.7,127.1,86.7,127.1,86.7,127.1z"/>
						<path class="st262" d="M86.5,127.5c0,0,0.2-0.1,0.2-0.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0
							C86.6,127.4,86.5,127.5,86.5,127.5z"/>
					</g>
				</g>
			</g>
			<path class="st263" d="M120.4,115.9c0.3,0.5,0.7,0.9,1,1.3c0.8-1.1,0.9-2.5,0.9-2.5c0,0-1.3-0.6-2.6-0.3
				C119.8,114.9,120.1,115.4,120.4,115.9z"/>
			<path class="st233" d="M120.6,116.3c0.2,0.3,0.5,0.6,0.8,0.9c0.8-1.1,0.9-2.5,0.9-2.5C122.3,114.7,121.6,115.7,120.6,116.3z"/>
			<path class="st239" d="M121,114.7c-0.4,0-0.4,0-0.4-0.1c0-0.1,0-0.1,0.4-0.1c0.4,0,0.7,0.2,0.7,0.2S121.4,114.7,121,114.7z"/>
			<polygon class="st263" points="121.7,114.5 142.8,101.9 122.2,115.3 			"/>
		</g>
		<g>
			<path class="st264" d="M104.1,126.1c-2.8-4.5-5.1-9.2-6.8-13.9l0.4-0.2c1.7,4.8,4,9.4,6.8,13.9c2.7,4.4,5.9,8.5,9.4,12.1
				l-0.3,0.3C110,134.7,106.8,130.6,104.1,126.1z"/>
		</g>
		<path class="st265" d="M97.1,112.2c-0.2,0-0.3,0.1-0.5,0.1c1.8,4.8,4.2,9.6,7,14.1c2.8,4.5,6,8.7,9.5,12.4
			c0.1-0.1,0.2-0.2,0.3-0.4c-3.4-3.7-6.6-7.8-9.4-12.3C101.3,121.6,98.9,116.9,97.1,112.2z"/>
		
	</g>
	<g>
		<g class="st266">
			<path class="st267" d="M669.1,145.7c0,37.3-53.4,67.6-119.3,67.6c-65.9,0-119.3-30.3-119.3-67.6c0-37.3,53.4-67.6,119.3-67.6
				C615.7,78.1,669.1,108.4,669.1,145.7z"/>
			<path class="st268" d="M667.4,145.7c0,36.8-52.6,66.6-117.6,66.6c-64.9,0-117.6-29.8-117.6-66.6s52.6-66.6,117.6-66.6
				C614.7,79.1,667.4,108.9,667.4,145.7z"/>
			<path class="st269" d="M665.7,145.7c0,36.3-51.9,65.7-115.9,65.7c-64,0-115.9-29.4-115.9-65.7c0-36.3,51.9-65.7,115.9-65.7
				C613.8,80,665.7,109.4,665.7,145.7z"/>
			<path class="st270" d="M664,145.7c0,35.8-51.1,64.8-114.2,64.8c-63.1,0-114.2-29-114.2-64.8c0-35.8,51.1-64.8,114.2-64.8
				C612.9,80.9,664,109.9,664,145.7z"/>
			<path class="st271" d="M662.3,145.7c0,35.3-50.4,63.9-112.5,63.9c-62.1,0-112.5-28.6-112.5-63.9c0-35.3,50.4-63.9,112.5-63.9
				C611.9,81.8,662.3,110.4,662.3,145.7z"/>
			<path class="st272" d="M660.6,145.7c0,34.8-49.6,62.9-110.8,62.9c-61.2,0-110.8-28.2-110.8-62.9c0-34.8,49.6-62.9,110.8-62.9
				C611,82.8,660.6,110.9,660.6,145.7z"/>
			<path class="st273" d="M658.9,145.7c0,34.2-48.8,62-109.1,62c-60.2,0-109.1-27.8-109.1-62c0-34.2,48.8-62,109.1-62
				C610.1,83.7,658.9,111.5,658.9,145.7z"/>
			<path class="st274" d="M657.2,145.7c0,33.7-48.1,61.1-107.4,61.1c-59.3,0-107.4-27.4-107.4-61.1c0-33.7,48.1-61.1,107.4-61.1
				C609.1,84.6,657.2,112,657.2,145.7z"/>
			<path class="st275" d="M655.5,145.7c0,33.2-47.3,60.2-105.7,60.2c-58.4,0-105.7-26.9-105.7-60.2c0-33.2,47.3-60.2,105.7-60.2
				C608.2,85.5,655.5,112.5,655.5,145.7z"/>
			<path class="st276" d="M653.8,145.7c0,32.7-46.6,59.2-104,59.2c-57.4,0-104-26.5-104-59.2c0-32.7,46.6-59.2,104-59.2
				C607.2,86.5,653.8,113,653.8,145.7z"/>
			<path class="st277" d="M652.1,145.7c0,32.2-45.8,58.3-102.3,58.3c-56.5,0-102.3-26.1-102.3-58.3c0-32.2,45.8-58.3,102.3-58.3
				C606.3,87.4,652.1,113.5,652.1,145.7z"/>
			<path class="st278" d="M650.4,145.7c0,31.7-45,57.4-100.6,57.4c-55.6,0-100.6-25.7-100.6-57.4c0-31.7,45-57.4,100.6-57.4
				C605.4,88.3,650.4,114,650.4,145.7z"/>
			<path class="st279" d="M648.7,145.7c0,31.2-44.3,56.5-98.9,56.5c-54.6,0-98.9-25.3-98.9-56.5c0-31.2,44.3-56.5,98.9-56.5
				C604.4,89.3,648.7,114.5,648.7,145.7z"/>
			<path class="st280" d="M647,145.7c0,30.7-43.5,55.5-97.2,55.5c-53.7,0-97.2-24.9-97.2-55.5c0-30.7,43.5-55.5,97.2-55.5
				C603.5,90.2,647,115,647,145.7z"/>
			<path class="st281" d="M645.3,145.7c0,30.2-42.8,54.6-95.5,54.6c-52.7,0-95.5-24.4-95.5-54.6c0-30.2,42.8-54.6,95.5-54.6
				C602.6,91.1,645.3,115.6,645.3,145.7z"/>
			<path class="st282" d="M643.6,145.7c0,29.6-42,53.7-93.8,53.7c-51.8,0-93.8-24-93.8-53.7c0-29.6,42-53.7,93.8-53.7
				C601.6,92,643.6,116.1,643.6,145.7z"/>
			<path class="st283" d="M641.9,145.7c0,29.1-41.2,52.7-92.1,52.7c-50.9,0-92.1-23.6-92.1-52.7c0-29.1,41.2-52.7,92.1-52.7
				C600.7,93,641.9,116.6,641.9,145.7z"/>
			<path class="st284" d="M640.2,145.7c0,28.6-40.5,51.8-90.4,51.8c-49.9,0-90.4-23.2-90.4-51.8c0-28.6,40.5-51.8,90.4-51.8
				C599.7,93.9,640.2,117.1,640.2,145.7z"/>
			<path class="st285" d="M638.5,145.7c0,28.1-39.7,50.9-88.7,50.9c-49,0-88.7-22.8-88.7-50.9c0-28.1,39.7-50.9,88.7-50.9
				C598.8,94.8,638.5,117.6,638.5,145.7z"/>
			<path class="st286" d="M636.8,145.7c0,27.6-39,50-87,50c-48.1,0-87-22.4-87-50c0-27.6,39-50,87-50
				C597.9,95.7,636.8,118.1,636.8,145.7z"/>
			<path class="st287" d="M635.1,145.7c0,27.1-38.2,49-85.3,49c-47.1,0-85.3-22-85.3-49c0-27.1,38.2-49,85.3-49
				C596.9,96.7,635.1,118.6,635.1,145.7z"/>
			<path class="st288" d="M633.4,145.7c0,26.6-37.4,48.1-83.6,48.1c-46.2,0-83.6-21.5-83.6-48.1c0-26.6,37.4-48.1,83.6-48.1
				C596,97.6,633.4,119.1,633.4,145.7z"/>
			<path class="st289" d="M631.7,145.7c0,26.1-36.7,47.2-81.9,47.2c-45.2,0-81.9-21.1-81.9-47.2c0-26.1,36.7-47.2,81.9-47.2
				C595.1,98.5,631.7,119.6,631.7,145.7z"/>
			<path class="st290" d="M630,145.7c0,25.5-35.9,46.3-80.2,46.3c-44.3,0-80.2-20.7-80.2-46.3c0-25.5,35.9-46.3,80.2-46.3
				C594.1,99.4,630,120.2,630,145.7z"/>
			<path class="st291" d="M628.3,145.7c0,25-35.2,45.3-78.5,45.3c-43.4,0-78.5-20.3-78.5-45.3c0-25,35.2-45.3,78.5-45.3
				C593.2,100.4,628.3,120.7,628.3,145.7z"/>
			<path class="st292" d="M626.6,145.7c0,24.5-34.4,44.4-76.8,44.4c-42.4,0-76.8-19.9-76.8-44.4c0-24.5,34.4-44.4,76.8-44.4
				C592.2,101.3,626.6,121.2,626.6,145.7z"/>
			<path class="st293" d="M624.9,145.7c0,24-33.6,43.5-75.1,43.5c-41.5,0-75.1-19.5-75.1-43.5c0-24,33.6-43.5,75.1-43.5
				C591.3,102.2,624.9,121.7,624.9,145.7z"/>
			<path class="st294" d="M623.3,145.7c0,23.5-32.9,42.6-73.4,42.6c-40.6,0-73.4-19.1-73.4-42.6c0-23.5,32.9-42.6,73.4-42.6
				C590.4,103.1,623.3,122.2,623.3,145.7z"/>
			<path class="st295" d="M621.6,145.7c0,23-32.1,41.6-71.7,41.6c-39.6,0-71.7-18.6-71.7-41.6c0-23,32.1-41.6,71.7-41.6
				C589.4,104.1,621.6,122.7,621.6,145.7z"/>
			<path class="st296" d="M619.9,145.7c0,22.5-31.4,40.7-70.1,40.7c-38.7,0-70.1-18.2-70.1-40.7c0-22.5,31.4-40.7,70.1-40.7
				C588.5,105,619.9,123.2,619.9,145.7z"/>
			<path class="st297" d="M618.2,145.7c0,22-30.6,39.8-68.4,39.8c-37.8,0-68.4-17.8-68.4-39.8c0-22,30.6-39.8,68.4-39.8
				C587.6,105.9,618.2,123.7,618.2,145.7z"/>
			<path class="st298" d="M616.5,145.7c0,21.5-29.8,38.9-66.7,38.9c-36.8,0-66.7-17.4-66.7-38.9c0-21.5,29.8-38.9,66.7-38.9
				C586.6,106.9,616.5,124.2,616.5,145.7z"/>
			<path class="st299" d="M614.8,145.7c0,20.9-29.1,37.9-65,37.9c-35.9,0-65-17-65-37.9c0-20.9,29.1-37.9,65-37.9
				C585.7,107.8,614.8,124.8,614.8,145.7z"/>
			<path class="st300" d="M613.1,145.7c0,20.4-28.3,37-63.3,37c-34.9,0-63.3-16.6-63.3-37c0-20.4,28.3-37,63.3-37
				C584.7,108.7,613.1,125.3,613.1,145.7z"/>
			<path class="st301" d="M611.4,145.7c0,19.9-27.6,36.1-61.6,36.1c-34,0-61.6-16.2-61.6-36.1c0-19.9,27.6-36.1,61.6-36.1
				C583.8,109.6,611.4,125.8,611.4,145.7z"/>
			<path class="st302" d="M609.7,145.7c0,19.4-26.8,35.1-59.9,35.1c-33.1,0-59.9-15.7-59.9-35.1c0-19.4,26.8-35.1,59.9-35.1
				C582.9,110.6,609.7,126.3,609.7,145.7z"/>
			<path class="st303" d="M608,145.7c0,18.9-26,34.2-58.2,34.2c-32.1,0-58.2-15.3-58.2-34.2c0-18.9,26-34.2,58.2-34.2
				C581.9,111.5,608,126.8,608,145.7z"/>
			<path class="st304" d="M606.3,145.7c0,18.4-25.3,33.3-56.5,33.3c-31.2,0-56.5-14.9-56.5-33.3c0-18.4,25.3-33.3,56.5-33.3
				C581,112.4,606.3,127.3,606.3,145.7z"/>
			<path class="st305" d="M604.6,145.7c0,17.9-24.5,32.4-54.8,32.4c-30.3,0-54.8-14.5-54.8-32.4c0-17.9,24.5-32.4,54.8-32.4
				C580.1,113.3,604.6,127.8,604.6,145.7z"/>
			<path class="st306" d="M602.9,145.7c0,17.4-23.8,31.4-53.1,31.4c-29.3,0-53.1-14.1-53.1-31.4c0-17.4,23.8-31.4,53.1-31.4
				C579.1,114.3,602.9,128.3,602.9,145.7z"/>
			<path class="st307" d="M601.2,145.7c0,16.9-23,30.5-51.4,30.5c-28.4,0-51.4-13.7-51.4-30.5c0-16.8,23-30.5,51.4-30.5
				C578.2,115.2,601.2,128.9,601.2,145.7z"/>
			<path class="st308" d="M599.5,145.7c0,16.3-22.2,29.6-49.7,29.6c-27.4,0-49.7-13.3-49.7-29.6c0-16.3,22.2-29.6,49.7-29.6
				C577.2,116.1,599.5,129.4,599.5,145.7z"/>
			<path class="st309" d="M597.8,145.7c0,15.8-21.5,28.7-48,28.7c-26.5,0-48-12.8-48-28.7c0-15.8,21.5-28.7,48-28.7
				C576.3,117,597.8,129.9,597.8,145.7z"/>
			<path class="st310" d="M596.1,145.7c0,15.3-20.7,27.7-46.3,27.7c-25.6,0-46.3-12.4-46.3-27.7c0-15.3,20.7-27.7,46.3-27.7
				C575.4,118,596.1,130.4,596.1,145.7z"/>
			<path class="st311" d="M594.4,145.7c0,14.8-20,26.8-44.6,26.8c-24.6,0-44.6-12-44.6-26.8c0-14.8,20-26.8,44.6-26.8
				C574.4,118.9,594.4,130.9,594.4,145.7z"/>
			<path class="st312" d="M592.7,145.7c0,14.3-19.2,25.9-42.9,25.9c-23.7,0-42.9-11.6-42.9-25.9c0-14.3,19.2-25.9,42.9-25.9
				C573.5,119.8,592.7,131.4,592.7,145.7z"/>
		</g>
		<g>
			<path class="st313" d="M605.1,145.7c0,30.5-24.7,55.3-55.3,55.3c-30.5,0-55.3-24.7-55.3-55.3c0-30.5,24.7-55.3,55.3-55.3
				C580.3,90.5,605.1,115.2,605.1,145.7z"/>
			<path class="st314" d="M510.9,180.4c-1.2-1.4-2.4-2.9-3.6-4.3c-2.4-2.9-4.8-5.8-6.8-8.9c-1.9-3-3.6-6-4.6-9.4
				c1.5,6.8,4.3,13.1,8,18.6c1.2,1.3,2.5,2.5,3.7,3.6c1.3,1.2,2.5,2.2,3.6,3.1c1.1,0.9,2.1,1.8,3,2.5c0-0.3-0.1-0.6-0.1-0.9
				C513.1,183.2,512,181.8,510.9,180.4z M603.3,155.5c-0.4,0-0.8,0-1.2-0.1c-3.2-0.3-6.5-2.4-9.7-4.9c-3.1-2.5-6.3-5.2-9.6-5.5
				c-0.3,0-0.6-0.1-0.9-0.1c-3,0-6.2,2.3-8.7,5.7c-2.8,3.8-4.5,8.1-5.3,10.9c-0.7,3-0.6,5,0.2,7.5c0.8,2.5,2.2,5.6,4.2,9.3
				c2,3.6,4.9,7.8,8,9.7c1.1,0.7,2.1,1,3.2,1c0.1,0,0.3,0,0.4,0c3-2.4,5.8-5.1,8.3-8.1c-1.1,0.7-2.1,1.3-3.1,1.9
				c-1.8,1.1-3.7,1.8-5.5,1.8c-0.7,0-1.5-0.1-2.2-0.4c-2.6-0.8-5.2-3.4-7.3-6.4c-2.1-3.1-3.7-6.2-4.4-8.9c-0.7-2.7-0.8-5-0.1-8
				c0.7-2.9,2.3-6.6,4.8-9.6c2.2-2.7,4.8-4.3,7.5-4.3c0.4,0,0.7,0,1.1,0.1c3,0.4,6,2.7,9,4.9c3,2.2,6,4.1,8.8,4.8
				c1,0.3,1.9,0.4,2.8,0.5c0.1-0.6,0.3-1.3,0.4-1.9C603.9,155.5,603.6,155.5,603.3,155.5z M570.2,182.2c-0.4-0.5-0.9-1-1.4-1.4
				c-0.9-0.8-1.8-1.5-2.9-1.9c-1-0.5-2.1-0.8-3.4-1c-1.3-0.3-2.8-0.5-4.6-1.1c-1.7-0.5-3.7-1.4-5.4-2.4c-1.7-1-3.2-2-4.6-2.9
				c-1.4-0.9-2.7-1.7-4-2.3c-1.3-0.7-2.6-1.2-4.1-1.7c-2.8-0.9-6.1-1.4-9.6-1.9c-3.5-0.5-7.3-1-10.6-2c-1.7-0.5-3.2-1-4.6-1.6
				c-0.2-0.1-0.5-0.2-0.7-0.3c0-0.3,0-0.6,0-0.9c-0.1-2.2-0.5-4.6-0.9-7.1c-0.9-5-1.8-10.3-1.3-15.1c0.1-2,0.5-3.9,0.9-5.6
				c0.3,1.6,0.8,3.3,1.5,5.1c1.5,4,4,8.8,6.8,12.6c2.8,3.9,6,6.6,9.1,7.6c1.3,0.5,2.6,0.7,3.9,0.7c5,0,10-3.1,14.7-6.6
				c6-4.4,11.8-9.2,17.5-12.2c5.6-3.1,11.2-4.2,16.6-5c2.7-0.5,5.3-1,7.6-2.6c1.1-0.8,2.2-2,3-3.6c0.8-1.6,1.3-3.8,1.2-6
				c-0.1-2.1-0.7-4-1.5-5.2c-0.9-1.3-1.9-2-3.1-2.6c-2.3-1.1-5-1.3-7.7-1.6c-5.5-0.6-11.3-1.9-17.3-5c-6-3-12-7.6-18.2-11.9
				c-2.7-1.8-5.4-3.6-8.1-5c-0.8,0.1-1.5,0.3-2.2,0.5c3.4,1.6,6.7,3.7,10,6c6.1,4.2,12.1,8.6,17.9,11.6c5.8,3.1,11.5,4.6,16.6,5.6
				c2.6,0.6,5,1.1,7,2.2c2,1.1,3.6,3.2,3.6,5.8c0.1,2.6-1.5,5-3.5,6.3c-2,1.4-4.5,2.1-7,2.7c-5.1,1.1-10.7,2.4-16.3,5.2
				c-5.6,2.6-11.2,6.6-16.8,9.9c-4.2,2.4-8.4,4.3-12.4,4.3c-1.4,0-2.7-0.2-4-0.7c-2.6-0.9-4.9-2.7-6.9-5.1c-2-2.4-3.5-5.3-4.4-8.2
				c-0.9-2.9-1.2-5.7-0.9-8.5c0.1-1.4,0.5-2.8,1-4c0.6-1.3,1.3-2.5,2.1-3.6c1.7-2.2,3.7-3.9,5.5-5.5c0.2-0.2,0.4-0.3,0.6-0.5
				c0,0.7,0.1,1.4,0.3,2.1c0.2,0.9,0.6,1.8,1.1,2.7c0.9,1.7,2.1,3.2,3.3,4.4c1.2,1.2,2.4,2.2,3.3,3.2c1,1,1.6,2.1,2.1,3.7
				c0.2,0.8,0.5,1.6,0.7,2.5c0.3,0.9,0.6,1.7,0.9,2.5c0.3,0.7,0.6,1.4,0.8,1.9c0.2,0.6,0.3,1.1,0.4,1.6c0,0.5,0,1-0.1,1.6
				c0,0.3-0.1,0.5-0.2,0.8c-0.1,0.3-0.1,0.5-0.2,0.8c-0.4,1.1-1.1,2.2-2,2.6c-0.3,0.2-0.7,0.2-1,0.2c-0.7,0-1.5-0.3-2.4-0.9
				c-1.3-0.9-2.9-2.4-4.6-4.1c-1.7-1.7-3.5-3.7-4.7-6c-1.3-2.3-1.7-4.7-1.3-6.4c0.4-1.6,1.6-2.3,2.8-2.3c0,0,0.1,0,0.1,0
				c0.6,0,1.3,0.1,2,0.4c0.2,0.1,0.3,0.1,0.5,0.2l0.2,0.1l0.3,0.1c0.3,0.1,0.6,0.3,0.9,0.5c0.6,0.4,1,1,1.1,1.7
				c0.1,0.8,0,1.7-0.2,2.5c-0.2,0.8-0.4,1.6-0.7,2.1c-0.3,0.6-0.6,1.1-1.1,1.2c0.1,0.2,0.2,0.4,0.3,0.6c1.2-0.2,1.7-1.4,2.2-3.1
				c0.1-0.4,0.2-1,0.3-1.5c0.1-0.6,0.1-1.2,0.1-2c-0.1-0.7-0.2-1.4-0.5-2c-0.3-0.6-0.6-1-1-1.3c-0.4-0.3-0.7-0.6-1.1-0.9l-0.3-0.2
				l-0.3-0.2c-0.2-0.1-0.4-0.2-0.6-0.3c-0.7-0.4-1.5-0.7-2.3-0.9c-0.3-0.1-0.6-0.1-0.9-0.1c-0.5,0-1,0.1-1.5,0.3
				c-0.8,0.4-1.6,1.1-2.1,2.2c-0.5,1.1-0.7,2.6-0.5,4.2c0.2,1.6,0.8,3.2,1.6,4.8c1.7,3.1,4.2,5.6,6.3,7.8c2.2,2.2,4,4.4,5.7,5.9
				c0.9,0.7,1.7,1.2,2.5,1.3c0.1,0,0.2,0,0.3,0c0.7,0,1.4-0.3,2-0.9c1.3-1.3,2.1-3.5,2.3-5.1c0.1-0.4,0.1-0.8,0.1-1.2
				c0-0.3,0-0.7,0-1c-0.1-0.6-0.2-1.2-0.4-1.7c-0.2-0.5-0.5-1-0.9-1.6c-0.4-0.6-0.9-1.2-1.4-2c-0.5-0.8-0.9-1.8-1.2-2.8
				c-0.3-1-0.5-1.9-0.7-2.7c-0.2-0.8-0.5-1.5-0.7-2.2c-0.3-0.7-0.6-1.4-1-2c-0.8-1.3-1.8-2.6-3-4c-1.2-1.4-2.4-2.9-3.3-4.4
				c-0.5-0.7-0.9-1.5-1.2-2.2c-0.2-0.4-0.3-0.8-0.5-1.2c1.5-1.2,3.1-2.3,4.9-3.2c1.9-1,4.2-1.7,6.5-1.7c0.4,0,0.8,0,1.2,0.1
				c1.4,0.1,2.8,0.5,4.3,1.1c1.4,0.6,2.8,1.3,4.2,2c2.8,1.5,5.4,3.1,8,4.7c1.3,0.8,2.5,1.5,3.7,2.3c1.1,0.7,2.3,1.4,3.3,2.1
				c1,0.7,1.9,1.4,2.6,2c0.1,0.1,0.3,0.3,0.3,0.4c-0.1,0-0.2,0-0.4,0.1c-0.6,0.1-1.5,0.2-2.4,0.2c-0.2,0-0.4,0-0.6,0
				c-2.4,0-4.9-0.5-7.3-1.2c-1.2-0.4-2.4-0.8-3.5-1.4c-1.1-0.6-2.2-1.2-3.8-1.2c-1.6,2.5-3.2,5.3-4.9,8.3c1,1.8,2.6,2.5,4.3,2.9
				c1.6,0.4,3.3,0.6,5,0.7c0.3,0,0.6,0,0.9,0c3.1,0,6.3-0.5,9.6-1.1c1.8-0.4,3.7-0.8,5.8-1.5c1-0.4,2.1-0.8,3.2-1.5
				c0.5-0.4,1.1-0.8,1.5-1.3c0.4-0.5,0.7-1.2,0.8-1.8c0.2-1.2-0.4-2.2-0.9-2.9c-0.6-0.7-1.2-1.2-1.9-1.7c-1.3-0.9-2.7-1.6-4.1-2.4
				c-1.4-0.7-2.8-1.4-4.3-2.2c-1.4-0.7-2.9-1.5-4.3-2.4c-2.9-1.7-5.9-3.6-9-5.5c-1.6-1-3.1-1.9-4.8-2.9c-1.6-0.9-3.2-1.9-4.9-2.8
				c-3.3-1.8-6.8-3.5-10.4-3.5c-0.1,0-0.1,0-0.2,0c-3.7,0-7.4,2.1-10.5,5.7c-3.1,3.5-5.3,8.1-6.7,12c-0.1,0.1-0.1,0.3-0.1,0.4
				c-0.6-0.2-1.2-0.3-1.9-0.3c-0.7,0-1.4,0.1-2,0.3c-0.5,0.1-1,0.4-1.5,0.6c-0.5,0.3-1,0.6-1.4,0.9c-0.9,0.7-1.8,1.5-2.6,2.5
				c-0.8,1-1.7,2.3-2.5,3.9c-0.9,1.6-1.8,3.6-2.7,5.9c-1,2.3-2,5-3,7.7c-0.9,2.6-1.6,5.2-2.1,7.6c-0.1,0.7-0.3,1.4-0.4,2.1
				c0.1,1.6,0.2,3.2,0.5,4.8c-0.2-2.3,0-4.8,0.5-7.5c0.4-2.3,1.1-4.7,1.9-7c0.8-2.3,1.7-4.6,2.6-6.4c0.9-1.9,1.7-3.4,2.4-4.6
				c0.8-1.2,1.5-2.1,2.2-2.8c0.7-0.7,1.4-1.2,2.1-1.6c0.4-0.2,0.7-0.3,1.1-0.5c0.4-0.1,0.7-0.3,1-0.3c0.3,0,0.5-0.1,0.8-0.1
				c1,0,1.9,0.4,2.4,1.4c0.6,1.2,0.4,3.1-0.2,5.8c-0.6,2.7-1.3,6.3-1.4,10.2c-0.2,3.9,0.3,8.3,0.8,12.2c0.4,3,0.6,5.7,0.4,7.9
				l-0.1-0.1c0,0.3,0,0.5,0,0.8c-0.1,0.5-0.1,0.9-0.2,1.3c-0.5,2-1.7,3-3.2,3c-0.2,0-0.5,0-0.7-0.1c-0.9-0.2-1.8-0.6-2.8-1.2
				c-0.2-0.2-0.5-0.3-0.7-0.5l-0.3-0.3l-0.3-0.3c-0.4-0.4-0.9-0.9-1.2-1.5c-0.7-1.2-1.2-2.6-1.1-4.3c0-1.7,0.6-3.5,1.2-4.9
				c0.6-1.4,1.3-2.5,1.9-3.2c0.6-0.6,1.1-1,1.6-1c0.1,0,0.2,0,0.3,0c0-0.4,0.1-0.8,0.1-1.2c-0.3-0.2-0.6-0.3-0.9-0.3
				c-1,0-2.2,1.2-3.4,3.3c-0.4,0.7-0.8,1.6-1.2,2.7c-0.4,1.1-0.7,2.4-0.9,4.1c-0.2,1.6,0,3.4,0.3,4.8c0.4,1.4,0.9,2.5,1.4,3.3
				c0.5,0.9,1.1,1.5,1.7,2.1c0.1,0.1,0.3,0.3,0.4,0.4c0.1,0.1,0.3,0.3,0.4,0.4c0.3,0.2,0.6,0.5,0.8,0.7c1.1,0.8,2.3,1.5,3.5,1.8
				c0.4,0.1,0.9,0.2,1.3,0.2c0.7,0,1.3-0.2,1.9-0.5c1-0.5,1.8-1.6,2.3-3.2c0.2-0.7,0.4-1.5,0.5-2.3c0.4,0.4,0.9,0.7,1.4,1
				c1.6,1,3.3,1.8,5.1,2.5c3.6,1.2,7.2,1.7,10.5,2c3.3,0.2,6.3,0,9,0.2c2.7,0.2,5.1,1,7.8,2.6c1.4,0.8,2.8,1.8,4.4,2.7
				c1.6,0.9,3.2,1.7,4.6,2.4c1.4,0.6,2.7,1.2,3.7,1.7c1,0.5,1.9,1.1,2.6,1.8c0.7,0.7,1.4,1.5,2,2.4c0.3,0.5,0.6,0.9,0.9,1.4
				c0.3,0.5,0.5,1,0.7,1.5c0.9,2.1,1.3,4.5,0.5,6.4c-0.8,1.9-2.7,3.1-5.9,3.6c-1.5,0.2-3.1,0.3-5,0.3c-2.1,0-4.5-0.1-7-0.2
				c-4.6-0.3-9.9-0.9-14.7-2.6c-4.8-1.6-8.7-4.6-10.2-7.6c-1.6-3-0.7-5.6,1.2-7.2c0.9-0.8,2.1-1.5,3.4-2.1c0.3-0.1,0.6-0.3,1-0.4
				c0.2-0.1,0.3-0.1,0.5-0.2c0.2-0.1,0.3-0.1,0.5-0.2c0.6-0.2,1.3-0.4,2-0.4c0.2,0,0.4,0,0.6,0c1.2,0,2.3,0.3,3.3,1.1
				c1.2,0.9,2.3,2.5,3.1,3.9c0.8,1.4,1.5,2.8,1.8,4c0.4,1.2,0.5,2.3,0.1,3.2c0.4,0.2,0.8,0.3,1.2,0.5c1.5-1.8,0.7-4.2-0.9-7.4
				c-0.4-0.8-0.9-1.7-1.6-2.6c-0.6-0.9-1.4-1.9-2.4-2.9c-1-1-2.3-1.8-3.4-2.2c-1.1-0.4-2.2-0.6-3.2-0.6c-0.1,0-0.2,0-0.3,0
				c-0.9,0-1.6,0.1-2.4,0.2c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0-0.4,0.1-0.6,0.1c-0.4,0.1-0.8,0.2-1.2,0.3c-1.6,0.4-3.2,0.9-4.5,1.7
				c-1.3,0.8-2.5,1.9-3.2,3.4c-0.7,1.5-0.8,3.6-0.1,5.9c0.7,2.3,2.4,4.7,4.8,6.8c2.4,2,5.4,3.6,8.6,4.7c6.5,2.2,13.3,2.5,19.3,3
				c3.6,0.2,7,0.6,10.1,0.8c4.7-1,9.2-2.6,13.3-4.7c0-0.2,0.1-0.4,0.1-0.6c0.2-3.6-1.5-7.8-3.3-10.4
				C571.1,183.3,570.7,182.7,570.2,182.2z M568.9,124.8c0,0.1,0,0.1,0.1,0.1c0,0.1,0,0.2,0,0.2c0,0-0.1,0-0.1-0.1
				C568.9,124.9,568.9,124.9,568.9,124.8z M593.9,133.4c-0.6,0.1-1.2,0.4-1.8,1c-0.6,0.6-1,1.5-1.2,2.6c-0.2,1.1-0.2,2.2,0.1,3.4
				c0.5,2.3,1.5,4.4,2.4,6.2c0.9,1.8,1.6,3.6,2.4,4.9c0.4,0.6,0.8,1.1,1.4,1.4c0.2,0.1,0.4,0.1,0.7,0.1c0.3,0,0.6-0.1,0.9-0.2
				c1.1-0.6,2-1.8,2.5-2.8c0.1-0.2,0.2-0.5,0.3-0.7c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.4,0.1-0.8,0.1-1.2c0-0.4-0.1-0.8-0.3-1.2
				c-0.1-0.4-0.3-0.9-0.5-1.5c-0.1-0.6-0.2-1.3-0.2-2c0-0.7,0-1.3,0.1-1.9c0-0.6,0-1.1,0-1.6c0-0.5-0.1-1-0.2-1.5
				c-0.2-1-0.6-2.1-1.1-3.2c-0.5-1.1-1-2.3-1.2-3.5c-0.1-0.6-0.2-1.1-0.3-1.6c-0.1-0.5-0.1-1,0-1.4l-0.3,0.2
				c-0.3,0.6-0.4,1.2-0.5,1.9c0,0.6,0,1.3,0.1,1.9c0.3,1.3,0.7,2.4,1.2,3.5c0.5,1,1.1,1.9,1.5,2.7c0.4,0.8,0.6,1.7,0.6,2.8
				c0,0.5,0,1.1,0,1.8c0,0.6,0,1.2,0.1,1.8c0.1,0.5,0.1,1,0.1,1.4c0,0.4,0,0.8-0.1,1.1c-0.1,0.3-0.2,0.7-0.4,1
				c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.2-0.2,0.3-0.3,0.5c-0.5,0.6-1.1,1.2-1.8,1.2c-0.1,0-0.2,0-0.2,0c-0.6,0-1.2-0.4-1.8-1.2
				c-0.7-0.9-1.3-2.1-2.1-3.5c-0.7-1.4-1.5-3.1-1.8-4.8c-0.3-1.7-0.1-3.4,0.5-4.3c0.4-0.7,1.1-1,1.7-1c0.2,0,0.5,0,0.7,0.1
				c0.4,0.1,0.8,0.4,1.2,0.6l0.3,0.2l0.1,0.1l0.1,0.1c0.2,0.2,0.3,0.3,0.5,0.5c0.3,0.4,0.4,0.8,0.3,1.3c-0.1,0.5-0.3,1.1-0.6,1.6
				c-0.3,0.5-0.6,0.9-0.9,1.2c-0.3,0.3-0.6,0.5-0.9,0.6c0,0.2,0,0.3,0.1,0.4c0.1,0,0.1,0,0.2,0c0.7,0,1.2-0.6,1.8-1.6
				c0.2-0.3,0.3-0.6,0.5-0.9c0.2-0.4,0.3-0.8,0.4-1.2c0.1-0.5,0.1-1,0.1-1.4c-0.1-0.4-0.2-0.8-0.4-1c-0.2-0.3-0.3-0.5-0.5-0.8
				l-0.1-0.2l-0.2-0.2l-0.3-0.3c-0.4-0.4-0.8-0.8-1.3-1c-0.4-0.2-0.8-0.4-1.3-0.4C594.1,133.4,594,133.4,593.9,133.4z M589.4,170.8
				c-0.7,0-1.3,0.1-1.9,0.1c-0.7,0-1.4,0-2-0.1c-1.2-0.1-2-0.5-2.3-0.7c-0.2-0.1-0.3-0.2-0.4-0.5c-0.1-0.3-0.2-0.8-0.2-1.4
				c0.1-1.2,0.5-2.5,1.1-3.8c0.6-1.2,1.3-2.3,2.2-3.2c0.6,1,0.9,2,0.6,2.9c-0.2,0.5-0.6,0.9-1,1.3c-0.5,0.4-1,0.7-1.6,0.9
				c0.1,0.3,0.2,0.6,0.3,0.8c0.7-0.3,1.3-0.7,1.8-1.1c0.5-0.4,1-1,1.3-1.6c0.2-0.6,0.2-1.4,0-2.1c-0.2-0.7-0.6-1.4-1-2l0,0l-0.1-0.2
				c-0.2,0-0.3,0-0.5,0c-0.1,0-0.1,0-0.2,0c-1.7,0-3.2,0.6-4.6,1.6c-1.4,1.1-2.7,2.7-3.5,4.8c-0.4,1.1-0.6,2.3-0.5,3.7
				c0.1,1.4,0.6,3,1.5,4.3c0.9,1.3,2,2.1,3,2.6c1,0.4,1.9,0.6,2.8,0.6c0.1,0,0.1,0,0.2,0c1.9,0,3.6-0.7,5.3-1.4
				c1.7-0.7,3.3-1.5,4.9-2.2c1.2-0.5,2.4-1,3.5-1.4c0.6-1.1,1.2-2.2,1.7-3.3c-1.9,0.5-4.1,0.9-6.2,1.2
				C592.2,170.6,590.8,170.7,589.4,170.8z M550,158.5l0-0.2c0.1-0.3,0.2-0.6,0.3-0.9c0.3-0.6,0.8-1.1,1.5-1.5
				c0.7-0.4,1.6-0.6,2.3-0.7c0.4-0.1,0.7-0.1,1-0.1c0.3,0,0.6,0,0.8,0.1c0.5,0.1,0.8,0.3,0.9,0.6c0.2-0.1,0.3-0.2,0.5-0.3
				c0.1-0.7-0.7-1-2-1c-0.1,0-0.2,0-0.3,0c-0.4,0-0.9,0.1-1.4,0.2c-0.5,0.1-1.2,0.3-1.9,0.7c-0.7,0.3-1.4,0.8-1.9,1.3
				c-0.5,0.5-0.8,1-1,1.4c-0.2,0.4-0.4,0.8-0.5,1.2l-0.1,0.3l0,0.3c0,0.2-0.1,0.3-0.1,0.5c-0.1,0.7,0,1.3,0.1,1.9
				c0.2,0.5,0.5,1,0.9,1.2c0.3,0.2,0.6,0.3,1.1,0.3c0.2,0,0.5,0,0.8-0.1c0.8-0.2,1.6-0.6,2.5-1.2c0.9-0.6,1.8-1.4,2.7-2.1
				c1.8-1.6,3.8-3.3,5.9-4.3c2.1-1.1,4.3-1.5,6-1.7c0.9-0.1,1.6-0.3,2.2-0.5c0.5-0.2,0.9-0.6,1-1c0.3-0.8-0.1-1.8-0.9-2.5
				c-0.2-0.2-0.4-0.3-0.6-0.5c-0.2-0.1-0.5-0.3-0.7-0.4c-0.5-0.2-1.1-0.4-1.7-0.5c-0.4-0.1-0.9-0.1-1.4-0.1c-0.2,0-0.5,0-0.8,0
				c-0.9,0-1.9,0.2-3.1,0.3c-1.2,0.2-2.6,0.4-3.9,0.7c-1.3,0.3-2.5,0.6-3.6,1c-1.1,0.4-2.1,0.9-3.1,1.4c-0.9,0.5-1.8,1.2-2.6,1.9
				c-1.6,1.4-2.8,3.2-3.5,5c-0.8,1.8-1.2,3.6-1.5,5.2c-0.2,0.8-0.3,1.6-0.4,2.3c-0.1,0.7-0.2,1.3-0.3,1.8l0.4-0.3
				c0.4-0.8,0.7-1.6,1-2.4c0.3-0.8,0.6-1.7,0.9-2.5c0.6-1.7,1.2-3.4,2-5c0.8-1.6,1.6-3,2.7-4.2c1.2-1.3,2.7-2.3,4.8-3.1
				c1-0.4,2.2-0.7,3.3-0.9c1.2-0.2,2.3-0.4,3.3-0.5c0.8-0.1,1.6-0.1,2.2-0.1c0.1,0,0.2,0,0.3,0c0.7,0,1.2,0.1,1.7,0.2
				c0.5,0.1,0.8,0.3,1.2,0.5c0.2,0.1,0.3,0.2,0.5,0.3c0.1,0.1,0.3,0.2,0.4,0.3c0.4,0.5,0.6,1.1,0.2,1.6c-0.3,0.5-1.2,0.9-2.4,1.4
				c-1.3,0.4-2.9,1-4.6,1.9c-1.7,0.9-3.3,2.2-4.8,3.4c-1.5,1.2-2.9,2.2-4,2.5c-0.3,0.1-0.5,0.1-0.8,0.1c-0.7,0-1.2-0.4-1.4-1
				c-0.2-0.4-0.2-0.9-0.2-1.4c0-0.1,0-0.3,0-0.4L550,158.5z"/>
			<path class="st315" d="M600.3,123.2c-3.6,33.5-31.9,59.5-66.3,59.5c-11.2,0-21.7-2.8-30.9-7.6c9.8,15.5,27,25.8,46.7,25.8
				c30.5,0,55.3-24.7,55.3-55.3C605.1,137.7,603.3,130.1,600.3,123.2z"/>
			<path class="st316" d="M540.8,102.5c3.6,2.9-2.7,7.9-11.5,19c-8.9,11.2-17,24.3-20.6,21.5c-3.6-2.9-1.4-20.7,7.5-31.9
				C525,99.9,537.2,99.6,540.8,102.5z"/>
			<path class="st317" d="M530.2,106.4c1.3,1.3-1.5,3-5.6,7c-4.1,4-8.1,8.8-9.4,7.5c-1.3-1.3,0.5-8.3,4.7-12.3
				C524.1,104.5,529,105,530.2,106.4z"/>
			<path class="st318" d="M544.8,197.8c-7.8,0-15.3-1.4-22.2-4c8,4.5,17.3,7.1,27.2,7.1c26.5,0,48.6-18.6,54-43.4
				C594.6,181.1,571.7,197.8,544.8,197.8z"/>
		</g>
	</g>
	<g>
		<g>
			<path class="st319" d="M460.6,312.3c-0.4,0.6-1.1,5.3-1.1,5.3s-3.4,0.9-7.2-1.9c-4.2-3.2-3.9-6.7-3.9-6.7s4.5-1.7,4.9-2.2
				c0.7-0.9,2.9-0.4,4.9,1.1C460.2,309.4,461.3,311.4,460.6,312.3z"/>
			<path class="st320" d="M455.1,317.2c2.5,0.9,4.4,0.4,4.4,0.4s0.7-4.8,1.1-5.3c0.7-0.9-0.4-2.9-2.5-4.4c0,0-0.1-0.1-0.1-0.1
				c-0.4,1.2-1.9,4.1-2.4,6C455.1,315.4,455,316.1,455.1,317.2z"/>
		</g>
		<g>
			<path class="st319" d="M418.9,312c0.4,0.3,4.1,0.4,4.1,0.4s0.9,2.5-0.9,5.6c-2,3.4-4.7,3.5-4.7,3.5s-1.6-3.2-2-3.5
				c-0.7-0.4-0.5-2.1,0.5-3.8C416.7,312.6,418.1,311.6,418.9,312z"/>
			<path class="st320" d="M423,315.8c0.5-2,0-3.4,0-3.4s-3.7-0.1-4.1-0.4c-0.7-0.4-2.1,0.6-3.1,2.2c0,0,0,0.1-0.1,0.1
				c0.9,0.2,3.3,1.1,4.7,1.4C421.6,315.9,422.2,315.9,423,315.8z"/>
		</g>
		<circle class="st321" cx="438.5" cy="330.4" r="24.6"/>
		<g>
			<path class="st322" d="M419.1,324.5c-0.8-1.1-0.9-1.9,1.4-5.5c2.3-3.6,8.3-7.8,10.2-4.4c1.9,3.4,3.1,8.7,1.9,10.6
				c-1.2,1.9-6.6,4.8-8.8,3.1C421.4,326.6,420,325.7,419.1,324.5z"/>
			<path class="st323" d="M421.1,320.2c2.3-3.6,8.3-7.8,10.2-4.4c0.1,0.2,0.2,0.3,0.3,0.5c-0.3-0.6-0.5-1.2-0.8-1.7
				c-1.9-3.4-7.9,0.8-10.2,4.4c-2.3,3.6-2.3,4.3-1.4,5.5c0,0,0,0,0,0C419.1,323.7,419.6,322.6,421.1,320.2z"/>
		</g>
		<g>
			<path class="st322" d="M457.4,332.4c1.2,0.7,1.5,1.4,0.7,5.6c-0.8,4.2-4.9,10.3-7.8,7.8c-3-2.5-6.1-7-5.6-9.2
				c0.4-2.2,4.3-6.9,7.1-6.1C454.5,331.3,456.1,331.6,457.4,332.4z"/>
			<path class="st323" d="M457.1,337c-0.8,4.2-4.9,10.3-7.8,7.8c-0.2-0.1-0.3-0.3-0.5-0.4c0.5,0.5,0.9,0.9,1.4,1.3
				c3,2.5,7-3.6,7.8-7.8c0.8-4.2,0.5-4.9-0.7-5.6c0,0,0,0,0,0C457.7,333.1,457.7,334.3,457.1,337z"/>
		</g>
		<g>
			<path class="st322" d="M444.2,312.6c0.9-0.9,2.1-2,7.2-1c5.8,1.4,7.4,8,4.2,7.1c-3.1-0.5-6.1,0.7-7.4,0.7
				c-1.1-0.5-5.6-0.6-5.6-2.4C442.9,315,443.2,313.7,444.2,312.6z"/>
			<path class="st323" d="M450,312.1c5.7,0.9,7.8,7.1,4.6,6.6c-0.1,0-0.3,0-0.4,0c0.5,0,1,0,1.4,0.1c3.3,0.9,1.6-5.7-4.2-7.1
				c-5.2-1.1-6.3,0.1-7.2,1c0,0,0,0,0,0C445,312.2,446.7,311.5,450,312.1z"/>
		</g>
		<g>
			<path class="st322" d="M427.2,347c-2.1-0.5-3.2-1.2-3.8-4.5c-0.4-3.4,3-6.3,5-5.3c2.3,1.3,6.6,1.2,8.1,1.6
				c1.4,0.5,3.3,4.7-0.6,6.8C432.1,347.3,429.5,347.4,427.2,347z"/>
			<path class="st323" d="M424.9,343.5c-0.8-3.3,2.2-6.6,4.4-5.8c0.1,0,0.3,0.1,0.4,0.1c-0.4-0.2-0.9-0.3-1.3-0.5
				c-2-1-5.4,1.9-5,5.3c0.7,3.3,1.8,4,3.8,4.5c0,0,0,0,0.1,0C426.3,346.5,425.5,345.5,424.9,343.5z"/>
		</g>
		<path class="st321" d="M460.1,318.6c-3.5,15.6-17.4,27.2-34,27.2c-2.5,0-4.9-0.3-7.3-0.8c4.5,6,11.7,9.9,19.7,9.9
			c13.6,0,24.6-11,24.6-24.6C463.1,326.1,462,322.1,460.1,318.6z"/>
		<path class="st324" d="M462.9,327.5c-0.2,14.5-12,26.1-26.6,26.1c-3.6,0-7.1-0.7-10.3-2.1c3.6,2.1,7.9,3.4,12.4,3.4
			c13.6,0,24.6-11,24.6-24.6C463.1,329.4,463,328.5,462.9,327.5z"/>
		<g>
			<g>
				<path class="st319" d="M459.8,332.9c-1.1,0.2-7.3,5-7.3,5s-5-2.8-6.2-10.4c-1.4-8.5,3-12.3,3-12.3s7.1,3,8.2,2.9
					c1.8-0.3,3.8,2.8,4.4,6.9C462.5,329.1,461.6,332.6,459.8,332.9z"/>
				<path class="st320" d="M447.8,332.4c1.9,3.9,4.7,5.5,4.7,5.5s6.2-4.8,7.3-5c1.8-0.3,2.7-3.8,2-7.9c0-0.1,0-0.2,0-0.2
					c-1.8,0.9-6.8,2.8-9.6,4.2C449.8,330.3,448.9,331.1,447.8,332.4z"/>
				<path class="st321" d="M459.8,332.9c-1.8,0.3-3.8-2.8-4.4-6.9c-0.7-4.1,0.2-7.6,2-7.9c1.8-0.3,3.8,2.8,4.4,6.9
					C462.5,329.1,461.6,332.6,459.8,332.9z"/>
				<path class="st323" d="M459.8,331.6c-1.4,0.2-2.9-2.3-3.5-5.7c-0.5-3.4,0.1-6.3,1.5-6.5c1.4-0.2,2.9,2.3,3.5,5.7
					C461.8,328.4,461.2,331.3,459.8,331.6z"/>
				<path class="st325" d="M450.8,320.2c-1.6-0.3-2.2-1.2-1.9-2.2c0.3-1.1,1.9-1.7,3.1-0.7c1.2,1,3.2,1.7,2.8,2.7
					C454.6,321,452.1,320.5,450.8,320.2z"/>
				<path class="st326" d="M460.4,322c-0.7-1.7-1.7-2.7-2.6-2.6c-1.1,0.2-1.7,2-1.7,4.4C457.8,322.8,459.3,322.3,460.4,322z"/>
			</g>
			<g>
				<g>
					<path class="st319" d="M443,302.6c0,0.9,3.1,8.7,3.1,8.7s-2.7,3.4-8.6,3.4c-6.6,0-9-3.8-9-3.8s2.9-7.6,2.9-8.4
						c0-1.4,2.6-2.5,5.8-2.5C440.4,300.1,443,301.2,443,302.6z"/>
					<path class="st320" d="M441.4,314.2c3.2-1,4.8-2.9,4.8-2.9s-3.1-7.9-3.1-8.7c0-1.4-2.6-2.5-5.8-2.5c-0.1,0-0.1,0-0.2,0
						c0.5,1.5,1.5,8.1,2.3,10.3C440,312.4,440.5,313.2,441.4,314.2z"/>
					<path class="st321" d="M443,302.6c0,1.4-2.6,2.5-5.8,2.5c-3.2,0-5.8-1.1-5.8-2.5c0-1.4,2.6-2.5,5.8-2.5
						C440.4,300.1,443,301.2,443,302.6z"/>
					<ellipse class="st323" cx="437.2" cy="302.5" rx="4.7" ry="1.9"/>
					<path class="st325" d="M432.5,310.4c-0.4,1.1-1.2,1.5-1.9,1.1c-0.8-0.4-1.1-1.7-0.2-2.5c0.9-0.8,1.4-4.7,2.2-4.4
						C433.3,305.1,432.8,309.5,432.5,310.4z"/>
					<path class="st326" d="M434.8,300.8c-1.4,0.3-2.3,1-2.3,1.6c0,0.8,1.3,1.6,3.1,1.8C435,302.9,434.8,301.7,434.8,300.8z"/>
				</g>
				<g>
					<path class="st319" d="M439.6,346.5c-0.5-0.3-3.7-2.3-3.7-2.3s-0.1-4.4,3.6-6.1c4.2-1.8,7.4,0.8,7.6,1.4c0.3,1-0.6,3.6-0.7,4.1
						c-0.1,1-1.3,1.5-3.3,2.4C441,347,440.3,347,439.6,346.5z"/>
					<path class="st320" d="M437.4,339.8c-1.8,1.5-1.7,3.9-1.5,4.4c0.5,1,3.3,2,3.8,2.3c0.8,0.4,1.7,0.3,3.7-0.6c0,0,0.1,0,0.1-0.1
						c-0.7-0.8-1.9-1.8-3.1-3C439.4,341.9,438.2,340.2,437.4,339.8z"/>
					<path class="st321" d="M439,345.9c-0.6-1.3,0.6-3.1,2.6-3.9c2-0.9,4.1-0.5,4.7,0.7c0.6,1.3-0.6,3.1-2.6,3.9
						C441.7,347.6,439.6,347.2,439,345.9z"/>
					<path class="st323" d="M439.7,345.8c-0.4-1,0.5-2.4,2.2-3.1c1.6-0.7,3.3-0.5,3.8,0.5c0.4,1-0.5,2.4-2.2,3.1
						C441.9,347,440.2,346.8,439.7,345.8z"/>
					<path class="st325" d="M445.3,341.1c-0.1-0.8-0.3-1.6,0.3-1.6c0.6,0,1.4-0.3,1,0.4c-0.3,0.7-0.4,1.8-1,1.8
						C445,341.7,445.4,341.8,445.3,341.1z"/>
					<path class="st326" d="M444.9,345.3c0.7-0.7,1-1.5,0.8-2.2c-0.3-0.8-1.5-1.1-2.7-0.8C443.9,343.5,444.5,344.5,444.9,345.3z"/>
				</g>
				<g>
					<path class="st319" d="M413.3,339.1c0.7,0,5.3,2.1,5.3,2.1s2.8-2.4,2.5-7.4c-0.3-5.5-3.6-7.3-3.6-7.3s-4.1,2.9-4.7,2.9
						c-1.2,0.1-2,2.3-1.9,4.9C411.1,337.1,412.2,339.2,413.3,339.1z"/>
					<path class="st320" d="M420.9,337.1c-0.7,2.7-2.2,4.1-2.2,4.1s-4.6-2.2-5.3-2.1c-1.2,0.1-2.2-2-2.4-4.7c0,0,0-0.1,0-0.1
						c1.3,0.3,4.7,0.8,6.6,1.4C419.3,336.1,420,336.5,420.9,337.1z"/>
					<path class="st321" d="M413.3,339.1c1.2-0.1,2-2.3,1.9-4.9c-0.1-2.7-1.2-4.8-2.4-4.7c-1.2,0.1-2,2.3-1.9,4.9
						C411.1,337.1,412.2,339.2,413.3,339.1z"/>
					<path class="st323" d="M413.2,338.3c0.9,0,1.5-1.9,1.4-4c-0.1-2.2-0.9-3.9-1.8-3.9c-0.9,0-1.5,1.9-1.4,4
						C411.5,336.6,412.3,338.3,413.2,338.3z"/>
					<path class="st325" d="M417.3,329.9c0.9-0.4,1.2-1,0.9-1.7c-0.4-0.6-1.4-0.8-2.1,0c-0.6,0.8-1.8,1.5-1.4,2.1
						C415,330.9,416.5,330.2,417.3,329.9z"/>
					<path class="st326" d="M411.5,332.3c0.2-1.2,0.7-1.9,1.3-2c0.7,0,1.4,1,1.7,2.6C413.3,332.5,412.2,332.3,411.5,332.3z"/>
				</g>
				<g>
					<path class="st327" d="M436.4,356.3c0.1-0.6,2.9-3.7,2.9-3.7s-1.3-2.8-5.3-3.7c-4.5-1-6.7,1.2-6.7,1.2s1.4,3.9,1.3,4.5
						c-0.2,1,1.4,2.1,3.5,2.6C434.2,357.7,436.2,357.3,436.4,356.3z"/>
					<path class="st328" d="M436.5,349.9c2,1.2,2.8,2.7,2.8,2.7s-2.8,3.2-2.9,3.7c-0.2,0.9-2.2,1.3-4.3,0.8c0,0-0.1,0-0.1,0
						c0.6-1,1.7-3.6,2.6-5C435.3,350.9,435.8,350.4,436.5,349.9z"/>
					<path class="st329" d="M436.4,356.3c0.2-0.9-1.4-2.1-3.5-2.6c-2.2-0.5-4.1-0.1-4.3,0.8c-0.2,1,1.4,2.1,3.5,2.6
						C434.2,357.7,436.2,357.3,436.4,356.3z"/>
					<path class="st330" d="M435.6,356.3c0.2-0.7-1.1-1.6-2.9-2.1c-1.8-0.4-3.4-0.2-3.5,0.6c-0.2,0.7,1.1,1.6,2.9,2.1
						C433.9,357.3,435.5,357,435.6,356.3z"/>
					<path class="st331" d="M429.9,351.1c-0.1-0.8-0.6-1.2-1.1-1.1c-0.6,0.1-1,1-0.5,1.6c0.5,0.7,0.8,1.8,1.4,1.6
						C430.2,353.1,430,351.8,429.9,351.1z"/>
					<path class="st332" d="M430.5,356.3c-0.9-0.4-1.4-1-1.3-1.5c0.1-0.6,1.1-0.9,2.4-0.7C431,354.9,430.7,355.7,430.5,356.3z"/>
				</g>
				<g>
					<path class="st333" d="M461.7,344.2c-0.5-0.6-1.5-5.8-1.5-5.8s-3.8-0.8-7.8,2.5c-4.5,3.7-4,7.6-4,7.6s5,1.6,5.4,2.2
						c0.8,0.9,3.2,0.3,5.3-1.5C461.4,347.4,462.5,345.2,461.7,344.2z"/>
					<path class="st334" d="M455.4,339.1c2.7-1.1,4.8-0.7,4.8-0.7s1,5.2,1.5,5.8c0.8,0.9-0.3,3.2-2.5,4.9c0,0-0.1,0.1-0.1,0.1
						c-0.5-1.3-2.2-4.4-3-6.4C455.5,341.1,455.4,340.3,455.4,339.1z"/>
					<path class="st329" d="M461.7,344.2c-0.8-0.9-3.2-0.3-5.3,1.5c-2.2,1.8-3.3,4-2.5,4.9c0.8,0.9,3.2,0.3,5.3-1.5
						C461.4,347.4,462.5,345.2,461.7,344.2z"/>
					<path class="st330" d="M461.1,344.9c-0.6-0.7-2.5-0.1-4.3,1.3c-1.8,1.5-2.7,3.2-2.1,4c0.6,0.7,2.5,0.1,4.3-1.3
						C460.8,347.4,461.7,345.6,461.1,344.9z"/>
					<path class="st331" d="M451.5,346.7c-0.9-0.5-1.6-0.4-1.9,0.3c-0.3,0.7,0.2,1.7,1.3,1.8c1,0,2.4,0.6,2.7-0.1
						C453.8,347.9,452.3,347.1,451.5,346.7z"/>
					<path class="st332" d="M457.2,350c-1.1,0.5-2.1,0.6-2.5,0.2c-0.5-0.6,0-1.8,1.1-3C456.2,348.5,456.7,349.4,457.2,350z"/>
				</g>
				<g>
					<path class="st319" d="M441.5,320.9c0.5,0.7,3.6,5.2,3.6,5.2s-2.5,5.9-8.4,5.9c-6.6,0-9.4-5.4-9.2-6.3c0.2-1.5,2.9-4.4,3.3-5.1
						c0.7-1.2,2.7-1.2,5.9-1.2C439.9,319.5,440.8,319.9,441.5,320.9z"/>
					<path class="st320" d="M440.6,331.1c3.2-1,4.6-4.2,4.6-5c-0.1-1.6-3.2-4.6-3.7-5.3c-0.8-1-2-1.3-5.2-1.3c-0.1,0-0.1,0-0.2,0
						c0.5,1.5,1.6,3.5,2.3,5.7C439,327.2,439.7,330.1,440.6,331.1z"/>
					<path class="st321" d="M441.9,322c0,2.1-2.6,3.7-5.8,3.7c-3.2,0-5.8-1.7-5.8-3.7c0-2.1,2.6-3.7,5.8-3.7
						C439.4,318.3,441.9,320,441.9,322z"/>
					<path class="st323" d="M440.9,321.8c0,1.6-2.1,2.8-4.7,2.8c-2.6,0-4.7-1.3-4.7-2.8c0-1.6,2.1-2.8,4.7-2.8
						C438.8,319,440.9,320.2,440.9,321.8z"/>
					<path class="st325" d="M430.7,324.8c-0.4,1.1-0.5,2.3-1.3,1.9c-0.8-0.4-2.1-0.4-1.1-1.2c0.9-0.8,1.7-2.2,2.4-1.8
						C431.5,324.1,431.1,323.9,430.7,324.8z"/>
					<path class="st326" d="M433.7,319.4c-1.4,0.5-2.3,1.4-2.3,2.4c0,1.2,1.3,2.3,3.1,2.7C434,322.5,433.8,320.7,433.7,319.4z"/>
				</g>
			</g>
		</g>
		<path class="st325" d="M430.5,313.2c1.6,1.7-1.2,4.8-4.8,8.3c-3.6,3.5-6.4,6.1-8.1,4.5c-1.6-1.7-1.4-6.9,2.1-10.5
			C423.3,312,428.8,311.6,430.5,313.2z"/>
	</g>
	<g>
		<g>
			<path class="st335" d="M386.9,195.7c-1.9,0.7-12.6-3-23.8-7.9c-11.2-4.9-21.2-10.4-21.9-12.2c-0.9-0.8,1.3-5.6,7.9-7.8
				c6.1-2.5,16.6-2.6,22.7,0.4c6.3,2.5,13.3,10.2,15.6,16.4C390.1,191,388.1,195.8,386.9,195.7z"/>
			<path class="st336" d="M371.7,168.2c-0.1-0.1-0.3-0.1-0.4-0.2c2.9,3.9,5.4,10.3,5.9,15.2c0.6,5-0.8,8.5-1.8,9.5
				c5.8,2.1,10.5,3.4,11.5,2.9c1.2,0.2,3.2-4.7,0.4-11.1C385.1,178.4,378.1,170.7,371.7,168.2z"/>
			<path class="st337" d="M377.7,171.9c4.5,4.2,8.1,11,8.7,15.7c0.8,4.9-1.4,7.6-2.1,7.8c-0.1,0-0.2,0.1-0.4,0.1
				c1.5,0.4,2.6,0.5,3,0.3c0.7-0.1,2.8-3.2,1.4-8.2C387.3,182.6,382.8,175.8,377.7,171.9z"/>
			<g>
				<path class="st338" d="M356.9,169.6c-6.5,1.6-12.8,6.3-12.2,6.4c1.2,0.7,2.3,1.5,3.5,2.2c0.1,0-0.2-0.6,2.2-3.1
					c2.5-3,12.7-6.5,13.6-6.7C363.5,168.4,360.7,168.5,356.9,169.6z"/>
				<path class="st338" d="M364,168.4C364,168.4,364.1,168.4,364,168.4C364.1,168.3,364,168.4,364,168.4z"/>
			</g>
			<g>
				<path class="st338" d="M380.9,175.4c0,0,2.2,2.7,4.1,6.6c3.3,5.9,3.1,13.1,2.6,12.8c-0.9-0.3-1.8-0.5-2.8-0.8
					c-0.1-0.1,0.9-0.2,1.1-3.5C386.8,187.4,381.1,175.2,380.9,175.4z"/>
			</g>
			<path class="st339" d="M368.3,175.5c6.6,2.9,12.9,4.3,16.4,4c-3.4-4.9-7.9-9.1-12.9-11.3c-5.1-2.3-11.3-2.8-17.3-1.8
				C356.6,169.1,361.8,172.7,368.3,175.5z"/>
		</g>
		<g>
			<path class="st340" d="M376.2,171c2,0.3,0.6-2.8-3.8-4.4c-4.2-1.9-7.1-1.1-5.7,0.1c0.7,0.8,1.6,0.5,4.8,1.9
				C374.8,170.1,375,171,376.2,171z"/>
			<path class="st341" d="M372.3,167c-3.4-1.5-6.2-1.2-5.4-0.2c0,0-0.1-0.1-0.1-0.1c-1.4-1.2,1.5-2,5.7-0.1c4.4,1.6,5.8,4.6,3.8,4.4
				c-0.1,0-0.1,0-0.2,0C377.5,170.8,375.7,168.4,372.3,167z"/>
			<path class="st340" d="M374.1,169.3c0.3-0.2-0.3-1.3-1.9-2c-1.5-0.7-2.8-0.4-2.7-0.1c0,0.3,1,0.3,2.3,0.9
				C373.2,168.7,373.8,169.5,374.1,169.3z"/>
			<path class="st342" d="M371.7,168.5c2.5,1.1,3.7,2.2,4.4,2.5c-1-0.1-1.4-1-4.4-2.4c-3-1.3-4.1-1.2-4.7-1.8
				C367.5,167.1,369.1,167.4,371.7,168.5z"/>
		</g>
		<g>
			<path class="st343" d="M393.1,202.8c-1.2,2.8-20.1,8.3-37.1,0.8c-17-7.5-25.7-25.1-24.5-27.9c1.2-2.8,17.4-2.2,34.4,5.3
				C383,188.6,394.4,200.1,393.1,202.8z"/>
			<path class="st344" d="M349.4,189.2c-3.9-2-7.3-3.9-10-5.7c0,0,2.2,6,7.8,14c2.2,2.2,4.8,4.1,7.6,5.5
				C350.7,193.3,350.5,192.7,349.4,189.2z"/>
			<path class="st345" d="M385.6,192.8c-4.4,4.2-9.1,11-21.4,9.4c-3.8-0.5-10.8-2.1-17.2-4c2.7,2.1,5.8,4,9.1,5.5
				c17,7.5,35.9,2,37.1-0.8C393.8,201.4,390.9,197.3,385.6,192.8z"/>
			<path class="st346" d="M349.7,200.2c2,1.3,4.1,2.5,6.4,3.5c16.7,7.3,35.2,2.1,37-0.7c0.1-0.4-0.1-0.8-0.4-1.3
				c-4.9,4.2-22.9,7.6-39.6,0.3C351.9,201.4,350.8,200.8,349.7,200.2z"/>
			<path class="st347" d="M393.1,202.8c-1.2,2.8-16-1-33-8.5c-17-7.5-29.8-15.8-28.6-18.6c1.2-2.8,17.4-2.2,34.4,5.3
				C383,188.6,394.4,200.1,393.1,202.8z"/>
			<path class="st348" d="M373.4,199.6C373.4,199.6,373.4,199.6,373.4,199.6c4,1.4,7.7,2.5,10.7,3.2c0.3-2.5,0.4-7.7-0.1-11.3
				c-3.5-2.8-8-5.4-13.1-8C372.2,187.1,374.4,194.6,373.4,199.6z"/>
			<path class="st348" d="M339.3,183.5C339.4,183.5,339.4,183.5,339.3,183.5c0.4,0.2,0.7,0.4,1,0.7c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1
				c0.3,0.2,0.7,0.4,1,0.6c0,0,0.1,0,0.1,0.1c0.3,0.2,0.7,0.4,1,0.6c0,0,0.1,0.1,0.1,0.1c0.4,0.2,0.7,0.4,1.1,0.6
				c0.1,0,0.1,0.1,0.2,0.1c0.4,0.2,0.7,0.4,1.1,0.6c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.4,0.2,0.8,0.4,1.2,0.6c0,0,0,0,0.1,0
				c0,0,0,0,0.1,0c0.4,0.2,0.8,0.4,1.2,0.7c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0c0.4,0.2,0.9,0.5,1.3,0.7c0,0,0.1,0,0.1,0
				c3.5-3.9,8.8-8.1,12-10c-0.6-0.2-1.3-0.5-1.9-0.7c-4.4-1.5-8.6-2.6-12.4-3.3C344.2,177.5,340.2,181.1,339.3,183.5z"/>
			<path class="st348" d="M354.9,177.1c-0.9-0.3-1.8-0.5-2.7-0.7c-2.5,1.9-7,5.6-9.5,9.2c0.1,0.1,0.3,0.2,0.4,0.2
				c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.1,0.4,0.2,0.6,0.3c0.1,0.1,0.3,0.2,0.4,0.2c0.5,0.3,1,0.6,1.6,0.9
				C348.8,183.6,352.9,179.1,354.9,177.1z"/>
			<g>
				<g>
					<path class="st349" d="M393.1,202.8c-0.1,0.3-0.5,0.6-0.8,0.7c-0.3,0.1-0.7,0.2-1.1,0.2c-0.7,0.1-1.5,0-2.2,0
						c-1.4-0.1-2.9-0.4-4.3-0.7c-2.8-0.6-5.6-1.4-8.4-2.3c-5.5-1.8-11-3.9-16.3-6.2c-5.3-2.4-10.5-4.9-15.6-7.8
						c-2.5-1.4-5-3-7.4-4.6c-1.2-0.8-2.3-1.7-3.4-2.7c-0.5-0.5-1-1-1.5-1.6c-0.2-0.3-0.4-0.6-0.6-0.9c-0.1-0.3-0.2-0.7-0.1-1.1
						c-0.1,0.3-0.1,0.7,0.1,1.1c0.1,0.3,0.3,0.6,0.6,0.9c0.4,0.6,1,1.1,1.5,1.6c1.1,1,2.3,1.8,3.5,2.6c2.4,1.6,4.9,3.1,7.4,4.5
						c5.1,2.8,10.3,5.4,15.6,7.7c5.3,2.3,10.7,4.5,16.2,6.3c2.8,0.9,5.5,1.7,8.4,2.4c1.4,0.3,2.8,0.6,4.3,0.8
						c0.7,0.1,1.4,0.1,2.2,0.1c0.4,0,0.7-0.1,1.1-0.2C392.7,203.4,393,203.2,393.1,202.8z"/>
				</g>
			</g>
			<path class="st350" d="M387.3,194.8c-0.6,1.4-11.4-2-24-7.5c-12.6-5.6-22.4-11.2-21.8-12.6c0.6-1.4,16.3,3.6,24.2,7.1
				C373.4,185.2,387.9,193.5,387.3,194.8z"/>
		</g>
		<g>
			<path class="st351" d="M365.2,192.8c-0.4,0.8-1.8,0.9-3.2,0.3c-1.4-0.6-2.3-1.8-2-2.6c0.4-0.8,1.8-0.9,3.2-0.3
				C364.7,190.9,365.5,192,365.2,192.8z"/>
			<path class="st352" d="M364.8,192.7c-0.3,0.7-1.6,0.8-2.8,0.3c-1.2-0.5-2-1.6-1.7-2.3c0.3-0.7,1.6-0.8,2.8-0.3
				C364.4,191,365.1,192,364.8,192.7z"/>
			<path class="st353" d="M363.4,191.6c-0.1,0.3-0.6-0.1-1.3-0.4c-0.7-0.3-1.3-0.3-1.1-0.7c0.1-0.3,0.8-0.3,1.4,0
				C363.1,190.8,363.5,191.3,363.4,191.6z"/>
			<path class="st354" d="M365.2,192.1c0,0.2,0,0.4-0.1,0.6c-0.4,0.8-1.8,0.9-3.2,0.3c-1-0.5-1.8-1.2-2-1.9c0.1,0.7,0.9,1.5,2,2
				c1.4,0.6,2.9,0.5,3.2-0.3C365.3,192.6,365.3,192.4,365.2,192.1z"/>
			<path class="st355" d="M364.8,191.8c0,0.1,0,0.1-0.1,0.2c-0.3,0.7-1.6,0.8-2.9,0.3c-0.7-0.3-1.3-0.8-1.6-1.3
				c0,0.7,0.7,1.4,1.8,1.9c1.2,0.5,2.5,0.4,2.8-0.3C365,192.4,364.9,192.1,364.8,191.8z"/>
		</g>
		<g>
			<path class="st351" d="M380.5,198c-0.3,0.8-1.7,1.1-3.2,0.6c-1.5-0.5-2.4-1.6-2.1-2.4c0.3-0.8,1.7-1.1,3.2-0.6
				C379.8,196,380.8,197.1,380.5,198z"/>
			<path class="st352" d="M380.1,197.8c-0.3,0.7-1.5,0.9-2.8,0.5c-1.3-0.5-2.1-1.4-1.9-2.1c0.3-0.7,1.5-0.9,2.8-0.5
				C379.5,196.2,380.4,197.1,380.1,197.8z"/>
			<path class="st353" d="M378.6,196.9c-0.1,0.3-0.6-0.1-1.3-0.3c-0.7-0.2-1.3-0.2-1.2-0.6c0.1-0.3,0.8-0.4,1.4-0.1
				C378.2,196.1,378.7,196.6,378.6,196.9z"/>
			<path class="st354" d="M380.4,197.3c0,0.2,0,0.4,0,0.6c-0.3,0.8-1.7,1.1-3.2,0.6c-1.1-0.4-1.9-1.1-2.1-1.7c0.2,0.7,1,1.5,2.2,1.9
				c1.5,0.5,2.9,0.3,3.2-0.6C380.5,197.7,380.5,197.5,380.4,197.3z"/>
			<path class="st355" d="M380,197c0,0.1,0,0.1,0,0.2c-0.3,0.7-1.5,1-2.9,0.5c-0.7-0.3-1.3-0.7-1.7-1.1c0.1,0.7,0.8,1.4,1.9,1.8
				c1.3,0.5,2.5,0.2,2.8-0.5C380.2,197.6,380.2,197.3,380,197z"/>
		</g>
		<g>
			<path class="st351" d="M391.7,200.3c-0.2,0.8-1.5,1.2-2.9,0.8c-1.4-0.4-2.3-1.4-2.1-2.2c0.2-0.8,1.5-1.2,2.9-0.8
				C391,198.4,391.9,199.4,391.7,200.3z"/>
			<path class="st352" d="M391.4,200.2c-0.2,0.7-1.3,1.1-2.5,0.7c-1.2-0.3-2-1.2-1.8-1.9c0.2-0.7,1.3-1.1,2.5-0.7
				C390.8,198.6,391.6,199.4,391.4,200.2z"/>
			<path class="st353" d="M389.9,199.4c-0.1,0.3-0.6,0-1.2-0.2c-0.6-0.2-1.2-0.1-1.1-0.5c0.1-0.3,0.7-0.5,1.3-0.3
				C389.5,198.6,390,199,389.9,199.4z"/>
			<path class="st354" d="M391.6,199.6c0.1,0.2,0.1,0.4,0,0.6c-0.2,0.8-1.5,1.2-2.9,0.8c-1-0.3-1.8-0.9-2-1.5c0.2,0.7,1,1.3,2.1,1.6
				c1.4,0.4,2.7,0,2.9-0.8C391.8,200,391.7,199.8,391.6,199.6z"/>
			<path class="st355" d="M391.2,199.3c0,0.1,0,0.1,0,0.2c-0.2,0.8-1.4,1.1-2.6,0.8c-0.7-0.2-1.3-0.6-1.6-1c0.1,0.6,0.8,1.3,1.8,1.6
				c1.2,0.3,2.3,0,2.5-0.7C391.4,199.9,391.4,199.6,391.2,199.3z"/>
		</g>
		<g>
			<path class="st351" d="M351.1,185.6c-0.4,0.8-1.9,0.8-3.2,0c-1.4-0.8-2.1-2-1.7-2.8c0.4-0.8,1.9-0.8,3.2,0
				C350.8,183.6,351.5,184.8,351.1,185.6z"/>
			<path class="st352" d="M350.8,185.4c-0.4,0.7-1.6,0.7-2.8,0c-1.2-0.7-1.9-1.7-1.5-2.4c0.4-0.7,1.6-0.7,2.8,0
				C350.5,183.7,351.2,184.7,350.8,185.4z"/>
			<path class="st353" d="M349.4,184.2c-0.2,0.3-0.6-0.2-1.2-0.5c-0.6-0.3-1.2-0.5-1.1-0.8c0.2-0.3,0.8-0.3,1.4,0.1
				C349.2,183.4,349.6,183.9,349.4,184.2z"/>
			<path class="st354" d="M351.2,184.9c0,0.2,0,0.4-0.1,0.6c-0.4,0.8-1.9,0.8-3.2,0c-1-0.6-1.7-1.4-1.8-2.1c0.1,0.7,0.8,1.6,1.8,2.2
				c1.4,0.8,2.8,0.8,3.2,0C351.2,185.4,351.2,185.1,351.2,184.9z"/>
			<path class="st355" d="M350.8,184.6c0,0.1,0,0.1-0.1,0.2c-0.4,0.7-1.7,0.7-2.9,0c-0.7-0.4-1.2-0.9-1.5-1.4c0,0.7,0.6,1.5,1.6,2.1
				c1.2,0.7,2.5,0.7,2.8,0C350.9,185.2,350.9,184.9,350.8,184.6z"/>
		</g>
		<g>
			<path class="st351" d="M340.8,179c-0.5,0.7-1.9,0.6-3-0.3c-1.1-0.9-1.6-2.1-1-2.8c0.5-0.7,1.9-0.6,3,0.3
				C340.9,177,341.3,178.3,340.8,179z"/>
			<path class="st352" d="M340.6,178.8c-0.5,0.6-1.6,0.5-2.6-0.3c-1-0.7-1.4-1.8-0.9-2.4c0.5-0.6,1.6-0.5,2.6,0.3
				C340.6,177.1,341,178.2,340.6,178.8z"/>
			<path class="st353" d="M339.5,177.5c-0.2,0.3-0.5-0.2-1-0.6c-0.5-0.4-1-0.6-0.8-0.8c0.2-0.3,0.8-0.2,1.3,0.2
				C339.5,176.7,339.7,177.2,339.5,177.5z"/>
			<path class="st354" d="M341,178.3c0,0.2-0.1,0.4-0.2,0.5c-0.5,0.7-1.9,0.6-3-0.3c-0.8-0.6-1.3-1.5-1.3-2.1
				c-0.1,0.7,0.4,1.6,1.2,2.3c1.1,0.9,2.4,1,3,0.3C341,178.8,341,178.6,341,178.3z"/>
			<path class="st355" d="M340.7,178c0,0.1-0.1,0.1-0.1,0.2c-0.5,0.6-1.7,0.5-2.7-0.3c-0.6-0.4-0.9-1-1.1-1.5
				c-0.2,0.6,0.2,1.5,1.1,2.1c1,0.7,2.1,0.9,2.6,0.3C340.7,178.6,340.8,178.3,340.7,178z"/>
		</g>
		<g>
			<g>
				<path class="st356" d="M355.5,200.9c0.3,0.7,1,1.6,1.9,2c0.9,0.4,2,0.3,2.7,0c0.1,0,0.1-0.1,0.2-0.1c0.7-0.2,0-1.5-1.8-2.4
					c-1.9-0.7-3.3-0.4-3,0.3C355.5,200.7,355.5,200.8,355.5,200.9z"/>
				<path class="st357" d="M360.2,202.8c-0.7,0.3-1.9,0.3-2.8-0.1c-0.9-0.4-1.7-1.3-2-2c-0.3-0.6,1.1-1,3-0.3
					C360.3,201.2,360.9,202.5,360.2,202.8z"/>
			</g>
			<g>
				<g>
					<path class="st358" d="M348.1,218.2c-0.2,0.4,0.6,1.2,1.8,1.7c1.2,0.5,2.3,0.6,2.4,0.2l0-0.1c0.2-0.4-0.6-1.2-1.8-1.7
						c-1.2-0.5-2.3-0.6-2.4-0.2L348.1,218.2z"/>
					<path class="st359" d="M352.4,219.9c-0.2,0.4-1.3,0.3-2.4-0.2c-1.2-0.5-2-1.3-1.8-1.7c0.2-0.4,1.3-0.3,2.4,0.2
						C351.8,218.7,352.6,219.5,352.4,219.9z"/>
				</g>
				<g>
					<path class="st351" d="M351.1,218.7c-0.1,0.3-0.5,0.5-0.9,0.3l0,0c-0.3-0.1-0.5-0.5-0.3-0.9l3-6.7c0.1-0.3,0.5-0.5,0.9-0.3l0,0
						c0.3,0.1,0.5,0.5,0.3,0.9L351.1,218.7z"/>
					<path class="st360" d="M353.9,211.2l-3.1,7c-0.2,0.4-0.6,0.5-0.9,0.4c0,0-0.1,0-0.1-0.1c0.1,0.2,0.2,0.4,0.4,0.4
						c0.3,0.1,0.7,0,0.9-0.3l3-6.7C354.1,211.7,354.1,211.4,353.9,211.2z"/>
					<path class="st359" d="M350.6,217.3c0,0.1-0.1,0.1-0.1,0.1l0,0c-0.1,0-0.1-0.1-0.1-0.1l1.4-3.3c0-0.1,0.1-0.1,0.1-0.1l0,0
						c0.1,0,0.1,0.1,0.1,0.1L350.6,217.3z"/>
				</g>
				<g>
					<path class="st351" d="M354.1,212.5c-0.2,0.5-0.8,0.7-1.3,0.5l0,0c-0.5-0.2-0.7-0.8-0.5-1.3l4.3-9.8c0.2-0.5,0.8-0.7,1.3-0.5
						l0,0c0.5,0.2,0.7,0.8,0.5,1.3L354.1,212.5z"/>
					<path class="st360" d="M358.3,201.6l-4.5,10.3c-0.2,0.5-0.8,0.8-1.3,0.5c-0.1,0-0.1-0.1-0.2-0.1c0.1,0.3,0.3,0.5,0.5,0.6
						c0.5,0.2,1.1,0,1.3-0.5l4.3-9.8C358.6,202.3,358.5,201.9,358.3,201.6z"/>
					<path class="st359" d="M353.5,210.5c0,0.1-0.1,0.1-0.2,0.1l0,0c-0.1,0-0.1-0.1-0.1-0.2l2.1-4.8c0-0.1,0.1-0.1,0.2-0.1l0,0
						c0.1,0,0.1,0.1,0.1,0.2L353.5,210.5z"/>
				</g>
			</g>
		</g>
		<g>
			<g>
				<path class="st356" d="M340.7,190.2c0,0.8,0.4,1.8,1.1,2.5c0.7,0.7,1.8,1,2.5,1c0.1,0,0.1,0,0.2,0c0.7,0,0.5-1.4-0.9-2.9
					c-1.5-1.3-3-1.4-2.9-0.7C340.8,190.1,340.7,190.1,340.7,190.2z"/>
				<path class="st357" d="M344.5,193.6c-0.8,0-1.9-0.3-2.6-1c-0.7-0.7-1.2-1.8-1.2-2.6c-0.1-0.7,1.4-0.6,2.9,0.7
					C345.1,192.2,345.3,193.6,344.5,193.6z"/>
			</g>
			<g>
				<g>
					<path class="st358" d="M327.9,204c-0.3,0.3,0.2,1.3,1.1,2.2c0.9,0.9,1.9,1.3,2.2,1l0.1-0.1c0.3-0.3-0.2-1.3-1.1-2.2
						C329.3,204,328.3,203.6,327.9,204L327.9,204z"/>
					<path class="st359" d="M331.4,207.1c-0.3,0.3-1.3-0.1-2.2-1c-0.9-0.9-1.4-1.9-1.1-2.2c0.3-0.3,1.3,0.1,2.2,1
						C331.2,205.8,331.7,206.7,331.4,207.1z"/>
				</g>
				<g>
					<path class="st351" d="M330.5,205.5c-0.3,0.3-0.7,0.3-0.9,0l0,0c-0.3-0.3-0.3-0.7,0-0.9l5.1-5.3c0.3-0.3,0.7-0.3,0.9,0l0,0
						c0.3,0.3,0.3,0.7,0,0.9L330.5,205.5z"/>
					<path class="st360" d="M335.7,199.4l-5.3,5.6c-0.3,0.3-0.7,0.3-1,0c0,0-0.1-0.1-0.1-0.1c0,0.2,0.1,0.4,0.2,0.5
						c0.3,0.3,0.7,0.2,0.9,0l5.1-5.3C335.8,199.9,335.8,199.7,335.7,199.4z"/>
					<path class="st359" d="M330.6,204.1c0,0-0.1,0-0.2,0l0,0c0,0,0-0.1,0-0.2l2.5-2.6c0,0,0.1,0,0.2,0l0,0c0,0,0,0.1,0,0.2
						L330.6,204.1z"/>
				</g>
				<g>
					<path class="st351" d="M335.5,200.7c-0.4,0.4-1,0.4-1.4,0l0,0c-0.4-0.4-0.4-1,0-1.4l7.4-7.8c0.4-0.4,1-0.4,1.4,0l0,0
						c0.4,0.4,0.4,1,0,1.4L335.5,200.7z"/>
					<path class="st360" d="M343.1,191.9l-7.7,8.1c-0.4,0.4-1,0.4-1.4,0.1c0,0-0.1-0.1-0.1-0.1c0,0.3,0.1,0.6,0.3,0.8
						c0.4,0.4,1,0.4,1.4,0l7.4-7.8C343.1,192.6,343.2,192.2,343.1,191.9z"/>
					<path class="st359" d="M335.6,198.6c-0.1,0.1-0.2,0.1-0.2,0l0,0c-0.1-0.1-0.1-0.2,0-0.2l3.6-3.8c0.1-0.1,0.2-0.1,0.2,0l0,0
						c0.1,0.1,0.1,0.2,0,0.2L335.6,198.6z"/>
				</g>
			</g>
		</g>
		<g>
			<g>
				<path class="st356" d="M377,206.1c-0.6,0.5-1.6,0.9-2.6,0.9c-1-0.1-1.9-0.6-2.4-1.2c0-0.1-0.1-0.1-0.1-0.2
					c-0.5-0.5,0.7-1.4,2.7-1.3c2,0.2,3.1,1.2,2.5,1.6C377.1,206,377,206.1,377,206.1z"/>
				<path class="st357" d="M371.9,205.6c0.5,0.6,1.5,1.2,2.5,1.2c1,0.1,2.1-0.3,2.7-0.9c0.6-0.4-0.5-1.4-2.5-1.6
					C372.6,204.3,371.4,205.1,371.9,205.6z"/>
			</g>
			<g>
				<g>
					<path class="st358" d="M375.5,224.9c0,0.4-1.1,0.7-2.4,0.7c-1.3-0.1-2.3-0.5-2.2-1l0-0.1c0-0.4,1.1-0.7,2.4-0.7
						c1.3,0.1,2.3,0.5,2.2,1L375.5,224.9z"/>
					<path class="st359" d="M370.9,224.5c0,0.4,1,0.9,2.2,1c1.3,0.1,2.3-0.2,2.4-0.7c0-0.4-1-0.9-2.2-1
						C372,223.7,371,224,370.9,224.5z"/>
				</g>
				<g>
					<path class="st351" d="M372.7,224c0,0.4,0.2,0.7,0.6,0.7l0,0c0.4,0,0.7-0.3,0.7-0.6l0.5-7.3c0-0.4-0.3-0.7-0.6-0.7l0,0
						c-0.4,0-0.7,0.3-0.7,0.6L372.7,224z"/>
					<path class="st360" d="M373.6,216.1l-0.5,7.7c0,0.4,0.2,0.7,0.6,0.7c0,0,0.1,0,0.1,0c-0.1,0.1-0.3,0.2-0.5,0.2
						c-0.4,0-0.6-0.3-0.6-0.7l0.5-7.3C373.2,216.4,373.4,216.2,373.6,216.1z"/>
					<path class="st359" d="M373.7,223c0,0.1,0,0.1,0.1,0.1l0,0c0.1,0,0.1,0,0.1-0.1l0.2-3.6c0-0.1,0-0.1-0.1-0.1l0,0
						c-0.1,0-0.1,0-0.1,0.1L373.7,223z"/>
				</g>
				<g>
					<path class="st351" d="M372.8,217.1c0,0.5,0.4,1,0.9,1l0,0c0.5,0,1-0.4,1-0.9l0.7-10.7c0-0.5-0.4-1-0.9-1l0,0
						c-0.5,0-1,0.4-1,0.9L372.8,217.1z"/>
					<path class="st360" d="M374.2,205.5l-0.8,11.2c0,0.6,0.4,1,0.9,1.1c0.1,0,0.1,0,0.2,0c-0.2,0.2-0.5,0.3-0.8,0.3
						c-0.5,0-0.9-0.5-0.9-1l0.7-10.7C373.6,206,373.9,205.7,374.2,205.5z"/>
					<path class="st359" d="M374.3,215.6c0,0.1,0.1,0.2,0.2,0.2l0,0c0.1,0,0.2-0.1,0.2-0.1l0.4-5.2c0-0.1-0.1-0.2-0.1-0.2l0,0
						c-0.1,0-0.2,0.1-0.2,0.1L374.3,215.6z"/>
				</g>
			</g>
		</g>
		<g>
			<defs>
				<path id="SVGID_179_" d="M386.9,195c-1.9,0.7-12.4-2.8-23.6-7.7c-11.2-4.9-21-10.3-21.8-12.2c-0.9-0.8,1-5.1,7.5-7.4
					c6.1-2.5,16.6-2.6,22.7,0.4c6.3,2.5,13.3,10.2,15.6,16.4C390.1,191,388.1,195.1,386.9,195z"/>
			</defs>
			<clipPath id="SVGID_180_">
				<use style={{overflow:"visible"}}/>
			</clipPath>
			<g class="st361">
				<g>
					<g>
						<path class="st362" d="M369.5,190.7c-0.2,0.3-0.5-0.1-0.7-0.8c-0.3-0.7-0.3-1.1,0.1-1.1c0.4,0,0.8,0.3,1,0.7
							C369.9,189.9,369.8,190.4,369.5,190.7z"/>
					</g>
					<g>
						<path class="st362" d="M368.9,189c0.3,0,0.7,0.2,0.8,0.6C369.6,189.3,369.2,189,368.9,189C368.9,189,368.8,189,368.9,189
							C368.8,189,368.8,189,368.9,189z"/>
					</g>
					<path class="st362" d="M369.8,189.9c-0.1,0.1-0.1,0.3-0.2,0.4c-0.2,0.2-0.5-0.1-0.8-0.8c-0.1-0.2-0.1-0.4-0.2-0.5
						c0,0.2,0,0.5,0.2,0.9c0.3,0.7,0.5,1,0.7,0.8C369.7,190.5,369.8,190.2,369.8,189.9z"/>
				</g>
				<g>
					<g>
						<g>
							<path class="st362" d="M356.9,185.4c0.2-0.3-0.2-0.5-0.9-0.6c-0.7-0.1-1.1-0.1-1,0.3c0.1,0.3,0.5,0.7,0.9,0.8
								C356.2,185.9,356.6,185.6,356.9,185.4z"/>
						</g>
						<g>
							<path class="st362" d="M355.1,185c0.1,0.3,0.4,0.6,0.7,0.7C355.5,185.7,355.2,185.4,355.1,185C355.1,185,355.1,185,355.1,185
								C355.1,185,355.1,185,355.1,185z"/>
						</g>
						<path class="st362" d="M356.2,185.8c0.1-0.1,0.3-0.2,0.3-0.3c0.2-0.3-0.2-0.5-0.9-0.7c-0.2,0-0.4-0.1-0.5-0.1
							c0.2-0.1,0.5-0.1,0.9,0c0.7,0.1,1.1,0.3,0.9,0.6C356.7,185.5,356.4,185.7,356.2,185.8z"/>
					</g>
					<g>
						<path class="st363" d="M362.6,185.2c-1.9-0.3-3.8,0-5.7,0c-1.2,0.2-2.2-0.4-2.6-1.6c-0.3-0.8-0.5-3.3,0-4.2
							c0.2-0.4,0.4-0.7,0.6-0.3c0.2,0.3,0,0.8-0.1,1.1c-0.3,1.4-0.3,4.6,1.9,4.3c1.9,0.1,4.2-0.8,6-1.3
							C364.6,182.6,364.6,185.8,362.6,185.2C362.6,185.2,362.6,185.2,362.6,185.2z"/>
					</g>
					<g>
						<g>
							<path class="st363" d="M354.8,179.5c-0.1,0.5-0.2,1.1-0.1,1.8c0-0.4,0.1-0.8,0.1-1.2c0.1-0.3,0.2-0.7,0.1-1
								C354.9,179.3,354.9,179.4,354.8,179.5z"/>
						</g>
						<g>
							<path class="st363" d="M363.2,184.9c-2.1-0.4-4.1,0-6.1,0c-1.3,0.2-2.4-0.5-2.8-1.7c-0.1-0.3-0.2-0.7-0.2-1.2
								c0,0.7,0.1,1.3,0.3,1.7c0.4,1.2,1.4,1.8,2.6,1.6c1.9,0,3.8-0.4,5.7,0l0,0l0,0c0.6,0.2,1,0,1.2-0.2
								C363.6,185,363.4,184.9,363.2,184.9z M362.6,185.2C362.6,185.2,362.6,185.2,362.6,185.2L362.6,185.2z"/>
						</g>
						<g>
							<path class="st363" d="M363.2,184.9C363.2,184.9,363.2,184.9,363.2,184.9C363.2,184.9,363.2,184.9,363.2,184.9z"/>
						</g>
						<g>
							<path class="st363" d="M363.2,184.9C363.2,184.9,363.2,184.9,363.2,184.9L363.2,184.9z"/>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path class="st364" d="M354,177.6c0.5-0.2,1-0.1,1.2,0.5c0.2,0.6,0,1.5-0.5,1.7c-0.5,0.2-1.2-0.5-1.4-1.1
									C353.2,178.1,353.5,177.8,354,177.6z"/>
							</g>
							<g>
								<path class="st364" d="M354.6,177.7c-0.1-0.7-0.3-2-0.2-2c0.1,0,0.6,1.2,0.7,1.9c0.1,0.7,0.1,1.3,0,1.3
									C354.9,179,354.7,178.4,354.6,177.7z"/>
							</g>
							<g>
								<path class="st364" d="M355.2,178.2c0.3-0.6,0.4-0.8,0.6-0.7c0.1,0.1,0.2,0.3-0.1,0.9c-0.3,0.6-0.6,1.1-0.7,1.1
									C354.8,179.4,354.9,178.8,355.2,178.2z"/>
							</g>
							<g>
								<path class="st364" d="M354,177.7c-0.1-0.7-0.3-1.8-0.2-1.8c0.1,0,0.5,1,0.6,1.7c0.1,0.7,0.1,1.3,0,1.3
									C354.3,178.9,354.1,178.4,354,177.7z"/>
							</g>
							<g>
								<path class="st364" d="M353.6,178.2c-0.2-0.7-0.4-1.9-0.2-1.9c0.1,0,0.5,1.1,0.7,1.8c0.2,0.7,0.3,1.3,0.1,1.3
									C354,179.4,353.8,178.8,353.6,178.2z"/>
							</g>
							<g>
								<path class="st364" d="M353.2,178.4c-0.3-0.6-0.6-1.4-0.5-1.5c0.1-0.1,0.6,0.7,0.9,1.3c0.3,0.6,0.4,1.1,0.3,1.1
									C353.9,179.4,353.5,179,353.2,178.4z"/>
							</g>
							<g>
								<path class="st364" d="M354.6,177c0-0.3-0.2-0.8-0.2-0.8c0.1,0,0.3,0.5,0.3,0.7c0,0.3,0,0.5,0,0.5
									C354.7,177.5,354.7,177.2,354.6,177z"/>
							</g>
							<g>
								<path class="st364" d="M354.1,177.2c0-0.3-0.2-0.8-0.2-0.8c0.1,0,0.2,0.5,0.3,0.8c0,0.3,0,0.5,0,0.5
									C354.1,177.6,354.1,177.4,354.1,177.2z"/>
							</g>
							<g>
								<path class="st364" d="M353.6,177.3c0-0.2-0.2-0.7-0.2-0.7c0.1,0,0.2,0.4,0.3,0.7c0,0.2,0,0.5,0,0.5
									C353.6,177.7,353.6,177.5,353.6,177.3z"/>
							</g>
							<g>
								<path class="st364" d="M353.1,177.8c-0.1-0.2-0.3-0.5-0.2-0.5c0,0,0.3,0.3,0.3,0.5c0.1,0.2,0.1,0.4,0.1,0.4
									C353.2,178.2,353.2,178,353.1,177.8z"/>
							</g>
							<g>
								<path class="st364" d="M355.5,178.2c0.1-0.2,0.2-0.6,0.2-0.6C355.8,177.6,355.7,178,355.5,178.2c0,0.2-0.1,0.4-0.1,0.3
									C355.3,178.5,355.4,178.4,355.5,178.2z"/>
							</g>
							<g class="st365">
								<path class="st364" d="M354.2,177.9c0.3-0.1,0.6,0,0.7,0.3c0.1,0.3,0,0.9-0.3,0.9c-0.3,0.1-0.7-0.3-0.8-0.6
									C353.7,178.2,353.9,178,354.2,177.9z"/>
							</g>
						</g>
						<g>
							<path class="st364" d="M354.9,183.1c0.1-1.6,0.2-4.2-0.3-4.2c-0.8-0.1-0.8,2.2-0.7,4C354.3,183,354.6,183,354.9,183.1z"/>
						</g>
						<g>
							<g>
								<path class="st366" d="M353.2,182.9c-0.1-0.1,0.4-0.5,1.2-0.4c0.8,0.1,1.4,0.6,1.3,0.7c-0.1,0.1-0.7-0.1-1.3-0.1
									C353.7,183,353.2,183.1,353.2,182.9z"/>
							</g>
							<g>
								<path class="st367" d="M354.5,182.9c0,0,0.2-0.1,0.4,0c0.2,0.1,0.3,0.2,0.3,0.2c0,0-0.2,0-0.4,0
									C354.7,183,354.5,183,354.5,182.9z"/>
							</g>
							<g>
								<path class="st368" d="M353.4,183L353.4,183c0-0.2,0.5-0.5,1.2-0.3c0.5,0.1,0.9,0.3,1.1,0.5c0.1-0.1-0.5-0.7-1.3-0.7
									c-0.8-0.1-1.3,0.3-1.2,0.4C353.2,183,353.3,183,353.4,183z"/>
							</g>
						</g>
						<g>
							<path class="st364" d="M354.6,181.5c0-0.4,0-1.1,0.1-1.1c0.1,0,0.1,0.7,0.1,1.1c0,0.4-0.1,0.7-0.1,0.7
								C354.6,182.2,354.6,181.9,354.6,181.5z"/>
						</g>
					</g>
				</g>
				<g>
					<path class="st369" d="M366.9,181.4c-0.5,1-1.6,2.9-2,2.8c-0.3-0.1,0.3-2.3,0.7-3.3c0.5-1,1.1-1.7,1.4-1.6
						C367.5,179.4,367.4,180.3,366.9,181.4z"/>
				</g>
				<g>
					<path class="st363" d="M367,184.9c1.2,2.2,2.1,4.7,2.7,7.2c0.2,0.7,1.3,6.5,0,6.5c-0.7,0-0.5-4.9-0.6-5.5
						c-0.5-2.5-1.7-5.7-3.9-7.1C363.6,184.9,366.3,182.7,367,184.9C367.1,185,367,184.9,367,184.9z"/>
				</g>
				<g>
					<g>
						<path class="st363" d="M367.1,184.9C367.1,184.9,367.1,184.9,367.1,184.9C367.1,184.9,367.1,184.9,367.1,184.9z"/>
					</g>
					<g>
						<path class="st363" d="M367.1,184.9C367.1,184.9,367.1,184.9,367.1,184.9L367.1,184.9z"/>
					</g>
					<g>
						<path class="st363" d="M367.1,184.9L367.1,184.9L367.1,184.9z"/>
					</g>
					<g>
						<path class="st363" d="M367,184.9C367,184.9,367,184.9,367,184.9C367,184.9,367,184.9,367,184.9z"/>
					</g>
					<g>
						<path class="st363" d="M367,184.9C367.1,184.9,367.1,184.9,367,184.9C367.1,184.9,367.1,184.9,367,184.9z"/>
					</g>
					<g>
						<path class="st363" d="M369.7,192c-0.6-2.5-1.5-4.9-2.7-7.2c-0.2-0.5-0.4-0.8-0.7-0.9c0.1,0.1,0.2,0.3,0.2,0.5c0,0,0,0,0,0
							c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c1.2,2.4,2.2,4.9,2.8,7.6c0.1,0.6,1.1,5.2,0.5,6.5
							C371,198.2,369.9,192.7,369.7,192z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st369" d="M371.8,177.3c-0.8,1.8-3.7,4.6-5.5,3.9c-1.7-0.8-1.6-4.9-0.8-6.6c0.8-1.8,2.8-2.6,4.6-1.8
							C371.8,173.4,372.5,175.5,371.8,177.3z"/>
					</g>
					<g>
						<g>
							<path class="st369" d="M365.5,175c-0.4,0.4-0.3,1.2,0.3,1.9c0.6,0.6,1.4,0.8,1.8,0.4c0.4-0.4,0.3-1.2-0.3-1.9
								C366.8,174.8,366,174.6,365.5,175z"/>
						</g>
						<g>
							<g>
								<path class="st370" d="M365.7,175.2c-0.3,0.2-0.1,0.9,0.4,1.4c0.5,0.5,1.1,0.8,1.4,0.5c0.3-0.2,0.1-0.9-0.4-1.4
									C366.6,175.2,366,175,365.7,175.2z"/>
							</g>
							<g>
								<path class="st370" d="M366.8,176c0.4,0.4,0.6,0.9,0.5,1.2c-0.3,0.1-0.8-0.2-1.2-0.6c-0.4-0.4-0.6-0.9-0.5-1.2
									C365.9,175.3,366.4,175.5,366.8,176z"/>
							</g>
							<g>
								<path class="st371" d="M367,176.1c-0.1,0.1-0.1,0.2,0.1,0.4c0.1,0.2,0.3,0.3,0.4,0.2c0.1-0.1,0.1-0.2-0.1-0.4
									C367.2,176.1,367.1,176,367,176.1z"/>
							</g>
							<g>
								<path class="st371" d="M365.7,175.6c0,0,0,0.2,0.1,0.3c0.1,0.2,0.3,0.3,0.3,0.3c0,0-0.1-0.2-0.2-0.3
									C365.8,175.7,365.8,175.6,365.7,175.6z"/>
							</g>
							<g>
								<path class="st371" d="M366.8,175.8c-0.1,0-0.1,0.1,0,0.2c0,0.1,0.1,0.1,0.2,0c0.1,0,0.1-0.1,0-0.2
									C367,175.7,366.9,175.7,366.8,175.8z"/>
							</g>
						</g>
					</g>
					<g>
						<path class="st372" d="M367.9,175.1c0,0.1-0.1,0.1-0.2,0.1l-1.3-0.6c-0.1,0-0.1-0.1-0.1-0.2l0,0c0-0.1,0.1-0.1,0.2-0.1
							l1.3,0.6C367.9,174.9,368,175,367.9,175.1L367.9,175.1z"/>
					</g>
					<g>
						<path class="st372" d="M371.1,176.5c0,0.1-0.1,0.1-0.2,0.1l-1.3-0.6c-0.1,0-0.1-0.1-0.1-0.2l0,0c0-0.1,0.1-0.1,0.2-0.1
							l1.3,0.6C371.1,176.3,371.1,176.4,371.1,176.5L371.1,176.5z"/>
					</g>
					<g>
						<path class="st373" d="M368.3,179.5c-0.1,0.2-1.2,0-1.4-0.1c-0.2-0.1-1-0.8-1-1c0.1-0.2,1,0.5,1.1,0.6
							C367.3,179.2,368.4,179.3,368.3,179.5z"/>
					</g>
					<g>
						<path class="st374" d="M368.6,173.1c0.1,0.2-0.4,0.3-1,0.5c-0.6,0.2-1.1,0.5-1.1,0.3c-0.1-0.2,0.3-0.7,0.9-1
							C368,172.8,368.5,172.9,368.6,173.1z"/>
					</g>
					<g>
						<path class="st374" d="M369.3,173.1c-0.1,0.1-0.2,0.2-0.4,0.1c-0.1-0.1-0.2-0.2-0.1-0.4c0.1-0.1,0.2-0.2,0.4-0.1
							C369.3,172.8,369.4,173,369.3,173.1z"/>
					</g>
					<g class="st365">
						<path class="st369" d="M371.6,174c0.1,0.6,0,1.3-0.3,2c-0.8,1.8-3.7,4.6-5.5,3.9c-0.3-0.1-0.6-0.4-0.8-0.7
							c0.2,1,0.6,1.8,1.3,2c1.7,0.8,4.7-2.1,5.5-3.9C372.3,176.2,372.1,175,371.6,174z"/>
					</g>
					<g>
						<g>
							<path class="st369" d="M371.2,177.5c0,0.6-0.7,1-1.6,1c-0.9,0-1.6-0.5-1.6-1.1c0-0.6,0.7-1,1.6-1
								C370.5,176.4,371.2,176.9,371.2,177.5z"/>
						</g>
						<g>
							<g>
								<path class="st370" d="M370.9,177.5c0,0.4-0.6,0.6-1.3,0.6c-0.7,0-1.3-0.3-1.3-0.7c0-0.4,0.6-0.6,1.3-0.6
									C370.3,176.8,370.9,177.1,370.9,177.5z"/>
							</g>
							<g>
								<path class="st370" d="M369.6,177.2c-0.6,0-1.1,0.2-1.3,0.4c0.2,0.3,0.7,0.5,1.3,0.5c0.6,0,1.1-0.2,1.3-0.4
									C370.7,177.4,370.2,177.2,369.6,177.2z"/>
							</g>
							<g>
								<path class="st371" d="M369.4,177.1c0,0.1-0.1,0.2-0.3,0.2c-0.2,0-0.4,0-0.4-0.1c0-0.1,0.1-0.2,0.3-0.2
									C369.2,177,369.4,177,369.4,177.1z"/>
							</g>
							<g>
								<path class="st371" d="M370.6,177.7c0,0-0.1,0.1-0.3,0.2c-0.2,0-0.4,0-0.4,0c0,0,0.2-0.1,0.4-0.1
									C370.5,177.7,370.6,177.7,370.6,177.7z"/>
							</g>
							<g>
								<path class="st371" d="M369.8,177.1c0,0.1,0,0.1-0.1,0.2c-0.1,0-0.1,0-0.1-0.1c0-0.1,0-0.1,0.1-0.2
									C369.7,176.9,369.8,177,369.8,177.1z"/>
							</g>
						</g>
					</g>
				</g>
				<g>
					<g>
						<path class="st363" d="M367,188.2c-0.9,2-4,5.2-5.7,4.4c-1.7-0.8-1.5-5.2-0.6-7.2c0.9-2,3-2.9,4.7-2.2
							C367.1,184.1,367.8,186.3,367,188.2z"/>
					</g>
					<g>
						<path class="st363" d="M367,188.2c0.7-1.7,0.3-3.5-0.9-4.5c-0.6-0.2-1.2-0.5-1.7-0.7c-0.2,0-0.5,0-0.7,0
							c0.1,0.3,0.9,0.8,1.7,1.3c0.9,0.6,1.9,1,0.9,3.1c-1,2.1-1.5,2.7-2.7,2.4c-1.1-0.3-2.8-0.9-2.8-0.9s1.9,1.6,2.5,1.9
							c0.7,0.4,0.5,0.4,0.9,0.6c0.4-0.3,0.5,0,0.9-0.4C365.8,190.1,366.6,189.1,367,188.2z"/>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path class="st364" d="M370.4,199.7c-0.5,0.2-1,0.1-1.2-0.5c-0.2-0.6-0.1-1.5,0.4-1.7c0.5-0.2,1.2,0.4,1.5,1
								C371.3,199.1,370.9,199.5,370.4,199.7z"/>
						</g>
						<g>
							<path class="st364" d="M369.9,199.6c0.2,0.7,0.5,2,0.4,2c-0.1,0-0.6-1.2-0.8-1.9c-0.2-0.7-0.2-1.3-0.1-1.3
								C369.5,198.4,369.7,198.9,369.9,199.6z"/>
						</g>
						<g>
							<path class="st364" d="M369.2,199.2c-0.3,0.7-0.4,0.8-0.5,0.8c-0.1,0-0.2-0.3,0.1-0.9c0.2-0.7,0.5-1.2,0.7-1.1
								C369.6,197.9,369.5,198.5,369.2,199.2z"/>
						</g>
						<g>
							<path class="st364" d="M370.5,199.6c0.2,0.7,0.4,1.7,0.3,1.8c-0.1,0-0.6-1-0.7-1.7c-0.2-0.7-0.2-1.3-0.1-1.3
								C370.1,198.4,370.3,198.9,370.5,199.6z"/>
						</g>
						<g>
							<path class="st364" d="M370.9,199.1c0.2,0.7,0.5,1.8,0.4,1.9c-0.1,0-0.6-1.1-0.8-1.7c-0.2-0.7-0.3-1.2-0.2-1.3
								C370.3,197.9,370.6,198.4,370.9,199.1z"/>
						</g>
						<g>
							<path class="st364" d="M371.2,198.8c0.3,0.6,0.7,1.4,0.6,1.4c-0.1,0.1-0.6-0.7-1-1.2c-0.3-0.6-0.5-1-0.4-1.1
								C370.5,197.8,370.8,198.2,371.2,198.8z"/>
						</g>
						<g>
							<path class="st364" d="M369.9,200.3c0.1,0.3,0.3,0.7,0.2,0.8c-0.1,0-0.3-0.5-0.3-0.7c-0.1-0.3-0.1-0.5,0-0.5
								C369.8,199.8,369.8,200.1,369.9,200.3z"/>
						</g>
						<g>
							<path class="st364" d="M370.4,200.1c0.1,0.3,0.3,0.8,0.2,0.8c-0.1,0-0.3-0.5-0.3-0.7c-0.1-0.3-0.1-0.5,0-0.5
								C370.4,199.6,370.4,199.8,370.4,200.1z"/>
						</g>
						<g>
							<path class="st364" d="M370.9,200c0.1,0.2,0.3,0.7,0.2,0.7c-0.1,0-0.3-0.4-0.3-0.7c-0.1-0.2-0.1-0.5,0-0.5
								C370.9,199.5,370.9,199.7,370.9,200z"/>
						</g>
						<g>
							<path class="st364" d="M371.4,199.4c0.1,0.2,0.3,0.5,0.3,0.5c0,0-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.4-0.1-0.4
								C371.2,199,371.3,199.2,371.4,199.4z"/>
						</g>
						<g>
							<path class="st364" d="M368.9,199.2c-0.1,0.2-0.1,0.6-0.2,0.6c0,0,0-0.4,0.1-0.6c0.1-0.2,0.2-0.4,0.2-0.3
								C369.1,198.8,369,198.9,368.9,199.2z"/>
						</g>
					</g>
					<g>
						<path class="st364" d="M369.2,194.3c0,1.6,0.1,4.2,0.5,4.2c0.8,0,0.7-2.2,0.4-4.1C369.8,194.3,369.5,194.3,369.2,194.3z"/>
					</g>
					<g>
						<g>
							<path class="st366" d="M371,194.3c0.1,0.1-0.4,0.5-1.2,0.5c-0.8,0-1.4-0.5-1.3-0.6c0.1-0.2,0.7,0,1.3,0
								C370.4,194.2,370.9,194.1,371,194.3z"/>
						</g>
						<g>
							<path class="st367" d="M369.6,194.4c0,0-0.2,0.1-0.4,0c-0.2,0-0.4-0.1-0.3-0.2c0,0,0.2,0,0.4,0
								C369.4,194.3,369.6,194.3,369.6,194.4z"/>
						</g>
						<g>
							<path class="st368" d="M370.7,194.2C370.7,194.2,370.7,194.2,370.7,194.2c0.1,0.1-0.4,0.5-1.2,0.4c-0.5,0-0.9-0.2-1.1-0.4
								c-0.1,0.2,0.5,0.6,1.3,0.6c0.8,0,1.3-0.4,1.2-0.5C370.9,194.2,370.8,194.2,370.7,194.2z"/>
						</g>
					</g>
					<g>
						<path class="st364" d="M369.6,195.8c0,0.4,0,1.1,0,1.1c-0.1,0-0.2-0.7-0.2-1.1c0-0.4,0-0.7,0.1-0.7
							C369.6,195.1,369.6,195.4,369.6,195.8z"/>
					</g>
				</g>
				<g>
					<path class="st364" d="M365.1,191.4c-0.1,0.2-0.3,0.3-0.5,0.3c-1.8-0.5-3.4-1.2-4.9-2.1c-0.1-0.1-0.2-0.3-0.1-0.5
						c0-0.1,0.1-0.2,0.1-0.3c0-0.2,0.2-0.3,0.4-0.2c1.7,1,3.2,1.7,5.2,2.3c0.2,0.1,0.2,0.2,0.1,0.4
						C365.2,191.3,365.2,191.3,365.1,191.4z"/>
				</g>
				<g>
					<path class="st368" d="M365.2,190.8c-0.2-0.1-0.4-0.1-0.5-0.2c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.2-0.3,0.3-0.5,0.3
						c-1.6-0.4-3.1-1-4.5-1.9c0,0.1,0,0.3,0.2,0.4c1.5,1,3.1,1.7,4.9,2.1c0.2,0,0.4-0.1,0.5-0.3c0.1-0.1,0.1-0.2,0.2-0.3
						C365.4,191,365.4,190.8,365.2,190.8z"/>
				</g>
				<g>
					<path class="st367" d="M362.8,190.1c0,0.1-0.7,0-1.5-0.4c-0.8-0.4-1.3-0.8-1.2-0.9c0-0.1,0.6,0.3,1.4,0.6
						C362.2,189.8,362.8,190,362.8,190.1z"/>
				</g>
				<g>
					<g>
						<polygon class="st366" points="365.1,184 364.9,183.8 364.9,183.8 365.1,183.9 						"/>
					</g>
					<g>
						<path class="st366" d="M362.4,189.6l0-0.2l0.2,0.1l0,0.2L362.4,189.6z M362.5,189.2l0-0.2l0.2,0.1l0,0.2L362.5,189.2z
							 M362.6,188.8l0-0.2l0.2,0.1l0,0.2L362.6,188.8z M362.7,188.4l0-0.1c0,0,0,0,0-0.1l0.2,0.1c0,0,0,0,0,0.1l0,0.1L362.7,188.4z
							 M362.8,187.9c0-0.1,0-0.1,0.1-0.2l0.2,0.1c0,0.1,0,0.1,0,0.2L362.8,187.9z M362.9,187.5c0-0.1,0-0.1,0.1-0.2l0.2,0.1
							c0,0.1,0,0.1-0.1,0.2L362.9,187.5z M363.1,187.1c0-0.1,0-0.1,0.1-0.2l0.2,0.1l0,0c0,0,0,0.1-0.1,0.1L363.1,187.1z
							 M363.5,186.9l-0.2-0.1c0-0.1,0-0.1,0.1-0.2l0.2,0.1C363.5,186.8,363.5,186.8,363.5,186.9z M363.6,186.5l-0.2-0.1
							c0-0.1,0.1-0.1,0.1-0.1l0.2,0.1C363.7,186.4,363.7,186.4,363.6,186.5z M363.8,186.1l-0.2-0.1c0,0,0.1-0.1,0.1-0.1l0.2,0.1
							C363.9,186,363.9,186.1,363.8,186.1z M364,185.8l-0.2-0.1c0,0,0.1-0.1,0.1-0.1l0.2,0.1C364.1,185.7,364.1,185.7,364,185.8z
							 M364.2,185.4l-0.2-0.1c0,0,0.1-0.1,0.1-0.1l0.2,0.1C364.3,185.3,364.3,185.3,364.2,185.4z M364.4,185l-0.2-0.1
							c0,0,0.1-0.1,0.1-0.1l0.2,0.1C364.5,184.9,364.5,185,364.4,185z M364.6,184.7l-0.2-0.1l0.1-0.1l0.2,0.1L364.6,184.7z
							 M364.9,184.3l-0.2-0.1c0-0.1,0.1-0.1,0.1-0.1l0.2,0.1C364.9,184.2,364.9,184.3,364.9,184.3z"/>
					</g>
					<g>

							<rect x="362.4" y="189.8" transform="matrix(0.2158 -0.9764 0.9764 0.2158 98.8406 502.8325)" class="st366" width="0.1" height="0.2"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st369" d="M371.4,173.6l3.6-1.9c0.1,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1l-3.3,2.3c-0.2,0.1-0.4,0.1-0.5-0.1
							C371.2,173.9,371.2,173.7,371.4,173.6C371.4,173.6,371.4,173.6,371.4,173.6z"/>
					</g>
					<g>
						<g>
							<path class="st375" d="M371.7,174.5c-0.1,0.1-0.2,0-0.3-0.1l-0.4-0.8c-0.1-0.1,0-0.2,0.1-0.3l0,0c0.1-0.1,0.2,0,0.3,0.1
								l0.4,0.8C371.9,174.4,371.8,174.5,371.7,174.5L371.7,174.5z"/>
						</g>
						<g>
							<path class="st374" d="M371.4,173.9c0,0-0.1,0-0.1,0l-0.1-0.3c0,0,0-0.1,0-0.1l0,0c0,0,0.1,0,0.1,0l0.1,0.3
								C371.5,173.8,371.5,173.9,371.4,173.9L371.4,173.9z"/>
						</g>
						<g class="st365">
							<path class="st376" d="M371.8,174.3C371.8,174.4,371.8,174.4,371.8,174.3c-0.2,0.1-0.3,0.1-0.4,0l-0.4-0.8c0,0,0-0.1,0-0.1
								c0,0.1,0,0.2,0,0.2l0.4,0.8c0.1,0.1,0.2,0.1,0.3,0.1C371.8,174.5,371.8,174.4,371.8,174.3z"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st376" d="M375.4,171.9c-0.1,0.2-0.3,0.3-0.5,0.2c-0.2-0.1-0.3-0.3-0.2-0.5c0.1-0.2,0.3-0.3,0.5-0.2
								C375.4,171.5,375.5,171.7,375.4,171.9z"/>
						</g>
						<g>
							<path class="st376" d="M375,171.6C375,171.6,374.9,171.6,375,171.6c-0.1,0-0.2,0-0.1-0.1c0,0,0.1-0.1,0.1,0
								C375,171.5,375,171.5,375,171.6z"/>
						</g>
						<g class="st377">
							<path class="st376" d="M375.4,171.6C375.4,171.6,375.4,171.6,375.4,171.6c-0.1,0.2-0.3,0.3-0.5,0.3c-0.1,0-0.2-0.1-0.2-0.2
								c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0,0.5-0.2C375.4,171.8,375.4,171.7,375.4,171.6z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<path class="st369" d="M368.2,172.2l-1-3.9c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1l0.6,4c0,0.2,0.2,0.3,0.4,0.3
							C368.1,172.6,368.2,172.4,368.2,172.2C368.2,172.2,368.2,172.2,368.2,172.2z"/>
					</g>
					<g>
						<g>
							<path class="st375" d="M367.2,172.6c0,0.1,0.1,0.2,0.3,0.2l0.8-0.2c0.1,0,0.2-0.1,0.2-0.3l0,0c0-0.1-0.1-0.2-0.3-0.2
								l-0.8,0.2C367.3,172.3,367.2,172.5,367.2,172.6L367.2,172.6z"/>
						</g>
						<g>
							<path class="st374" d="M367.9,172.3C367.9,172.4,367.9,172.4,367.9,172.3l0.4,0c0,0,0.1-0.1,0.1-0.1l0,0c0,0-0.1-0.1-0.1-0.1
								L367.9,172.3C367.9,172.3,367.9,172.3,367.9,172.3L367.9,172.3z"/>
						</g>
						<g class="st365">
							<path class="st376" d="M367.3,172.4c0,0,0,0.1,0,0.1c0,0.1,0.1,0.2,0.3,0.2l0.8-0.2c0,0,0.1,0,0.1,0c0,0.1-0.1,0.1-0.2,0.2
								l-0.8,0.2c-0.1,0-0.2,0-0.3-0.2C367.2,172.5,367.2,172.4,367.3,172.4z"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st376" d="M366.7,168.1c-0.1,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0,0.5-0.2c0.1-0.2,0-0.4-0.2-0.5
								C367,167.8,366.8,167.9,366.7,168.1z"/>
						</g>
						<g>
							<path class="st376" d="M367.2,168.1c0,0,0,0.1,0,0.1c0,0,0.1,0,0.1,0C367.4,168.2,367.4,168.1,367.2,168.1
								C367.3,168.1,367.2,168.1,367.2,168.1z"/>
						</g>
						<g class="st377">
							<path class="st376" d="M366.9,167.9C366.9,167.9,366.9,167.9,366.9,167.9c-0.1,0.2,0,0.5,0.2,0.6c0.1,0,0.2,0,0.3,0
								c-0.1,0.2-0.3,0.2-0.5,0.1c-0.2-0.1-0.3-0.3-0.2-0.5C366.7,168,366.8,167.9,366.9,167.9z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path class="st362" d="M362.9,190.4c-0.2,0.4-0.6,0.6-1,0.4c-0.4-0.2-0.6-0.6-0.4-1c0.2-0.4,0.6-0.6,1-0.4
								C362.9,189.5,363.1,190,362.9,190.4z"/>
						</g>
						<g>
							<path class="st362" d="M362.7,189.5c0.2,0.2,0.3,0.5,0.1,0.8c-0.2,0.4-0.6,0.6-1,0.4c-0.1,0-0.1-0.1-0.2-0.1
								c0.1,0.1,0.2,0.1,0.3,0.2c0.4,0.2,0.9,0,1-0.4C363.1,190.1,363,189.7,362.7,189.5z"/>
						</g>
					</g>
					<g>
						<path class="st362" d="M361.6,189.9c0.2-0.3,0.5-0.5,0.9-0.4C362.2,189.4,361.8,189.5,361.6,189.9c0,0,0,0.1,0,0.1
							C361.6,190,361.6,190,361.6,189.9z"/>
					</g>
					<g>
						<g>
							<path class="st362" d="M361.9,189.8c0.1-0.2,0.4-0.2,0.6,0c0.2,0.1,0.2,0.4,0,0.6c-0.1,0.2-0.4,0.2-0.6,0
								C361.8,190.2,361.7,189.9,361.9,189.8z"/>
						</g>
						<g>
							<path class="st362" d="M361.8,190.2c-0.1-0.1,0-0.3,0.1-0.4c0.1-0.2,0.4-0.2,0.6,0c0,0,0.1,0.1,0.1,0.1
								c0-0.1-0.1-0.1-0.1-0.1c-0.2-0.1-0.4-0.1-0.6,0C361.8,189.9,361.7,190.1,361.8,190.2z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<path class="st362" d="M365.8,188.1c0,0.1-0.1,0.1-0.2,0.1l-0.7-0.3c-0.1,0-0.1-0.1-0.1-0.2l0.4-0.9c0-0.1,0.1-0.1,0.2-0.1
							l0.7,0.3c0.1,0,0.1,0.1,0.1,0.2L365.8,188.1z"/>
					</g>
					<g>
						<path class="st362" d="M366.1,187.1c0,0,0,0.1,0,0.1l-0.4,0.9c0,0.1-0.1,0.1-0.2,0.1l-0.7-0.3c0,0-0.1,0-0.1-0.1
							c0,0.1,0,0.1,0.1,0.1l0.7,0.3c0.1,0,0.2,0,0.2-0.1l0.4-0.9C366.2,187.2,366.2,187.1,366.1,187.1z"/>
					</g>
					<g>
						<path class="st362" d="M365.8,187l-0.5-0.2c-0.1,0-0.1,0-0.2,0.1l-0.2,0.5c0,0,0,0,0,0l0.2-0.5c0-0.1,0.1-0.1,0.2-0.1
							L365.8,187C365.8,187.1,365.8,187,365.8,187z"/>
					</g>
					<g>
						<g>
							<path class="st378" d="M365.7,187.2c0-0.1,0-0.1-0.1-0.2c-0.1,0-0.1,0-0.2,0.1c0,0.1,0,0.1,0.1,0.2
								C365.6,187.3,365.7,187.3,365.7,187.2z"/>
						</g>
						<g>
							<path class="st362" d="M365.6,187.3c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.1-0.1-0.2c0,0,0,0,0,0c0,0,0,0,0.1,0
								c0.1,0,0.1,0.1,0.1,0.2C365.7,187.3,365.7,187.3,365.6,187.3z"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st379" d="M365.6,187.5c0-0.1,0-0.1-0.1-0.2c-0.1,0-0.2,0-0.2,0.1c0,0.1,0,0.1,0.1,0.2
								C365.5,187.6,365.6,187.6,365.6,187.5z"/>
						</g>
						<g>
							<path class="st362" d="M365.4,187.6c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.1-0.1-0.2c0,0,0,0,0,0c0,0,0,0,0.1,0
								c0.1,0,0.1,0.1,0.1,0.2C365.6,187.6,365.5,187.6,365.4,187.6z"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st380" d="M365.4,187.8c0-0.1,0-0.1-0.1-0.2c-0.1,0-0.1,0-0.2,0.1c0,0.1,0,0.2,0.1,0.2
								C365.3,188,365.4,187.9,365.4,187.8z"/>
						</g>
						<g>
							<path class="st362" d="M365.3,188c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.1-0.1-0.2c0,0,0,0,0,0c0,0,0,0,0.1,0
								c0.1,0,0.1,0.1,0.1,0.2C365.4,187.9,365.4,188,365.3,188z"/>
						</g>
					</g>
				</g>
				<g class="st381">
					<path class="st363" d="M361.4,185.2c0.6-1.3,1-1,1.5-0.8c0.5,0.2,0.2,0.2-0.5,1.5c-0.6,1.3-0.9,2.5-1.4,2.2
						C360.6,187.9,360.8,186.5,361.4,185.2z"/>
				</g>
				<g>
					<path class="st382" d="M367.8,185.2c-0.2-0.3-0.5-0.7-1.1-1.1c-0.6-0.3-1-0.5-1.4-0.7c-0.4-0.2-0.9-0.4-1.5-0.6
						c-0.7-0.2-1.3-0.2-1.6-0.1c-0.2,0.4-0.2,0.9-0.1,1.4c0.2,1.1,0.6,1.4,1.2,1.1c0.1-0.1,0.3-0.1,0.4-0.1c0,0-0.1,1,0.4,1.2
						c0.5,0.2,1.1-0.5,1.1-0.5c0.1,0.1,0.2,0.2,0.2,0.4c0.1,0.7,0.7,0.7,1.6,0.2C367.3,185.9,367.7,185.5,367.8,185.2z"/>
					<path class="st383" d="M367.8,185.2c-0.2-0.3-0.5-0.7-1.1-1.1c-0.3-0.2-0.6-0.3-0.8-0.4c-1.5-0.5-2.8-0.8-2.8-0.8
						c0,0,3.8,1.8,3.1,2.5c-0.2,0.3-0.5,0.5-0.8,0.8c0.1,0.6,0.7,0.7,1.6,0.1C367.3,185.9,367.7,185.5,367.8,185.2z"/>
					<path class="st384" d="M364.5,183.9c-0.4-0.3-1.2,0.3-1.4,1.3c0,0,0,0,0,0c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.3-0.1,0.4-0.1
						c0,0-0.1,0.9,0.3,1.2c0,0,0-0.1,0.1-0.1C364.6,185.1,364.9,184.1,364.5,183.9z"/>
					<g>
						<path class="st383" d="M364,186.3c-0.4-0.2-0.4-0.7-0.4-1.1c-0.1,0-0.1,0-0.2,0.1c-0.3,0.2-0.6,0.2-0.9,0
							c-0.2-0.2-0.4-0.7-0.5-1.2c0-0.1,0-0.2,0.1-0.1c0.1,0,0.1,0.1,0.2,0.2c0.1,0.4,0.2,0.7,0.3,0.8c0.2,0.2,0.4,0.2,0.7,0
							c0.1-0.1,0.3-0.1,0.4-0.1c0,0,0.1,0,0.1,0c0,0,0,0.1,0,0.2c0,0-0.1,0.8,0.3,1c0.4,0.2,0.9-0.4,1-0.5c0,0,0.1-0.1,0.1-0.1
							c0.1,0,0.1,0,0.1,0c0.1,0.1,0.2,0.3,0.2,0.4c0.1,0.3,0.2,0.5,0.5,0.5c0.2,0,0.5-0.1,0.9-0.3c0.1-0.1,0.2-0.1,0.3,0
							c0.1,0,0,0.1,0,0.2c-0.5,0.3-0.9,0.5-1.2,0.5c-0.4,0-0.6-0.2-0.6-0.6c0-0.1,0-0.1-0.1-0.2C364.8,186.2,364.3,186.5,364,186.3z
							"/>
					</g>
					<path class="st383" d="M365.9,186.6c-0.4,0-0.6-0.2-0.6-0.6c0-0.1,0-0.1-0.1-0.2c-0.3,0.3-0.8,0.6-1.2,0.4
						c-0.4-0.2-0.5-0.7-0.5-1.2c-0.1,0-0.1,0-0.2,0.1c-0.4,0.2-0.7,0.2-0.9-0.1c-0.2-0.2-0.4-0.7-0.5-1.2c0,0,0,0.1,0,0.1
						c0.1,0.5,0.3,1,0.5,1.2c0.2,0.3,0.5,0.3,0.9,0c0,0,0.1-0.1,0.2-0.1c0,0.4,0.1,1,0.4,1.1c0.4,0.2,0.8-0.2,1.2-0.4
						c0,0.1,0.1,0.1,0.1,0.2C365.2,186.5,365.4,186.7,365.9,186.6c0.2,0.1,0.7-0.1,1.1-0.4c0,0,0,0,0.1-0.1
						C366.6,186.4,366.2,186.6,365.9,186.6z"/>
				</g>
				<g>
					<g>
						<path class="st366" d="M368.1,183.9c-0.1,0.4-0.7,0.8-1.3,0.7c-1.3-0.3-2.6-0.8-3.7-1.6c-0.5-0.3-0.6-1-0.4-1.4l0,0
							c0.3-0.4,0.8-0.4,1.2-0.1c1,0.7,2.2,1.3,3.4,1.5C367.9,183.1,368.2,183.5,368.1,183.9L368.1,183.9z"/>
					</g>
					<g>
						<path class="st367" d="M365.2,182.9c-0.1,0.1-0.3,0.2-0.4,0.1c-0.4-0.2-0.8-0.4-1.2-0.7c-0.2-0.1-0.2-0.3-0.1-0.5l0,0
							c0.1-0.1,0.3-0.2,0.4,0c0.4,0.3,0.7,0.5,1.1,0.7C365.1,182.6,365.2,182.8,365.2,182.9L365.2,182.9z"/>
					</g>
					<g>
						<path class="st368" d="M367.8,183.1c0,0.1,0,0.3,0,0.4c-0.1,0.4-0.7,0.8-1.2,0.6c-1.3-0.3-2.5-0.9-3.5-1.8
							c-0.1-0.1-0.2-0.2-0.3-0.4c0,0.3,0.2,0.7,0.5,1c1.1,0.8,2.4,1.4,3.7,1.6c0.6,0.1,1.1-0.2,1.3-0.7
							C368.2,183.6,368,183.3,367.8,183.1z"/>
					</g>
				</g>
				<g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<path class="st362" d="M364,187.9c-0.3,0.5-0.9,0.7-1.4,0.4c-0.5-0.3-0.7-0.9-0.4-1.4c0.3-0.5,0.9-0.7,1.4-0.4
											C364.1,186.8,364.3,187.4,364,187.9z"/>
									</g>
								</g>
								<g>
									<g>
										<path class="st362" d="M363.8,186.7c0.2,0.3,0.3,0.7,0.1,1.1c-0.3,0.5-0.9,0.7-1.4,0.4c-0.1,0-0.2-0.1-0.2-0.2
											c0.1,0.1,0.2,0.2,0.3,0.3c0.5,0.3,1.1,0.1,1.4-0.4C364.2,187.5,364.1,187,363.8,186.7z"/>
									</g>
								</g>
							</g>
							<g>
								<path class="st362" d="M362.3,187c0.3-0.5,0.9-0.7,1.4-0.4c0.1,0.1,0.2,0.1,0.3,0.2c-0.1-0.1-0.2-0.2-0.3-0.3
									C363.1,186.3,362.5,186.5,362.3,187c-0.2,0.3-0.2,0.8,0.1,1.1C362.1,187.8,362.1,187.4,362.3,187z"/>
							</g>
						</g>
						<g>
							<g>
								<g>
									<path class="st366" d="M363.8,187.7c-0.2,0.4-0.6,0.6-1,0.4c-0.4-0.2-0.6-0.6-0.4-1c0.2-0.4,0.6-0.6,1-0.4
										C363.8,186.9,364,187.3,363.8,187.7z"/>
								</g>
							</g>
							<g>
								<g>
									<path class="st367" d="M363,187c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1-0.1-0.1-0.2c0-0.1,0.1-0.1,0.2-0.1
										C363,186.8,363.1,186.9,363,187z"/>
								</g>
							</g>
							<g>
								<g>
									<path class="st368" d="M363.8,187.1c0,0,0,0.1,0,0.1c-0.2,0.4-0.6,0.6-1,0.4c-0.2-0.1-0.3-0.2-0.4-0.4
										c-0.1,0.4,0.1,0.7,0.4,0.9c0.4,0.2,0.8,0,1-0.4C363.9,187.5,363.9,187.3,363.8,187.1z"/>
								</g>
							</g>
						</g>
					</g>
					<g>
						<g>
							<path class="st385" d="M362.5,188.8c-0.8-0.3-1.1-1.2-0.8-2c0.3-0.8,1.2-1.1,2-0.8c0.8,0.3,1.1,1.2,0.8,2
								C364.2,188.8,363.3,189.1,362.5,188.8z M363.6,186.3c-0.6-0.3-1.3,0-1.6,0.6c-0.3,0.6,0,1.3,0.6,1.6c0.6,0.3,1.3,0,1.6-0.6
								C364.5,187.3,364.2,186.6,363.6,186.3z"/>
						</g>
						<g>
							<path class="st385" d="M362,187.8c-0.1-0.3-0.1-0.6,0-0.9c0.3-0.6,1-0.9,1.6-0.6c0.3,0.1,0.5,0.4,0.6,0.6
								c-0.1-0.3-0.3-0.6-0.7-0.7c-0.6-0.3-1.3,0-1.6,0.6C361.8,187.1,361.9,187.5,362,187.8z"/>
							<path class="st385" d="M364.5,186.8c0.1,0.3,0.1,0.7,0,1.1c-0.3,0.8-1.2,1.1-2,0.8c-0.4-0.2-0.6-0.4-0.8-0.8
								c0.1,0.4,0.4,0.7,0.8,0.9c0.8,0.3,1.7,0,2-0.8C364.7,187.6,364.7,187.2,364.5,186.8z"/>
						</g>
					</g>
				</g>
			</g>
		</g>
		<path class="st386" d="M375.6,174.5c-0.2,0.6-2.6-0.9-5.7-2.2c-3.1-1.4-5.7-2.1-5.5-2.7c0.2-0.6,3.3-0.7,6.4,0.6
			C373.9,171.6,375.8,173.9,375.6,174.5z"/>
	</g>
	<g>
		<g>
			<g>
				<path class="st387" d="M286.6,409.8c0.8,3.3-12.3,20.8-32.4,25.5c-20.1,4.7-39.6-5.1-40.4-8.4c-0.8-3.3,19.2-15,34.1-18.5
					C262.8,404.9,285.9,406.5,286.6,409.8z"/>
				<path class="st344" d="M238.6,426.9c-4.8,0.8-9.1,1.4-12.7,1.6c0,0,6,3.9,16.2,7.2c3.4,0.4,7,0.4,10.5-0.2
					C242.6,429.7,241.9,429.3,238.6,426.9z"/>
				<path class="st345" d="M266.9,408c0,8.3,5.5,13.6-6.5,20.5c-3.7,2.1-10.9,5.4-18,8c3.8,0,7.8-0.3,11.8-1.2
					c20.1-4.7,33.2-22.2,32.4-25.5C286.2,408,273,409.6,266.9,408z"/>
				<path class="st346" d="M246.2,436.4c2.6-0.2,5.3-0.5,8-1.2c19.8-4.6,32.7-21.6,32.4-25.3c-0.2-0.4-0.6-0.7-1.2-0.9
					c-1.6,7-15.3,22.1-35,26.7C249,436,247.6,436.3,246.2,436.4z"/>
				<path class="st349" d="M286.6,409.8c0.8,3.3-14.9,9.8-35,14.5c-20.1,4.7-37,5.9-37.8,2.6c-0.8-3.3,19.2-15,34.1-18.5
					C262.8,404.9,285.9,406.5,286.6,409.8z"/>
				<path class="st348" d="M266.9,420.1C266.9,420.1,266.9,420.1,266.9,420.1c4.5-1.4,8.5-2.9,11.6-4.3c-1.4-2.4-10.8-4.1-13.4-8.1
					c-3.9-0.8-8.2-0.6-12.8,0C255.4,411.1,264.5,414.9,266.9,420.1z"/>
				<path class="st348" d="M225.9,428.5C225.9,428.5,225.9,428.5,225.9,428.5c0.5,0,0.9-0.1,1.4-0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0
					c0.4,0,0.9-0.1,1.3-0.1c0.1,0,0.1,0,0.2,0c0.4,0,0.9-0.1,1.3-0.1c0.1,0,0.1,0,0.2,0c0.5-0.1,0.9-0.1,1.4-0.2c0.1,0,0.1,0,0.2,0
					c0.5-0.1,0.9-0.1,1.4-0.2c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0.5-0.1,1-0.1,1.5-0.2c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
					c0.5-0.1,1-0.2,1.5-0.2c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0.5-0.1,1.1-0.2,1.6-0.3c0,0,0.1,0,0.1,0c0.5-5.8,4.6-12.7,5.3-17.4
					c-0.5,0.2-1.1,0.4-1.6,0.6c-3.6,1.4-6.8,3.2-9.5,5.1C232.5,420.1,225,425.8,225.9,428.5z"/>
				<path class="st348" d="M238.7,411.7c-0.7,0.4-1.4,0.7-2.1,1.1c-0.3,4.1-6.7,10.4-6.4,15.3c0.2,0,0.3,0,0.5-0.1
					c0.3,0,0.5-0.1,0.8-0.1c0.2,0,0.5-0.1,0.8-0.1c0.2,0,0.4,0,0.5-0.1c0.7-0.1,1.3-0.2,2-0.3C234.3,422.3,238.8,415.6,238.7,411.7z
					"/>
				<g>
					<g>
						<path class="st349" d="M286.6,409.8c0.1,0.4-0.1,0.8-0.3,1.1c-0.2,0.3-0.5,0.6-0.8,0.9c-0.6,0.5-1.3,1-1.9,1.5
							c-1.4,0.9-2.8,1.6-4.3,2.3c-2.9,1.4-6,2.5-9,3.6c-6.1,2.1-12.3,3.8-18.6,5.3c-6.3,1.5-12.7,2.7-19.1,3.5
							c-3.2,0.4-6.4,0.7-9.7,0.8c-1.6,0-3.2,0-4.8-0.1c-0.8-0.1-1.6-0.2-2.4-0.4c-0.4-0.1-0.8-0.3-1.1-0.5c-0.3-0.2-0.7-0.5-0.8-0.9
							c0.1,0.4,0.4,0.7,0.8,0.9c0.3,0.2,0.7,0.3,1.1,0.4c0.8,0.2,1.6,0.3,2.4,0.4c1.6,0.1,3.2,0.1,4.8,0c3.2-0.1,6.4-0.5,9.6-0.9
							c6.4-0.9,12.7-2.1,19-3.6c6.3-1.5,12.5-3.2,18.6-5.2c3.1-1,6.1-2.1,9-3.5c1.5-0.7,2.9-1.4,4.3-2.2c0.7-0.4,1.4-0.9,2-1.4
							c0.3-0.3,0.6-0.6,0.8-0.9C286.5,410.6,286.7,410.2,286.6,409.8z"/>
					</g>
				</g>
				<path class="st350" d="M269,408.4c0.5,2.1-8.1,5.9-19.1,8.5c-11.1,2.6-20.4,3-20.9,0.9c-0.5-2.1,12.2-6.7,19.2-8.4
					C254.9,407.9,268.5,406.3,269,408.4z"/>
				<path class="st388" d="M271.7,425.2c-5.6,2.3-11.6,4.2-18,5.7l-0.9,0.2c-6.4,1.5-12.7,2.5-18.7,2.9c-2.5,0.2-4.7,0.3-6.7,0.3
					c2,0.6,4.1,1.1,6.3,1.5c0.2,0,0.4,0,0.6,0c5.9-0.4,12.2-1.3,19.3-2.9l0,0c7.1-1.7,13.1-3.7,18.6-6c0.2-0.1,0.4-0.2,0.6-0.2
					c1.8-1.3,3.5-2.7,5-4.1C275.9,423.3,274,424.2,271.7,425.2z"/>
			</g>
			<g>
				<path class="st351" d="M280.3,407.3c-1.5-0.9-7.1-3.9-13.4-6.4c-6.6-2.4-12.5-4.3-21.7-2c-9.3,2.1-13.7,6.5-18.6,11.5
					c-4.6,5-8.3,10-9.2,11.6c-0.7,2.8,12.3,3.1,32.8-2C270.8,415.5,282.2,409.6,280.3,407.3z"/>
			</g>
			<g>
				<path class="st387" d="M280.8,405.1c-1.6-0.9-7.3-3.9-13.8-6.3c-6.8-2.3-12.8-4.2-22.4-1.9c-9.6,2.2-14.2,6.6-19.3,11.7
					c-4.7,5-8.6,10.1-9.6,11.6c-0.7,2.8,12.7,3,33.8-2.2C271,413.4,282.7,407.4,280.8,405.1z"/>
			</g>
			<path class="st344" d="M231.4,405.5c-4.4,3.3-7,7.9-7,7.9l1.8,0.1l1.9,0.3c0,0,0.3-1.1,2.1-3.8c1.8-2.8,9.2-9.4,9.2-9.4
				S236.1,401.9,231.4,405.5z"/>
			<path class="st389" d="M280.8,405.1c-1.6-0.9-7.3-3.9-13.8-6.3c-5.4-1.9-10.3-3.4-16.9-2.8c6.1,3.4,11.6,8.6,16,15.2
				c0.4,0.7,0.9,1.3,1.3,2C277.2,409.8,282.1,406.6,280.8,405.1z"/>
			<path class="st390" d="M280.8,405.1c-1.6-0.9-7.3-3.9-13.8-6.3c-4.4-1.5-8.5-2.8-13.4-2.9c3.8,0.5,7.3,1.9,11.1,3.4
				c7,3,13.4,6.8,15.1,8c0,0,0,0,0,0C281,406.4,281.3,405.6,280.8,405.1z"/>
			<g>
				<path class="st387" d="M215.9,420.3c-0.1,0.4,0.1,0.7,0.4,0.9c0.3,0.2,0.6,0.3,1,0.4c0.7,0.2,1.4,0.3,2.1,0.3
					c1.4,0.1,2.9,0,4.3-0.1c2.9-0.2,5.8-0.5,8.6-1c2.9-0.5,5.7-1,8.6-1.6c2.8-0.6,5.7-1.2,8.5-2c2.9-0.6,5.7-1.3,8.5-2
					c2.8-0.7,5.6-1.6,8.4-2.4c2.8-0.9,5.5-1.8,8.2-2.9c1.3-0.6,2.7-1.1,3.9-1.9c0.6-0.4,1.2-0.8,1.8-1.2c0.3-0.2,0.5-0.5,0.7-0.8
					c0.2-0.3,0.2-0.7,0-1l0.1-0.1c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2-0.1,0.4-0.1,0.5c-0.2,0.3-0.4,0.6-0.7,0.9
					c-0.5,0.5-1.1,0.9-1.7,1.3c-1.2,0.8-2.5,1.4-3.9,2.1c-2.7,1.2-5.4,2.2-8.2,3.2c-5.5,1.8-11.2,3.3-16.9,4.6
					c-5.7,1.4-11.4,2.5-17.2,3.3c-2.9,0.4-5.8,0.7-8.7,0.8c-1.5,0-2.9,0-4.4-0.1c-0.7-0.1-1.5-0.2-2.2-0.4c-0.3-0.1-0.7-0.2-1-0.5
					c-0.1-0.1-0.3-0.3-0.4-0.4C215.8,420.6,215.8,420.4,215.9,420.3L215.9,420.3z"/>
			</g>
			<path class="st351" d="M244.7,396.9c-1.2,0.8-2.4,1.6-3.6,2.5c-1.2,0.9-2.3,1.8-3.4,2.7c-2.2,1.9-4.4,3.8-6.4,6
				c-2,2.1-3.9,4.3-5.7,6.6c-0.9,1.1-1.8,2.3-2.6,3.5c-0.8,1.2-1.6,2.4-2.4,3.6c0.7-1.3,1.5-2.5,2.3-3.7c0.8-1.2,1.6-2.4,2.5-3.6
				c1.7-2.3,3.7-4.6,5.7-6.7c2-2.1,4.2-4.1,6.5-5.9C239.8,400,242.2,398.3,244.7,396.9z"/>
			<path class="st351" d="M244.7,396.9c2.7,0.2,5.4,0.9,8,1.6c2.6,0.7,5.2,1.7,7.7,2.7c2.5,1.1,5,2.2,7.4,3.5c1.2,0.7,2.4,1.3,3.6,2
				c1.2,0.7,2.3,1.5,3.4,2.2c-2.3-1.4-4.7-2.7-7.1-4c-2.4-1.2-4.9-2.4-7.4-3.4c-2.5-1.1-5.1-2-7.7-2.8c-1.3-0.4-2.6-0.8-3.9-1.1
				C247.4,397.4,246.1,397.1,244.7,396.9z"/>
			<path class="st351" d="M249.6,417.2c-0.5-1.7-0.9-3.3-1.4-5c-0.4-1.7-0.8-3.4-1.3-5.1c-0.4-1.7-0.8-3.4-1.2-5.1l-0.5-2.6
				c-0.2-0.9-0.3-1.7-0.5-2.6c0.3,0.8,0.5,1.7,0.7,2.5l0.7,2.5c0.4,1.7,0.8,3.4,1.3,5.1c0.4,1.7,0.8,3.4,1.2,5.1
				C248.9,413.8,249.3,415.5,249.6,417.2z"/>
			<path class="st391" d="M225.6,414.5c-0.7,1-4.3,6.2-5,7.2c0.7-1,4.2-6.1,5-7.1c1.8-2.3,3.7-4.5,5.7-6.6c2-2.1,4.1-4.1,6.4-6
				c1.1-0.9,2.2-1.8,3.4-2.7c0.2-0.1,3.3-2.3,3.5-2.4c-1.3,0.9-5.6,3.9-6.9,4.9c-2.3,1.8-4.4,3.8-6.5,5.9
				C229.3,409.9,227.4,412.1,225.6,414.5z"/>
			<g>
				<path class="st392" d="M217.9,417.5c2.6,0.2,5.2,0.1,7.8,0c2.6-0.1,5.2-0.3,7.8-0.6c5.2-0.6,10.3-1.4,15.3-2.7
					c5.1-1.2,10-2.7,14.9-4.5c2.4-0.9,4.8-1.9,7.2-2.9c2.4-1.1,4.7-2.2,7-3.4c-4.5,2.7-9.2,4.8-14.1,6.7c-4.9,1.9-9.8,3.4-14.9,4.6
					c-5.1,1.2-10.2,2.1-15.4,2.6C228.3,417.8,223,418,217.9,417.5z"/>
				<path class="st393" d="M225.7,417.7c2.6-0.1,5.2-0.3,7.8-0.6c5.2-0.6,10.3-1.4,15.3-2.7c5.1-1.2,10-2.7,14.9-4.5
					c2.4-0.9,4.8-1.9,7.2-2.9c0.9-0.4,1.8-0.8,2.7-1.3c1.4-0.7,2.8-1.5,4.2-2.3c-2.3,1.3-4.6,2.4-7,3.4c-2.4,1.1-4.8,2-7.2,2.9
					c-4.9,1.8-9.8,3.3-14.9,4.5c-5,1.2-10.2,2.1-15.3,2.7c-2.6,0.3-5.2,0.5-7.8,0.6c-2.6,0.1-5.2,0.1-7.8,0c1.6,0.1,3.2,0.2,4.8,0.3
					C223.7,417.7,224.7,417.7,225.7,417.7z"/>
			</g>
			<g>
				<g>
					<path class="st394" d="M231,416.8c-0.3,1.2-1.3,2.3-2.1,2.2c-1.4-0.1-3.1-0.4-4.9-0.6c-1.1-0.2-2-0.9-1.8-1.8l0,0
						c0.2-0.9,1.2-1.9,2.1-2c1.5-0.1,3.1,0,4.9,0C230.3,414.7,231.3,415.6,231,416.8L231,416.8z"/>
					<path class="st394" d="M223.5,418.3c0.2,0.1,0.4,0.1,0.6,0.1c0.7,0.1,1.4,0.2,2.1,0.3c0.5-1.1,1.5-2.8,2-4.1
						c-0.6,0-1.2,0-1.8,0C225.5,415.8,224.2,417.4,223.5,418.3z"/>
					<path class="st395" d="M228.8,419.5c-1.4-0.2-3-0.5-4.8-0.7c-1.3-0.2-2.5-1-2.1-2.1c0.3-1.1,1.5-2.3,2.5-2.4
						c1.4-0.1,3.1-0.1,4.7,0c1.3,0.1,2.6,1.1,2.2,2.6C230.9,418.3,229.8,419.6,228.8,419.5z M224.3,415.1c-0.7,0-1.5,0.8-1.7,1.6
						c-0.2,0.8,0.5,1.3,1.4,1.4c1.8,0.2,3.5,0.4,5,0.5c0.7,0.1,1.5-0.8,1.7-1.8c0.2-1-0.5-1.7-1.4-1.7
						C227.5,415,225.9,415,224.3,415.1z"/>
				</g>
				<g>
					<path class="st396" d="M222.8,417.5c-0.2-0.2-0.3-0.5-0.2-0.8c0.2-0.8,0.9-1.5,1.7-1.6c1.5-0.1,3.2,0,4.9,0
						c0.4,0,0.8,0.2,1.1,0.5c-0.3-0.4-0.7-0.7-1.2-0.7c-1.7,0-3.4-0.1-4.9,0c-0.7,0-1.5,0.8-1.7,1.6
						C222.4,416.9,222.6,417.2,222.8,417.5z"/>
					<path class="st396" d="M230.9,415c0.3,0.4,0.5,1,0.3,1.6c-0.4,1.5-1.5,2.8-2.5,2.7c-1.4-0.2-3.1-0.4-4.9-0.7
						c-0.7-0.1-1.3-0.4-1.7-0.7c0.4,0.4,1.1,0.8,1.9,0.9c1.8,0.2,3.5,0.5,4.8,0.7c0.9,0.2,2.1-1.2,2.5-2.7
						C231.5,416.1,231.3,415.5,230.9,415z"/>
				</g>
			</g>
			<g>
				<g>
					<path class="st394" d="M253.8,412.5c0.3,1.2-0.5,2.4-1.7,2.7l-5.8,1.4c-1.2,0.3-2.4-0.5-2.7-1.7l0,0c-0.3-1.2,0.5-2.4,1.7-2.7
						l5.8-1.4C252.3,410.6,253.5,411.3,253.8,412.5L253.8,412.5z"/>
					<path class="st394" d="M245.7,416.6c0.2,0,0.4,0,0.6,0l2.3-0.5c0.3-1.5,0.8-3.4,1.2-4.9l-2.2,0.5
						C247,413.2,246.2,415.1,245.7,416.6z"/>
					<path class="st395" d="M252.2,415.7l-5.8,1.4c-1.4,0.3-2.9-0.6-3.2-2c-0.3-1.4,0.6-2.9,2-3.2l5.8-1.4c1.4-0.3,2.9,0.6,3.2,2
						C254.5,413.9,253.7,415.3,252.2,415.7z M245.4,412.7c-1,0.2-1.6,1.2-1.3,2.1c0.2,1,1.2,1.6,2.1,1.3l5.8-1.4
						c1-0.2,1.6-1.2,1.3-2.1c-0.2-1-1.2-1.6-2.1-1.3L245.4,412.7z"/>
				</g>
				<g>
					<path class="st396" d="M244.7,415.8c-0.3-0.2-0.5-0.6-0.6-1c-0.2-1,0.4-1.9,1.3-2.1l5.8-1.4c0.5-0.1,0.9,0,1.3,0.2
						c-0.4-0.3-1-0.5-1.5-0.4l-5.8,1.4c-1,0.2-1.6,1.2-1.3,2.1C244,415.2,244.3,415.6,244.7,415.8z"/>
					<path class="st396" d="M253,410.8c0.5,0.4,0.8,0.9,0.9,1.5c0.3,1.4-0.6,2.9-2,3.2l-5.8,1.4c-0.7,0.2-1.5,0-2.1-0.4
						c0.6,0.5,1.5,0.7,2.3,0.5l5.8-1.4c1.4-0.3,2.3-1.8,2-3.2C254,411.7,253.6,411.2,253,410.8z"/>
				</g>
			</g>
			<g>
				<g>
					<path class="st394" d="M263.9,409.1c0.8,1,2.2,1.5,2.9,1.1c1.2-0.7,2.6-1.7,4.1-2.7c0.9-0.6,1.4-1.7,0.8-2.4l0,0
						c-0.6-0.7-1.9-1.2-2.8-0.8c-1.4,0.6-2.8,1.4-4.3,2.2C263.7,406.8,263.2,408.1,263.9,409.1L263.9,409.1z"/>
					<path class="st394" d="M271.4,407c-0.1,0.1-0.3,0.3-0.4,0.4c-0.6,0.4-1.2,0.8-1.7,1.1c-1-0.8-2.6-1.8-3.6-2.7
						c0.6-0.3,1.1-0.6,1.6-0.8C268.5,405.7,270.3,406.6,271.4,407z"/>
					<path class="st395" d="M263.6,409.2c-1-1.1-0.3-2.6,0.8-3.3c1.5-0.8,2.9-1.6,4.3-2.1c1-0.4,2.6,0.2,3.3,1c0.8,0.8,0.1,2-1,2.8
						c-1.5,1-2.9,2-4,2.8C266.3,411,264.7,410.4,263.6,409.2z M264.7,406.7c-0.8,0.4-1.1,1.4-0.5,2.2c0.6,0.8,1.7,1.2,2.3,0.8
						c1.3-0.8,2.7-1.7,4.2-2.7c0.7-0.5,1.1-1.3,0.6-1.9c-0.5-0.6-1.5-0.9-2.2-0.6C267.8,405.1,266.3,405.9,264.7,406.7z"/>
				</g>
				<g>
					<path class="st396" d="M271.6,406c0.1-0.3,0-0.6-0.2-0.8c-0.5-0.6-1.5-0.9-2.2-0.6c-1.4,0.6-2.9,1.4-4.4,2.2
						c-0.4,0.2-0.6,0.5-0.7,0.9c0.1-0.5,0.4-0.9,0.8-1.1c1.5-0.8,3-1.6,4.4-2.2c0.7-0.3,1.7,0.1,2.2,0.7
						C271.6,405.3,271.7,405.7,271.6,406z"/>
					<path class="st396" d="M263.2,407.4c-0.1,0.5,0,1.1,0.4,1.6c1,1.2,2.6,1.8,3.4,1.3c1.2-0.8,2.6-1.8,4.1-2.8
						c0.6-0.4,1-0.9,1.2-1.4c-0.1,0.6-0.6,1.2-1.3,1.6c-1.5,1-2.9,2-4,2.8c-0.8,0.6-2.4-0.1-3.4-1.3
						C263.2,408.6,263.1,408,263.2,407.4z"/>
				</g>
			</g>
		</g>
		<g>
			<g>
				<path class="st397" d="M247.6,429.5c0.1,0.8-0.9,1.7-2.4,1.9c-1.5,0.2-2.7-0.2-2.9-1c-0.1-0.8,0.9-1.7,2.4-1.9
					C246.2,428.2,247.4,428.6,247.6,429.5z"/>
				<path class="st398" d="M247.2,429.5c0.1,0.7-0.8,1.5-2.1,1.7c-1.3,0.2-2.4-0.2-2.5-0.9c-0.1-0.7,0.8-1.5,2.1-1.7
					C246,428.4,247.1,428.8,247.2,429.5z"/>
				<path class="st398" d="M245.5,429.4c0.1,0.3-0.5,0.2-1.2,0.3c-0.7,0.1-1.2,0.4-1.2,0.1c-0.1-0.3,0.4-0.7,1.1-0.8
					C244.9,428.9,245.5,429.1,245.5,429.4z"/>
				<path class="st399" d="M247.2,428.9c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.8-0.9,1.7-2.4,1.9c-1.1,0.2-2,0-2.5-0.5
					c0.5,0.5,1.5,0.7,2.6,0.5c1.5-0.2,2.5-1.1,2.4-1.9C247.5,429.2,247.4,429,247.2,428.9z"/>
				<path class="st400" d="M246.8,428.9c0,0.1,0,0.1,0.1,0.2c0.1,0.7-0.8,1.5-2.1,1.7c-0.7,0.1-1.4,0-1.9-0.2
					c0.3,0.5,1.3,0.8,2.4,0.6c1.3-0.2,2.2-1,2.1-1.7C247.2,429.3,247,429,246.8,428.9z"/>
			</g>
			<g>
				<path class="st397" d="M262.3,425.6c0.2,0.8-0.8,1.7-2.2,2.1c-1.4,0.4-2.8,0-3-0.8c-0.2-0.8,0.8-1.7,2.2-2.1
					C260.8,424.4,262.2,424.8,262.3,425.6z"/>
				<path class="st398" d="M262,425.7c0.2,0.7-0.7,1.5-2,1.8c-1.2,0.3-2.4,0-2.6-0.7c-0.2-0.7,0.7-1.5,2-1.8
					C260.7,424.7,261.8,425,262,425.7z"/>
				<path class="st398" d="M260.3,425.7c0.1,0.3-0.5,0.3-1.2,0.4c-0.7,0.2-1.2,0.5-1.2,0.2c-0.1-0.3,0.4-0.7,1.1-0.9
					C259.6,425.3,260.2,425.4,260.3,425.7z"/>
				<path class="st399" d="M262,425.1c0.1,0.1,0.2,0.3,0.3,0.5c0.2,0.8-0.8,1.7-2.2,2.1c-1.1,0.3-2,0.1-2.6-0.3
					c0.5,0.5,1.5,0.6,2.7,0.3c1.4-0.4,2.4-1.3,2.2-2.1C262.3,425.4,262.2,425.2,262,425.1z"/>
				<path class="st400" d="M261.5,425.1c0,0.1,0.1,0.1,0.1,0.2c0.2,0.7-0.7,1.6-2,1.9c-0.7,0.2-1.4,0.2-1.9,0
					c0.4,0.5,1.4,0.7,2.4,0.4c1.2-0.3,2.1-1.1,2-1.8C262,425.4,261.8,425.2,261.5,425.1z"/>
			</g>
			<g>
				<path class="st397" d="M273.8,421.3c0.3,0.8-0.6,1.8-1.9,2.2c-1.3,0.4-2.5,0.1-2.8-0.7c-0.3-0.8,0.6-1.8,1.9-2.2
					C272.3,420.2,273.5,420.5,273.8,421.3z"/>
				<path class="st398" d="M273.5,421.4c0.2,0.7-0.5,1.5-1.6,1.9c-1.1,0.4-2.2,0.1-2.4-0.6c-0.2-0.7,0.5-1.5,1.6-1.9
					C272.2,420.5,273.3,420.7,273.5,421.4z"/>
				<path class="st398" d="M271.9,421.5c0.1,0.3-0.4,0.3-1,0.5c-0.6,0.2-1,0.5-1.1,0.2c-0.1-0.3,0.3-0.7,0.9-0.9
					C271.3,421.1,271.8,421.2,271.9,421.5z"/>
				<path class="st399" d="M273.4,420.8c0.1,0.1,0.3,0.3,0.3,0.4c0.3,0.8-0.6,1.8-1.9,2.2c-0.9,0.3-1.9,0.2-2.4-0.1
					c0.5,0.4,1.5,0.5,2.5,0.2c1.3-0.4,2.1-1.4,1.9-2.2C273.7,421.1,273.6,420.9,273.4,420.8z"/>
				<path class="st400" d="M273,420.8c0,0.1,0.1,0.1,0.1,0.2c0.2,0.7-0.5,1.6-1.7,1.9c-0.6,0.2-1.3,0.2-1.8,0.1
					c0.4,0.5,1.3,0.6,2.3,0.3c1.1-0.4,1.8-1.2,1.6-1.9C273.4,421.2,273.2,421,273,420.8z"/>
			</g>
			<g>
				<path class="st397" d="M232.7,431c0.1,0.8-1.1,1.6-2.6,1.7c-1.5,0.1-2.7-0.5-2.8-1.3c-0.1-0.8,1.1-1.6,2.6-1.7
					C231.4,429.6,232.6,430.2,232.7,431z"/>
				<path class="st398" d="M232.4,431.1c0.1,0.7-0.9,1.4-2.2,1.5c-1.3,0.1-2.4-0.4-2.4-1.1c-0.1-0.7,0.9-1.4,2.2-1.5
					C231.2,429.8,232.3,430.3,232.4,431.1z"/>
				<path class="st398" d="M230.7,430.8c0,0.3-0.5,0.2-1.2,0.2c-0.7,0-1.2,0.3-1.2,0c0-0.3,0.5-0.6,1.2-0.7
					C230.1,430.3,230.6,430.5,230.7,430.8z"/>
				<path class="st399" d="M232.4,430.4c0.1,0.2,0.2,0.3,0.2,0.5c0.1,0.8-1.1,1.6-2.6,1.7c-1.1,0.1-2-0.2-2.5-0.7
					c0.4,0.5,1.4,0.9,2.6,0.8c1.5-0.1,2.6-0.9,2.6-1.7C232.7,430.8,232.6,430.6,232.4,430.4z"/>
				<path class="st400" d="M231.9,430.4c0,0.1,0,0.1,0,0.2c0.1,0.7-1,1.4-2.3,1.5c-0.7,0.1-1.4-0.1-1.9-0.3c0.3,0.5,1.2,0.9,2.3,0.8
					c1.3-0.1,2.3-0.8,2.2-1.5C232.3,430.8,232.2,430.6,231.9,430.4z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path class="st401" d="M207,217.4c0,9.7-7.8,17.5-17.5,17.5c-9.7,0-17.5-7.8-17.5-17.5c0-9.7,7.8-17.5,17.5-17.5
					C199.2,199.9,207,207.7,207,217.4z"/>
			</g>

				<ellipse transform="matrix(0.1678 -0.9858 0.9858 0.1678 -56.5458 367.7807)" class="st402" cx="189.6" cy="217.4" rx="12.6" ry="12.6"/>
			<g>

					<ellipse transform="matrix(0.1678 -0.9858 0.9858 0.1678 -56.5494 367.7747)" class="st403" cx="189.6" cy="217.4" rx="14.4" ry="14.4"/>
				<g>
					<path class="st404" d="M176.4,217.9c0-7.4,6-13.5,13.5-13.5c4.6,0,8.6,2.3,11.1,5.8c-2.4-3.8-6.6-6.2-11.4-6.2
						c-7.4,0-13.5,6-13.5,13.5c0,2.9,0.9,5.5,2.4,7.7C177.2,223,176.4,220.5,176.4,217.9z"/>
					<path class="st405" d="M201.8,211.8c0.6,1.6,1,3.3,1,5c0,7.4-6,13.5-13.5,13.5c-5.5,0-10.2-3.3-12.3-7.9
						c2,4.9,6.8,8.4,12.5,8.4c7.4,0,13.5-6,13.5-13.5C203,215.4,202.6,213.5,201.8,211.8z"/>
				</g>
			</g>
			<g>
				<g>
					<path class="st406" d="M189.6,232c-8.1,0-14.7-6.6-14.7-14.7c0-8.1,6.6-14.7,14.7-14.7c8.1,0,14.7,6.6,14.7,14.7
						C204.2,225.5,197.6,232,189.6,232z M189.6,202.9c-8,0-14.5,6.5-14.5,14.5c0,8,6.5,14.5,14.5,14.5c8,0,14.5-6.5,14.5-14.5
						C204,209.4,197.5,202.9,189.6,202.9z"/>
				</g>
				<g>
					<path class="st401" d="M204.1,217.4c0-4.5-2.1-8.5-5.3-11.2C202,208.9,204,212.9,204.1,217.4c0,7.9-6.5,14.4-14.5,14.4
						c-3.5,0-6.7-1.2-9.2-3.3c2.5,2.1,5.7,3.3,9.2,3.3C197.6,231.9,204.1,225.4,204.1,217.4z"/>
					<path class="st401" d="M174.9,217.4c0-8.1,6.6-14.7,14.7-14.7c3.5,0,6.8,1.2,9.3,3.3c-2.5-2.1-5.8-3.4-9.3-3.4
						C181.5,202.7,174.9,209.3,174.9,217.4c0,4.5,2,8.6,5.3,11.3C177,226,174.9,222,174.9,217.4z"/>
				</g>
			</g>
			<defs>
				<filter id="Adobe_OpacityMaskFilter_116_" filterUnits="userSpaceOnUse" x="181.2" y="205" width="17.1" height="3">
					<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
					<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
				</filter>
			</defs>
			<mask maskUnits="userSpaceOnUse" x="181.2" y="205" width="17.1" height="3" id="SVGID_181_">
				<g class="st407">
					<defs>
						<filter id="Adobe_OpacityMaskFilter_117_" filterUnits="userSpaceOnUse" x="181.2" y="205" width="17.1" height="3">
							<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
							<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
						</filter>
					</defs>
					<mask maskUnits="userSpaceOnUse" x="181.2" y="205" width="17.1" height="3" id="SVGID_181_">
						<g class="st408">
						</g>
					</mask>
					<linearGradient id="SVGID_182_" gradientUnits="userSpaceOnUse" x1="189.7764" y1="205.2631" x2="189.7764" y2="207.476">
						<stop  offset="5.780350e-02" style={{stopColor:"#696969"}}/>
						<stop  offset="0.5155" style={{stopColor:"#B5B5B5"}}/>
						<stop  offset="0.9769" style={{stopColor:"#FFFFFF"}}/>
					</linearGradient>
					<path class="st409" d="M198.3,207.9c0,0.6-3.8-0.6-8.6-0.6c-4.7,0-8.6,1.2-8.6,0.6c0-0.6,3.8-2.9,8.6-2.9
						C194.5,205,198.3,207.3,198.3,207.9z"/>
				</g>
			</mask>
			<linearGradient id="SVGID_183_" gradientUnits="userSpaceOnUse" x1="189.7764" y1="205.2631" x2="189.7764" y2="207.476">
				<stop  offset="5.780350e-02" style={{stopColor:"#FFF384"}}/>
				<stop  offset="0.4532" style={{stopColor:"#FCD44A"}}/>
				<stop  offset="0.9769" style={{stopColor:"#F8AE00"}}/>
			</linearGradient>
			<path class="st410" d="M198.3,207.9c0,0.6-3.8-0.6-8.6-0.6c-4.7,0-8.6,1.2-8.6,0.6c0-0.6,3.8-2.9,8.6-2.9
				C194.5,205,198.3,207.3,198.3,207.9z"/>
			<defs>
				<filter id="Adobe_OpacityMaskFilter_118_" filterUnits="userSpaceOnUse" x="178.8" y="204.6" width="21.9" height="7.3">
					<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
					<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
				</filter>
			</defs>
			<mask maskUnits="userSpaceOnUse" x="178.8" y="204.6" width="21.9" height="7.3" id="SVGID_184_">
				<g class="st411">
					<defs>
						<filter id="Adobe_OpacityMaskFilter_119_" filterUnits="userSpaceOnUse" x="178.8" y="204.6" width="21.9" height="7.3">
							<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
							<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
						</filter>
					</defs>
					<mask maskUnits="userSpaceOnUse" x="178.8" y="204.6" width="21.9" height="7.3" id="SVGID_184_">
						<g class="st412">
						</g>
					</mask>
					<linearGradient id="SVGID_185_" gradientUnits="userSpaceOnUse" x1="189.7768" y1="205.2277" x2="189.7768" y2="210.5952">
						<stop  offset="5.780350e-02" style={{stopColor:"#696969"}}/>
						<stop  offset="0.5155" style={{stopColor:"#B5B5B5"}}/>
						<stop  offset="0.9769" style={{stopColor:"#FFFFFF"}}/>
					</linearGradient>
					<path class="st413" d="M200.7,211.2c0,1.6-4.9-0.2-11-0.2s-11,1.9-11,0.2s4.9-6.7,11-6.7S200.7,209.6,200.7,211.2z"/>
				</g>
			</mask>
			<linearGradient id="SVGID_186_" gradientUnits="userSpaceOnUse" x1="189.7768" y1="205.2277" x2="189.7768" y2="210.5952">
				<stop  offset="5.780350e-02" style={{stopColor:"#FFF384"}}/>
				<stop  offset="0.4532" style={{stopColor:"#FCD44A"}}/>
				<stop  offset="0.9769" style={{stopColor:"#F8AE00"}}/>
			</linearGradient>
			<path class="st414" d="M200.7,211.2c0,1.6-4.9-0.2-11-0.2s-11,1.9-11,0.2s4.9-6.7,11-6.7S200.7,209.6,200.7,211.2z"/>
			<defs>
				<filter id="Adobe_OpacityMaskFilter_120_" filterUnits="userSpaceOnUse" x="178.3" y="223.5" width="22.4" height="6.5">
					<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
					<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
				</filter>
			</defs>
			<mask maskUnits="userSpaceOnUse" x="178.3" y="223.5" width="22.4" height="6.5" id="SVGID_187_">
				<g class="st415">
					<defs>
						<filter id="Adobe_OpacityMaskFilter_121_" filterUnits="userSpaceOnUse" x="178.3" y="223.5" width="22.4" height="6.5">
							<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
							<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
						</filter>
					</defs>
					<mask maskUnits="userSpaceOnUse" x="178.3" y="223.5" width="22.4" height="6.5" id="SVGID_187_">
						<g class="st416">
						</g>
					</mask>

						<linearGradient id="SVGID_188_" gradientUnits="userSpaceOnUse" x1="547.2065" y1="1265.3687" x2="547.2065" y2="1270.1392" gradientTransform="matrix(-1 0 0 -1 736.6699 1492.3066)">
						<stop  offset="5.780350e-02" style={{stopColor:"#696969"}}/>
						<stop  offset="0.5155" style={{stopColor:"#B5B5B5"}}/>
						<stop  offset="0.9769" style={{stopColor:"#FFFFFF"}}/>
					</linearGradient>
					<path class="st417" d="M178.3,223.9c0-1.5,5,2.5,11.2,2.5c6.2,0,11.2-4,11.2-2.5c0,1.5-5,6.2-11.2,6.2
						C183.3,230.1,178.3,225.4,178.3,223.9z"/>
				</g>
			</mask>

				<linearGradient id="SVGID_189_" gradientUnits="userSpaceOnUse" x1="547.2065" y1="1262.8546" x2="547.2065" y2="1267.6251" gradientTransform="matrix(-1 0 0 -1 736.6699 1492.3066)">
				<stop  offset="5.780350e-02" style={{stopColor:"#FFF384"}}/>
				<stop  offset="0.4532" style={{stopColor:"#FCD44A"}}/>
				<stop  offset="0.9769" style={{stopColor:"#F8AE00"}}/>
			</linearGradient>
			<path class="st418" d="M178.3,223.9c0-1.5,5,2.5,11.2,2.5c6.2,0,11.2-4,11.2-2.5c0,1.5-5,6.2-11.2,6.2
				C183.3,230.1,178.3,225.4,178.3,223.9z"/>
			<g>
				<linearGradient id="SVGID_190_" gradientUnits="userSpaceOnUse" x1="189.8013" y1="208.8411" x2="189.8013" y2="220.3281">
					<stop  offset="5.780350e-02" style={{stopColor:"#FFF7AD"}}/>
					<stop  offset="0.4523" style={{stopColor:"#FCDC72"}}/>
					<stop  offset="0.9769" style={{stopColor:"#F9BB29"}}/>
				</linearGradient>
				<path class="st419" d="M189.8,207.1c1.7,0,3.8,5.4,3.8,5.4s5.8,0.3,6.3,1.9c0.5,1.6-4,5.3-4,5.3s1.5,5.6,0.1,6.6
					c-1.3,1-6.2-2.2-6.2-2.2s-4.9,3.1-6.2,2.2c-1.3-1,0.1-6.6,0.1-6.6s-4.5-3.7-4-5.3c0.5-1.6,6.3-1.9,6.3-1.9
					S188.1,207.1,189.8,207.1z"/>
				<g>
					<path class="st420" d="M183.9,219.1c0,0-2.9-2.2-4-3.8c0.5,1.3,2.9,3.5,3.9,4.4C183.7,219.5,183.9,219.1,183.9,219.1z"/>
					<path class="st420" d="M199.9,214.4c-0.1-0.2-0.3-0.4-0.5-0.6c1.3,1.4-4,5.5-4,5.5s1.5,5.1,0.1,6c-1.3,1-5.8-2.2-5.8-2.2
						s-5.2,3-6.7,2.2c0.1,0.4,0.2,0.7,0.5,0.9c1.3,1,6.2-2.2,6.2-2.2s4.9,3.1,6.2,2.2c1.3-1-0.1-6.6-0.1-6.6S200.4,216,199.9,214.4z
						"/>
				</g>
				<path class="st420" d="M189.8,207.1c-1.7,0-3.8,5.4-3.8,5.4s-5.8,0.3-6.3,1.9l0,0c0.2-0.2,0.4-0.4,0.6-0.5
					c0.2-0.1,0.5-0.2,0.8-0.2c0.5-0.1,1.1-0.2,1.6-0.3c0.6-0.1,1.1-0.1,1.7-0.2c0.5-0.1,1.1-0.1,1.7-0.1l0.4,0l0.1-0.4
					c0.2-0.5,0.4-1,0.6-1.5c0.2-0.5,0.4-1,0.7-1.5c0.2-0.5,0.5-1,0.8-1.5c0.1-0.2,0.3-0.5,0.5-0.7
					C189.3,207.4,189.5,207.2,189.8,207.1L189.8,207.1z"/>
				<path class="st420" d="M190.4,207.7c0.1,0.2,0.3,0.4,0.4,0.7l0.8,1.4l0.4,0.7c0.2,0.2,0.3,0.5,0.5,0.7c0.3,0.5,0.7,0.9,1.1,1.4
					l0,0c0,0-2.1-5.4-3.8-5.4l0,0C190.1,207.2,190.2,207.4,190.4,207.7z"/>
				<path class="st420" d="M195.2,213.2c0.3,0.1,0.5,0.1,0.8,0.2l0.8,0.2l1.6,0.3c0.3,0.1,0.5,0.1,0.8,0.2c0.3,0.1,0.5,0.2,0.7,0.4
					l0,0c-0.5-1.6-6.3-1.9-6.3-1.9l0,0C194.1,212.8,194.7,213,195.2,213.2z"/>
			</g>
			<linearGradient id="SVGID_191_" gradientUnits="userSpaceOnUse" x1="201.5791" y1="229.4572" x2="201.5791" y2="227.19">
				<stop  offset="5.780350e-02" style={{stopColor:"#FFF384"}}/>
				<stop  offset="0.4532" style={{stopColor:"#FCD44A"}}/>
				<stop  offset="0.9769" style={{stopColor:"#F8AE00"}}/>
			</linearGradient>
			<path class="st421" d="M203.4,228l-2.1-2c-0.5,0.6-1,1.2-1.6,1.8l2.1,2C202.4,229.2,202.9,228.7,203.4,228z"/>
			<path class="st422" d="M176.8,205.4l2.7,3c0.5-0.6,1.1-1.1,1.7-1.6l-2.7-3C177.9,204.3,177.3,204.8,176.8,205.4z"/>
			<linearGradient id="SVGID_192_" gradientUnits="userSpaceOnUse" x1="189.5537" y1="233.3215" x2="189.5537" y2="223.3311">
				<stop  offset="5.780350e-02" style={{stopColor:"#FFF384"}}/>
				<stop  offset="0.4532" style={{stopColor:"#FCD44A"}}/>
				<stop  offset="0.9769" style={{stopColor:"#F8AE00"}}/>
			</linearGradient>
			<path class="st423" d="M204.3,219.7c-3.4,1.5-9.7,2.6-14.8,2.6c-5,0-11.4-1.3-14.9-2.8c-0.7-0.3-2.6-1.5-2.6-1.5
				c0.3,9.4,8,16.9,17.5,16.9c9.4,0,17.1-7.5,17.5-16.8C207,218,205,219.4,204.3,219.7z"/>
			<path class="st422" d="M189.6,211.2c6.6,0,12.7,1.6,17.4,4.1c-1-8.7-8.4-15.4-17.4-15.4c-9,0-16.3,6.7-17.4,15.4
				C176.9,212.7,182.9,211.2,189.6,211.2z"/>
			<defs>
				<filter id="Adobe_OpacityMaskFilter_122_" filterUnits="userSpaceOnUse" x="182.2" y="203.5" width="10.5" height="10.5">
					<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
					<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
				</filter>
			</defs>
			<mask maskUnits="userSpaceOnUse" x="182.2" y="203.5" width="10.5" height="10.5" id="SVGID_193_">
				<g class="st424">
					<defs>
						<filter id="Adobe_OpacityMaskFilter_123_" filterUnits="userSpaceOnUse" x="182.2" y="203.5" width="10.5" height="10.5">
							<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
							<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
						</filter>
					</defs>
					<mask maskUnits="userSpaceOnUse" x="182.2" y="203.5" width="10.5" height="10.5" id="SVGID_193_">
						<g class="st425">
						</g>
					</mask>
					<radialGradient id="SVGID_194_" cx="187.4057" cy="208.7842" r="5.2449" gradientUnits="userSpaceOnUse">
						<stop  offset="5.780350e-02" style={{stopColor:"#FFFFFF"}}/>
						<stop  offset="0.6956" style={{stopColor:"#4B4B4B"}}/>
						<stop  offset="0.9769" style={{stopColor:"#000000"}}/>
					</radialGradient>
					<path class="st426" d="M192.7,208.8c0,2.9-2.3,5.2-5.2,5.2c-2.9,0-5.2-2.3-5.2-5.2c0-2.9,2.3-5.2,5.2-5.2
						C190.3,203.5,192.7,205.9,192.7,208.8z"/>
				</g>
			</mask>
			<radialGradient id="SVGID_195_" cx="187.4057" cy="208.7842" r="5.2449" gradientUnits="userSpaceOnUse">
				<stop  offset="5.780350e-02" style={{stopColor:"#FFF384"}}/>
				<stop  offset="0.4532" style={{stopColor:"#FCD44A"}}/>
				<stop  offset="0.9769" style={{stopColor:"#F8AE00"}}/>
			</radialGradient>
			<path class="st427" d="M192.7,208.8c0,2.9-2.3,5.2-5.2,5.2c-2.9,0-5.2-2.3-5.2-5.2c0-2.9,2.3-5.2,5.2-5.2
				C190.3,203.5,192.7,205.9,192.7,208.8z"/>
			<g>

				<defs>
					<filter id="Adobe_OpacityMaskFilter_124_" filterUnits="userSpaceOnUse" x="189.6" y="216.7" width="13.9" height="13.9">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="189.6" y="216.7" width="13.9" height="13.9" id="SVGID_196_">
					
				</mask>
				
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path class="st401" d="M768.7,80.9c0,9.7-7.8,17.5-17.5,17.5c-9.7,0-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5
					C760.8,63.4,768.7,71.3,768.7,80.9z"/>
			</g>
			<path class="st402" d="M763.8,80.9c0,7-5.7,12.6-12.6,12.6c-7,0-12.6-5.7-12.6-12.6c0-7,5.7-12.6,12.6-12.6
				C758.2,68.3,763.8,73.9,763.8,80.9z"/>
			<g>
				<path class="st403" d="M765.6,80.9c0,7.9-6.4,14.4-14.4,14.4c-7.9,0-14.4-6.4-14.4-14.4c0-7.9,6.4-14.4,14.4-14.4
					C759.1,66.5,765.6,73,765.6,80.9z"/>
				<g>
					<path class="st404" d="M738,81.4c0-7.4,6-13.5,13.5-13.5c4.6,0,8.6,2.3,11.1,5.8c-2.4-3.8-6.6-6.2-11.4-6.2
						c-7.4,0-13.5,6-13.5,13.5c0,2.9,0.9,5.5,2.4,7.7C738.8,86.5,738,84.1,738,81.4z"/>
					<path class="st405" d="M763.4,75.4c0.6,1.6,1,3.3,1,5c0,7.4-6,13.5-13.5,13.5c-5.5,0-10.2-3.3-12.3-7.9c2,4.9,6.8,8.4,12.5,8.4
						c7.4,0,13.5-6,13.5-13.5C764.6,78.9,764.2,77.1,763.4,75.4z"/>
				</g>
			</g>
			<g>
				<g>
					<path class="st406" d="M751.2,95.6c-8.1,0-14.7-6.6-14.7-14.7c0-8.1,6.6-14.7,14.7-14.7c8.1,0,14.7,6.6,14.7,14.7
						C765.8,89,759.3,95.6,751.2,95.6z M751.2,66.5c-8,0-14.5,6.5-14.5,14.5c0,8,6.5,14.5,14.5,14.5c8,0,14.5-6.5,14.5-14.5
						C765.6,72.9,759.2,66.5,751.2,66.5z"/>
				</g>
				<g>
					<path class="st401" d="M765.7,81c0-4.5-2.1-8.5-5.3-11.2C763.6,72.4,765.6,76.4,765.7,81c0,7.9-6.5,14.4-14.5,14.4
						c-3.5,0-6.7-1.2-9.2-3.3c2.5,2.1,5.7,3.3,9.2,3.3C759.2,95.4,765.7,88.9,765.7,81z"/>
					<path class="st401" d="M736.6,81c0-8.1,6.6-14.7,14.7-14.7c3.5,0,6.8,1.2,9.3,3.3c-2.5-2.1-5.8-3.4-9.3-3.4
						C743.1,66.3,736.5,72.8,736.6,81c0,4.5,2.1,8.6,5.3,11.3C738.6,89.6,736.6,85.5,736.6,81z"/>
				</g>
			</g>
			<defs>
				<filter id="Adobe_OpacityMaskFilter_125_" filterUnits="userSpaceOnUse" x="742.8" y="68.5" width="17.1" height="3">
					<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
					<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
				</filter>
			</defs>
			<mask maskUnits="userSpaceOnUse" x="742.8" y="68.5" width="17.1" height="3" id="SVGID_197_">
				<g class="st430">
					<defs>
						<filter id="Adobe_OpacityMaskFilter_126_" filterUnits="userSpaceOnUse" x="742.8" y="68.5" width="17.1" height="3">
							<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
							<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
						</filter>
					</defs>
					<mask maskUnits="userSpaceOnUse" x="742.8" y="68.5" width="17.1" height="3" id="SVGID_197_">
						<g class="st431">
						</g>
					</mask>
					<linearGradient id="SVGID_198_" gradientUnits="userSpaceOnUse" x1="751.3925" y1="68.8075" x2="751.3925" y2="71.0209">
						<stop  offset="5.780350e-02" style={{stopColor:"#696969"}}/>
						<stop  offset="0.5155" style={{stopColor:"#B5B5B5"}}/>
						<stop  offset="0.9769" style={{stopColor:"#FFFFFF"}}/>
					</linearGradient>
					<path class="st432" d="M760,71.4c0,0.6-3.8-0.6-8.6-0.6c-4.7,0-8.6,1.2-8.6,0.6c0-0.6,3.8-2.9,8.6-2.9
						C756.1,68.5,760,70.8,760,71.4z"/>
				</g>
			</mask>
			<linearGradient id="SVGID_199_" gradientUnits="userSpaceOnUse" x1="751.3925" y1="68.8075" x2="751.3925" y2="71.0209">
				<stop  offset="5.780350e-02" style={{stopColor:"#FFF384"}}/>
				<stop  offset="0.4532" style={{stopColor:"#FCD44A"}}/>
				<stop  offset="0.9769" style={{stopColor:"#F8AE00"}}/>
			</linearGradient>
			<path class="st433" d="M760,71.4c0,0.6-3.8-0.6-8.6-0.6c-4.7,0-8.6,1.2-8.6,0.6c0-0.6,3.8-2.9,8.6-2.9
				C756.1,68.5,760,70.8,760,71.4z"/>
			<defs>
				<filter id="Adobe_OpacityMaskFilter_127_" filterUnits="userSpaceOnUse" x="740.4" y="68.1" width="21.9" height="7.3">
					<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
					<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
				</filter>
			</defs>
			<mask maskUnits="userSpaceOnUse" x="740.4" y="68.1" width="21.9" height="7.3" id="SVGID_200_">
				<g class="st434">
					<defs>
						<filter id="Adobe_OpacityMaskFilter_128_" filterUnits="userSpaceOnUse" x="740.4" y="68.1" width="21.9" height="7.3">
							<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
							<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
						</filter>
					</defs>
					<mask maskUnits="userSpaceOnUse" x="740.4" y="68.1" width="21.9" height="7.3" id="SVGID_200_">
						<g class="st435">
						</g>
					</mask>
					<linearGradient id="SVGID_201_" gradientUnits="userSpaceOnUse" x1="751.3936" y1="68.7722" x2="751.3936" y2="74.1401">
						<stop  offset="5.780350e-02" style={{stopColor:"#696969"}}/>
						<stop  offset="0.5155" style={{stopColor:"#B5B5B5"}}/>
						<stop  offset="0.9769" style={{stopColor:"#FFFFFF"}}/>
					</linearGradient>
					<path class="st436" d="M762.4,74.8c0,1.6-4.9-0.2-11-0.2s-11,1.9-11,0.2c0-1.6,4.9-6.7,11-6.7S762.4,73.2,762.4,74.8z"/>
				</g>
			</mask>
			<linearGradient id="SVGID_202_" gradientUnits="userSpaceOnUse" x1="751.3936" y1="68.7722" x2="751.3936" y2="74.1401">
				<stop  offset="5.780350e-02" style={{stopColor:"#FFF384"}}/>
				<stop  offset="0.4532" style={{stopColor:"#FCD44A"}}/>
				<stop  offset="0.9769" style={{stopColor:"#F8AE00"}}/>
			</linearGradient>
			<path class="st437" d="M762.4,74.8c0,1.6-4.9-0.2-11-0.2s-11,1.9-11,0.2c0-1.6,4.9-6.7,11-6.7S762.4,73.2,762.4,74.8z"/>
			<defs>
				<filter id="Adobe_OpacityMaskFilter_129_" filterUnits="userSpaceOnUse" x="739.9" y="87.1" width="22.4" height="6.5">
					<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
					<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
				</filter>
			</defs>
			<mask maskUnits="userSpaceOnUse" x="739.9" y="87.1" width="22.4" height="6.5" id="SVGID_203_">
				<g class="st438">
					<defs>
						<filter id="Adobe_OpacityMaskFilter_130_" filterUnits="userSpaceOnUse" x="739.9" y="87.1" width="22.4" height="6.5">
							<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
							<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
						</filter>
					</defs>
					<mask maskUnits="userSpaceOnUse" x="739.9" y="87.1" width="22.4" height="6.5" id="SVGID_203_">
						<g class="st439">
						</g>
					</mask>

						<linearGradient id="SVGID_204_" gradientUnits="userSpaceOnUse" x1="-14.4102" y1="1401.8235" x2="-14.4102" y2="1406.5944" gradientTransform="matrix(-1 0 0 -1 736.6699 1492.3066)">
						<stop  offset="5.780350e-02" style={{stopColor:"#696969"}}/>
						<stop  offset="0.5155" style={{stopColor:"#B5B5B5"}}/>
						<stop  offset="0.9769" style={{stopColor:"#FFFFFF"}}/>
					</linearGradient>
					<path class="st440" d="M739.9,87.4c0-1.5,5,2.5,11.2,2.5c6.2,0,11.2-4,11.2-2.5c0,1.5-5,6.2-11.2,6.2
						C744.9,93.6,739.9,88.9,739.9,87.4z"/>
				</g>
			</mask>

				<linearGradient id="SVGID_205_" gradientUnits="userSpaceOnUse" x1="-14.4102" y1="1399.3093" x2="-14.4102" y2="1404.0802" gradientTransform="matrix(-1 0 0 -1 736.6699 1492.3066)">
				<stop  offset="5.780350e-02" style={{stopColor:"#FFF384"}}/>
				<stop  offset="0.4532" style={{stopColor:"#FCD44A"}}/>
				<stop  offset="0.9769" style={{stopColor:"#F8AE00"}}/>
			</linearGradient>
			<path class="st441" d="M739.9,87.4c0-1.5,5,2.5,11.2,2.5c6.2,0,11.2-4,11.2-2.5c0,1.5-5,6.2-11.2,6.2
				C744.9,93.6,739.9,88.9,739.9,87.4z"/>
			<g>
				<linearGradient id="SVGID_206_" gradientUnits="userSpaceOnUse" x1="751.4179" y1="72.3865" x2="751.4179" y2="83.8731">
					<stop  offset="5.780350e-02" style={{stopColor:"#FFF7AD"}}/>
					<stop  offset="0.4523" style={{stopColor:"#FCDC72"}}/>
					<stop  offset="0.9769" style={{stopColor:"#F9BB29"}}/>
				</linearGradient>
				<path class="st442" d="M751.4,70.6c1.7,0,3.8,5.4,3.8,5.4s5.8,0.3,6.3,1.9c0.5,1.6-4,5.3-4,5.3s1.5,5.6,0.1,6.6
					c-1.3,1-6.2-2.2-6.2-2.2s-4.9,3.1-6.2,2.2c-1.3-1,0.1-6.6,0.1-6.6s-4.5-3.7-4-5.3c0.5-1.6,6.3-1.9,6.3-1.9
					S749.8,70.6,751.4,70.6z"/>
				<g>
					<path class="st420" d="M745.5,82.7c0,0-2.9-2.2-4-3.8c0.5,1.3,2.9,3.5,3.9,4.4C745.4,83,745.5,82.7,745.5,82.7z"/>
					<path class="st420" d="M761.5,77.9c-0.1-0.2-0.3-0.4-0.5-0.6c1.3,1.4-4,5.5-4,5.5s1.5,5.1,0.1,6c-1.3,1-5.8-2.2-5.8-2.2
						s-5.2,3-6.7,2.2c0.1,0.4,0.2,0.7,0.5,0.9c1.3,1,6.2-2.2,6.2-2.2s4.9,3.1,6.2,2.2c1.3-1-0.1-6.6-0.1-6.6S762,79.5,761.5,77.9z"
						/>
				</g>
				<path class="st420" d="M751.4,70.6c-1.7,0-3.8,5.4-3.8,5.4s-5.8,0.3-6.3,1.9l0,0c0.2-0.2,0.4-0.4,0.6-0.5
					c0.2-0.1,0.5-0.2,0.8-0.2c0.5-0.1,1.1-0.2,1.6-0.3c0.6-0.1,1.1-0.1,1.7-0.2c0.5-0.1,1.1-0.1,1.7-0.1l0.4,0l0.1-0.4
					c0.2-0.5,0.4-1,0.6-1.5c0.2-0.5,0.4-1,0.7-1.5c0.2-0.5,0.5-1,0.8-1.5c0.1-0.2,0.3-0.5,0.5-0.7C751,70.9,751.1,70.7,751.4,70.6
					L751.4,70.6z"/>
				<path class="st420" d="M752,71.2c0.1,0.2,0.3,0.4,0.4,0.7l0.8,1.4l0.4,0.7c0.2,0.2,0.3,0.5,0.5,0.7c0.3,0.5,0.7,0.9,1.1,1.4l0,0
					c0,0-2.1-5.4-3.8-5.4l0,0C751.7,70.8,751.8,71,752,71.2z"/>
				<path class="st420" d="M756.8,76.7c0.3,0.1,0.5,0.1,0.8,0.2l0.8,0.2l1.6,0.3c0.3,0.1,0.5,0.1,0.8,0.2c0.3,0.1,0.5,0.2,0.7,0.4
					l0,0c-0.5-1.6-6.3-1.9-6.3-1.9l0,0C755.7,76.4,756.3,76.5,756.8,76.7z"/>
			</g>
			<linearGradient id="SVGID_207_" gradientUnits="userSpaceOnUse" x1="763.1963" y1="93.0025" x2="763.1963" y2="90.7348">
				<stop  offset="5.780350e-02" style={{stopColor:"#FFF384"}}/>
				<stop  offset="0.4532" style={{stopColor:"#FCD44A"}}/>
				<stop  offset="0.9769" style={{stopColor:"#F8AE00"}}/>
			</linearGradient>
			<path class="st443" d="M765,91.6l-2.1-2c-0.5,0.6-1,1.2-1.6,1.8l2.1,2C764,92.8,764.6,92.2,765,91.6z"/>
			<path class="st422" d="M738.4,69l2.7,3c0.5-0.6,1.1-1.1,1.7-1.6l-2.7-3C739.5,67.9,738.9,68.4,738.4,69z"/>
			<linearGradient id="SVGID_208_" gradientUnits="userSpaceOnUse" x1="751.1709" y1="96.8661" x2="751.1709" y2="86.8762">
				<stop  offset="5.780350e-02" style={{stopColor:"#FFF384"}}/>
				<stop  offset="0.4532" style={{stopColor:"#FCD44A"}}/>
				<stop  offset="0.9769" style={{stopColor:"#F8AE00"}}/>
			</linearGradient>
			<path class="st444" d="M765.9,83.3c-3.4,1.5-9.7,2.6-14.8,2.6c-5,0-11.4-1.3-14.9-2.8c-0.7-0.3-2.6-1.5-2.6-1.5
				c0.3,9.4,8,16.9,17.5,16.9c9.4,0,17.1-7.5,17.5-16.8C768.6,81.6,766.7,83,765.9,83.3z"/>
			<path class="st422" d="M751.2,74.7c6.6,0,12.7,1.6,17.4,4.1c-1-8.7-8.4-15.4-17.4-15.4c-9,0-16.3,6.7-17.4,15.4
				C738.5,76.3,744.5,74.7,751.2,74.7z"/>
			<defs>
				<filter id="Adobe_OpacityMaskFilter_131_" filterUnits="userSpaceOnUse" x="743.8" y="67.1" width="10.5" height="10.5">
					<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
					<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
				</filter>
			</defs>
			<mask maskUnits="userSpaceOnUse" x="743.8" y="67.1" width="10.5" height="10.5" id="SVGID_209_">
				<g class="st445">
					<defs>
						<filter id="Adobe_OpacityMaskFilter_132_" filterUnits="userSpaceOnUse" x="743.8" y="67.1" width="10.5" height="10.5">
							<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
							<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
						</filter>
					</defs>
					<mask maskUnits="userSpaceOnUse" x="743.8" y="67.1" width="10.5" height="10.5" id="SVGID_209_">
						<g class="st446">
						</g>
					</mask>
					<radialGradient id="SVGID_210_" cx="749.0225" cy="72.3291" r="5.2449" gradientUnits="userSpaceOnUse">
						<stop  offset="5.780350e-02" style={{stopColor:"#FFFFFF"}}/>
						<stop  offset="0.6956" style={{stopColor:"#4B4B4B"}}/>
						<stop  offset="0.9769" style={{stopColor:"#000000"}}/>
					</radialGradient>
					<path class="st447" d="M754.3,72.3c0,2.9-2.3,5.2-5.2,5.2c-2.9,0-5.2-2.3-5.2-5.2c0-2.9,2.3-5.2,5.2-5.2
						C751.9,67.1,754.3,69.4,754.3,72.3z"/>
				</g>
			</mask>
			<radialGradient id="SVGID_211_" cx="749.0225" cy="72.3291" r="5.2449" gradientUnits="userSpaceOnUse">
				<stop  offset="5.780350e-02" style={{stopColor:"#FFF384"}}/>
				<stop  offset="0.4532" style={{stopColor:"#FCD44A"}}/>
				<stop  offset="0.9769" style={{stopColor:"#F8AE00"}}/>
			</radialGradient>
			<path class="st448" d="M754.3,72.3c0,2.9-2.3,5.2-5.2,5.2c-2.9,0-5.2-2.3-5.2-5.2c0-2.9,2.3-5.2,5.2-5.2
				C751.9,67.1,754.3,69.4,754.3,72.3z"/>
			<g>

					
				<defs>
					<filter id="Adobe_OpacityMaskFilter_133_" filterUnits="userSpaceOnUse" x="751.2" y="80.4" width="13.9" height="13.9">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="751.2" y="80.4" width="13.9" height="13.9" id="SVGID_212_">
					
				</mask>
				<g class="st450">
					
				</g>
			</g>
		</g>
	</g>
	<g>
		<defs>
			<rect id="SVGID_213_" width="800" height="600"/>
		</defs>
		<clipPath id="SVGID_214_">
			<use style={{overflow:"visible"}}/>
		</clipPath>
		<g class="st451">
			<g>
				<defs>
					<filter id="Adobe_OpacityMaskFilter_134_" filterUnits="userSpaceOnUse" x="241.8" y="-42.5" width="188.4" height="199.7">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="241.8" y="-42.5" width="188.4" height="199.7" id="SVGID_215_">
					<g class="st452">
						<defs>
							<filter id="Adobe_OpacityMaskFilter_135_" filterUnits="userSpaceOnUse" x="241.8" y="-42.5" width="188.4" height="199.7">
								<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
								<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
							</filter>
						</defs>
						<mask maskUnits="userSpaceOnUse" x="241.8" y="-42.5" width="188.4" height="199.7" id="SVGID_215_">
							<g class="st453">
							</g>
						</mask>

							<radialGradient id="SVGID_216_" cx="177.4083" cy="87.5651" r="93.7979" gradientTransform="matrix(0.884 0.4675 -0.4675 0.884 220.2874 -105.0701)" gradientUnits="userSpaceOnUse">
							<stop  offset="0.6763" style={{stopColor:"#000000"}}/>
							<stop  offset="0.7139" style={{stopColor:"#373737"}}/>
							<stop  offset="0.7548" style={{stopColor:"#6C6C6C"}}/>
							<stop  offset="0.7963" style={{stopColor:"#999999"}}/>
							<stop  offset="0.8377" style={{stopColor:"#BEBEBE"}}/>
							<stop  offset="0.8788" style={{stopColor:"#DADADA"}}/>
							<stop  offset="0.9197" style={{stopColor:"#EFEFEF"}}/>
							<stop  offset="0.9602" style={{stopColor:"#FBFBFB"}}/>
							<stop  offset="1" style={{stopColor:"#FFFFFF"}}/>
						</radialGradient>
						<path class="st454" d="M418.2,99.4c-3.4,1.5-8.7-4.8-9.5,0c-2.1,4.1,8,11.1,3.7,15.8c-4.9,6-16.9-7.2-23.3,1.5
							c-4.2,4.7,2.5,11.7,0.3,15.7c-3.6,6.8-8.1-4.3-11.5-4.4c-4.1,1.3,4.6,7.6-1,11.2c-1.3,0.7-3.6-0.8-5.9-2.3
							c-2.3-1.5-3.7-3.5-4.4-3.4c0.7,4.5-0.1,9.3-0.1,12.7c-3.3-6.9-5.1-16.5-9.2-15.3c-9.6,1.8-6,21.1-17,25.6
							c-12.4,3.8-17.5-12.7-17.3-23c-4.8,2.6-3.7,10.2-7.1,13.5c-1.3-4.1,0.2-6.8-1.4-11c-1.1-0.3-3.3,1.4-5.4,3.1
							c-2.2,1.7-4.3,3.3-5.7,2.8c-2.8-1.1-1.6-10.7-4.3-12.1c-2.7-1.4-10,4.9-12.4,3.3c-2.5-1.7,0.6-10.8-1.7-12.7
							c-2.3-1.9-10.8,2.7-12.8,0.6c-2.1-2.2,2.8-10.5,1-12.8c-1.9-2.4-11.1,0.4-12.7-2.1c-1.6-2.5,5-9.6,3.6-12.3
							c-1.3-2.7-10.9-1.9-12-4.7c-1-2.8,6.8-8.4,6.1-11.3c-0.7-2.9-10.3-4.1-10.7-7c-0.2-1.5,1.9-3.2,4-4.9c2.1-1.7,4.3-2.1,4.3-2.8
							c-2.9-2.9-8.4-2.4-11-7.2c4.8,0.8,11.6,0.9,11-1.1c-0.5-8.9-14.7-14.7-13.8-22.9c-0.4-12.3,18-7.8,24.2-13.1
							c2.8-2-8.8-2.9-6.7-7c0.8-4.8,6.1,1.5,10.5,0.3c0.6-0.8-0.5-3.2-1.4-5.8c-0.9-2.6-1.8-5.1-0.9-6.3c1.9-2.3,10.7,1.7,12.8-0.4
							c2.2-2.1-1.6-11,0.7-12.8c2.4-1.9,10.1,3.9,12.6,2.3c1.3-0.8,1.5-3.6,1.6-6.3c0.2-2.7,0.4-5.4,1.8-6.1c2.7,1,4,5.2,6.7,6.6
							c-0.6-5.5-1.1-11,1.7-16.4c1.2,7.6,0.9,21.2,9.2,18.7c15.1-2.4,12.3-29.8,28.7-28.1c6.8,0.2,9.3,11.8,9.8,20.8
							c2.1-4.1,0.2-6.8,2.3-10.9c1.3,4.1,2.5,8.3,3.8,12.3c1.4,0.4,3.6-1.3,5.8-3c2.2-1.7,4.3-3.3,5.7-2.8c1.4,0.6,1.8,3.2,2.2,5.9
							c-0.6,1.5-0.7,5,0.6,5.7c5.5-0.6,7-6.7,11.1-8c-2.1,4.1-7.8,11.5-5.1,12.9c8.1,4.3,20.8-9.8,29.4,0c6.7,7-1.1,18.4-5.4,26.6
							c6.1,1.5,9.6-1.8,14.5-4.5c-0.8,4.8-6.3,5.3-9.8,8.7c3.3,3.5,8.3-6,12.3-0.4c0.5,1.6,0.1,3-0.6,4.4c-2.3,4.2-8.6,8-8.7,13.2
							c-1.6,9.5,12.2,6.4,18.9,13.4c-4.9,2.6-7.6,4.6-12.5,7.2c2.7,1.4,6.8,3.6,9.7,5.2c-0.2,1.2-2.6,2.4-4.8,3.6
							c-2.6,1.2-5.1,2.5-3.2,3.8c-2.5,1.6-0.5,3.5,2,5.6c1.3,1.9,3.1,3.8,2.7,5.3c-0.4,1.4-3,2.1-5.2,3c-3.1,0.4-5.8,1.1-5,3.7
							c-2,0.2-0.4,2.4,1.2,4.6C416.8,96.2,418.4,98.4,418.2,99.4z"/>
					</g>
				</mask>

					<radialGradient id="SVGID_217_" cx="177.4083" cy="87.5651" r="93.7979" gradientTransform="matrix(0.884 0.4675 -0.4675 0.884 220.2874 -105.0701)" gradientUnits="userSpaceOnUse">
					<stop  offset="0.6763" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.6884" style={{stopColor:"#F4FAFA"}}/>
					<stop  offset="0.7405" style={{stopColor:"#C7E8E7"}}/>
					<stop  offset="0.7932" style={{stopColor:"#A2D9D7"}}/>
					<stop  offset="0.8455" style={{stopColor:"#86CDCA"}}/>
					<stop  offset="0.8976" style={{stopColor:"#71C5C1"}}/>
					<stop  offset="0.9493" style={{stopColor:"#65C0BC"}}/>
					<stop  offset="1" style={{stopColor:"#61BEBA"}}/>
				</radialGradient>
				<path class="st455" d="M418.2,99.4c-3.4,1.5-8.7-4.8-9.5,0c-2.1,4.1,8,11.1,3.7,15.8c-4.9,6-16.9-7.2-23.3,1.5
					c-4.2,4.7,2.5,11.7,0.3,15.7c-3.6,6.8-8.1-4.3-11.5-4.4c-4.1,1.3,4.6,7.6-1,11.2c-1.3,0.7-3.6-0.8-5.9-2.3
					c-2.3-1.5-3.7-3.5-4.4-3.4c0.7,4.5-0.1,9.3-0.1,12.7c-3.3-6.9-5.1-16.5-9.2-15.3c-9.6,1.8-6,21.1-17,25.6
					c-12.4,3.8-17.5-12.7-17.3-23c-4.8,2.6-3.7,10.2-7.1,13.5c-1.3-4.1,0.2-6.8-1.4-11c-1.1-0.3-3.3,1.4-5.4,3.1
					c-2.2,1.7-4.3,3.3-5.7,2.8c-2.8-1.1-1.6-10.7-4.3-12.1c-2.7-1.4-10,4.9-12.4,3.3c-2.5-1.7,0.6-10.8-1.7-12.7
					c-2.3-1.9-10.8,2.7-12.8,0.6c-2.1-2.2,2.8-10.5,1-12.8c-1.9-2.4-11.1,0.4-12.7-2.1c-1.6-2.5,5-9.6,3.6-12.3
					c-1.3-2.7-10.9-1.9-12-4.7c-1-2.8,6.8-8.4,6.1-11.3c-0.7-2.9-10.3-4.1-10.7-7c-0.2-1.5,1.9-3.2,4-4.9c2.1-1.7,4.3-2.1,4.3-2.8
					c-2.9-2.9-8.4-2.4-11-7.2c4.8,0.8,11.6,0.9,11-1.1c-0.5-8.9-14.7-14.7-13.8-22.9c-0.4-12.3,18-7.8,24.2-13.1
					c2.8-2-8.8-2.9-6.7-7c0.8-4.8,6.1,1.5,10.5,0.3c0.6-0.8-0.5-3.2-1.4-5.8c-0.9-2.6-1.8-5.1-0.9-6.3c1.9-2.3,10.7,1.7,12.8-0.4
					c2.2-2.1-1.6-11,0.7-12.8c2.4-1.9,10.1,3.9,12.6,2.3c1.3-0.8,1.5-3.6,1.6-6.3c0.2-2.7,0.4-5.4,1.8-6.1c2.7,1,4,5.2,6.7,6.6
					c-0.6-5.5-1.1-11,1.7-16.4c1.2,7.6,0.9,21.2,9.2,18.7c15.1-2.4,12.3-29.8,28.7-28.1c6.8,0.2,9.3,11.8,9.8,20.8
					c2.1-4.1,0.2-6.8,2.3-10.9c1.3,4.1,2.5,8.3,3.8,12.3c1.4,0.4,3.6-1.3,5.8-3c2.2-1.7,4.3-3.3,5.7-2.8c1.4,0.6,1.8,3.2,2.2,5.9
					c-0.6,1.5-0.7,5,0.6,5.7c5.5-0.6,7-6.7,11.1-8c-2.1,4.1-7.8,11.5-5.1,12.9c8.1,4.3,20.8-9.8,29.4,0c6.7,7-1.1,18.4-5.4,26.6
					c6.1,1.5,9.6-1.8,14.5-4.5c-0.8,4.8-6.3,5.3-9.8,8.7c3.3,3.5,8.3-6,12.3-0.4c0.5,1.6,0.1,3-0.6,4.4c-2.3,4.2-8.6,8-8.7,13.2
					c-1.6,9.5,12.2,6.4,18.9,13.4c-4.9,2.6-7.6,4.6-12.5,7.2c2.7,1.4,6.8,3.6,9.7,5.2c-0.2,1.2-2.6,2.4-4.8,3.6
					c-2.6,1.2-5.1,2.5-3.2,3.8c-2.5,1.6-0.5,3.5,2,5.6c1.3,1.9,3.1,3.8,2.7,5.3c-0.4,1.4-3,2.1-5.2,3c-3.1,0.4-5.8,1.1-5,3.7
					c-2,0.2-0.4,2.4,1.2,4.6C416.8,96.2,418.4,98.4,418.2,99.4z"/>
				<defs>
					<filter id="Adobe_OpacityMaskFilter_136_" filterUnits="userSpaceOnUse" x="240.7" y="-36.8" width="191.1" height="184.1">
						<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
						<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="240.7" y="-36.8" width="191.1" height="184.1" id="SVGID_218_">
					<g class="st456">
						<defs>
							<filter id="Adobe_OpacityMaskFilter_137_" filterUnits="userSpaceOnUse" x="240.7" y="-36.8" width="191.1" height="184.1">
								<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
								<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
							</filter>
						</defs>
						<mask maskUnits="userSpaceOnUse" x="240.7" y="-36.8" width="191.1" height="184.1" id="SVGID_218_">
							<g class="st457">
							</g>
						</mask>
						<radialGradient id="SVGID_219_" cx="336.18" cy="55.2753" r="93.798" gradientUnits="userSpaceOnUse">
							<stop  offset="0.6763" style={{stopColor:"#000000"}}/>
							<stop  offset="0.7139" style={{stopColor:"#373737"}}/>
							<stop  offset="0.7548" style={{stopColor:"#6C6C6C"}}/>
							<stop  offset="0.7963" style={{stopColor:"#999999"}}/>
							<stop  offset="0.8377" style={{stopColor:"#BEBEBE"}}/>
							<stop  offset="0.8788" style={{stopColor:"#DADADA"}}/>
							<stop  offset="0.9197" style={{stopColor:"#EFEFEF"}}/>
							<stop  offset="0.9602" style={{stopColor:"#FBFBFB"}}/>
							<stop  offset="1" style={{stopColor:"#FFFFFF"}}/>
						</radialGradient>
						<path class="st458" d="M429.3,55.9c-2.3,2.9-9.9-0.1-8.4,4.4c0,4.6,12.2,6.1,10.7,12.2c-1.5,7.6-18.4,1.5-19.9,12.2
							c-1.5,6.1,7.7,9.2,7.7,13.8c0,7.6-9.2,0-12.2,1.5c-3.1,3.1,7.7,4.6,4.4,10.4c-0.9,1.2-3.6,1-6.3,0.7c-2.7-0.3-4.9-1.4-5.4-0.9
							c2.8,3.7,4.3,8.2,5.8,11.3c-6.1-4.6-12.2-12.2-15.3-9.2c-7.6,6.1,4.6,21.4-3.1,30.6c-9.2,9.2-21.4-3.1-26-12.2
							c-3.1,4.6,1.5,10.7,0,15.3c-3.1-3.1-3.1-6.1-6.4-9.1c-1.1,0.3-2.2,2.8-3.3,5.3c-1.1,2.5-2.3,5-3.8,5.1c-3,0.3-6.4-8.7-9.5-8.7
							c-3,0-6.5,9-9.5,8.7c-3-0.3-4.6-9.8-7.5-10.4c-3-0.6-8.2,7.5-11.1,6.5c-2.9-0.9-2.4-10.6-5.1-11.8c-2.7-1.2-9.6,5.6-12.2,4.1
							c-2.6-1.5-0.1-10.8-2.5-12.6c-2.4-1.8-10.5,3.5-12.8,1.5c-2.2-2,2.1-10.6,0.1-12.8c-2-2.2-11,1.2-12.8-1.2
							c-0.9-1.2,0.2-3.7,1.3-6.2c1.1-2.5,2.8-3.9,2.5-4.5c-4-1.2-8.5,1.8-13.1-1.2c4.6-1.5,10.7-4.6,9.2-6.1
							c-4.6-7.6-19.9-6.1-22.9-13.8c-6.1-10.7,12.2-15.3,15.3-22.9c1.5-3.1-9.2,1.5-9.2-3.1c-1.5-4.6,6.1-1.5,9.4-4.7
							c0.1-1-1.9-2.6-4-4.4c-2-1.8-4-3.7-3.7-5.1c0.6-3,10.3-3.5,11.2-6.3c0.9-2.9-6.5-9-5.3-11.7c1.2-2.7,10.8-1.3,12.3-3.8
							c0.8-1.3-0.4-3.8-1.5-6.3c-1.1-2.5-2.2-5-1.3-6.2c2.9-0.3,5.9,2.7,9,2.7c-3.1-4.6-6.1-9.2-6.1-15.3
							c4.6,6.1,10.7,18.4,16.8,12.2c12.2-9.2-3.1-32.1,12.2-38.2c6.1-3.1,13.8,6.1,18.4,13.8c0-4.6-3.1-6.1-3.1-10.7
							c3.1,3.1,6.1,6.1,9.1,9.2c1.5-0.3,2.6-2.8,3.7-5.3c1.1-2.5,2.3-5,3.8-5.1c1.5-0.2,3.1,2,4.7,4.2c0.2,1.7,1.7,4.7,3.2,4.7
							c4.6-3.1,3.1-9.2,6.1-12.2c0,4.6-1.5,13.8,1.5,13.8c9.2,0,13.8-18.4,26-13.8c9.2,3.1,7.6,16.8,7.6,26
							c6.1-1.5,7.6-6.1,10.7-10.7c1.5,4.6-3.1,7.6-4.6,12.2c4.6,1.5,4.6-9.2,10.7-6.1c1.1,1.2,1.5,2.6,1.5,4.2
							c-0.1,4.8-3.8,11.1-1.5,15.7c3.1,9.2,13.8,0,22.9,3.1c-3.1,4.6-4.6,7.6-7.6,12.2c3.1,0,7.6,0,11,0.1c0.4,1.2-1.1,3.4-2.6,5.5
							c-1.7,2.3-3.4,4.6-1,4.9c-1.4,2.5,1.2,3.4,4.4,4c2,1.1,4.6,1.9,4.9,3.4c0.3,1.5-1.7,3.3-3.2,5.1c-2.6,1.8-4.6,3.7-2.7,5.6
							c-1.6,1.1,0.8,2.3,3.2,3.5C426.6,53.7,429.1,54.9,429.3,55.9z"/>
					</g>
				</mask>
				<radialGradient id="SVGID_220_" cx="336.18" cy="55.2753" r="93.798" gradientUnits="userSpaceOnUse">
					<stop  offset="0.6763" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="0.6884" style={{stopColor:"#F4FAFA"}}/>
					<stop  offset="0.7405" style={{stopColor:"#C7E8E7"}}/>
					<stop  offset="0.7932" style={{stopColor:"#A2D9D7"}}/>
					<stop  offset="0.8455" style={{stopColor:"#86CDCA"}}/>
					<stop  offset="0.8976" style={{stopColor:"#71C5C1"}}/>
					<stop  offset="0.9493" style={{stopColor:"#65C0BC"}}/>
					<stop  offset="1" style={{stopColor:"#61BEBA"}}/>
				</radialGradient>
				<path class="st459" d="M429.3,55.9c-2.3,2.9-9.9-0.1-8.4,4.4c0,4.6,12.2,6.1,10.7,12.2c-1.5,7.6-18.4,1.5-19.9,12.2
					c-1.5,6.1,7.7,9.2,7.7,13.8c0,7.6-9.2,0-12.2,1.5c-3.1,3.1,7.7,4.6,4.4,10.4c-0.9,1.2-3.6,1-6.3,0.7c-2.7-0.3-4.9-1.4-5.4-0.9
					c2.8,3.7,4.3,8.2,5.8,11.3c-6.1-4.6-12.2-12.2-15.3-9.2c-7.6,6.1,4.6,21.4-3.1,30.6c-9.2,9.2-21.4-3.1-26-12.2
					c-3.1,4.6,1.5,10.7,0,15.3c-3.1-3.1-3.1-6.1-6.4-9.1c-1.1,0.3-2.2,2.8-3.3,5.3c-1.1,2.5-2.3,5-3.8,5.1c-3,0.3-6.4-8.7-9.5-8.7
					c-3,0-6.5,9-9.5,8.7c-3-0.3-4.6-9.8-7.5-10.4c-3-0.6-8.2,7.5-11.1,6.5c-2.9-0.9-2.4-10.6-5.1-11.8c-2.7-1.2-9.6,5.6-12.2,4.1
					c-2.6-1.5-0.1-10.8-2.5-12.6c-2.4-1.8-10.5,3.5-12.8,1.5c-2.2-2,2.1-10.6,0.1-12.8c-2-2.2-11,1.2-12.8-1.2
					c-0.9-1.2,0.2-3.7,1.3-6.2c1.1-2.5,2.8-3.9,2.5-4.5c-4-1.2-8.5,1.8-13.1-1.2c4.6-1.5,10.7-4.6,9.2-6.1
					c-4.6-7.6-19.9-6.1-22.9-13.8c-6.1-10.7,12.2-15.3,15.3-22.9c1.5-3.1-9.2,1.5-9.2-3.1c-1.5-4.6,6.1-1.5,9.4-4.7
					c0.1-1-1.9-2.6-4-4.4c-2-1.8-4-3.7-3.7-5.1c0.6-3,10.3-3.5,11.2-6.3c0.9-2.9-6.5-9-5.3-11.7c1.2-2.7,10.8-1.3,12.3-3.8
					c0.8-1.3-0.4-3.8-1.5-6.3c-1.1-2.5-2.2-5-1.3-6.2c2.9-0.3,5.9,2.7,9,2.7c-3.1-4.6-6.1-9.2-6.1-15.3c4.6,6.1,10.7,18.4,16.8,12.2
					c12.2-9.2-3.1-32.1,12.2-38.2c6.1-3.1,13.8,6.1,18.4,13.8c0-4.6-3.1-6.1-3.1-10.7c3.1,3.1,6.1,6.1,9.1,9.2
					c1.5-0.3,2.6-2.8,3.7-5.3c1.1-2.5,2.3-5,3.8-5.1c1.5-0.2,3.1,2,4.7,4.2c0.2,1.7,1.7,4.7,3.2,4.7c4.6-3.1,3.1-9.2,6.1-12.2
					c0,4.6-1.5,13.8,1.5,13.8c9.2,0,13.8-18.4,26-13.8c9.2,3.1,7.6,16.8,7.6,26c6.1-1.5,7.6-6.1,10.7-10.7c1.5,4.6-3.1,7.6-4.6,12.2
					c4.6,1.5,4.6-9.2,10.7-6.1c1.1,1.2,1.5,2.6,1.5,4.2c-0.1,4.8-3.8,11.1-1.5,15.7c3.1,9.2,13.8,0,22.9,3.1
					c-3.1,4.6-4.6,7.6-7.6,12.2c3.1,0,7.6,0,11,0.1c0.4,1.2-1.1,3.4-2.6,5.5c-1.7,2.3-3.4,4.6-1,4.9c-1.4,2.5,1.2,3.4,4.4,4
					c2,1.1,4.6,1.9,4.9,3.4c0.3,1.5-1.7,3.3-3.2,5.1c-2.6,1.8-4.6,3.7-2.7,5.6c-1.6,1.1,0.8,2.3,3.2,3.5
					C426.6,53.7,429.1,54.9,429.3,55.9z"/>

					<ellipse transform="matrix(5.658472e-02 -0.9984 0.9984 5.658472e-02 261.9242 392.3497)" class="st460" cx="338.6" cy="57.6" rx="71.9" ry="71.9"/>
				<linearGradient id="SVGID_221_" gradientUnits="userSpaceOnUse" x1="353.2942" y1="73.4568" x2="302.8535" y2="19.0685">
					<stop  offset="0" style={{stopColor:"#FFFFFF"}}/>
					<stop  offset="1" style={{stopColor:"#000000"}}/>
				</linearGradient>
				<circle class="st461" cx="338.6" cy="57.6" r="71.9"/>
				<path class="st462" d="M343.3,126.1c1.4-0.8,2.9-1.6,4.5-2.2c0.2,0.6,0.3,1.2,0.5,1.8c0.4,1.2,0.8,2.3,1.3,3.4
					c3.2-0.5,6.5-1.2,9.6-2.1c-0.5-1.4-1-2.7-1.3-4.1c-0.2-0.6-0.3-1.2-0.4-1.7c2.1-0.5,4.3-1.2,6.5-2.1c0.8,0.4,1.5,0.8,2.3,1.1
					c1.9,0.8,3.9,1.3,5.9,1.6c2.5-1.3,5-2.8,7.3-4.4c-1.1-0.6-2.2-1.3-3.1-2c-1.5-1.1-2.9-2.3-4.3-3.4c0.1-0.1,0.1-0.2,0.2-0.3
					c1.5-3.4,1.4-6.9,0.8-9.8c-0.6-3-1.6-5.4-2.8-7.9c-2.3-4.9-5.1-9.1-7.3-13.7c-0.6-1.1-1.1-2.2-1.5-3.4c-0.5-1.1-0.9-2.3-1.3-3.5
					c-0.7-2.4-1.2-4.8-1.1-7.2c0.1-2.4,0.7-4.7,1.9-6.5c1.1-1.8,2.8-3.2,4.5-4.3c3.5-2.2,7.7-4.1,12.3-5.7c0.2,0.4,0.4,0.7,0.6,1.2
					c1.9,3.9,3.6,8.6,5.8,13c2.1,4.5,4.6,9.1,8.1,13.1c1.8,2,3.8,3.9,6,5.5l0.4,0.3l0.4,0.3c0.3,0.2,0.7,0.4,1.1,0.6
					c0.7,0.4,1.4,0.7,2,0.9c1.1,0.4,2.2,0.7,3.2,0.9c0.9-2.1,1.7-4.3,2.3-6.5c-2.1-0.6-3.8-1.3-5.1-2.5c-1.6-1.3-3.1-2.8-4.3-4.5
					c-2.5-3.4-4.6-7.4-6.4-11.7l-2.7-6.5c-0.9-2.2-1.8-4.5-3-6.8c2-0.6,4-1.2,6.1-2c2.5-0.9,5.1-2,7.6-3.5c1.3-0.7,2.5-1.5,3.7-2.4
					c1.1-0.9,2.4-2,3.4-3.2c0.1-0.1,0.2-0.3,0.3-0.4c-1.1-3.4-2.5-6.7-4.1-10c0,0.1,0,0.2,0,0.3c-0.3,1.9-1.1,3.5-2.3,4.8
					c-0.6,0.7-1.3,1.3-2.2,2c-0.8,0.6-1.8,1.2-2.8,1.7c-2,1.1-4.1,1.9-6.4,2.7c-2.6,0.9-5.4,1.7-8.2,2.5c-0.9-0.9-1.8-1.8-3-2.6
					c-0.4-0.3-0.6-0.4-0.9-0.5c-0.5-0.3-0.7-0.4-1.1-0.6c-0.7-0.4-1.4-0.7-2.2-0.9c-3-1-6-0.9-8.6-0.4c-2.7,0.5-5.1,1.4-7.4,2.2
					c-3.4,1.2-6.6,2.5-10,3.2c4.5-3.1,9-6.3,13.3-10.4c2.3-2.4,4.7-4.9,6.4-9c0.4-1,0.7-2.2,0.8-3.4c0.1-0.6,0.1-1.3,0-2.1l-0.1-0.7
					l-0.1-0.5l-0.1-0.3c-0.4-1.8-0.9-3.5-1.5-5.2c-2.7-6.8-7.7-12.2-13.1-16.1c-3.3-2.4-6.8-4.4-10.3-6.1c-6.3-0.4-12.6,0-18.8,1.3
					c1.1,0.5,2.3,0.9,3.4,1.4c-0.3,0.6-0.6,1.3-0.8,1.9c-0.8,2.3-1.1,4.6-1.2,7c-0.1,2.3,0,4.6,0.2,6.9c0.2,2.3,0.5,4.6,0.7,6.8
					c0.3,2.3,0.5,4.5,0.4,6.8c-0.1,2.2-0.5,4.6-1.1,6.5c-0.5,1.7-1.3,2.8-2,3.3c-2.2-3-4.5-6.5-6.7-9.9c-1.4-2.1-2.7-4.3-4.3-6.3
					c-1.7-2-3.7-3.7-6-4.9c-4.7-2.3-9.7-2.9-14.6-3c-0.4,0-0.9,0-1.3,0c-0.8,0.7-1.5,1.4-2.3,2.1c1.2,0,2.4,0,3.5,0
					c4.7,0.2,9.4,1,13.3,3.3c3.8,2.3,6.6,6.1,7.7,10.6c0.8,2.9,0.9,6,1.3,9.3c-0.3,0-0.7,0-1,0.1c-2.5,0.2-5.5,0.8-8,2.2
					c-2.9,1.5-4.9,4-6.1,6.6c-2.2,5.2-1.9,10.1-1.9,14.7c0.1,4.7,0.4,9.3,0.4,13.8c0,4.5-0.4,9.1-1,13.4c-0.2,1.4-0.5,2.8-0.8,4.1
					c-1.7,0.4-3.3,0.8-5.2,0.9c-1.9,0.1-4-0.3-6-1.2c-2-0.9-3.7-2.3-5.4-3.9c-1.6-1.6-3.2-3.3-4.7-5c-2.6-2.9-5.1-6.1-7.8-9.1
					c0.2,1.6,0.4,3.3,0.7,4.9c1.6,2,3.1,4.1,4.7,6.2c1.5,1.9,3,3.7,4.6,5.5c1.7,1.8,3.5,3.5,5.4,5.1c2,1.7,4.2,3.3,6.8,4.4
					c1.1,0.5,2.2,0.8,3.3,1.1c-0.1,0.2-0.2,0.3-0.3,0.5l-1.5,1.9c-1,1.3-2.2,2.8-3.8,4.2c-1.4,1.2-3.1,2.3-4.9,3
					c0.1,0.2,0.3,0.3,0.4,0.5c1,1.2,2,2.4,3,3.5c1.4-0.2,2.9-0.5,4.4-0.8c2.5-0.5,5.3-1.5,7.5-3.3c0.6-0.5,1.1-0.9,1.6-1.6l0.7-0.8
					c0.3-0.4,0.4-0.6,0.7-0.9c0.8-1.2,1.5-2.5,2-3.8c0.4-1.1,0.8-2.1,1-3.2c0.7-0.2,1.5-0.5,2.1-0.8c2.3-1,4.5-2,6.5-3
					c2-1,3.9-1.9,5.9-2.6c1.9-0.7,3.7-1.1,5.6-1.1c3.9,0,7.9,2,11.4,4.8c3.6,2.9,6.9,6.1,9.9,9.8c1.6,2,3.1,4.1,4.7,6.3
					c-0.6,0.8-1.1,1.7-1.7,2.7c-0.5,1-1,2.1-1.2,3.4l-0.1,0.5l-0.1,0.5l-0.1,0.6c-0.1,0.3-0.1,0.9-0.2,1.5c-0.1,1.6,0,3.1,0.1,4.6
					c-2,1-4,2.1-5.8,3.4c-2.7,1.8-5.2,4.2-7.1,6.9c1.5,0.1,2.9,0.2,4.4,0.2C339.9,128.4,341.6,127.1,343.3,126.1z M338.9,46.8
					c2.3-0.9,4.3-1.8,6.3-2.7c2.3-1,4.6-1.8,6.9-2.5c2.3-0.7,4.6-1.3,7-1.5c4.7-0.6,9.4,0,12.3,2c0.3,0.2,0.6,0.4,0.9,0.7
					c-1.6,0.6-3.1,1.2-4.7,2c-2.5,1.2-5,2.9-7.1,5.2c-2.1,2.2-3.6,5-4.4,8c-0.7,2.9-0.7,5.8-0.3,8.5c0.7,5.4,2.2,10.4,3.8,15.4
					c1.2,3.7,2.4,7.4,3.4,11c-1.1-2.1-2.6-4-4.3-5.4c-2.9-2.5-5.9-4.1-8.8-5.5c-2.9-1.4-5.7-2.5-8.4-3.7c-2.7-1.2-5.2-2.5-7.4-4.1
					c-0.5-0.4-1.1-0.8-1.5-1.3c-0.5-0.5-0.9-1-1.4-1.5c-0.8-1-1.6-2.1-2.1-3.3c-1-2.4-1-5.2-0.3-8c0.3-1.4,0.8-2.8,1.5-4.2l0.5-1
					c0.2-0.3,0.3-0.6,0.5-0.8c0.4-0.6,0.8-1.1,1.3-1.6C334.4,50.3,336.6,48.5,338.9,46.8z M331,18c-1.2-4.5-1.8-9-2.4-13.5
					c-0.6-4.4-0.8-9,0.5-13.2c0.2-0.6,0.4-1.2,0.7-1.8c1.2,0.5,2.4,1.1,3.6,1.7c5.4,2.5,10.5,5.3,14.9,8.7c4.4,3.4,8,7.4,9.7,12.1
					c0.4,1.2,0.8,2.4,1,3.6l0,0.2l0,0.1c0,0.2,0.1,0.4,0,0.4c0,0,0-0.1,0,0c0,0.1,0,0.1,0,0.2c0,0.3-0.1,0.6-0.2,1
					c-0.6,1.7-2.1,4-3.9,6.1c-3.6,4.3-8,8.6-12.3,12.9c-1.3,1.3-2.6,2.6-3.9,3.9c-0.4-0.1-0.8-0.2-1.3-0.3c-2.1-0.6-3.9-1.6-5.1-2.5
					c-1.1-0.9-1.8-1.6-2.7-2.6c0.2-0.2,0.5-0.4,0.7-0.7c1.3-1.5,1.9-3.2,2.2-4.8c0.3-1.6,0.2-3,0-4.3C332.3,22.5,331.6,20.3,331,18z
					 M349,96c-3.7-3-7-6.4-10.5-9.6c-3.5-3.3-7.7-6.7-13.2-8c-2.7-0.7-5.7-0.7-8.4-0.3c-2.6,0.4-5.2,1.1-7.5,1.9
					c-1.6,0.5-3.1,1.1-4.6,1.6c0-0.5-0.1-1-0.1-1.5c-0.5-4.7-1.4-9.3-2-13.8c-0.6-4.5-0.9-9-0.6-13.4c0.1-1.1,0.2-2.1,0.4-3.3
					c0.1-1.1,0.4-2.1,0.6-3.2c0.5-2,1.3-3.9,2.4-5.3c1-1.4,2.4-2.3,3.7-2.6c1.6-0.5,3.2-0.5,5.3-0.5c0.9,0,1.8,0.1,2.9,0.1
					c0.6,1.5,1.5,3,2.7,4.5c2.1,2.6,5.4,4.7,8.8,5.3c0.9,0.2,1.8,0.3,2.7,0.3c-0.5,0.7-1.1,1.3-1.6,2c-0.5,0.7-0.9,1.4-1.3,2.2
					c-0.2,0.4-0.4,0.8-0.5,1.2l-0.4,1.1c-0.5,1.5-1.1,2.9-1.7,4.5c-1.1,3-1.7,6.6-0.9,10.1c0.4,1.7,1.1,3.4,1.9,4.9
					c0.4,0.7,0.9,1.5,1.4,2.2c0.6,0.8,1.1,1.4,1.7,2.1c2.3,2.6,5,4.6,7.6,6.5c2.6,1.8,5.2,3.5,7.7,5.1c2.4,1.6,4.7,3.2,6.3,4.9
					c0.8,0.8,1.4,1.6,1.9,2.4c0.2,0.4,0.4,0.8,0.5,1.2c0.1,0.2,0.1,0.4,0.2,0.6c0,0.1,0,0.2,0.1,0.3l0,0.1l0,0.1l0,0.1
					c0,0,0,0.1,0,0.1c0,0,0,0,0,0C352.5,98.7,350.7,97.4,349,96z M354.3,99.8C354.3,99.7,354.3,99.7,354.3,99.8l0,0.1
					C354.3,99.8,354.3,99.8,354.3,99.8z M363.2,105.8c0.5-0.9,1-1.9,1.3-3.1c0.2-0.7,0.3-1.4,0.4-2.1c0-0.2,0-0.3,0-0.5
					c0.2,1.1,0.4,2.1,0.6,3.1c0.2,1.5,0.2,2.9-0.1,4.1C364.7,106.8,363.9,106.3,363.2,105.8z M357.4,114.3L357.4,114.3L357.4,114.3
					c0.1,0,0.2,0.1,0.3,0.2c-0.1,0.1-0.2,0.1-0.3,0.2L357.4,114.3L357.4,114.3z"/>
				<path class="st460" d="M388.6,6c0.1,2,0.2,4,0.2,6c0,48.8-39.5,88.3-88.3,88.3c-7.5,0-14.8-0.9-21.8-2.7
					c12.9,19.3,34.9,32,59.8,32c39.7,0,71.9-32.2,71.9-71.9C410.5,37.3,402.1,19,388.6,6z"/>
				<g class="st463">
					<path class="st464" d="M394.2,97.1c0.2-1.6,0.6-3.3,1.1-4.9c0.6,0.3,1.2,0.5,1.7,0.8c1.2,0.4,2.3,0.8,3.5,1.2
						c1.7-2.8,3.2-5.8,4.4-8.8c-1.4-0.5-2.8-1-4-1.5c-0.5-0.2-1.1-0.5-1.6-0.8c0.9-2,1.8-4.1,2.4-6.4c0.8-0.3,1.6-0.7,2.3-1.1
						c1.8-1,3.4-2.2,5-3.6c0.6-2.8,0.9-5.6,1.2-8.5c-1.2,0.5-2.3,0.9-3.5,1.2c-1.8,0.5-3.6,0.8-5.3,1.2l-0.1-0.3
						c-1.7-3.3-4.5-5.5-7.1-6.8c-2.7-1.4-5.2-2.1-7.9-2.8c-5.2-1.3-10.3-1.8-15.2-2.9c-1.2-0.3-2.4-0.6-3.7-1
						c-1.2-0.3-2.4-0.8-3.5-1.2c-2.3-0.9-4.5-2.1-6.3-3.6c-1.8-1.5-3.2-3.5-3.9-5.5c-0.7-2-0.7-4.2-0.5-6.2
						c0.5-4.1,1.7-8.5,3.3-13.2c0.4,0.1,0.9,0.1,1.3,0.2c4.2,0.9,9,2.6,13.8,3.7c4.8,1.1,9.9,2.2,15.3,1.9c2.7-0.1,5.4-0.5,8-1.3
						l0.5-0.1l0.5-0.2c0.3-0.1,0.8-0.3,1.1-0.4c0.7-0.3,1.4-0.6,2-1c1-0.6,1.9-1.2,2.8-1.9c-1.1-2-2.3-4-3.6-5.9
						c-1.8,1.2-3.4,2.1-5.1,2.4c-2,0.4-4.1,0.6-6.2,0.5c-4.3-0.2-8.6-1.1-13.1-2.3l-6.8-2c-2.3-0.7-4.7-1.4-7.2-2
						c0.8-1.9,1.6-3.9,2.3-6c0.9-2.6,1.6-5.2,2.1-8.1c0.2-1.4,0.4-2.9,0.4-4.4c0-1.4,0-3.1-0.4-4.7c0-0.2-0.1-0.3-0.1-0.5
						c-3.3-1.3-6.8-2.3-10.3-3.1l0.2,0.2c1.3,1.4,2,3,2.4,4.8c0.2,0.9,0.2,1.8,0.1,3c0,1-0.2,2.1-0.4,3.2c-0.4,2.2-1.1,4.4-1.9,6.7
						c-0.9,2.6-2,5.3-3.2,8c-1.3,0.1-2.6,0.3-3.9,0.7c-0.5,0.1-0.7,0.2-1,0.3c-0.5,0.2-0.8,0.3-1.2,0.5c-0.7,0.3-1.4,0.7-2.1,1.1
						c-2.6,1.7-4.4,4.1-5.7,6.5c-1.3,2.4-2.1,4.8-3,7.1c-1.2,3.3-2.2,6.7-3.7,9.8c0.4-5.5,0.8-11,0.2-16.9c-0.4-3.3-0.9-6.8-3-10.6
						c-0.6-0.9-1.3-1.9-2.2-2.8c-0.4-0.4-0.9-0.9-1.7-1.4l-0.6-0.4L322.9,7l-0.3-0.2c-1.6-0.8-3.3-1.5-5-2.1c-7-2.2-14.3-1.7-20.7,0
						c-4,1.1-7.7,2.6-11.2,4.2c-4.3,4.6-7.9,9.8-10.8,15.5c1.1-0.6,2.2-1.2,3.3-1.8c0.3,0.6,0.6,1.3,1,1.8c1.3,2,2.9,3.7,4.7,5.3
						c1.7,1.5,3.6,2.9,5.5,4.2c1.9,1.3,3.8,2.5,5.8,3.7c1.9,1.2,3.8,2.5,5.5,4c1.6,1.5,3.2,3.2,4.4,4.9c1,1.4,1.4,2.8,1.3,3.6
						c-3.7-0.2-7.9-0.6-11.9-1.1c-2.5-0.3-5.1-0.6-7.7-0.6c-2.6,0-5.2,0.6-7.6,1.6c-4.7,2.2-8.4,5.7-11.5,9.5
						c-0.3,0.3-0.5,0.7-0.8,1c0,1,0.1,2.1,0.2,3.1c0.7-0.9,1.5-1.9,2.2-2.8c3.1-3.5,6.7-6.7,10.9-8.3c4.2-1.5,8.8-1.3,13.1,0.6
						c2.8,1.2,5.3,3.1,8,4.8c-0.2,0.3-0.4,0.5-0.6,0.8c-1.4,2.1-2.8,4.7-3.4,7.6c-0.6,3.2,0,6.4,1.3,8.9c2.7,5,6.7,7.8,10.3,10.7
						c3.7,2.9,7.5,5.5,11,8.4c3.6,2.8,6.8,6,9.8,9.2c1,1,1.9,2.1,2.7,3.2c-0.7,1.6-1.5,3.1-2.6,4.6c-1.2,1.6-2.8,3-4.7,4
						c-1.9,1-4.1,1.5-6.4,1.8c-2.2,0.3-4.5,0.4-6.9,0.5c-3.9,0.2-8,0.1-12,0.4c1.4,0.9,2.8,1.8,4.3,2.6c2.6,0,5.2,0.1,7.8,0.2
						c2.4,0,4.8,0,7.2-0.1c2.4-0.2,4.9-0.5,7.4-1c2.5-0.5,5.2-1.2,7.8-2.5c1-0.5,2-1.2,2.9-1.9c0.1,0.2,0.1,0.4,0.2,0.6l0.6,2.4
						c0.3,1.6,0.8,3.5,0.8,5.6c0.1,1.9-0.2,3.9-0.8,5.7c0.2,0,0.4,0,0.7,0c1.5,0,3.1-0.1,4.6-0.2c0.7-1.3,1.5-2.6,2.2-3.9
						c1.2-2.3,2.2-5,2.1-8c0-0.8-0.1-1.4-0.2-2.2l-0.2-1c-0.1-0.5-0.2-0.7-0.3-1.1c-0.4-1.4-1-2.7-1.7-4c-0.6-1-1.2-1.9-1.8-2.8
						c0.3-0.7,0.5-1.5,0.7-2.2c0.7-2.4,1.3-4.7,1.8-6.9c0.5-2.2,1-4.3,1.7-6.2c0.6-1.9,1.4-3.6,2.7-5c2.4-3,6.5-4.9,10.9-5.8
						c4.5-1,9.1-1.6,13.8-1.5c2.6,0,5.2,0.2,7.8,0.3c0.3,0.9,0.6,1.9,1.1,3c0.4,1,1,2.1,1.8,3.1l0.3,0.4l0.3,0.4l0.4,0.4
						c0.2,0.2,0.7,0.7,1,1c1.2,1.1,2.4,2,3.6,2.8c-0.5,2.2-0.8,4.4-1,6.7c-0.3,3.3,0,6.7,0.9,9.9c1-1.1,2-2.2,2.9-3.3
						C393.9,101.2,393.9,99.1,394.2,97.1z M329.7,50.8c0.8-2.3,1.3-4.5,1.9-6.6c0.6-2.4,1.5-4.7,2.3-6.9c0.9-2.2,1.9-4.4,3.2-6.4
						c2.4-4,6-7.3,9.3-8.3c0.4-0.1,0.7-0.2,1.1-0.3c-0.5,1.6-1,3.2-1.4,4.9c-0.6,2.7-0.9,5.7-0.4,8.7c0.4,3,1.7,6,3.5,8.4
						c1.8,2.4,4.1,4.2,6.4,5.6c4.6,2.9,9.5,4.8,14.3,6.7c3.6,1.4,7.2,2.8,10.7,4.2c-2.4-0.5-4.7-0.4-6.8,0c-3.8,0.7-6.9,2-9.8,3.4
						c-2.9,1.4-5.5,2.9-8.2,4.2c-2.6,1.3-5.2,2.5-7.8,3.2c-0.6,0.2-1.3,0.3-1.9,0.4c-0.7,0.1-1.3,0.1-2,0.1c-1.3,0-2.7-0.1-3.9-0.5
						c-2.5-0.7-4.6-2.4-6.4-4.7c-0.9-1.1-1.7-2.4-2.3-3.8l-0.5-1c-0.1-0.3-0.2-0.6-0.3-0.9c-0.2-0.6-0.4-1.3-0.5-2
						C329.7,56.5,329.7,53.6,329.7,50.8z M302.3,38.8c-4.3-2-8.1-4.3-12-6.6c-3.8-2.3-7.5-5-9.9-8.7c-0.4-0.5-0.7-1.1-0.9-1.7
						c1.2-0.6,2.4-1.2,3.6-1.8c5.3-2.6,10.7-4.9,16.1-6.1c5.4-1.3,10.8-1.6,15.5,0c1.2,0.4,2.3,0.9,3.4,1.5l0.2,0.1l0.1,0.1l0.3,0.2
						l0,0l0.1,0.1c0.2,0.2,0.4,0.5,0.7,0.8c1,1.5,1.8,4.2,2.3,6.9c1.1,5.5,1.6,11.6,2.3,17.7c0.2,1.8,0.4,3.6,0.6,5.5
						c-0.3,0.3-0.7,0.5-1,0.8c-1.8,1.3-3.7,2-5.1,2.4c-1.4,0.3-2.4,0.4-3.7,0.5c0-0.3,0-0.6-0.1-1c-0.4-1.9-1.3-3.5-2.4-4.7
						c-1.1-1.2-2.2-2-3.4-2.7C306.6,40.7,304.5,39.8,302.3,38.8z M374.4,73.8c-4.6,1-9.3,1.5-14.1,2.2c-4.8,0.6-10,1.8-14.6,5.3
						c-2.2,1.7-4.1,4-5.5,6.4c-1.3,2.3-2.4,4.7-3.2,7c-0.6,1.6-1.1,3.1-1.6,4.6c-0.4-0.3-0.8-0.6-1.2-0.8c-4-2.6-8.1-4.7-12-7.1
						c-3.9-2.4-7.6-5-10.8-8c-0.8-0.8-1.6-1.5-2.3-2.3c-0.8-0.8-1.4-1.6-2.1-2.5c-1.3-1.7-2.2-3.5-2.6-5.2c-0.4-1.7-0.2-3.3,0.3-4.5
						c0.6-1.6,1.6-2.8,3-4.4c0.6-0.7,1.2-1.4,1.9-2.2c1.6,0.4,3.3,0.7,5.2,0.7c3.4,0,7-1.3,9.6-3.5c0.7-0.6,1.3-1.2,1.9-1.9
						c0.2,0.8,0.4,1.7,0.6,2.5c0.2,0.8,0.5,1.6,0.9,2.4c0.2,0.4,0.4,0.8,0.6,1.2l0.6,1c0.8,1.3,1.6,2.7,2.4,4.1c1.6,2.7,4,5.5,7.3,7
						c1.6,0.8,3.3,1.3,5,1.6c0.8,0.1,1.8,0.2,2.6,0.3c0.9,0,1.8,0,2.7,0c3.5-0.2,6.7-1,9.8-1.9c3.1-0.9,6-1.9,8.8-2.8
						c2.8-0.9,5.5-1.6,7.8-1.8c1.1-0.1,2.2-0.1,3.1,0.1c0.4,0.1,0.9,0.2,1.2,0.3c0.2,0.1,0.4,0.2,0.6,0.3l0.3,0.2l0.1,0.1l0.1,0
						l0.1,0l0.1,0.1l0,0C378.6,72.7,376.5,73.3,374.4,73.8z M380.6,72L380.6,72l0.1,0.1L380.6,72z M390.9,68.9c-0.4-0.9-0.9-2-1.6-3
						c-0.4-0.6-0.9-1.1-1.4-1.6l-0.3-0.3c1,0.5,1.9,1,2.8,1.5c1.3,0.8,2.4,1.7,3.1,2.6C392.6,68.4,391.8,68.6,390.9,68.9z
						 M393.8,78.7L393.8,78.7L393.8,78.7l0.3-0.1l-0.1,0.4L393.8,78.7L393.8,78.7z"/>
				</g>

				<path class="st465" d="M332.9-5.8c11.4,5,2.5,20.6-8.4,23.2c-10.9,2.6-14.8,3-10.5,11.8c2.4,4.8-10,12.4-16.2,22.9
					c-5.2,8.7-3.6,21.1-8.6,20.1c-11-2.2-11.7-17.8-11.1-28.5c1-16.1,11.7-31.4,25-34.5C313.2,6.9,318.9-11.9,332.9-5.8z"/>
				<path class="st460" d="M323.7,9.8c-6.6,5.2-9.4,7-11.1,4.8c-1.7-2.2-1.6-7.5,5-12.6c6.6-5.2,15.3-5.1,17-2.9
					C336.3,1.2,330.3,4.6,323.7,9.8z"/>
				<path class="st465" d="M301,34.1c-3.4,5.5-8.5,16.2-11.2,14.5c-2.7-1.6-1.9-15,1.5-20.5c3.4-5.4,10.1-8,12.7-6.4
					C306.8,23.4,304.4,28.6,301,34.1z"/>
			</g>
			<g>
				<path class="st466" d="M159.4,327.4c0,25.1-20.4,45.5-45.5,45.5c-25.1,0-45.5-20.4-45.5-45.5c0-25.1,20.4-45.5,45.5-45.5
					C139,281.9,159.4,302.3,159.4,327.4z"/>
				<g>
					<path class="st467" d="M129.2,312c0,6.6-4.5,18.5-18.7,14.8c-9.4-2.4-20.1-7.1-20.1-13.7c0-6.6,13.4-14.2,23.2-14.2
						C123.2,298.9,129.2,305.4,129.2,312z"/>
					<path class="st468" d="M124,312.2c0,4.8-5.7,8.6-12.7,8.6c-7,0-12.7-3.9-12.7-8.6c0-4.8,5.7-8.6,12.7-8.6
						C118.3,303.6,124,307.4,124,312.2z"/>
					<path class="st469" d="M101.4,314.2c0-4.8,4.8-8.8,11.8-8.8c3.7,0,7.1,1.1,9.4,2.9c-2.1-2.8-6.4-4.8-11.3-4.8
						c-7,0-12.7,3.9-12.7,8.6c0,2.2,4.1,6.5,6.1,8C103.9,319.1,101.4,315.6,101.4,314.2z"/>
					<path class="st470" d="M124.7,315.1c-2.1,5.7-8.6,6.5-13.4,6.7c-3,0.1-6-0.6-8.6-2.1c-1.3-0.8-2.5-1.7-3.5-3
						c-0.9-1.3-1.6-2.9-1.5-4.5c0-1.6,0.6-3.3,1.5-4.5c2-2.7,8.9-5.6,12.1-5.5c4,0,9.2,0.8,12,3.6c1.1,1.1,2.9,3.6,2.9,5.3
						c0,0-0.2,1.2-0.2,1.7C125.9,313.5,124.9,314.5,124.7,315.1z M122.6,312.2c0-1.1-0.2-2.2-0.8-3.4c-0.6-1.1-1.5-2.2-2.7-2.9
						c-4.9-3-19.6-2.3-19.6,6.2c0,1.2,0.5,2.3,1.2,3.3c0.8,1,1.8,1.8,2.9,2.4c3.8,2.1,11.7,3.4,15.4,0.4c1.1-0.8,2.1-1.8,2.6-2.9
						C122.3,314.3,122.6,313.3,122.6,312.2L122.6,312.2C122.6,311.1,122.6,312.2,122.6,312.2z"/>
				</g>
				<g>
					<path class="st467" d="M130,288.6c5.4,3.7,1.7,6.6-17.4,5.8c-12.2,0-25.2-0.2-20.7-4.7c2-2.1,8.3-4.1,12.5-5.3
						c4.6-1.3,7.1-1.8,8.7-1.8C115.8,282.6,125.1,285.4,130,288.6z"/>
					<path class="st468" d="M125.2,288.6c2.5,2.1-3.2,3.2-11.7,3.5c-8.5,0.4-14.8-0.7-12.7-3.3c2.2-2.5,8.1-4.2,11.9-4.1
						C116.4,284.8,122.5,286.5,125.2,288.6z"/>
					<path class="st469" d="M102.9,289.7c1.5-2.3,6.7-4.2,11.2-4.1c2.6,0,5.3,0.5,8.1,1.3c-3.2-1.3-6.6-2.1-9.5-2.2
						c-3.8-0.1-9.7,1.6-11.9,4.1c-1.1,1.2,2.5,3,5,3.4C104.7,291.8,102.5,290.4,102.9,289.7z"/>
					<path class="st470" d="M126.5,288.6c3.5,2.7-7,3.5-13,3.8c-3.6,0.2-7.3,0.1-10.2-0.3c-1.4-0.2-2.7-0.6-3.4-1.1
						c-0.7-0.5-0.8-1.3-0.1-2.2c0.6-0.8,2-1.7,3.3-2.3c3.2-1.4,8.1-2.5,9.5-2.4c1.9,0.1,5.9,1.3,9.9,2.5
						C123.9,287.1,125.6,287.8,126.5,288.6C126.5,288.6,126.5,288.6,126.5,288.6C126.9,289,126.5,288.6,126.5,288.6z M123.9,288.6
						c-0.4-0.5-1.2-1-2.1-1.5c-1-0.5-2.2-1-3.3-1.3c-4.4-1.2-12.3-1.4-16.8,2.9c-0.4,0.6-0.3,1.1,0.3,1.6c0.6,0.4,1.6,0.7,2.8,0.9
						c4,0.6,13.9,0.8,17.5-0.2c1-0.3,1.8-0.7,2.1-1.1C124.5,289.5,124.4,289,123.9,288.6C123.9,288.5,123.9,288.5,123.9,288.6
						C123.5,288.1,123.9,288.6,123.9,288.6z"/>
				</g>
				<g>
					<path class="st467" d="M154.3,337.9c-2,1.9-2.9,0.1-0.7-7.6c1.1-5,2.5-10.3,3.9-8c0.7,1,0.9,3.8,1,5.6c0.1,2,0.1,3.1-0.1,3.7
						C158.1,332.6,156,336.2,154.3,337.9z"/>
					<path class="st468" d="M154.7,335.9c-1.1,0.8-1-1.6-0.3-5.1c0.7-3.5,1.7-6,2.5-4.9c0.8,1.2,0.9,3.7,0.5,5.2
						C157.1,332.7,155.8,335,154.7,335.9z"/>
					<path class="st469" d="M156.4,326.7c0.8,0.8,1.1,3.1,0.6,4.9c-0.3,1.1-0.7,2.1-1.3,3.2c0.8-1.2,1.5-2.5,1.8-3.7
						c0.4-1.5,0.3-4.1-0.5-5.2c-0.4-0.5-1.5,0.8-1.9,1.7C155.4,327.3,156.1,326.5,156.4,326.7z"/>
					<path class="st470" d="M154.6,336.5c-1.4,1.2-0.7-3.2-0.3-5.7c0.3-1.5,0.6-3,1.1-4.1c0.2-0.6,0.5-1,0.8-1.3
						c0.3-0.2,0.6-0.2,0.9,0.2c0.3,0.3,0.5,1,0.6,1.6c0.3,1.4,0.3,3.5,0.1,4.1c-0.2,0.8-1.1,2.3-2,3.8
						C155.5,335.5,155,336.1,154.6,336.5C154.6,336.4,154.6,336.5,154.6,336.5C154.4,336.6,154.6,336.5,154.6,336.5z M154.9,335.4
						c0.2-0.1,0.5-0.4,0.8-0.7c0.3-0.4,0.6-0.8,0.8-1.2c0.9-1.7,1.7-4.9,0.4-7.1c-0.2-0.2-0.4-0.2-0.7,0c-0.2,0.2-0.4,0.6-0.6,1
						c-0.6,1.6-1.6,5.6-1.6,7.2c0,0.5,0.1,0.8,0.3,0.9C154.4,335.6,154.6,335.5,154.9,335.4C154.9,335.4,154.9,335.4,154.9,335.4
						C155.1,335.3,154.9,335.4,154.9,335.4z"/>
				</g>
				<g>
					<path class="st467" d="M101.8,368.2c2.3-1.2,8-1.6,10.1-2.2c2.5-0.5,3.1-0.6,4.1-0.3c0.9,0,1.1-0.1,2.3,0.1
						c1.1,0.2,3.4,0.6,8,1c4.5,0.3,2.9,2.3-1.3,3.7c-4.1,1.5-7.8,2-10,2.2C111.5,373.4,97.2,370.6,101.8,368.2z"/>
					<path class="st468" d="M106,368.9c2.5-1.3,7.3-1.4,9.6-1.7c2.3-0.3,4.7,0,7.7,1c2.4,1.1-2.9,3.1-7.6,3.3
						C111.1,371.9,104.1,370.4,106,368.9z"/>
					<path class="st469" d="M120.9,368.1c2.1,1.1-1.7,2.9-6.9,3c-2.8,0.1-5.8-0.3-7.7-1c1.8,1,5.9,1.6,9.3,1.4
						c4.7-0.2,10-2.1,7.6-3.3c-1.2-0.5-4.8-1-5.4-1.3C118.2,367.2,120.3,367.8,120.9,368.1z"/>
					<path class="st470" d="M104.9,368.7c3.9-1.5,9.5-1.4,10.7-1.7c1-0.1,1.8-0.2,2.9,0c0.6,0.1,1.3,0.2,2.2,0.4
						c0.9,0.2,2.2,0.4,3,0.8c0.8,0.4,1,0.9,0.5,1.3c-1.1,1.1-6.6,2.4-8.5,2.4c-2.4,0.2-7.4-0.5-10-1.6
						C104.7,369.8,104.2,369.2,104.9,368.7C104.9,368.7,104.9,368.7,104.9,368.7C105.3,368.5,104.9,368.7,104.9,368.7z M107,369
						c-0.3,0.3-0.4,0.6,0,1c0.4,0.4,1.3,0.7,2.3,0.9c3.8,1.2,15.8-0.2,13.4-2.6c-0.5-0.3-1.2-0.5-2-0.6c-0.7-0.1-1.4-0.2-2-0.3
						c-2-0.1-4.4-0.1-7.3,0.3c-0.8,0.1-1.8,0.3-2.6,0.5C108,368.5,107.4,368.7,107,369C107,369,107,369,107,369
						C106.7,369.3,107,369,107,369z"/>
				</g>
				<g>
					<path class="st467" d="M141.9,300.3c-1.3,0.1-3.1-1.2-4.1-1.5c-1-0.4-1.2-0.5-1.3-1c-0.2-0.3-0.4-0.2-0.6-0.7
						c-0.2-0.4-0.6-1.3-1.6-2.9c-1.1-1.4,0.5-2.2,2.5-2c2,0.2,3.3,0.9,4,1.4C142.3,294.3,144.6,300,141.9,300.3z"/>
					<path class="st468" d="M141.1,298.7c-1.4,0.1-2.8-1.1-3.6-1.6c-0.8-0.5-1.3-1.3-1.5-2.8c0-1.4,2.6-1.1,4,0.1
						C141.5,295.3,142.5,298.3,141.1,298.7z"/>
					<path class="st469" d="M136.6,295c0.1-1.3,2.2-1.3,3.7,0c0.8,0.7,1.4,1.8,1.6,2.8c0.1-1.2-0.7-2.7-1.7-3.5
						c-1.4-1.2-4-1.5-4-0.1c0.1,0.6,0.7,2,0.7,2.3C136.8,296.3,136.6,295.3,136.6,295z"/>
					<path class="st470" d="M141.3,299.1c-2-0.1-3.4-1.8-4-1.9c-0.4-0.2-0.6-0.4-0.8-0.8c-0.1-0.2-0.2-0.5-0.4-0.8
						c-0.1-0.4-0.3-0.9-0.3-1.4c0-0.5,0.2-0.8,0.6-1c1-0.4,3.2,0.3,3.8,0.9c0.8,0.6,1.8,2.3,1.8,3.8
						C142.1,298.4,141.8,299,141.3,299.1C141.3,299.1,141.3,299.1,141.3,299.1C141.1,299.1,141.3,299.1,141.3,299.1z M140.9,298.3
						c0.3-0.1,0.5-0.3,0.6-0.6c0.1-0.3,0.1-0.8-0.1-1.2c-0.4-1.8-4.5-4.3-5.2-2.1c0,0.3,0.1,0.7,0.2,0.9c0.1,0.3,0.2,0.5,0.4,0.7
						c0.5,0.6,1.1,1.3,2.2,1.9c0.3,0.1,0.7,0.3,1,0.4C140.3,298.3,140.7,298.4,140.9,298.3C140.9,298.3,140.9,298.3,140.9,298.3
						C141.2,298.2,140.9,298.3,140.9,298.3z"/>
				</g>
				<g>
					<path class="st467" d="M121.8,358.2c1.3-0.4,3.3,0.4,4.3,0.4c1.1,0.1,1.3,0.2,1.5,0.6c0.3,0.2,0.4,0.1,0.7,0.5
						c0.3,0.4,0.9,1.1,2.3,2.4c1.4,1.1,0,2.3-2,2.6c-2,0.3-3.5,0-4.3-0.4C122.9,364.2,119.3,359.2,121.8,358.2z"/>
					<path class="st468" d="M123,359.7c1.4-0.5,3,0.4,3.9,0.7c0.9,0.3,1.6,0.9,2.2,2.3c0.4,1.3-2.3,1.7-3.9,0.9
						C123.4,363,121.7,360.4,123,359.7z"/>
					<path class="st469" d="M128.3,362.1c0.3,1.3-1.8,1.8-3.6,0.9c-1-0.5-1.8-1.4-2.2-2.3c0.2,1.2,1.3,2.4,2.6,2.9
						c1.6,0.8,4.3,0.4,3.9-0.9c-0.2-0.6-1.2-1.7-1.3-2.1C127.8,360.9,128.2,361.7,128.3,362.1z"/>
					<path class="st470" d="M122.7,359.3c1.9-0.4,3.8,0.9,4.3,0.8c0.4,0.1,0.7,0.2,1,0.6c0.2,0.2,0.4,0.4,0.6,0.7
						c0.2,0.3,0.5,0.8,0.7,1.2c0.1,0.4,0,0.9-0.4,1.1c-0.8,0.6-3.2,0.5-3.9,0.1c-0.9-0.4-2.3-1.8-2.7-3.2
						C122.1,360.1,122.2,359.5,122.7,359.3C122.7,359.3,122.7,359.3,122.7,359.3C122.9,359.2,122.7,359.3,122.7,359.3z M123.3,360
						c-0.2,0.2-0.4,0.4-0.4,0.8c0,0.4,0.1,0.8,0.4,1.2c0.8,1.6,5.4,3,5.6,0.7c0-0.3-0.2-0.6-0.4-0.9c-0.2-0.3-0.4-0.5-0.5-0.6
						c-0.6-0.5-1.4-1-2.6-1.3c-0.3-0.1-0.7-0.1-1.1-0.1C123.8,359.8,123.5,359.8,123.3,360C123.3,360,123.3,360,123.3,360
						C123,360.1,123.3,360,123.3,360z"/>
				</g>
				<g>
					<path class="st467" d="M86.5,311.2c0.5,4-1.4,6-7.6,10.3c-2.1,1.2-3.9,3.5-6,1.8c-2-1.4-2.7-1-3-3.4c-0.3-2.5,1.2-7.6,2.3-10.2
						c1.2-2.9,3.6-5.6,5.7-6.8C82.1,300.1,86,307.6,86.5,311.2z"/>
					<path class="st468" d="M83.8,309.7c0.3,2.5-2.1,4.6-5.1,7.6c-3,3-5.9,1.3-6.4-2.2c-0.4-3.2,1.9-6.3,4.9-8.7
						C80.3,304.1,83.4,306.9,83.8,309.7z"/>
					<path class="st469" d="M73.8,316.2c-0.4-3.1,1.4-6.9,4.5-9.1c1.7-1.1,3.3-0.9,4.6,0.3c-1.2-1.8-3.4-2.5-5.6-1
						c-3,2.4-5.3,5.6-4.9,8.7c0.2,1.7,2.5,4,3.5,4.2C75.4,318.9,73.9,317.2,73.8,316.2z"/>
					<path class="st470" d="M84.4,309.9c0.4,2.7-3.7,5.3-5.7,8c-1.3,1.2-2.6,1.9-3.9,1.6c-0.6-0.2-1.3-0.6-1.8-1.4
						c-0.6-0.8-1-1.9-1.1-3c-0.2-1.1,0-2.3,0.3-3.2c0.7-1.8,3.6-5.5,5-6.5c1.7-1.3,4.3-0.5,6,1.6C83.7,307.8,84.3,308.9,84.4,309.9
						C84.4,309.9,84.4,309.9,84.4,309.9C84.5,310.4,84.4,309.9,84.4,309.9z M83.1,309.6c-0.1-0.6-0.3-1.3-0.6-2
						c-0.4-0.7-0.9-1.3-1.5-1.5c-2.5-1.2-8.9,3.9-8.2,8.9c0.1,0.8,0.4,1.6,0.9,2.1c0.4,0.5,1,0.9,1.5,1c1.9,0.4,5.3-2.4,6.8-4.6
						c0.4-0.7,0.8-1.3,1-2C83.1,310.9,83.2,310.3,83.1,309.6C83.1,309.6,83.1,309.6,83.1,309.6C83,309,83.1,309.6,83.1,309.6z"/>
				</g>
				<g>
					<path class="st467" d="M100.8,355.9c-5.5,1.4-12.3,7.3-18.7,0.2c-4.4-4.7-9.8-11.1-10.9-15.2c-0.3-1.9,2.5-3.5,7.6-4.4
						c5-0.8,12.4-0.9,17.2,2.4C105.6,345.8,105.2,354.6,100.8,355.9z"/>
					<path class="st468" d="M96.8,353.3c-3.1,2-8.2,2-12.3-0.4c-4.1-2.5-6.9-6.7-6.5-9.9c0.3-3.1,6.7-4.9,13.4-1.5
						C98,345.2,99.8,351.4,96.8,353.3z"/>
					<path class="st469" d="M79.8,345.4c0.7-3,6.1-4.9,12.4-1.5c3.2,1.9,5.3,4.6,6,6.8c0.2-2.5-2.2-6.7-6.8-9.2
						c-6.7-3.4-13.1-1.6-13.4,1.5c-0.1,1.5,1.9,5.7,2.7,7.3C80.5,349.3,79.6,346.3,79.8,345.4z"/>
					<path class="st470" d="M97.8,354c-4.1,2.4-10.8,1.1-13.7-0.5c-1.8-1-3.4-2.5-4.7-4.3c-0.7-0.9-1.2-2-1.7-3.1
						c-0.4-1.1-0.7-2.4-0.5-3.5c0.2-1.1,0.8-2.1,1.9-2.7c2-1.4,9.9-1.5,13,0.4c3.9,2,6.6,7.5,7,10.5C99.3,352,98.9,353.2,97.8,354
						C97.8,354,97.8,354,97.8,354C97.3,354.3,97.8,354,97.8,354z M95.9,352.6c0.6-0.5,1.2-1.2,1.4-2.1c0.3-0.9,0.2-2-0.4-3.1
						c-1.8-5.6-17.3-10.6-18.1-4.1c-0.2,0.8,0,1.7,0.3,2.6c0.4,0.9,0.9,1.8,1.5,2.5c2.1,2.7,6.1,6.1,9.7,5.9c1,0,2.1-0.1,3.1-0.5
						C94.4,353.6,95.2,353.2,95.9,352.6C95.9,352.6,95.9,352.6,95.9,352.6C96.5,352.1,95.9,352.6,95.9,352.6z"/>
				</g>
				<g>
					<path class="st467" d="M88,299c-1.2,1.2-3.1,4.3-3.3,2.6c-0.1-1.1-0.2-2.6,0.2-3.9c0.2-0.6,0.9-1.6,1.9-2.6c1-1,2.3-2,2.7-1.5
						C90.2,294.7,88.9,297.9,88,299z"/>
					<path class="st468" d="M87.6,298.6c-0.8,1.1-1.7,1.8-2.1,1.5c-0.4-0.3-0.3-1.4,0.2-2.6c0.5-1.1,1.8-2.6,2.5-2.3
						C88.9,295.6,88.4,297.5,87.6,298.6z"/>
					<path class="st469" d="M85.7,298.1c0.5-1.2,1.7-2.6,2.4-2.2c0.3,0.2,0.3,0.9,0.1,1.6c0.4-0.9,0.5-2.1,0.1-2.4
						c-0.7-0.3-2.1,1.2-2.5,2.3c-0.2,0.6-0.4,1.8-0.5,2.2C85.3,299.4,85.6,298.4,85.7,298.1z"/>
					<path class="st470" d="M87.7,298.7c-1,1.4-2,1.8-2.3,1.7c-0.2-0.1-0.3-0.4-0.2-0.9c0-0.2,0-0.5,0.1-0.9
						c0.1-0.3,0.2-0.7,0.4-1.2c0.2-0.4,0.4-0.8,0.7-1.2c0.5-0.8,1.9-1.9,2.2-1.6c0.4,0.2,0.2,1.8-0.2,2.8
						C88.2,297.8,88,298.3,87.7,298.7C87.7,298.7,87.7,298.7,87.7,298.7C87.6,298.9,87.7,298.7,87.7,298.7z M87.6,298.5
						c0.2-0.3,0.4-0.6,0.5-0.9c0.2-0.4,0.3-0.7,0.4-1.1c0.4-1.8-1.6-1.4-2.6,1c-0.1,0.3-0.2,0.6-0.3,0.9c-0.1,0.3-0.1,0.5-0.1,0.7
						c0,0.7,0.2,1.3,0.9,0.8c0.2-0.2,0.4-0.3,0.6-0.6C87.2,299,87.4,298.8,87.6,298.5C87.6,298.5,87.6,298.5,87.6,298.5
						C87.7,298.2,87.6,298.5,87.6,298.5z"/>
				</g>
				<g>
					<path class="st467" d="M157.2,316.9c-0.4,1.1-1.4,2-3.9,3.7c-2.4,1.5-6.3,3.8-10.6,2.6c-5.7-1.8-9.4-9.3-7.3-14.5
						c1.9-5.3,12.3-10.2,16.8-4.7C156.5,309.6,157.7,315.6,157.2,316.9z"/>
					<path class="st468" d="M154.4,315.7c-0.7,1.8-5,3.6-9.5,3.1c-4.4-0.6-6.3-5.1-4.8-9.2c1.6-4,6-6.1,9.6-3.3
						C153.3,309.4,155.1,313.9,154.4,315.7z"/>
					<path class="st469" d="M141.1,312.1c1.5-4,5.6-6.2,9.2-3.6c1.9,1.4,3.3,3.4,4.2,5.1c-0.5-2-2.1-5.2-4.6-7.1
						c-3.6-2.8-8.1-0.7-9.6,3.3c-0.7,1.9,0.2,6.4,0.9,7.9C140.9,316.6,140.6,313.2,141.1,312.1z"/>
					<path class="st470" d="M155.1,316.1c-0.7,2.1-7.3,3.7-10.5,3.6c-1.9-0.1-3.5-1.1-4.6-2.8c-0.5-0.9-0.9-1.9-1-3.2
						c-0.1-1.3,0.1-2.7,0.6-4.1c0.5-1.4,1.4-2.6,2.3-3.5c2-2,6.7-2.2,8.2-0.6c2,1.6,3.8,5.9,4.7,8.1
						C155.2,314.4,155.4,315.4,155.1,316.1C155.1,316.1,155.1,316.1,155.1,316.1C155,316.4,155.1,316.1,155.1,316.1z M153.7,315.4
						c0.2-0.5,0.3-1.1,0.2-1.9c-0.1-0.7-0.3-1.6-0.8-2.5c-1.6-4.1-9.5-8.9-12.5-1.1c-0.4,1-0.5,2.1-0.4,3c0.1,1,0.4,1.8,0.9,2.6
						c1.5,2.6,6.2,4,9.2,2.5c0.8-0.3,1.6-0.7,2.2-1.2C153.1,316.4,153.4,315.9,153.7,315.4C153.7,315.4,153.7,315.4,153.7,315.4
						C153.9,314.9,153.7,315.4,153.7,315.4z"/>
				</g>
				<g>
					<path class="st467" d="M95.1,329.7c-0.2,0.6-0.8,1.1-2.2,2.1c-1.4,0.9-3.6,2.2-6,1.5c-3.2-1-5.3-5.3-4.1-8.2
						c1.1-3,7-5.8,9.5-2.6C94.8,325.5,95.4,329,95.1,329.7z"/>
					<path class="st468" d="M93.6,329c-0.4,1-2.8,2-5.4,1.8c-2.5-0.3-3.6-2.9-2.7-5.2c0.9-2.3,3.4-3.4,5.4-1.9
						C92.9,325.4,94,328,93.6,329z"/>
					<path class="st469" d="M86,327c0.9-2.3,3.2-3.5,5.2-2c1.1,0.8,1.9,2,2.4,2.9c-0.3-1.1-1.2-2.9-2.6-4c-2-1.6-4.6-0.4-5.4,1.9
						c-0.4,1.1,0.1,3.6,0.5,4.5C86,329.5,85.8,327.6,86,327z"/>
					<path class="st470" d="M94,329.2c-0.4,1.2-4.1,2.1-5.9,2c-1.1-0.1-2-0.6-2.6-1.6c-0.3-0.5-0.5-1.1-0.6-1.8
						c-0.1-0.7,0-1.6,0.3-2.3c0.3-0.8,0.8-1.5,1.3-2c1.1-1.1,3.8-1.2,4.7-0.4c1.1,0.9,2.1,3.3,2.7,4.6C94,328.3,94.1,328.8,94,329.2
						C94,329.2,94,329.2,94,329.2C93.9,329.4,94,329.2,94,329.2z M93.2,328.9c0.1-0.3,0.2-0.6,0.1-1.1c0-0.4-0.2-0.9-0.4-1.4
						c-0.9-2.3-5.4-5-7.1-0.6c-0.2,0.6-0.3,1.2-0.2,1.7c0.1,0.6,0.2,1,0.5,1.5c0.8,1.5,3.5,2.3,5.2,1.4c0.5-0.2,0.9-0.4,1.2-0.7
						C92.8,329.4,93,329.2,93.2,328.9C93.2,328.9,93.2,328.9,93.2,328.9C93.3,328.6,93.2,328.9,93.2,328.9z"/>
				</g>
				<g>
					<path class="st467" d="M152.1,345.3c-2.4,8.2-9.2,19.1-22.6,12.4c-9.4-4.3-21.9-6.3-23.3-11.1c-1.7-4.8,14.2-16.5,28.1-17.4
						C147.9,328.4,154.2,337.6,152.1,345.3z"/>
					<path class="st468" d="M145.9,344.3c-1.1,5.5-7.9,8.2-15.4,7.4c-7.5-0.8-14.2-3.7-14.8-7.7c-0.6-4,6.1-9.1,15.4-9.8
						C140.4,333.7,147,338.9,145.9,344.3z"/>
					<path class="st469" d="M119.2,345.5c-0.5-4.2,5.2-9,14.3-9.4c4.8-0.1,9,1.3,11.5,3.7c-2-3.5-7.4-5.9-13.9-5.5
						c-9.2,0.6-16,5.7-15.4,9.8c0.3,1.8,5.4,5.3,7.6,6.8C122.4,349.7,119.4,346.7,119.2,345.5z"/>
					<path class="st470" d="M147.5,344.6c-1.6,7-11.9,8.4-17,8.2c-3.2-0.2-6.4-1.1-9.4-2.4c-1.5-0.7-3-1.5-4.2-2.5
						c-1.2-1-2.1-2.3-2.3-3.7c-0.2-1.4,0.3-2.9,1.3-4.3c2-2.8,10.9-7,15.2-7.1c5.3-0.5,11.9,2.7,14.8,6.4
						C147.2,340.6,147.9,342.6,147.5,344.6C147.5,344.6,147.5,344.6,147.5,344.6C147.3,345.6,147.5,344.6,147.5,344.6z M144.3,344.1
						c0.2-1.2,0.2-2.5-0.3-3.9c-0.5-1.3-1.5-2.6-2.9-3.4c-5.9-4.2-25.4-0.7-24.2,7.1c0.1,1.1,0.8,2,1.7,2.8c1,0.8,2.2,1.5,3.5,2
						c4.5,1.7,12.8,4,17.2,1.5c1.3-0.7,2.5-1.5,3.4-2.7C143.6,346.5,144.1,345.3,144.3,344.1C144.3,344.1,144.3,344.1,144.3,344.1
						C144.5,342.9,144.3,344.1,144.3,344.1z"/>
				</g>
				<path class="st467" d="M106.2,333c0,1.1-0.9,3-4,2.4c-2-0.4-4.3-1.2-4.3-2.2c0-1.1,2.8-2.3,4.9-2.3
					C104.9,330.8,106.2,331.9,106.2,333z"/>
				<path class="st467" d="M107,311.5c0,0.5-0.3,1.3-1.3,1c-0.7-0.2-1.4-0.5-1.4-0.9c0-0.5,1-1,1.6-1C106.6,310.6,107,311,107,311.5
					z"/>
				<path class="st467" d="M108.2,309.2c-0.4,0.3-1.4,0.8-1.6,0c-0.1-0.5-0.1-1.2,0.3-1.5c0.4-0.3,1.4-0.2,1.6,0.2
					C108.9,308.3,108.7,308.8,108.2,309.2z"/>
				<path class="st467" d="M115.1,308.1c0,0.6-0.2,1.6-0.9,1.2c-0.5-0.2-1-0.6-1-1.2c0-0.6,0.7-1.2,1.1-1.2
					C114.8,307,115.1,307.5,115.1,308.1z"/>
				<path class="st467" d="M106.7,315.7c0,0.6-0.2,1.6-0.9,1.2c-0.5-0.2-1-0.6-1-1.2c0-0.6,0.7-1.2,1.1-1.2
					C106.4,314.6,106.7,315.1,106.7,315.7z"/>
				<path class="st467" d="M87,346.9c0.4,0.2,1,0.9,0.3,1.6c-0.5,0.5-1.1,1-1.5,0.8c-0.4-0.2-0.4-1.3-0.1-1.9
					C86,346.9,86.6,346.7,87,346.9z"/>
				<path class="st467" d="M85.4,344.9c0.1,0.5,0.1,1.6-0.7,1.4c-0.5-0.1-1.1-0.4-1.2-1c-0.1-0.5,0.4-1.3,0.9-1.4
					C85,343.8,85.3,344.3,85.4,344.9z"/>
				<path class="st467" d="M90.6,349.2c0.5,0.3,1.3,0.9,0.7,1.4c-0.4,0.3-1,0.6-1.5,0.4c-0.5-0.3-0.8-1.1-0.5-1.6
					C89.4,349,90.1,348.9,90.6,349.2z"/>
				<path class="st467" d="M145.3,315.4c0.3,0.1,0.9,0.4,0.6,1.1c-0.2,0.5-0.5,1-0.9,0.9c-0.3-0.1-0.6-0.8-0.5-1.3
					C144.6,315.6,145,315.4,145.3,315.4z"/>
				<path class="st467" d="M143.8,314.3c0.2,0.4,0.4,1.1-0.2,1.1c-0.4,0-0.9,0-1.1-0.4c-0.2-0.4,0-1,0.3-1.2
					C143.2,313.7,143.6,314,143.8,314.3z"/>
				<path class="st467" d="M148.4,316.2c0.4,0.1,1.1,0.3,0.8,0.8c-0.2,0.3-0.5,0.7-1,0.6c-0.4-0.1-0.8-0.6-0.7-1
					C147.5,316.3,148,316.1,148.4,316.2z"/>
				<path class="st467" d="M111,312.9c0,0.6-0.3,1.6-1.2,1.2c-0.6-0.2-1.3-0.6-1.3-1.2c0-0.6,0.9-1.2,1.5-1.2
					C110.6,311.8,111,312.3,111,312.9z"/>
				<path class="st467" d="M125.7,341.7c-0.3,0.3-1.1,0.8-1.7-0.1c-0.4-0.6-0.8-1.3-0.5-1.6c0.3-0.3,1.4-0.1,1.9,0.3
					C125.9,340.7,126,341.3,125.7,341.7z"/>
				<path class="st467" d="M128,340.6c-0.6,0-1.5-0.3-1.2-1c0.2-0.5,0.6-1,1.2-0.9c0.6,0,1.2,0.7,1.1,1.2
					C129.2,340.4,128.6,340.7,128,340.6z"/>
				<path class="st467" d="M122.7,344.7c-0.4,0.4-1.2,1.1-1.5,0.4c-0.2-0.5-0.4-1.1,0-1.5c0.4-0.4,1.3-0.5,1.6-0.2
					C123.2,343.6,123,344.3,122.7,344.7z"/>
				<path class="st467" d="M127.8,345.2c-0.4,0.4-1.2,1-1.7,0.2c-0.3-0.5-0.6-1.3-0.2-1.7c0.4-0.4,1.4-0.4,1.9,0
					C128.2,344.2,128.2,344.8,127.8,345.2z"/>
				<path class="st467" d="M112.7,316.3c0.4,0.4,1,1.2,0.3,1.5c-0.5,0.2-1.1,0.3-1.5-0.1c-0.4-0.4-0.4-1.3-0.1-1.7
					C111.7,315.7,112.3,315.9,112.7,316.3z"/>
				<path class="st467" d="M71.2,329.9c0,1.9,0.6,5.4-0.1,4.4c-0.5-0.7-2.1-1.9-2.1-3.8c0-1.9,1.8-4.3,2.2-4.3
					C71.6,326.1,71.2,327.9,71.2,329.9z"/>
				<path class="st467" d="M109.9,362.4c-0.9-0.7-1.8-2.6,0.5-4.6c1.5-1.3,3.5-2.7,4.4-2c0.8,0.7,0.1,3.7-1.2,5.3
					C112.4,362.7,110.8,363,109.9,362.4z"/>
				<path class="st467" d="M136.5,325c-0.8,0.7-1.7,0.5-3.3-2.2c-1.1-1.7-1.6-2.4-0.8-3.1c0.8-0.7,2.3-0.7,3.7,0.8
					C137.5,322,137.3,324.3,136.5,325z"/>
				<path class="st467" d="M133.8,302.4c-0.5,0.4-1.2,0-3-2.4c-1.2-1.6-1.7-2.2-1.3-2.7c0.5-0.4,1.6-0.1,3,1.4
					C133.9,300.2,134.2,302,133.8,302.4z"/>
				<path class="st467" d="M97.7,300.5c-0.1,0.6-4.5,2.1-7,1.5c-1.6-0.4,1.5-1.7,1.6-2.3c0.1-0.6,0.9-1.2,2.6-1
					C96.6,298.8,97.7,299.9,97.7,300.5z"/>
				<path class="st471" d="M139.4,289.7c1.8,5.2,2.8,10.8,2.8,16.6c0,28.3-22.9,51.2-51.2,51.2c-4.3,0-8.4-0.5-12.4-1.5
					c8.3,10.3,21.1,16.8,35.3,16.8c25.1,0,45.5-20.4,45.5-45.5C159.4,311.7,151.5,297.9,139.4,289.7z"/>
				<path class="st472" d="M97.2,289.7c3.5-0.7,19.1,2.1,15.5,4.6c-3.6,2.5-15.3,7.7-19.2,11.9c-3.9,4.2-7,9.6-5.9,13.2
					c1.1,3.7-2.6,8.3-8.1,7c-5.5-1.3-8.1,0.2-6.6-6.8c1.5-7,3.1-14,6.8-18.9C83.4,295.8,90.4,291,97.2,289.7z"/>
				<g>
					<path class="st473" d="M120.8,305.4c1.1,0.6,2,1.4,2.7,2.4c0.7,1,1.3,2.2,1.4,3.4c0.1,1.4-0.2,2.6-0.8,3.7
						c-0.6,1.1-1.5,2-2.6,2.5c0.7-1,1.3-1.9,1.7-3c0.4-1,0.6-2.1,0.5-3.1c-0.1-1.1-0.5-2.1-1-3.1
						C122.1,307.3,121.4,306.4,120.8,305.4z"/>
				</g>
				<g>
					<path class="st473" d="M83.3,339.5c1.5,0,3,0.1,4.5,0.4c1.5,0.3,3,0.6,4.4,1.4c1.3,0.9,2.4,1.9,3.5,3c1,1.1,1.9,2.5,2.2,4
						c-0.5-1.4-1.5-2.6-2.6-3.6c-1.1-1-2.2-2-3.4-2.8c-1.2-0.7-2.7-1.1-4.1-1.5C86.2,340,84.8,339.7,83.3,339.5z"/>
				</g>
				<g>
					<path class="st473" d="M115.6,342.3c0.6-1.6,1.7-2.8,3-3.9c1.3-1.1,2.7-1.9,4.3-2.6c1.5-0.6,3.1-1.2,4.8-1.5
						c1.7-0.2,3.3-0.3,5-0.2c-1.6,0.2-3.3,0.4-4.9,0.7c-1.6,0.3-3.1,0.9-4.6,1.5c-1.5,0.6-2.9,1.4-4.2,2.3
						C117.6,339.7,116.4,340.8,115.6,342.3z"/>
				</g>
			</g>
			<g>
				<g>
					<path class="st474" d="M916.4,227.6c5,15.8-66.1,52.6-158.8,82.2c-92.7,29.6-172,40.7-177.1,24.9c-5-15.8,66.1-52.6,158.8-82.2
						C832.1,222.9,911.4,211.7,916.4,227.6z"/>
					<path class="st475" d="M914,228.3c5,15.6-65.1,51.9-156.5,81c-91.4,29.1-169.6,40.1-174.5,24.5c-5-15.6,65.1-51.9,156.5-81
						C830.9,223.7,909,212.7,914,228.3z"/>
					<path class="st476" d="M911.6,229.1c4.9,15.4-64.2,51.1-154.3,79.9c-90.1,28.7-167.1,39.5-172,24.1
						c-4.9-15.4,64.2-51.1,154.3-79.9C829.7,224.5,906.7,213.7,911.6,229.1z"/>
					<path class="st477" d="M909.2,229.9c4.8,15.2-63.2,50.4-152,78.7c-88.8,28.3-164.7,38.9-169.5,23.8
						c-4.8-15.2,63.2-50.4,152-78.7C828.5,225.3,904.4,214.7,909.2,229.9z"/>
					<path class="st478" d="M906.8,230.6c4.8,15-62.3,49.7-149.8,77.5c-87.5,27.9-162.2,38.3-167,23.4c-4.8-15,62.3-49.7,149.7-77.5
						C827.3,226.1,902.1,215.7,906.8,230.6z"/>
					<path class="st479" d="M904.5,231.4c4.7,14.7-61.3,48.9-147.5,76.4c-86.1,27.5-159.8,37.8-164.5,23
						c-4.7-14.7,61.3-48.9,147.5-76.4C826.1,226.9,899.8,216.6,904.5,231.4z"/>
					<path class="st480" d="M902.1,232.1c4.6,14.5-60.4,48.2-145.2,75.2c-84.8,27-157.4,37.2-162,22.7
						c-4.6-14.5,60.4-48.2,145.2-75.2C824.9,227.8,897.4,217.6,902.1,232.1z"/>
					<path class="st481" d="M899.7,232.9c4.6,14.3-59.4,47.5-143,74.1c-83.5,26.6-154.9,36.6-159.5,22.3
						c-4.6-14.3,59.4-47.5,143-74.1C823.7,228.6,895.1,218.6,899.7,232.9z"/>
					<path class="st482" d="M897.3,233.7c4.5,14.1-58.5,46.7-140.7,72.9c-82.2,26.2-152.5,36-156.9,21.9
						c-4.5-14.1,58.5-46.7,140.7-72.9C822.5,229.4,892.8,219.6,897.3,233.7z"/>
					<path class="st483" d="M894.9,234.4c4.4,13.9-57.6,46-138.4,71.8c-80.9,25.8-150,35.4-154.4,21.6c-4.4-13.9,57.6-46,138.4-71.8
						C821.3,230.2,890.5,220.6,894.9,234.4z"/>
					<path class="st484" d="M892.5,235.2c4.3,13.7-56.6,45.3-136.2,70.6c-79.6,25.4-147.6,34.8-151.9,21.2
						c-4.4-13.7,56.6-45.3,136.2-70.6C820.1,231,888.2,221.5,892.5,235.2z"/>
					<path class="st485" d="M890.1,235.9c4.3,13.4-55.7,44.5-133.9,69.5c-78.2,24.9-145.1,34.2-149.4,20.8
						c-4.3-13.4,55.7-44.5,133.9-69.5C818.9,231.8,885.8,222.5,890.1,235.9z"/>
					<path class="st486" d="M887.7,236.7c4.2,13.2-54.7,43.8-131.6,68.3c-76.9,24.5-142.7,33.7-146.9,20.4
						c-4.2-13.2,54.7-43.8,131.6-68.3C817.8,232.6,883.5,223.5,887.7,236.7z"/>
					<path class="st487" d="M885.3,237.5c4.1,13-53.8,43.1-129.4,67.2c-75.6,24.1-140.2,33.1-144.4,20.1
						c-4.1-13,53.8-43.1,129.4-67.2C816.6,233.5,881.2,224.5,885.3,237.5z"/>
					<path class="st488" d="M883,238.2c4.1,12.8-52.8,42.3-127.1,66c-74.3,23.7-137.8,32.5-141.9,19.7c-4.1-12.8,52.8-42.3,127.1-66
						C815.4,234.3,878.9,225.4,883,238.2z"/>
					<path class="st489" d="M880.6,239c4,12.6-51.9,41.6-124.8,64.9c-73,23.2-135.3,31.9-139.3,19.3c-4-12.6,51.9-41.6,124.8-64.9
						C814.2,235.1,876.6,226.4,880.6,239z"/>
					<path class="st490" d="M878.2,239.7c3.9,12.4-50.9,40.9-122.6,63.7c-71.6,22.8-132.9,31.3-136.8,19
						c-3.9-12.3,50.9-40.9,122.6-63.7C813,235.9,874.2,227.4,878.2,239.7z"/>
					<path class="st491" d="M875.8,240.5c3.9,12.1-50,40.1-120.3,62.5c-70.3,22.4-130.5,30.7-134.3,18.6
						c-3.9-12.1,50-40.1,120.3-62.5C811.8,236.7,871.9,228.4,875.8,240.5z"/>
					<path class="st492" d="M873.4,241.3c3.8,11.9-49.1,39.4-118.1,61.4c-69,22-128,30.2-131.8,18.2c-3.8-11.9,49.1-39.4,118.1-61.4
						C810.6,237.5,869.6,229.4,873.4,241.3z"/>
					<path class="st493" d="M871,242c3.7,11.7-48.1,38.7-115.8,60.2c-67.7,21.6-125.6,29.6-129.3,17.9
						c-3.7-11.7,48.1-38.7,115.8-60.2C809.4,238.3,867.3,230.3,871,242z"/>
					<path class="st494" d="M868.6,242.8c3.7,11.5-47.2,37.9-113.5,59.1c-66.4,21.1-123.1,29-126.8,17.5
						c-3.7-11.5,47.2-37.9,113.5-59.1C808.2,239.1,865,231.3,868.6,242.8z"/>
					<path class="st495" d="M866.2,243.6c3.6,11.3-46.2,37.2-111.3,57.9c-65,20.7-120.7,28.4-124.3,17.1
						c-3.6-11.3,46.2-37.2,111.3-57.9C807,240,862.6,232.3,866.2,243.6z"/>
					<path class="st496" d="M863.8,244.3c3.5,11-45.3,36.5-109,56.8c-63.7,20.3-118.2,27.8-121.7,16.8c-3.5-11,45.3-36.5,109-56.8
						C805.8,240.8,860.3,233.3,863.8,244.3z"/>
					<path class="st497" d="M861.4,245.1c3.5,10.8-44.3,35.7-106.7,55.6c-62.4,19.9-115.8,27.2-119.2,16.4
						c-3.5-10.8,44.3-35.7,106.7-55.6C804.6,241.6,858,234.2,861.4,245.1z"/>
					<path class="st498" d="M859.1,245.8c3.4,10.6-43.4,35-104.5,54.5c-61.1,19.5-113.3,26.6-116.7,16c-3.4-10.6,43.4-35,104.5-54.5
						C803.4,242.4,855.7,235.2,859.1,245.8z"/>
					<path class="st499" d="M856.7,246.6c3.3,10.4-42.4,34.3-102.2,53.3c-59.8,19-110.9,26.1-114.2,15.7
						c-3.3-10.4,42.4-34.3,102.2-53.3C802.2,243.2,853.4,236.2,856.7,246.6z"/>
					<path class="st500" d="M854.3,247.4c3.2,10.2-41.5,33.5-99.9,52.2c-58.4,18.6-108.4,25.5-111.7,15.3
						c-3.2-10.2,41.5-33.5,99.9-52.2C801,244,851,237.2,854.3,247.4z"/>
					<path class="st501" d="M851.9,248.1c3.2,10-40.6,32.8-97.7,51C697.1,317.3,648.2,324,645,314c-3.2-10,40.6-32.8,97.7-51
						C799.8,244.8,848.7,238.2,851.9,248.1z"/>
					<path class="st502" d="M849.5,248.9c3.1,9.7-39.6,32.1-95.4,49.8c-55.8,17.8-103.6,24.3-106.7,14.5
						c-3.1-9.7,39.6-32.1,95.4-49.8C798.6,245.7,846.4,239.1,849.5,248.9z"/>
					<path class="st503" d="M847.1,249.6c3,9.5-38.7,31.3-93.1,48.7c-54.5,17.4-101.1,23.7-104.1,14.2c-3-9.5,38.7-31.3,93.1-48.7
						C797.4,246.5,844.1,240.1,847.1,249.6z"/>
					<path class="st504" d="M844.7,250.4c3,9.3-37.7,30.6-90.9,47.5c-53.2,16.9-98.7,23.1-101.6,13.8c-3-9.3,37.7-30.6,90.9-47.5
						C796.3,247.3,841.8,241.1,844.7,250.4z"/>
					<path class="st505" d="M842.3,251.2c2.9,9.1-36.8,29.9-88.6,46.4c-51.8,16.5-96.2,22.5-99.1,13.4c-2.9-9.1,36.8-29.9,88.6-46.4
						C795.1,248.1,839.4,242.1,842.3,251.2z"/>
					<path class="st506" d="M839.9,251.9c2.8,8.9-35.8,29.1-86.4,45.2c-50.5,16.1-93.8,22-96.6,13.1c-2.8-8.9,35.8-29.1,86.4-45.2
						C793.9,248.9,837.1,243.1,839.9,251.9z"/>
					<path class="st507" d="M837.6,252.7c2.8,8.7-34.9,28.4-84.1,44.1c-49.2,15.7-91.3,21.4-94.1,12.7c-2.8-8.7,34.9-28.4,84.1-44.1
						C792.7,249.7,834.8,244,837.6,252.7z"/>
					<path class="st508" d="M835.2,253.5c2.7,8.4-33.9,27.7-81.8,42.9c-47.9,15.3-88.9,20.8-91.6,12.3c-2.7-8.4,33.9-27.7,81.8-42.9
						C791.5,250.5,832.5,245,835.2,253.5z"/>
					<path class="st509" d="M832.8,254.2c2.6,8.2-33,26.9-79.6,41.8c-46.6,14.8-86.4,20.2-89.1,12c-2.6-8.2,33-26.9,79.6-41.8
						C790.3,251.3,830.2,246,832.8,254.2z"/>
					<path class="st510" d="M830.4,255c2.6,8-32.1,26.2-77.3,40.6c-45.2,14.4-84,19.6-86.5,11.6c-2.6-8,32.1-26.2,77.3-40.6
						C789.1,252.2,827.8,247,830.4,255z"/>
					<path class="st511" d="M828,255.7c2.5,7.8-31.1,25.5-75,39.5c-43.9,14-81.5,19-84,11.2c-2.5-7.8,31.1-25.5,75-39.5
						C787.9,253,825.5,247.9,828,255.7z"/>
					<path class="st512" d="M825.6,256.5c2.4,7.6-30.2,24.7-72.8,38.3c-42.6,13.6-79.1,18.4-81.5,10.9c-2.4-7.6,30.2-24.7,72.8-38.3
						C786.7,253.8,823.2,248.9,825.6,256.5z"/>
					<path class="st513" d="M823.2,257.3c2.3,7.4-29.2,24-70.5,37.1c-41.3,13.2-76.7,17.9-79,10.5c-2.3-7.4,29.2-24,70.5-37.1
						C785.5,254.6,820.9,249.9,823.2,257.3z"/>
					<path class="st514" d="M820.8,258c2.3,7.1-28.3,23.3-68.2,36c-40,12.7-74.2,17.3-76.5,10.1c-2.3-7.1,28.3-23.3,68.2-36
						C784.3,255.4,818.5,250.9,820.8,258z"/>
					<path class="st515" d="M818.4,258.8c2.2,6.9-27.3,22.5-66,34.8c-38.6,12.3-71.8,16.7-74,9.8c-2.2-6.9,27.3-22.5,66-34.8
						C783.1,256.2,816.2,251.9,818.4,258.8z"/>
					<path class="st516" d="M816,259.5c2.1,6.7-26.4,21.8-63.7,33.7c-37.3,11.9-69.3,16.1-71.5,9.4c-2.1-6.7,26.4-21.8,63.7-33.7
						C781.9,257,813.9,252.8,816,259.5z"/>
					<path class="st517" d="M813.7,260.3c2.1,6.5-25.4,21.1-61.4,32.5c-36,11.5-66.9,15.5-68.9,9c-2.1-6.5,25.4-21.1,61.4-32.5
						C780.7,257.9,811.6,253.8,813.7,260.3z"/>
					<path class="st518" d="M811.3,261.1c2,6.3-24.5,20.3-59.2,31.4c-34.7,11.1-64.4,14.9-66.4,8.6c-2-6.3,24.5-20.3,59.2-31.4
						C779.5,258.7,809.3,254.8,811.3,261.1z"/>
					<path class="st519" d="M808.9,261.8c1.9,6.1-23.6,19.6-56.9,30.2c-33.4,10.6-62,14.3-63.9,8.3c-1.9-6.1,23.6-19.6,56.9-30.2
						C778.3,259.5,806.9,255.8,808.9,261.8z"/>
				</g>
				<g>
					<path class="st520" d="M829.1,255.4c14.2,44.5-10.4,92.2-54.9,106.3c-44.5,14.2-92.2-10.4-106.3-54.9
						c-14.2-44.5,10.4-92.2,54.9-106.4C767.3,186.2,814.9,210.8,829.1,255.4z"/>
					<g class="st521">
						<path class="st522" d="M679.7,330.5c4.2,5.9,9.2,11.1,14.6,15.7c10.8,0.8,23.3,1,36.7-0.4c21.8-1.5,53.4-11.2,77.2-25.3
							c7.9-4.3,14.9-9.1,20.9-13.5c2.3-7.1,3.7-14.5,4-22.1c-15,11.8-32.3,22.6-52,30.7C745.8,330.1,710,333,679.7,330.5z
							 M791,323.2c0.5,0.9-16,10.6-38.6,15.7c-22.6,5.2-42,3.8-42,2.8c-0.1-1.1,18.8-1.4,41-6.6C773.7,330,790.6,322.1,791,323.2z"
							/>
						<path class="st522" d="M812.8,325.4c-22.8,15.3-54.3,26.5-76.7,28.3c-10.2,1.3-20.1,1.5-29.2,1c6.7,3.8,13.8,6.6,21.3,8.5
							c33.5-2.4,55.5-10.5,76.4-22c2.6-1.5,5.2-3,7.8-4.7c5.2-6.1,9.6-12.8,12.9-20.1C821.5,319.5,817.3,322.5,812.8,325.4z"/>
						<path class="st522" d="M707.2,279.5c-16.8,0.6-32.1-2.4-43.1-5.6c-0.1,0.7-0.1,1.5-0.2,2.2c12.5,3.5,25,5.3,35.6,5.6
							c24.6,0.4,40.7-3.9,56.5-10.2c15.4-6.5,31.7-16.3,48.2-32.5c3.4-3.4,6.8-7.1,10.1-11c-0.2-0.2-0.3-0.4-0.5-0.6
							c-4,4.5-6.9,7-7.1,6.8c-0.2-0.3,2.3-3.3,6-8.1c-0.8-0.9-1.6-1.8-2.4-2.7c-15.1,16.8-36.6,33.7-64.9,42.9
							c-28.3,9.3-57.2,8.2-80.7,2.6c-0.2,1.2-0.3,2.3-0.4,3.5c10.1,2.6,24.1,5.3,40.1,5.1c16.2,0.4,40.1-4.6,58.2-13.4
							c18.4-8.2,30.8-20,31.1-19.3c0.2-0.1-11.6,11.8-29.3,20.3C747,274,723.6,279.6,707.2,279.5z M720.9,275.4
							c-17.1,2.3-31.6,0.3-31.5,0.1c0-0.3,14.3,1.2,31.3-1.1c17-2.2,29.9-7.3,30-6.9C750.9,267.6,738,273.2,720.9,275.4z"/>
						<path class="st522" d="M697.3,263.9c18.7,0.2,34.2-1.9,47.7-5.7c13.6-4,26.4-10.1,42-23.8c5.4-4.8,11-10.9,16.2-17.8
							c-2.1-1.8-4.2-3.4-6.4-4.9c-4.8,6.6-11.3,13.7-19.3,19.9c-21.2,16.6-42.8,19.2-43.4,16.5c-0.8-2.9,18.2-9.8,37.9-25.4
							c6.7-5.2,12.5-10.7,17.5-15.6c-3.9-2.1-7.9-4-12-5.5c-0.2,0-0.4,0-0.5,0c-10.3,9.2-22.6,17.8-36.6,24.8
							c-24.3,11.7-48.3,15.2-66.9,15.8c-3,5.7-5.3,11.8-6.9,18.1C676.5,262.5,687.4,263.7,697.3,263.9z"/>
						<path class="st522" d="M782,306c16.2-8.7,32.9-20.9,50-38.6c-0.7-4-1.6-8-2.9-12c-1.7-5.3-3.8-10.2-6.4-14.9
							c-16.4,15-37.1,29.1-62.1,38.5c-34.3,12.9-68.9,13.2-96.5,9.1c0.4,4.6,1.1,9.3,2.3,13.9c11.8,2.2,30.4,5.4,52.2,3.7
							c18.5-0.7,45.4-8.2,65.8-19.4c20.7-10.5,34.8-24.8,35.8-23.2c0.9,0.7-11.6,16.4-31.6,28.4c-19.6,12.6-46.6,21.3-65.7,22.4
							c-21.9,2.2-41.8-1.6-54.6-5.7c1.2,3.5,2.6,6.9,4.2,10.2c14.9,3.6,29.8,5.1,42.5,4.7C744.4,322,763.7,315.5,782,306z"/>
					</g>
					<path class="st523" d="M787.2,288.2c-13.9,18.6-15.9,39.9-33.9,54c-8.6,6.7-17.4,14.7-24.2,21.2c5.5,1.3,11.1,2,16.8,2.2
						c8.7-11,13.5-16,21.5-19.2c18.7-7.6,21.3,0.4,51.2-22.2c6.7-5.1,10-29.8,14.1-34.9c0.7-7.3,0.5-14.8-0.8-22.3
						C817.3,278.1,795.9,276.7,787.2,288.2z M748.2,356.4c-2.7,3.3-6.1,5.3-6.8,4.6c-0.8-0.7,1.3-3.7,4-7c2.7-3.3,5.1-5.5,5.9-4.8
						C752.1,349.8,751,353.1,748.2,356.4z M796.4,304.1c1.2-1.4,2.3-2.2,3.1-1.5c0.8,0.7,0.9,2.5-0.3,3.9c-1.2,1.4-3.1,1.8-3.9,1.1
						C794.6,306.9,795.3,305.4,796.4,304.1z M809.4,326.2c-4.9,5.8-13.2,7.4-16.5,4.7c-3.3-2.8-0.4-8.9,4.5-14.7
						c4.9-5.8,9.9-9.2,13.2-6.4C813.9,312.6,814.3,320.4,809.4,326.2z M817.9,296.8c-2.9,3.5-7.8,4.4-9.8,2.8
						c-2-1.6-0.2-5.3,2.7-8.7c2.9-3.5,5.8-5.4,7.8-3.8C820.6,288.7,820.8,293.3,817.9,296.8z"/>
					<path class="st524" d="M663.9,277.5c4.6-4.2,8.8-10,12-18c11.1-27.6,21.7-30.1,22.6-36.3c0.4-2.3-0.2-5.9-1.1-9.6
						C677.3,228.8,664.9,252.3,663.9,277.5z M673,258.5c-1.4,3-2.9,4.7-4.1,4.1c-1.2-0.6-1.7-3.2-0.3-6.2c1.4-3,4.3-5.1,5.5-4.5
						C675.3,252.4,674.4,255.5,673,258.5z"/>
					<path class="st524" d="M778.8,202.1c-14.4,11.7-33.1,4.4-47.9,12.5c-29.3,16.1-18.6,43-35.3,66.1
						c-9.5,13.2-18.6,23.3-26.1,30.6c7,18.4,19.9,33.1,35.8,42.5c8.3-17.6,18-36.7,25-46.4c14.8-20.6,39.3-25.2,75.9-44.7
						c16-8.5,1.9-22.9,13.1-28.1C809.4,219.6,795.1,208.3,778.8,202.1z M703.1,321.7c-5.4,5.6-7.7,4.8-9.6,3c-1.9-1.8-2.7-4,2.7-9.6
						c5.4-5.6,14.9-12.6,16.8-10.8C714.9,306.1,708.5,316.1,703.1,321.7z M707.3,301.2c-2.2,2.4-4.4,1.3-6-0.2
						c-1.6-1.5-2.2-2.8,0.1-5.2c2.2-2.4,6.3-5.1,8-3.6C710.9,293.7,709.5,298.8,707.3,301.2z M761.1,226.4c2.6-2.2,5.5-3.1,6.5-1.9
						c1,1.2-0.2,3.9-2.8,6.2c-2.6,2.2-5.5,3.1-6.5,1.9C757.2,231.4,758.5,228.7,761.1,226.4z M765.1,267.1c-8.3,2.6-16.2,1-17.7-3.8
						c-1.5-4.7,4-10.7,12.3-13.3c8.3-2.6,16.2-1,17.7,3.8C778.9,258.5,773.4,264.4,765.1,267.1z M780.5,223
						c-2.3,0.6-4.6-0.6-5.1-2.7c-0.5-2.1,1-4.2,3.3-4.8c2.3-0.6,4.6,0.7,5.1,2.7C784.3,220.3,782.8,222.5,780.5,223z M805,237.7
						c-2.1,2.6-5.8,1.3-10.5-2.4c-4.7-3.7-8.6-8.2-6.5-10.8c2-2.6,9.3-2.4,14,1.3C806.6,229.4,807,235,805,237.7z"/>
					<path class="st525" d="M712.2,227.4c6.7,3.4,1.2,22.3-5.8,36c-7,13.7-12.8,17-19.5,13.6c-6.7-3.4-11.6-12.2-4.6-25.9
						C689.3,237.4,705.6,224,712.2,227.4z"/>
					<path class="st525" d="M699.9,294.6c-1.1,5.9-5.7,18.2-13,16.8c-7.2-1.4-14.4-15.9-13.3-21.8c1.1-5.9,7.9-9.6,15.2-8.2
						C696,282.8,701,288.7,699.9,294.6z"/>
					<path class="st520" d="M813.6,227c3.8,52.1-28.2,101.9-80.1,118.5c-10.9,3.5-21.9,5.2-32.8,5.4c20.8,14.2,47.7,19.1,73.6,10.9
						c44.5-14.2,69.1-61.8,54.9-106.3C825.7,244.7,820.3,235.1,813.6,227z"/>
					<path class="st525" d="M704.3,259.2c0.6-3.9,2.6-19.9-2.1-20.7c-4.7-0.8-14.4,14-15.1,17.9c-0.6,3.9,2.7,7.7,7.4,8.5
						C699.3,265.6,703.6,263.1,704.3,259.2z"/>
					<path class="st525" d="M762.9,361.7c-6,1.9-12.1,3.2-18.2,4c9.7,0.4,19.6-0.8,29.4-3.9c41.3-13.2,65.4-55,57.4-96.6
						C832.7,307.9,805.7,348,762.9,361.7z"/>
				</g>
			</g>
		</g>
	</g>
</g>
<g id="Layer_3">
	<g>
		<g>
			<g>
				<path class="st526" d="M184.4,65.8c-10.3-3.1-21.9-4.1-33.7-3.5l-1.8-35c14.8-0.7,30.3,0.3,45.7,4.9L184.4,65.8z"/>
			</g>
			<g>
				<path class="st527" d="M150.7,62.3c-5.9,0.3-11.9,1-17.8,2c-3,0.5-5.9,1.1-8.8,1.8c-1.5,0.3-2.9,0.7-4.3,1.1l-2.1,0.6l-1.7,0.5
					l-11-33.2l1.6-0.5l1.4-0.4l2.7-0.8c1.8-0.5,3.6-0.9,5.4-1.3c3.6-0.8,7.2-1.6,10.8-2.2c7.2-1.2,14.6-2.1,22-2.5L150.7,62.3z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st526" d="M229.7,108.8c-1.6-4.3-4.4-8.9-7.7-13.4c-3.3-4.5-7.2-9-11.5-13.4l25.2-24.3c5.1,5.2,10,10.8,14.5,17.1
					c4.5,6.3,8.8,13.2,12,21.4L229.7,108.8z"/>
			</g>
			<g>
				<path class="st527" d="M210.6,82l-1.6-1.6l-1.5-1.5c-0.9-0.9-1.8-1.7-2.8-2.5c-1.9-1.6-3.9-3-6.1-4.4c-4.3-2.6-9.1-4.7-14.2-6.3
					l10.1-33.5c7.7,2.3,15.3,5.6,22.4,9.9c3.6,2.2,7,4.6,10.2,7.3c1.6,1.3,3.2,2.8,4.7,4.2l2.1,2l1.9,2L210.6,82z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st526" d="M200.6,149c5.4-2.9,10.6-6,15.1-9.3c4.5-3.3,8.5-6.8,11.2-10.2l27.5,21.7c-5.6,7.1-11.9,12.4-18.2,16.9
					c-6.3,4.5-12.7,8.3-19,11.7L200.6,149z"/>
			</g>
			<g>
				<path class="st527" d="M227,129.5c1.4-1.7,2.4-3.4,3.1-5c0.7-1.6,1.2-3.1,1.4-4.6c0.2-1.6,0.2-3.2-0.1-5c-0.3-1.8-0.8-3.8-1.6-6
					l32.6-12.6c1.6,4.1,2.9,8.6,3.6,13.4c0.7,4.8,0.8,9.9,0.1,15c-0.7,5.1-2.2,10.1-4.3,14.5c-2.1,4.5-4.6,8.5-7.4,12L227,129.5z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st526" d="M128.5,181.5c12.6-5.8,25.1-11,37.3-16.2l13.8,32.2c-12.4,5.3-24.7,10.4-36.3,15.8L128.5,181.5z"/>
			</g>
			<g>
				<path class="st527" d="M165.7,165.3c12.1-5.2,24-10.4,34.9-16.3l16.6,30.8c-12.7,6.8-25.3,12.3-37.8,17.7L165.7,165.3z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st526" d="M59,234.4c4.5-5.6,9.3-11,14.3-16.2c1.2-1.3,2.5-2.6,3.8-3.8l1.9-1.9c0.7-0.7,1.5-1.4,2.3-2.1
					c3-2.8,6.1-5.2,9.3-7.5l20.6,28.3c-2.3,1.7-4.4,3.4-6.3,5.1c-0.5,0.4-0.9,0.9-1.4,1.3l-1.6,1.6c-1.1,1.1-2.2,2.2-3.2,3.3
					c-4.3,4.5-8.4,9.1-12.2,13.9L59,234.4z"/>
			</g>
			<g>
				<path class="st527" d="M90.6,202.8c6.3-4.5,12.6-8.4,18.9-11.8c6.3-3.5,12.7-6.5,19-9.5l14.7,31.8c-5.8,2.7-11.5,5.5-16.9,8.4
					c-5.4,2.9-10.5,6.1-15.1,9.4L90.6,202.8z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st526" d="M20.2,313c3.1-14.8,8.4-28.9,15-42l31.2,15.9c-5.5,10.7-9.6,21.9-12,33.3L20.2,313z"/>
			</g>
			<g>
				<path class="st527" d="M35.2,271c6.7-13.1,14.8-25.3,23.8-36.6l27.3,21.9c-7.7,9.6-14.5,19.8-19.9,30.6L35.2,271z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st526" d="M37.6,404.5c-5.6-6.3-10.4-13.7-13.7-21.9c-1.7-4-3-8.3-3.9-12.5c-0.8-3.9-1.4-7.6-1.9-11.4l34.7-4.3
					c0.3,2.8,0.8,5.7,1.4,8.3c0.5,2.3,1.2,4.5,2,6.6c1.7,4.3,4.3,8.3,7.5,12L37.6,404.5z"/>
			</g>
			<g>
				<path class="st527" d="M18.2,358.7c-1.9-15.3-1.1-30.8,2.1-45.6l34.3,7.2c-2.4,11.4-3,22.9-1.6,34.2L18.2,358.7z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st526" d="M121,441.9c-14.9-1.4-29.7-4.6-43.9-10.4L90.3,399c10.5,4.3,22.1,6.9,33.9,8L121,441.9z"/>
			</g>
			<g>
				<path class="st527" d="M77.1,431.4c-7.1-2.9-14.1-6.5-20.8-10.9c-6.7-4.4-13-9.7-18.6-16.1l26.3-23.1
					c6.5,7.4,15.9,13.4,26.4,17.7L77.1,431.4z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st526" d="M193.6,381.3c-3.6,5.9-7.3,11.8-11.3,17.7c-4,5.9-8.2,11.7-13.1,17.5l-26.7-22.6
					c3.7-4.4,7.4-9.4,10.9-14.6c3.5-5.2,6.9-10.6,10.3-16.2L193.6,381.3z"/>
			</g>
			<g>
				<path class="st527" d="M169.2,416.5c-2.4,2.9-5.1,5.8-8,8.6c-2.9,2.8-6.1,5.6-9.9,8.3c-3.8,2.6-8.2,5.1-13.6,6.8
					c-2.7,0.9-5.6,1.5-8.6,1.8c-1.5,0.1-3.1,0.2-4.6,0.1l-1.2-0.1l-1-0.1l-1.4-0.1l3.1-34.9l1.5,0.1c0.1,0,0.2,0,0.4,0
					c0.3,0,0.7-0.1,1.2-0.3c1-0.3,2.5-1,4.2-2.2c3.4-2.4,7.4-6.3,11.1-10.7L169.2,416.5z"/>
			</g>
		</g>
		<g>
			<g>
				<polygon class="st526" points="233.2,310.6 213.9,345.9 183.3,329 202.5,293.8 				"/>
			</g>
			<g>
				<path class="st527" d="M213.9,345.9c-6.5,11.8-13.1,23.6-20.3,35.4l-29.9-18.1c6.7-11.1,13.2-22.6,19.6-34.2L213.9,345.9z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st526" d="M272.7,241.8c-6.9,11.2-13.6,22.6-20.1,34l-30.4-17.4c6.7-11.7,13.6-23.4,20.7-35L272.7,241.8z"/>
			</g>
			<g>
				<path class="st527" d="M252.6,275.8c-6.6,11.5-13,23.1-19.4,34.8l-30.7-16.8c6.5-11.8,13-23.6,19.7-35.3L252.6,275.8z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st526" d="M317,177.2c-8,10.2-15.6,20.8-23,31.6l-28.9-19.7c7.7-11.3,15.8-22.4,24.2-33.3L317,177.2z"/>
			</g>
			<g>
				<path class="st527" d="M294.1,208.8c-7.4,10.8-14.5,21.8-21.3,33l-29.8-18.4c7.1-11.6,14.5-23.1,22.2-34.4L294.1,208.8z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st526" d="M369.2,120.6c-9.5,8.5-18.6,17.5-27.2,27L316.2,124c9.4-10.2,19.3-20.1,29.8-29.5L369.2,120.6z"/>
			</g>
			<g>
				<path class="st527" d="M342,147.6c-8.7,9.5-17,19.4-24.9,29.6l-27.6-21.5c8.5-10.9,17.4-21.5,26.7-31.8L342,147.6z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st526" d="M431.3,78.2c-11.2,5.5-22,11.9-32.3,19l-19.9-28.8c11.7-8,24-15.3,36.8-21.6L431.3,78.2z"/>
			</g>
			<g>
				<path class="st527" d="M399,97.1c-10.4,7.1-20.3,15-29.8,23.5l-23.3-26.1c10.5-9.3,21.5-18.1,33.2-26.2L399,97.1z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st526" d="M502.2,57.2c-12.3,1.4-24.5,3.9-36.3,7.5l-10-33.6c13.8-4.1,27.9-7,42.2-8.7L502.2,57.2z"/>
			</g>
			<g>
				<path class="st527" d="M465.9,64.6c-11.9,3.5-23.4,8.1-34.6,13.5l-15.4-31.4c12.8-6.3,26.3-11.6,40-15.6L465.9,64.6z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st526" d="M577.4,59.9c-6.3-1-12.6-1.8-19-2.5c-3.2-0.3-6.4-0.6-9.6-0.9c-3.1-0.3-6.2-0.4-9.4-0.6l1.5-35
					c3.6,0.2,7.1,0.4,10.7,0.7c3.5,0.3,6.9,0.6,10.4,1c6.9,0.7,13.9,1.6,20.8,2.7L577.4,59.9z"/>
			</g>
			<g>
				<path class="st527" d="M539.5,55.9c-12.5-0.5-24.9-0.2-37.2,1.3l-4.1-34.8c14.3-1.7,28.6-2.1,42.9-1.5L539.5,55.9z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st526" d="M649.4,81c-11.1-5.2-22.9-9.5-34.9-13l9.7-33.6c13.6,3.9,27.1,8.8,40.2,15L649.4,81z"/>
			</g>
			<g>
				<path class="st527" d="M614.5,68c-12.1-3.5-24.5-6.2-37.1-8.2l5.5-34.6c13.8,2.2,27.6,5.2,41.3,9.1L614.5,68z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st526" d="M704,124.7c-6.1-9.2-14.3-17.6-23.6-24.9l21.5-27.6c11.7,9.2,22.6,20.1,31.3,33.2L704,124.7z"/>
			</g>
			<g>
				<path class="st527" d="M680.3,99.8c-9.3-7.3-19.8-13.5-30.9-18.8l15-31.6c13.1,6.2,25.8,13.6,37.5,22.8L680.3,99.8z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st526" d="M709.5,183.2c4.7-9.1,6.6-19.1,5.5-29.2l34.8-3.5c0.8,8.3,0.5,16.8-1.1,25c-1.6,8.2-4.4,16.2-8.1,23.6
					L709.5,183.2z"/>
			</g>
			<g>
				<path class="st527" d="M715,154c-1-10.1-5-20.1-11.1-29.4l29.2-19.3c4.3,6.5,8.1,13.6,11,21.2c2.9,7.6,4.9,15.6,5.7,23.9
					L715,154z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st526" d="M657.7,225.5c11.1-4.8,21.5-10.7,30.6-17.9l21.6,27.5c-11.9,9.3-24.9,16.7-38.4,22.5L657.7,225.5z"/>
			</g>
			<g>
				<path class="st527" d="M688.4,207.6c4.7-3.7,8.7-7.3,12.3-11.4c3.5-4.1,6.5-8.4,8.9-13l31.2,15.9c-3.7,7.4-8.4,14.1-13.7,20.1
					c-5.2,6-11.2,11.4-17,15.9L688.4,207.6z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st526" d="M585,242.6c12.8-1.3,25.3-3.2,37.5-6l7.7,34.1c-13.9,3.1-27.8,5.2-41.7,6.6L585,242.6z"/>
			</g>
			<g>
				<path class="st527" d="M622.6,236.7c12.2-2.8,24-6.4,35.1-11.2l13.8,32.2c-13.5,5.8-27.4,10-41.2,13.1L622.6,236.7z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st526" d="M506.9,245.2c13.2,0.2,26.4,0.2,39.4-0.2l1,35c-13.7,0.4-27.3,0.4-40.8,0.2L506.9,245.2z"/>
			</g>
			<g>
				<path class="st527" d="M546.3,245c13-0.4,26-1.1,38.7-2.4l3.6,34.8c-13.9,1.4-27.6,2.2-41.3,2.5L546.3,245z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st526" d="M426.4,243.7c13.6,0,27.1,0.3,40.5,0.6l-0.9,35c-13.3-0.3-26.6-0.6-39.7-0.6L426.4,243.7z"/>
			</g>
			<g>
				<path class="st527" d="M467,244.3c13.4,0.3,26.7,0.7,39.9,0.9l-0.5,35c-13.5-0.2-27-0.5-40.3-0.9L467,244.3z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st526" d="M342,252.1c14.7-4.1,29-6,43-7.1l2.7,34.9c-12.6,1-25,2.7-36.1,5.8L342,252.1z"/>
			</g>
			<g>
				<path class="st527" d="M385,245c14-1.1,27.8-1.3,41.5-1.3l0,35c-13.1,0-26.1,0.3-38.7,1.2L385,245z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st526" d="M279.3,322.8c0.7-8,2.4-16.3,5.7-24.4c3.3-8.1,8.2-15.9,14.4-22.3l25.3,24.2c-3.1,3.3-5.5,7-7.2,11.3
					c-1.7,4.3-2.8,9.2-3.3,14.5L279.3,322.8z"/>
			</g>
			<g>
				<path class="st527" d="M299.4,276c6.1-6.4,13.3-11.5,20.5-15.3c7.3-3.8,14.7-6.6,22-8.7l9.6,33.6c-5.5,1.6-10.7,3.6-15.3,6
					c-4.6,2.4-8.5,5.2-11.6,8.5L299.4,276z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st526" d="M320.6,408.4c-7.9-3.6-15.6-8.8-21.8-15.6c-6.3-6.7-10.9-14.8-13.9-22.8l32.8-12.3
					c3.4,9,8.8,14.9,17.4,18.8L320.6,408.4z"/>
			</g>
			<g>
				<path class="st527" d="M284.9,370.1c-3-8.1-4.6-16.1-5.4-23.9c-0.4-3.9-0.6-7.7-0.6-11.6c-0.1-3.9,0.1-7.8,0.4-11.8l34.9,3.2
					c-0.2,2.6-0.3,5.4-0.3,8.2c0,2.9,0.2,5.7,0.5,8.5c0.6,5.5,1.6,10.7,3.3,15.2L284.9,370.1z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st526" d="M409.8,413.3c-13.8,2.2-27.9,3.8-42.7,3.8l0.1-35c12,0,24.5-1.4,37.1-3.4L409.8,413.3z"/>
			</g>
			<g>
				<path class="st527" d="M367.1,417.1c-7.4,0-15-0.5-22.7-1.7c-7.8-1.3-15.8-3.3-23.7-6.9l14.5-31.9c8.6,3.9,20.1,5.5,32,5.5
					L367.1,417.1z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st526" d="M489.7,395.4c-13,3.4-26.1,6.7-39.4,9.8l-7.9-34.1c12.8-3,25.6-6.2,38.5-9.6L489.7,395.4z"/>
			</g>
			<g>
				<path class="st527" d="M450.3,405.2c-13.3,3.1-26.7,5.9-40.5,8.1l-5.5-34.6c12.6-2,25.4-4.7,38.2-7.6L450.3,405.2z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st526" d="M567,375.7c-12.8,2.9-25.6,6.2-38.5,9.5l-8.8-33.9c13-3.4,26.2-6.7,39.4-9.7L567,375.7z"/>
			</g>
			<g>
				<polygon class="st527" points="528.5,385.2 489.7,395.4 480.9,361.5 519.7,351.3 				"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st526" d="M642.9,364c-12.3,0.4-25,2-37.7,4l-5.7-34.5c13.7-2.2,27.7-4,42.1-4.5L642.9,364z"/>
			</g>
			<g>
				<path class="st527" d="M605.2,368.1c-12.7,2.1-25.5,4.7-38.3,7.7l-7.8-34.1c13.3-3,26.7-5.8,40.4-8.1L605.2,368.1z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st526" d="M704.8,380.7c-3.4-3.4-7.3-6.2-11.8-8.5c-4.5-2.3-9.5-4.1-14.9-5.4l8.1-34c7.6,1.8,15.3,4.4,22.7,8.2
					c7.4,3.8,14.5,8.8,20.6,14.9L704.8,380.7z"/>
			</g>
			<g>
				<path class="st527" d="M678.1,366.8c-10.8-2.6-22.9-3.2-35.2-2.8l-1.3-35c14.4-0.5,29.3,0.1,44.6,3.7L678.1,366.8z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st526" d="M721,439.7c1.9-9.9,1-20.9-2-32l33.8-8.9c1.9,7.4,3.3,15.1,3.9,23c0.5,8,0.2,16.2-1.4,24.4L721,439.7z"/>
			</g>
			<g>
				<path class="st527" d="M719,407.7c-0.4-1.4-0.8-2.8-1.2-4.2c-0.4-1.4-0.8-2.6-1.3-3.9c-0.9-2.5-1.9-4.9-3.1-7.1
					c-2.3-4.5-5.2-8.5-8.6-11.8l24.7-24.8c6.1,6.1,11.1,13.1,14.9,20.5c1.9,3.7,3.5,7.5,4.9,11.2c0.7,1.9,1.3,3.8,1.9,5.7
					c0.6,1.8,1.1,3.6,1.6,5.5L719,407.7z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st526" d="M679.8,486.1c10.6-5.4,20.2-12,27.4-19.8l25.7,23.8c-5.5,5.9-11.5,11.1-17.8,15.5
					c-6.3,4.5-12.8,8.3-19.4,11.7L679.8,486.1z"/>
			</g>
			<g>
				<path class="st527" d="M707.2,466.3c7.2-7.8,11.9-16.7,13.8-26.6l34.4,6.5c-1.5,8.2-4.3,16.3-8.2,23.7
					c-3.9,7.4-8.8,14.2-14.3,20.1L707.2,466.3z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st526" d="M608.3,507.9c12.7-2.2,25.1-5,37.2-8.6l9.9,33.6c-13.6,4-27.3,7-41,9.4L608.3,507.9z"/>
			</g>
			<g>
				<path class="st527" d="M645.5,499.4c12-3.5,23.7-7.9,34.3-13.3l15.9,31.2c-13.2,6.7-26.8,11.7-40.4,15.7L645.5,499.4z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st526" d="M530.8,516c13.1-0.6,26.1-1.5,39.1-2.8l3.5,34.8c-13.7,1.4-27.3,2.3-41,2.9L530.8,516z"/>
			</g>
			<g>
				<path class="st527" d="M569.9,513.2c12.9-1.3,25.8-3,38.5-5.2l6.1,34.5c-13.7,2.4-27.4,4.2-41,5.6L569.9,513.2z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st526" d="M452,516.2c13.2,0.4,26.3,0.7,39.5,0.6l0.1,35c-13.6,0-27.2-0.2-40.8-0.7L452,516.2z"/>
			</g>
			<g>
				<path class="st527" d="M491.4,516.9c13.1,0,26.3-0.3,39.4-0.9l1.6,35c-13.6,0.6-27.3,0.9-40.9,0.9L491.4,516.9z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st526" d="M373.3,510.8l4.7,0.5l4.9,0.5l9.8,0.9c6.6,0.6,13.1,1.1,19.7,1.5l-2.4,34.9c-6.8-0.5-13.5-1-20.3-1.6
					l-10.2-0.9l-5.1-0.5l-5.2-0.6L373.3,510.8z"/>
			</g>
			<g>
				<path class="st527" d="M412.5,514.2c13.1,0.9,26.3,1.6,39.5,2l-1.2,35c-13.6-0.5-27.1-1.1-40.7-2.1L412.5,514.2z"/>
			</g>
		</g>
	</g>
</g>
<g id="Layer_4">
	<g>
		<g>
			<g>
				<g>
					<g>
						<path class="st363" d="M665.9,452.9c0.7,8.5,1.3,17.1,1.6,25.6c-0.6,0-1.2,0.1-1.7,0.1c0.4-7.7,0.6-18.5-3.6-25.2L665.9,452.9
							C666.1,455.1,665.9,452.9,665.9,452.9z"/>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path class="st363" d="M665.9,453.4C665.9,453.4,665.9,453.4,665.9,453.4C665.9,453.4,665.9,453.4,665.9,453.4z"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st363" d="M665.9,453.5C665.9,453.5,665.9,453.4,665.9,453.5C665.9,453.4,665.9,453.5,665.9,453.5z"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st363" d="M665.9,453.7C665.9,453.7,665.9,453.6,665.9,453.7C665.9,453.6,665.9,453.7,665.9,453.7z"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st363" d="M665.9,453.6c0,0,0-0.1,0-0.1C665.9,453.5,665.9,453.6,665.9,453.6z"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st363" d="M666,453.8C666,453.8,666,453.7,666,453.8C666,453.7,666,453.7,666,453.8z"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st363" d="M666,453.9C666,453.8,666,453.8,666,453.9C666,453.8,666,453.8,666,453.9z"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st363" d="M666,453.8C666,453.8,666,453.8,666,453.8C666,453.8,666,453.8,666,453.8z"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st363" d="M666,453.9C666,453.9,666,453.9,666,453.9L666,453.9z"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st363" d="M665.3,453.1C665.3,453.2,665.3,453.2,665.3,453.1c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0c0,0,0,0,0,0.1
								c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0C665.3,453.3,665.3,453.3,665.3,453.1C665.3,453.2,665.3,453.2,665.3,453.1
								c0,0,0-0.1,0-0.1l0,0c0.6,8.5,1.2,17.1,1.6,25.6c0.2,0,0.4,0,0.6,0c-0.3-8.5-0.9-17.1-1.6-25.6l-0.6,0.1
								C665.3,453.1,665.3,453.1,665.3,453.1z"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st363" d="M665.9,453.3c0,0,0-0.1,0-0.1C665.9,453.3,665.9,453.3,665.9,453.3z"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st363" d="M665.9,453.2c0-0.1,0-0.2,0-0.3C665.9,452.9,665.9,453.1,665.9,453.2z"/>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path class="st364" d="M665.8,478.7c0.6,0,1.2-0.1,1.7-0.1c-0.1-2.7-0.2-5.3-0.4-8c-0.4,0-0.8,0.1-1.2,0.1
							C666,473.6,665.9,476.3,665.8,478.7z"/>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path class="st366" d="M667.8,470.5c0,0.2-0.5,0.4-1.1,0.6c-0.7,0.1-1.2,0.1-1.2-0.1c0-0.2,0.5-0.4,1.1-0.6
								C667.2,470.3,667.7,470.3,667.8,470.5z"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st528" d="M666.4,470.6c0,0-0.1,0.1-0.3,0.2c-0.2,0-0.3,0-0.3,0c0,0,0.1-0.1,0.3-0.2
								C666.2,470.6,666.4,470.6,666.4,470.6z"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st368" d="M667.5,470.3C667.5,470.3,667.5,470.3,667.5,470.3c0,0.2-0.5,0.4-1.1,0.6c-0.4,0.1-0.8,0.1-1,0.1
								c0,0.2,0.6,0.2,1.2,0.1c0.7-0.1,1.2-0.4,1.1-0.6C667.7,470.4,667.7,470.4,667.5,470.3z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path class="st364" d="M671.6,479.8c0,0-1.5,0-3.3,0c-1.8,0-3.3,0-3.3,0s0-1.8,1.9-1.8C670.6,478,671.6,479.8,671.6,479.8z"
								/>
						</g>
					</g>
					<g>
						<g>
							<path class="st367" d="M666.3,478.1c0,0-0.1,0.1-0.1,0.1c0,0.4,1.4,0.8,3.1,0.8c0.4,0,0.8,0,1.2-0.1
								c-0.7-0.4-1.9-0.9-3.8-0.9C666.6,478,666.5,478,666.3,478.1z"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st364" d="M671.3,479.5c-0.5,0-1.7,0-3.1,0c-1.7,0-3,0-3.3,0c0,0.2,0,0.3,0,0.3s1.5,0,3.3,0c1.8,0,3.3,0,3.3,0
								S671.5,479.7,671.3,479.5z"/>
						</g>
					</g>
				</g>
			</g>
		</g>
		<g>
			<g>
				<g>
					<g>
						<path class="st363" d="M652,453.4c-1.6,8.4-2.8,16.7-3.6,25.2c-0.6,0-1.2-0.1-1.7-0.1c0.6-3.3,1-6.7,1.4-10.1
							c0.6-5.1,1.7-10.5,0.2-15.5L652,453.4C651.6,455.5,652,453.4,652,453.4z"/>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path class="st363" d="M651.2,453.6C651.2,453.6,651.2,453.5,651.2,453.6C651.2,453.5,651.2,453.6,651.2,453.6z"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st363" d="M651.2,453.7C651.2,453.7,651.2,453.6,651.2,453.7C651.2,453.6,651.2,453.7,651.2,453.7z"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st363" d="M651.3,453.4c-1.5,8.4-2.7,16.8-3.4,25.3c0.2,0,0.3,0,0.5,0c0.8-8.5,1.9-16.9,3.6-25.2L651.3,453.4z
								 M652,453.5C652,453.5,652,453.5,652,453.5C652,453.5,652,453.5,652,453.5z M651.8,454.3
								C651.8,454.3,651.8,454.3,651.8,454.3C651.8,454.3,651.8,454.3,651.8,454.3z M651.8,454.3
								C651.8,454.2,651.8,454.2,651.8,454.3C651.8,454.2,651.8,454.2,651.8,454.3z M651.9,453.8
								C651.9,453.8,651.9,453.8,651.9,453.8C651.9,453.8,651.9,453.8,651.9,453.8z M651.9,453.7
								C651.9,453.7,651.9,453.7,651.9,453.7C651.9,453.7,651.9,453.7,651.9,453.7z M651.9,453.6C651.9,453.6,652,453.6,651.9,453.6
								C652,453.6,651.9,453.6,651.9,453.6z"/>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path class="st364" d="M649.1,471.8c-0.4,0-0.9,0-1.3,0c0,0-0.1,0-0.1,0c-0.3,2.3-0.6,4.5-1,6.8c0.6,0,1.2,0.1,1.7,0.1
							C648.6,476.4,648.9,474.1,649.1,471.8z"/>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path class="st366" d="M649.6,471.7c0,0.2-0.6,0.3-1.3,0.2c-0.7-0.1-1.2-0.3-1.1-0.4c0-0.2,0.6-0.2,1.3-0.2
								C649.1,471.4,649.6,471.6,649.6,471.7z"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st528" d="M648.2,471.5c0,0-0.2,0.1-0.3,0c-0.2,0-0.3-0.1-0.3-0.1c0,0,0.2-0.1,0.3,0
								C648.1,471.4,648.3,471.4,648.2,471.5z"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st368" d="M649.4,471.5C649.4,471.5,649.4,471.5,649.4,471.5c0,0.2-0.6,0.3-1.3,0.2c-0.4,0-0.8-0.1-1-0.2
								c0,0.2,0.5,0.4,1.1,0.4c0.7,0.1,1.2,0,1.3-0.2C649.6,471.7,649.5,471.6,649.4,471.5z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path class="st364" d="M642.7,479.8c0,0,1.5,0,3.3,0c1.8,0,3.3,0,3.3,0s0-1.8-1.9-1.8C643.6,478,642.7,479.8,642.7,479.8z"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st367" d="M647.9,478.1c0,0,0.1,0.1,0.1,0.1c0,0.4-1.4,0.8-3.1,0.8c-0.4,0-0.8,0-1.2-0.1
								c0.7-0.4,1.9-0.9,3.8-0.9C647.7,478,647.8,478,647.9,478.1z"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st364" d="M642.9,479.5c0.5,0,1.7,0,3.1,0c1.7,0,3,0,3.3,0c0,0.2,0,0.3,0,0.3s-1.5,0-3.3,0c-1.8,0-3.3,0-3.3,0
								S642.7,479.7,642.9,479.5z"/>
						</g>
					</g>
				</g>
			</g>
		</g>
		<g>
			<g>
				<g>
					<g>
						<path class="st363" d="M667.4,453.5c0,2-4.4,5.9-9.8,5.9c-5.4,0-9.8-3.9-9.8-5.9c0-2,4.4-3.6,9.8-3.6
							C663,449.9,667.4,451.5,667.4,453.5z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st363" d="M665.6,451.5l-2.4,0.5c0.2,0.2,0.5,0.5,0.8,0.7c-0.8-0.1-1.9-0.2-3.3-0.2c-3.6,0-12.2,0.4-12.2,0.4
							s3.3,0.5,6.5,0.6c3.2,0.1,9-0.3,9,0.5c0,0.6-0.5,1.5-0.9,1.9c-1.4,1.2-3.5,3-10.3,2.6c1.4,0.6,3,1,4.7,1
							c5.4,0,9.8-3.9,9.8-5.9C667.4,452.8,666.8,452.1,665.6,451.5z"/>
					</g>
				</g>
			</g>
			<g class="st381">
				<g>
					<path class="st363" d="M650.3,456.3c-0.4-0.8,0.4-0.9,1.3-1.1c1-0.1,0.5,0,0.9,0.9c0.4,0.8,1.3,1.5,0.4,1.6
						C652,457.8,650.8,457.1,650.3,456.3z"/>
				</g>
			</g>
		</g>
		<g>
			<g>
				<g>
					<path class="st362" d="M657.4,393.8c-5.4,0-9.7-0.3-10.6,5.5c-1,5.7,3.3,16,10.6,16c7.3,0,11.6-10.3,10.6-16
						C667.1,393.5,662.7,393.8,657.4,393.8z"/>
				</g>
			</g>
			<g>
				<g>
					<path class="st362" d="M657.4,393.8c-5.4,0-9.7-0.3-10.6,5.5c-1,5.7,3.3,16,10.6,16c7.3,0,11.6-10.3,10.6-16
						C667.1,393.5,662.7,393.8,657.4,393.8z M657,414c-6.3-0.1-10.1-9.1-9.3-14.3c0.7-5.2,4.6-5.1,9.3-5c4.8,0.1,8.6-0.1,9.3,5.2
						C667.2,405.1,663.3,414,657,414z"/>
				</g>
			</g>
		</g>
		<g>
			<g>
				<g>
					<path class="st363" d="M640.6,418c-9.2,2.6-15.3,7.8-17.5,12.9c-0.4,0.7-1.5,4.1-1,6.9c0.3,2.9,2.1,5.1,3.1,5
						c0.5,0.2-1.8-8.7-0.7-10c1.2-4.8,4.7-4.8,14-5.1C646.3,427.4,648.9,415.2,640.6,418C640,418.2,640.6,418,640.6,418z"/>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path class="st363" d="M640.4,418C640.4,418,640.4,418.1,640.4,418C640.3,418.1,640.4,418,640.4,418z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st363" d="M640.3,418.1C640.3,418.1,640.3,418.1,640.3,418.1L640.3,418.1z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st363" d="M640.3,418.1L640.3,418.1L640.3,418.1z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st363" d="M640.6,418C640.6,418,640.5,418,640.6,418C640.5,418,640.5,418,640.6,418z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st363" d="M640.5,418C640.5,418,640.4,418,640.5,418C640.4,418,640.5,418,640.5,418z"/>
					</g>
				</g>
				<path class="st363" d="M622.3,433.4c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2-0.1,0.3c0,0,0,0,0,0c-0.1,0.8-0.2,1.7-0.2,2.6
					c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.3c0,0.1,0,0.3,0,0.4c0,0.1,0,0.2,0,0.3c0,0.3,0.1,0.6,0.1,0.9c0,0.1,0,0.2,0.1,0.2
					c0,0.1,0,0.1,0,0.2c0.3,0.9,0.7,1.7,1.1,2.4c0,0,0,0,0,0c0.1,0.1,0.1,0.2,0.2,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0,0.1,0.1,0.1,0.1
					c0.3,0.4,0.7,0.6,1,0.7c0,0,0.1,0,0.1,0c-1.1-1-2.2-3.5-2.2-6.1c-0.1-2.6,1-5.3,1.3-5.9c2.5-5.3,9.1-10.6,19.2-12.9
					c0,0-0.1,0-0.1,0c0.1,0,0.1,0,0.1,0c0.2-0.1,0.3-0.1,0.5-0.1c-0.8-0.2-1.9-0.2-3.2,0.3c-1.2,0.3-2.3,0.7-3.3,1.1
					c-7.4,2.8-12.2,7.4-14.2,11.8C622.9,431.2,622.5,432.1,622.3,433.4C622.3,433.4,622.3,433.4,622.3,433.4z"/>
			</g>
		</g>
		<g>
			<g>
				<g>
					<g>
						<path class="st363" d="M675.9,435.8c-4.5,8.6-8,19.2-18.3,19.2c-11.8,0-14.6-11.5-18.3-19.2c-1.8-3.7-3.3-7.6,0-12.7
							c3.2-4.9,10.1-10.1,18.3-10.4c8.2,0.3,15.1,5.5,18.3,10.4C679.2,428.1,677.9,432.1,675.9,435.8z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st363" d="M672.8,419.5c-1.9-1.7-4.1-3.2-6.5-4.5c-0.8-0.4-1.7-0.8-2.6-1.1c-4.8-0.8-9.8-0.4-14,0.7
							c-2.1,0.9-4,2.1-5.7,3.5c2,0.2,9.5-1.3,16.4,0.1c8.4,1.3,13.2,4.6,11.1,14.4c-2.4,11.2-5.6,14.7-11.2,16.3
							c-2.4,0.7-9.4,0.9-13.8,0.9c2.5,3.1,6,5.3,11.1,5.3c10.4,0,13.8-10.6,18.3-19.2c1.9-3.7,3.3-7.6,0-12.7
							C675.1,421.9,674,420.6,672.8,419.5z"/>
					</g>
				</g>
			</g>
			<path class="st529" d="M664.8,434.3c-0.8-0.5-1.8-0.8-3.1-0.8C662.7,433.9,663.7,434.2,664.8,434.3z M638.4,433.9
				c0.3,0.6,0.5,1.2,0.8,1.9c1.5,3.2,2.9,7,4.8,10.3c2.2-2.4,4-7.8,12.1-11.3c2.3-1,4-1.4,5.5-1.4c-2.8-1.3-5.4-3.6-9.9-3.7
				C646.9,429.6,641.9,431.9,638.4,433.9z M652.6,425.3c4.8-2.6,7.3-0.5,12.2-0.5c2.7,0,7.6-0.3,11.7-0.6c-0.2-0.4-0.4-0.7-0.7-1.1
				c-0.5-0.7-1-1.4-1.6-2.1c-0.1,0-0.3,0-0.4,0c-6.3,0.4-5-0.2-11.9-0.8c-6.9-0.6-6.1,1-11.9,1.9c-2.8,0.5-5.8-0.7-8.3-2.1
				c-1,1-1.8,2-2.5,3.1c-1,1.5-1.5,2.9-1.8,4.2c1.5,0.2,3,0.4,4.5,0.6C647.5,428.9,647.7,427.9,652.6,425.3z M677.8,427.7
				c-2.4,1.3-6.1,4.6-9.8,6c-1.2,0.5-2.2,0.6-3.2,0.6c3.3,1.9,4.3,6.8,7.4,9c1.3-2.5,2.5-5.1,3.8-7.5
				C677.2,433.3,678.3,430.7,677.8,427.7z"/>
			<g class="st530">
				<g>
					<path class="st363" d="M639.7,432.6c-2.1-5.6-0.2-8.6,3.7-9.1c4-0.4,2.6,2.7,3.2,9.2c0.8,6.5,3.3,11.9,1.3,11.6
						C646.2,443.9,642,438.1,639.7,432.6z"/>
				</g>
			</g>
		</g>
		<g>
			<path class="st366" d="M655.3,451.7c-0.1-0.2-0.2-0.5-0.3-0.9l0.4-0.1c0.1,0.4,0.2,0.7,0.3,0.8L655.3,451.7z M654.6,449.6
				c-0.1-0.3-0.2-0.6-0.3-0.9l0.4-0.1c0.1,0.3,0.2,0.6,0.3,0.9L654.6,449.6z M654,447.4c-0.1-0.3-0.2-0.6-0.2-0.9l0.4-0.1
				c0.1,0.3,0.2,0.6,0.2,0.9L654,447.4z M653.4,445.3c-0.1-0.3-0.1-0.6-0.2-0.9l0.4-0.1c0.1,0.3,0.1,0.6,0.2,0.9L653.4,445.3z
				 M652.9,443.1c-0.1-0.3-0.1-0.6-0.2-0.9l0.4-0.1c0.1,0.3,0.1,0.6,0.2,0.9L652.9,443.1z M652.5,440.9c0-0.3-0.1-0.6-0.1-0.9
				l0.4-0.1c0,0.3,0.1,0.6,0.1,0.9L652.5,440.9z M652.2,438.7c0-0.3-0.1-0.6-0.1-0.9l0.4-0.1c0,0.3,0.1,0.6,0.1,0.9L652.2,438.7z
				 M651.9,436.4c0-0.3-0.1-0.6-0.1-0.9l0.4,0c0,0.3,0,0.6,0.1,0.9L651.9,436.4z M651.8,434.2c0-0.3,0-0.6,0-0.9l0.4,0
				c0,0.3,0,0.6,0,0.9L651.8,434.2z M652.2,432l-0.4,0c0-0.2,0-0.4,0-0.6l0-0.3l0.4,0l0,0.3C652.2,431.6,652.2,431.8,652.2,432z
				 M652.2,429.7l-0.4,0c0-0.3,0-0.6,0-0.9l0.4,0C652.2,429.1,652.2,429.4,652.2,429.7z M652.3,427.5l-0.4,0c0-0.3,0.1-0.6,0.1-0.9
				l0.4,0C652.4,426.9,652.4,427.2,652.3,427.5z M652.6,425.3l-0.4-0.1c0-0.3,0.1-0.6,0.1-0.9l0.4,0.1
				C652.6,424.7,652.6,425,652.6,425.3z M652.9,423.1l-0.4-0.1c0.1-0.3,0.1-0.6,0.2-0.9l0.4,0.1C653,422.5,652.9,422.8,652.9,423.1z
				 M653.4,421l-0.4-0.1c0.1-0.3,0.2-0.6,0.3-0.9l0.4,0.1C653.5,420.4,653.4,420.7,653.4,421z M654.1,418.9l-0.4-0.2
				c0.1-0.3,0.3-0.6,0.4-0.8l0.4,0.2C654.3,418.4,654.2,418.6,654.1,418.9z M655.2,417.1l-0.3-0.3c0.5-0.5,0.8-0.6,0.8-0.6l0.1,0.4
				l-0.1-0.2l0.1,0.2C655.8,416.6,655.5,416.7,655.2,417.1z"/>
		</g>
		<g>
			<g>
				<path class="st369" d="M658,411.1c0,2-0.2,6-0.4,6c-0.2,0-0.4-4-0.4-6c0-2,0.2-3.7,0.4-3.7C657.8,407.4,658,409,658,411.1z"/>
			</g>
		</g>
		<g>
			<g>
				<g>
					<path class="st363" d="M670.7,415.6c9.6,0.9,16.4,5,19.5,9.6c0.5,0.6,2.2,3.8,2.2,6.6c0.2,2.9-1.2,5.4-2.1,5.5
						c-0.4,0.2,0.2-8.9-1-10c-2-4.5-5.5-3.9-14.7-2.5C666.7,425.9,662,414.3,670.7,415.6C671.3,415.7,670.7,415.6,670.7,415.6z"/>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path class="st363" d="M670.9,415.6C670.9,415.6,670.9,415.6,670.9,415.6C670.9,415.6,670.9,415.6,670.9,415.6z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st363" d="M670.9,415.6C670.9,415.6,670.9,415.6,670.9,415.6L670.9,415.6z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st363" d="M670.9,415.6L670.9,415.6L670.9,415.6z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st363" d="M670.7,415.6C670.7,415.6,670.7,415.6,670.7,415.6C670.7,415.6,670.7,415.6,670.7,415.6z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st363" d="M670.8,415.6C670.8,415.6,670.8,415.6,670.8,415.6C670.8,415.6,670.8,415.6,670.8,415.6z"/>
					</g>
				</g>
				<path class="st363" d="M667.4,415.9c0.2,0,0.3,0,0.5,0c0,0,0.1,0,0.1,0c0,0-0.1,0-0.1,0c10.4,0.5,17.9,4.6,21.2,9.3
					c0.4,0.5,2,3,2.3,5.5c0.5,2.6-0.2,5.2-1.1,6.4c0,0,0.1,0,0.1,0c0.3-0.1,0.6-0.4,0.9-0.9c0,0,0-0.1,0.1-0.1
					c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0,0c0.3-0.7,0.6-1.6,0.7-2.5c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3
					c0-0.3,0-0.6,0-1c0-0.1,0-0.2,0-0.3c0-0.1,0-0.3,0-0.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2c-0.1-0.9-0.4-1.7-0.7-2.5
					c0,0,0,0,0,0c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.2-0.1-0.2c0,0,0,0,0-0.1c-0.5-1.1-1-2-1.2-2.3c-2.5-3.7-7.5-7.1-14.4-8.8
					c-1.6-0.4-3.3-0.7-5.1-0.8C669.3,415.4,668.2,415.5,667.4,415.9z"/>
			</g>
			<g>
				<g>
					<path class="st366" d="M673.6,425.1c-0.2,0.4-1.6,0.2-2.8-0.6c-2.9-1.8-4.6-3.6-4.9-6.1c-0.2-1.1,0.3-2.3,0.8-2.5l0,0
						c0.5-0.2,0.9,0.4,1.1,1.3c0.5,2.2,2,4,4.4,5.8C673.2,423.8,673.9,424.7,673.6,425.1L673.6,425.1z"/>
				</g>
				<g>
					<path class="st367" d="M668.4,420.5c-0.2,0.1-0.5,0-0.7-0.3c-0.4-0.7-0.7-1.5-0.9-2.3c-0.1-0.3,0-0.7,0.2-0.8l0,0
						c0.2-0.1,0.4,0.1,0.4,0.4c0.2,0.8,0.5,1.5,0.9,2.2C668.6,420.1,668.6,420.4,668.4,420.5L668.4,420.5z"/>
				</g>
				<g>
					<path class="st368" d="M673,423.7c0.1,0.2,0.1,0.4,0,0.5c-0.3,0.4-1.6,0.2-2.7-0.6c-2.5-1.8-3.9-3.7-4-6.3c0-0.3,0-0.6,0.1-0.9
						c-0.3,0.5-0.5,1.3-0.4,2c0.4,2.5,2,4.4,4.9,6.1c1.2,0.7,2.6,1,2.8,0.6C673.8,424.8,673.5,424.3,673,423.7z"/>
				</g>
			</g>
		</g>
		<g>
			<g>
				<g>
					<g>
						<path class="st364" d="M690.6,443.6c-1,0.2-1.8,0-2.1-1.2c-0.2-1.1,0.3-2.8,1.2-3c1-0.2,2.1,1.1,2.3,2.3
							C692.3,442.8,691.6,443.4,690.6,443.6z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st364" d="M689.6,443.2c0.1,1.3,0.3,3.7,0,3.7c-0.2,0-0.8-2.4-0.9-3.6c-0.1-1.3,0-2.3,0.2-2.3
							C689.3,440.9,689.5,441.9,689.6,443.2z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st364" d="M688.6,442.3c-0.6,1.1-0.9,1.3-1.1,1.2c-0.2-0.1-0.2-0.5,0.4-1.6c0.6-1.1,1.3-1.9,1.5-1.8
							C689.6,440.2,689.3,441.2,688.6,442.3z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st364" d="M690.8,443.4c0.1,1.3,0.2,3.2,0,3.2c-0.2,0-0.7-1.9-0.8-3.2c-0.1-1.3,0-2.3,0.2-2.3
							C690.4,441.1,690.7,442.2,690.8,443.4z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st364" d="M691.6,442.6c0.2,1.3,0.3,3.4,0.1,3.5c-0.2,0-0.7-2.1-0.9-3.3c-0.2-1.3-0.2-2.3,0-2.3
							C691,440.4,691.3,441.4,691.6,442.6z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st364" d="M692.2,442.2c0.4,1.1,0.9,2.7,0.7,2.7c-0.2,0.1-1-1.4-1.4-2.5c-0.4-1.1-0.6-2-0.4-2.1
							C691.3,440.3,691.8,441.1,692.2,442.2z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st364" d="M689.4,444.6c0,0.5,0.2,1.4,0.1,1.4c-0.1,0-0.4-0.9-0.4-1.4c0-0.5,0-0.9,0.2-0.9
							C689.4,443.7,689.4,444.1,689.4,444.6z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st364" d="M690.5,444.4c0,0.5,0.3,1.4,0.2,1.4c-0.1,0-0.4-0.9-0.4-1.4c0-0.5,0-0.9,0.1-0.9
							C690.5,443.5,690.4,443.9,690.5,444.4z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st364" d="M691.4,444.2c0,0.5,0.3,1.3,0.2,1.3c-0.1,0-0.3-0.8-0.4-1.3c0-0.5,0-0.8,0.1-0.8
							C691.4,443.4,691.4,443.8,691.4,444.2z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st364" d="M692.3,443.4c0.1,0.4,0.4,1,0.3,1c-0.1,0-0.4-0.6-0.5-1c-0.1-0.4-0.1-0.7,0-0.7
							C692.2,442.6,692.2,443,692.3,443.4z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st364" d="M688.1,442.2c-0.2,0.3-0.4,1.1-0.5,1c-0.1,0,0.1-0.8,0.3-1.1c0.2-0.3,0.4-0.6,0.5-0.5
							C688.5,441.6,688.3,441.8,688.1,442.2z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<path class="st364" d="M690.1,433.6c-0.5,2.9-1.2,7.5-0.3,7.7c1.4,0.3,1.9-3.8,1.9-7.2C691.2,433.8,690.6,433.7,690.1,433.6z"
						/>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path class="st366" d="M693.2,434.1c0.1,0.3-0.9,0.8-2.3,0.6c-1.4-0.2-2.4-1.3-2.2-1.5c0.2-0.3,1.2,0.3,2.4,0.5
							C692.2,433.8,693.1,433.8,693.2,434.1z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st367" d="M690.7,433.8c0,0.1-0.3,0.1-0.7-0.1c-0.3-0.1-0.6-0.3-0.6-0.4c0-0.1,0.3,0,0.7,0.1
							C690.4,433.6,690.7,433.8,690.7,433.8z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st368" d="M692.8,433.8C692.8,433.9,692.8,433.9,692.8,433.8c0.1,0.3-0.9,0.8-2.3,0.4c-0.8-0.2-1.5-0.7-1.8-1.1
							c-0.2,0.2,0.8,1.3,2.2,1.5c1.4,0.3,2.4-0.3,2.3-0.6C693.2,434,693,433.9,692.8,433.8z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<path class="st364" d="M690.3,436.4c-0.1,0.7-0.3,2-0.4,2c-0.1,0-0.1-1.4,0-2.1c0.1-0.7,0.2-1.3,0.3-1.3
						C690.4,435.2,690.4,435.7,690.3,436.4z"/>
				</g>
			</g>
		</g>
		<g>
			<g>
				<g>
					<g>
						<path class="st364" d="M627.1,449.6c-0.8,0.5-1.7,0.6-2.3-0.4c-0.6-1-0.7-2.7,0.1-3.2c0.8-0.5,2.3,0.3,2.9,1.3
							C628.4,448.3,627.9,449.1,627.1,449.6z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st364" d="M626,449.6c0.5,1.2,1.6,3.3,1.3,3.4c-0.2,0.1-1.6-1.9-2.1-3.1c-0.5-1.2-0.8-2.2-0.6-2.3
							C624.9,447.6,625.5,448.5,626,449.6z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st364" d="M624.8,449.2c-0.2,1.3-0.4,1.6-0.6,1.5c-0.2,0-0.4-0.4-0.2-1.7c0.2-1.3,0.6-2.2,0.8-2.2
							C625,446.9,625,447.9,624.8,449.2z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st364" d="M627.2,449.4c0.5,1.2,1.4,2.9,1.1,3c-0.2,0.1-1.4-1.5-1.9-2.7c-0.5-1.2-0.8-2.2-0.6-2.3
							C626,447.4,626.6,448.3,627.2,449.4z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st364" d="M627.6,448.4c0.7,1.1,1.5,3.1,1.3,3.2c-0.2,0.1-1.4-1.7-2-2.8c-0.7-1.1-1-2.1-0.8-2.2
							C626.3,446.5,627,447.3,627.6,448.4z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st364" d="M628.1,447.8c0.8,0.9,1.8,2.2,1.6,2.3c-0.2,0.1-1.4-1-2.2-1.8c-0.8-0.9-1.3-1.7-1.1-1.8
							C626.5,446.4,627.3,446.9,628.1,447.8z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st364" d="M626.3,451c0.2,0.5,0.7,1.2,0.6,1.3c-0.1,0-0.7-0.7-0.9-1.2c-0.2-0.5-0.3-0.9-0.2-0.9
							C626,450.1,626.1,450.5,626.3,451z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st364" d="M627.2,450.4c0.2,0.5,0.8,1.2,0.7,1.3c-0.1,0-0.7-0.7-0.9-1.2c-0.2-0.5-0.3-0.9-0.2-0.9
							C626.9,449.6,627,450,627.2,450.4z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st364" d="M628,450c0.2,0.4,0.7,1.1,0.6,1.2c-0.1,0-0.6-0.7-0.8-1.1c-0.2-0.4-0.3-0.8-0.2-0.8
							C627.8,449.2,627.8,449.6,628,450z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st364" d="M628.6,448.8c0.2,0.3,0.8,0.8,0.7,0.8c-0.1,0.1-0.6-0.4-0.9-0.7c-0.2-0.3-0.4-0.6-0.3-0.7
							C628.2,448.2,628.4,448.5,628.6,448.8z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st364" d="M624.2,449.2c0,0.4,0,1.1-0.1,1.1c-0.1,0-0.1-0.8-0.1-1.1c0-0.4,0.2-0.7,0.3-0.7
							C624.4,448.6,624.3,448.9,624.2,449.2z"/>
					</g>
				</g>
				<g class="st365">
					<g>
						<path class="st364" d="M626.7,449.1c-0.5,0.3-1,0.3-1.3-0.2c-0.3-0.6-0.4-1.5,0.1-1.8c0.5-0.3,1.3,0.2,1.6,0.7
							C627.4,448.4,627.1,448.8,626.7,449.1z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<path class="st364" d="M623,440.5c0.6,2.9,1.6,7.4,2.4,7.3c1.5-0.2,0.4-4.2-0.7-7.4C624.1,440.3,623.6,440.4,623,440.5z"/>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path class="st366" d="M626.1,439.8c0.2,0.2-0.5,1.1-2,1.4c-1.4,0.3-2.7-0.3-2.6-0.6c0.1-0.3,1.2-0.2,2.4-0.4
							C625.1,439.9,625.9,439.6,626.1,439.8z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st367" d="M623.7,440.5c0,0.1-0.3,0.2-0.6,0.2c-0.4,0-0.7-0.1-0.7-0.2c0-0.1,0.3-0.1,0.7-0.1
							C623.4,440.4,623.7,440.4,623.7,440.5z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st368" d="M625.7,439.8C625.7,439.8,625.7,439.8,625.7,439.8c0.1,0.2-0.6,1-2,1.2c-0.9,0.1-1.7-0.1-2.1-0.4
							c-0.1,0.3,1.2,0.9,2.6,0.6c1.4-0.3,2.1-1.1,2-1.4C626,439.7,625.9,439.7,625.7,439.8z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<path class="st364" d="M624.3,443.1c0.2,0.7,0.5,2,0.4,2c-0.1,0-0.5-1.2-0.7-1.9c-0.2-0.7-0.2-1.3-0.1-1.3
						C623.9,441.8,624.1,442.4,624.3,443.1z"/>
				</g>
			</g>
		</g>
		<g>
			<g>
				<g>
					<path class="st369" d="M666.2,395.6l4.3-5.4c0.1-0.1,0.2-0.1,0.2,0c0.1,0.1,0.1,0.2,0,0.2l-3.6,5.9c-0.2,0.3-0.5,0.4-0.8,0.2
						C666.1,396.3,666,395.9,666.2,395.6C666.2,395.6,666.2,395.6,666.2,395.6z"/>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path class="st375" d="M667.4,396.8c-0.1,0.2-0.4,0.2-0.5,0.1l-1.2-0.9c-0.2-0.1-0.2-0.4-0.1-0.5l0,0c0.1-0.2,0.4-0.2,0.5-0.1
							l1.2,0.9C667.5,396.4,667.5,396.7,667.4,396.8L667.4,396.8z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st374" d="M666.5,396c0,0.1-0.1,0.1-0.2,0l-0.4-0.3c-0.1,0-0.1-0.1,0-0.2l0,0c0-0.1,0.1-0.1,0.2,0l0.4,0.3
							C666.5,395.9,666.5,396,666.5,396L666.5,396z"/>
					</g>
				</g>
				<g class="st365">
					<g>
						<path class="st376" d="M667.4,396.4c0,0.1,0,0.1-0.1,0.2c-0.1,0.2-0.4,0.2-0.5,0.1l-1.2-0.9c0,0-0.1-0.1-0.1-0.1
							c0,0.1,0,0.3,0.1,0.4l1.2,0.9c0.2,0.1,0.4,0.1,0.5-0.1C667.5,396.7,667.5,396.6,667.4,396.4z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path class="st376" d="M671.3,390.2c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7
							C671,389.6,671.3,389.9,671.3,390.2z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st376" d="M670.4,390c0,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.1,0.1-0.1
							C670.4,389.8,670.4,389.9,670.4,390z"/>
					</g>
				</g>
				<g class="st377">
					<g>
						<path class="st376" d="M671,389.7C671,389.8,671,389.8,671,389.7c0,0.5-0.3,0.7-0.6,0.7c-0.2,0-0.3-0.1-0.4-0.2
							c0,0.3,0.3,0.6,0.6,0.6c0.4,0,0.7-0.3,0.7-0.7C671.3,390,671.2,389.9,671,389.7z"/>
					</g>
				</g>
			</g>
		</g>
		<g>
			<g>
				<g>
					<path class="st369" d="M648.9,395.6l-4.3-5.4c-0.1-0.1-0.2-0.1-0.2,0c-0.1,0.1-0.1,0.2,0,0.2l3.6,5.9c0.2,0.3,0.5,0.4,0.8,0.2
						C649,396.3,649.1,395.9,648.9,395.6C648.9,395.6,648.9,395.6,648.9,395.6z"/>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path class="st375" d="M647.7,396.8c0.1,0.2,0.4,0.2,0.5,0.1l1.2-0.9c0.2-0.1,0.2-0.4,0.1-0.5l0,0c-0.1-0.2-0.4-0.2-0.5-0.1
							l-1.2,0.9C647.7,396.4,647.6,396.7,647.7,396.8L647.7,396.8z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st374" d="M648.6,396c0,0.1,0.1,0.1,0.2,0l0.4-0.3c0.1,0,0.1-0.1,0-0.2l0,0c0-0.1-0.1-0.1-0.2,0l-0.4,0.3
							C648.6,395.9,648.6,396,648.6,396L648.6,396z"/>
					</g>
				</g>
				<g class="st365">
					<g>
						<path class="st376" d="M647.7,396.4c0,0.1,0,0.1,0.1,0.2c0.1,0.2,0.4,0.2,0.5,0.1l1.2-0.9c0,0,0.1-0.1,0.1-0.1
							c0,0.1,0,0.3-0.1,0.4l-1.2,0.9c-0.2,0.1-0.4,0.1-0.5-0.1C647.7,396.7,647.6,396.6,647.7,396.4z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path class="st376" d="M643.9,390.2c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7
							C644.1,389.6,643.9,389.9,643.9,390.2z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st376" d="M644.7,390c0,0.1,0.1,0.1,0.1,0.1c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1
							C644.8,389.8,644.7,389.9,644.7,390z"/>
					</g>
				</g>
				<g class="st377">
					<g>
						<path class="st376" d="M644.1,389.7C644.1,389.8,644.1,389.8,644.1,389.7c0,0.5,0.3,0.7,0.6,0.7c0.2,0,0.3-0.1,0.4-0.2
							c0,0.3-0.3,0.6-0.6,0.6c-0.4,0-0.7-0.3-0.7-0.7C643.9,390,643.9,389.9,644.1,389.7z"/>
					</g>
				</g>
			</g>
		</g>
		<g>
			<g>
				<path class="st362" d="M656.4,396.1c0.2,0.5-2.2,0.7-4.2,1.4c-2,0.7-2.8,1.7-3,1.2c-0.2-0.5,0.4-2.3,2.4-3
					C653.5,395,656.2,395.6,656.4,396.1z"/>
			</g>
		</g>
		<g>
			<g>
				<g>
					<path class="st366" d="M664.4,414c0.2,1.1-0.8,2.4-2.1,2.7c-3.2,0.7-6.6,0.7-9.8,0c-1.4-0.3-2.3-1.6-2.1-2.7l0,0
						c0.2-1.1,1.3-1.6,2.5-1.4c3,0.7,6,0.7,9,0C663.1,412.3,664.2,412.8,664.4,414L664.4,414z"/>
				</g>
			</g>
			<g>
				<g>
					<path class="st367" d="M656.8,414.6c0,0.4-0.4,0.7-0.9,0.7c-1.1-0.1-2.2-0.2-3.3-0.5c-0.5-0.1-0.8-0.5-0.7-0.9l0,0
						c0-0.4,0.4-0.6,0.9-0.5c1,0.2,2.1,0.4,3.2,0.5C656.5,413.9,656.8,414.2,656.8,414.6L656.8,414.6z"/>
				</g>
			</g>
			<g>
				<g>
					<path class="st368" d="M662.9,412.6c0.2,0.2,0.3,0.5,0.4,0.9c0.2,1.1-0.8,2.4-2.2,2.6c-3.2,0.6-6.5,0.4-9.6-0.5
						c-0.4-0.1-0.7-0.3-1-0.6c0.3,0.8,1.1,1.5,2,1.7c3.2,0.7,6.6,0.7,9.8,0c1.4-0.3,2.3-1.6,2.1-2.7
						C664.2,413.2,663.6,412.7,662.9,412.6z"/>
				</g>
			</g>
			<g>
				<g>
					<g>
						<g>
							<path class="st362" d="M651.9,414.8c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4
								C651.7,414.3,651.9,414.5,651.9,414.8z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<path class="st362" d="M651.1,414.8c0-0.2,0.2-0.4,0.4-0.4C651.3,414.4,651.1,414.6,651.1,414.8
							C651.1,414.8,651.1,414.9,651.1,414.8C651.1,414.9,651.1,414.8,651.1,414.8z"/>
					</g>
				</g>
				<path class="st362" d="M651.7,414.4c0.1,0.1,0.1,0.2,0.1,0.3c0,0.2-0.2,0.4-0.4,0.4c-0.1,0-0.1,0-0.2-0.1
					c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0,0.4-0.2,0.4-0.4C651.9,414.6,651.8,414.5,651.7,414.4z"/>
			</g>
			<g>
				<g>
					<g>
						<g>
							<path class="st362" d="M655.1,415.7c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4
								C654.9,415.2,655.1,415.4,655.1,415.7z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<path class="st362" d="M654.3,415.7c0-0.2,0.2-0.4,0.4-0.4C654.5,415.3,654.3,415.5,654.3,415.7
							C654.3,415.7,654.3,415.8,654.3,415.7C654.3,415.8,654.3,415.8,654.3,415.7z"/>
					</g>
				</g>
				<path class="st362" d="M654.9,415.3c0.1,0.1,0.1,0.2,0.1,0.3c0,0.2-0.2,0.4-0.4,0.4c-0.1,0-0.1,0-0.2-0.1
					c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0,0.4-0.2,0.4-0.4C655.1,415.5,655,415.4,654.9,415.3z"/>
			</g>
			<g>
				<g>
					<g>
						<g>
							<path class="st362" d="M658.8,415.8c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4
								C658.6,415.4,658.8,415.5,658.8,415.8z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<path class="st362" d="M658,415.9c0-0.2,0.2-0.4,0.4-0.4C658.2,415.4,658,415.6,658,415.9C658,415.9,658,415.9,658,415.9
							C658,415.9,658,415.9,658,415.9z"/>
					</g>
				</g>
				<path class="st362" d="M658.6,415.4c0.1,0.1,0.1,0.2,0.1,0.3c0,0.2-0.2,0.4-0.4,0.4c-0.1,0-0.1,0-0.2-0.1
					c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0,0.4-0.2,0.4-0.4C658.8,415.6,658.7,415.5,658.6,415.4z"/>
			</g>
			<g>
				<g>
					<g>
						<g>
							<path class="st362" d="M662.3,415.3c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4
								C662.1,414.8,662.3,415,662.3,415.3z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<path class="st362" d="M661.5,415.3c0-0.2,0.2-0.4,0.4-0.4C661.7,414.9,661.5,415.1,661.5,415.3
							C661.5,415.3,661.5,415.4,661.5,415.3C661.5,415.4,661.5,415.4,661.5,415.3z"/>
					</g>
				</g>
				<path class="st362" d="M662.1,414.9c0.1,0.1,0.1,0.2,0.1,0.3c0,0.2-0.2,0.4-0.4,0.4c-0.1,0-0.1,0-0.2-0.1
					c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0,0.4-0.2,0.4-0.4C662.3,415.1,662.2,415,662.1,414.9z"/>
			</g>
		</g>
		<g>
			<g>
				<g>
					<path class="st362" d="M663.1,434.7c0,0.4-0.3,0.7-0.7,0.7h-3.2c-0.4,0-0.7-0.3-0.7-0.7v-4c0-0.4,0.3-0.7,0.7-0.7h3.2
						c0.4,0,0.7,0.3,0.7,0.7V434.7z"/>
				</g>
			</g>
			<g>
				<g>
					<path class="st362" d="M662.8,430.1c0.1,0.1,0.1,0.3,0.1,0.4v4c0,0.4-0.3,0.7-0.7,0.7H659c-0.1,0-0.3,0-0.4-0.1
						c0.1,0.2,0.4,0.3,0.6,0.3h3.2c0.4,0,0.7-0.3,0.7-0.7v-4C663.1,430.5,663,430.2,662.8,430.1z"/>
				</g>
			</g>
			<g>
				<g>
					<path class="st362" d="M661.6,430.5h-2.2c-0.3,0-0.5,0.2-0.5,0.5v2.3c0.1,0,0.1,0,0.2,0v-2.2c0-0.3,0.2-0.5,0.5-0.5h2
						C661.5,430.5,661.5,430.5,661.6,430.5z"/>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path class="st378" d="M661.3,431.2c-0.1-0.3-0.4-0.5-0.7-0.4c-0.3,0.1-0.5,0.4-0.4,0.7c0.1,0.3,0.4,0.5,0.7,0.4
							C661.3,431.9,661.4,431.5,661.3,431.2z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st362" d="M661.1,431.9c0.2-0.1,0.2-0.4,0.2-0.6c-0.1-0.3-0.4-0.5-0.7-0.4c-0.1,0-0.1,0-0.2,0.1
							c0.1-0.1,0.1-0.1,0.2-0.1c0.3-0.1,0.6,0.1,0.7,0.4C661.4,431.5,661.3,431.8,661.1,431.9z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path class="st379" d="M661.3,432.7c-0.1-0.3-0.4-0.5-0.7-0.4c-0.3,0.1-0.5,0.4-0.4,0.7c0.1,0.3,0.4,0.5,0.7,0.4
							C661.3,433.3,661.4,433,661.3,432.7z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st362" d="M661.1,433.3c0.2-0.1,0.2-0.4,0.2-0.6c-0.1-0.3-0.4-0.5-0.7-0.4c-0.1,0-0.1,0-0.2,0.1
							c0.1-0.1,0.1-0.1,0.2-0.1c0.3-0.1,0.6,0.1,0.7,0.4C661.4,432.9,661.3,433.2,661.1,433.3z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path class="st380" d="M661.3,434.1c-0.1-0.3-0.4-0.5-0.7-0.4c-0.3,0.1-0.5,0.4-0.4,0.7c0.1,0.3,0.4,0.5,0.7,0.4
							C661.3,434.8,661.4,434.4,661.3,434.1z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st362" d="M661.1,434.8c0.2-0.1,0.2-0.4,0.2-0.6c-0.1-0.3-0.4-0.5-0.7-0.4c-0.1,0-0.1,0-0.2,0.1
							c0.1-0.1,0.1-0.1,0.2-0.1c0.3-0.1,0.6,0.1,0.7,0.4C661.4,434.4,661.3,434.6,661.1,434.8z"/>
					</g>
				</g>
			</g>
		</g>
		<g>
			<path class="st531" d="M661.2,405.4c0,2.1-1.7,3.8-3.8,3.8c-2.1,0-3.8-1.7-3.8-3.8c0-2.1,1.7-3.8,3.8-3.8
				C659.5,401.6,661.2,403.3,661.2,405.4z"/>
		</g>
		<g>
			<path class="st532" d="M659.6,405.4c0,1.2-1,2.3-2.3,2.3c-1.2,0-2.3-1-2.3-2.3c0-1.2,1-2.3,2.3-2.3
				C658.6,403.2,659.6,404.2,659.6,405.4z"/>
		</g>
		<g>
			<path class="st533" d="M658.8,405.4c0,0.8-0.6,1.4-1.4,1.4c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4
				C658.2,404,658.8,404.6,658.8,405.4z"/>
		</g>
		<g>
			<path class="st531" d="M659.4,402.2c0.2,0.5,0.3,1,0.3,1.5c0,2.1-1.7,3.8-3.8,3.8c-0.7,0-1.4-0.2-2-0.6c0.6,1.4,1.9,2.3,3.5,2.3
				c2.1,0,3.8-1.7,3.8-3.8C661.2,404.1,660.5,402.9,659.4,402.2z"/>
		</g>
		<g>
			<g>
				<path class="st532" d="M659.5,402.8c0,0.1-0.1,0.1-0.1,0.2c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0.1,0.2
					c0,0.1,0,0.2,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.2,0.2-0.3,0.3l-0.1-0.1c0.1,0,0.3-0.1,0.4-0.2c0.1,0,0.1-0.1,0.1-0.2
					c0-0.1,0.1-0.1,0-0.2c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0.1-0.3,0.3-0.4L659.5,402.8z"/>
			</g>
			<g>
				<path class="st532" d="M659.3,405.4c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0.1,0.1,0.1
					c0,0,0.1,0.1,0.1,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0,0.1,0.1,0.1,0.1c0.1,0,0.1,0,0.1-0.1l0.1,0.1c-0.1,0.1-0.2,0.1-0.2,0
					c-0.1,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1,0-0.2-0.1s-0.1,0-0.1,0c-0.1,0-0.1,0.1-0.1,0.1
					c0,0-0.1,0-0.2,0L659.3,405.4z"/>
			</g>
			<g>
				<path class="st532" d="M659.1,406.5c0.1,0,0.1,0.1,0.2,0.2c0,0.1,0,0.1,0.1,0.2c0,0.1,0.1,0.1,0.2,0.2c0,0.1,0.1,0.1,0.1,0.2
					c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0.1,0.2,0.1c0.1,0.1,0.2,0.3,0.1,0.5l-0.1-0.1c0.1-0.1,0.1-0.3,0-0.4
					c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.2-0.2
					c0-0.1-0.1-0.1-0.1-0.2L659.1,406.5z"/>
			</g>
			<g>
				<path class="st532" d="M657.6,407.1c0.1,0,0.1,0.1,0.2,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.2,0.1c0,0.1,0.1,0.1,0.1,0.2
					c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0,0.2c-0.1,0.1-0.1,0.3-0.1,0.4l-0.1-0.1c0.1-0.1,0.2-0.2,0.3-0.4c0-0.1,0-0.3-0.1-0.4
					c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.1-0.1-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2L657.6,407.1z"/>
			</g>
			<g>
				<path class="st532" d="M653.9,407.1c-0.1-0.2-0.2-0.4,0-0.6c0.1-0.1,0.2-0.2,0.3-0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0
					c0.1,0,0.2,0,0.3,0c0.2,0,0.4-0.1,0.5-0.2l0.1,0.1c-0.2,0.1-0.4,0.1-0.6,0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0
					c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.2,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.3L653.9,407.1z"/>
			</g>
			<g>
				<path class="st532" d="M655.7,408.5c0-0.2,0-0.2,0-0.4c0-0.1,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.2,0
					c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.3,0-0.4l0.1-0.1c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.1,0.1-0.2,0.1
					c-0.1,0-0.2,0-0.2,0.1c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.1,0-0.1,0c0,0,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.3L655.7,408.5z"/>
			</g>
			<g>
				<path class="st532" d="M653.8,405.1c0-0.1-0.1-0.2,0-0.3c0-0.1,0.2-0.1,0.2-0.1c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.2,0.1,0.2,0.2
					c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.2,0.1,0.2,0.1c0.1,0,0.1,0,0.1-0.1l0.1,0
					c0,0,0,0.1-0.1,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.2-0.2l-0.2-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.1-0.2-0.2
					c-0.1-0.1-0.1-0.1-0.2-0.2c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0.1,0,0.2L653.8,405.1z"/>
			</g>
			<g>
				<path class="st532" d="M654.4,403.1c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0.1,0.2,0.2c0.1,0.1,0,0.2,0,0.3
					c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0.1,0.2c0.1,0.2,0.3,0.2,0.4,0.1l0.1,0.1c-0.1,0.1-0.2,0.1-0.3,0c-0.1-0.1-0.1-0.1-0.2-0.2
					c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1,0-0.2,0-0.2,0
					c-0.1,0-0.2,0-0.3-0.1L654.4,403.1z"/>
			</g>
			<g>
				<path class="st532" d="M656.6,402.3c0,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.1,0-0.2,0.1c0,0.1,0,0.1,0,0.1c0,0,0.1,0.1,0.1,0.2
					c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0.1,0.2,0.2c0.1,0,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-0.1-0.1c0.1,0,0.1-0.1,0.1-0.1
					c0-0.1,0-0.1-0.1-0.2c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1,0-0.2,0-0.3
					c0.1-0.2,0.2-0.3,0.4-0.3L656.6,402.3z"/>
			</g>
			<g>
				<path class="st532" d="M658.2,402.2c0,0.1,0,0.1-0.1,0.2c0,0-0.1,0.1-0.1,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0.1,0,0.1
					c0,0,0,0.1-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.2-0.2,0.4l-0.1-0.1c0.1-0.1,0.2-0.2,0.3-0.3
					c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1-0.1-0.2c0-0.1-0.1-0.2,0-0.3
					c0-0.1,0.1-0.2,0.2-0.2L658.2,402.2z"/>
			</g>
			<g>
				<path class="st532" d="M660.6,404c-0.1,0.1-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0.1c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.1-0.2,0.2
					c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.3,0.1-0.4,0.2l0-0.1c0.1,0,0.3,0,0.4,0c0.1,0,0.1-0.1,0.2-0.1
					c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.1,0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.1-0.1,0.2-0.1,0.2,0L660.6,404z"/>
			</g>
		</g>
		<g>
			<path class="st531" d="M657.5,402.9c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5
				C657.2,402.4,657.5,402.6,657.5,402.9z"/>
		</g>
		<g>
			<path class="st531" d="M656.1,403.3c0.2,0.1,0,0.6-0.4,1c-0.4,0.4-1.1,1.4-1.3,1.3c-0.2-0.1,0.3-1.4,0.7-1.8
				C655.5,403.4,655.9,403.1,656.1,403.3z"/>
		</g>
		<g>
			<g>
				<g>
					<g>
						<g>
							<path class="st362" d="M656.1,436.3c-0.1,2-1.8,3.5-3.8,3.4c-2-0.1-3.5-1.8-3.4-3.8c0.1-2,1.8-3.5,3.8-3.4
								C654.7,432.6,656.2,434.3,656.1,436.3z"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st362" d="M653.7,432.7c1.2,0.6,2,1.9,1.9,3.4c-0.1,2-1.8,3.5-3.8,3.4c-0.4,0-0.7-0.1-1-0.2
								c0.4,0.2,0.9,0.4,1.5,0.4c2,0.1,3.7-1.4,3.8-3.4C656.2,434.6,655.2,433.2,653.7,432.7z"/>
						</g>
					</g>
				</g>
				<g>
					<path class="st362" d="M649.1,436c0.1-2,1.8-3.5,3.8-3.4c0.5,0,0.9,0.1,1.3,0.3c-0.5-0.2-1-0.4-1.5-0.4c-2-0.1-3.7,1.4-3.8,3.4
						c-0.1,1.5,0.8,2.9,2.1,3.5C649.8,438.7,649.1,437.4,649.1,436z"/>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path class="st366" d="M655.1,436c0,1.5-1.2,2.7-2.7,2.7s-2.7-1.2-2.7-2.7c0-1.5,1.2-2.7,2.7-2.7S655.1,434.5,655.1,436z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st367" d="M651.7,434.9c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5
							C651.4,434.4,651.7,434.6,651.7,434.9z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st368" d="M654.1,434c0,0.1,0,0.2,0,0.4c0,1.5-1.2,2.7-2.7,2.7c-0.7,0-1.3-0.3-1.7-0.7c0.2,1.3,1.3,2.3,2.6,2.3
							c1.5,0,2.7-1.2,2.7-2.7C655.1,435.2,654.7,434.5,654.1,434z"/>
					</g>
				</g>
			</g>
		</g>
		<g>
			<g>
				<g>
					<g>
						<path class="st364" d="M668.1,452.6c-0.1,0.8-0.6,1.4-1.2,1.5c-6.2,1-12.4,1-18.6,0c-0.6-0.1-1.1-0.8-1.2-1.5
							c0-0.4-0.1-0.7-0.2-1.1c-0.2-0.7,0.2-1.3,0.8-1.2c6.9,0.9,12.7,0.9,19.6,0c0.7-0.1,1,0.4,0.8,1.2
							C668.2,451.9,668.2,452.3,668.1,452.6z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st368" d="M667.5,450.4c-0.6,0.1-1.3,0.2-1.9,0.2c-0.1,0.3-0.1,0.5-0.1,0.8c-0.1,0.8-0.6,1.4-1.2,1.5
							c-5.7,0.9-11.3,1-16.9,0.2c0.2,0.5,0.6,0.9,1,0.9c6.2,1,12.4,1,18.6,0c0.6-0.1,1.1-0.8,1.2-1.5c0-0.4,0.1-0.7,0.2-1.1
							C668.5,450.8,668.2,450.3,667.5,450.4z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st367" d="M658.8,451.8c0,0.3-2.4,0.9-5.2,0.9c-2.9-0.1-5.2-0.8-5.2-1.1c0-0.3,2.3-0.1,5.2,0
							C656.4,451.6,658.8,451.4,658.8,451.8z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<g>
							<path class="st362" d="M659.6,452.3c0,1.5-1.2,2.7-2.7,2.7c-1.5,0-2.7-1.2-2.7-2.7s1.2-2.7,2.7-2.7
								C658.4,449.6,659.6,450.8,659.6,452.3z"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st362" d="M657.7,449.7c0.9,0.4,1.6,1.4,1.6,2.5c0,1.5-1.2,2.7-2.7,2.7c-0.3,0-0.5,0-0.8-0.1
								c0.3,0.2,0.7,0.2,1.1,0.2c1.5,0,2.7-1.2,2.7-2.7C659.6,451.1,658.8,450.1,657.7,449.7z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<path class="st362" d="M654.8,452.8c0-1.3,0.9-2.4,2.2-2.6c-1.3,0-2.3,1.1-2.3,2.3c0,0.3,0,0.5,0.1,0.8
							C654.8,453.1,654.8,452.9,654.8,452.8z"/>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path class="st362" d="M655.4,451.8c0.2-0.7,1-1.2,1.8-0.9c0.8,0.2,1.2,1,0.9,1.8c-0.2,0.8-1,1.2-1.8,0.9
								C655.6,453.4,655.2,452.6,655.4,451.8z"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st362" d="M656,453.4c-0.4-0.4-0.6-0.9-0.4-1.5c0.2-0.8,1-1.2,1.8-0.9c0.1,0,0.3,0.1,0.4,0.2
								c-0.1-0.1-0.3-0.2-0.5-0.3c-0.8-0.2-1.6,0.2-1.8,0.9C655.2,452.4,655.5,453.1,656,453.4z"/>
						</g>
					</g>
				</g>
			</g>
		</g>
		<g>
			<g>
				<path class="st362" d="M687.3,422.2c-0.1,0.9-1.1,0.6-2.8-0.3c-1.6-0.9-2.4-1.7-1.7-2.2c0.8-0.5,2.2-0.7,3.1-0.2
					C686.8,419.9,687.3,421.2,687.3,422.2z"/>
			</g>
			<g>
				<path class="st362" d="M683.2,420c0.6-0.5,1.8-0.6,2.6-0.3c-0.8-0.5-2-0.3-2.6,0.1c-0.1,0.1-0.2,0.2-0.2,0.3
					C683.1,420.1,683.2,420,683.2,420z"/>
			</g>
			<path class="st362" d="M686.6,420.1c0.1,0.4,0.2,0.8,0.2,1.2c-0.1,0.9-1.3,0.6-2.9-0.2c-0.5-0.2-0.9-0.5-1.2-0.7
				c0.2,0.4,0.9,0.9,1.8,1.5c1.6,0.9,2.7,1.3,2.8,0.3C687.3,421.5,687,420.7,686.6,420.1z"/>
		</g>
		<g>
			<g>
				<path class="st362" d="M629.4,423.7c0.8,0.4,0.1,1.3-1.4,2.4c-1.5,1.1-2.6,1.5-2.7,0.6c-0.2-0.9,0.2-2.3,1.1-2.9
					C627.2,423.2,628.6,423.3,629.4,423.7z"/>
			</g>
			<g>
				<path class="st362" d="M625.8,426.5c-0.2-0.7,0.1-1.9,0.8-2.5c-0.8,0.5-1.1,1.7-0.9,2.4c0,0.1,0.1,0.3,0.2,0.3
					C625.8,426.6,625.8,426.5,625.8,426.5z"/>
			</g>
			<path class="st362" d="M627.3,423.5c0.4,0,0.8,0.1,1.1,0.3c0.7,0.5,0,1.5-1.4,2.6c-0.4,0.3-0.8,0.6-1.1,0.8
				c0.5,0,1.2-0.4,2.1-1.1c1.5-1.1,2.3-2,1.4-2.4C628.9,423.4,628,423.3,627.3,423.5z"/>
		</g>
		<g>
			<g>
				<path class="st366" d="M637,426.3c0.2,0.4,1.6,0.1,2.9-0.6c3-1.8,4.8-3.8,5.2-6.3c0.2-1.1-0.3-2.3-0.8-2.5l0,0
					c-0.5-0.2-0.9,0.4-1.2,1.4c-0.5,2.3-2.1,4.1-4.6,5.9C637.5,424.9,636.8,425.9,637,426.3L637,426.3z"/>
			</g>
			<g>
				<path class="st367" d="M642.5,421.6c0.2,0.1,0.5,0,0.7-0.3c0.5-0.7,0.8-1.5,1-2.4c0.1-0.4,0-0.7-0.2-0.8l0,0
					c-0.2-0.1-0.4,0.1-0.5,0.4c-0.2,0.8-0.5,1.6-1,2.3C642.3,421.1,642.3,421.4,642.5,421.6L642.5,421.6z"/>
			</g>
			<g>
				<path class="st368" d="M637.7,424.9c-0.1,0.2-0.1,0.4,0,0.5c0.3,0.4,1.6,0.1,2.8-0.6c2.7-1.8,4.1-3.9,4.3-6.5c0-0.3,0-0.6,0-1
					c0.3,0.5,0.5,1.3,0.3,2.1c-0.4,2.6-2.2,4.5-5.2,6.3c-1.3,0.8-2.7,1.1-2.9,0.6C636.8,426,637.2,425.5,637.7,424.9z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path class="st369" d="M94.9,453.8l7.7-9.8c0.1-0.1,0.3-0.2,0.5-0.1c0.1,0.1,0.2,0.3,0.1,0.4L96.7,455c-0.3,0.5-1,0.7-1.4,0.3
					C94.7,455.1,94.6,454.4,94.9,453.8C94.9,453.9,94.9,453.8,94.9,453.8z"/>
			</g>
			<g>
				<g>
					<path class="st375" d="M97.1,456.1c-0.2,0.3-0.7,0.4-1,0.1l-2.1-1.6c-0.3-0.2-0.4-0.7-0.1-1l0,0c0.2-0.3,0.7-0.4,1-0.1l2.1,1.6
						C97.2,455.3,97.3,455.8,97.1,456.1L97.1,456.1z"/>
				</g>
				<g>
					<path class="st374" d="M95.5,454.6c-0.1,0.1-0.2,0.1-0.3,0l-0.7-0.6c-0.1-0.1-0.1-0.2,0-0.3l0,0c0.1-0.1,0.2-0.1,0.3,0l0.7,0.6
						C95.5,454.4,95.6,454.5,95.5,454.6L95.5,454.6z"/>
				</g>
				<g class="st365">
					<path class="st376" d="M97.1,455.4c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.3-0.7,0.4-1,0.1l-2.1-1.6c-0.1-0.1-0.2-0.1-0.2-0.2
						c0,0.2,0.1,0.5,0.3,0.7l2.1,1.6c0.3,0.2,0.7,0.2,1-0.1C97.2,455.8,97.2,455.6,97.1,455.4z"/>
				</g>
			</g>
			<g>
				<g>
					<path class="st376" d="M104.1,444.1c0,0.7-0.5,1.2-1.2,1.2c-0.7,0-1.2-0.5-1.2-1.2s0.5-1.2,1.2-1.2
						C103.6,442.9,104.1,443.5,104.1,444.1z"/>
				</g>
				<g>
					<path class="st376" d="M102.6,443.6c0,0.1-0.1,0.2-0.2,0.2s-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2S102.6,443.5,102.6,443.6z"
						/>
				</g>
				<g class="st377">
					<path class="st376" d="M103.7,443.2c0,0.1,0,0.1,0,0.2c0,0.7-0.5,1.2-1.2,1.2c-0.3,0-0.6-0.1-0.8-0.3c0.1,0.6,0.6,1,1.2,1
						c0.7,0,1.2-0.5,1.2-1.2C104.1,443.8,104,443.4,103.7,443.2z"/>
				</g>
			</g>
		</g>
		<g>
			<g>
				<path class="st369" d="M84.1,453.8l-7.7-9.8c-0.1-0.1-0.3-0.2-0.5-0.1c-0.1,0.1-0.2,0.3-0.1,0.4l6.5,10.6c0.3,0.5,1,0.7,1.4,0.3
					C84.3,455.1,84.5,454.4,84.1,453.8C84.2,453.9,84.1,453.8,84.1,453.8z"/>
			</g>
			<g>
				<g>
					<path class="st375" d="M82,456.1c0.2,0.3,0.7,0.4,1,0.1l2.1-1.6c0.3-0.2,0.4-0.7,0.1-1l0,0c-0.2-0.3-0.7-0.4-1-0.1l-2.1,1.6
						C81.8,455.3,81.8,455.8,82,456.1L82,456.1z"/>
				</g>
				<g>
					<path class="st374" d="M83.6,454.6c0.1,0.1,0.2,0.1,0.3,0l0.7-0.6c0.1-0.1,0.1-0.2,0-0.3l0,0c-0.1-0.1-0.2-0.1-0.3,0l-0.7,0.6
						C83.5,454.4,83.5,454.5,83.6,454.6L83.6,454.6z"/>
				</g>
				<g class="st365">
					<path class="st376" d="M81.9,455.4c0,0.1,0.1,0.2,0.1,0.3c0.2,0.3,0.7,0.4,1,0.1l2.1-1.6c0.1-0.1,0.2-0.1,0.2-0.2
						c0,0.2-0.1,0.5-0.3,0.7l-2.1,1.6c-0.3,0.2-0.7,0.2-1-0.1C81.8,455.8,81.8,455.6,81.9,455.4z"/>
				</g>
			</g>
			<g>
				<g>
					<path class="st376" d="M75,444.1c0,0.7,0.5,1.2,1.2,1.2c0.7,0,1.2-0.5,1.2-1.2s-0.5-1.2-1.2-1.2C75.5,442.9,75,443.5,75,444.1z
						"/>
				</g>
				<g>
					<path class="st376" d="M76.5,443.6c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
						C76.6,443.4,76.5,443.5,76.5,443.6z"/>
				</g>
				<g class="st377">
					<path class="st376" d="M75.4,443.2c0,0.1,0,0.1,0,0.2c0,0.7,0.5,1.2,1.2,1.2c0.3,0,0.6-0.1,0.8-0.3c-0.1,0.6-0.6,1-1.2,1
						c-0.7,0-1.2-0.5-1.2-1.2C75,443.8,75.1,443.4,75.4,443.2z"/>
				</g>
			</g>
		</g>
		<g>
			<g>
				<path class="st362" d="M89.4,452.8C66.5,453,56.9,463.5,64,478.1c6.8,14.3,18,29.4,25.4,30.1c7.4-0.2,18.5-15.5,25.5-30.5
					C122,462.4,112.3,452.1,89.4,452.8z"/>
			</g>
			<g>
				<path class="st362" d="M89.4,452.8C66.5,453,56.9,463.5,64,478.1c6.8,14.3,18,29.4,25.4,30.1c7.4-0.2,18.5-15.5,25.5-30.5
					C122,462.4,112.3,452.1,89.4,452.8z M89,504.9c-7-0.4-17.3-14.6-23-27.7c-5.8-13.2,3.2-21.8,22.5-22.1
					c19.2-0.7,29.1,7.3,23.4,21.1C106.2,489.9,95.9,504.7,89,504.9z"/>
			</g>
			<g>
				<path class="st362" d="M91.8,459.3c0.8,1.2-9.8,1.5-16.9,5c-7,3.4-8,7.3-9.1,6.3c-0.8-0.8-1.5-6.3,5.6-10.1
					C78.7,456.7,91.3,458,91.8,459.3z"/>
			</g>
		</g>
		<g>
			<path class="st534" d="M85.1,478.7c0.8,2.8-3.5,1.5-5.3,3.2c-2.4,2-5.9,6.4-6.4,6.3c-1.7-0.3-6.5-5.3-3-10.6
				c3.4-5.3,1.8-10,4.2-10c1.6,0,2.6,4.3,3.9,6.3C80,475.8,84.2,475.6,85.1,478.7z"/>
			<path class="st535" d="M79.3,480c-2.3,2.3-6.5,7.3-7.1,7.1c-0.2,0-0.4-0.1-0.6-0.2c0.7,0.7,1.3,1.1,1.8,1.2
				c0.5,0.1,4-4.2,6.4-6.3c1.8-1.7,6.1-0.4,5.3-3.2c-0.1-0.4-0.3-0.6-0.4-0.9C84.6,479.1,80.8,478.4,79.3,480z"/>
			<path class="st536" d="M77.7,478.1c0.2,5.9-2.9,10.3-4.3,10c-1.7-0.3-6.5-5.3-3-10.6c3.4-5.3,1.8-10,4.2-10
				C77.4,467.6,77.5,472.2,77.7,478.1z"/>
			<path class="st369" d="M77,477.7c0,1.3-0.5,2.3-1,2.2c-0.5,0-0.8-1.1-0.6-2.3c0.2-1.2,0.6-2.2,1-2.2
				C76.7,475.4,77,476.4,77,477.7z"/>
			<path class="st369" d="M77,477.7c0,3.8-1.6,6.7-3.1,6.6c-1.6-0.2-2.6-3.3-1.3-6.9c1.3-3.6,1.9-6.6,2.9-6.6
				C76.7,470.8,77.1,473.9,77,477.7z"/>
		</g>
		<g>
			<path class="st534" d="M95.3,478.8c-0.7,2.8,3.5,1.4,5.4,3.1c2.4,2,6.1,6.3,6.5,6.1c1.7-0.3,6.3-5.4,2.8-10.6
				c-3.5-5.2-2-10-4.4-9.9c-1.6,0.1-2.5,4.3-3.8,6.4C100.3,475.9,96.1,475.7,95.3,478.8z"/>
			<path class="st535" d="M101.1,480c2.4,2.3,6.6,7.1,7.3,7c0.2,0,0.4-0.1,0.6-0.2c-0.7,0.7-1.3,1.2-1.8,1.2
				c-0.5,0.1-4.1-4.2-6.5-6.1c-1.8-1.7-6.1-0.3-5.4-3.1c0.1-0.4,0.3-0.6,0.4-0.9C95.8,479.3,99.6,478.5,101.1,480z"/>
			<path class="st536" d="M102.7,478.1c-0.1,5.9,3.1,10.2,4.5,9.9c1.7-0.3,6.3-5.4,2.8-10.6c-3.5-5.2-2-10-4.4-9.9
				C102.8,467.6,102.8,472.2,102.7,478.1z"/>
			<path class="st369" d="M103.4,477.7c0.1,1.3,0.5,2.2,1.1,2.2c0.5-0.1,0.8-1.1,0.6-2.3c-0.2-1.2-0.7-2.2-1-2.2
				C103.6,475.4,103.3,476.4,103.4,477.7z"/>
			<path class="st369" d="M103.3,477.7c0.1,3.8,1.7,6.7,3.2,6.5c1.6-0.2,2.6-3.4,1.1-6.9c-1.4-3.6-2.1-6.5-3.1-6.5
				C103.6,470.8,103.2,473.9,103.3,477.7z"/>
		</g>
		<g>
			<g>
				<g>
					<path class="st363" d="M95.4,530.8c1.1,14.5,2.2,19.4,2.8,33.9c-1,0.1-2,0.1-3,0.2c0.7-13.1,1-21.9-6-33.2L95.4,530.8
						C95.7,534.5,95.4,530.8,95.4,530.8z"/>
				</g>
				<g>
					<g>
						<path class="st363" d="M95.5,531.7c0,0,0-0.1,0-0.2C95.5,531.6,95.5,531.6,95.5,531.7z"/>
					</g>
					<g>
						<path class="st363" d="M95.5,531.7C95.5,531.7,95.5,531.7,95.5,531.7C95.5,531.7,95.5,531.7,95.5,531.7z"/>
					</g>
					<g>
						<path class="st363" d="M95.5,532.1c0,0,0-0.1,0-0.2C95.5,532,95.5,532.1,95.5,532.1z"/>
					</g>
					<g>
						<path class="st363" d="M95.5,532c0-0.1,0-0.2,0-0.2C95.5,531.8,95.5,531.9,95.5,532z"/>
					</g>
					<g>
						<path class="st363" d="M95.5,532.2c0,0,0-0.1,0-0.1C95.5,532.2,95.5,532.2,95.5,532.2z"/>
					</g>
					<g>
						<path class="st363" d="M95.6,532.4C95.6,532.4,95.6,532.4,95.6,532.4C95.6,532.4,95.6,532.4,95.6,532.4z"/>
					</g>
					<g>
						<path class="st363" d="M95.5,532.3C95.5,532.3,95.5,532.3,95.5,532.3C95.5,532.3,95.5,532.3,95.5,532.3z"/>
					</g>
					<g>
						<path class="st363" d="M95.6,532.4C95.6,532.5,95.6,532.5,95.6,532.4L95.6,532.4z"/>
					</g>
					<g>
						<path class="st363" d="M94.5,531.2c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0
							c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1C94.5,531.3,94.5,531.2,94.5,531.2c0-0.1,0-0.1,0-0.2h0
							c1.1,14.4,2.1,19.3,2.6,33.8c0.4,0,0.7,0,1.1-0.1c-0.6-14.5-1.6-19.4-2.8-33.9l-1,0.1C94.5,531.1,94.5,531.1,94.5,531.2z"/>
					</g>
					<g>
						<path class="st363" d="M95.5,531.5c0-0.1,0-0.1,0-0.2C95.5,531.4,95.5,531.4,95.5,531.5z"/>
					</g>
					<g>
						<path class="st363" d="M95.5,531.3c0-0.1,0-0.3,0-0.5C95.4,530.8,95.4,531,95.5,531.3z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<path class="st364" d="M95.2,564.9c1-0.1,2-0.1,3-0.2c-0.2-4.5-0.4-9-0.7-13.5c-0.7,0.1-1.3,0.1-2,0.3
						C95.6,556.2,95.4,560.8,95.2,564.9z"/>
				</g>
				<g>
					<g>
						<path class="st366" d="M98.6,551c0,0.3-0.8,0.7-1.9,1c-1.1,0.2-2,0.2-2.1-0.1c0-0.3,0.8-0.7,1.9-1
							C97.6,550.6,98.6,550.7,98.6,551z"/>
					</g>
					<g>
						<path class="st528" d="M96.3,551.3c0,0.1-0.2,0.2-0.5,0.3c-0.3,0.1-0.6,0-0.6,0c0-0.1,0.2-0.2,0.5-0.3
							C96,551.2,96.3,551.2,96.3,551.3z"/>
					</g>
					<g>
						<path class="st368" d="M98.2,550.8C98.2,550.8,98.2,550.8,98.2,550.8c0,0.3-0.8,0.7-1.9,1c-0.7,0.1-1.3,0.2-1.7,0.1
							c0,0.3,1,0.3,2.1,0.1c1.1-0.2,2-0.7,1.9-1C98.6,550.9,98.4,550.8,98.2,550.8z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st364" d="M105.2,566.8c0,0-2.5,0-5.7,0c-3.1,0-5.7,0-5.7,0s0-3,3.1-3C103.5,563.8,105.2,566.8,105.2,566.8z"/>
					</g>
					<g>
						<path class="st367" d="M96.2,563.9c-0.1,0.1-0.1,0.2-0.1,0.2c0,0.7,2.4,1.3,5.3,1.3c0.7,0,1.4,0,2-0.1
							c-1.2-0.8-3.3-1.5-6.5-1.5C96.7,563.8,96.4,563.8,96.2,563.9z"/>
					</g>
					<g>
						<path class="st364" d="M104.7,566.2c-0.9,0-2.9,0-5.2,0c-2.8,0-5.2,0-5.6,0c-0.1,0.3-0.1,0.6-0.1,0.6s2.5,0,5.7,0
							c3.1,0,5.7,0,5.7,0S105,566.6,104.7,566.2z"/>
					</g>
				</g>
			</g>
		</g>
		<g>
			<g>
				<g>
					<path class="st363" d="M88.5,531.7c-2.8,14.2-4.8,18.7-6,33.2c-1-0.1-2-0.1-3-0.2c1-5.7,1.7-11.4,2.4-17.1
						c1.1-8.7,2.8-8.2,0.4-16.8L88.5,531.7C87.8,535.2,88.5,531.7,88.5,531.7z"/>
				</g>
				<g>
					<g>
						<path class="st363" d="M87.2,532c0,0,0-0.1,0-0.1C87.2,531.9,87.2,531.9,87.2,532z"/>
					</g>
					<g>
						<path class="st363" d="M87.2,532.1C87.2,532.1,87.2,532,87.2,532.1C87.2,532,87.2,532.1,87.2,532.1z"/>
					</g>
					<g>
						<path class="st363" d="M87.3,531.5c-2.6,14.3-4.5,18.8-5.7,33.3c0.3,0,0.6,0,0.9,0.1c1.3-14.5,3.3-19,6-33.2L87.3,531.5z
							 M88.5,531.8C88.5,531.8,88.5,531.8,88.5,531.8C88.5,531.8,88.5,531.8,88.5,531.8z M88.2,533.2
							C88.2,533.2,88.2,533.2,88.2,533.2C88.2,533.2,88.2,533.2,88.2,533.2z M88.2,533.1C88.2,533,88.2,533,88.2,533.1
							C88.2,533,88.2,533,88.2,533.1z M88.3,532.3C88.4,532.3,88.4,532.3,88.3,532.3C88.4,532.3,88.4,532.3,88.3,532.3z M88.4,532.2
							c0,0,0-0.1,0-0.1C88.4,532.1,88.4,532.1,88.4,532.2z M88.4,532c0,0,0-0.1,0-0.1C88.4,531.9,88.4,532,88.4,532z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<path class="st364" d="M83.6,553.3c-0.7,0-1.5-0.1-2.2-0.1c-0.1,0-0.2,0-0.2,0c-0.5,3.9-1,7.7-1.7,11.5c1,0.1,2,0.1,3,0.2
						C82.8,561,83.2,557.1,83.6,553.3z"/>
				</g>
				<g>
					<g>
						<path class="st366" d="M84.4,553.1c-0.1,0.3-1,0.4-2.1,0.3c-1.1-0.1-2-0.5-1.9-0.8c0.1-0.3,1-0.4,2.1-0.3
							C83.6,552.5,84.5,552.8,84.4,553.1z"/>
					</g>
					<g>
						<path class="st528" d="M82.1,552.6c0,0.1-0.3,0.1-0.6,0.1c-0.3,0-0.5-0.1-0.5-0.2c0-0.1,0.3-0.1,0.6-0.1
							C81.9,552.5,82.2,552.6,82.1,552.6z"/>
					</g>
					<g>
						<path class="st368" d="M84.1,552.8C84.1,552.8,84.1,552.8,84.1,552.8c-0.1,0.3-1,0.4-2.1,0.3c-0.7-0.1-1.3-0.2-1.6-0.4
							c-0.1,0.3,0.8,0.6,1.9,0.8c1.1,0.1,2.1,0,2.1-0.3C84.4,553,84.3,552.9,84.1,552.8z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st364" d="M72.7,566.8c0,0,2.5,0,5.7,0c3.1,0,5.7,0,5.7,0s0-3-3.1-3C74.3,563.8,72.7,566.8,72.7,566.8z"/>
					</g>
					<g>
						<path class="st367" d="M81.6,563.9c0.1,0.1,0.1,0.2,0.1,0.2c0,0.7-2.4,1.3-5.3,1.3c-0.7,0-1.4,0-2-0.1
							c1.2-0.8,3.3-1.5,6.5-1.5C81.2,563.8,81.4,563.8,81.6,563.9z"/>
					</g>
					<g>
						<path class="st364" d="M73.1,566.2c0.9,0,2.9,0,5.2,0c2.8,0,5.2,0,5.6,0c0.1,0.3,0.1,0.6,0.1,0.6s-2.5,0-5.7,0
							c-3.1,0-5.7,0-5.7,0S72.8,566.6,73.1,566.2z"/>
					</g>
				</g>
			</g>
		</g>
		<g>
			<path class="st369" d="M92.1,499c0,3.5-0.9,10.2-2.1,10.2s-2.1-6.8-2.1-10.2c0-3.5,0.9-6.3,2.1-6.3S92.1,495.6,92.1,499z"/>
		</g>
		<g>
			<path class="st363" d="M96.6,508.7c6.1,4.9,11.8,10.6,16.4,16.8c1.3,1.8,11.8,16.7,8.1,18.1c-2,0.8-7.4-13.1-8.4-14.6
				c-4.4-6.3-11.7-14-19.8-15.2C87.1,513,91.8,503.5,96.6,508.7C97,509,96.6,508.7,96.6,508.7z"/>
		</g>
		<g>
			<g>
				<path class="st363" d="M96.7,508.8C96.8,508.8,96.8,508.8,96.7,508.8C96.8,508.8,96.8,508.8,96.7,508.8z"/>
			</g>
			<g>
				<path class="st363" d="M96.8,508.8C96.8,508.9,96.8,508.9,96.8,508.8L96.8,508.8z"/>
			</g>
			<g>
				<path class="st363" d="M96.8,508.8C96.8,508.8,96.8,508.8,96.8,508.8C96.8,508.8,96.8,508.8,96.8,508.8z"/>
			</g>
			<g>
				<path class="st363" d="M96.6,508.7C96.6,508.7,96.7,508.7,96.6,508.7C96.7,508.8,96.7,508.7,96.6,508.7z"/>
			</g>
			<g>
				<path class="st363" d="M96.7,508.8C96.7,508.8,96.7,508.8,96.7,508.8C96.7,508.8,96.7,508.8,96.7,508.8z"/>
			</g>
			<g>
				<path class="st363" d="M113.1,525.5c-4.6-6.3-10.3-12-16.4-16.8c-1.1-1.2-2.2-1.6-3.1-1.6c0.4,0.3,0.8,0.6,1.2,1
					c0,0,0,0,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1-0.1c6.4,5.1,12.4,11.1,17.3,17.8
					c1.2,1.6,9.4,13.2,9.4,17.6C124.3,541.4,114.3,527.3,113.1,525.5z"/>
			</g>
		</g>
		<g>
			<path class="st363" d="M83.3,516.4c-4.7,3.7-7.7,8.8-11.5,13.2c-2.1,3.2-5.6,4.2-9.1,2.8c-2.4-1-8.6-5.7-9.6-8.5
				c-0.4-1.2-0.8-2.2,0.5-2c1.2,0.2,1.8,1.6,2.4,2.3c2.6,3.5,10,10.1,13.8,4.2c4.2-4.3,6.7-11.3,9.2-16.6
				C81.3,506.8,88.6,513.2,83.3,516.4C83.2,516.5,83.3,516.4,83.3,516.4z"/>
		</g>
		<g>
			<g>
				<path class="st363" d="M54.4,522.8c0.9,1.2,2.3,2.7,3.9,4c-1-0.9-1.8-1.9-2.4-2.7c-0.5-0.7-1.1-2-2.1-2.3
					C54,522.3,54.2,522.6,54.4,522.8z"/>
			</g>
			<g>
				<path class="st363" d="M83.7,514.5c-5,4-8.2,9.4-12.3,14.2c-2.2,3.4-5.9,4.5-9.7,3c-0.8-0.3-2-1.1-3.3-2
					c1.7,1.3,3.3,2.3,4.3,2.8c3.5,1.5,7,0.4,9.1-2.8c3.8-4.5,6.9-9.5,11.5-13.2c0,0,0,0,0,0c0,0,0,0,0,0c1.5-0.9,2-2.2,1.9-3.3
					C84.8,513.6,84.3,514.1,83.7,514.5z M83.3,516.4C83.2,516.5,83.2,516.5,83.3,516.4L83.3,516.4z"/>
			</g>
			<g>
				<path class="st363" d="M83.7,514.5C83.7,514.5,83.7,514.5,83.7,514.5C83.7,514.5,83.7,514.5,83.7,514.5z"/>
			</g>
			<g>
				<path class="st363" d="M83.6,514.5C83.6,514.6,83.6,514.6,83.6,514.5L83.6,514.5z"/>
			</g>
		</g>
		<g>
			<g>
				<g>
					<path class="st369" d="M97.9,481.7c1.2,2.6,1.9,6.5-0.1,9.7c-1.7,3.2-6.1,5.8-7.8,5.8c-1.7,0-6.1-2.6-7.8-5.8
						c-2-3.2-1.3-7.1-0.1-9.7c2.2-5.2,1.6-9.5,7.9-9.5C96.3,472.2,95.8,476.4,97.9,481.7z"/>
				</g>
				<g>
					<path class="st374" d="M87.4,474.7c0.3,0.3-0.4,1.2-1.2,2.4c-0.8,1.2-1.7,2.4-1.9,2.1c-0.2-0.3,0.1-2,0.7-3.3
						C85.7,474.6,87,474.3,87.4,474.7z"/>
				</g>
				<g>
					<path class="st374" d="M88.9,474.1c0,0.4-0.2,0.8-0.6,0.8s-0.7-0.3-0.8-0.8c-0.1-0.4,0.2-0.8,0.6-0.8
						C88.5,473.3,88.9,473.7,88.9,474.1z"/>
				</g>
				<g class="st365">
					<path class="st369" d="M94.8,473.8c0.2,1.5,0.2,3.2,0.4,5.1c0.3,2.6,0.9,6.5-0.7,9.7c-1.3,3.2-4.9,5.8-7.5,5.8
						c-1.1,0-2.2-0.4-3.3-1c2.2,2.2,4.5,3.7,6.2,3.7c1.7,0,6.1-2.6,7.8-5.8c2-3.2,1.3-7.1,0.1-9.7C96.6,478.4,96.2,475.6,94.8,473.8
						z"/>
				</g>
			</g>
			<g>
				<g>
					<path class="st369" d="M81.5,482.8c-0.6,1.4,0.7,3.3,2.8,4.2c2.2,0.9,4.4,0.5,5-0.9c0.6-1.4-0.7-3.3-2.8-4.2
						C84.3,481,82.1,481.4,81.5,482.8z"/>
				</g>
				<g>
					<g>
						<path class="st370" d="M82.1,483.1c-0.4,0.9,0.8,2.2,2.6,3c1.8,0.8,3.6,0.7,3.9-0.2c0.4-0.9-0.8-2.2-2.6-3
							C84.3,482.1,82.5,482.2,82.1,483.1z"/>
					</g>
					<g>
						<path class="st370" d="M85.7,483.8c1.5,0.6,2.5,1.6,2.6,2.5c-0.7,0.5-2.1,0.4-3.6-0.2c-1.5-0.6-2.5-1.6-2.6-2.5
							C82.8,483.1,84.2,483.2,85.7,483.8z"/>
					</g>
					<g>
						<path class="st371" d="M86.2,483.8c-0.1,0.2,0.1,0.7,0.6,0.9c0.5,0.3,1,0.3,1.1,0.1c0.1-0.2-0.1-0.6-0.6-0.9
							C86.9,483.7,86.4,483.6,86.2,483.8z"/>
					</g>
					<g>
						<path class="st371" d="M82.6,484c-0.1,0.1,0.2,0.4,0.6,0.7c0.5,0.3,1,0.4,1.1,0.3c0.1-0.1-0.4-0.3-0.9-0.6
							C83,484.2,82.7,483.9,82.6,484z"/>
					</g>
					<g>
						<path class="st371" d="M85.5,483.3c-0.1,0.2-0.1,0.4,0.1,0.5c0.2,0.1,0.4,0,0.5-0.1c0.1-0.2,0.1-0.4-0.1-0.5
							C85.8,483,85.6,483.1,85.5,483.3z"/>
					</g>
				</g>
			</g>
			<g>
				<path class="st372" d="M87.5,480.4c0,0.2-0.2,0.4-0.4,0.4h-3.9c-0.2,0-0.4-0.2-0.4-0.4l0,0c0-0.2,0.2-0.4,0.4-0.4h3.9
					C87.3,480,87.5,480.2,87.5,480.4L87.5,480.4z"/>
			</g>
			<g>
				<path class="st372" d="M96.7,480.4c0,0.2-0.2,0.4-0.4,0.4h-3.9c-0.2,0-0.4-0.2-0.4-0.4l0,0c0-0.2,0.2-0.4,0.4-0.4h3.9
					C96.5,480,96.7,480.2,96.7,480.4L96.7,480.4z"/>
			</g>
			<g>
				<g>
					<path class="st369" d="M98.1,482.8c0.6,1.4-0.7,3.3-2.8,4.2c-2.2,0.9-4.4,0.5-5-0.9c-0.6-1.4,0.7-3.3,2.8-4.2
						C95.2,481,97.5,481.4,98.1,482.8z"/>
				</g>
				<g>
					<g>
						<path class="st370" d="M97.4,483.1c0.4,0.9-0.8,2.2-2.6,3c-1.8,0.8-3.6,0.7-3.9-0.2c-0.4-0.9,0.8-2.2,2.6-3
							C95.3,482.1,97,482.2,97.4,483.1z"/>
					</g>
					<g>
						<path class="st370" d="M93.9,483.8c-1.5,0.6-2.5,1.6-2.6,2.5c0.7,0.5,2.1,0.4,3.6-0.2c1.5-0.6,2.5-1.6,2.6-2.5
							C96.8,483.1,95.3,483.2,93.9,483.8z"/>
					</g>
					<g>
						<path class="st371" d="M93.3,483.8c0.1,0.2-0.1,0.7-0.6,0.9c-0.5,0.3-1,0.3-1.1,0.1c-0.1-0.2,0.1-0.6,0.6-0.9
							C92.7,483.7,93.2,483.6,93.3,483.8z"/>
					</g>
					<g>
						<path class="st371" d="M96.9,484c0.1,0.1-0.2,0.4-0.6,0.7c-0.5,0.3-1,0.4-1.1,0.3c-0.1-0.1,0.4-0.3,0.9-0.6
							C96.6,484.2,96.9,483.9,96.9,484z"/>
					</g>
					<g>
						<path class="st371" d="M94.1,483.3c0.1,0.2,0.1,0.4-0.1,0.5c-0.2,0.1-0.4,0-0.5-0.1c-0.1-0.2-0.1-0.4,0.1-0.5
							C93.8,483,94,483.1,94.1,483.3z"/>
					</g>
				</g>
			</g>
		</g>
		<g>
			<g>
				<path class="st363" d="M98.7,532.2c0,3.4-3.9,10-8.7,10c-4.8,0-8.7-6.6-8.7-10c0-3.4,3.9-6.1,8.7-6.1
					C94.8,526,98.7,528.8,98.7,532.2z"/>
			</g>
			<g>
				<path class="st363" d="M97.1,528.6l-2.2,0.8c0.2,0.4,0.5,0.8,0.7,1.1c-0.7-0.2-1.6-0.4-2.9-0.3c-3.2,0-10.8,0.8-10.8,0.8
					s2.9,0.8,5.7,1c2.9,0.2,8-0.6,8,0.9c0,1.1-0.5,2.6-0.8,3.2c-1.2,2.1-3.1,5-9.1,4.4c1.2,1,2.7,1.7,4.2,1.7c4.8,0,8.7-6.6,8.7-10
					C98.7,530.9,98.1,529.6,97.1,528.6z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st363" d="M100.6,518.4c0,6.6-4.7,19.5-10.6,19.5c-5.9,0-10.6-12.9-10.6-19.5c0-6.6,4.7-12,10.6-12
					C95.8,506.4,100.6,511.7,100.6,518.4z"/>
			</g>
			<g>
				<path class="st363" d="M100.6,518.4c0-5.7-3.5-10.4-8.1-11.6c-1.9,0.1-3.9,0.1-5.7,0.2c-0.7,0.3-1.4,0.6-2,1
					c0.7,0.7,3.6,1.1,6.3,1.5c3.3,0.5,6.5,0.6,6.3,7.6c-0.3,7.1-1,9.5-4.5,10c-3.5,0.5-9.1,1-9.1,1s7.4,2,9.4,2.2
					c2.6,0.3,1.7,0.4,3.2,0.5c0.7-1.3,1.5-0.8,2-2.2C99.8,525,100.6,521.1,100.6,518.4z"/>
			</g>
		</g>
		<g class="st381">
			<path class="st363" d="M81.2,516.6c0.2-4.4,1.6-4.1,3.3-4c1.7,0.1,0.8,0.4,0.6,4.8c-0.2,4.4,0.6,8-1.1,7.9
				C82.2,525.3,81,521,81.2,516.6z"/>
		</g>
		<g>
			<g>
				<g>
					<path class="st364" d="M124.5,546.2c-1.2,1.2-2.6,1.7-4,0.2c-1.4-1.4-2.2-4.2-1-5.4c1.2-1.2,4-0.3,5.4,1.1
						C126.2,543.6,125.7,545,124.5,546.2z"/>
				</g>
				<g>
					<path class="st364" d="M122.8,546.6c1.3,1.7,3.8,4.9,3.5,5.2c-0.3,0.2-3.3-2.6-4.6-4.3c-1.3-1.7-2.2-3.3-1.8-3.5
						C120.1,543.7,121.5,544.9,122.8,546.6z"/>
				</g>
				<g>
					<path class="st364" d="M120.5,546.3c0.1,2.2-0.1,2.8-0.5,2.8c-0.4,0-0.8-0.6-1-2.7c-0.1-2.2,0.1-3.9,0.5-3.9
						C120,542.4,120.4,544.1,120.5,546.3z"/>
				</g>
				<g>
					<path class="st364" d="M124.6,545.9c1.3,1.7,3.3,4.4,3,4.6c-0.3,0.2-2.8-2-4.1-3.7c-1.3-1.7-2.2-3.3-1.8-3.5
						C122,543,123.3,544.1,124.6,545.9z"/>
				</g>
				<g>
					<path class="st364" d="M125,544c1.5,1.6,3.6,4.6,3.3,4.8c-0.3,0.3-2.9-2.3-4.4-3.9c-1.5-1.6-2.5-3.1-2.2-3.3
						C122,541.4,123.5,542.4,125,544z"/>
				</g>
				<g>
					<path class="st364" d="M125.5,542.8c1.6,1.1,3.7,3,3.5,3.3c-0.2,0.3-2.6-1.1-4.3-2.2c-1.6-1.1-2.8-2.3-2.5-2.6
						C122.4,541,123.9,541.7,125.5,542.8z"/>
				</g>
				<g>
					<path class="st364" d="M123.8,548.8c0.5,0.7,1.7,1.8,1.5,1.9c-0.1,0.1-1.4-0.9-1.9-1.6c-0.5-0.7-0.8-1.3-0.7-1.5
						C122.9,547.5,123.3,548.1,123.8,548.8z"/>
				</g>
				<g>
					<path class="st364" d="M125.1,547.4c0.5,0.7,1.7,1.8,1.6,1.9c-0.1,0.1-1.4-1-1.9-1.6c-0.5-0.7-0.8-1.3-0.7-1.4
						C124.3,546.2,124.6,546.8,125.1,547.4z"/>
				</g>
				<g>
					<path class="st364" d="M126.2,546.4c0.5,0.6,1.7,1.6,1.5,1.7c-0.1,0.1-1.3-0.9-1.7-1.5c-0.5-0.6-0.7-1.2-0.6-1.3
						C125.6,545.2,125.8,545.8,126.2,546.4z"/>
				</g>
				<g>
					<path class="st364" d="M126.7,544.3c0.5,0.4,1.5,1,1.4,1.2c-0.1,0.1-1.2-0.5-1.7-0.9c-0.5-0.4-0.8-0.9-0.7-1
						C125.9,543.4,126.2,543.9,126.7,544.3z"/>
				</g>
				<g>
					<path class="st364" d="M119.6,546.6c0.1,0.6,0.4,1.9,0.3,1.9c-0.2,0-0.5-1.2-0.6-1.8c-0.1-0.6,0-1.2,0.2-1.2
						C119.7,545.5,119.6,546,119.6,546.6z"/>
				</g>
			</g>
			<g>
				<path class="st364" d="M114.4,532.6c2.1,4.5,5.3,11.7,6.7,11.1c2.3-0.9-0.9-7-3.9-12C116.2,531.9,115.3,532.2,114.4,532.6z"/>
			</g>
			<g>
				<g>
					<path class="st366" d="M119.3,530.4c0.4,0.3-0.5,2-2.7,3c-2.2,1-4.6,0.4-4.5-0.1c0-0.5,2-0.8,3.8-1.6
						C117.7,530.9,118.9,530,119.3,530.4z"/>
				</g>
				<g>
					<path class="st367" d="M115.5,532.4c0,0.1-0.4,0.4-1,0.5c-0.6,0.1-1.2,0.1-1.2-0.1c0-0.1,0.5-0.3,1.1-0.4
						C115,532.3,115.5,532.2,115.5,532.4z"/>
				</g>
				<g>
					<path class="st368" d="M118.5,530.4C118.5,530.4,118.5,530.4,118.5,530.4c0.3,0.3-0.6,1.9-2.9,2.7c-1.4,0.5-2.8,0.4-3.6,0.1
						c0,0.5,2.3,1.1,4.5,0.1c2.3-1,3.1-2.7,2.7-3C119.2,530.2,118.9,530.3,118.5,530.4z"/>
				</g>
			</g>
			<g>
				<path class="st364" d="M117.5,536.4c0.5,1.1,1.5,3.1,1.4,3.2c-0.1,0.1-1.4-1.9-1.9-2.9c-0.5-1.1-0.8-2-0.7-2.1
					C116.4,534.5,116.9,535.3,117.5,536.4z"/>
			</g>
		</g>
		<g>
			<g>
				<g>
					<path class="st364" d="M48.4,520.8c0.7-1.5,1.9-2.5,3.7-1.7c1.8,0.8,3.6,3.1,2.9,4.7c-0.7,1.5-3.6,1.7-5.4,0.9
						C47.7,523.8,47.7,522.3,48.4,520.8z"/>
				</g>
				<g>
					<path class="st364" d="M49.8,519.8c-1.8-1.1-5.3-3.3-5.1-3.6c0.2-0.3,4,1.3,5.9,2.4c1.8,1.1,3.2,2.3,3,2.6
						C53.3,521.5,51.7,520.9,49.8,519.8z"/>
				</g>
				<g>
					<path class="st364" d="M52.1,519.3c-0.9-2-0.9-2.6-0.6-2.8c0.4-0.2,1,0.2,1.9,2.2c0.9,2,1.3,3.7,0.9,3.9
						C53.9,522.7,52.9,521.2,52.1,519.3z"/>
				</g>
				<g>
					<path class="st364" d="M48.4,521.1c-1.8-1.1-4.7-2.9-4.5-3.2c0.2-0.3,3.4,0.9,5.2,2c1.8,1.1,3.2,2.3,3,2.6
						C51.9,522.9,50.2,522.3,48.4,521.1z"/>
				</g>
				<g>
					<path class="st364" d="M48.7,523c-2-0.9-5-3-4.8-3.3c0.2-0.4,3.5,1.1,5.4,2c2,0.9,3.4,2,3.2,2.3
						C52.4,524.4,50.7,523.9,48.7,523z"/>
				</g>
				<g>
					<path class="st364" d="M48.6,524.3c-1.9-0.5-4.5-1.5-4.4-1.8c0.1-0.3,2.9,0.1,4.8,0.5c1.9,0.5,3.4,1.2,3.3,1.5
						C52.2,524.9,50.5,524.8,48.6,524.3z"/>
				</g>
				<g>
					<path class="st364" d="M48.1,518.1c-0.7-0.4-2.2-1.1-2.1-1.2c0.1-0.2,1.6,0.4,2.3,0.8c0.7,0.4,1.2,1,1.1,1.1
						C49.4,519,48.8,518.6,48.1,518.1z"/>
				</g>
				<g>
					<path class="st364" d="M47.4,519.8c-0.7-0.4-2.2-1-2.1-1.2c0.1-0.2,1.6,0.4,2.3,0.9c0.7,0.4,1.2,0.9,1.1,1.1
						C48.6,520.7,48.1,520.3,47.4,519.8z"/>
				</g>
				<g>
					<path class="st364" d="M46.7,521.2c-0.7-0.4-2.1-0.9-2-1.1c0.1-0.2,1.5,0.4,2.2,0.8c0.7,0.4,1.1,0.9,1,1
						C47.7,522,47.3,521.6,46.7,521.2z"/>
				</g>
				<g>
					<path class="st364" d="M47,523.3c-0.6-0.2-1.8-0.4-1.7-0.6c0.1-0.1,1.3,0,1.9,0.3c0.6,0.2,1.1,0.5,1,0.7
						C48.1,523.9,47.6,523.6,47,523.3z"/>
				</g>
				<g>
					<path class="st364" d="M52.8,518.7c-0.3-0.6-1.1-1.6-0.9-1.7c0.1-0.1,0.9,0.9,1.2,1.5c0.3,0.6,0.4,1.1,0.3,1.2
						C53.2,519.7,53.1,519.2,52.8,518.7z"/>
				</g>
				<g class="st365">
					<path class="st364" d="M49.4,521.1c0.4-0.9,1-1.4,2-0.9c1,0.5,2,1.7,1.6,2.6c-0.4,0.8-2,1-3,0.5C49,522.8,49,521.9,49.4,521.1z
						"/>
				</g>
			</g>
			<g>
				<path class="st364" d="M62.7,529.9c-3.5-3.5-9.1-9-10.2-8c-1.9,1.7,3.3,6.3,7.9,9.8C61.2,531.2,62,530.6,62.7,529.9z"/>
			</g>
			<g>
				<g>
					<path class="st366" d="M58.9,533.7c-0.5-0.2-0.3-2.1,1.5-3.8c1.8-1.7,4.1-2,4.3-1.5c0.2,0.5-1.6,1.4-3,2.8
						C60.2,532.7,59.4,533.9,58.9,533.7z"/>
				</g>
				<g>
					<path class="st367" d="M61.7,530.5c-0.1-0.1,0.2-0.5,0.7-0.9c0.5-0.3,1.1-0.5,1.1-0.4c0.1,0.1-0.3,0.4-0.8,0.8
						C62.2,530.4,61.8,530.6,61.7,530.5z"/>
				</g>
				<g>
					<path class="st368" d="M59.6,533.4C59.6,533.4,59.6,533.4,59.6,533.4c-0.4-0.2-0.1-2,1.7-3.6c1.1-0.9,2.5-1.4,3.3-1.4
						c-0.2-0.5-2.5-0.2-4.3,1.5c-1.8,1.7-2,3.6-1.5,3.8C59.1,533.8,59.3,533.7,59.6,533.4z"/>
				</g>
			</g>
			<g>
				<path class="st364" d="M58.4,527.5c-0.9-0.8-2.5-2.4-2.4-2.5c0.1-0.1,1.9,1.2,2.8,2.1c0.9,0.8,1.5,1.6,1.4,1.7
					C60.1,528.8,59.3,528.3,58.4,527.5z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st385" d="M94.5,529.1c-0.1,0-0.3,0-0.4-0.1c-0.5-0.2-2-2-4.3-4.9c-3.5-4.4-7.6-14.2-7.8-14.6c-0.2-0.4,0-0.8,0.4-1
					c0.4-0.2,0.8,0,1,0.4c0,0.1,4.2,10,7.6,14.3c3,3.8,3.8,4.5,3.9,4.6c0.3,0.2,0.4,0.6,0.2,1C95.1,529,94.8,529.1,94.5,529.1z"/>
				<path class="st385" d="M95,527.8c-0.1-0.1-0.9-0.7-3.9-4.6c-3.4-4.3-7.5-14.2-7.6-14.3c-0.2-0.4-0.6-0.6-1-0.4c0,0,0,0,0,0
					c0.3,0,0.6,0.1,0.8,0.4c0,0.1,4.2,10,7.6,14.3c3,3.8,3.8,4.5,3.9,4.6c0.3,0.2,0.4,0.6,0.2,1c-0.1,0.2-0.2,0.3-0.4,0.3
					c0.2,0,0.5-0.1,0.6-0.4C95.4,528.4,95.3,528,95,527.8z"/>
			</g>
			<g>
				<g>
					<path class="st385" d="M81.5,529.3c-0.2,0-0.4-0.1-0.6-0.3c-0.3-0.3-0.2-0.8,0.1-1.1c7.6-6,14.8-19.5,14.9-19.6
						c0.2-0.4,0.6-0.5,1-0.3c0.4,0.2,0.5,0.7,0.3,1c-0.3,0.6-7.5,13.9-15.3,20.1C81.8,529.2,81.7,529.3,81.5,529.3z"/>
				</g>
				<path class="st385" d="M97,508c0,0-0.1,0-0.1,0c0.3,0.2,0.4,0.6,0.2,0.9c-0.3,0.6-7.5,13.9-15.3,20.1c-0.1,0.1-0.3,0.2-0.5,0.2
					c-0.1,0-0.2,0-0.2-0.1c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.3-0.1,0.5-0.2c7.8-6.2,15-19.5,15.3-20.1C97.5,508.7,97.3,508.2,97,508z
					"/>
			</g>
		</g>
		<g>
			<path class="st364" d="M99.4,529.6c-0.1,0.7-0.5,1.3-1,1.4c-5.5,0.9-10.9,0.9-16.4,0c-0.5-0.1-0.9-0.7-1-1.4
				c0-0.3-0.1-0.6-0.2-0.9c-0.2-0.7,0.1-1.1,0.7-1c6.1,0.8,11.2,0.8,17.3,0c0.6-0.1,0.9,0.4,0.7,1C99.5,529,99.4,529.3,99.4,529.6z"
				/>
		</g>
		<g>
			<path class="st368" d="M98.8,527.6c-0.6,0.1-1.1,0.1-1.7,0.2c0,0.2-0.1,0.5-0.1,0.7c-0.1,0.7-0.5,1.3-1,1.4
				c-5,0.8-9.9,0.9-14.9,0.2c0.2,0.4,0.5,0.8,0.9,0.8c5.5,0.9,10.9,0.9,16.4,0c0.5-0.1,0.9-0.7,1-1.4c0-0.3,0.1-0.6,0.2-0.9
				C99.7,528,99.4,527.5,98.8,527.6z"/>
		</g>
		<g>
			<path class="st367" d="M91.1,528.8c0,0.3-2.1,0.8-4.6,0.8c-2.5-0.1-4.6-0.7-4.6-1c0-0.3,2.1-0.1,4.6,0
				C89.1,528.7,91.1,528.6,91.1,528.8z"/>
		</g>
		<g>
			<g>
				<g>
					<path class="st362" d="M91.8,529.4c0,1.3-1.1,2.4-2.4,2.4c-1.3,0-2.4-1.1-2.4-2.4c0-1.3,1.1-2.4,2.4-2.4
						C90.8,527,91.8,528,91.8,529.4z"/>
				</g>
				<g>
					<path class="st362" d="M90.1,527.1c0.8,0.4,1.4,1.2,1.4,2.2c0,1.3-1.1,2.4-2.4,2.4c-0.2,0-0.5,0-0.7-0.1c0.3,0.1,0.6,0.2,1,0.2
						c1.3,0,2.4-1.1,2.4-2.4C91.8,528.3,91.1,527.4,90.1,527.1z"/>
				</g>
			</g>
			<g>
				<path class="st362" d="M87.7,529.7c0-1.2,0.8-2.1,1.9-2.3c-1.1,0-2,0.9-2,2.1c0,0.2,0,0.5,0.1,0.7
					C87.7,530,87.7,529.9,87.7,529.7z"/>
			</g>
			<g>
				<g>
					<path class="st362" d="M88.2,528.9c0.2-0.7,0.9-1,1.6-0.8c0.7,0.2,1,0.9,0.8,1.6c-0.2,0.7-0.9,1-1.6,0.8
						C88.3,530.3,88,529.6,88.2,528.9z"/>
				</g>
				<g>
					<path class="st362" d="M88.7,530.3c-0.4-0.3-0.5-0.8-0.4-1.3c0.2-0.7,0.9-1,1.6-0.8c0.1,0,0.2,0.1,0.3,0.2
						c-0.1-0.1-0.3-0.2-0.5-0.3c-0.7-0.2-1.4,0.2-1.6,0.8C88,529.4,88.2,530,88.7,530.3z"/>
				</g>
			</g>
		</g>
		<g>
			<g>
				<path class="st362" d="M90.6,514.1c0,0.3-0.2,0.5-0.5,0.5h-2.2c-0.3,0-0.5-0.2-0.5-0.5v-2.8c0-0.3,0.2-0.5,0.5-0.5h2.2
					c0.3,0,0.5,0.2,0.5,0.5V514.1z"/>
			</g>
			<g>
				<path class="st362" d="M90.4,510.9c0.1,0.1,0.1,0.2,0.1,0.3v2.8c0,0.3-0.2,0.5-0.5,0.5h-2.2c-0.1,0-0.2,0-0.3-0.1
					c0.1,0.1,0.3,0.2,0.4,0.2h2.2c0.3,0,0.5-0.2,0.5-0.5v-2.8C90.6,511.1,90.5,511,90.4,510.9z"/>
			</g>
			<g>
				<path class="st362" d="M89.5,511.1H88c-0.2,0-0.4,0.2-0.4,0.4v1.6c0,0,0.1,0,0.1,0v-1.5c0-0.2,0.2-0.4,0.4-0.4L89.5,511.1
					C89.5,511.2,89.5,511.2,89.5,511.1z"/>
			</g>
			<g>
				<g>
					<path class="st378" d="M89.4,511.7c-0.1-0.2-0.3-0.3-0.5-0.3c-0.2,0.1-0.3,0.3-0.3,0.5c0.1,0.2,0.3,0.3,0.5,0.3
						C89.3,512.1,89.4,511.9,89.4,511.7z"/>
				</g>
				<g>
					<path class="st362" d="M89.2,512.1c0.1-0.1,0.2-0.3,0.1-0.4c-0.1-0.2-0.3-0.3-0.5-0.3c0,0-0.1,0-0.1,0.1c0,0,0.1-0.1,0.2-0.1
						c0.2-0.1,0.4,0.1,0.5,0.3C89.4,511.9,89.4,512,89.2,512.1z"/>
				</g>
			</g>
			<g>
				<g>
					<path class="st379" d="M89.4,512.7c-0.1-0.2-0.3-0.3-0.5-0.3c-0.2,0.1-0.3,0.3-0.3,0.5c0.1,0.2,0.3,0.3,0.5,0.3
						C89.3,513.1,89.4,512.9,89.4,512.7z"/>
				</g>
				<g>
					<path class="st362" d="M89.2,513.2c0.1-0.1,0.2-0.3,0.1-0.4c-0.1-0.2-0.3-0.3-0.5-0.3c0,0-0.1,0-0.1,0.1c0,0,0.1-0.1,0.2-0.1
						c0.2-0.1,0.4,0.1,0.5,0.3C89.4,512.9,89.4,513.1,89.2,513.2z"/>
				</g>
			</g>
			<g>
				<g>
					<path class="st380" d="M89.4,513.7c-0.1-0.2-0.3-0.3-0.5-0.3c-0.2,0.1-0.3,0.3-0.3,0.5c0.1,0.2,0.3,0.3,0.5,0.3
						C89.3,514.2,89.4,513.9,89.4,513.7z"/>
				</g>
				<g>
					<path class="st362" d="M89.2,514.2c0.1-0.1,0.2-0.3,0.1-0.4c-0.1-0.2-0.3-0.3-0.5-0.3c0,0-0.1,0-0.1,0.1c0,0,0.1-0.1,0.2-0.1
						c0.2-0.1,0.4,0.1,0.5,0.3C89.4,513.9,89.4,514.1,89.2,514.2z"/>
				</g>
			</g>
		</g>
		<g class="st381">
			<path class="st363" d="M83.2,534.6c-0.5-2.2,0.2-2.2,1.1-2.4c0.9-0.2,0.4,0.1,0.9,2.3c0.5,2.2,1.3,3.9,0.5,4.1
				C84.8,538.7,83.7,536.8,83.2,534.6z"/>
		</g>
		<g>
			<path class="st373" d="M93.1,491.8c-0.2,0.9-2.9,2.5-3.4,2.4c-0.6-0.1-0.2-0.9-0.4-1.9c-0.1-0.4-1.4-2.2-0.9-2
				C91.9,491.9,93.3,490.9,93.1,491.8z"/>
			<path class="st373" d="M91.4,492.9c-0.2,0.4-0.7,0.6-1.1,0.5c-0.4-0.2-0.5-0.7-0.3-1.1c0.2-0.4,0.7-0.6,1.1-0.5
				C91.5,491.9,91.6,492.4,91.4,492.9z"/>
		</g>
		<g>
			<g>
				<g>
					<path class="st366" d="M97.6,505.9c0.2,1.2-0.9,2.7-2.4,3.1c-3.6,0.8-7.4,0.8-11,0c-1.6-0.4-2.6-1.8-2.4-3.1l0,0
						c0.2-1.3,1.5-1.9,2.9-1.5c3.3,0.8,6.8,0.8,10.1,0C96.1,504,97.4,504.6,97.6,505.9L97.6,505.9z"/>
				</g>
			</g>
			<g>
				<g>
					<path class="st367" d="M89.1,506.6c0,0.4-0.4,0.8-1,0.8c-1.2-0.1-2.5-0.3-3.7-0.5c-0.5-0.1-0.9-0.6-0.8-1l0,0
						c0.1-0.4,0.5-0.7,1-0.6c1.2,0.3,2.4,0.4,3.6,0.5C88.7,505.8,89.1,506.1,89.1,506.6L89.1,506.6z"/>
				</g>
			</g>
			<g>
				<g>
					<path class="st368" d="M95.9,504.3c0.2,0.3,0.4,0.6,0.4,1c0.2,1.3-0.9,2.7-2.4,2.9c-3.6,0.6-7.3,0.5-10.8-0.6
						c-0.4-0.1-0.8-0.3-1.2-0.6c0.3,0.9,1.2,1.7,2.3,1.9c3.6,0.8,7.4,0.8,11,0c1.6-0.4,2.6-1.8,2.4-3.1
						C97.4,505,96.8,504.4,95.9,504.3z"/>
				</g>
			</g>
			<g>
				<g>
					<g>
						<g>
							<path class="st362" d="M83.5,506.8c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5
								C83.3,506.3,83.5,506.5,83.5,506.8z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<path class="st362" d="M82.7,506.9c0-0.2,0.2-0.4,0.4-0.5C82.9,506.4,82.7,506.6,82.7,506.9C82.7,506.8,82.7,506.9,82.7,506.9
							C82.7,506.9,82.7,506.9,82.7,506.9z"/>
					</g>
				</g>
				<path class="st362" d="M83.3,506.3c0.1,0.1,0.1,0.2,0.1,0.3c0,0.3-0.2,0.5-0.5,0.5c-0.1,0-0.2,0-0.2-0.1
					c0.1,0.1,0.2,0.2,0.4,0.2c0.3,0,0.5-0.2,0.5-0.5C83.5,506.6,83.4,506.4,83.3,506.3z"/>
			</g>
			<g>
				<g>
					<g>
						<g>
							<path class="st362" d="M87.2,507.8c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5
								C86.9,507.3,87.2,507.5,87.2,507.8z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<path class="st362" d="M86.3,507.9c0-0.2,0.2-0.4,0.4-0.5C86.5,507.4,86.3,507.6,86.3,507.9C86.3,507.9,86.3,507.9,86.3,507.9
							C86.3,507.9,86.3,507.9,86.3,507.9z"/>
					</g>
				</g>
				<path class="st362" d="M86.9,507.4c0.1,0.1,0.1,0.2,0.1,0.3c0,0.3-0.2,0.5-0.5,0.5c-0.1,0-0.2,0-0.2-0.1
					c0.1,0.1,0.2,0.2,0.4,0.2c0.3,0,0.5-0.2,0.5-0.5C87.2,507.6,87,507.5,86.9,507.4z"/>
			</g>
			<g>
				<g>
					<g>
						<g>
							<path class="st362" d="M91.3,507.9c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5
								C91.1,507.4,91.3,507.7,91.3,507.9z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<path class="st362" d="M90.5,508c0-0.2,0.2-0.4,0.4-0.5C90.6,507.5,90.4,507.7,90.5,508C90.4,508,90.4,508.1,90.5,508
							C90.5,508.1,90.5,508,90.5,508z"/>
					</g>
				</g>
				<path class="st362" d="M91.1,507.5c0.1,0.1,0.1,0.2,0.1,0.3c0,0.3-0.2,0.5-0.5,0.5c-0.1,0-0.2,0-0.2-0.1
					c0.1,0.1,0.2,0.2,0.4,0.2c0.3,0,0.5-0.2,0.5-0.5C91.3,507.8,91.2,507.6,91.1,507.5z"/>
			</g>
			<g>
				<g>
					<g>
						<g>
							<path class="st362" d="M95.3,507.4c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5
								C95,506.9,95.3,507.1,95.3,507.4z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<path class="st362" d="M94.4,507.4c0-0.2,0.2-0.4,0.4-0.5C94.6,507,94.4,507.1,94.4,507.4C94.4,507.4,94.4,507.5,94.4,507.4
							C94.4,507.5,94.4,507.5,94.4,507.4z"/>
					</g>
				</g>
				<path class="st362" d="M95,506.9c0.1,0.1,0.1,0.2,0.1,0.3c0,0.3-0.2,0.5-0.5,0.5c-0.1,0-0.2,0-0.2-0.1c0.1,0.1,0.2,0.2,0.4,0.2
					c0.3,0,0.5-0.2,0.5-0.5C95.3,507.2,95.1,507,95,506.9z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st366" d="M102.3,518.6c-0.5,0.4-2-0.1-3.1-1.1c-2.5-2.3-3.6-4.4-2.8-7c0.4-1.1,1.5-2.2,2.2-2.3l0,0
					c0.7-0.1,0.9,0.6,0.7,1.5c-0.5,2.3,0.4,4.4,2.3,6.5C102.4,517.2,102.8,518.2,102.3,518.6L102.3,518.6z"/>
			</g>
			<g>
				<path class="st367" d="M98.4,513.1c-0.3,0.1-0.6-0.1-0.7-0.4c-0.2-0.8-0.1-1.6,0.1-2.4c0.1-0.4,0.4-0.7,0.7-0.8l0,0
					c0.3-0.1,0.4,0.2,0.3,0.5c-0.2,0.8-0.2,1.6,0,2.4C98.8,512.6,98.7,513,98.4,513.1L98.4,513.1z"/>
			</g>
			<g>
				<path class="st368" d="M102.2,517.1c0,0.2-0.1,0.4-0.3,0.5c-0.5,0.4-2-0.1-2.9-1.1c-2.2-2.2-2.7-4.4-1.6-7
					c0.1-0.3,0.3-0.6,0.5-0.9c-0.6,0.4-1.2,1.2-1.5,1.9c-0.9,2.6,0.2,4.7,2.8,7c1.1,1,2.6,1.5,3.1,1.1
					C102.6,518.3,102.5,517.7,102.2,517.1z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st366" d="M79,519.1c0.6,0.3,1.9-0.6,2.7-1.8c2-2.8,2.5-5.1,1.1-7.4c-0.6-1-2-1.8-2.7-1.7l0,0
					c-0.7,0-0.7,0.8-0.3,1.6c1,2.1,0.6,4.3-0.7,6.8C78.6,517.8,78.4,518.9,79,519.1L79,519.1z"/>
			</g>
			<g>
				<path class="st367" d="M81.5,512.9c0.3,0,0.6-0.2,0.6-0.6c0-0.8-0.3-1.6-0.7-2.4c-0.2-0.3-0.6-0.6-0.8-0.6l0,0
					c-0.3,0-0.3,0.3-0.2,0.6c0.4,0.7,0.6,1.5,0.6,2.3C81,512.5,81.2,512.8,81.5,512.9L81.5,512.9z"/>
			</g>
			<g>
				<path class="st368" d="M78.8,517.6c0.1,0.2,0.2,0.3,0.4,0.4c0.6,0.2,1.9-0.6,2.6-1.7c1.6-2.7,1.6-4.9,0-7.2
					c-0.2-0.3-0.5-0.5-0.7-0.8c0.7,0.3,1.5,0.9,1.9,1.5c1.4,2.3,0.9,4.6-1.1,7.4c-0.8,1.2-2.2,2-2.7,1.8
					C78.6,519,78.6,518.3,78.8,517.6z"/>
			</g>
		</g>
		<g>
			<g>
				<g>
					<g>
						<g>
							<g>
								<path class="st362" d="M91.5,520.6c-0.1,1.3-1.2,2.4-2.6,2.3c-1.3-0.1-2.4-1.2-2.3-2.6c0.1-1.3,1.2-2.4,2.6-2.3
									C90.5,518.1,91.5,519.2,91.5,520.6z"/>
							</g>
						</g>
						<g>
							<g>
								<path class="st362" d="M89.9,518.2c0.8,0.4,1.4,1.3,1.3,2.3c-0.1,1.3-1.2,2.4-2.6,2.3c-0.2,0-0.5-0.1-0.7-0.1
									c0.3,0.2,0.6,0.3,1,0.3c1.3,0.1,2.5-1,2.6-2.3C91.5,519.5,90.8,518.5,89.9,518.2z"/>
							</g>
						</g>
					</g>
					<g>
						<path class="st362" d="M86.7,520.4c0.1-1.3,1.2-2.4,2.6-2.3c0.3,0,0.6,0.1,0.9,0.2c-0.3-0.2-0.7-0.3-1-0.3
							c-1.3-0.1-2.5,1-2.6,2.3c-0.1,1,0.5,2,1.4,2.4C87.2,522.2,86.7,521.4,86.7,520.4z"/>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path class="st366" d="M90.8,520.4c0,1-0.8,1.8-1.8,1.8c-1,0-1.8-0.8-1.8-1.8c0-1,0.8-1.8,1.8-1.8
								C90,518.6,90.8,519.4,90.8,520.4z"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st367" d="M88.4,519.6c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4
								C88.3,519.3,88.4,519.5,88.4,519.6z"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st368" d="M90.1,519c0,0.1,0,0.2,0,0.3c0,1-0.8,1.8-1.8,1.8c-0.5,0-0.9-0.2-1.2-0.4c0.1,0.9,0.9,1.5,1.8,1.5
								c1,0,1.8-0.8,1.8-1.8C90.8,519.9,90.5,519.4,90.1,519z"/>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<path class="st385" d="M89,524.1c-2,0-3.7-1.6-3.7-3.7c0-2,1.6-3.7,3.7-3.7s3.7,1.6,3.7,3.7C92.7,522.5,91,524.1,89,524.1z
						 M89,517.6c-1.6,0-2.9,1.3-2.9,2.9c0,1.6,1.3,2.9,2.9,2.9c1.6,0,2.9-1.3,2.9-2.9C91.9,518.8,90.6,517.6,89,517.6z"/>
				</g>
				<g>
					<path class="st385" d="M86.9,522.4c-0.5-0.5-0.8-1.2-0.8-1.9c0-1.6,1.3-2.9,2.9-2.9c0.8,0,1.4,0.3,1.9,0.8
						c-0.5-0.6-1.3-1-2.1-1c-1.6,0-2.9,1.3-2.9,2.9C85.9,521.1,86.3,521.9,86.9,522.4z"/>
					<path class="st385" d="M91.5,517.8c0.6,0.7,1,1.5,1,2.5c0,2-1.6,3.7-3.7,3.7c-1,0-1.8-0.4-2.5-1c0.7,0.7,1.6,1.2,2.7,1.2
						c2,0,3.7-1.6,3.7-3.7C92.7,519.4,92.2,518.4,91.5,517.8z"/>
				</g>
			</g>
		</g>
	</g>
	<g>
		<g>
			<path class="st362" d="M449.3,108c-7.8,0-14.2,2.1-14.2,12.7s6.3,25.5,14.2,25.5c7.8,0,14.2-14.9,14.2-25.5S457.1,108,449.3,108z
				"/>
		</g>
		<g>
			<g>
				<g>
					<path class="st363" d="M454.5,167.1c0.8,10.5,1.6,21,2,31.5c-0.7,0-1.4,0.1-2.1,0.1c0.5-9.5,0.8-22.8-4.4-31L454.5,167.1
						C454.7,169.8,454.5,167.1,454.5,167.1z"/>
				</g>
				<g>
					<g>
						<path class="st363" d="M454.6,167.7c0,0,0-0.1,0-0.1C454.6,167.6,454.6,167.7,454.6,167.7z"/>
					</g>
					<g>
						<path class="st363" d="M454.6,167.8C454.6,167.8,454.6,167.7,454.6,167.8C454.6,167.7,454.6,167.8,454.6,167.8z"/>
					</g>
					<g>
						<path class="st363" d="M454.6,168.1c0,0,0-0.1,0-0.1C454.6,168,454.6,168,454.6,168.1z"/>
					</g>
					<g>
						<path class="st363" d="M454.6,168c0-0.1,0-0.1,0-0.2C454.6,167.8,454.6,167.9,454.6,168z"/>
					</g>
					<g>
						<path class="st363" d="M454.6,168.2C454.6,168.1,454.6,168.1,454.6,168.2C454.6,168.1,454.6,168.1,454.6,168.2z"/>
					</g>
					<g>
						<path class="st363" d="M454.6,168.3C454.6,168.2,454.6,168.2,454.6,168.3C454.6,168.2,454.6,168.2,454.6,168.3z"/>
					</g>
					<g>
						<path class="st363" d="M454.6,168.2C454.6,168.2,454.6,168.2,454.6,168.2C454.6,168.2,454.6,168.2,454.6,168.2z"/>
					</g>
					<g>
						<path class="st363" d="M454.6,168.3C454.6,168.3,454.6,168.3,454.6,168.3L454.6,168.3z"/>
					</g>
					<g>
						<path class="st363" d="M453.8,167.4c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0
							c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1C453.8,167.4,453.8,167.4,453.8,167.4c0,0,0-0.1,0-0.1l0,0
							c0.8,10.5,1.5,21,1.9,31.5c0.3,0,0.5,0,0.8,0c-0.4-10.5-1.2-21-2-31.5l-0.7,0.1C453.8,167.3,453.8,167.3,453.8,167.4z"/>
					</g>
					<g>
						<path class="st363" d="M454.6,167.6c0,0,0-0.1,0-0.1C454.6,167.5,454.6,167.6,454.6,167.6z"/>
					</g>
					<g>
						<path class="st363" d="M454.6,167.5c0-0.1,0-0.2,0-0.3C454.5,167.1,454.5,167.3,454.6,167.5z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<path class="st364" d="M454.4,198.8c0.7,0,1.4-0.1,2.1-0.1c-0.1-3.3-0.3-6.5-0.5-9.8c-0.5,0-1,0.1-1.4,0.2
						C454.7,192.5,454.5,195.8,454.4,198.8z"/>
				</g>
				<g>
					<g>
						<path class="st366" d="M456.8,188.7c0,0.2-0.6,0.5-1.4,0.7c-0.8,0.2-1.5,0.1-1.5-0.1c0-0.2,0.6-0.5,1.4-0.7
							C456.1,188.4,456.8,188.5,456.8,188.7z"/>
					</g>
					<g>
						<path class="st528" d="M455.2,188.9c0,0.1-0.2,0.1-0.4,0.2c-0.2,0-0.4,0-0.4,0c0-0.1,0.2-0.1,0.4-0.2
							C455,188.8,455.1,188.8,455.2,188.9z"/>
					</g>
					<g>
						<path class="st368" d="M456.5,188.5C456.5,188.5,456.5,188.5,456.5,188.5c0,0.2-0.6,0.5-1.4,0.7c-0.5,0.1-0.9,0.1-1.2,0.1
							c0,0.2,0.7,0.3,1.5,0.1c0.8-0.2,1.4-0.5,1.4-0.7C456.8,188.6,456.7,188.6,456.5,188.5z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st364" d="M461.6,200.2c0,0-1.8,0-4.1,0c-2.3,0-4.1,0-4.1,0s0-2.2,2.3-2.2C460.4,198,461.6,200.2,461.6,200.2z"/>
					</g>
					<g>
						<path class="st367" d="M455.1,198c0,0.1-0.1,0.1-0.1,0.2c0,0.5,1.7,0.9,3.9,0.9c0.5,0,1,0,1.5-0.1c-0.9-0.5-2.4-1.1-4.7-1.1
							C455.4,198,455.2,198,455.1,198z"/>
					</g>
					<g>
						<path class="st364" d="M461.2,199.7c-0.6,0-2.1,0-3.8,0c-2,0-3.7,0-4.1,0c-0.1,0.3-0.1,0.4-0.1,0.4s1.8,0,4.1,0
							c2.3,0,4.1,0,4.1,0S461.5,200,461.2,199.7z"/>
					</g>
				</g>
			</g>
		</g>
		<g>
			<g>
				<g>
					<path class="st363" d="M449.5,167.8c-2,10.3-3.5,20.5-4.4,31c-0.7,0-1.4-0.1-2.1-0.1c0.7-4.1,1.2-8.3,1.7-12.4
						c0.8-6.3,2.1-12.9,0.3-19.1L449.5,167.8C449,170.3,449.5,167.8,449.5,167.8z"/>
				</g>
				<g>
					<g>
						<path class="st363" d="M448.6,167.9C448.6,167.9,448.6,167.9,448.6,167.9C448.6,167.9,448.6,167.9,448.6,167.9z"/>
					</g>
					<g>
						<path class="st363" d="M448.5,168C448.5,168,448.5,168,448.5,168C448.5,168,448.5,168,448.5,168z"/>
					</g>
					<g>
						<path class="st363" d="M448.6,167.6c-1.9,10.3-3.3,20.6-4.2,31.1c0.2,0,0.4,0,0.6,0c0.9-10.5,2.4-20.7,4.4-31L448.6,167.6z
							 M449.5,167.8C449.5,167.8,449.5,167.8,449.5,167.8C449.5,167.8,449.5,167.8,449.5,167.8z M449.3,168.8
							C449.3,168.8,449.3,168.8,449.3,168.8C449.3,168.8,449.3,168.8,449.3,168.8z M449.3,168.7
							C449.3,168.7,449.3,168.7,449.3,168.7C449.3,168.7,449.3,168.7,449.3,168.7z M449.4,168.2
							C449.4,168.2,449.4,168.2,449.4,168.2C449.4,168.2,449.4,168.2,449.4,168.2z M449.4,168.1C449.4,168,449.4,168,449.4,168.1
							C449.4,168,449.4,168,449.4,168.1z M449.4,168C449.4,167.9,449.5,167.9,449.4,168C449.5,167.9,449.5,167.9,449.4,168z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<path class="st364" d="M446,190.3c-0.5,0-1.1,0-1.6,0c-0.1,0-0.1,0-0.2,0c-0.4,2.8-0.7,5.6-1.2,8.4c0.7,0,1.4,0.1,2.1,0.1
						C445.4,196,445.6,193.1,446,190.3z"/>
				</g>
				<g>
					<g>
						<path class="st366" d="M446.5,190.2c0,0.2-0.7,0.3-1.6,0.2c-0.8-0.1-1.4-0.3-1.4-0.6c0-0.2,0.7-0.3,1.6-0.2
							C446,189.8,446.6,190,446.5,190.2z"/>
					</g>
					<g>
						<path class="st528" d="M444.9,189.9c0,0.1-0.2,0.1-0.4,0.1c-0.2,0-0.4-0.1-0.4-0.1c0-0.1,0.2-0.1,0.4-0.1
							C444.7,189.8,444.9,189.8,444.9,189.9z"/>
					</g>
					<g>
						<path class="st368" d="M446.3,190C446.3,190,446.3,190,446.3,190c0,0.2-0.7,0.3-1.6,0.2c-0.5-0.1-0.9-0.2-1.2-0.3
							c0,0.2,0.6,0.5,1.4,0.5c0.8,0.1,1.5,0,1.6-0.2C446.6,190.2,446.5,190.1,446.3,190z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st364" d="M438,200.2c0,0,1.8,0,4.1,0c2.3,0,4.1,0,4.1,0s0-2.2-2.3-2.2C439.2,198,438,200.2,438,200.2z"/>
					</g>
					<g>
						<path class="st367" d="M444.5,198c0,0.1,0.1,0.1,0.1,0.2c0,0.5-1.7,0.9-3.9,0.9c-0.5,0-1,0-1.5-0.1c0.9-0.5,2.4-1.1,4.7-1.1
							C444.2,198,444.4,198,444.5,198z"/>
					</g>
					<g>
						<path class="st364" d="M438.4,199.7c0.6,0,2.1,0,3.8,0c2,0,3.7,0,4.1,0c0.1,0.3,0.1,0.4,0.1,0.4s-1.8,0-4.1,0
							c-2.3,0-4.1,0-4.1,0S438.1,200,438.4,199.7z"/>
					</g>
				</g>
			</g>
		</g>
		<g>
			<path class="st369" d="M451.3,140.1c0,2.5-0.7,7.4-1.5,7.4c-0.8,0-1.5-4.9-1.5-7.4c0-2.5,0.7-4.6,1.5-4.6
				C450.6,135.5,451.3,137.6,451.3,140.1z"/>
		</g>
		<g>
			<path class="st363" d="M454.6,147.1c4.4,3.5,8.5,7.7,11.9,12.2c0.9,1.3,8.6,12.1,5.9,13.1c-1.4,0.5-5.4-9.5-6.1-10.6
				c-3.2-4.6-8.5-10.2-14.3-11C447.6,150.2,451.1,143.3,454.6,147.1C454.8,147.3,454.6,147.1,454.6,147.1z"/>
		</g>
		<g>
			<g>
				<path class="st363" d="M454.7,147.2C454.7,147.2,454.7,147.2,454.7,147.2C454.7,147.2,454.7,147.2,454.7,147.2z"/>
			</g>
			<g>
				<path class="st363" d="M454.7,147.2C454.7,147.2,454.7,147.2,454.7,147.2L454.7,147.2z"/>
			</g>
			<g>
				<path class="st363" d="M454.7,147.2L454.7,147.2L454.7,147.2z"/>
			</g>
			<g>
				<path class="st363" d="M454.6,147.1C454.6,147.1,454.6,147.1,454.6,147.1C454.6,147.1,454.6,147.1,454.6,147.1z"/>
			</g>
			<g>
				<path class="st363" d="M454.6,147.1C454.6,147.1,454.6,147.2,454.6,147.1C454.6,147.2,454.6,147.1,454.6,147.1z"/>
			</g>
			<g>
				<path class="st363" d="M466.5,159.3c-3.4-4.5-7.5-8.7-11.9-12.2c-0.8-0.8-1.6-1.2-2.3-1.1c0.3,0.2,0.6,0.4,0.9,0.7c0,0,0,0,0,0
					c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c4.7,3.7,9,8.1,12.6,12.9c0.8,1.1,6.8,9.6,6.8,12.7
					C474.6,170.8,467.4,160.5,466.5,159.3z"/>
			</g>
		</g>
		<g>
			<path class="st363" d="M446.9,151.5c-3.4,2.7-5.6,6.4-8.4,9.6c-1.5,2.3-4,3.1-6.6,2c-1.8-0.7-6.3-4.2-7-6.2
				c-0.3-0.9-0.6-1.6,0.3-1.5c0.9,0.1,1.3,1.1,1.7,1.7c1.9,2.6,7.3,7.4,10,3.1c3-3.1,4.9-8.2,6.7-12
				C445.5,144.5,450.8,149.2,446.9,151.5C446.9,151.6,446.9,151.5,446.9,151.5z"/>
		</g>
		<g>
			<g>
				<path class="st363" d="M426,156.2c0.6,0.9,1.7,2,2.8,2.9c-0.7-0.7-1.3-1.4-1.7-1.9c-0.4-0.5-0.8-1.4-1.5-1.7
					C425.7,155.8,425.9,156,426,156.2z"/>
			</g>
			<g>
				<path class="st363" d="M447.2,150.1c-3.6,2.9-6,6.8-9,10.3c-1.6,2.4-4.3,3.3-7,2.1c-0.6-0.2-1.4-0.8-2.4-1.4
					c1.2,0.9,2.4,1.7,3.1,2c2.5,1.1,5.1,0.3,6.6-2c2.8-3.2,5-6.9,8.4-9.6c0,0,0,0,0,0c0,0,0,0,0,0c1.1-0.7,1.5-1.6,1.4-2.4
					C448.1,149.5,447.7,149.8,447.2,150.1z M446.9,151.5C446.9,151.6,446.9,151.6,446.9,151.5L446.9,151.5z"/>
			</g>
			<g>
				<path class="st363" d="M447.2,150.1C447.2,150.1,447.2,150.1,447.2,150.1C447.2,150.1,447.2,150.1,447.2,150.1z"/>
			</g>
			<g>
				<path class="st363" d="M447.2,150.2C447.2,150.2,447.2,150.2,447.2,150.2L447.2,150.2z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st369" d="M457.4,127.4c0,4.3-3.4,12.8-7.7,12.8c-4.2,0-7.7-8.5-7.7-12.8c0-4.3,3.4-7.9,7.7-7.9
					C454,119.6,457.4,123.1,457.4,127.4z"/>
			</g>
			<g>
				<g>
					<path class="st369" d="M442.7,128.4c-0.5,1.2,0.5,2.8,2.3,3.5c1.8,0.8,3.6,0.4,4.1-0.8c0.5-1.2-0.5-2.8-2.3-3.5
						C445.1,126.8,443.2,127.2,442.7,128.4z"/>
				</g>
				<g>
					<g>
						<path class="st370" d="M443.2,128.6c-0.3,0.7,0.6,1.9,2.1,2.5c1.5,0.6,3,0.5,3.3-0.2c0.3-0.7-0.6-1.9-2.1-2.5
							C445,127.8,443.5,127.8,443.2,128.6z"/>
					</g>
					<g>
						<path class="st370" d="M446.2,129.2c1.2,0.5,2.1,1.4,2.2,2c-0.6,0.4-1.8,0.4-3-0.2c-1.2-0.5-2.1-1.4-2.2-2
							C443.8,128.6,445,128.7,446.2,129.2z"/>
					</g>
					<g>
						<path class="st371" d="M446.6,129.2c-0.1,0.2,0.1,0.5,0.5,0.8c0.4,0.2,0.8,0.3,0.9,0.1c0.1-0.2-0.1-0.5-0.5-0.8
							C447.1,129.1,446.7,129,446.6,129.2z"/>
					</g>
					<g>
						<path class="st371" d="M443.6,129.4c0,0.1,0.1,0.4,0.5,0.6c0.4,0.2,0.8,0.3,0.9,0.3c0-0.1-0.3-0.3-0.7-0.5
							C443.9,129.5,443.7,129.3,443.6,129.4z"/>
					</g>
					<g>
						<path class="st371" d="M446,128.7c-0.1,0.1-0.1,0.3,0.1,0.4c0.1,0.1,0.3,0,0.4-0.1c0.1-0.1,0.1-0.3-0.1-0.4
							C446.2,128.6,446.1,128.6,446,128.7z"/>
					</g>
				</g>
			</g>
			<g>
				<path class="st372" d="M447.7,126.4c0,0.2-0.2,0.4-0.4,0.4h-3.3c-0.2,0-0.4-0.2-0.4-0.4l0,0c0-0.2,0.2-0.4,0.4-0.4h3.3
					C447.5,126,447.7,126.2,447.7,126.4L447.7,126.4z"/>
			</g>
			<g>
				<path class="st372" d="M455.3,126.4c0,0.2-0.2,0.4-0.4,0.4h-3.3c-0.2,0-0.4-0.2-0.4-0.4l0,0c0-0.2,0.2-0.4,0.4-0.4h3.3
					C455.1,126,455.3,126.2,455.3,126.4L455.3,126.4z"/>
			</g>
			<g>
				<path class="st373" d="M452.4,135.1c0,0.5-2.4,1.1-2.8,1.1c-0.4,0-2.8-0.6-2.8-1.1s2.4,0.2,2.8,0.2
					C450,135.3,452.4,134.6,452.4,135.1z"/>
			</g>
			<g>
				<path class="st374" d="M447.2,121.6c0.3,0.3-0.6,1-1.5,2c-1,1-1.7,2-2,1.7c-0.3-0.3,0-1.7,0.9-2.7
					C445.6,121.6,446.9,121.3,447.2,121.6z"/>
			</g>
			<g>
				<path class="st374" d="M448.8,121.1c0,0.3-0.3,0.6-0.6,0.6c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6
					C448.5,120.5,448.8,120.8,448.8,121.1z"/>
			</g>
			<g class="st365">
				<path class="st369" d="M454.1,120.9c0.8,1.2,1.2,2.7,1.2,4.2c0,4.3-3.4,12.8-7.7,12.8c-0.8,0-1.5-0.3-2.3-0.8
					c1.2,1.8,2.8,3,4.4,3c4.2,0,7.7-8.5,7.7-12.8C457.4,124.7,456.1,122.4,454.1,120.9z"/>
			</g>
			<g>
				<g>
					<path class="st369" d="M456.4,128.4c0.5,1.2-0.5,2.8-2.3,3.5c-1.8,0.8-3.6,0.4-4.1-0.8c-0.5-1.2,0.5-2.8,2.3-3.5
						C454.1,126.8,455.9,127.2,456.4,128.4z"/>
				</g>
				<g>
					<g>
						<path class="st370" d="M455.9,128.6c0.3,0.7-0.6,1.9-2.1,2.5c-1.5,0.6-3,0.5-3.3-0.2c-0.3-0.7,0.6-1.9,2.1-2.5
							C454.1,127.8,455.6,127.8,455.9,128.6z"/>
					</g>
					<g>
						<path class="st370" d="M453,129.2c-1.2,0.5-2.1,1.4-2.2,2c0.6,0.4,1.8,0.4,3-0.2c1.2-0.5,2.1-1.4,2.2-2
							C455.4,128.6,454.2,128.7,453,129.2z"/>
					</g>
					<g>
						<path class="st371" d="M452.5,129.2c0.1,0.2-0.1,0.5-0.5,0.8c-0.4,0.2-0.8,0.3-0.9,0.1c-0.1-0.2,0.1-0.5,0.5-0.8
							C452,129.1,452.4,129,452.5,129.2z"/>
					</g>
					<g>
						<path class="st371" d="M455.5,129.4c0,0.1-0.1,0.4-0.5,0.6c-0.4,0.2-0.8,0.3-0.9,0.3c0-0.1,0.3-0.3,0.7-0.5
							C455.2,129.5,455.4,129.3,455.5,129.4z"/>
					</g>
					<g>
						<path class="st371" d="M453.1,128.7c0.1,0.1,0.1,0.3-0.1,0.4c-0.1,0.1-0.3,0-0.4-0.1c-0.1-0.1-0.1-0.3,0.1-0.4
							C452.9,128.6,453.1,128.6,453.1,128.7z"/>
					</g>
				</g>
			</g>
		</g>
		<g>
			<g>
				<path class="st363" d="M456.1,164.1c0,2.5-2.8,7.3-6.3,7.3c-3.5,0-6.3-4.8-6.3-7.3c0-2.5,2.8-4.5,6.3-4.5
					C453.2,159.6,456.1,161.6,456.1,164.1z"/>
			</g>
			<g>
				<path class="st363" d="M454.9,161.6l-1.6,0.6c0.1,0.3,0.3,0.6,0.5,0.8c-0.5-0.2-1.2-0.3-2.1-0.3c-2.3,0-7.8,0.5-7.8,0.5
					s2.1,0.6,4.2,0.7c2.1,0.1,5.8-0.4,5.8,0.7c0,0.8-0.3,1.9-0.6,2.3c-0.9,1.5-2.2,3.7-6.6,3.2c0.9,0.7,1.9,1.2,3,1.2
					c3.5,0,6.3-4.8,6.3-7.3C456.1,163.2,455.6,162.3,454.9,161.6z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st363" d="M457.4,154.1c0,4.8-3.4,14.1-7.7,14.1c-4.2,0-7.7-9.3-7.7-14.1c0-4.8,3.4-8.7,7.7-8.7
					C454,145.4,457.4,149.3,457.4,154.1z"/>
			</g>
			<g>
				<path class="st363" d="M457.4,154.1c0-4.1-2.5-7.5-5.9-8.4c-1.4,0.1-2.9,0.1-4.1,0.2c-0.5,0.2-1,0.4-1.5,0.7
					c0.5,0.5,2.6,0.8,4.6,1.1c2.4,0.3,4.7,0.4,4.6,5.5c-0.2,5.1-0.7,6.9-3.3,7.2c-2.6,0.4-6.6,0.7-6.6,0.7s5.4,1.4,6.8,1.6
					c1.9,0.2,1.3,0.3,2.4,0.4c0.5-0.9,1.1-0.6,1.5-1.6C456.8,158.9,457.4,156.1,457.4,154.1z"/>
			</g>
		</g>
		<g>
			<g>
				<g>
					<path class="st364" d="M474.8,174.3c-0.9,0.9-1.9,1.2-2.9,0.2c-1-1-1.6-3-0.7-3.9c0.9-0.9,2.9-0.2,3.9,0.8
						C476,172.4,475.7,173.4,474.8,174.3z"/>
				</g>
				<g>
					<path class="st364" d="M473.5,174.6c1,1.2,2.8,3.6,2.5,3.8c-0.2,0.2-2.4-1.9-3.3-3.1c-1-1.2-1.6-2.4-1.3-2.6
						C471.6,172.5,472.6,173.3,473.5,174.6z"/>
				</g>
				<g>
					<path class="st364" d="M471.9,174.4c0.1,1.6,0,2-0.3,2c-0.3,0-0.6-0.4-0.7-2c-0.1-1.6,0.1-2.9,0.4-2.9
						C471.5,171.5,471.8,172.8,471.9,174.4z"/>
				</g>
				<g>
					<path class="st364" d="M474.9,174c1,1.2,2.4,3.2,2.2,3.3c-0.2,0.2-2-1.5-3-2.7c-1-1.2-1.6-2.4-1.3-2.6
						C472.9,171.9,473.9,172.8,474.9,174z"/>
				</g>
				<g>
					<path class="st364" d="M475.1,172.7c1.1,1.1,2.6,3.3,2.4,3.5c-0.2,0.2-2.1-1.7-3.2-2.8c-1.1-1.1-1.8-2.2-1.6-2.4
						C473,170.8,474,171.5,475.1,172.7z"/>
				</g>
				<g>
					<path class="st364" d="M475.5,171.8c1.2,0.8,2.7,2.2,2.5,2.4c-0.1,0.2-1.9-0.8-3.1-1.6c-1.2-0.8-2-1.7-1.8-1.9
						C473.3,170.5,474.3,171,475.5,171.8z"/>
				</g>
				<g>
					<path class="st364" d="M474.3,176.1c0.4,0.5,1.2,1.3,1.1,1.4c-0.1,0.1-1-0.7-1.4-1.1c-0.4-0.5-0.6-1-0.5-1.1
						C473.6,175.2,473.9,175.6,474.3,176.1z"/>
				</g>
				<g>
					<path class="st364" d="M475.2,175.2c0.4,0.5,1.3,1.3,1.1,1.4c-0.1,0.1-1-0.7-1.4-1.2c-0.4-0.5-0.6-0.9-0.5-1
						C474.6,174.3,474.8,174.7,475.2,175.2z"/>
				</g>
				<g>
					<path class="st364" d="M476,174.5c0.3,0.4,1.2,1.2,1.1,1.2c-0.1,0.1-0.9-0.6-1.3-1.1c-0.3-0.4-0.5-0.9-0.4-1
						C475.5,173.6,475.7,174,476,174.5z"/>
				</g>
				<g>
					<path class="st364" d="M476.4,172.9c0.4,0.3,1.1,0.7,1,0.8c-0.1,0.1-0.9-0.4-1.2-0.7c-0.4-0.3-0.6-0.6-0.5-0.7
						C475.8,172.3,476.1,172.6,476.4,172.9z"/>
				</g>
				<g>
					<path class="st364" d="M471.3,174.6c0,0.5,0.3,1.4,0.2,1.4c-0.1,0-0.4-0.9-0.4-1.3c0-0.5,0-0.9,0.1-0.9
						C471.3,173.7,471.2,174.1,471.3,174.6z"/>
				</g>
			</g>
			<g>
				<path class="st364" d="M467.5,164.4c1.5,3.3,3.8,8.5,4.9,8.1c1.7-0.7-0.6-5.1-2.9-8.7C468.8,163.9,468.1,164.1,467.5,164.4z"/>
			</g>
			<g>
				<g>
					<path class="st366" d="M471,162.8c0.3,0.2-0.3,1.5-2,2.2c-1.6,0.7-3.3,0.3-3.3-0.1c0-0.4,1.4-0.6,2.8-1.2
						C469.9,163.2,470.7,162.5,471,162.8z"/>
				</g>
				<g>
					<path class="st367" d="M468.3,164.3c0,0.1-0.3,0.3-0.7,0.4c-0.4,0.1-0.8,0-0.9,0c0-0.1,0.3-0.2,0.8-0.3
						C467.9,164.2,468.2,164.2,468.3,164.3z"/>
				</g>
				<g>
					<path class="st368" d="M470.4,162.8C470.4,162.8,470.4,162.8,470.4,162.8c0.2,0.3-0.4,1.4-2.1,2c-1,0.3-2,0.3-2.6,0.1
						c0,0.4,1.7,0.8,3.3,0.1c1.6-0.7,2.2-1.9,2-2.2C470.9,162.7,470.7,162.7,470.4,162.8z"/>
				</g>
			</g>
			<g>
				<path class="st364" d="M469.7,167.2c0.4,0.8,1.1,2.3,1,2.3c-0.1,0-1-1.3-1.4-2.1c-0.4-0.8-0.6-1.5-0.5-1.5
					C468.9,165.8,469.3,166.4,469.7,167.2z"/>
			</g>
		</g>
		<g>
			<g>
				<g>
					<path class="st364" d="M421.6,154.7c0.5-1.1,1.4-1.8,2.7-1.2c1.3,0.6,2.6,2.3,2.1,3.4c-0.5,1.1-2.6,1.2-3.9,0.7
						C421.1,156.9,421.1,155.8,421.6,154.7z"/>
				</g>
				<g>
					<path class="st364" d="M422.7,154c-1.3-0.8-3.9-2.4-3.7-2.6c0.1-0.2,2.9,0.9,4.2,1.7c1.3,0.8,2.3,1.7,2.2,1.9
						C425.2,155.2,424,154.8,422.7,154z"/>
				</g>
				<g>
					<path class="st364" d="M424.3,153.6c-0.6-1.4-0.7-1.9-0.4-2c0.3-0.1,0.7,0.2,1.4,1.6c0.6,1.4,0.9,2.7,0.7,2.8
						C425.7,156.1,424.9,155,424.3,153.6z"/>
				</g>
				<g>
					<path class="st364" d="M421.7,154.9c-1.3-0.8-3.4-2.1-3.2-2.3c0.1-0.2,2.4,0.6,3.8,1.5c1.3,0.8,2.3,1.7,2.2,1.9
						C424.2,156.2,423,155.8,421.7,154.9z"/>
				</g>
				<g>
					<path class="st364" d="M421.9,156.3c-1.4-0.7-3.6-2.2-3.5-2.4c0.1-0.3,2.5,0.8,3.9,1.5c1.4,0.7,2.5,1.4,2.3,1.7
						C424.6,157.3,423.3,157,421.9,156.3z"/>
				</g>
				<g>
					<path class="st364" d="M421.8,157.2c-1.4-0.4-3.3-1.1-3.2-1.3c0.1-0.2,2.1,0,3.5,0.4c1.4,0.4,2.5,0.8,2.4,1.1
						C424.4,157.7,423.2,157.6,421.8,157.2z"/>
				</g>
				<g>
					<path class="st364" d="M421.5,152.8c-0.5-0.3-1.6-0.8-1.5-0.9c0.1-0.1,1.2,0.3,1.7,0.6c0.5,0.3,0.9,0.7,0.8,0.8
						C422.4,153.4,422,153.1,421.5,152.8z"/>
				</g>
				<g>
					<path class="st364" d="M420.9,154c-0.5-0.3-1.6-0.8-1.6-0.9c0.1-0.1,1.2,0.3,1.7,0.6c0.5,0.3,0.9,0.7,0.8,0.8
						C421.8,154.6,421.5,154.3,420.9,154z"/>
				</g>
				<g>
					<path class="st364" d="M420.4,155c-0.5-0.3-1.5-0.7-1.5-0.8c0.1-0.1,1.1,0.3,1.6,0.5c0.5,0.3,0.8,0.6,0.7,0.7
						C421.2,155.6,420.9,155.3,420.4,155z"/>
				</g>
				<g>
					<path class="st364" d="M420.6,156.5c-0.4-0.2-1.3-0.3-1.3-0.4c0-0.1,0.9,0,1.4,0.2c0.4,0.2,0.8,0.4,0.7,0.5
						C421.4,156.9,421.1,156.7,420.6,156.5z"/>
				</g>
				<g>
					<path class="st364" d="M424.8,153.1c-0.2-0.4-0.8-1.2-0.7-1.2c0.1-0.1,0.7,0.7,0.9,1.1c0.2,0.4,0.3,0.8,0.2,0.9
						C425.1,153.9,425,153.6,424.8,153.1z"/>
				</g>
				<g class="st365">
					<path class="st364" d="M422.4,154.9c0.3-0.6,0.8-1,1.5-0.7c0.7,0.3,1.4,1.3,1.1,1.9c-0.3,0.6-1.4,0.7-2.2,0.4
						C422.1,156.1,422.1,155.5,422.4,154.9z"/>
				</g>
			</g>
			<g>
				<path class="st364" d="M432,161.3c-2.6-2.5-6.6-6.5-7.4-5.8c-1.4,1.2,2.4,4.5,5.8,7.1C430.9,162.2,431.5,161.8,432,161.3z"/>
			</g>
			<g>
				<g>
					<path class="st366" d="M429.3,164.1c-0.3-0.1-0.2-1.5,1.1-2.7c1.3-1.2,3-1.5,3.1-1.1c0.1,0.3-1.1,1-2.2,2.1
						C430.2,163.3,429.6,164.2,429.3,164.1z"/>
				</g>
				<g>
					<path class="st367" d="M431.3,161.7c-0.1-0.1,0.2-0.4,0.5-0.6c0.4-0.3,0.8-0.3,0.8-0.3c0,0.1-0.2,0.3-0.6,0.6
						C431.7,161.6,431.4,161.8,431.3,161.7z"/>
				</g>
				<g>
					<path class="st368" d="M429.8,163.8L429.8,163.8c-0.3-0.1-0.1-1.5,1.2-2.6c0.8-0.7,1.8-1,2.4-1c-0.1-0.4-1.8-0.1-3.1,1.1
						c-1.3,1.2-1.4,2.6-1.1,2.7C429.4,164.1,429.6,164,429.8,163.8z"/>
				</g>
			</g>
			<g>
				<path class="st364" d="M428.9,159.5c-0.6-0.6-1.8-1.7-1.8-1.8c0.1-0.1,1.4,0.9,2,1.5c0.6,0.6,1.1,1.1,1,1.2
					C430.2,160.5,429.6,160.1,428.9,159.5z"/>
			</g>
		</g>
		<g>
			<path class="st364" d="M456.6,162.2c-0.1,0.5-0.4,0.9-0.7,1c-4,0.6-7.9,0.6-11.9,0c-0.4-0.1-0.7-0.5-0.7-1c0-0.2-0.1-0.5-0.1-0.7
				c-0.1-0.5,0.1-0.8,0.5-0.7c4.4,0.6,8.1,0.6,12.5,0c0.4-0.1,0.7,0.3,0.5,0.7C456.6,161.8,456.6,162,456.6,162.2z"/>
		</g>
		<g>
			<path class="st368" d="M456.1,160.8c-0.4,0.1-0.8,0.1-1.2,0.2c0,0.2-0.1,0.4-0.1,0.5c-0.1,0.5-0.4,0.9-0.7,1
				c-3.6,0.6-7.2,0.6-10.8,0.2c0.1,0.3,0.4,0.5,0.6,0.6c4,0.6,7.9,0.6,11.9,0c0.4-0.1,0.7-0.5,0.7-1c0-0.2,0.1-0.5,0.1-0.7
				C456.8,161.1,456.6,160.8,456.1,160.8z"/>
		</g>
		<g>
			<path class="st367" d="M450.6,161.7c0,0.2-1.5,0.6-3.4,0.5c-1.8,0-3.3-0.5-3.3-0.7c0-0.2,1.5,0,3.3,0
				C449.1,161.6,450.6,161.5,450.6,161.7z"/>
		</g>
		<g>
			<g>

					<rect x="449.5" y="146.7" transform="matrix(0.1622 -0.9868 0.9868 0.1622 231.6774 566.7039)" class="st366" width="0.2" height="0.5"/>
			</g>
			<g>
				<path class="st366" d="M449.4,160.9l-0.1-0.4l0.5-0.1l0.1,0.4L449.4,160.9z M449.2,160l-0.1-0.4l0.5-0.1l0.1,0.4L449.2,160z
					 M449,159.1l-0.1-0.4l0.5-0.1l0.1,0.4L449,159.1z M448.8,158.2l0-0.2c0,0,0-0.1,0-0.1l0.5-0.1c0,0,0,0.1,0,0.1l0,0.2
					L448.8,158.2z M448.7,157.2c0-0.1,0-0.2,0-0.4l0.5-0.1c0,0.1,0,0.2,0,0.4L448.7,157.2z M448.6,156.3c0-0.1,0-0.2,0-0.4l0.5,0
					c0,0.1,0,0.2,0,0.4L448.6,156.3z M448.5,155.3c0-0.1,0-0.2,0-0.4l0.5,0v0c0,0.1,0,0.2,0,0.4L448.5,155.3z M449.1,154.4l-0.5,0
					c0-0.1,0-0.2,0-0.4l0.5,0C449.1,154.2,449.1,154.3,449.1,154.4z M449.1,153.5l-0.5,0c0-0.1,0-0.2,0-0.4l0.5,0
					C449.1,153.2,449.1,153.4,449.1,153.5z M449.2,152.6l-0.5,0c0-0.1,0-0.2,0-0.4l0.5,0C449.2,152.3,449.2,152.4,449.2,152.6z
					 M449.2,151.6l-0.5,0c0-0.1,0-0.2,0-0.4l0.5,0.1C449.3,151.4,449.2,151.5,449.2,151.6z M449.3,150.7l-0.5-0.1c0-0.1,0-0.2,0-0.4
					l0.5,0.1C449.3,150.5,449.3,150.6,449.3,150.7z M449.4,149.8l-0.5-0.1c0-0.1,0-0.2,0-0.4l0.5,0.1
					C449.5,149.6,449.4,149.7,449.4,149.8z M449.5,148.9l-0.5-0.1l0-0.4l0.5,0.1L449.5,148.9z M449.7,148l-0.5-0.1
					c0-0.1,0-0.3,0.1-0.4l0.5,0.1C449.7,147.7,449.7,147.8,449.7,148z"/>
			</g>
			<g>
				<polygon class="st366" points="449.5,161.6 449.5,161.4 450,161.3 450,161.5 				"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st369" d="M453.4,120.2l5.6-7.1c0.1-0.1,0.2-0.1,0.3,0c0.1,0.1,0.1,0.2,0.1,0.3l-4.7,7.7c-0.2,0.4-0.7,0.5-1,0.3
					C453.2,121.1,453.1,120.7,453.4,120.2C453.3,120.3,453.3,120.3,453.4,120.2z"/>
			</g>
			<g>
				<g>
					<path class="st375" d="M454.9,121.9c-0.2,0.2-0.5,0.3-0.7,0.1l-1.5-1.2c-0.2-0.2-0.3-0.5-0.1-0.7l0,0c0.2-0.2,0.5-0.3,0.7-0.1
						l1.5,1.2C455,121.3,455.1,121.7,454.9,121.9L454.9,121.9z"/>
				</g>
				<g>
					<path class="st374" d="M453.7,120.8c-0.1,0.1-0.2,0.1-0.2,0l-0.5-0.4c-0.1-0.1-0.1-0.2,0-0.2l0,0c0.1-0.1,0.2-0.1,0.2,0
						l0.5,0.4C453.8,120.6,453.8,120.7,453.7,120.8L453.7,120.8z"/>
				</g>
				<g class="st365">
					<path class="st376" d="M454.9,121.4c0,0.1,0,0.2-0.1,0.2c-0.2,0.2-0.5,0.3-0.7,0.1l-1.5-1.2c-0.1,0-0.1-0.1-0.1-0.2
						c0,0.2,0,0.4,0.2,0.5l1.5,1.2c0.2,0.2,0.5,0.1,0.7-0.1C455,121.7,455,121.5,454.9,121.4z"/>
				</g>
			</g>
			<g>
				<g>
					<path class="st376" d="M460,113.2c0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9
						C459.6,112.3,460,112.7,460,113.2z"/>
				</g>
				<g>
					<path class="st376" d="M458.9,112.8c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
						C458.8,112.7,458.9,112.7,458.9,112.8z"/>
				</g>
				<g class="st377">
					<path class="st376" d="M459.7,112.6c0,0,0,0.1,0,0.1c0,0.5-0.4,0.9-0.9,0.9c-0.2,0-0.4-0.1-0.6-0.2c0.1,0.4,0.4,0.7,0.8,0.7
						c0.5,0,0.9-0.4,0.9-0.9C460,112.9,459.9,112.7,459.7,112.6z"/>
				</g>
			</g>
		</g>
		<g>
			<g>
				<path class="st369" d="M445.5,120.2l-5.6-7.1c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2-0.1,0.3l4.7,7.7c0.2,0.4,0.7,0.5,1,0.3
					C445.7,121.1,445.8,120.7,445.5,120.2C445.5,120.3,445.5,120.3,445.5,120.2z"/>
			</g>
			<g>
				<g>
					<path class="st375" d="M444,121.9c0.2,0.2,0.5,0.3,0.7,0.1l1.5-1.2c0.2-0.2,0.3-0.5,0.1-0.7l0,0c-0.2-0.2-0.5-0.3-0.7-0.1
						l-1.5,1.2C443.8,121.3,443.8,121.7,444,121.9L444,121.9z"/>
				</g>
				<g>
					<path class="st374" d="M445.1,120.8c0.1,0.1,0.2,0.1,0.2,0l0.5-0.4c0.1-0.1,0.1-0.2,0-0.2l0,0c-0.1-0.1-0.2-0.1-0.2,0l-0.5,0.4
						C445.1,120.6,445.1,120.7,445.1,120.8L445.1,120.8z"/>
				</g>
				<g class="st365">
					<path class="st376" d="M443.9,121.4c0,0.1,0,0.2,0.1,0.2c0.2,0.2,0.5,0.3,0.7,0.1l1.5-1.2c0.1,0,0.1-0.1,0.1-0.2
						c0,0.2,0,0.4-0.2,0.5l-1.5,1.2c-0.2,0.2-0.5,0.1-0.7-0.1C443.8,121.7,443.8,121.5,443.9,121.4z"/>
				</g>
			</g>
			<g>
				<g>
					<path class="st376" d="M438.9,113.2c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9
						C439.2,112.3,438.9,112.7,438.9,113.2z"/>
				</g>
				<g>
					<path class="st376" d="M440,112.8c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
						C440,112.7,440,112.7,440,112.8z"/>
				</g>
				<g class="st377">
					<path class="st376" d="M439.2,112.6c0,0,0,0.1,0,0.1c0,0.5,0.4,0.9,0.9,0.9c0.2,0,0.4-0.1,0.6-0.2c-0.1,0.4-0.4,0.7-0.8,0.7
						c-0.5,0-0.9-0.4-0.9-0.9C438.9,112.9,439,112.7,439.2,112.6z"/>
				</g>
			</g>
		</g>
		<g>
			<path class="st362" d="M449.3,108c-7.8,0-14.2,2.1-14.2,12.7s6.3,25.5,14.2,25.5c7.8,0,14.2-14.9,14.2-25.5S457.1,108,449.3,108z
				 M448.9,143.9c-6.9,0-12.4-13.4-12.4-22.9c0-9.5,5.6-11.4,12.4-11.4s12.4,1.9,12.4,11.4C461.4,130.4,455.8,143.9,448.9,143.9z"/>
		</g>
		<g>
			<path class="st362" d="M450.3,112.5c0.3,0.8-3.9,1.2-7.3,2.4c-3.5,1.3-4.9,3-5.2,2.1c-0.3-0.8,0.7-3.9,4.1-5.2
				C445.3,110.6,450,111.6,450.3,112.5z"/>
		</g>
		<g>
			<g>
				<g>
					<path class="st362" d="M451.1,162.1c0,1-0.8,1.7-1.7,1.7c-1,0-1.7-0.8-1.7-1.7c0-1,0.8-1.7,1.7-1.7
						C450.3,160.3,451.1,161.1,451.1,162.1z"/>
				</g>
				<g>
					<path class="st362" d="M449.9,160.4c0.6,0.3,1,0.9,1,1.6c0,1-0.8,1.7-1.7,1.7c-0.2,0-0.3,0-0.5-0.1c0.2,0.1,0.5,0.2,0.7,0.2
						c1,0,1.7-0.8,1.7-1.7C451.1,161.3,450.6,160.6,449.9,160.4z"/>
				</g>
			</g>
			<g>
				<path class="st362" d="M448.1,162.3c0-0.8,0.6-1.5,1.4-1.7c-0.8,0-1.5,0.7-1.5,1.5c0,0.2,0,0.3,0.1,0.5
					C448.1,162.5,448.1,162.4,448.1,162.3z"/>
			</g>
			<g>
				<g>
					<path class="st362" d="M448.4,161.7c0.2-0.5,0.7-0.7,1.1-0.6c0.5,0.2,0.7,0.7,0.6,1.1c-0.2,0.5-0.7,0.7-1.1,0.6
						C448.6,162.7,448.3,162.2,448.4,161.7z"/>
				</g>
				<g>
					<path class="st362" d="M448.8,162.8c-0.3-0.2-0.4-0.6-0.3-0.9c0.1-0.5,0.7-0.7,1.1-0.6c0.1,0,0.2,0.1,0.2,0.1
						c-0.1-0.1-0.2-0.2-0.3-0.2c-0.5-0.2-1,0.1-1.1,0.6C448.3,162.1,448.5,162.5,448.8,162.8z"/>
				</g>
			</g>
		</g>
		<g>
			<g>
				<path class="st362" d="M454.9,154.9c0,0.2-0.2,0.4-0.4,0.4h-1.7c-0.2,0-0.4-0.2-0.4-0.4v-2.2c0-0.2,0.2-0.4,0.4-0.4h1.7
					c0.2,0,0.4,0.2,0.4,0.4V154.9z"/>
			</g>
			<g>
				<path class="st362" d="M454.7,152.4c0,0.1,0.1,0.1,0.1,0.2v2.2c0,0.2-0.2,0.4-0.4,0.4h-1.7c-0.1,0-0.2,0-0.2-0.1
					c0.1,0.1,0.2,0.2,0.3,0.2h1.7c0.2,0,0.4-0.2,0.4-0.4v-2.2C454.9,152.6,454.9,152.5,454.7,152.4z"/>
			</g>
			<g>
				<path class="st362" d="M454.1,152.6h-1.2c-0.2,0-0.3,0.1-0.3,0.3v1.3c0,0,0.1,0,0.1,0V153c0-0.2,0.1-0.3,0.3-0.3L454.1,152.6
					C454.1,152.7,454.1,152.7,454.1,152.6z"/>
			</g>
			<g>
				<g>
					<path class="st378" d="M454,153.1c-0.1-0.2-0.2-0.3-0.4-0.2c-0.2,0.1-0.3,0.2-0.2,0.4c0.1,0.2,0.2,0.3,0.4,0.2
						C453.9,153.4,454,153.2,454,153.1z"/>
				</g>
				<g>
					<path class="st362" d="M453.8,153.4c0.1-0.1,0.1-0.2,0.1-0.3c-0.1-0.2-0.2-0.3-0.4-0.2c0,0-0.1,0-0.1,0c0,0,0.1-0.1,0.1-0.1
						c0.2-0.1,0.3,0,0.4,0.2C454,153.2,453.9,153.3,453.8,153.4z"/>
				</g>
			</g>
			<g>
				<g>
					<path class="st379" d="M454,153.9c-0.1-0.2-0.2-0.3-0.4-0.2s-0.3,0.2-0.2,0.4s0.2,0.3,0.4,0.2C453.9,154.2,454,154,454,153.9z"
						/>
				</g>
				<g>
					<path class="st362" d="M453.8,154.2c0.1-0.1,0.1-0.2,0.1-0.3c-0.1-0.2-0.2-0.3-0.4-0.2c0,0-0.1,0-0.1,0c0,0,0.1-0.1,0.1-0.1
						c0.2-0.1,0.3,0,0.4,0.2C454,154,453.9,154.1,453.8,154.2z"/>
				</g>
			</g>
			<g>
				<g>
					<path class="st380" d="M454,154.6c-0.1-0.2-0.2-0.3-0.4-0.2c-0.2,0.1-0.3,0.2-0.2,0.4c0.1,0.2,0.2,0.3,0.4,0.2
						C453.9,155,454,154.8,454,154.6z"/>
				</g>
				<g>
					<path class="st362" d="M453.8,155c0.1-0.1,0.1-0.2,0.1-0.3c-0.1-0.2-0.2-0.3-0.4-0.2c0,0-0.1,0-0.1,0c0,0,0.1-0.1,0.1-0.1
						c0.2-0.1,0.3,0,0.4,0.2C454,154.8,453.9,154.9,453.8,155z"/>
				</g>
			</g>
		</g>
		<g class="st381">
			<path class="st363" d="M443.4,152.8c0.1-3.2,1.1-3,2.4-2.9c1.2,0.1,0.5,0.3,0.4,3.5c-0.1,3.2,0.4,5.8-0.8,5.8
				C444.1,159.1,443.2,156,443.4,152.8z"/>
		</g>
		<g class="st381">
			<path class="st363" d="M444.8,165.9c-0.3-1.6,0.2-1.6,0.8-1.7c0.6-0.1,0.3,0.1,0.7,1.7c0.3,1.6,1,2.8,0.3,3
				C446,168.9,445.2,167.4,444.8,165.9z"/>
		</g>
		<g>
			<path class="st382" d="M456.5,147c-0.6-0.5-1.7-1.1-3.4-1.3c-1.4-0.2-2.6-0.2-3.5-0.1c-0.9,0-2.1-0.1-3.5,0.1
				c-1.7,0.2-2.7,0.7-3.3,1.3c0,1,0.5,2,1,2.9c1.4,2,2.6,2.4,3.4,1.1c0.1-0.2,0.4-0.4,0.7-0.6c0,0,0.6,2.1,1.8,2.1
				c1.3,0,1.8-2.1,1.8-2.1c0.2,0.1,0.5,0.4,0.7,0.6c0.8,1.4,2,0.9,3.4-1.1C456,149,456.5,148,456.5,147z"/>
			<path class="st383" d="M456.5,147c-0.6-0.5-1.7-1.1-3.4-1.3c-0.8-0.1-1.5-0.1-2.1-0.1c-3.5,0.5-6.5,0.9-6.5,0.9
				c0,0,9.4,0.1,8.6,2.2c-0.2,0.8-0.6,1.6-1,2.3c0.8,1.2,2,0.7,3.3-1.2C456,149,456.5,148,456.5,147z"/>
			<path class="st384" d="M448.4,147.4c-1.1-0.2-2.1,1.6-1.8,4c0,0,0,0,0,0.1c0.2-0.1,0.3-0.3,0.5-0.5c0.1-0.2,0.4-0.4,0.7-0.6
				c0,0,0.6,1.9,1.7,2.1c0-0.1,0-0.2,0-0.3C449.9,149.7,449.6,147.6,448.4,147.4z"/>
			<g>
				<path class="st383" d="M449.6,152.9c-0.9,0-1.5-1.1-1.9-1.9c-0.1,0.1-0.2,0.2-0.3,0.3c-0.5,0.8-1.1,1-1.8,0.7
					c-0.7-0.3-1.4-1-2.1-2c-0.1-0.2-0.1-0.4,0.1-0.4c0.2,0,0.4,0.1,0.5,0.3c0.4,0.7,0.9,1.2,1.4,1.4c0.5,0.2,1,0,1.4-0.6
					c0.2-0.3,0.5-0.5,0.8-0.6c0,0,0.2-0.1,0.3,0c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0.5,1.8,1.5,1.8c1.1,0,1.5-1.7,1.5-1.8
					c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.3,0,0.3,0c0.3,0.1,0.6,0.4,0.8,0.6c0.4,0.6,0.8,0.8,1.4,0.6c0.5-0.2,1-0.7,1.4-1.5
					c0.1-0.2,0.4-0.3,0.5-0.3c0.2,0,0.2,0.2,0.1,0.4c-0.7,1-1.4,1.8-2.1,2.1c-0.7,0.3-1.3,0.1-1.8-0.7c-0.1-0.1-0.2-0.2-0.3-0.3
					C451.2,151.8,450.5,152.9,449.6,152.9z"/>
			</g>
			<path class="st383" d="M453.8,151.7c-0.8,0.3-1.4,0.1-1.9-0.7c-0.1-0.1-0.2-0.2-0.3-0.3c-0.4,0.8-1,1.9-2,1.9
				c-0.9,0-1.6-1.1-2-1.9c-0.1,0.1-0.2,0.2-0.3,0.3c-0.5,0.8-1.1,1-1.9,0.7c-0.7-0.3-1.4-1-2.1-1.9c0,0.1,0,0.1,0.1,0.2
				c0.7,1,1.4,1.7,2.1,2c0.7,0.3,1.3,0.1,1.8-0.7c0.1-0.1,0.2-0.2,0.3-0.3c0.4,0.8,1,1.9,1.9,1.9c0.9,0,1.6-1.1,2-1.9
				c0.1,0.1,0.2,0.2,0.3,0.3c0.5,0.8,1.1,1.1,1.8,0.7c0.7-0.3,1.4-1.1,2.1-2.1c0-0.1,0.1-0.1,0.1-0.2
				C455.2,150.7,454.5,151.4,453.8,151.7z"/>
		</g>
		<g>
			<g>
				<path class="st366" d="M455.9,144.2c0.2,1-0.7,2.2-2,2.5c-3,0.7-6,0.7-9,0c-1.3-0.3-2.1-1.5-2-2.5l0,0c0.2-1,1.2-1.5,2.3-1.2
					c2.7,0.6,5.5,0.6,8.2,0C454.7,142.7,455.7,143.2,455.9,144.2L455.9,144.2z"/>
			</g>
			<g>
				<path class="st367" d="M449,144.8c0,0.4-0.4,0.7-0.8,0.6c-1-0.1-2-0.2-3-0.4c-0.4-0.1-0.7-0.5-0.7-0.8l0,0
					c0-0.4,0.4-0.6,0.8-0.5c1,0.2,1.9,0.4,2.9,0.4C448.7,144.1,449,144.5,449,144.8L449,144.8z"/>
			</g>
			<g>
				<path class="st368" d="M454.5,142.9c0.2,0.2,0.3,0.5,0.4,0.8c0.1,1-0.7,2.2-2,2.4c-2.9,0.5-5.9,0.4-8.8-0.4
					c-0.4-0.1-0.7-0.3-1-0.5c0.3,0.7,1,1.4,1.8,1.6c3,0.7,6,0.7,9,0c1.3-0.3,2.1-1.5,2-2.5C455.8,143.5,455.2,143.1,454.5,142.9z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st362" d="M464.9,156.7c-0.3,0.8-1.1,0.3-2.2-0.9c-1.1-1.1-1.6-2-0.8-2.2c0.8-0.2,2-0.1,2.6,0.5
					C465,154.8,465.1,156,464.9,156.7z"/>
			</g>
			<g>
				<path class="st362" d="M462.1,154c0.6-0.3,1.6-0.1,2.2,0.4c-0.6-0.6-1.6-0.7-2.1-0.5c-0.1,0.1-0.2,0.1-0.2,0.2
					C461.9,154.1,462,154,462.1,154z"/>
			</g>
			<path class="st362" d="M464.8,154.9c0,0.3,0,0.7-0.1,1c-0.3,0.7-1.2,0.2-2.4-0.8c-0.3-0.3-0.6-0.6-0.8-0.8c0.1,0.4,0.5,1,1.2,1.6
				c1.1,1.2,1.9,1.7,2.2,0.9C465,156.2,465,155.5,464.8,154.9z"/>
		</g>
		<g>
			<g>
				<path class="st362" d="M438.7,161.4c-0.2-0.8-1-0.4-2.3,0.6c-1.2,1-1.8,1.8-1.1,2.1c0.7,0.3,1.9,0.3,2.6-0.3
					C438.6,163.4,438.9,162.2,438.7,161.4z"/>
			</g>
			<g>
				<path class="st362" d="M435.6,163.8c0.6,0.3,1.6,0.3,2.2-0.1c-0.6,0.5-1.6,0.6-2.2,0.3c-0.1-0.1-0.2-0.1-0.2-0.2
					C435.5,163.8,435.6,163.8,435.6,163.8z"/>
			</g>
			<path class="st362" d="M438.5,163.3c0.1-0.3,0.1-0.7,0-1c-0.2-0.7-1.2-0.3-2.4,0.6c-0.4,0.3-0.7,0.5-0.9,0.8
				c0.1-0.4,0.6-0.9,1.3-1.5c1.2-1,2.1-1.4,2.3-0.6C438.9,161.9,438.7,162.7,438.5,163.3z"/>
		</g>
		<g>
			<g>
				<g>
					<g>
						<g>
							<g>
								<path class="st362" d="M451.1,155.9c-0.1,1.2-1.1,2.2-2.4,2.1c-1.2-0.1-2.2-1.1-2.1-2.4c0.1-1.2,1.1-2.2,2.4-2.1
									C450.2,153.6,451.2,154.7,451.1,155.9z"/>
							</g>
						</g>
						<g>
							<g>
								<path class="st362" d="M449.7,153.7c0.8,0.4,1.3,1.2,1.2,2.1c-0.1,1.2-1.1,2.2-2.4,2.1c-0.2,0-0.4-0.1-0.7-0.1
									c0.3,0.1,0.6,0.2,0.9,0.3c1.2,0.1,2.3-0.9,2.4-2.1C451.2,154.9,450.6,154,449.7,153.7z"/>
							</g>
						</g>
					</g>
					<g>
						<path class="st362" d="M446.8,155.8c0.1-1.2,1.1-2.2,2.4-2.1c0.3,0,0.6,0.1,0.8,0.2c-0.3-0.2-0.6-0.3-0.9-0.3
							c-1.2-0.1-2.3,0.9-2.4,2.1c-0.1,1,0.5,1.8,1.3,2.2C447.2,157.5,446.7,156.7,446.8,155.8z"/>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path class="st366" d="M450.5,155.8c0,0.9-0.7,1.7-1.7,1.7s-1.7-0.7-1.7-1.7c0-0.9,0.7-1.7,1.7-1.7S450.5,154.9,450.5,155.8z
								"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st367" d="M448.3,155.1c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3
								C448.2,154.8,448.3,154.9,448.3,155.1z"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st368" d="M449.9,154.5c0,0.1,0,0.2,0,0.2c0,0.9-0.7,1.7-1.7,1.7c-0.4,0-0.8-0.2-1.1-0.4
								c0.1,0.8,0.8,1.4,1.6,1.4c0.9,0,1.7-0.7,1.7-1.7C450.5,155.3,450.3,154.8,449.9,154.5z"/>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<path class="st385" d="M448.9,159.2c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4c1.9,0,3.4,1.5,3.4,3.4
						C452.3,157.7,450.7,159.2,448.9,159.2z M448.9,153.1c-1.5,0-2.7,1.2-2.7,2.7c0,1.5,1.2,2.7,2.7,2.7c1.5,0,2.7-1.2,2.7-2.7
						C451.6,154.3,450.4,153.1,448.9,153.1z"/>
				</g>
				<g>
					<path class="st385" d="M446.9,157.6c-0.4-0.5-0.7-1.1-0.7-1.8c0-1.5,1.2-2.7,2.7-2.7c0.7,0,1.3,0.3,1.8,0.7
						c-0.5-0.5-1.2-0.9-2-0.9c-1.5,0-2.7,1.2-2.7,2.7C446,156.4,446.4,157.1,446.9,157.6z"/>
					<path class="st385" d="M451.2,153.4c0.6,0.6,0.9,1.4,0.9,2.3c0,1.9-1.5,3.4-3.4,3.4c-0.9,0-1.7-0.4-2.3-0.9
						c0.6,0.7,1.5,1.1,2.5,1.1c1.9,0,3.4-1.5,3.4-3.4C452.3,154.8,451.8,154,451.2,153.4z"/>
				</g>
			</g>
		</g>
	</g>
	<g>
		<path class="st537" d="M657.6,139.9c0,1.2-3.9,59.7-8.7,59.7c-4.8,0-8.7-58.5-8.7-59.7c0-1.2,3.9-2.2,8.7-2.2
			C653.7,137.7,657.6,138.7,657.6,139.9z"/>
		<g>
			<path class="st538" d="M664,127.9c0,1.8-7.1,8.9-16,8.9c-8.8,0-16-7.1-16-8.9c0-1.8,7.1-3.3,16-3.3
				C656.9,124.6,664,126,664,127.9z"/>
			<path class="st539" d="M648.1,125.2c8.4,0,15.2,1.3,15.9,3c0-0.1,0.1-0.2,0.1-0.3c0-1.8-7.1-3.3-16-3.3c-8.8,0-16,1.5-16,3.3
				c0,0.1,0,0.2,0.1,0.3C632.9,126.5,639.7,125.2,648.1,125.2z"/>
		</g>
		<g>
			<g>
				<path class="st362" d="M648.2,94.5c-9.1,0-16.5,2.5-16.5,14.8c0,12.3,7.4,29.7,16.5,29.7c9.1,0,16.5-17.4,16.5-29.7
					C664.8,97,657.4,94.5,648.2,94.5z"/>
			</g>
			<g>
				<g>
					<g>
						<path class="st539" d="M654.3,163.4c1,12.2,1.9,24.5,2.3,36.7c-0.8,0.1-1.7,0.1-2.5,0.2c0.6-11,0.9-26.6-5.1-36.2L654.3,163.4
							C654.6,166.5,654.3,163.4,654.3,163.4z"/>
					</g>
					<g>
						<g>
							<path class="st363" d="M654.4,164.1c0,0,0-0.1,0-0.1C654.4,164,654.4,164.1,654.4,164.1z"/>
						</g>
						<g>
							<path class="st363" d="M654.4,164.2C654.4,164.2,654.4,164.1,654.4,164.2C654.4,164.1,654.4,164.2,654.4,164.2z"/>
						</g>
						<g>
							<path class="st363" d="M654.4,164.5c0,0,0-0.1,0-0.1C654.4,164.4,654.4,164.5,654.4,164.5z"/>
						</g>
						<g>
							<path class="st363" d="M654.4,164.4c0-0.1,0-0.1,0-0.2C654.4,164.3,654.4,164.3,654.4,164.4z"/>
						</g>
						<g>
							<path class="st363" d="M654.4,164.6C654.4,164.6,654.4,164.6,654.4,164.6C654.4,164.6,654.4,164.6,654.4,164.6z"/>
						</g>
						<g>
							<path class="st363" d="M654.4,164.8C654.4,164.7,654.4,164.7,654.4,164.8C654.4,164.7,654.4,164.7,654.4,164.8z"/>
						</g>
						<g>
							<path class="st363" d="M654.4,164.7C654.4,164.7,654.4,164.6,654.4,164.7C654.4,164.6,654.4,164.7,654.4,164.7z"/>
						</g>
						<g>
							<path class="st363" d="M654.4,164.8C654.4,164.8,654.4,164.8,654.4,164.8L654.4,164.8z"/>
						</g>
						<g>
							<path class="st363" d="M653.5,163.7c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0
								c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1C653.5,163.8,653.5,163.8,653.5,163.7c0,0,0-0.1,0-0.2h0
								c0.9,12.2,1.8,24.5,2.2,36.7c0.3,0,0.6,0,0.9-0.1c-0.5-12.3-1.4-24.5-2.3-36.7l-0.8,0.1C653.5,163.6,653.5,163.7,653.5,163.7
								z"/>
						</g>
						<g>
							<path class="st363" d="M654.4,164c0-0.1,0-0.1,0-0.2C654.4,163.9,654.4,163.9,654.4,164z"/>
						</g>
						<g>
							<path class="st363" d="M654.4,163.8c0-0.1,0-0.2,0-0.4C654.3,163.4,654.3,163.6,654.4,163.8z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<path class="st364" d="M654.1,200.3c0.8-0.1,1.7-0.1,2.5-0.2c-0.1-3.8-0.3-7.6-0.6-11.4c-0.6,0-1.1,0.1-1.7,0.2
							C654.5,193,654.3,196.9,654.1,200.3z"/>
					</g>
					<g>
						<g>
							<path class="st366" d="M657,188.6c0,0.3-0.7,0.6-1.6,0.8c-0.9,0.2-1.7,0.1-1.7-0.1c0-0.3,0.7-0.6,1.6-0.8
								C656.2,188.3,657,188.3,657,188.6z"/>
						</g>
						<g>
							<path class="st528" d="M655,188.8c0,0.1-0.2,0.2-0.4,0.2c-0.3,0.1-0.5,0-0.5,0c0-0.1,0.2-0.2,0.4-0.2
								C654.8,188.7,655,188.7,655,188.8z"/>
						</g>
						<g>
							<path class="st368" d="M656.7,188.4C656.7,188.4,656.7,188.4,656.7,188.4c0,0.3-0.7,0.6-1.6,0.8c-0.6,0.1-1.1,0.1-1.4,0.1
								c0,0.2,0.8,0.3,1.7,0.1c0.9-0.2,1.7-0.6,1.6-0.8C657,188.5,656.9,188.4,656.7,188.4z"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st364" d="M662.5,202c0,0-2.1,0-4.8,0c-2.7,0-4.8,0-4.8,0s0-2.6,2.7-2.6C661.1,199.4,662.5,202,662.5,202z"/>
						</g>
						<g>
							<path class="st367" d="M655,199.5c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.6,2,1.1,4.5,1.1c0.6,0,1.2,0,1.7-0.1c-1-0.6-2.8-1.3-5.5-1.3
								C655.4,199.4,655.2,199.4,655,199.5z"/>
						</g>
						<g>
							<path class="st364" d="M662.1,201.5c-0.7,0-2.4,0-4.4,0c-2.4,0-4.4,0-4.7,0c-0.1,0.3-0.1,0.5-0.1,0.5s2.1,0,4.8,0
								c2.7,0,4.8,0,4.8,0S662.4,201.7,662.1,201.5z"/>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path class="st539" d="M648.4,164.2c-2.3,12-4,24-5.1,36.2c-0.8-0.1-1.7-0.1-2.5-0.2c0.9-4.8,1.4-9.6,2-14.5
							c0.9-7.3,2.4-15,0.3-22.3L648.4,164.2C647.9,167.2,648.4,164.2,648.4,164.2z"/>
					</g>
					<g>
						<g>
							<path class="st363" d="M647.4,164.4c0,0,0-0.1,0-0.1C647.4,164.3,647.4,164.3,647.4,164.4z"/>
						</g>
						<g>
							<path class="st363" d="M647.3,164.5C647.3,164.5,647.3,164.4,647.3,164.5C647.3,164.4,647.3,164.5,647.3,164.5z"/>
						</g>
						<g>
							<path class="st363" d="M647.4,164c-2.2,12.1-3.8,24-4.9,36.3c0.3,0,0.5,0,0.8,0c1.1-12.2,2.8-24.1,5.1-36.2L647.4,164z
								 M648.4,164.3C648.4,164.2,648.4,164.2,648.4,164.3C648.4,164.2,648.4,164.2,648.4,164.3z M648.2,165.4
								C648.2,165.4,648.2,165.4,648.2,165.4C648.2,165.4,648.2,165.4,648.2,165.4z M648.2,165.3
								C648.2,165.3,648.2,165.3,648.2,165.3C648.2,165.3,648.2,165.3,648.2,165.3z M648.3,164.7
								C648.3,164.7,648.3,164.7,648.3,164.7C648.3,164.7,648.3,164.7,648.3,164.7z M648.4,164.5c0,0,0-0.1,0-0.1
								C648.4,164.5,648.4,164.5,648.4,164.5z M648.4,164.4c0,0,0-0.1,0-0.1C648.4,164.3,648.4,164.4,648.4,164.4z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<path class="st364" d="M644.3,190.5c-0.6,0-1.2-0.1-1.9-0.1c-0.1,0-0.1,0-0.2,0c-0.4,3.3-0.9,6.5-1.4,9.7
							c0.8,0.1,1.7,0.1,2.5,0.2C643.6,197,644,193.8,644.3,190.5z"/>
					</g>
					<g>
						<g>
							<path class="st366" d="M645,190.4c-0.1,0.2-0.9,0.4-1.8,0.3c-0.9-0.1-1.7-0.4-1.6-0.6c0.1-0.2,0.9-0.4,1.8-0.3
								C644.3,189.8,645.1,190.1,645,190.4z"/>
						</g>
						<g>
							<path class="st528" d="M643.1,190c0,0.1-0.2,0.1-0.5,0.1c-0.3,0-0.5-0.1-0.4-0.2c0-0.1,0.2-0.1,0.5-0.1
								C642.9,189.8,643.1,189.9,643.1,190z"/>
						</g>
						<g>
							<path class="st368" d="M644.8,190.1C644.8,190.1,644.8,190.1,644.8,190.1c-0.1,0.3-0.9,0.4-1.8,0.3c-0.6-0.1-1.1-0.2-1.4-0.4
								c0,0.2,0.7,0.5,1.6,0.6c0.9,0.1,1.8,0,1.8-0.3C645,190.3,644.9,190.2,644.8,190.1z"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st364" d="M635.1,202c0,0,2.1,0,4.8,0c2.7,0,4.8,0,4.8,0s0-2.6-2.7-2.6C636.5,199.4,635.1,202,635.1,202z"/>
						</g>
						<g>
							<path class="st367" d="M642.7,199.5c0.1,0.1,0.1,0.1,0.1,0.2c0,0.6-2,1.1-4.5,1.1c-0.6,0-1.2,0-1.7-0.1
								c1-0.6,2.8-1.3,5.5-1.3C642.3,199.4,642.5,199.4,642.7,199.5z"/>
						</g>
						<g>
							<path class="st364" d="M635.5,201.5c0.7,0,2.4,0,4.4,0c2.4,0,4.4,0,4.7,0c0.1,0.3,0.1,0.5,0.1,0.5s-2.1,0-4.8,0
								c-2.6,0-4.8,0-4.8,0S635.2,201.7,635.5,201.5z"/>
						</g>
					</g>
				</g>
			</g>
			<g>
				<path class="st369" d="M650.5,131.9c0,2.9-0.8,8.6-1.7,8.6c-1,0-1.7-5.7-1.7-8.6c0-2.9,0.8-5.3,1.7-5.3
					C649.7,126.6,650.5,129,650.5,131.9z"/>
			</g>
			<g>
				<path class="st363" d="M654.4,140.1c5.2,4.1,10,8.9,13.9,14.2c1.1,1.5,10,14.1,6.8,15.3c-1.7,0.6-6.3-11.1-7.1-12.4
					c-3.7-5.3-9.9-11.8-16.7-12.8C646.3,143.7,650.3,135.7,654.4,140.1C654.7,140.3,654.4,140.1,654.4,140.1z"/>
			</g>
			<g>
				<g>
					<path class="st363" d="M654.5,140.2C654.5,140.2,654.5,140.2,654.5,140.2C654.5,140.2,654.5,140.2,654.5,140.2z"/>
				</g>
				<g>
					<path class="st363" d="M654.5,140.2C654.5,140.2,654.5,140.2,654.5,140.2L654.5,140.2z"/>
				</g>
				<g>
					<path class="st363" d="M654.5,140.2L654.5,140.2L654.5,140.2z"/>
				</g>
				<g>
					<path class="st363" d="M654.4,140.1C654.4,140.1,654.4,140.1,654.4,140.1C654.4,140.1,654.4,140.1,654.4,140.1z"/>
				</g>
				<g>
					<path class="st363" d="M654.4,140.1C654.4,140.1,654.5,140.2,654.4,140.1C654.5,140.2,654.4,140.1,654.4,140.1z"/>
				</g>
				<g>
					<path class="st363" d="M668.3,154.3c-3.9-5.3-8.7-10.1-13.9-14.2c-0.9-1-1.8-1.4-2.6-1.3c0.3,0.2,0.7,0.5,1,0.9c0,0,0,0,0.1,0
						c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c5.4,4.3,10.5,9.4,14.6,15
						c1,1.3,7.9,11.2,7.9,14.9C677.8,167.7,669.3,155.8,668.3,154.3z"/>
				</g>
			</g>
			<g>
				<path class="st363" d="M645.5,145.2c-4,3.2-6.5,7.4-9.8,11.2c-1.8,2.7-4.7,3.6-7.7,2.3c-2.1-0.9-7.3-4.9-8.2-7.2
					c-0.4-1-0.7-1.9,0.4-1.7c1,0.1,1.5,1.3,2,2c2.2,3,8.5,8.6,11.7,3.6c3.5-3.6,5.7-9.5,7.8-14C643.8,137.1,650,142.5,645.5,145.2
					C645.4,145.3,645.5,145.2,645.5,145.2z"/>
			</g>
			<g>
				<g>
					<path class="st363" d="M621.1,150.7c0.7,1,1.9,2.3,3.3,3.4c-0.8-0.8-1.5-1.6-2-2.3c-0.5-0.6-0.9-1.7-1.8-1.9
						C620.7,150.2,620.9,150.4,621.1,150.7z"/>
				</g>
				<g>
					<path class="st363" d="M645.8,143.6c-4.2,3.4-7,7.9-10.4,12c-1.9,2.9-5,3.8-8.2,2.5c-0.7-0.3-1.7-0.9-2.8-1.7
						c1.4,1.1,2.8,2,3.7,2.3c3,1.2,5.9,0.3,7.7-2.3c3.2-3.8,5.8-8,9.8-11.2c0,0,0,0,0,0c0,0,0,0,0,0c1.3-0.8,1.7-1.8,1.6-2.8
						C646.8,142.9,646.4,143.3,645.8,143.6z M645.5,145.3C645.5,145.3,645.4,145.3,645.5,145.3L645.5,145.3z"/>
				</g>
				<g>
					<path class="st363" d="M645.8,143.6C645.8,143.6,645.8,143.6,645.8,143.6C645.8,143.6,645.8,143.6,645.8,143.6z"/>
				</g>
				<g>
					<path class="st363" d="M645.8,143.7C645.8,143.7,645.8,143.7,645.8,143.7L645.8,143.7z"/>
				</g>
			</g>
			<g>
				<g>
					<path class="st369" d="M657.7,117.2c0,5.1-4,14.9-9,14.9c-4.9,0-9-9.9-9-14.9c0-5.1,4-9.2,9-9.2
						C653.7,108,657.7,112.1,657.7,117.2z"/>
				</g>
				<g>
					<g>
						<path class="st369" d="M640.5,118.2c-0.6,1.4,0.6,3.2,2.7,4.1c2.1,0.9,4.2,0.5,4.8-0.9c0.6-1.4-0.6-3.2-2.7-4.1
							C643.3,116.5,641.1,116.9,640.5,118.2z"/>
					</g>
					<g>
						<g>
							<path class="st370" d="M641.2,118.5c-0.4,0.9,0.7,2.2,2.5,2.9c1.7,0.7,3.4,0.6,3.8-0.2c0.4-0.9-0.7-2.2-2.5-2.9
								C643.2,117.5,641.5,117.6,641.2,118.5z"/>
						</g>
						<g>
							<path class="st370" d="M644.6,119.2c1.4,0.6,2.4,1.6,2.5,2.4c-0.7,0.5-2.1,0.4-3.5-0.2c-1.4-0.6-2.4-1.6-2.5-2.4
								C641.8,118.5,643.2,118.6,644.6,119.2z"/>
						</g>
						<g>
							<path class="st371" d="M645.1,119.2c-0.1,0.2,0.1,0.6,0.6,0.9c0.4,0.3,0.9,0.3,1.1,0.1c0.1-0.2-0.1-0.6-0.6-0.9
								C645.7,119.1,645.2,119,645.1,119.2z"/>
						</g>
						<g>
							<path class="st371" d="M641.6,119.4c-0.1,0.1,0.2,0.4,0.6,0.7c0.4,0.3,1,0.4,1,0.3c0.1-0.1-0.4-0.3-0.8-0.6
								C642,119.5,641.7,119.3,641.6,119.4z"/>
						</g>
						<g>
							<path class="st371" d="M644.4,118.7c-0.1,0.2-0.1,0.4,0.1,0.5c0.1,0.1,0.4,0,0.5-0.1c0.1-0.2,0.1-0.4-0.1-0.5
								C644.7,118.5,644.5,118.5,644.4,118.7z"/>
						</g>
					</g>
				</g>
				<g>
					<path class="st372" d="M646.3,115.9c0,0.2-0.2,0.4-0.4,0.4h-3.8c-0.2,0-0.4-0.2-0.4-0.4l0,0c0-0.2,0.2-0.4,0.4-0.4h3.8
						C646.2,115.5,646.3,115.7,646.3,115.9L646.3,115.9z"/>
				</g>
				<g>
					<path class="st372" d="M655.2,115.9c0,0.2-0.2,0.4-0.4,0.4H651c-0.2,0-0.4-0.2-0.4-0.4l0,0c0-0.2,0.2-0.4,0.4-0.4h3.8
						C655,115.5,655.2,115.7,655.2,115.9L655.2,115.9z"/>
				</g>
				<g>
					<path class="st374" d="M645.8,110.4c0.3,0.3-0.7,1.1-1.8,2.3c-1.1,1.2-2,2.3-2.3,2c-0.3-0.3-0.1-2,1.1-3.2
						C643.9,110.3,645.5,110.1,645.8,110.4z"/>
				</g>
				<g>
					<path class="st374" d="M647.6,109.8c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7
						C647.3,109.1,647.6,109.4,647.6,109.8z"/>
				</g>
				<g class="st365">
					<path class="st369" d="M653.8,109.6c0.9,1.4,1.4,3.1,1.4,4.9c0,5.1-4,14.9-9,14.9c-0.9,0-1.8-0.3-2.6-0.9
						c1.5,2.1,3.2,3.6,5.1,3.6c4.9,0,9-9.9,9-14.9C657.7,114,656.2,111.2,653.8,109.6z"/>
				</g>
				<g>
					<g>
						<path class="st369" d="M656.5,118.2c0.6,1.4-0.6,3.2-2.7,4.1c-2.1,0.9-4.2,0.5-4.8-0.9c-0.6-1.4,0.6-3.2,2.7-4.1
							C653.8,116.5,655.9,116.9,656.5,118.2z"/>
					</g>
					<g>
						<g>
							<path class="st370" d="M655.9,118.5c0.4,0.9-0.7,2.2-2.5,2.9c-1.7,0.7-3.4,0.6-3.8-0.2c-0.4-0.9,0.7-2.2,2.5-2.9
								C653.8,117.5,655.5,117.6,655.9,118.5z"/>
						</g>
						<g>
							<path class="st370" d="M652.5,119.2c-1.4,0.6-2.4,1.6-2.5,2.4c0.7,0.5,2.1,0.4,3.5-0.2c1.4-0.6,2.4-1.6,2.5-2.4
								C655.3,118.5,653.9,118.6,652.5,119.2z"/>
						</g>
						<g>
							<path class="st371" d="M652,119.2c0.1,0.2-0.1,0.6-0.6,0.9c-0.4,0.3-0.9,0.3-1.1,0.1c-0.1-0.2,0.1-0.6,0.6-0.9
								C651.4,119.1,651.8,119,652,119.2z"/>
						</g>
						<g>
							<path class="st371" d="M655.4,119.4c0.1,0.1-0.2,0.4-0.6,0.7c-0.4,0.3-1,0.4-1,0.3c-0.1-0.1,0.4-0.3,0.8-0.6
								C655.1,119.5,655.4,119.3,655.4,119.4z"/>
						</g>
						<g>
							<path class="st371" d="M652.7,118.7c0.1,0.2,0.1,0.4-0.1,0.5c-0.1,0.1-0.4,0-0.5-0.1c-0.1-0.2-0.1-0.4,0.1-0.5
								C652.4,118.5,652.6,118.5,652.7,118.7z"/>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<path class="st539" d="M656.1,159.9c0,2.9-3.3,8.5-7.3,8.5c-4.1,0-7.3-5.6-7.3-8.5c0-2.9,3.3-5.2,7.3-5.2
						C652.8,154.7,656.1,157,656.1,159.9z"/>
				</g>
				<g>
					<path class="st363" d="M654.8,156.9l-1.8,0.7c0.2,0.3,0.4,0.6,0.6,0.9c-0.6-0.2-1.4-0.3-2.5-0.3c-2.7,0-9.1,0.6-9.1,0.6
						s2.4,0.7,4.9,0.9c2.4,0.1,6.8-0.5,6.8,0.8c0,0.9-0.4,2.2-0.7,2.7c-1,1.8-2.6,4.3-7.7,3.8c1.1,0.9,2.3,1.4,3.5,1.4
						c4.1,0,7.3-5.6,7.3-8.5C656.1,158.8,655.6,157.8,654.8,156.9z"/>
				</g>
			</g>
			<g>
				<g>
					<path class="st540" d="M657.7,148.2c0,5.6-4,16.5-9,16.5c-4.9,0-9-10.9-9-16.5c0-5.6,4-10.1,9-10.1
						C653.7,138.1,657.7,142.6,657.7,148.2z"/>
				</g>
				<g>
					<path class="st363" d="M657.7,148.2c0-4.8-2.9-8.8-6.9-9.8c-1.6,0.1-3.3,0.1-4.8,0.2c-0.6,0.2-1.2,0.5-1.7,0.8
						c0.6,0.6,3,0.9,5.3,1.3c2.8,0.4,5.5,0.5,5.3,6.5c-0.2,6-0.9,8-3.8,8.4c-3,0.4-7.7,0.9-7.7,0.9s6.3,1.7,7.9,1.8
						c2.2,0.2,1.5,0.4,2.7,0.4c0.6-1.1,1.2-0.6,1.7-1.9C657,153.8,657.7,150.6,657.7,148.2z"/>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path class="st364" d="M677.9,171.8c-1,1-2.2,1.4-3.4,0.2c-1.2-1.2-1.9-3.5-0.9-4.5c1-1,3.4-0.3,4.5,0.9
							C679.4,169.6,679,170.8,677.9,171.8z"/>
					</g>
					<g>
						<path class="st364" d="M676.5,172.1c1.1,1.4,3.2,4.2,3,4.4c-0.3,0.2-2.8-2.2-3.9-3.7c-1.1-1.4-1.8-2.8-1.6-3
							C674.2,169.7,675.4,170.7,676.5,172.1z"/>
					</g>
					<g>
						<path class="st364" d="M674.6,171.9c0.1,1.8-0.1,2.3-0.4,2.4c-0.3,0-0.7-0.5-0.8-2.3c-0.1-1.8,0.1-3.3,0.4-3.3
							C674.1,168.6,674.5,170,674.6,171.9z"/>
					</g>
					<g>
						<path class="st364" d="M678,171.5c1.1,1.4,2.8,3.7,2.6,3.9c-0.3,0.2-2.4-1.7-3.5-3.2c-1.1-1.4-1.8-2.8-1.6-3
							C675.8,169,676.9,170,678,171.5z"/>
					</g>
					<g>
						<path class="st364" d="M678.3,169.9c1.3,1.3,3.1,3.9,2.8,4.1c-0.2,0.2-2.4-1.9-3.7-3.3c-1.3-1.3-2.1-2.6-1.9-2.8
							C675.8,167.7,677.1,168.6,678.3,169.9z"/>
					</g>
					<g>
						<path class="st364" d="M678.8,168.9c1.4,1,3.1,2.5,3,2.8c-0.2,0.2-2.2-0.9-3.6-1.9c-1.4-1-2.3-1.9-2.2-2.2
							C676.2,167.4,677.4,168,678.8,168.9z"/>
					</g>
					<g>
						<path class="st364" d="M677.3,173.9c0.4,0.6,1.4,1.5,1.3,1.6c-0.1,0.1-1.1-0.8-1.6-1.3c-0.4-0.6-0.7-1.1-0.6-1.2
							C676.6,172.8,676.9,173.4,677.3,173.9z"/>
					</g>
					<g>
						<path class="st364" d="M678.4,172.8c0.4,0.6,1.5,1.5,1.3,1.6c-0.1,0.1-1.1-0.8-1.6-1.4c-0.4-0.6-0.7-1.1-0.6-1.2
							C677.7,171.7,678,172.3,678.4,172.8z"/>
					</g>
					<g>
						<path class="st364" d="M679.4,172c0.4,0.5,1.4,1.3,1.3,1.4c-0.1,0.1-1.1-0.7-1.5-1.2c-0.4-0.5-0.6-1-0.5-1.1
							C678.8,171,679,171.5,679.4,172z"/>
					</g>
					<g>
						<path class="st364" d="M679.8,170.2c0.4,0.4,1.3,0.9,1.2,1c-0.1,0.1-1-0.4-1.4-0.8c-0.4-0.4-0.7-0.7-0.6-0.8
							C679.1,169.4,679.4,169.8,679.8,170.2z"/>
					</g>
					<g>
						<path class="st364" d="M673.8,172.1c0.1,0.5,0.4,1.6,0.2,1.6c-0.1,0-0.4-1-0.5-1.6c-0.1-0.6,0-1,0.1-1
							C673.8,171.1,673.8,171.6,673.8,172.1z"/>
					</g>
				</g>
				<g>
					<path class="st364" d="M669.4,160.2c1.7,3.8,4.5,9.9,5.7,9.4c2-0.8-0.7-6-3.3-10.1C670.9,159.7,670.1,159.9,669.4,160.2z"/>
				</g>
				<g>
					<g>
						<path class="st366" d="M673.5,158.4c0.3,0.3-0.4,1.7-2.3,2.5c-1.9,0.8-3.9,0.4-3.8-0.1c0-0.4,1.7-0.7,3.2-1.3
							C672.2,158.8,673.2,158.1,673.5,158.4z"/>
					</g>
					<g>
						<path class="st367" d="M670.3,160.1c0,0.1-0.3,0.3-0.8,0.5c-0.5,0.1-1,0.1-1-0.1c0-0.1,0.4-0.2,0.9-0.4
							C669.9,160,670.3,160,670.3,160.1z"/>
					</g>
					<g>
						<path class="st368" d="M672.9,158.4C672.9,158.4,672.9,158.4,672.9,158.4c0.3,0.3-0.5,1.6-2.4,2.3c-1.2,0.4-2.4,0.3-3.1,0.1
							c0,0.4,1.9,0.9,3.8,0.1c1.9-0.8,2.6-2.2,2.3-2.5C673.4,158.3,673.2,158.3,672.9,158.4z"/>
					</g>
				</g>
				<g>
					<path class="st364" d="M672,163.5c0.4,0.9,1.3,2.6,1.2,2.7c-0.1,0.1-1.1-1.6-1.6-2.5c-0.4-0.9-0.7-1.7-0.6-1.8
						C671.1,161.9,671.5,162.5,672,163.5z"/>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path class="st364" d="M616,148.9c0.6-1.3,1.6-2.1,3.1-1.4c1.5,0.7,3,2.6,2.4,3.9c-0.6,1.3-3,1.5-4.6,0.8
							C615.4,151.5,615.4,150.2,616,148.9z"/>
					</g>
					<g>
						<path class="st364" d="M617.2,148.1c-1.6-1-4.5-2.8-4.3-3c0.2-0.3,3.4,1.1,4.9,2c1.6,1,2.7,2,2.5,2.2
							C620.2,149.6,618.8,149,617.2,148.1z"/>
					</g>
					<g>
						<path class="st364" d="M619.1,147.6c-0.7-1.7-0.8-2.2-0.5-2.3c0.3-0.1,0.8,0.2,1.6,1.9c0.7,1.7,1.1,3.1,0.8,3.3
							C620.7,150.6,619.8,149.3,619.1,147.6z"/>
					</g>
					<g>
						<path class="st364" d="M616,149.2c-1.6-1-3.9-2.4-3.8-2.7c0.2-0.3,2.8,0.7,4.4,1.7c1.6,1,2.7,2,2.5,2.2
							C619,150.7,617.6,150.2,616,149.2z"/>
					</g>
					<g>
						<path class="st364" d="M616.3,150.8c-1.7-0.8-4.2-2.5-4.1-2.8c0.1-0.3,2.9,0.9,4.6,1.7c1.7,0.8,2.9,1.7,2.7,2
							C619.4,152,617.9,151.6,616.3,150.8z"/>
					</g>
					<g>
						<path class="st364" d="M616.2,151.9c-1.6-0.4-3.8-1.2-3.8-1.5c0.1-0.3,2.4,0.1,4,0.5c1.6,0.4,2.9,1,2.8,1.3
							C619.2,152.4,617.8,152.3,616.2,151.9z"/>
					</g>
					<g>
						<path class="st364" d="M615.8,146.7c-0.6-0.4-1.8-0.9-1.8-1c0.1-0.1,1.3,0.3,2,0.7c0.6,0.4,1,0.8,1,1
							C616.8,147.4,616.4,147.1,615.8,146.7z"/>
					</g>
					<g>
						<path class="st364" d="M615.2,148.1c-0.6-0.4-1.9-0.9-1.8-1c0.1-0.1,1.4,0.4,2,0.7c0.6,0.4,1,0.8,1,0.9
							C616.2,148.9,615.8,148.5,615.2,148.1z"/>
					</g>
					<g>
						<path class="st364" d="M614.5,149.3c-0.6-0.3-1.8-0.8-1.7-0.9c0.1-0.1,1.3,0.3,1.8,0.6c0.6,0.3,0.9,0.7,0.9,0.9
							C615.4,150,615.1,149.6,614.5,149.3z"/>
					</g>
					<g>
						<path class="st364" d="M614.8,151.1c-0.5-0.2-1.5-0.4-1.5-0.5c0-0.1,1.1,0,1.6,0.2c0.5,0.2,0.9,0.4,0.9,0.6
							C615.7,151.5,615.3,151.3,614.8,151.1z"/>
					</g>
					<g>
						<path class="st364" d="M619.7,147.1c-0.2-0.5-0.9-1.4-0.8-1.4c0.1-0.1,0.8,0.8,1,1.3c0.2,0.5,0.3,0.9,0.2,1
							C620,148,619.9,147.6,619.7,147.1z"/>
					</g>
					<g class="st365">
						<path class="st364" d="M616.8,149.2c0.3-0.7,0.9-1.2,1.7-0.8c0.8,0.4,1.7,1.5,1.3,2.2c-0.3,0.7-1.7,0.8-2.5,0.4
							C616.5,150.6,616.5,149.9,616.8,149.2z"/>
					</g>
				</g>
				<g>
					<path class="st364" d="M628.1,156.7c-3-3-7.7-7.6-8.7-6.7c-1.6,1.4,2.8,5.3,6.7,8.3C626.8,157.7,627.5,157.2,628.1,156.7z"/>
				</g>
				<g>
					<g>
						<path class="st366" d="M624.9,159.9c-0.4-0.1-0.2-1.7,1.3-3.2c1.5-1.4,3.5-1.7,3.6-1.3c0.2,0.4-1.3,1.2-2.5,2.4
							C625.9,159,625.3,160,624.9,159.9z"/>
					</g>
					<g>
						<path class="st367" d="M627.2,157.1c-0.1-0.1,0.2-0.4,0.6-0.7c0.4-0.3,0.9-0.4,0.9-0.3c0.1,0.1-0.3,0.4-0.7,0.7
							C627.7,157,627.3,157.2,627.2,157.1z"/>
					</g>
					<g>
						<path class="st368" d="M625.5,159.6L625.5,159.6c-0.4-0.2-0.1-1.7,1.4-3c0.9-0.8,2.1-1.2,2.8-1.2c-0.1-0.4-2.1-0.1-3.6,1.3
							c-1.5,1.4-1.6,3-1.3,3.2C625,159.9,625.2,159.8,625.5,159.6z"/>
					</g>
				</g>
				<g>
					<path class="st364" d="M624.5,154.6c-0.7-0.7-2.1-2-2-2.1c0.1-0.1,1.6,1.1,2.4,1.7c0.7,0.7,1.3,1.3,1.2,1.4
						C625.9,155.7,625.2,155.3,624.5,154.6z"/>
				</g>
			</g>
			<g>
				<path class="st364" d="M656.7,157.7c-0.1,0.6-0.4,1.1-0.9,1.2c-4.6,0.8-9.2,0.8-13.9,0c-0.5-0.1-0.8-0.6-0.9-1.2
					c0-0.3-0.1-0.5-0.1-0.8c-0.1-0.6,0.1-0.9,0.6-0.9c5.2,0.7,9.5,0.7,14.6,0c0.5-0.1,0.8,0.3,0.6,0.9
					C656.8,157.2,656.7,157.5,656.7,157.7z"/>
			</g>
			<g>
				<path class="st368" d="M656.2,156.1c-0.5,0.1-1,0.1-1.4,0.2c0,0.2-0.1,0.4-0.1,0.6c-0.1,0.6-0.4,1.1-0.9,1.2
					c-4.2,0.7-8.4,0.7-12.6,0.2c0.2,0.4,0.4,0.6,0.7,0.7c4.6,0.8,9.2,0.8,13.9,0c0.5-0.1,0.8-0.6,0.9-1.2c0-0.3,0.1-0.5,0.1-0.8
					C657,156.4,656.7,156,656.2,156.1z"/>
			</g>
			<g>
				<path class="st367" d="M649.7,157.1c0,0.2-1.8,0.7-3.9,0.6c-2.1-0.1-3.9-0.6-3.9-0.8c0-0.2,1.7,0,3.9,0
					C648,157,649.7,156.9,649.7,157.1z"/>
			</g>
			<g>
				<path class="st362" d="M648.2,94.5c-9.1,0-16.5,2.5-16.5,14.8c0,12.3,7.4,29.7,16.5,29.7c9.1,0,16.5-17.4,16.5-29.7
					C664.8,97,657.4,94.5,648.2,94.5z M647.8,136.3c-8,0-14.5-15.6-14.5-26.7s6.5-13.3,14.5-13.3c8,0,14.5,2.2,14.5,13.3
					S655.8,136.3,647.8,136.3z"/>
			</g>
			<g>
				<path class="st362" d="M649.4,99.7c0.4,1-4.5,1.4-8.5,2.8c-4,1.5-5.7,3.5-6.1,2.5c-0.4-1,0.8-4.6,4.8-6
					C643.6,97.5,649,98.7,649.4,99.7z"/>
			</g>
			<g class="st541">
				<path class="st362" d="M653.6,133c-0.5-0.3,2.3-4.1,4.2-7.9c2-3.8,2.4-6.2,2.9-5.9c0.5,0.3,0.9,3-1,6.9
					C657.7,129.8,654.1,133.2,653.6,133z"/>
			</g>
			<g>
				<g>
					<g>
						<path class="st362" d="M650.3,157.5c0,1.1-0.9,2-2,2c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2C649.4,155.5,650.3,156.4,650.3,157.5z"
							/>
					</g>
					<g>
						<path class="st362" d="M648.9,155.6c0.7,0.3,1.2,1,1.2,1.8c0,1.1-0.9,2-2,2c-0.2,0-0.4,0-0.6-0.1c0.3,0.1,0.5,0.2,0.8,0.2
							c1.1,0,2-0.9,2-2C650.3,156.6,649.7,155.9,648.9,155.6z"/>
					</g>
				</g>
				<g>
					<path class="st362" d="M646.8,157.9c0-1,0.7-1.8,1.6-2c-0.9,0-1.7,0.8-1.7,1.7c0,0.2,0,0.4,0.1,0.6
						C646.8,158.1,646.8,158,646.8,157.9z"/>
				</g>
				<g>
					<g>
						<path class="st362" d="M647.2,157.1c0.2-0.6,0.8-0.9,1.3-0.7c0.6,0.2,0.9,0.8,0.7,1.3c-0.2,0.6-0.8,0.9-1.3,0.7
							C647.4,158.3,647.1,157.7,647.2,157.1z"/>
					</g>
					<g>
						<path class="st362" d="M647.6,158.3c-0.3-0.3-0.4-0.7-0.3-1.1c0.2-0.6,0.8-0.9,1.3-0.7c0.1,0,0.2,0.1,0.3,0.1
							c-0.1-0.1-0.2-0.2-0.4-0.2c-0.6-0.2-1.2,0.1-1.3,0.7C647.1,157.6,647.3,158.1,647.6,158.3z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<path class="st362" d="M653.1,148.6c0,0.3-0.3,0.6-0.6,0.6H650c-0.3,0-0.6-0.3-0.6-0.6v-3.1c0-0.3,0.3-0.6,0.6-0.6h2.4
						c0.3,0,0.6,0.3,0.6,0.6V148.6z"/>
				</g>
				<g>
					<path class="st362" d="M652.8,145.1c0.1,0.1,0.1,0.2,0.1,0.3v3.1c0,0.3-0.3,0.6-0.6,0.6h-2.4c-0.1,0-0.2,0-0.3-0.1
						c0.1,0.2,0.3,0.3,0.5,0.3h2.4c0.3,0,0.6-0.3,0.6-0.6v-3.1C653.1,145.3,652.9,145.2,652.8,145.1z"/>
				</g>
				<g>
					<path class="st362" d="M651.8,145.3h-1.7c-0.2,0-0.4,0.2-0.4,0.4v1.8c0,0,0.1,0,0.1,0v-1.7c0-0.2,0.2-0.4,0.4-0.4L651.8,145.3
						C651.8,145.4,651.8,145.4,651.8,145.3z"/>
				</g>
				<g>
					<g>
						<path class="st378" d="M651.7,146c-0.1-0.2-0.3-0.4-0.6-0.3c-0.2,0.1-0.4,0.3-0.3,0.6c0.1,0.2,0.3,0.4,0.6,0.3
							C651.6,146.4,651.8,146.2,651.7,146z"/>
					</g>
					<g>
						<path class="st362" d="M651.5,146.5c0.1-0.1,0.2-0.3,0.1-0.5c-0.1-0.2-0.3-0.4-0.6-0.3c0,0-0.1,0-0.1,0.1c0,0,0.1-0.1,0.2-0.1
							c0.2-0.1,0.5,0.1,0.6,0.3C651.7,146.1,651.7,146.4,651.5,146.5z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st379" d="M651.7,147.1c-0.1-0.2-0.3-0.4-0.6-0.3c-0.2,0.1-0.4,0.3-0.3,0.6c0.1,0.2,0.3,0.4,0.6,0.3
							C651.6,147.6,651.8,147.3,651.7,147.1z"/>
					</g>
					<g>
						<path class="st362" d="M651.5,147.6c0.1-0.1,0.2-0.3,0.1-0.5c-0.1-0.2-0.3-0.4-0.6-0.3c0,0-0.1,0-0.1,0.1c0,0,0.1-0.1,0.2-0.1
							c0.2-0.1,0.5,0.1,0.6,0.3C651.7,147.3,651.7,147.5,651.5,147.6z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st380" d="M651.7,148.2c-0.1-0.2-0.3-0.4-0.6-0.3c-0.2,0.1-0.4,0.3-0.3,0.6c0.1,0.2,0.3,0.4,0.6,0.3
							C651.6,148.7,651.8,148.4,651.7,148.2z"/>
					</g>
					<g>
						<path class="st362" d="M651.5,148.7c0.1-0.1,0.2-0.3,0.1-0.5c-0.1-0.2-0.3-0.4-0.6-0.3c0,0-0.1,0-0.1,0.1c0,0,0.1-0.1,0.2-0.1
							c0.2-0.1,0.5,0.1,0.6,0.3C651.7,148.4,651.7,148.6,651.5,148.7z"/>
					</g>
				</g>
			</g>
			<g class="st381">
				<path class="st363" d="M641.3,146.8c0.2-3.7,1.3-3.5,2.8-3.4c1.4,0.1,0.6,0.4,0.5,4.1c-0.2,3.7,0.5,6.8-1,6.7
					C642.2,154.1,641.2,150.5,641.3,146.8z"/>
			</g>
			<g class="st381">
				<path class="st363" d="M643,161.9c-0.4-1.8,0.2-1.9,0.9-2c0.7-0.2,0.4,0.1,0.8,1.9c0.4,1.8,1.1,3.3,0.4,3.4
					C644.4,165.5,643.4,163.8,643,161.9z"/>
			</g>
			<g>
				<path class="st534" d="M642.4,111.5c-0.7,1.5-2-0.6-3.4-0.5c-1.7,0-4.7,0.6-4.9,0.4c-0.6-0.7-0.9-4.5,2.4-5.5
					c3.3-1.1,4.2-3.6,5.3-2.8c0.7,0.6-0.4,2.7-0.5,4C641.2,108.6,643.1,109.9,642.4,111.5z"/>
				<path class="st535" d="M639.4,110.1c-1.8,0.2-5.3,0.8-5.5,0.5c-0.1-0.1-0.1-0.2-0.2-0.3c0.1,0.5,0.2,0.9,0.4,1.1
					c0.2,0.2,3.2-0.4,4.9-0.4c1.4-0.1,2.7,2,3.4,0.5c0.1-0.2,0.1-0.4,0.1-0.5C642,111.6,640.6,109.9,639.4,110.1z"/>
				<path class="st536" d="M639.4,108.7c-1.9,2.6-4.8,3.4-5.3,2.8c-0.6-0.7-0.9-4.5,2.4-5.5c3.3-1.1,4.2-3.6,5.3-2.8
					C642.9,104.1,641.4,106.2,639.4,108.7z"/>
				<path class="st369" d="M639.3,108.3c-0.5,0.5-1,0.8-1.2,0.6c-0.2-0.2,0-0.7,0.5-1.2c0.5-0.5,1-0.7,1.2-0.6
					C640,107.3,639.7,107.8,639.3,108.3z"/>
				<path class="st369" d="M639.3,108.3c-1.3,1.6-3,2.3-3.6,1.7c-0.6-0.6,0-2.3,1.8-3.4c1.8-1.1,3.1-2.1,3.5-1.8
					C641.5,105.3,640.6,106.7,639.3,108.3z"/>
			</g>
			<g>
				<path class="st534" d="M654.6,111.5c0.7,1.5,2-0.6,3.4-0.5c1.7,0,4.7,0.6,4.9,0.4c0.6-0.7,0.9-4.5-2.4-5.5
					c-3.3-1.1-4.2-3.6-5.3-2.8c-0.7,0.6,0.4,2.7,0.5,4C655.8,108.6,653.9,109.9,654.6,111.5z"/>
				<path class="st535" d="M657.5,110.1c1.8,0.2,5.3,0.8,5.5,0.5c0.1-0.1,0.1-0.2,0.2-0.3c-0.1,0.5-0.2,0.9-0.4,1.1
					c-0.2,0.2-3.2-0.4-4.9-0.4c-1.4-0.1-2.7,2-3.4,0.5c-0.1-0.2-0.1-0.4-0.1-0.5C655,111.6,656.4,109.9,657.5,110.1z"/>
				<path class="st536" d="M657.6,108.7c1.9,2.6,4.8,3.4,5.3,2.8c0.6-0.7,0.9-4.5-2.4-5.5c-3.3-1.1-4.2-3.6-5.3-2.8
					C654,104.1,655.6,106.2,657.6,108.7z"/>
				<path class="st369" d="M657.7,108.3c0.5,0.5,1,0.8,1.2,0.6c0.2-0.2,0-0.7-0.5-1.2c-0.5-0.5-1-0.7-1.2-0.6
					C657,107.3,657.3,107.8,657.7,108.3z"/>
				<path class="st369" d="M657.7,108.3c1.3,1.6,3,2.3,3.6,1.7c0.6-0.6,0-2.3-1.8-3.4c-1.8-1.1-3.1-2.1-3.5-1.8
					C655.5,105.3,656.4,106.7,657.7,108.3z"/>
			</g>
			<g>
				<g>
					<path class="st369" d="M664.4,104.6l10.2-2.8c0.1,0,0.3,0,0.3,0.2c0,0.1,0,0.3-0.2,0.3l-9.8,3.9c-0.5,0.2-1,0-1.2-0.5
						C663.6,105.4,663.9,104.8,664.4,104.6C664.3,104.6,664.4,104.6,664.4,104.6z"/>
				</g>
				<g>
					<g>
						<path class="st375" d="M664.7,107.2c-0.3,0.1-0.6-0.1-0.7-0.4l-0.6-2.2c-0.1-0.3,0.1-0.6,0.4-0.7l0,0c0.3-0.1,0.6,0.1,0.7,0.4
							l0.6,2.2C665.2,106.8,665,107.1,664.7,107.2L664.7,107.2z"/>
					</g>
					<g>
						<path class="st374" d="M664.4,105.4c-0.1,0-0.2,0-0.2-0.1l-0.2-0.8c0-0.1,0-0.2,0.1-0.2l0,0c0.1,0,0.2,0,0.2,0.1l0.2,0.8
							C664.5,105.3,664.5,105.4,664.4,105.4L664.4,105.4z"/>
					</g>
					<g class="st365">
						<path class="st376" d="M665.1,106.8c-0.1,0.1-0.1,0.1-0.2,0.1c-0.3,0.1-0.6-0.1-0.7-0.4l-0.6-2.2c0-0.1,0-0.2,0-0.3
							c-0.1,0.1-0.2,0.4-0.2,0.6l0.6,2.2c0.1,0.3,0.4,0.5,0.7,0.4C664.9,107.2,665.1,107,665.1,106.8z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st376" d="M675.5,102.7c-0.3,0.4-1,0.5-1.4,0.2c-0.4-0.3-0.5-1-0.2-1.4c0.3-0.4,1-0.5,1.4-0.2
							C675.8,101.6,675.9,102.2,675.5,102.7z"/>
					</g>
					<g>
						<path class="st376" d="M674.7,101.5c-0.1,0.1-0.2,0.1-0.3,0c-0.1-0.1-0.1-0.2,0-0.3c0.1-0.1,0.2-0.1,0.3,0
							C674.8,101.3,674.8,101.5,674.7,101.5z"/>
					</g>
					<g class="st377">
						<path class="st376" d="M675.7,101.8c0,0,0,0.1-0.1,0.1c-0.3,0.4-1,0.5-1.4,0.2c-0.2-0.1-0.3-0.4-0.4-0.6
							c-0.2,0.4-0.1,1,0.3,1.3c0.4,0.3,1.1,0.2,1.4-0.2C675.7,102.4,675.8,102.1,675.7,101.8z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<path class="st369" d="M632.6,103.1l-10.5-1c-0.2,0-0.3,0.1-0.3,0.2c0,0.1,0.1,0.3,0.2,0.3l10.3,2.2c0.5,0.1,1-0.2,1.1-0.7
						c0.1-0.5-0.2-1-0.7-1.1C632.7,103.1,632.6,103.1,632.6,103.1z"/>
				</g>
				<g>
					<g>
						<path class="st375" d="M632.7,105.7c0.3,0,0.6-0.2,0.6-0.5l0.3-2.2c0-0.3-0.2-0.6-0.5-0.6l0,0c-0.3,0-0.6,0.2-0.6,0.5
							l-0.2,2.2C632.2,105.4,632.4,105.6,632.7,105.7L632.7,105.7z"/>
					</g>
					<g>
						<path class="st374" d="M632.8,103.8c0.1,0,0.2-0.1,0.2-0.2l0.1-0.8c0-0.1-0.1-0.2-0.2-0.2l0,0c-0.1,0-0.2,0.1-0.2,0.2
							l-0.1,0.8C632.6,103.7,632.7,103.8,632.8,103.8L632.8,103.8z"/>
					</g>
					<g class="st365">
						<path class="st376" d="M632.3,105.3c0.1,0.1,0.2,0.1,0.3,0.1c0.3,0,0.6-0.2,0.6-0.5l0.3-2.2c0-0.1,0-0.2,0-0.3
							c0.2,0.1,0.3,0.3,0.2,0.5l-0.3,2.2c0,0.3-0.3,0.5-0.6,0.5C632.5,105.6,632.3,105.5,632.3,105.3z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st376" d="M621.3,103c0.4,0.4,1,0.4,1.4,0c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0
							C620.9,102,620.9,102.6,621.3,103z"/>
					</g>
					<g>
						<path class="st376" d="M621.9,101.8c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0
							C621.8,101.6,621.8,101.7,621.9,101.8z"/>
					</g>
					<g class="st377">
						<path class="st376" d="M621,102.3c0,0,0.1,0.1,0.1,0.1c0.4,0.4,1,0.4,1.4,0c0.2-0.2,0.3-0.4,0.3-0.6c0.3,0.4,0.3,0.9-0.1,1.3
							c-0.4,0.4-1,0.4-1.4,0C621.1,102.8,621,102.5,621,102.3z"/>
					</g>
				</g>
			</g>
		</g>
		<g>
			<g>
				<path class="st542" d="M648.9,138.1c0,0,0.3,0.3-5.3-1c-5.6-1.3-9.4,4.5-9.4,4.5s5.9,7.1,6.7,21.5c0.8,14.4-3.5,35.9-3.5,35.9
					s1.4,0.1,5.2,1.2c3.8,1.2,6.4,0.6,6.4,0.6s-0.4-22.1-0.8-36.8C647.8,149.2,648.9,138.1,648.9,138.1z"/>
				<path class="st543" d="M648.9,138.1c0,0,0.3,0.3-5.3-1c-4.9-1.1-8.4,3.2-9.2,4.2c1.4,0.9,7.1,4.4,8.7,5.4
					c1.8,1.2,4.1,1.4,5.4,0.6C648.8,141.9,648.9,138.1,648.9,138.1z"/>
			</g>
			<g>
				<path class="st543" d="M640.8,136c-1.5,0.3-3.3,1-5.6,2.3c-2.2,1.3-4.8,2.6-4.8,2.6s3.8-1.2,7.8,1c4,2.1,6.1,3.2,7.7,4.3
					c1.6,1.1,2.5,1.2,2.5,1.2l0.5-9.2C648.9,138.1,644.8,135.3,640.8,136z"/>
				<path class="st544" d="M640.8,136c-0.8,0.1-1.6,0.4-2.6,0.8c-1.3,1.2-2.8,2.7-4,3.9c1.2,0.1,2.6,0.5,4,1.2c0,0,0.1,0,0.1,0.1
					c1.2-2.1,2.8-4.4,3.9-6C641.8,135.8,641.3,135.9,640.8,136z"/>
				<path class="st545" d="M646.3,145.3c-1.6-1.1-3.7-2.1-7.7-4.3c-2.3-1.2-4.5-1.4-6-1.3c-1.2,0.6-2.1,1.1-2.1,1.1s3.8-1.2,7.8,1
					c4,2.1,6.1,3.2,7.7,4.3c1.6,1.1,2.5,1.2,2.5,1.2l0-0.9C648.2,146.4,647.4,146.1,646.3,145.3z"/>
			</g>
			<path class="st543" d="M645.3,177.8c0,8.4-1.8,8.6-2.3,8.6c-0.5,0,0.3-0.2,0.3-8.6c0-8.4,0.4-21.8,1-21.8
				C644.8,156.1,645.3,169.5,645.3,177.8z"/>
			<g>
				<path class="st545" d="M648.9,138.1C648.9,138.1,648.9,138.1,648.9,138.1C648.9,138.1,648.9,138.1,648.9,138.1z"/>
				<path class="st545" d="M648.8,138.1c0,0.8-0.1,1.7-0.1,2.8C648.8,139.2,648.9,138.2,648.8,138.1
					C648.9,138.1,648.9,138.1,648.8,138.1z"/>
				<path class="st545" d="M648.1,156.7c0-4.4,0.1-8.3,0.3-11.3c0,0-0.1,0-0.1,0C648.2,148.6,648.1,152.5,648.1,156.7z"/>
				<path class="st545" d="M641,165.8c0-1.6,0-3.2-0.1-4.6c-0.6-11.3-4.4-18.1-5.9-20.5c-0.5,0.5-0.7,0.9-0.7,0.9s5.9,7.1,6.7,21.5
					C640.9,163.9,640.9,164.8,641,165.8z"/>
				<path class="st545" d="M642.6,198.3c-2.7-0.8-4.2-1.1-4.8-1.2c-0.2,1.2-0.4,1.9-0.4,1.9s1.4,0.1,5.2,1.2
					c3.8,1.2,6.4,0.6,6.4,0.6s0-0.7,0-1.9C648.6,198.9,646.1,199.4,642.6,198.3z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st542" d="M648.7,138.1c0,0-0.3,0.3,5.3-1c5.6-1.3,9.4,4.5,9.4,4.5s-5.9,7.1-6.7,21.5c-0.8,14.4,3.5,35.9,3.5,35.9
					s-1.4,0.1-5.2,1.2c-3.8,1.2-6.4,0.6-6.4,0.6s0.4-22.1,0.8-36.8C649.8,149.2,648.7,138.1,648.7,138.1z"/>
				<path class="st543" d="M648.7,138.1c0,0-0.3,0.3,5.3-1c4.9-1.1,8.4,3.2,9.2,4.2c-1.4,0.9-7.1,4.4-8.7,5.4
					c-1.8,1.2-4.1,1.4-5.4,0.6C648.9,141.9,648.7,138.1,648.7,138.1z"/>
			</g>
			<g>
				<path class="st543" d="M656.8,136c1.5,0.3,3.3,1,5.6,2.3c2.2,1.3,4.8,2.6,4.8,2.6s-3.8-1.2-7.8,1c-4,2.1-6.1,3.2-7.7,4.3
					c-1.6,1.1-2.5,1.2-2.5,1.2l-0.5-9.2C648.7,138.1,652.8,135.3,656.8,136z"/>
				<path class="st544" d="M656.8,136c0.8,0.1,1.6,0.4,2.5,0.8c1.3,1.2,2.8,2.7,4,3.9c-1.2,0.1-2.6,0.5-4,1.2c0,0-0.1,0-0.1,0.1
					c-1.2-2.1-2.8-4.4-3.9-6C655.9,135.8,656.3,135.9,656.8,136z"/>
				<path class="st545" d="M651.4,145.3c1.6-1.1,3.7-2.1,7.7-4.3c2.3-1.2,4.5-1.4,6-1.3c1.2,0.6,2.1,1.1,2.1,1.1s-3.8-1.2-7.8,1
					c-4,2.1-6.1,3.2-7.7,4.3c-1.6,1.1-2.5,1.2-2.5,1.2l0-0.9C649.5,146.4,650.3,146.1,651.4,145.3z"/>
			</g>
			<path class="st543" d="M652.4,177.8c0,8.4,1.8,8.6,2.3,8.6c0.5,0-0.3-0.2-0.3-8.6c0-8.4-0.4-21.8-1-21.8
				C652.8,156.1,652.4,169.5,652.4,177.8z"/>
			<g>
				<path class="st545" d="M648.7,138.1C648.7,138.1,648.7,138.1,648.7,138.1C648.7,138.1,648.7,138.1,648.7,138.1z"/>
				<path class="st545" d="M648.8,138.1c0,0.8,0.1,1.7,0.1,2.8C648.8,139.2,648.7,138.2,648.8,138.1
					C648.7,138.1,648.8,138.1,648.8,138.1z"/>
				<path class="st545" d="M649.5,156.7c0-4.4-0.1-8.3-0.3-11.3c0,0,0.1,0,0.1,0C649.5,148.6,649.6,152.5,649.5,156.7z"/>
				<path class="st545" d="M656.7,165.8c0-1.6,0-3.2,0.1-4.6c0.6-11.3,4.4-18.1,5.9-20.5c0.5,0.5,0.7,0.9,0.7,0.9s-5.9,7.1-6.7,21.5
					C656.7,163.9,656.7,164.8,656.7,165.8z"/>
				<path class="st545" d="M655.1,198.3c2.7-0.8,4.2-1.1,4.8-1.2c0.2,1.2,0.4,1.9,0.4,1.9s-1.4,0.1-5.2,1.2
					c-3.8,1.2-6.4,0.6-6.4,0.6s0-0.7,0-1.9C649,198.9,651.6,199.4,655.1,198.3z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st539" d="M633.9,127c-0.9-0.4-3.2,0.1-4.1,3.6c-0.4,1.7-0.2,1.8-0.9,3.2c-0.6,1.3-2.1,2.6-2.1,2.6s3.6-1.8,4.5-2.8
					c0.9-1,2-1,4.2,0.9c2.5,2.1,3.5,2.3,7.9,2.7c2,0.1,4.3-1.2,4.3-1.2s-3.6,0.9-7-1.9C637.2,131.3,636,127.8,633.9,127z"/>
				<path class="st546" d="M646.3,136c-0.8,0.3-1.9,0.6-2.9,0.5c-4.3-0.3-5.4-0.6-7.9-2.7c-2.3-1.9-3.3-1.9-4.2-0.9
					c-0.6,0.7-2.4,1.7-3.5,2.3c-0.5,0.6-1,1.1-1,1.1s3.6-1.8,4.5-2.8c0.9-1,2-1,4.2,0.9c2.5,2.1,3.5,2.3,7.9,2.7
					c2,0.1,4.3-1.2,4.3-1.2S647.2,136,646.3,136z"/>
			</g>
			<g>
				<path class="st539" d="M663.1,127c0.9-0.4,3.2,0.1,4.1,3.6c0.5,1.7,0.2,1.8,0.9,3.2c0.6,1.3,2.1,2.6,2.1,2.6s-3.6-1.8-4.5-2.8
					c-0.9-1-2-1-4.2,0.9c-2.5,2.1-3.5,2.3-7.9,2.7c-2,0.1-4.3-1.2-4.3-1.2s3.6,0.9,7-1.9C659.8,131.3,661,127.8,663.1,127z"/>
				<path class="st546" d="M650.7,136c0.8,0.3,1.9,0.6,2.9,0.5c4.3-0.3,5.4-0.6,7.9-2.7c2.3-1.9,3.3-1.9,4.2-0.9
					c0.6,0.7,2.4,1.7,3.5,2.3c0.5,0.6,1,1.1,1,1.1s-3.6-1.8-4.5-2.8c-0.9-1-2-1-4.2,0.9c-2.5,2.1-3.5,2.3-7.9,2.7
					c-2,0.1-4.3-1.2-4.3-1.2S649.9,136,650.7,136z"/>
			</g>
			<path class="st539" d="M637.3,132.2c0,0.3-2.2-1.9-4.2-1.9c-2,0-3.1,2.2-3.1,1.9c0-0.3,1-3,3.1-3
				C635.1,129.1,637.3,131.9,637.3,132.2z"/>
			<path class="st539" d="M659.6,132.2c0,0.3,2.2-1.9,4.2-1.9s3.1,2.2,3.1,1.9c0-0.3-1-3-3.1-3S659.6,131.9,659.6,132.2z"/>
		</g>
		<path class="st547" d="M652.9,124.9c0-0.1,0.1-0.1,0-0.2c-0.1-0.4-2.1,0.6-4.8,1.2c-0.4,0.1-0.9,0.2-1.3,0.2c-0.1,0-0.2,0-0.3,0
			c-1.9,0.3-3.3,0.4-3.3,0.7c0.1,0.3,1.6,0.8,3.6,0.6c0.8,1.1,2.3,2.4,3.5,2.2C652.3,129.3,653,125.3,652.9,124.9
			C652.9,124.9,652.9,124.9,652.9,124.9z"/>
		<path class="st548" d="M651.8,126.2c-1.5,0-2.7,1.3-3,3c0.5,0.3,1.1,0.5,1.6,0.4c1.2-0.3,1.9-2,2.2-3.3
			C652.4,126.3,652.1,126.2,651.8,126.2z"/>
		<path class="st549" d="M650.9,125.1c-0.2,0.1-0.4,0.1-0.7,0.2l0,0.2c0,0.2,0.2,0.3,0.4,0.3c0.2,0,0.3-0.2,0.3-0.4L650.9,125.1z
			 M648.8,125.7l0,0.5c0,0.2,0.2,0.3,0.4,0.3c0.2,0,0.3-0.2,0.3-0.4l-0.1-0.7C649.2,125.6,649,125.7,648.8,125.7z"/>
		<path class="st369" d="M651.7,127.1c0.1,0.3-0.1,0.6-1,0.7c-0.8,0.2-1.5,0.5-1.6,0.2c-0.1-0.3,0.5-1.3,1.3-1.4
			C651.4,126.4,651.7,126.7,651.7,127.1z"/>
		<g>
			<g>
				<path class="st550" d="M652,158.6c-0.1,0.6-0.6,1.2-1.2,1.4c-1.2,0.3-2.4,0.3-3.5,0c-0.6-0.2-1.1-0.9-1.2-1.4
					c-0.3-2.5-0.3-5.1,0-7.6c0.1-0.6,0.6-1.2,1.2-1.4c1.2-0.3,2.4-0.3,3.5,0c0.6,0.2,1.1,0.9,1.2,1.4
					C652.3,153.6,652.3,156.1,652,158.6z"/>
			</g>
			<g>
				<path class="st551" d="M651.8,158.5c-0.1,0.5-0.6,1.2-1.1,1.4c-1.1,0.3-2.2,0.3-3.3,0c-0.5-0.2-1.1-0.8-1.1-1.4
					c-0.3-2.5-0.3-4.9,0-7.3c0.1-0.5,0.6-1.2,1.1-1.4c1.1-0.3,2.2-0.3,3.3,0c0.5,0.2,1.1,0.8,1.1,1.4
					C652.1,153.6,652.1,156.1,651.8,158.5z"/>
			</g>
			<g>
				<path class="st552" d="M651.7,158.3c-0.1,0.5-0.6,1.1-1.1,1.3c-1,0.3-2.1,0.3-3.1,0c-0.5-0.2-1-0.8-1.1-1.3
					c-0.3-2.3-0.3-4.7,0-7c0.1-0.5,0.6-1.1,1.1-1.3c1-0.3,2.1-0.3,3.1,0c0.5,0.2,1,0.8,1.1,1.3C651.9,153.7,651.9,156,651.7,158.3z"
					/>
			</g>
			<g>
				<path class="st552" d="M651.7,158.3c-0.1,0.5-0.6,1.1-1.1,1.3c-1,0.3-2.1,0.3-3.1,0c-0.5-0.2-1-0.8-1.1-1.3
					c-0.3-2.3-0.3-4.7,0-7c0.1-0.5,0.6-1.1,1.1-1.3c1-0.3,2.1-0.3,3.1,0c0.5,0.2,1,0.8,1.1,1.3C651.9,153.7,651.9,156,651.7,158.3z"
					/>
			</g>
			<g>
				<path class="st553" d="M650.6,150c-1-0.3-2.1-0.3-3.1,0c-0.5,0.2-1,0.8-1.1,1.3c-0.3,2.3-0.3,4.7,0,7c0.1,0.5,0.6,1.1,1.1,1.3
					c1,0.3,2.1,0.3,3.1,0c0.5-0.2,1-0.8,1.1-1.3c0.3-2.3,0.3-4.7,0-7C651.6,150.8,651.1,150.2,650.6,150z M651.4,158.1
					c0,0.5-0.5,1-1,1.1c-1,0.2-1.9,0.2-2.9,0c-0.5-0.1-0.9-0.7-1-1.1c-0.2-2.1-0.2-4.3,0-6.4c0-0.5,0.5-1,1-1.1c1-0.2,1.9-0.2,2.9,0
					c0.5,0.1,0.9,0.7,1,1.1C651.6,153.8,651.6,155.9,651.4,158.1z"/>
			</g>
			<g>
				<path class="st554" d="M649,155.8c-1,0-2-0.3-2.8-0.6c0,1,0.1,2.1,0.2,3.1c0.1,0.5,0.6,1.1,1.1,1.3c1,0.3,2.1,0.3,3.1,0
					c0.5-0.2,1-0.8,1.1-1.3c0.1-1,0.2-2.1,0.2-3.1C651,155.6,650,155.8,649,155.8z"/>
			</g>
			<g>
				<path class="st554" d="M649.1,151.5c1,0,1.9-0.1,2.5-0.3c-0.1-0.5-0.6-1-1-1.2c-1-0.3-2.1-0.3-3.1,0c-0.4,0.1-0.9,0.6-1,1.1
					C647.1,151.3,648.1,151.5,649.1,151.5z"/>
			</g>
			<g>
				<path class="st554" d="M649.1,158.2c1,0,1.9,0.1,2.5,0.3c-0.1,0.5-0.6,1-1,1.2c-1,0.3-2.1,0.3-3.1,0c-0.4-0.1-0.9-0.6-1-1.1
					C647.1,158.4,648.1,158.2,649.1,158.2z"/>
			</g>
			<g>
				<path class="st552" d="M651.1,157.6c0,0.4-0.4,0.8-0.8,0.9c-0.8,0.1-1.7,0.1-2.5,0c-0.4-0.1-0.8-0.5-0.8-0.9
					c-0.1-1.9-0.1-3.7,0-5.6c0-0.4,0.4-0.8,0.8-0.9c0.8-0.1,1.7-0.1,2.5,0c0.4,0.1,0.8,0.5,0.8,0.9
					C651.2,153.9,651.2,155.8,651.1,157.6z"/>
			</g>
			<g>
				<path class="st552" d="M651.1,157.6c0,0.4-0.4,0.8-0.8,0.9c-0.8,0.1-1.7,0.1-2.5,0c-0.4-0.1-0.8-0.5-0.8-0.9
					c-0.1-1.9-0.1-3.7,0-5.6c0-0.4,0.4-0.8,0.8-0.9c0.8-0.1,1.7-0.1,2.5,0c0.4,0.1,0.8,0.5,0.8,0.9
					C651.2,153.9,651.2,155.8,651.1,157.6z"/>
			</g>
			<g>
				<path class="st552" d="M649,154.8c0.7,0,1.5,0.1,2.2,0.3c0-1,0-2-0.1-3.1c0-0.4-0.4-0.8-0.8-0.9c-0.8-0.1-1.7-0.1-2.5,0
					c-0.4,0.1-0.8,0.5-0.8,0.9c-0.1,1-0.1,2-0.1,3.1C647.6,154.9,648.3,154.8,649,154.8z"/>
			</g>
			<g>
				<path class="st555" d="M646.9,153.4c0,0.2,0,0.3,0,0.5c0.8-0.2,1.7-0.5,2.5-0.7c0.6-0.1,1.2-0.3,1.7-0.3c0-0.2,0-0.3,0-0.5
					c-0.6,0-1.2,0.1-1.9,0.3C648.5,152.9,647.7,153.1,646.9,153.4z"/>
			</g>
			<g>
				<path class="st556" d="M646.9,153.1c0,0.4,0,0.8,0,1.2c0.7-0.2,1.3-0.4,2-0.5c0.8-0.2,1.6-0.4,2.3-0.5c0-0.4,0-0.8-0.1-1.2
					c-0.8,0-1.6,0.2-2.5,0.4C648,152.7,647.4,152.9,646.9,153.1z"/>
			</g>
			<g>
				<path class="st557" d="M647.2,158.2c0.2,0.2,0.4,0.3,0.6,0.4c0.8,0.1,1.7,0.1,2.5,0c0.4-0.1,0.8-0.5,0.8-0.9c0-0.2,0-0.3,0-0.5
					c-0.8,0.3-1.7,0.6-2.5,0.8C648.1,158.1,647.6,158.1,647.2,158.2z"/>
			</g>
		</g>
		<g>
			<g>
				<g>
					<g>
						<g>
							<path class="st558" d="M650.7,137c0,1.2-1,2.2-2.2,2.2c-1.2,0-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2
								C649.7,134.8,650.7,135.8,650.7,137z"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st559" d="M650.4,137c0,1.1-0.9,1.9-1.9,1.9c-1.1,0-1.9-0.9-1.9-1.9c0-1.1,0.9-1.9,1.9-1.9
								C649.5,135.1,650.4,136,650.4,137z"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st560" d="M649.7,135.2c0.6,0.4,0.9,1.1,0.9,1.8c0,1.2-1,2.2-2.2,2.2c-0.4,0-0.8-0.1-1.2-0.3
								c0.4,0.3,0.8,0.4,1.3,0.4c1.2,0,2.2-1,2.2-2.2C650.7,136.2,650.3,135.5,649.7,135.2z"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st559" d="M646.6,137.1c0-1.1,0.9-1.9,1.9-1.9c0.4,0,0.7,0.1,1,0.3c-0.3-0.2-0.7-0.4-1.1-0.4
								C647.4,135.1,646.5,136,646.6,137.1c-0.1,0.6,0.3,1.2,0.8,1.6C647,138.3,646.6,137.8,646.6,137.1z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<path class="st558" d="M647.8,135.1c-0.2,0.1-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.2-0.3,0.5-0.4,0.8
							c0,0.2-0.1,0.3-0.1,0.5c0,0.2,0,0.3,0,0.5s0.1,0.3,0.1,0.5c0.1,0.2,0.1,0.3,0.2,0.4c-0.2-0.3-0.3-0.6-0.4-0.9
							c0-0.2-0.1-0.3-0.1-0.5c0-0.2,0-0.3,0.1-0.5c0.1-0.3,0.2-0.6,0.5-0.9C647.1,135.4,647.4,135.2,647.8,135.1z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path class="st561" d="M650.4,137c0,1-0.8,1.9-1.9,1.9c-1,0-1.9-0.8-1.9-1.9c0-1,0.8-1.9,1.9-1.9
							C649.5,135.1,650.4,136,650.4,137z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st562" d="M648.5,135.1c-0.8,0-1.4,0.4-1.7,1.1c0.3,0.6,1,1.1,1.7,1.1c0.8,0,1.4-0.4,1.7-1.1
							C649.9,135.6,649.2,135.1,648.5,135.1z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st563" d="M648.5,138.2c-0.9,0-1.7-0.6-1.9-1.5c0,0.1,0,0.3,0,0.4c0,1,0.8,1.9,1.9,1.9c1,0,1.9-0.8,1.9-1.9
							c0-0.1,0-0.3,0-0.4C650.2,137.5,649.4,138.2,648.5,138.2z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st564" d="M648.5,138.8c-0.6,0-1.1-0.2-1.5-0.5c0.3,0.4,0.9,0.7,1.5,0.7c0.6,0,1.1-0.3,1.5-0.7
							C649.6,138.6,649.1,138.8,648.5,138.8z"/>
					</g>
				</g>
				<g>
					<g>
						<path class="st562" d="M649.4,135.8c0,0.1-0.4,0-0.9,0c-0.5,0-0.9,0.1-0.9,0c0-0.1,0.4-0.4,0.9-0.4
							C649,135.4,649.4,135.7,649.4,135.8z"/>
					</g>
				</g>
			</g>
		</g>
	</g>
	<g>
		<polygon class="st565" points="23.4,441.8 18.3,437.3 23.3,432.7 33.5,432.7 38.7,437.2 33.6,441.8 		"/>
		<polygon class="st566" points="38.7,437.2 33.6,441.8 23.4,441.8 18.3,437.3 15,443.5 21.7,449.5 35.2,449.5 41.9,443.4 		"/>
		<polygon class="st567" points="21.7,449.5 15,443.5 28.5,465.1 		"/>
		<polygon class="st568" points="35.2,449.5 28.5,465.1 41.9,443.4 		"/>
		<polygon class="st566" points="38.7,437.2 33.6,441.8 35.2,449.5 41.9,443.4 		"/>
		<polygon class="st569" points="33.6,441.8 23.4,441.8 21.7,449.5 35.2,449.5 		"/>
		<path class="st565" d="M31.8,441.2c-2.3,0.1-2.3-0.2-4.7-0.6c-3.5-0.5-4.6-2.6-4.6-4.8c0-1.1,0.6-2.2,1.3-3.1l-0.4,0l-5.1,4.6
			l5.1,4.5l10.2,0l0.1-0.1C33.1,441.4,32.4,441.2,31.8,441.2z"/>
		<path class="st565" d="M33.6,441.8l-4.4,0c-0.8,2.5-1.6,5.1-2.4,7.7l8.5,0l6.7-6l-3.3-6.2L33.6,441.8z"/>
		<polygon class="st570" points="39.2,445.9 41.9,443.4 38.7,437.2 36.2,439.4 		"/>
		<polygon class="st570" points="37.4,438.3 36.2,439.4 39.2,445.9 40.9,444.4 		"/>
		<polygon class="st569" points="15.5,443.9 18.8,446.9 21.7,440.3 19.8,438.7 		"/>
		<path class="st569" d="M21.7,449.5"/>
		<polygon class="st571" points="28.5,465.1 21.7,449.5 35.2,449.5 		"/>
		<path class="st568" d="M28.5,465.1l3.6-8.4c-0.9-0.4-1.7-0.8-2.5-1.1c-1.4-0.5-3.3-0.5-5.3-0.2L28.5,465.1z"/>
		<path class="st572" d="M28.5,465.1l-4.2-9.7c0,0-0.5,0.3-2-0.1L28.5,465.1z"/>
		<path class="st568" d="M28.5,465.1l6.1-9.9c-1.4,1-2.5,1.5-2.5,1.5L28.5,465.1z"/>
		<path class="st573" d="M26.3,460l2.2,5.1l2.2-5.1c-0.6,0-1.1,0-1.7,0C28.1,459.9,27.2,460,26.3,460z"/>
		<polygon class="st568" points="33.1,454.4 34.3,451.5 23.4,453.2 24.4,455.5 		"/>
		<polygon class="st574" points="34.3,451.5 33.7,453 33.1,454.4 34.7,455.1 36.1,452.9 		"/>
		<polygon class="st568" points="21.2,453.4 22.3,455.2 24.3,455.4 23.4,453.2 		"/>
		<g>
			<path class="st575" d="M18.3,437.3l5.1,4.5l0,0l10.2,0l0,0l5.1-4.5l0,0c-0.4,0.4-0.8,0.8-1.2,1.2c-0.4,0.4-0.8,0.8-1.2,1.2
				l-2.5,2.4l-0.1,0.1l-0.1,0l-5.1,0.1l-5.1,0l-0.1,0l-0.1-0.1l-2.5-2.4c-0.4-0.4-0.8-0.8-1.2-1.2C19,438.1,18.6,437.7,18.3,437.3
				L18.3,437.3z"/>
		</g>
		<path class="st575" d="M23.4,441.8c0,0.7-0.1,1.3-0.2,2c-0.1,0.6-0.3,1.3-0.4,1.9c-0.3,1.3-0.6,2.5-1,3.8l0,0
			c0.2-1.3,0.4-2.6,0.7-3.9c0.1-0.6,0.2-1.3,0.4-1.9C22.9,443.1,23.1,442.4,23.4,441.8L23.4,441.8z"/>
		<path class="st575" d="M33.6,441.8c0.3,0.6,0.4,1.2,0.6,1.9c0.2,0.6,0.3,1.3,0.4,1.9c0.2,1.3,0.5,2.6,0.7,3.9l0,0
			c-0.3-1.3-0.7-2.5-1-3.8c-0.2-0.6-0.3-1.3-0.5-1.9C33.7,443.1,33.6,442.5,33.6,441.8L33.6,441.8z"/>
		<path class="st575" d="M15,443.5l1.7,1.5c0.6,0.5,1.2,1,1.7,1.5l3.4,3l-0.1,0l6.7-0.1l6.7,0l-0.1,0l3.4-3c0.6-0.5,1.1-1,1.7-1.5
			l1.7-1.5l0,0l-6.7,6l0,0l0,0l-13.5,0l0,0l0,0L15,443.5L15,443.5z"/>
		<g>
			<defs>
				<filter id="Adobe_OpacityMaskFilter_138_" filterUnits="userSpaceOnUse" x="18.3" y="436.8" width="10.7" height="10.2">
					<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
					<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
				</filter>
			</defs>
			<mask maskUnits="userSpaceOnUse" x="18.3" y="436.8" width="10.7" height="10.2" id="SVGID_222_">
				<g class="st576">
					<defs>
						<filter id="Adobe_OpacityMaskFilter_139_" filterUnits="userSpaceOnUse" x="18.3" y="436.8" width="10.7" height="10.2">
							<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
							<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
						</filter>
					</defs>
					<mask maskUnits="userSpaceOnUse" x="18.3" y="436.8" width="10.7" height="10.2" id="SVGID_222_">
						<g class="st577">
						</g>
					</mask>

						<radialGradient id="SVGID_223_" cx="185.452" cy="761.1287" r="5.2434" gradientTransform="matrix(1 -2.000000e-03 2.000000e-03 1 -163.3253 -318.8573)" gradientUnits="userSpaceOnUse">
						<stop  offset="0.2" style={{stopColor:"#FFFFFF"}}/>
						<stop  offset="0.2623" style={{stopColor:"#E5E5E5"}}/>
						<stop  offset="0.4646" style={{stopColor:"#959595"}}/>
						<stop  offset="0.6444" style={{stopColor:"#555555"}}/>
						<stop  offset="0.7949" style={{stopColor:"#272727"}}/>
						<stop  offset="0.9103" style={{stopColor:"#0B0B0B"}}/>
						<stop  offset="0.9769" style={{stopColor:"#000000"}}/>
					</radialGradient>
					<polygon class="st578" points="23.6,436.8 24,442 29,440.7 24.2,442.6 27,447 23.7,443 20.3,447 23.1,442.6 18.3,440.7
						23.3,442 					"/>
				</g>
			</mask>

				<radialGradient id="SVGID_224_" cx="185.452" cy="761.1287" r="5.2434" gradientTransform="matrix(1 -2.000000e-03 2.000000e-03 1 -163.3253 -318.8573)" gradientUnits="userSpaceOnUse">
				<stop  offset="0.2" style={{stopColor:"#FFF384"}}/>
				<stop  offset="0.44" style={{stopColor:"#D7BF59"}}/>
				<stop  offset="0.6819" style={{stopColor:"#B49232"}}/>
				<stop  offset="0.8689" style={{stopColor:"#9E761B"}}/>
				<stop  offset="0.9769" style={{stopColor:"#966B12"}}/>
			</radialGradient>
			<polygon class="st579" points="23.6,436.8 24,442 29,440.7 24.2,442.6 27,447 23.7,443 20.3,447 23.1,442.6 18.3,440.7 23.3,442
							"/>
			<defs>
				<filter id="Adobe_OpacityMaskFilter_140_" filterUnits="userSpaceOnUse" x="18.7" y="437.9" width="9.4" height="9.4">
					<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
					<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
				</filter>
			</defs>
			<mask maskUnits="userSpaceOnUse" x="18.7" y="437.9" width="9.4" height="9.4" id="SVGID_225_">
				<g class="st580">
					<defs>
						<filter id="Adobe_OpacityMaskFilter_141_" filterUnits="userSpaceOnUse" x="18.7" y="437.9" width="9.4" height="9.4">
							<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
							<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
						</filter>
					</defs>
					<mask maskUnits="userSpaceOnUse" x="18.7" y="437.9" width="9.4" height="9.4" id="SVGID_225_">
						<g class="st581">
						</g>
					</mask>

						<radialGradient id="SVGID_226_" cx="185.2181" cy="761.841" r="4.7234" gradientTransform="matrix(1 -2.000000e-03 2.000000e-03 1 -163.3253 -318.8573)" gradientUnits="userSpaceOnUse">
						<stop  offset="0.2545" style={{stopColor:"#FFFFFF"}}/>
						<stop  offset="0.3249" style={{stopColor:"#DFDFDF"}}/>
						<stop  offset="0.5084" style={{stopColor:"#919191"}}/>
						<stop  offset="0.6719" style={{stopColor:"#535353"}}/>
						<stop  offset="0.8093" style={{stopColor:"#262626"}}/>
						<stop  offset="0.9152" style={{stopColor:"#0A0A0A"}}/>
						<stop  offset="0.9769" style={{stopColor:"#000000"}}/>
					</radialGradient>
					<path class="st582" d="M28.1,442.6c0,2.6-2.1,4.7-4.7,4.7c-2.6,0-4.7-2.1-4.7-4.7c0-2.6,2.1-4.7,4.7-4.7
						C26,437.9,28.1,440,28.1,442.6z"/>
				</g>
			</mask>

				<radialGradient id="SVGID_227_" cx="185.2181" cy="761.841" r="4.7234" gradientTransform="matrix(1 -2.000000e-03 2.000000e-03 1 -163.3253 -318.8573)" gradientUnits="userSpaceOnUse">
				<stop  offset="0.2545" style={{stopColor:"#FFF384"}}/>
				<stop  offset="0.4845" style={{stopColor:"#D6BD57"}}/>
				<stop  offset="0.7062" style={{stopColor:"#B39132"}}/>
				<stop  offset="0.8772" style={{stopColor:"#9E751B"}}/>
				<stop  offset="0.9769" style={{stopColor:"#966B12"}}/>
			</radialGradient>
			<path class="st583" d="M28.1,442.6c0,2.6-2.1,4.7-4.7,4.7c-2.6,0-4.7-2.1-4.7-4.7c0-2.6,2.1-4.7,4.7-4.7
				C26,437.9,28.1,440,28.1,442.6z"/>
		</g>
	</g>
	<g>
		<polygon class="st565" points="344.5,306.9 339.4,302.4 344.5,297.9 354.7,297.8 359.8,302.4 354.7,306.9 		"/>
		<polygon class="st566" points="359.8,302.4 354.7,306.9 344.5,306.9 339.4,302.4 336.2,308.6 342.9,314.6 356.4,314.6
			363.1,308.6 		"/>
		<polygon class="st567" points="342.9,314.6 336.2,308.6 349.7,330.2 		"/>
		<polygon class="st568" points="356.4,314.6 349.7,330.2 363.1,308.6 		"/>
		<polygon class="st566" points="359.8,302.4 354.7,306.9 356.4,314.6 363.1,308.6 		"/>
		<polygon class="st569" points="354.7,306.9 344.5,306.9 342.9,314.6 356.4,314.6 		"/>
		<path class="st565" d="M352.9,306.3c-2.3,0.1-2.3-0.2-4.7-0.6c-3.5-0.5-4.6-2.6-4.6-4.8c0-1.1,0.6-2.2,1.3-3.1l-0.4,0l-5.1,4.6
			l5.1,4.5l10.2,0l0.1-0.1C354.3,306.5,353.6,306.3,352.9,306.3z"/>
		<path class="st565" d="M354.7,306.9l-4.4,0c-0.8,2.5-1.6,5.1-2.4,7.7l8.5,0l6.7-6l-3.3-6.2L354.7,306.9z"/>
		<polygon class="st570" points="360.4,311 363.1,308.6 359.8,302.4 357.4,304.5 		"/>
		<polygon class="st570" points="358.6,303.5 357.4,304.5 360.4,311 362,309.5 		"/>
		<polygon class="st569" points="336.7,309.1 340,312 342.8,305.4 341,303.8 		"/>
		<path class="st569" d="M342.9,314.6"/>
		<polygon class="st571" points="349.7,330.2 342.9,314.6 356.4,314.6 		"/>
		<path class="st568" d="M349.7,330.2l3.6-8.4c-0.9-0.4-1.7-0.8-2.5-1.1c-1.4-0.5-3.3-0.5-5.3-0.2L349.7,330.2z"/>
		<path class="st572" d="M349.7,330.2l-4.2-9.7c0,0-0.5,0.3-2-0.1L349.7,330.2z"/>
		<path class="st568" d="M349.7,330.2l6.1-9.9c-1.4,1-2.5,1.4-2.5,1.4L349.7,330.2z"/>
		<path class="st573" d="M347.5,325.1l2.2,5.1l2.2-5.1c-0.6,0-1.1,0-1.7,0C349.3,325.1,348.4,325.1,347.5,325.1z"/>
		<polygon class="st568" points="354.3,319.6 355.5,316.6 344.5,318.4 345.5,320.6 		"/>
		<polygon class="st574" points="355.5,316.6 354.9,318.1 354.3,319.6 355.9,320.2 357.2,318.1 		"/>
		<polygon class="st568" points="342.4,318.5 343.5,320.3 345.5,320.5 344.5,318.4 		"/>
		<g>
			<path class="st575" d="M339.4,302.4l5.1,4.5l0,0l10.2,0l0,0l5.1-4.5l0,0c-0.4,0.4-0.8,0.8-1.2,1.2c-0.4,0.4-0.8,0.8-1.2,1.2
				l-2.5,2.4l-0.1,0.1l-0.1,0l-5.1,0.1l-5.1,0l-0.1,0l-0.1-0.1l-2.5-2.4c-0.4-0.4-0.8-0.8-1.2-1.2
				C340.2,303.2,339.8,302.8,339.4,302.4L339.4,302.4z"/>
		</g>
		<path class="st575" d="M344.6,306.9c0,0.7-0.1,1.3-0.2,2c-0.1,0.6-0.3,1.3-0.4,1.9c-0.3,1.3-0.6,2.5-1,3.8l0,0
			c0.2-1.3,0.4-2.6,0.7-3.9c0.1-0.6,0.2-1.3,0.4-1.9C344.1,308.2,344.3,307.6,344.6,306.9L344.6,306.9z"/>
		<path class="st575" d="M354.8,306.9c0.3,0.6,0.4,1.2,0.6,1.9c0.2,0.6,0.3,1.3,0.4,1.9c0.2,1.3,0.5,2.6,0.7,3.9l0,0
			c-0.3-1.3-0.7-2.5-1-3.8c-0.2-0.6-0.3-1.3-0.5-1.9C354.8,308.2,354.7,307.6,354.8,306.9L354.8,306.9z"/>
		<path class="st575" d="M336.2,308.6l1.7,1.5c0.6,0.5,1.2,1,1.7,1.5l3.4,3l-0.1,0l6.7-0.1l6.7,0l-0.1,0l3.4-3
			c0.6-0.5,1.1-1,1.7-1.5l1.7-1.5l0,0l-6.7,6l0,0l0,0l-13.5,0l0,0l0,0L336.2,308.6L336.2,308.6z"/>
		<g>
			<defs>
				<filter id="Adobe_OpacityMaskFilter_142_" filterUnits="userSpaceOnUse" x="339.5" y="301.9" width="10.7" height="10.2">
					<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
					<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
				</filter>
			</defs>
			<mask maskUnits="userSpaceOnUse" x="339.5" y="301.9" width="10.7" height="10.2" id="SVGID_228_">
				<g class="st584">
					<defs>
						<filter id="Adobe_OpacityMaskFilter_143_" filterUnits="userSpaceOnUse" x="339.5" y="301.9" width="10.7" height="10.2">
							<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
							<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
						</filter>
					</defs>
					<mask maskUnits="userSpaceOnUse" x="339.5" y="301.9" width="10.7" height="10.2" id="SVGID_228_">
						<g class="st585">
						</g>
					</mask>

						<radialGradient id="SVGID_229_" cx="506.9001" cy="626.9044" r="5.2436" gradientTransform="matrix(1 -2.000000e-03 2.000000e-03 1 -163.3253 -318.8573)" gradientUnits="userSpaceOnUse">
						<stop  offset="0.2" style={{stopColor:"#FFFFFF"}}/>
						<stop  offset="0.2623" style={{stopColor:"#E5E5E5"}}/>
						<stop  offset="0.4646" style={{stopColor:"#959595"}}/>
						<stop  offset="0.6444" style={{stopColor:"#555555"}}/>
						<stop  offset="0.7949" style={{stopColor:"#272727"}}/>
						<stop  offset="0.9103" style={{stopColor:"#0B0B0B"}}/>
						<stop  offset="0.9769" style={{stopColor:"#000000"}}/>
					</radialGradient>
					<polygon class="st586" points="344.8,301.9 345.1,307.1 350.2,305.8 345.4,307.7 348.2,312.1 344.8,308.1 341.5,312.2
						344.3,307.7 339.5,305.8 344.5,307.1 					"/>
				</g>
			</mask>

				<radialGradient id="SVGID_230_" cx="506.9001" cy="626.9044" r="5.2436" gradientTransform="matrix(1 -2.000000e-03 2.000000e-03 1 -163.3253 -318.8573)" gradientUnits="userSpaceOnUse">
				<stop  offset="0.2" style={{stopColor:"#FFF384"}}/>
				<stop  offset="0.44" style={{stopColor:"#D7BF59"}}/>
				<stop  offset="0.6819" style={{stopColor:"#B49232"}}/>
				<stop  offset="0.8689" style={{stopColor:"#9E761B"}}/>
				<stop  offset="0.9769" style={{stopColor:"#966B12"}}/>
			</radialGradient>
			<polygon class="st587" points="344.8,301.9 345.1,307.1 350.2,305.8 345.4,307.7 348.2,312.1 344.8,308.1 341.5,312.2
				344.3,307.7 339.5,305.8 344.5,307.1 			"/>
			<defs>
				<filter id="Adobe_OpacityMaskFilter_144_" filterUnits="userSpaceOnUse" x="339.9" y="303" width="9.4" height="9.4">
					<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
					<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
				</filter>
			</defs>
			<mask maskUnits="userSpaceOnUse" x="339.9" y="303" width="9.4" height="9.4" id="SVGID_231_">
				<g class="st588">
					<defs>
						<filter id="Adobe_OpacityMaskFilter_145_" filterUnits="userSpaceOnUse" x="339.9" y="303" width="9.4" height="9.4">
							<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
							<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
						</filter>
					</defs>
					<mask maskUnits="userSpaceOnUse" x="339.9" y="303" width="9.4" height="9.4" id="SVGID_231_">
						<g class="st589">
						</g>
					</mask>

						<radialGradient id="SVGID_232_" cx="506.6663" cy="627.6168" r="4.7232" gradientTransform="matrix(1 -2.000000e-03 2.000000e-03 1 -163.3253 -318.8573)" gradientUnits="userSpaceOnUse">
						<stop  offset="0.2545" style={{stopColor:"#FFFFFF"}}/>
						<stop  offset="0.3249" style={{stopColor:"#DFDFDF"}}/>
						<stop  offset="0.5084" style={{stopColor:"#919191"}}/>
						<stop  offset="0.6719" style={{stopColor:"#535353"}}/>
						<stop  offset="0.8093" style={{stopColor:"#262626"}}/>
						<stop  offset="0.9152" style={{stopColor:"#0A0A0A"}}/>
						<stop  offset="0.9769" style={{stopColor:"#000000"}}/>
					</radialGradient>
					<path class="st590" d="M349.3,307.7c0,2.6-2.1,4.7-4.7,4.7c-2.6,0-4.7-2.1-4.7-4.7c0-2.6,2.1-4.7,4.7-4.7
						C347.2,303,349.3,305.1,349.3,307.7z"/>
				</g>
			</mask>

				<radialGradient id="SVGID_233_" cx="506.6663" cy="627.6168" r="4.7232" gradientTransform="matrix(1 -2.000000e-03 2.000000e-03 1 -163.3253 -318.8573)" gradientUnits="userSpaceOnUse">
				<stop  offset="0.2545" style={{stopColor:"#FFF384"}}/>
				<stop  offset="0.4845" style={{stopColor:"#D6BD57"}}/>
				<stop  offset="0.7062" style={{stopColor:"#B39132"}}/>
				<stop  offset="0.8772" style={{stopColor:"#9E751B"}}/>
				<stop  offset="0.9769" style={{stopColor:"#966B12"}}/>
			</radialGradient>
			<path class="st591" d="M349.3,307.7c0,2.6-2.1,4.7-4.7,4.7c-2.6,0-4.7-2.1-4.7-4.7c0-2.6,2.1-4.7,4.7-4.7
				C347.2,303,349.3,305.1,349.3,307.7z"/>
		</g>
	</g>
	<g>
		<polygon class="st565" points="481.6,78.1 476.5,73.5 481.6,69 491.8,69 496.9,73.5 491.8,78 		"/>
		<polygon class="st566" points="496.9,73.5 491.8,78 481.6,78.1 476.5,73.5 473.2,79.7 480,85.7 493.5,85.7 500.2,79.7 		"/>
		<polygon class="st567" points="480,85.7 473.2,79.7 486.8,101.3 		"/>
		<polygon class="st568" points="493.5,85.7 486.8,101.3 500.2,79.7 		"/>
		<polygon class="st566" points="496.9,73.5 491.8,78 493.5,85.7 500.2,79.7 		"/>
		<polygon class="st569" points="491.8,78 481.6,78.1 480,85.7 493.5,85.7 		"/>
		<path class="st565" d="M490,77.4c-2.3,0.1-2.3-0.2-4.7-0.6c-3.5-0.5-4.6-2.6-4.6-4.8c0-1.1,0.6-2.2,1.3-3.1l-0.4,0l-5.1,4.6
			l5.1,4.5l10.2,0l0.1-0.1C491.3,77.6,490.6,77.4,490,77.4z"/>
		<path class="st565" d="M491.8,78l-4.4,0c-0.8,2.5-1.6,5.1-2.4,7.7l8.5,0l6.7-6l-3.3-6.2L491.8,78z"/>
		<polygon class="st570" points="497.4,82.1 500.2,79.7 496.9,73.5 494.5,75.6 		"/>
		<polygon class="st570" points="495.6,74.6 494.5,75.6 497.4,82.1 499.1,80.6 		"/>
		<polygon class="st569" points="473.7,80.2 477.1,83.1 479.9,76.5 478.1,74.9 		"/>
		<path class="st569" d="M480,85.7"/>
		<polygon class="st571" points="486.8,101.3 480,85.7 493.5,85.7 		"/>
		<path class="st568" d="M486.8,101.3l3.6-8.4c-0.9-0.4-1.7-0.8-2.5-1.1c-1.4-0.5-3.3-0.5-5.3-0.2L486.8,101.3z"/>
		<path class="st572" d="M486.8,101.3l-4.2-9.7c0,0-0.5,0.3-2-0.1L486.8,101.3z"/>
		<path class="st568" d="M486.8,101.3l6.1-9.9c-1.4,1-2.5,1.4-2.5,1.4L486.8,101.3z"/>
		<path class="st573" d="M484.5,96.3l2.2,5.1l2.2-5.1c-0.6,0-1.1,0-1.7,0C486.3,96.2,485.4,96.2,484.5,96.3z"/>
		<polygon class="st568" points="491.3,90.7 492.6,87.7 481.6,89.5 482.6,91.8 		"/>
		<polygon class="st574" points="492.6,87.7 491.9,89.3 491.3,90.7 493,91.3 494.3,89.2 		"/>
		<polygon class="st568" points="479.4,89.6 480.6,91.4 482.5,91.6 481.6,89.5 		"/>
		<g>
			<path class="st575" d="M476.5,73.5l5.1,4.5l0,0l10.2,0l0,0l5.1-4.5l0,0c-0.4,0.4-0.8,0.8-1.2,1.2c-0.4,0.4-0.8,0.8-1.2,1.2
				l-2.5,2.4l-0.1,0.1l-0.1,0l-5.1,0.1l-5.1,0l-0.1,0l-0.1-0.1l-2.5-2.4c-0.4-0.4-0.8-0.8-1.2-1.2C477.3,74.4,476.9,74,476.5,73.5
				L476.5,73.5z"/>
		</g>
		<path class="st575" d="M481.6,78.1c0,0.7-0.1,1.3-0.2,2c-0.1,0.6-0.3,1.3-0.4,1.9c-0.3,1.3-0.6,2.5-1,3.8l0,0
			c0.2-1.3,0.4-2.6,0.7-3.9c0.1-0.6,0.2-1.3,0.4-1.9C481.1,79.3,481.3,78.7,481.6,78.1L481.6,78.1z"/>
		<path class="st575" d="M491.8,78c0.3,0.6,0.4,1.2,0.6,1.9c0.2,0.6,0.3,1.3,0.4,1.9c0.2,1.3,0.5,2.6,0.7,3.9l0,0
			c-0.3-1.3-0.7-2.5-1-3.8c-0.2-0.6-0.3-1.3-0.5-1.9C491.9,79.4,491.8,78.7,491.8,78L491.8,78z"/>
		<path class="st575" d="M473.2,79.7l1.7,1.5c0.6,0.5,1.2,1,1.7,1.5l3.4,3l-0.1,0l6.7-0.1l6.7,0l-0.1,0l3.4-3c0.6-0.5,1.1-1,1.7-1.5
			l1.7-1.5l0,0l-6.7,6l0,0l0,0l-13.5,0l0,0l0,0L473.2,79.7L473.2,79.7z"/>
		<g>
			<defs>
				<filter id="Adobe_OpacityMaskFilter_146_" filterUnits="userSpaceOnUse" x="476.5" y="73" width="10.7" height="10.2">
					<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
					<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
				</filter>
			</defs>
			<mask maskUnits="userSpaceOnUse" x="476.5" y="73" width="10.7" height="10.2" id="SVGID_234_">
				<g class="st592">
					<defs>
						<filter id="Adobe_OpacityMaskFilter_147_" filterUnits="userSpaceOnUse" x="476.5" y="73" width="10.7" height="10.2">
							<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
							<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
						</filter>
					</defs>
					<mask maskUnits="userSpaceOnUse" x="476.5" y="73" width="10.7" height="10.2" id="SVGID_234_">
						<g class="st593">
						</g>
					</mask>

						<radialGradient id="SVGID_235_" cx="644.4091" cy="398.2936" r="5.2434" gradientTransform="matrix(1 -2.000000e-03 2.000000e-03 1 -163.3253 -318.8573)" gradientUnits="userSpaceOnUse">
						<stop  offset="0.2" style={{stopColor:"#FFFFFF"}}/>
						<stop  offset="0.2623" style={{stopColor:"#E5E5E5"}}/>
						<stop  offset="0.4646" style={{stopColor:"#959595"}}/>
						<stop  offset="0.6444" style={{stopColor:"#555555"}}/>
						<stop  offset="0.7949" style={{stopColor:"#272727"}}/>
						<stop  offset="0.9103" style={{stopColor:"#0B0B0B"}}/>
						<stop  offset="0.9769" style={{stopColor:"#000000"}}/>
					</radialGradient>
					<polygon class="st594" points="481.9,73 482.2,78.2 487.2,76.9 482.4,78.9 485.2,83.3 481.9,79.2 478.6,83.3 481.4,78.9
						476.5,77 481.6,78.2 					"/>
				</g>
			</mask>

				<radialGradient id="SVGID_236_" cx="644.4091" cy="398.2936" r="5.2434" gradientTransform="matrix(1 -2.000000e-03 2.000000e-03 1 -163.3253 -318.8573)" gradientUnits="userSpaceOnUse">
				<stop  offset="0.2" style={{stopColor:"#FFF384"}}/>
				<stop  offset="0.44" style={{stopColor:"#D7BF59"}}/>
				<stop  offset="0.6819" style={{stopColor:"#B49232"}}/>
				<stop  offset="0.8689" style={{stopColor:"#9E761B"}}/>
				<stop  offset="0.9769" style={{stopColor:"#966B12"}}/>
			</radialGradient>
			<polygon class="st595" points="481.9,73 482.2,78.2 487.2,76.9 482.4,78.9 485.2,83.3 481.9,79.2 478.6,83.3 481.4,78.9
				476.5,77 481.6,78.2 			"/>
			<defs>
				<filter id="Adobe_OpacityMaskFilter_148_" filterUnits="userSpaceOnUse" x="476.9" y="74.1" width="9.4" height="9.4">
					<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
					<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
				</filter>
			</defs>
			<mask maskUnits="userSpaceOnUse" x="476.9" y="74.1" width="9.4" height="9.4" id="SVGID_237_">
				<g class="st596">
					<defs>
						<filter id="Adobe_OpacityMaskFilter_149_" filterUnits="userSpaceOnUse" x="476.9" y="74.1" width="9.4" height="9.4">
							<feFlood  style={{floodColor:"white",floodOpacity:1}} result="back"/>
							<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
						</filter>
					</defs>
					<mask maskUnits="userSpaceOnUse" x="476.9" y="74.1" width="9.4" height="9.4" id="SVGID_237_">
						<g class="st597">
						</g>
					</mask>

						<radialGradient id="SVGID_238_" cx="644.1747" cy="399.006" r="4.7232" gradientTransform="matrix(1 -2.000000e-03 2.000000e-03 1 -163.3253 -318.8573)" gradientUnits="userSpaceOnUse">
						<stop  offset="0.2545" style={{stopColor:"#FFFFFF"}}/>
						<stop  offset="0.3249" style={{stopColor:"#DFDFDF"}}/>
						<stop  offset="0.5084" style={{stopColor:"#919191"}}/>
						<stop  offset="0.6719" style={{stopColor:"#535353"}}/>
						<stop  offset="0.8093" style={{stopColor:"#262626"}}/>
						<stop  offset="0.9152" style={{stopColor:"#0A0A0A"}}/>
						<stop  offset="0.9769" style={{stopColor:"#000000"}}/>
					</radialGradient>
					<path class="st598" d="M486.4,78.9c0,2.6-2.1,4.7-4.7,4.7c-2.6,0-4.7-2.1-4.7-4.7c0-2.6,2.1-4.7,4.7-4.7
						C484.2,74.1,486.4,76.2,486.4,78.9z"/>
				</g>
			</mask>

				<radialGradient id="SVGID_239_" cx="644.1747" cy="399.006" r="4.7232" gradientTransform="matrix(1 -2.000000e-03 2.000000e-03 1 -163.3253 -318.8573)" gradientUnits="userSpaceOnUse">
				<stop  offset="0.2545" style={{stopColor:"#FFF384"}}/>
				<stop  offset="0.4845" style={{stopColor:"#D6BD57"}}/>
				<stop  offset="0.7062" style={{stopColor:"#B39132"}}/>
				<stop  offset="0.8772" style={{stopColor:"#9E751B"}}/>
				<stop  offset="0.9769" style={{stopColor:"#966B12"}}/>
			</radialGradient>
			<path class="st599" d="M486.4,78.9c0,2.6-2.1,4.7-4.7,4.7c-2.6,0-4.7-2.1-4.7-4.7c0-2.6,2.1-4.7,4.7-4.7
				C484.2,74.1,486.4,76.2,486.4,78.9z"/>
		</g>
	</g>
	<g>
		<path class="st600" d="M118.4,33c-6.3-1.9-16.5-1.4-16.5-1.4l0,0c0,0-4.7-5-10.4-11c-5.7-6-13.2-4.7-16.5,2.9
			c-3.4,7.6-6.1,13.8-6.1,13.8v0l0,0c-1,0.3-10,3.2-15,6.9c-5.3,3.9-6.4,11.4-2.4,16.6c3.7,4.9,11.5,9,12.6,9.6l0.1,0.1l0,0.1
			c-0.1,1.2-1,10.3,0.9,16.1c2.1,6.3,8.8,9.6,15.1,7.4c6.1-2.1,13.8-8.5,14.1-8.8c0.6,0.2,9.9,3.6,16.3,3.6c6.6,0,11.8-5.5,11.7-12
			c-0.2-6.6-4.6-15.6-4.6-15.6s6.7-7.1,8.7-13.4C128.3,41.6,124.7,34.9,118.4,33z"/>
		<g>
			<path class="st601" d="M118.4,37c-0.1,0.9-3.7,1.1-7.9,0.6c-4.3-0.5-7.6-1.7-7.5-2.5s3.7-1.1,7.9-0.6
				C115.1,35,118.5,36.1,118.4,37z"/>
			<path class="st601" d="M122.1,42.3c-0.4,0.3-1.1-0.8-1.9-2.1c-0.8-1.3-1.4-2.4-0.9-2.6c0.4-0.3,1.7,0.4,2.5,1.7
				C122.5,40.6,122.5,42.1,122.1,42.3z"/>
			<path class="st601" d="M62.7,57.3c0.2,0.9-0.7,1.8-1.9,2c-1.2,0.2-2.3-0.4-2.4-1.3c-0.2-0.9,0.7-1.8,1.8-2
				C61.5,55.9,62.6,56.5,62.7,57.3z"/>
			<path class="st601" d="M65.5,65.8c0.2,1.3-0.2,2.4-0.9,2.6c-0.7,0.1-1.5-0.8-1.7-2.1c-0.2-1.3,0.2-2.4,0.9-2.6
				C64.5,63.6,65.3,64.5,65.5,65.8z"/>
			<path class="st601" d="M60.5,53.1c0.1,0.6-0.4,1.1-1.1,1.3c-0.7,0.1-1.4-0.2-1.5-0.8c-0.1-0.6,0.4-1.1,1.1-1.3
				C59.8,52.2,60.4,52.5,60.5,53.1z"/>
			<path class="st601" d="M66.4,55.5c0.1,0.6-0.3,1.3-0.9,1.4c-0.6,0.1-1.2-0.3-1.3-1c-0.1-0.6,0.3-1.3,0.9-1.4
				C65.7,54.4,66.2,54.8,66.4,55.5z"/>
			<path class="st601" d="M71.9,59.7c0.1,0.8-0.2,1.5-0.7,1.6c-0.5,0.1-1.1-0.5-1.2-1.3c-0.1-0.8,0.2-1.5,0.7-1.6
				C71.2,58.3,71.8,58.9,71.9,59.7z"/>
			<path class="st601" d="M73.5,64.6c0.1,0.8-0.1,1.5-0.6,1.6c-0.5,0.1-1-0.5-1.1-1.3c-0.1-0.8,0.1-1.5,0.6-1.6
				C72.8,63.2,73.3,63.8,73.5,64.6z"/>
			<path class="st601" d="M60.4,61.2c0.1,0.7-0.7,1.5-1.8,1.7c-1.1,0.2-2.1-0.3-2.2-1c-0.1-0.7,0.7-1.5,1.8-1.7
				C59.3,60.1,60.3,60.5,60.4,61.2z"/>
			<path class="st601" d="M75.9,51.5c0.2,1.1-0.6,2.1-1.8,2.3c-1.2,0.2-2.3-0.5-2.4-1.6c-0.2-1.1,0.6-2.1,1.8-2.3
				C74.6,49.7,75.7,50.4,75.9,51.5z"/>
			<path class="st601" d="M75,72.1c0.2,1.4-0.5,2.7-1.7,2.9c-1.2,0.2-2.4-0.7-2.6-2.1c-0.2-1.4,0.5-2.7,1.7-2.9
				C73.5,69.8,74.7,70.7,75,72.1z"/>
			<path class="st601" d="M76.6,85.6c0.2,1.2-0.6,2.4-1.8,2.6c-1.2,0.2-2.3-0.6-2.5-1.8c-0.2-1.2,0.6-2.4,1.8-2.6
				C75.3,83.6,76.4,84.4,76.6,85.6z"/>
			<path class="st601" d="M85.8,84.5c0.1,0.6-1,1.3-2.4,1.5c-1.4,0.3-2.7,0-2.8-0.6c-0.1-0.6,1-1.3,2.4-1.5
				C84.5,83.7,85.7,83.9,85.8,84.5z"/>
			<path class="st601" d="M90.5,83c0.1,0.8-0.3,1.6-1.1,1.7c-0.7,0.1-1.5-0.4-1.6-1.2c-0.1-0.8,0.3-1.6,1.1-1.7
				C89.6,81.7,90.3,82.2,90.5,83z"/>
			<path class="st601" d="M104.2,72.9c0.1,0.7-0.5,1.5-1.3,1.6c-0.9,0.2-1.7-0.3-1.8-1.1c-0.1-0.7,0.5-1.5,1.3-1.6
				C103.3,71.6,104.1,72.1,104.2,72.9z"/>
			<path class="st601" d="M109.2,67c0.2,1.2-0.5,2.2-1.5,2.4c-1,0.2-2-0.6-2.2-1.8c-0.2-1.2,0.5-2.2,1.5-2.4
				C108,65.1,109,65.9,109.2,67z"/>
			<path class="st601" d="M115.1,69.1c1.2,1.5,1.7,3.1,1,3.7c-0.7,0.5-2.2-0.2-3.4-1.7c-1.2-1.5-1.7-3.1-1-3.7
				C112.4,66.9,113.9,67.6,115.1,69.1z"/>
			<path class="st601" d="M111.4,79.3c0.2,1.2-0.4,2.3-1.4,2.5c-1,0.2-1.9-0.7-2.1-1.9c-0.2-1.2,0.4-2.3,1.4-2.5
				C110.3,77.3,111.2,78.1,111.4,79.3z"/>
			<path class="st601" d="M117.8,79c0.1,0.8-0.1,1.5-0.5,1.5c-0.4,0.1-0.8-0.5-0.9-1.3c-0.1-0.8,0.1-1.5,0.5-1.5
				C117.2,77.6,117.6,78.2,117.8,79z"/>
			<path class="st601" d="M117.3,42.4c0.1,0.4-0.4,0.9-1.2,1c-0.7,0.1-1.3-0.1-1.4-0.5c-0.1-0.4,0.4-0.9,1.2-1
				C116.6,41.7,117.2,41.9,117.3,42.4z"/>
			<path class="st601" d="M122.3,46.8c0.1,0.7-0.1,1.3-0.4,1.4s-0.7-0.5-0.9-1.2c-0.1-0.7,0.1-1.3,0.4-1.4
				C121.8,45.6,122.1,46.1,122.3,46.8z"/>
			<path class="st601" d="M114.8,51.6c-0.7,1.7-1.8,2.8-2.4,2.6c-0.6-0.3-0.6-1.8,0.1-3.5c0.7-1.7,1.8-2.8,2.4-2.6
				C115.6,48.3,115.6,49.9,114.8,51.6z"/>
			<path class="st601" d="M123.2,48.8c0.4,0.6,0,1.7-1.1,2.5c-1,0.8-2.2,1-2.7,0.4c-0.4-0.6,0-1.7,1.1-2.5
				C121.5,48.4,122.7,48.2,123.2,48.8z"/>
		</g>
		<path class="st602" d="M125.7,39.2c-1.4-2.9-3.9-5.2-7.3-6.2c-6.3-1.9-16.5-1.4-16.5-1.4l0,0c0,0-4.7-5-10.4-11
			c-5.7-6-13.2-4.7-16.5,2.9c-3.4,7.6-6.1,13.8-6.1,13.8v0l0,0c-1,0.3-10,3.2-15,6.9c-2.9,2.1-4.5,5.3-4.8,8.6
			c9.2-4.5,22.4-10.6,37.6-13.2C101.5,37,115.6,38.2,125.7,39.2z"/>
		<path class="st603" d="M111.8,56.9c-2.1-7.7-10.3-10.1-17-12.1c-3.9-1.2,0.5-9.8,4.3-16.2c-0.6-0.6-1.2-1.3-1.9-2
			c-5.4,5-10.6,11.6-9.8,18.2c1,8,11.9,7,15.7,12.7c2.9,4.1-6.2,16.5-7.4,20.3c-0.7,2.4-1.4,5.1-2,8c0.3-0.3,0.5-0.4,0.6-0.5
			c0.3,0.1,2.9,1.1,6.2,1.9c1.1-3.1,2.3-6.1,3.5-8.8C106.7,71.9,113.9,64.7,111.8,56.9z"/>
		<path class="st600" d="M63.9,70.4C64,70.4,64,70.5,63.9,70.4l0.1,0.1l0,0.1c-0.1,1.2-1,10.3,0.9,16.1c2.1,6.3,8.8,9.6,15.1,7.4
			c6.1-2.1,13.8-8.5,14.1-8.8c0.6,0.2,9.9,3.6,16.3,3.6c6.6,0,11.8-5.5,11.7-12c-0.1-4-1.8-8.9-3.1-12.2c-11.6-2.6-23.7-5.7-30.4-6
			C81.7,58.4,72,64.3,63.9,70.4z"/>
		<path class="st604" d="M80.5,21.7c0.8,0.4-0.8,2.9-2.8,6.7c-2,3.8-3.8,7.4-4.5,7c-0.8-0.4-0.3-4.7,1.8-8.5
			C76.9,23.1,79.7,21.3,80.5,21.7z"/>
		<path class="st605" d="M66.3,39.7c0.2,0.4-2.9,1.8-6.7,3.9c-3.7,2.1-6.6,4.3-6.8,3.8c-0.2-0.4,2.2-3.2,5.9-5.4
			C62.5,40,66.1,39.3,66.3,39.7z"/>
		<path class="st604" d="M86.3,19.6c0.1,0.5-1.1,0.7-2.6,1c-1.5,0.3-2.7,0.4-2.8-0.1c-0.1-0.5,1-1.5,2.5-1.7
			C84.9,18.6,86.2,19.1,86.3,19.6z"/>
		<path class="st605" d="M51.7,49.8c0.7,0,0.8,1.7,0.9,3.8c0.1,2.1,0.1,3.8-0.6,3.8c-0.7,0-1.8-1.7-1.9-3.8
			C50,51.6,51,49.8,51.7,49.8z"/>
		<path class="st606" d="M93.2,83.8C82.6,85.7,72,82,64.8,86.2c0.1,0.2,0.1,0.4,0.2,0.6c2.1,6.3,8.8,9.6,15.1,7.4
			c6.1-2.1,13.8-8.5,14.1-8.8c0.6,0.2,9.9,3.6,16.3,3.6c6.6,0,11.8-5.5,11.7-12c0,0,0-0.1,0-0.1C113.7,74.9,104.4,81.9,93.2,83.8z"
			/>
		<path class="st607" d="M122.8,54.7c-4.2,3.3-8.9,5.9-8.9,5.9s3.9,7.8,4,13.6c0.1,5.7-4.4,10.4-10.2,10.5c-5.6,0-13.7-2.9-14.2-3.1
			c-0.3,0.3-7,5.8-12.3,7.7c-5.4,1.9-11.3-1-13.1-6.4c-1.7-5.1-0.9-13-0.8-14l0-0.1l-0.1-0.1c-0.5-0.3-5.3-1.1-10.1-2.4
			c3.1,2.3,6.2,3.9,6.8,4.3l0.1,0.1l0,0.1c-0.1,1.2-1,10.3,0.9,16.1c2.1,6.3,8.8,9.6,15.1,7.4c6.1-2.1,13.8-8.5,14.1-8.8
			c0.6,0.2,9.9,3.6,16.3,3.6c6.6,0,11.8-5.5,11.7-12c-0.2-6.6-4.6-15.6-4.6-15.6S120.3,58.4,122.8,54.7z"/>
		<path class="st601" d="M110.5,88.9c6.6,0,11.8-5.5,11.7-12c-0.2-6.6-4.6-15.6-4.6-15.6s6.7-7.1,8.7-13.4c2-6.3-1.5-13-7.8-14.8
			c-6.3-1.9-16.5-1.4-16.5-1.4l0,0c0,0-4.7-5-10.4-11l-0.1,0.1l2.3,3c0.8,1,1.6,2,2.3,3c0.8,1,1.6,2,2.4,3l2.4,2.9l0,0l0.2,0.2l0,0
			l0.3,0.3l0.7,0c4.2,0.2,8.4,0.6,12.4,1.4c1,0.2,2,0.4,2.9,0.7c0.5,0.1,0.9,0.3,1.2,0.5c0.4,0.2,0.8,0.4,1.1,0.6
			c1.4,0.9,2.5,2.2,3.3,3.6c0.7,1.4,1.1,3.1,1,4.7c-0.1,0.8-0.2,1.6-0.5,2.3c-0.3,0.8-0.7,1.6-1.1,2.5c-0.9,1.7-2,3.4-3.2,5
			c-0.6,0.8-1.2,1.6-1.9,2.4c-0.3,0.4-0.7,0.8-1,1.2c-0.3,0.4-0.7,0.8-1,1.1l-1.5,1.6l1,2.1l0.6,1.3c0.2,0.5,0.4,0.9,0.6,1.4
			c0.4,0.9,0.8,1.9,1.1,2.9c0.7,1.9,1.3,3.8,1.7,5.7c0.2,0.9,0.3,1.9,0.4,2.7c0,0.8,0,1.6-0.2,2.4c-0.3,1.6-1.1,3-2.2,4.2
			c-1.1,1.2-2.6,2.1-4.2,2.5c-0.4,0.1-0.8,0.2-1.2,0.3l-0.3,0l-0.3,0l-0.4,0l-0.4,0c-0.9,0-1.9,0-2.9-0.1c-4-0.4-8.2-1.3-12.3-2.5
			l-1-0.3l-0.6,0.6c-1,0.9-2,1.9-3,2.8c-1,0.9-2.1,1.8-3.2,2.6c-1.1,0.9-2.2,1.7-3.3,2.4c-1.1,0.8-2.3,1.5-3.6,2.1l0.1,0.2
			c6.1-2.1,13.8-8.5,14.1-8.8C94.8,85.6,104.1,88.9,110.5,88.9z"/>
		<path class="st603" d="M53.3,62.9c0.6,0.6,1.3,1.2,2,1.8c10.4-0.1,21.8,2.1,22.5,14.5c0.3,4.8,0.6,10.1,1.5,15.2
			c0.3-0.1,0.5-0.2,0.8-0.2c1.3-0.5,2.7-1.1,4-1.9c-0.3-4.7-0.3-9.5-0.8-14C82.4,62.3,66.2,59.3,53.3,62.9z"/>
		<g class="st608">
			<path class="st609" d="M63.8,66.1l0-3.3c1.2,0.1,2.3,0,3.3-0.1c0.9-0.2,1.7-0.4,2.2-0.5l-0.2-1l-1.8,0.2c-1.6,0.1-2.7,0-3.4-0.5
				c-0.7-0.5-1.2-1.3-1.4-2.5c-0.3-1.6-0.2-2.8,0.4-3.6c0.6-0.7,1.7-1.3,3.4-1.6c1.7-0.3,3.3-0.4,4.7-0.4l0.1,3.2
				c-1.2,0-2.2,0.1-2.9,0.2c-0.7,0.1-1.3,0.3-1.8,0.4l0.2,0.9l1.4-0.1c1.7-0.1,2.9,0,3.7,0.6c0.8,0.5,1.2,1.3,1.4,2.5
				c0.1,0.8,0.2,1.5,0.1,2.1c-0.1,0.6-0.3,1.1-0.5,1.4c-0.2,0.3-0.6,0.6-1.2,0.9c-0.5,0.3-1,0.4-1.4,0.5c-0.4,0.1-1,0.2-1.6,0.4
				C66.8,66.1,65.2,66.2,63.8,66.1z"/>
			<path class="st609" d="M82.2,53.7l-2.8,0.5l1.7,8.9l-3.8,0.7l-1.7-8.9l-2.8,0.5l-0.6-3.1l9.4-1.7L82.2,53.7z"/>
			<path class="st609" d="M87.1,62l-4,0.8l0.9-12.6l5.9-1.1l5.3,11.4l-4,0.8l-0.8-1.8L87.2,60L87.1,62z M87.5,53.4l-0.2,3.5l1.8-0.3
				l-1.4-3.2L87.5,53.4z"/>
			<path class="st609" d="M106.4,58.4l-4.2,0.8L99.9,56l-0.8,0.2l0.7,3.6l-3.8,0.7l-2.2-12l6-1.1c2.7-0.5,4.4,0.6,4.9,3.4
				c0.4,1.9,0,3.3-1.1,4.1L106.4,58.4z M98.1,50.8l0.4,2.4l0.9-0.2c0.5-0.1,0.8-0.2,1-0.3c0.2-0.1,0.3-0.4,0.2-0.7l-0.1-0.8
				c-0.1-0.3-0.2-0.5-0.4-0.6c-0.2-0.1-0.6,0-1,0L98.1,50.8z"/>
			<path class="st609" d="M114.6,47.7l-2.8,0.5l1.7,8.9l-3.8,0.7l-1.7-8.9l-2.8,0.5l-0.6-3.1l9.4-1.7L114.6,47.7z"/>
		</g>
	</g>
	<g>
		<path class="st610" d="M386.7,495.2c-6.4-4.6-18.2-8.1-18.2-8.1l0,0c0,0-3.4-7.5-7.5-16.5c-4.1-9.1-13.1-10.5-19.9-3.2
			c-6.8,7.3-12.3,13.3-12.3,13.3l0,0l0,0c-1.3,0-12.6-0.2-19.7,2c-7.5,2.3-11.7,10.4-9.2,17.9c2.3,7,9.5,14.7,10.5,15.8l0,0.1l0,0.1
			c-0.6,1.3-5.2,11.2-5.3,18.6c-0.1,7.9,6.3,14.3,14.2,14.3c7.7,0,19-4.3,19.5-4.5c0.6,0.5,9.8,7.9,17.1,10.4
			c7.5,2.5,15.6-1.5,18-9.1c2.4-7.5,0.9-19.5,0.9-19.5s10.4-5.4,15.1-11.8C394.5,508.7,393.1,499.8,386.7,495.2z"/>
		<g>
			<path class="st611" d="M385.1,499.6c-0.5,0.9-4.6-0.2-9.2-2.5c-4.6-2.3-8-4.9-7.5-5.8c0.5-0.9,4.6,0.2,9.2,2.5
				C382.2,496.1,385.6,498.7,385.1,499.6z"/>
			<path class="st612" d="M387.2,507.2c-0.6,0.1-0.9-1.3-1.3-3.1c-0.4-1.8-0.6-3.2,0-3.4c0.6-0.1,1.8,1.1,2.1,2.9
				C388.3,505.4,387.7,507,387.2,507.2z"/>
			<path class="st612" d="M314,501c-0.2,1.1-1.5,1.8-2.9,1.5c-1.4-0.2-2.4-1.3-2.2-2.4c0.2-1.1,1.5-1.8,2.9-1.5
				C313.2,498.8,314.2,499.9,314,501z"/>
			<path class="st612" d="M313.8,511.7c-0.3,1.5-1.2,2.7-2.1,2.5c-0.9-0.1-1.4-1.5-1.2-3.1c0.3-1.5,1.2-2.7,2.1-2.5
				C313.6,508.8,314.1,510.1,313.8,511.7z"/>
			<path class="st612" d="M313.2,495.3c-0.1,0.7-0.9,1.1-1.7,1c-0.9-0.1-1.4-0.8-1.3-1.5c0.1-0.7,0.9-1.2,1.7-1
				C312.7,493.9,313.3,494.6,313.2,495.3z"/>
			<path class="st612" d="M318.8,500.3c-0.1,0.8-0.8,1.3-1.6,1.2c-0.7-0.1-1.2-0.8-1.1-1.6c0.1-0.8,0.8-1.3,1.6-1.2
				C318.5,498.8,319,499.5,318.8,500.3z"/>
			<path class="st612" d="M323.5,507.3c-0.2,1-0.8,1.7-1.5,1.6c-0.7-0.1-1.1-1-0.9-1.9c0.2-1,0.8-1.7,1.5-1.6
				C323.2,505.4,323.6,506.3,323.5,507.3z"/>
			<path class="st612" d="M323.3,513.4c-0.2,1-0.8,1.7-1.3,1.6c-0.6-0.1-0.9-1-0.8-1.9c0.2-1,0.8-1.7,1.3-1.6
				C323.1,511.6,323.5,512.5,323.3,513.4z"/>
			<path class="st612" d="M309.8,504.5c-0.1,0.9-1.3,1.4-2.6,1.2c-1.3-0.2-2.3-1.1-2.1-2c0.1-0.9,1.3-1.4,2.6-1.2
				C309,502.7,310,503.6,309.8,504.5z"/>
			<path class="st612" d="M331.2,499.5c-0.2,1.3-1.5,2.2-2.9,2c-1.4-0.2-2.3-1.5-2.1-2.8c0.2-1.3,1.5-2.2,2.9-2
				C330.4,497,331.4,498.2,331.2,499.5z"/>
			<path class="st612" d="M322.1,522.5c-0.3,1.7-1.6,2.8-3.1,2.6c-1.4-0.2-2.4-1.8-2.1-3.4c0.3-1.7,1.6-2.8,3.1-2.6
				C321.4,519.3,322.3,520.8,322.1,522.5z"/>
			<path class="st612" d="M318.7,538.5c-0.2,1.5-1.6,2.5-3,2.2c-1.4-0.2-2.4-1.6-2.2-3.1c0.2-1.5,1.6-2.5,3-2.2
				C318,535.6,318.9,537,318.7,538.5z"/>
			<path class="st612" d="M329.5,540.8c-0.1,0.7-1.6,1-3.4,0.8c-1.7-0.3-3.1-1.1-3-1.8c0.1-0.7,1.6-1,3.4-0.8
				C328.3,539.3,329.6,540.1,329.5,540.8z"/>
			<path class="st612" d="M335.4,541c-0.2,1-1,1.6-1.9,1.5c-0.9-0.1-1.5-1-1.3-2c0.2-1,1-1.6,1.9-1.5C335,539.1,335.6,540,335.4,541
				z"/>
			<path class="st612" d="M355,534.8c-0.1,0.9-1.1,1.5-2.2,1.3c-1-0.2-1.8-1-1.6-1.9c0.1-0.9,1.1-1.5,2.2-1.3
				C354.4,533,355.1,533.9,355,534.8z"/>
			<path class="st612" d="M362.9,530.1c-0.2,1.4-1.4,2.4-2.7,2.2c-1.2-0.2-2.1-1.5-1.8-2.9c0.2-1.4,1.4-2.4,2.6-2.2
				C362.3,527.4,363.1,528.7,362.9,530.1z"/>
			<path class="st612" d="M368.8,534.8c0.8,2.2,0.7,4.2-0.3,4.6c-1,0.4-2.4-1.1-3.2-3.3c-0.8-2.2-0.7-4.2,0.3-4.6
				C366.6,531.2,368,532.6,368.8,534.8z"/>
			<path class="st612" d="M360.7,544.9c-0.2,1.4-1.4,2.4-2.5,2.3c-1.2-0.2-1.9-1.5-1.7-2.9c0.2-1.4,1.4-2.4,2.5-2.3
				C360.1,542.2,360.9,543.5,360.7,544.9z"/>
			<path class="st612" d="M367.9,547c-0.2,0.9-0.6,1.6-1.1,1.5c-0.5-0.1-0.7-0.9-0.6-1.8c0.2-0.9,0.6-1.6,1.1-1.5
				C367.8,545.3,368.1,546.1,367.9,547z"/>
			<path class="st612" d="M381.7,505.3c-0.1,0.5-0.8,0.8-1.7,0.7c-0.9-0.1-1.5-0.7-1.4-1.2c0.1-0.5,0.8-0.8,1.7-0.7
				C381.2,504.3,381.8,504.8,381.7,505.3z"/>
			<path class="st612" d="M385.6,512.4c-0.1,0.8-0.6,1.5-1,1.4c-0.4-0.1-0.7-0.8-0.5-1.7c0.1-0.9,0.6-1.5,1-1.4
				C385.5,510.8,385.8,511.5,385.6,512.4z"/>
			<path class="st612" d="M375.4,514.8c-1.5,1.6-3.1,2.5-3.8,2c-0.6-0.5,0.1-2.3,1.5-4c1.5-1.6,3.1-2.5,3.8-2
				C377.5,511.4,376.8,513.2,375.4,514.8z"/>
			<path class="st612" d="M385.9,514.9c0.3,0.8-0.7,1.9-2.2,2.4c-1.5,0.5-2.9,0.3-3.2-0.5c-0.3-0.8,0.7-1.9,2.2-2.4
				C384.2,513.9,385.6,514.1,385.9,514.9z"/>
		</g>
		<path class="st613" d="M392.4,505.1c-0.4-3.8-2.4-7.5-5.8-9.9c-6.4-4.6-18.2-8.1-18.2-8.1l0,0c0,0-3.4-7.5-7.5-16.5
			c-4.1-9.1-13.1-10.5-19.9-3.2c-6.8,7.3-12.3,13.3-12.3,13.3l0,0l0,0c-1.3,0-12.6-0.2-19.7,2c-4.1,1.3-7.2,4.2-8.7,7.8
			c12.2-1.5,29.5-3.2,47.9-0.3C365.9,493.1,381.4,499.9,392.4,505.1z"/>
		<path class="st614" d="M369.8,519.7c0.7-9.5-7.8-15.5-14.6-20.4c-4-2.9,4.4-10.9,11.2-16.6c-0.4-0.9-0.9-1.9-1.4-3.1
			c-8.1,3.6-16.6,9-18.3,16.8c-2,9.5,10.7,12.5,12.8,20.6c1.7,5.8-13.5,16.3-16.3,20.2c-1.7,2.4-3.6,5.2-5.4,8.3
			c0.5-0.2,0.8-0.3,0.9-0.3c0.3,0.2,2.9,2.3,6.3,4.6c2.5-3.1,5-6,7.4-8.6C358.2,534.7,369.1,529.3,369.8,519.7z"/>
		<path class="st615" d="M310.2,516.3C310.3,516.3,310.3,516.3,310.2,516.3l0.1,0.2l0,0.1c-0.6,1.3-5.2,11.2-5.3,18.6
			c-0.1,7.9,6.3,14.3,14.2,14.3c7.7,0,19-4.3,19.5-4.5c0.6,0.5,9.8,7.9,17.1,10.4c7.5,2.5,15.6-1.5,18-9.1c1.5-4.6,1.5-10.8,1.2-15
			c-12.2-7.5-24.6-15.7-32.1-18.7C335.2,509.6,321.8,512.5,310.2,516.3z"/>
		<path class="st616" d="M348.1,467.5c0.7,0.8-2,3-5.8,6.5c-3.8,3.5-7.2,6.9-7.9,6.2c-0.7-0.8,1.5-5.4,5.3-8.9
			C343.5,467.7,347.4,466.7,348.1,467.5z"/>
		<path class="st617" d="M325,482.4c0.1,0.6-4,0.9-9.1,1.9c-5.1,1-9.1,2.2-9.2,1.7c-0.1-0.6,3.8-2.8,8.8-3.8
			C320.5,481.2,324.9,481.8,325,482.4z"/>
		<path class="st616" d="M355.5,467.4c-0.1,0.6-1.6,0.4-3.4,0.1c-1.8-0.3-3.2-0.6-3.1-1.2c0.1-0.6,1.7-1.3,3.5-1
			C354.3,465.7,355.6,466.8,355.5,467.4z"/>
		<path class="st617" d="M304.4,488.1c0.8,0.2,0.3,2.2-0.5,4.6c-0.7,2.4-1.4,4.3-2.2,4.1c-0.8-0.2-1.4-2.6-0.6-5
			C301.8,489.4,303.6,487.8,304.4,488.1z"/>
		<path class="st618" d="M338.2,542.9c-12.7-2.1-23.3-10.4-33.1-8.5c0,0.3,0,0.5,0,0.8c-0.1,7.9,6.3,14.3,14.2,14.3
			c7.7,0,19-4.3,19.5-4.5c0.6,0.5,9.8,7.9,17.1,10.4c7.5,2.5,15.6-1.5,18-9.1c0,0,0-0.1,0-0.1C364.9,540.9,351.6,545.1,338.2,542.9z
			"/>
		<path class="st619" d="M383.1,521.4c-6,2.1-12.4,3.2-12.4,3.2s1.3,10.4-0.7,17c-2.1,6.6-9.1,10.1-15.6,7.9
			c-6.3-2.1-14.4-8.6-14.9-9.1c-0.5,0.2-10.3,3.9-17,3.9c-6.9,0-12.4-5.6-12.3-12.4c0.1-6.4,4.1-15.1,4.6-16.2l0-0.1l0-0.1
			c-0.5-0.5-5.6-3.3-10.5-6.7c2.6,3.8,5.4,6.9,6.1,7.5l0,0.1l0,0.1c-0.6,1.3-5.2,11.2-5.3,18.6c-0.1,7.9,6.3,14.3,14.2,14.3
			c7.7,0,19-4.3,19.5-4.5c0.6,0.5,9.8,7.9,17.1,10.4c7.5,2.5,15.6-1.5,18-9.1c2.4-7.5,0.9-19.5,0.9-19.5S378.8,524.6,383.1,521.4z"
			/>
		<path class="st620" d="M355.8,555.4c7.5,2.5,15.6-1.5,18-9.1c2.4-7.5,0.9-19.5,0.9-19.5s10.4-5.4,15.1-11.8
			c4.7-6.3,3.3-15.3-3.1-19.9c-6.4-4.6-18.2-8.1-18.2-8.1l0,0c0,0-3.4-7.5-7.5-16.5l-0.2,0.1l1.4,4.3c0.5,1.4,1,2.9,1.5,4.3
			c0.5,1.4,1,2.9,1.5,4.3l1.6,4.3l0,0l0.2,0.3v0l0.2,0.4l0.7,0.3c4.6,1.9,9.3,3.9,13.5,6.4c1,0.6,2,1.3,3,1.9c0.5,0.4,0.9,0.7,1.2,1
			c0.4,0.3,0.7,0.7,1,1.1c1.3,1.6,2,3.5,2.3,5.4c0.3,1.9,0,3.9-0.7,5.7c-0.4,0.9-0.9,1.7-1.5,2.4c-0.6,0.8-1.4,1.6-2.2,2.4
			c-1.6,1.6-3.6,3-5.5,4.4c-1,0.7-2,1.3-3.1,2c-0.5,0.3-1,0.6-1.6,0.9c-0.5,0.3-1.1,0.6-1.5,0.8l-2.3,1.2l0.3,2.8l0.2,1.8
			c0.1,0.6,0.1,1.2,0.2,1.8c0.1,1.2,0.1,2.5,0.2,3.7c0,2.4,0,4.9-0.3,7.2c-0.2,1.1-0.4,2.2-0.7,3.2c-0.3,1-0.6,1.8-1.1,2.7
			c-1,1.6-2.4,3-4.1,3.9c-1.7,0.9-3.7,1.3-5.7,1.3c-0.5,0-1-0.1-1.5-0.2l-0.4-0.1l-0.3-0.1l-0.4-0.1l-0.4-0.1
			c-1.1-0.3-2.2-0.8-3.3-1.3c-4.4-2.1-8.8-4.7-13-7.6l-1.1-0.8l-0.9,0.4c-1.5,0.7-3,1.3-4.5,1.9c-1.5,0.6-3.1,1.2-4.6,1.7
			c-1.6,0.5-3.1,1-4.7,1.5c-1.6,0.4-3.2,0.8-4.9,1l0,0.2c7.7,0,19-4.3,19.5-4.5C339.3,545.5,348.6,553,355.8,555.4z"/>
		<path class="st614" d="M301.1,503.6c0.5,1,1,1.9,1.6,2.8c11.9,3.9,23.9,10.9,19.9,25.2c-1.6,5.5-3.3,11.7-4.2,17.9
			c0.3,0,0.6,0,1,0c1.6,0,3.5-0.2,5.3-0.5c1.5-5.4,3.4-10.8,4.5-16.2C334.4,514.2,317.1,504.5,301.1,503.6z"/>
		<g class="st621">
			<g>
				<path class="st622" d="M324.5,505l-3.3-0.6l-0.8,4.4l-3.8-0.7l2.2-12l7.9,1.5l-1.1,3l-3.5-0.7l-0.3,1.6l3.3,0.6L324.5,505z"/>
				<path class="st622" d="M325.6,509.8l2.2-12l3.8,0.7l-2.2,12L325.6,509.8z"/>
				<path class="st622" d="M338.4,512.2l-2.1-4.8c-0.1-0.2-0.1-0.5,0-1l-0.1,0l-1,5.2l-3.8-0.7l2.2-12l3.6,0.7l2.1,4.8
					c0.1,0.2,0.1,0.5,0,1l0.1,0l1-5.2l3.8,0.7l-2.2,12L338.4,512.2z"/>
				<path class="st622" d="M343.9,513.2l2.2-12l3.8,0.7l-2.2,12L343.9,513.2z"/>
				<path class="st622" d="M349.2,514l1.1-3.1c1.1,0.5,2.1,0.9,3.1,1c0.9,0.2,1.7,0.3,2.3,0.3l0.2-1l-1.7-0.5c-1.5-0.4-2.5-1-3-1.7
					c-0.5-0.7-0.6-1.7-0.4-2.9c0.3-1.6,0.9-2.7,1.7-3.2c0.8-0.5,2.1-0.6,3.8-0.2c1.7,0.3,3.2,0.8,4.5,1.3l-1.1,3
					c-1.2-0.4-2.1-0.7-2.8-0.8c-0.7-0.1-1.3-0.2-1.8-0.2L355,507l1.4,0.4c1.6,0.5,2.7,1.1,3.2,1.8c0.5,0.8,0.7,1.7,0.5,2.8
					c-0.2,0.8-0.4,1.5-0.7,2c-0.3,0.5-0.7,0.9-1,1.1s-0.8,0.4-1.4,0.4c-0.6,0.1-1.1,0.1-1.5,0c-0.4,0-1-0.1-1.7-0.3
					C352.1,515,350.6,514.6,349.2,514z"/>
				<path class="st622" d="M367.4,517.6l0.8-4.2l-2.9-0.5l-0.8,4.2l-3.8-0.7l2.2-12l3.8,0.7l-0.8,4.2l2.9,0.5l0.8-4.2l3.8,0.7
					l-2.2,12L367.4,517.6z"/>
			</g>
		</g>
	</g>
	<g id="1">
		<g>
			<text transform="matrix(1 0 0 1 129.6963 55.9849)" class="st623 st624">1</text>
		</g>
	</g>
	<g id="10">
		<g>
			<text transform="matrix(1 0 0 1 71.6963 239.3174)" class="st623 st624">10</text>
		</g>
	</g>
	<g id="30">
		<g>
			<text transform="matrix(1 0 0 1 468.0293 49.1636)" class="st623 st624">30</text>
		</g>
	</g>
	<g id="20">
		<g>
			<text transform="matrix(1 0 0 1 195.5244 327.6406)" class="st623 st624">20</text>
		</g>
	</g>
	<g id="21">
		<g>
			<text transform="matrix(1 0 0 1 217.3628 291.1636)" class="st623 st624">21</text>
		</g>
	</g>
	<g id="22">
		<g>
			<text transform="matrix(1 0 0 1 236.0293 256.1636)" class="st623 st624">22</text>
		</g>
	</g>
	<g id="23">
		<g>
			<text transform="matrix(1 0 0 1 255.6958 221.1636)" class="st623 st624">23</text>
		</g>
	</g>
	<g id="24">
		<g>
			<text transform="matrix(1 0 0 1 276.6958 189.1636)" class="st623 st624">24</text>
		</g>
	</g>
	<g id="25">
		<g>
			<text transform="matrix(1 0 0 1 303.6958 159.1636)" class="st623 st624">25</text>
		</g>
	</g>
	<g id="26">
		<g>
			<text transform="matrix(1 0 0 1 332.3623 127.1636)" class="st623 st624">26</text>
		</g>
	</g>
	<g id="27">
		<g>
			<text transform="matrix(1 0 0 1 363.0293 100.1636)" class="st623 st624">27</text>
		</g>
	</g>
	<g id="28">
		<g>
			<text transform="matrix(1 0 0 1 395.3628 78.1636)" class="st623 st624">28</text>
		</g>
	</g>
	<g id="29">
		<g>
			<text transform="matrix(1 0 0 1 430.0293 59.1636)" class="st623 st624">29</text>
		</g>
	</g>
	<g id="40">
		<g>
			<text transform="matrix(1 0 0 1 671.0293 239.2842)" class="st623 st624">40</text>
		</g>
	</g>
	<g id="50">
		<g>
			<text transform="matrix(1 0 0 1 291.0293 308.957)" class="st623 st624">50</text>
		</g>
	</g>
	<g id="51">
		<g>
			<text transform="matrix(1 0 0 1 288.0981 347.957)" class="st623 st624">51</text>
		</g>
	</g>
	<g id="52">
		<g>
			<text transform="matrix(1 0 0 1 299.5894 386.957)" class="st623 st624">52</text>
		</g>
	</g>
	<g id="53">
		<g>
			<text transform="matrix(1 0 0 1 334.5679 405.957)" class="st623 st624">53</text>
		</g>
	</g>
	<g id="54">
		<g>
			<text transform="matrix(1 0 0 1 377.0083 405.957)" class="st623 st624">54</text>
		</g>
	</g>
	<g id="55">
		<g>
			<text transform="matrix(1 0 0 1 416.4238 397.957)" class="st623 st624">55</text>
		</g>
	</g>
	<g id="56">
		<g>
			<text transform="matrix(1 0 0 1 455.4238 389.457)" class="st623 st624">56</text>
		</g>
	</g>
	<g id="57">
		<g>
			<text transform="matrix(1 0 0 1 493.4238 380.457)" class="st623 st624">57</text>
		</g>
	</g>
	<g id="58">
		<g>
			<text transform="matrix(1 0 0 1 532.9238 369.957)" class="st623 st624">58</text>
		</g>
	</g>
	<g id="59">
		<g>
			<text transform="matrix(1 0 0 1 571.4238 360.957)" class="st623 st624">59</text>
		</g>
	</g>
	<g id="60">
		<g>
			<text transform="matrix(1 0 0 1 611.9238 353.957)" class="st623 st624">60</text>
		</g>
	</g>
	<g id="61">
		<g>
			<text transform="matrix(1 0 0 1 650.4238 352.457)" class="st623 st624">61</text>
		</g>
	</g>
	<g id="62">
		<g>
			<text transform="matrix(1 0 0 1 691.9238 362.457)" class="st623 st624">62</text>
		</g>
	</g>
	<g id="63">
		<g>
			<text transform="matrix(1 0 0 1 718.4238 390.457)" class="st623 st624">63</text>
		</g>
	</g>
	<g id="64">
		<g>
			<text transform="matrix(1 0 0 1 729.4238 428.957)" class="st623 st624">64</text>
		</g>
	</g>
	<g id="65">
		<g>
			<text transform="matrix(1 0 0 1 721.4238 466.457)" class="st623 st624">65</text>
		</g>
	</g>
	<g id="66">
		<g>
			<text transform="matrix(1 0 0 1 695.4238 496.957)" class="st623 st624">66</text>
		</g>
	</g>
	<g id="67">
		<g>
			<text transform="matrix(1 0 0 1 659.4238 514.957)" class="st623 st624">67</text>
		</g>
	</g>
	<g id="68">
		<g>
			<text transform="matrix(1 0 0 1 621.4238 528.457)" class="st623 st624">68</text>
		</g>
	</g>
	<g id="69">
		<g>
			<text transform="matrix(1 0 0 1 582.4238 534.457)" class="st623 st624">69</text>
		</g>
	</g>
	<g id="70">
		<g>
			<text transform="matrix(1 0 0 1 542.9238 538.957)" class="st623 st624">70</text>
		</g>
	</g>
	<g id="71">
		<g>
			<text transform="matrix(1 0 0 1 502.9238 541.457)" class="st623 st624">71</text>
		</g>
	</g>
	<g id="72">
		<g>
			<text transform="matrix(1 0 0 1 462.4238 541.457)" class="st623 st624">72</text>
		</g>
	</g>
	<g id="73">
		<g>
			<text transform="matrix(1 0 0 1 422.2441 538.9883)" class="st623 st624">73</text>
		</g>
	</g>
	<g id="74">
		<g>
			<text transform="matrix(1 0 0 1 383.5703 536.9609)" class="st623 st624">74</text>
		</g>
	</g>
	<g id="41">
		<g>
			<text transform="matrix(1 0 0 1 634.6963 255.2842)" class="st623 st624">41</text>
		</g>
	</g>
	<g id="42">
		<g>
			<text transform="matrix(1 0 0 1 597.5918 263.9507)" class="st623 st624">42</text>
		</g>
	</g>
	<g id="43">
		<g>
			<text transform="matrix(1 0 0 1 555.7852 269.2842)" class="st623 st624">43</text>
		</g>
	</g>
	<g id="44">
		<g>
			<text transform="matrix(1 0 0 1 514.8896 270.6177)" class="st623 st624">44</text>
		</g>
	</g>
	<g id="45">
		<g>
			<text transform="matrix(1 0 0 1 475.5742 269.9512)" class="st623 st624">45</text>
		</g>
	</g>
	<g id="46">
		<g>
			<text transform="matrix(1 0 0 1 436.6074 268.6177)" class="st623 st624">46</text>
		</g>
	</g>
	<g id="47">
		<g>
			<text transform="matrix(1 0 0 1 394.2041 269.9512)" class="st623 st624">47</text>
		</g>
	</g>
	<g id="48">
		<g>
			<text transform="matrix(1 0 0 1 355.2383 271.9512)" class="st623 st624">48</text>
		</g>
	</g>
	<g id="49">
		<g>
			<text transform="matrix(1 0 0 1 317.1509 283.2842)" class="st623 st624">49</text>
		</g>
	</g>
	<g id="31">
		<g>
			<text transform="matrix(1 0 0 1 511.0293 44.1636)" class="st623 st624">31</text>
		</g>
	</g>
	<g id="32">
		<g>
			<text transform="matrix(1 0 0 1 547.5293 46.1636)" class="st623 st624">32</text>
		</g>
	</g>
	<g id="33">
		<g>
			<text transform="matrix(1 0 0 1 587.0293 52.1636)" class="st623 st624">33</text>
		</g>
	</g>
	<g id="34">
		<g>
			<text transform="matrix(1 0 0 1 627.5293 62.1636)" class="st623 st624">34</text>
		</g>
	</g>
	<g id="35">
		<g>
			<text transform="matrix(1 0 0 1 665.1953 79.8306)" class="st623 st624">35</text>
		</g>
	</g>
	<g id="36">
		<g>
			<text transform="matrix(1 0 0 1 694.6963 102.4966)" class="st623 st624">36</text>
		</g>
	</g>
	<g id="37">
		<g>
			<text transform="matrix(1 0 0 1 716.6963 139.8301)" class="st623 st624">37</text>
		</g>
	</g>
	<g id="38">
		<g>
			<text transform="matrix(1 0 0 1 720.3633 175.8301)" class="st623 st624">38</text>
		</g>
	</g>
	<g id="39">
		<g>
			<text transform="matrix(1 0 0 1 700.8633 213.8301)" class="st623 st624">39</text>
		</g>
	</g>
	<g id="11">
		<g>
			<text transform="matrix(1 0 0 1 49.6958 268.6509)" class="st623 st624">11</text>
		</g>
	</g>
	<g id="12">
		<g>
			<text transform="matrix(1 0 0 1 29.8174 305.2168)" class="st623 st624">12</text>
		</g>
	</g>
	<g id="13">
		<g>
			<text transform="matrix(1 0 0 1 22.0288 343.6504)" class="st623 st624">13</text>
		</g>
	</g>
	<g id="14">
		<g>
			<text transform="matrix(1 0 0 1 27.0288 382.6504)" class="st623 st624">14</text>
		</g>
	</g>
	<g id="15">
		<g>
			<text transform="matrix(1 0 0 1 52.6709 415.7891)" class="st623 st624">15</text>
		</g>
	</g>
	<g id="16">
		<g>
			<text transform="matrix(1 0 0 1 90.3618 429.6504)" class="st623 st624">16</text>
		</g>
	</g>
	<g id="17">
		<g>
			<text transform="matrix(1 0 0 1 130.6953 429.6504)" class="st623 st624">17</text>
		</g>
	</g>
	<g id="18">
		<g>
			<text transform="matrix(1 0 0 1 154.3618 396.6504)" class="st623 st624">18</text>
		</g>
	</g>
	<g id="19">
		<g>
			<text transform="matrix(1 0 0 1 177.356 363.5508)" class="st623 st624">19</text>
		</g>
	</g>
	<g id="2">
		<g>
			<text transform="matrix(1 0 0 1 161.5518 53.3179)" class="st623 st624">2</text>
		</g>
	</g>
	<g id="3">
		<g>
			<text transform="matrix(1 0 0 1 199.3608 65.9849)" class="st623 st624">3</text>
		</g>
	</g>
	<g id="4">
		<g>
			<text transform="matrix(1 0 0 1 227.9614 91.3179)" class="st623 st624">4</text>
		</g>
	</g>
	<g id="5">
		<g>
			<text transform="matrix(1 0 0 1 242.1265 131.3179)" class="st623 st624">5 </text>
		</g>
	</g>
	<g id="6">
		<g>
			<text transform="matrix(1 0 0 1 218.1274 161.8271)" class="st623 st624">6 </text>
		</g>
	</g>
	<g id="7">
		<g>
			<text transform="matrix(1 0 0 1 184.1592 183.9844)" class="st623 st624">7 </text>
		</g>
	</g>
	<g id="8">
		<g>
			<text transform="matrix(1 0 0 1 147.9287 197.9844)" class="st623 st624">8 </text>
		</g>
	</g>
	<g id="9">
		<g>
			<text transform="matrix(1 0 0 1 112.9253 214.6514)" class="st623 st624">9 </text>
		</g>
	</g>
</g>
</svg>
)
}
