import Types from '../types/AuthTypes';
import subscriptionTypes from '../types/subscriptionTypes';
import Cookies from 'js-cookie';
import {
  postLogin,
  postSignup,
  postResetPassword,
  postVerifyPassword,
  changePasswordApi,
  sendFeedbackApi,
  postEditCurrentProfile,
  postCreateSubProfile,
  getSubUserProfile,
  getCurrentUser,
  postEditSubProfile,
  getDocuments,
  sendUserFeedbackApi,
  updateCurrentUser,
  userLogout,
  postDeleteAccount,
} from '../../api/authApi';
import { attemptClearData } from './quizActions';
import { notification } from 'antd';
import { getSimplifiedError } from '../../helpers/error';
import { attemptGetQuiz } from './quizActions';
import { checkSubscriptionApi } from './subscriptionActions';

export const doLogin = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: Types.LOGIN_INPROGRESS,
    });

    const resp = await postLogin(payload);
    if (resp.status === 1) {
      dispatch(attemptClearData());
      resp?.user?.user_type === 'Student' || resp?.user?.user_type === 'Children'
        ? sessionStorage.setItem('token', resp.token)
        : Cookies.set('token', resp.token);
      localStorage.setItem('user', JSON.stringify(resp.user));
      dispatch({
        type: Types.LOGIN_SUCCESS,
        status: resp.status,
        user: resp.user,
        token: resp.token,
        subscription: resp?.user_subscription,
        freeTrial: resp?.user?.is_trial_period_done,
      });

      if (resp?.user?.user_type !== 'Admin') {
        dispatch(attemptGetQuiz());
      }
    } else {
      notification['error']({
        message: resp?.message?.non_field_errors[0],
      });
      dispatch({
        type: Types.LOGIN_FAILURE,
        status: resp.status,
        message: resp.message,
      });
    }

    return resp.status;
  };
};
export const doSocialLogin = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: Types.LOGIN_INPROGRESS,
    });

    const resp = await postLogin(payload);
    if (resp.status === 1) {
      dispatch({
        type: Types.LOGIN_SUCCESS,
        status: resp.status,
        user: resp.user,
        token: resp.token,
      });
    } else {
      getSimplifiedError(resp).map((result, i) => {
        notification['error']({
          message: result,
        });
      });
      dispatch({
        type: Types.LOGIN_FAILURE,
        status: resp.status,
        message: resp.message,
      });
    }

    return resp.status;
  };
};

export const doSignup = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: Types.SIGNUP_INPROGRESS,
    });

    const resp = await postSignup(payload);
    if (resp.status === 1) {
      dispatch({
        type: Types.SIGNUP_SUCCESS,
        status: resp.status,
        user: '/login',
      });
      notification['success']({
        message: 'Your account has been successfully created.',
      });
    } else {
      getSimplifiedError(resp).map((result, i) => {
        notification['error']({
          message: result,
        });
      });
      dispatch({
        type: Types.SIGNUP_FAILURE,
        status: resp.status,
        message: resp.message,
      });
    }

    return resp.status;
  };
};
export const doResetPassword = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: Types.RESET_PASSWORD_INPROGRESS,
    });

    const { message, status } = await postResetPassword(payload);
    if (message === 'Successfully Token sent.') {
      dispatch({
        type: Types.RESET_PASSWORD_SUCCESS,
        status: status,
        payload,
      });
      notification['success']({
        message: 'Email Sent Successfully ',
      });
    } else {
      notification['error']({
        message: message,
      });
      dispatch({
        type: Types.RESET_PASSWORD_FAILURE,
        status: status,
        message: message,
      });
    }

    return { message, status };
  };
};
export const doVerifyPassword = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: Types.VERIFY_PASSWORD_INPROGRESS,
    });

    const resp = await postVerifyPassword(payload);
    if (resp.message !== 'Wrong Token.') {
      dispatch({
        type: Types.VERIFY_PASSWORD_SUCCESS,
        status: resp.status,
        user: resp.data,
      });
      notification['success']({
        message: resp.message,
      });
    } else {
      notification['error']({
        message: 'Invalid OTP',
      });
      dispatch({
        type: Types.VERIFY_PASSWORD_FAILURE,
        status: resp.status,
        message: resp.message,
      });
    }

    return resp;
  };
};

export const changePassword = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: Types.ATTEMPT_CHANGE_PASSWORD,
    });
    const resp = await changePasswordApi(payload);
    if (resp.message !== 'Incorrect Password.') {
      dispatch({
        type: Types.SUCCESS_CHANGE_PASSWORD,
      });
      notification['success']({
        message: resp.message,
      });
      return true;
    } else {
      notification['error']({
        message: 'Invalid password',
      });
      dispatch({
        type: Types.FAILED_CHANGE_PASSWORD,
      });
      return false;
    }
  };
};

export const sendFeedback = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: Types.ATTEMPT_SEND_FEEDBACK,
    });
    const resp = await sendFeedbackApi(payload);
    if (resp) {
      dispatch({
        type: Types.SUCCESS_SEND_FEEDBACK,
      });
      notification['success']({
        message: 'Feedback Sent Successfully',
      });
    } else {
      notification['error']({
        message: resp.message,
      });
      dispatch({
        type: Types.FAILED_SEND_FEEDBACK,
      });
    }
    return resp;
  };
};

export const userFeedback = (payload, callBack, isCancel) => {
  return async (dispatch) => {
    dispatch({
      type: Types.ATTEMPT_USER_FEEDBACK,
    });
    const resp = await sendUserFeedbackApi(payload);
    if (resp) {
      callBack();
      dispatch({
        type: Types.SUCCESS_USER_FEEDBACK,
      });
      dispatch(checkSubscriptionApi());
      if (!isCancel) {
        notification['success']({
          message: resp.message,
        });
      }
    } else {
      notification['error']({
        message: "'Something went wrong, please try again later",
      });
      dispatch({
        type: Types.FAILED_USER_FEEDBACK,
      });
    }
    return resp;
  };
};

export const deleteAccount = (payload) => {
  return async (dispatch) => {
    const resp = await postDeleteAccount(payload);
    if (resp) {
      dispatch({
        type: Types.DELETE_ACCOUNT_SUCCESS,
        type: subscriptionTypes.CLEAR_TERM_SERVICE,
      });
    }
    return resp.status;
  };
};

export const doLogout = () => {
  return async (dispatch) => {
    const resp = await userLogout();
    if (resp?.status === 1) {
      dispatch({
        type: Types.LOGOUT_SUCCESS,
      });
    } else {
      if (resp?.errorStatus === 401) {
        dispatch({
          type: Types.LOGOUT_SUCCESS,
        });
      }
    }
    return resp.status;
  };
};

export const attemptGetSubUserProfile = () => {
  return async (dispatch) => {
    dispatch({
      type: Types.ATTEMPT_GET_USER_PROFILE,
    });

    const resp = await getSubUserProfile();
    if (resp.status === 1) {
      dispatch({
        type: Types.SUCCESS_GET_USER_PROFILE,
        payload: resp[0]?.sub_account || [],
      });
    } else {
      getSimplifiedError(resp).map((result, i) => {
        notification['error']({
          message: result,
        });
      });
      dispatch({
        type: Types.FAILURE_GET_USER_PROFILE,
        status: resp.status,
        message: resp.message,
      });
    }
    return resp.status;
  };
};

export const attemptGetUserProfile = () => {
  return async (dispatch) => {
    dispatch({
      type: Types.GET_CURRENT_USER_PROFILE_ATTEMPT,
    });

    const resp = await getCurrentUser();
    if (resp.status === 1) {
      dispatch({
        type: Types.GET_CURRENT_USER_PROFILE_SUCCESS,
        payload: resp[0] || {},
      });
    } else {
      dispatch({
        type: Types.GET_CURRENT_USER_PROFILE_FAILURE,
      });
      notification['error']({
        message: resp?.message?.detail || 'Something went wrong, please try again later',
      });
    }
    return resp.status;
  };
};
export const attemptUpdateUserProfile = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: Types.UPDATE_CURRENT_USER_PROFILE_ATTEMPT,
    });

    const resp = await updateCurrentUser(payload);
    if (resp.status === 1) {
      localStorage.setItem('user', JSON.stringify(resp));
      dispatch({
        type: Types.UPDATE_CURRENT_USER_PROFILE_SUCCESS,
        payload: resp,
      });
    } else {
    }
    return resp.status;
  };
};

export const editSubProfile = (userId, payload, callBack, userType) => {
  return async (dispatch) => {
    dispatch({
      type: Types.EDIT_SUB_PROFILE_IN_PROGRESS,
    });

    const resp = await postEditSubProfile(userId, payload);
    if (resp.status === 1) {
      callBack();
      dispatch({
        type: Types.EDIT_SUB_PROFILE_SUCCESS,
        status: resp.status,
        // user: '/login',
      });
      notification['success']({
        message: `Your ${userType}'s profile successfully edited.`,
      });
    } else {
      getSimplifiedError(resp).map((result, i) => {
        notification['error']({
          message: result,
        });
      });
      dispatch({
        type: Types.EDIT_SUB_PROFILE_FAILURE,
        status: resp.status,
        message: resp.message,
      });
    }

    return resp.status;
  };
};

export const editCurrentProfile = (userId, payload, callBack) => {
  return async (dispatch) => {
    dispatch({
      type: Types.EDIT_CURRENT_PROFILE_IN_PROGRESS,
    });

    const resp = await postEditCurrentProfile(userId, payload);
    if (resp.status === 1) {
      callBack();
      dispatch({
        type: Types.EDIT_CURRENT_PROFILE_SUCCESS,
        payload: resp,
      });
      notification['success']({
        message: 'Your profile has been successfully created.',
      });
    } else {
      dispatch({
        type: Types.EDIT_CURRENT_PROFILE_FAILURE,
        status: resp.status,
        message: resp.message,
      });
      notification['error']({
        message: resp?.message && resp?.message?.email[0],
      });
    }

    return resp.status;
  };
};

export const createSubProfile = (payload, callBack, userType) => {
  return async (dispatch) => {
    dispatch({
      type: Types.CREATE_SUB_PROFILE_IN_PROGRESS,
    });

    const resp = await postCreateSubProfile(payload);
    if (resp.status === 1) {
      callBack();
      dispatch({
        type: Types.CREATE_SUB_PROFILE_SUCCESS,
      });
      notification['success']({
        message: `Your ${userType} profile has been successfully created.`,
      });
    } else {
      dispatch({
        type: Types.CREATE_SUB_PROFILE_FAILED,
      });
      notification['error']({
        message: resp?.message?.message,
      });
    }

    return resp.status;
  };
};

export const getUserDocuments = () => {
  return async (dispatch) => {
    dispatch({
      type: Types.GET_USER_DOCUMENTS,
    });
    const resp = await getDocuments();
    if (resp.status === 1) {
      delete resp.status;
      const objects = Object.values(resp);
      dispatch({
        type: Types.GET_USER_DOCUMENTS_SUCCESS,
        payload: objects,
      });
    } else {
      dispatch({
        type: Types.GET_USER_DOCUMENTS_SUCCESS,
        payload: [],
      });
    }

    return resp.status;
  };
};

export const attemptSetSelectedUserType = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: Types.SET_SELECTED_USER_TYPE,
      payload,
    });
  };
};
