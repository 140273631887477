import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import store from '../index';

const useReducerData = (reducerName, attr, defaultValue: {}) => {
  return useSelector((state: store.getState) => state[reducerName][attr]);
};

const useStoreActions = (actions) => {
  const dispatch = useDispatch();
  return useMemo(() => bindActionCreators(actions || {}, dispatch), [actions]);
};

const loginUserData = () => {
  return JSON.parse(localStorage.getItem('user'));
};

export { useStoreActions, loginUserData, useReducerData };
