import React from 'react';
import styles from './PrivacyPolicy.module.scss';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import PrivacyPolicyData from './PrivacyPolicyData';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

const PrivacyPolicy = () => {
  const { user } = useSelector((state) => state.auth);
  const {is_terms_agreed} = user
  const location = useLocation();
  const state = location.state;
  return (
    <div className={styles.mainContainer}>
      <div className={styles.termsContainer}>
        <Header title={`Privacy Policy`} />
        <div className={styles.contentContainer}>
          <div>
            <p>
              Protecting your private information is our priority. This Statement of Privacy applies to Cogplay, and
              Mazzei Enterprises - Cogplay and governs data collection and usage. For the purposes of this Privacy
              Policy, unless otherwise noted, all references to Mazzei Enterprises - Cogplay include www.cogplay.ca,,
              Mazzei Enterprises - Eva Mazzei Tutoring and www.evamazzeitutoring.com. The Mazzei Enterprises - Cogplay
              application is a Interactive Education for Children application. By using the Mazzei Enterprises - Cogplay
              application, you consent to the data practices described in this statement.{' '}
            </p>

            <div className={styles.titleClass}>Collection of your Personal Information</div>
            <p>
              In order to better provide you with products and services offered, Mazzei Enterprises - Cogplay may
              collect personally identifiable information, such as your:{' '}
            </p>
            <div className={styles.subContent}>
              <ul className={styles.listDashed}>
                <li> First and Last Name</li>
                <li> Mailing Address</li>
                <li> E-mail Address</li>
                <li> Phone Number</li>
                <li> child's age, school district, diagnosis</li>
              </ul>
            </div>

            <p>
              If you purchase Mazzei Enterprises - Cogplay's products and services, we collect billing and credit card
              information. This information is used to complete the purchase transaction.
            </p>

            <p>
              Mazzei Enterprises - Cogplay may also collect anonymous demographic information, which is not unique to
              you, such as your:{' '}
            </p>
            <div className={styles.subContent}>
              <ul className={styles.listDashed}>
                <li>Age</li>
                <li>school district and diagnosis</li>
              </ul>
            </div>

            <p>
              We do not collect any personal information about you unless you voluntarily provide it to us. However, you
              may be required to provide certain personal information to us when you elect to use certain products or
              services. These may include: (a) registering for an account; (b) entering a sweepstakes or contest
              sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d)
              sending us an email message; (e) submitting your credit card or other payment information when ordering
              and purchasing products and services. To wit, we will use your information for, but not limited to,
              communicating with you in relation to services and/or products you have requested from us. We also may
              gather additional personal or non-personal information in the future.
            </p>

            <div className={styles.titleClass}>Use of your Personal Information </div>
            <p>
              Mazzei Enterprises - Cogplay collects and uses your personal information to operate and deliver the
              services you have requested.{' '}
            </p>
            <p>
              Mazzei Enterprises - Cogplay may also use your personally identifiable information to inform you of other
              products or services available from Mazzei Enterprises - Cogplay and its affiliates.{' '}
            </p>

            <div className={styles.titleClass}>Sharing Information with Third Parties </div>
            <p>Mazzei Enterprises - Cogplay does not sell, rent or lease its customer lists to third parties. </p>

            <p>
              Mazzei Enterprises - Cogplay may, from time to time, contact you on behalf of external business partners
              about a particular offering that may be of interest to you. In those cases, your unique personally
              identifiable information (e-mail, name, address, telephone number) is not transferred to the third party.
              Mazzei Enterprises - Cogplay may share data with trusted partners to help perform statistical analysis,
              send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties
              are prohibited from using your personal information except to provide these services to Mazzei Enterprises
              - Cogplay, and they are required to maintain the confidentiality of your information.
            </p>

            <p>
              Mazzei Enterprises - Cogplay may disclose your personal information, without notice, if required to do so
              by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law
              or comply with legal process served on Mazzei Enterprises - Cogplay or the site; (b) protect and defend
              the rights or property of Mazzei Enterprises - Cogplay; and/or (c) act under exigent circumstances to
              protect the personal safety of users of Mazzei Enterprises - Cogplay, or the public.{' '}
            </p>

            <div className={styles.titleClass}>Right to Deletion </div>
            <p>Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:</p>
            <div className={styles.subContent}>
              <ul>
                <li> Delete your personal information from our records; and</li>
                <li> Direct any service providers to delete your personal information from their records.</li>
              </ul>
            </div>

            <p>
              Please note that we may not be able to comply with requests to delete your personal information if it is
              necessary to:{' '}
            </p>
            <div className={styles.subContent}>
              <ul>
                <li>
                  Complete the transaction for which the personal information was collected, fulfill the terms of a
                  written warranty or product recall conducted in accordance with federal law, provide a good or service
                  requested by you, or reasonably anticipated within the context of our ongoing business relationship
                  with you, or otherwise perform a contract between you and us;
                </li>
                <li>
                  Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or
                  prosecute those responsible for that activity;
                </li>
                <li> Debug to identify and repair errors that impair existing intended functionality;</li>
                <li>
                  Exercise free speech, ensure the right of another consumer to exercise his or her right of free
                  speech, or exercise another right provided for by law;
                </li>
                <li>Comply with the California Electronic Communications Privacy Act;</li>
                <li>
                  {' '}
                  Engage in public or peer-reviewed scientific, historical, or statistical research in the public
                  interest that adheres to all other applicable ethics and privacy laws, when our deletion of the
                  information is likely to render impossible or seriously impair the achievement of such research,
                  provided we have obtained your informed consent;
                </li>
                <li>
                  Enable solely internal uses that are reasonably aligned with your expectations based on your
                  relationship with us;
                </li>
                <li>Comply with an existing legal obligation; or</li>
                <li>
                  Otherwise use your personal information, internally, in a lawful manner that is compatible with the
                  context in which you provided the information.
                </li>
              </ul>
            </div>

            <div className={styles.titleClass}>Children Under Thirteen </div>
            <p>
              Mazzei Enterprises - Cogplay collects personally identifiable information from children under the age of
              thirteen. Mazzei Enterprises - Cogplay collects this information for the following reason(s): statistical
              purposes.{' '}
            </p>

            <p>
              If you are under the age of thirteen, you must ask your parent or guardian for permission to use this
              application. If you are a parent and you have questions regarding our data collection practices, please
              contact us using the information provided at the end of this Statement of Privacy.{' '}
            </p>

            <div className={styles.titleClass}> E-mail Communications</div>
            <p>
              From time to time, Mazzei Enterprises - Cogplay may contact you via email for the purpose of providing
              announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication. In
              order to improve our Services, we may receive a notification when you open an email from Mazzei
              Enterprises - Cogplay or click on a link therein.
            </p>

            <p>
              If you would like to stop receiving marketing or promotional communications via email from Mazzei
              Enterprises - Cogplay, you may opt out of such communications by clicking on unsubscribe.
            </p>

            <div className={styles.titleClass}>Changes to this Statement </div>
            <p>
              Mazzei Enterprises - Cogplay reserves the right to change this Privacy Policy from time to time. We will
              notify you about significant changes in the way we treat personal information by sending a notice to the
              primary email address specified in your account, by placing a prominent notice on our application, and/or
              by updating any privacy information. Your continued use of the application and/or Services available after
              such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b)
              agreement to abide and be bound by that Policy.{' '}
            </p>

            <div className={styles.titleClass}>Contact Information </div>
            <p>
              Mazzei Enterprises - Cogplay welcomes your questions or comments regarding this Statement of Privacy. If
              you believe that Mazzei Enterprises - Cogplay has not adhered to this Statement, please contact Mazzei
              Enterprises - Cogplay at:
            </p>

            <p>
              Mazzei Enterprises - Cogplay
              <br />
              5504 Bay Ridge Drive
              <br />
              Blaine, Washington 98230
            </p>

            <div>Email Address: </div>
            <p>mazzeienterprises38@gmail.com </p>

            <div>Telephone number: </div>
            <p>7788283540</p>

            <p>Effective as of July 04, 2022 </p>
          </div>
        </div>
        {!is_terms_agreed && <Footer state={state} />}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
