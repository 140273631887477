import Types from '../types/subscriptionTypes';

const initialState = {
  loading: false,
  verifyTokenLoading: false,
  subscriptions: [],
  message: '',
  status: '',
  resendCodeLoading: false,
  cancelLoading: false,
  termServiceData: []
};

export default function SubscriptionReducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_SUBSCRIPTIONS:
      return {
        ...state,
        loading: true,
      };
    case Types.VERIFY_CODE:
      return {
        ...state,
        verifyTokenLoading: true,
      };

    case Types.SUCCESS_VERIFY_CODE:
      return {
        ...state,
        verifyTokenLoading: false,
      };

    case Types.RESEND_CODE:
      return {
        ...state,
        resendCodeLoading: true,
      };
    case Types.SUCCESS_RESEND_CODE:
      return {
        ...state,
        resendCodeLoading: false,
      };

    case Types.CANCEL_SUBSCRIPTION:
      return {
        ...state,
        cancelLoading: true,
      };

    case Types.SUCCESS_CANCEL_SUBSCRIPTION:
      return {
        ...state,
        cancelLoading: false,
      };
    case Types.SUCCESS_GET_SUBSCRIPTIONS:
      return {
        ...state,
        loading: false,
        subscriptions: action.payload,
      };
    case Types.FAILURE_GET_SUBSCRIPTIONS:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };
    case Types.SET_TERM_SERVICE:
      const data = [...state.termServiceData, action.payload]
      return {
        ...state,
        loading: false,
        termServiceData: data,
      };
    case Types.CLEAR_TERM_SERVICE:
      return {
        ...state,
        loading: false,
        termServiceData: [],
      };

    default:
      return state;
  }
}
