import { Form } from 'antd';
import React, { useState } from 'react';
import CustomInput from '../../../../components/Input/Input';
import styles from './Feedback.module.scss';
import CustomTextarea from '../../../../components/InputTextarea/InputTextarea';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { sendFeedback } from '../../../../store/actions/authActions';

const Feedback = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [feedbackForm, setFeedBackForm] = useState({
    title: '',
    text: '',
  });

  const handleInput = ({ target }) => {
    const { name, value } = target;
    setFeedBackForm({
      ...feedbackForm,
      [name]: value,
    });
  };

  const onFinish = async () => {
    await dispatch(sendFeedback(feedbackForm));
    navigate(-1);
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.feedbackContainer}>
        <Header title={`Support / Send Feedback`} />
        <div className={styles.formInput}>
          <Form onFinish={onFinish} autoComplete="off">
            <CustomInput
              label="Enter Title"
              inputType="input"
              name="title"
              placeholder="Title"
              value={feedbackForm.title}
              onChange={handleInput}
              rules={[
                {
                  required: true,
                  message: 'Please enter title',
                },
              ]}
              allowClear
            />
            <CustomTextarea
              label="Enter Message"
              inputType="textarea"
              name="text"
              placeholder="Message"
              value={feedbackForm.text}
              onChange={handleInput}
              rules={[
                {
                  required: true,
                  message: 'Please enter message',
                },
              ]}
              allowClear
            />
            <div className={styles.btnWrapper}>
              <Footer />
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
