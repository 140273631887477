import * as React from 'react';
import { InputNumber, Form } from 'antd';
import styles from './inputNumber.module.scss';
import cs from 'classnames';
const CustomInputNumber = ({
  children,
  message,
  className,
  value,
  rules,
  name,
  label,
  isVertical = true,
  onChange,
  placeholder,
  isDisables,
  autoFocus,
  defaultValue,
  ...rest
}) => {
  return (
    <>
      <Form.Item
        required={false}
        className={cs(styles.inputWrapper, { [styles.inputVerticalWrapper]: isVertical })}
        label={label}
        onChange={onChange}
        name={name}
        rules={rules}
        labelAlign={isVertical ? 'left' : 'right'}
      >
        <InputNumber
          className={cs(styles.inputClass, { [className]: className })}
          name={name}
          type="number"
          placeholder={placeholder}
          onChange={onChange}
          controls={false}
          value={value}
          autoFocus
          disabled={isDisables}
          defaultValue={defaultValue}
          {...rest}
        />
      </Form.Item>
    </>
  );
};
export default CustomInputNumber;
