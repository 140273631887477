import styles from './NumberFamilies.module.scss';
import { Form } from 'antd';
import CustomInput from '../../../components/Input';
import React from 'react';

const Question = ({
  variableA,
  variableB,
  sign,
  handleChange,
  handleKeyDown,
  isDisplayNext,
  form,
  name,
  inputRef,
  keyValue,
  numberKey,
}) => {
  return (
    <div className={styles.questionWrapper}>
      {sign === '+' || sign === 'x' ? (
        <span className={styles.numberWrapper}>{variableA}</span>
      ) : variableA === '∞' && (name === 3 || (name === 2 && numberKey > 0)) && sign === '÷' ? (
        <span className={styles.numberWrapper}>{name === 2 ? numberKey : keyValue}</span>
      ) : (
        <span className={styles.answerWrapper}>
          <Form colon={false} form={form} autoComplete="off">
            <CustomInput
              inputType="answer"
              name={name}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              disabled={!isDisplayNext}
              ref={inputRef}
              id={`question-${name}`}
            />
          </Form>
        </span>
      )}
      <span className={styles.signWrapper}>{sign}</span>
      {variableA === '∞' && (name === 3 || (name === 2 && numberKey > 0)) && sign === '÷' ? (
        <span className={styles.answerWrapper}>
          <Form colon={false} form={form} autoComplete="off">
            <CustomInput
              inputType="answer"
              name={name}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              disabled={!isDisplayNext}
              ref={inputRef}
              id={`question-${name}`}
            />
          </Form>
        </span>
      ) : (
        <span className={styles.numberWrapper}>{variableB}</span>
      )}

      <span className={styles.signWrapper}>=</span>
      {sign === '+' || sign === 'x' ? (
        <span className={styles.answerWrapper}>
          <Form colon={false} form={form} autoComplete="off">
            <CustomInput
              inputType="answer"
              name={name}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              disabled={!isDisplayNext}
              ref={inputRef}
              id={`question-${name}`}
            />
          </Form>
        </span>
      ) : (
        <span className={variableA === '∞' ? styles.infinityNumberWrapper : styles.numberWrapper}>{variableA}</span>
      )}
    </div>
  );
};

export default Question;
