import React, { useState } from 'react';
import styles from './signup.module.scss';
import { Form } from 'antd';
import CustomInput from '../../../components/Input/Input';
import CustomButton from '../../../components/Button/Button';
import Header from '../authComponent/Header/Header';
import Footer from '../authComponent/Footer/Footer';
import { doSignup } from '../../../store/actions/authActions';
import { doLogin } from '../../../store/actions/authActions';
import { useDispatch } from 'react-redux';
import CustomPassword from '../../../components/InputPassword/InputPassword';
import CustomInputDropDown from '../../../components/InputDropDown';
import { validatePassword } from '../../../helpers/constants';
import { getCountries, getCities, getStates } from '../../../helpers/utility';
import { useNavigate } from 'react-router-dom';
import { Country, State, City } from 'country-state-city';
import lookup from 'country-code-lookup'
const SignUp = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [signupFormData, setSignupFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    country: '',
    state: '',
    city: '',
    school_district: '',
  });

  const handleChange = ({ target: { name, value } }) => {
    setSignupFormData({
      ...signupFormData,
      [name]: value,
    });
  };

  const handleSelection = (name, value, option) => {
    if (option) {
      const data = {
        name: name === 'country' || name === 'state' ? option?.children : value,
        value,
      };
      setSignupFormData((prevData) => {
        if (name === 'country') {
          form.resetFields(['state', 'city', 'school_district']);
          return { ...prevData, [name]: data, state: null, city: null, school_district: null };
        } else if (name === 'state') {
          form.resetFields(['city', 'school_district']);
          return { ...prevData, [name]: data, city: null, school_district: null };
        } else {
          return { ...prevData, [name]: data };
        }
      });
    }
  };
  const handleSubmit = async () => {
    const subscriptionCountries = ['Canada', 'United States', 'United Kingdom']
    const payload = {
      name: signupFormData.name,
      email: signupFormData.email,
      password: signupFormData.password,
      country: signupFormData.country?.name,
      state: signupFormData.state?.name ?? signupFormData?.state,
      city: signupFormData.city?.name ?? signupFormData.city,
      school_district: signupFormData.school_district?.name ?? signupFormData?.school_district,
      country_region: subscriptionCountries.includes(signupFormData?.country?.name) ? signupFormData?.country?.name : lookup.byInternet(signupFormData?.country?.value)?.continent
    };

    setLoading(true);
    const response = await dispatch(doSignup(payload));
    if (response === 1) {
      const login = await dispatch(doLogin({ username: signupFormData.email, password: signupFormData.password }));
      if (login === 1) {
        navigate('/pricing');
      }
      setSignupFormData({
        name: '',
        email: '',
        password: '',
        country: '',
        state: '',
        city: '',
        school: '',
      });
      form.resetFields();
    } else {
      setLoading(false);
    }
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.formContainer}>
        <Header />
        <div className={styles.formInput}>
          <Form onFinish={handleSubmit} autoComplete="off" colon={false} form={form}>
            <CustomInput
              label="Full Name"
              inputType="input"
              name="name"
              value={signupFormData.name}
              onChange={handleChange}
              placeholder="Enter your full name"
              maxLength={50}
              rules={[
                {
                  required: true,
                  message: 'Please enter your name',
                  whitespace: true,
                },
              ]}
              allowClear
            />
            <CustomInput
              label="Email"
              inputType="email"
              name="email"
              value={signupFormData.email}
              onChange={handleChange}
              placeholder="Enter your email"
              rules={[
                {
                  required: true,
                  message: 'Please enter your email!',
                },
                {
                  type: 'email',
                  message: 'Please enter valid E-mail!',
                },
              ]}
              allowClear
            />
            <CustomPassword
              label="Password"
              inputType="password"
              name="password"
              value={signupFormData.password}
              onChange={handleChange}
              placeholder="Enter your password"
              rules={[
                ({ getFieldValue }) => ({
                  validator() {
                    if (!validatePassword(getFieldValue('password'))) {
                      if (!getFieldValue('password')) {
                        return Promise.reject(new Error('Please enter password'));
                      }
                      return Promise.reject(new Error('Please enter strong password'));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              allowClear
            />
            <CustomPassword
              label="Confirm Password"
              inputType="password"
              name="confirmPassword"
              value={signupFormData.confirmPassword}
              onChange={handleChange}
              placeholder="Confirm password"
              dependencies={['password']}
              rules={[
                {
                  required: true,
                  message: 'Please enter confirm password',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('confirm password does not match with password'));
                  },
                }),
              ]}
              allowClear
            />
            <CustomInputDropDown
              label="Enter Country"
              data={getCountries && getCountries}
              placeholder="Enter Country"
              name="country"
              onChange={(e, option) => handleSelection('country', e, option)}
              rules={[
                {
                  required: true,
                  message: 'Please enter your country',
                  whitespace: true,
                },
              ]}
            />
            {
              getStates(signupFormData?.country?.value)?.length > 0 && (
                <CustomInputDropDown
                  label="Enter State/Province"
                  data={getStates(signupFormData?.country?.value) && getStates(signupFormData?.country?.value)}
                  placeholder="Enter State/Province"
                  name="state"
                  selectedValue={signupFormData?.state?.value}
                  onChange={(e, option) => handleSelection('state', e, option)}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your state',
                      whitespace: true,
                    },
                  ]} />
              ) 
            }

            {signupFormData?.country?.name !== 'China' &&
              signupFormData?.state &&
              getCities(signupFormData?.country?.value, signupFormData?.state?.value)?.length > 0 && (

              <CustomInputDropDown
                label="Enter city"
                data={getCities(signupFormData?.country?.value, signupFormData?.state?.value) &&
                  getCities(signupFormData?.country?.value, signupFormData?.state?.value)}
                placeholder="Enter city"
                name="city"
                selectedValue={signupFormData?.city?.value}
                onChange={(e, option) => handleSelection('city', e, option)}
                rules={[
                  {
                    required: true,
                    message: 'Please enter your city',
                    whitespace: true,
                  },
                ]} />
            ) }
            {getCities(signupFormData?.country?.value, signupFormData?.state?.value).length > 0 && (
              <CustomInputDropDown
                label="School District"
                data={
                  getCities(signupFormData?.country?.value, signupFormData?.state?.value) &&
                  getCities(signupFormData?.country?.value, signupFormData?.state?.value)
                }
                placeholder="School District"
                name="school_district"
                selectedValue={signupFormData?.school_district ? signupFormData.school_district?.value : null}
                onChange={(e, option) => handleSelection('school_district', e, option)}
                rules={[
                  {
                    required: true,
                    message: 'Please enter your school district',
                    whitespace: true,
                  },
                ]} />
            )}

            { <>
              {( !getStates(signupFormData?.country?.value).length > 0 && signupFormData?.country?.name) &&<CustomInput
                label="Enter State/Province"
                inputType="input"
                name="state"
                value={signupFormData?.state?.value}
                onChange={handleChange}
                placeholder="Enter State/Province" />}

            {(!getCities(signupFormData?.country?.value, signupFormData?.state?.value)?.length > 0 && signupFormData?.state) &&  <CustomInput
                label="Enter city"
                inputType="input"
                name="city"
                value={signupFormData?.city?.value}
                onChange={handleChange}
                placeholder="Enter city" />}
              {(!getCities(signupFormData?.country?.value, signupFormData?.state?.value).length > 0 && signupFormData?.city) && <CustomInput
                label="School District"
                inputType="input"
                name="school_district"
                value={signupFormData?.school_district?.value}
                onChange={handleChange}
                placeholder="School District" />}
            </>}


            <CustomButton type="primary" loading={loading} htmlType="submit">
              Create account
            </CustomButton>
          </Form>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default SignUp;
