import * as React from 'react';
import { Input, Form } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import cs from 'classnames';

// Styles
import styles from './Input.module.scss';

const CustomInput = ({
  children,
  message,
  className,
  rules,
  id,
  name,
  inputType = 'text',
  label,
  isVertical = true,
  maxLength,
  onChange,
  placeholder,
  type = 'text',
  ...rest
}) => {
  return (
    <>
      <Form.Item
        required={false}
        className={cs(styles.inputWrapper, { [styles.inputVerticalWrapper]: isVertical })}
        label={label}
        onChange={onChange}
        name={name}
        type={inputType}
        rules={rules}
        labelAlign={isVertical ? 'left' : 'right'}
      >
        <Input
          type={type}
          className={cs(styles.inputClass, { [className]: className })}
          id={id}
          name={name}
          showCount={!!maxLength}
          maxLength={maxLength}
          placeholder={placeholder}
          suffix={rest.searchField ? <SearchOutlined /> : ''}
          {...rest}
        />
      </Form.Item>
    </>
  );
};
export default CustomInput;
