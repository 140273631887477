import React from 'react';
import styles from './EyeTrackingModal.module.scss';
import BoyImage from '../../../../assets/images/HomePage/InformationModal/EyeTrackingModal/boyImage.png';
import HeaderIcon from '../../../../assets/images/HomePage/InformationModal/BrainModal/icon.png';
import DirectionChart from '../../../../assets/images/HomePage/InformationModal/EyeTrackingModal/directionChart.png';
import Figure from '../../../../assets/images/HomePage/InformationModal/EyeTrackingModal/figure8.png';
import { TrademarkCircleOutlined } from '@ant-design/icons';

const EyeTrackingModal = () => {
  return (
    <div>
      <div className={styles.headerContainer}>
        <div className={styles.headerContent}>
          <div className={styles.headerText}>Eye Tracking and Focus</div>
          <div className={styles.boyImage}>
            <img src={BoyImage} alt={BoyImage} />
          </div>
        </div>
        <div className={styles.headerIcon}>
          <img src={HeaderIcon} alt={HeaderIcon} />
        </div>
      </div>

      <div className={styles.mainContainer}>
        <div className={styles.containerWrapper1}>
          <div className={styles.imageContainer}>
            <img src={DirectionChart} alt={DirectionChart} />
          </div>
          <div className={styles.containerText}>
            <div className={styles.textWrapper1}>
              <p>
                "Eye tracking skills are particularly important for reading,and achieving maximum academic potential.
                Poor eye tracking skills are closely associated with poor reading skills.Poor eye tracking skills are
                more common than most people may realize. 25%(1 in 4)of all students cannot read due to vision skills
                deficits."
              </p>
            </div>
            <div className={styles.textWrapper2}>
              <p>Mar 10,2017</p>
              <p>Eye Tracking</p>
              <div className={styles.linkWrapper}>
                <a href="https://www.advancedvisiontherapycenter.com/" target="blank">
                  www.advancedvisiontherapycenter.com
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.containerWrapper2}>
          <div className={styles.textContainer1}>
            <p>
              "Similar to when a child loses his place while reading,poor tracking skills might cause him to lose this
              place within a math problem.He will have to keep numbers lined up properly and organized to calculate even
              basic math,such as aligning columns of numbers for adding,or following the diagonal movement of a division
              or multiplication problem"
            </p>
            <div className={styles.linkWrapper}>
              <a
                href="http://www.visuallearningcenter.com/can-vision-therapy-improve-performance-in-math"
                target="blank"
              >
                http://www.visuallearningcenter.com/can-vision-therapy-improve-performance-in-math
              </a>
            </div>
          </div>
          <div className={styles.textContainer2}>
            <div className={styles.imageContainer}>
              <img src={Figure} alt={Figure} />
            </div>
            <div className={styles.imageText}>
              <p>
                I created this program with eye tracking activities that strengthen the child's ability to follow a line
                in reading or a simple math equation.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.footerContainer}>
        <div className={styles.copyRight}>
          <TrademarkCircleOutlined
            style={{
              fontSize: '14px',
            }}
          />
          Cogplay
        </div>
      </div>
    </div>
  );
};

export default EyeTrackingModal;
